import { Stack } from '@mui/material'
import { IViewActionsProps } from './ViewActions.types'

export function ViewActions(props: IViewActionsProps): JSX.Element {
  const { disablePadding, style, ...stackProps } = props
  const padding = disablePadding ? undefined : { pl: 3, pr: 3, pt: 1, pb: 1 }

  return (
    <Stack
      flexDirection="row"
      justifyContent={props.justifyContent ?? 'flex-end'}
      gap={2}
      style={{ marginTop: 0, ...style }}
      {...padding}
      {...stackProps}
    >
      {props.children}
    </Stack>
  )
}
