import { useTour } from '@reactour/tour'
import React, { createContext, useContext, useEffect } from 'react'
import { useAuthContext } from './AuthContext'
import { hints, isConsumer, isOperator } from '../shared/Constants'
import { buildOperatorTourInitialSteps } from '../guide/OperatorTourInitial'
import { buildCustomerTourInitialSteps } from '../guide/CustomerTourInitial'
import { useUiContext } from './UiContext'
import { useLayout } from '../hooks/Layout'

interface TourStepsContextProps {
  startTour: () => void
  endTour: () => void
}

interface IProps {
  children?: React.ReactNode
}

const TourStepsContext = createContext<TourStepsContextProps | undefined>(undefined)
export const TourStepsContextProvider: React.FC<IProps> = ({ children }) => {
  const tourContext = useTour()
  const authContext = useAuthContext()
  const uiContext = useUiContext()
  const layout = useLayout()

  useEffect(() => {
    if (!tourContext || !tourContext.setSteps) return
    if (!authContext.loggedAccount?.profile) return
    const profileType = authContext.loggedAccount?.profile.type

    // check if default tutorial is disabled
    const showFirstLoginHint = !authContext.loggedAccount.profile.fePreferences?.guideCfg?.silencedIds.includes(
      hints.HINT_FIRST_LOGIN
    )
    if (!showFirstLoginHint) return

    if (isOperator(profileType)) {
      const _steps = buildOperatorTourInitialSteps(layout, uiContext)
      tourContext.setSteps(_steps)
    } else if (isConsumer(profileType)) {
      const _steps = buildCustomerTourInitialSteps(layout, uiContext)
      tourContext.setSteps(_steps)
    }
    tourContext.setIsOpen(true)

    return () => {
      tourContext.setSteps && tourContext.setSteps([])
      tourContext.setCurrentStep(0)
      tourContext.setIsOpen(false)
    }
  }, [authContext.loggedAccount])

  const startTour = () => {
    tourContext.setIsOpen(true)
  }

  const endTour = () => {
    tourContext.setIsOpen(false)
  }

  return (
    <TourStepsContext.Provider value={{ startTour, endTour }}>
      {/* don't remove, is an invisible anchor for a tour to show steps not related to a specific element */}
      <div className="new-entry-tour-intro" style={{ width: '0px', height: '0px', display: 'none' }} />
      {children}
    </TourStepsContext.Provider>
  )
}

export const useTourStepsContext = (): TourStepsContextProps => {
  const context = useContext(TourStepsContext)
  if (!context) {
    throw new Error('useTourStepsContext must be used within a TourStepsContextProvider')
  }
  return context
}
