import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function InterdictIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 12,2.0127982 A 9.9872049,9.9872049 0 1 0 21.987199,12.000003 9.9908449,9.9908449 0 0 0 12,2.0127982 Z M 4.0102399,12.000003 A 7.9875598,7.9875598 0 0 1 12,4.0102383 a 7.8922449,7.8922449 0 0 1 4.89373,1.68784 L 5.6980749,16.893729 A 7.8922599,7.8922599 0 0 1 4.0102399,12.000003 Z M 12,19.989763 A 7.8922449,7.8922449 0 0 1 7.1062699,18.301923 L 18.301924,7.1062732 A 7.8922249,7.8922249 0 0 1 19.98976,12.000003 7.9875598,7.9875598 0 0 1 12,19.989763 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
