import { Box, Paper, Portal, Stack } from '@mui/material'
import { ViewHeader } from '../viewHeader/ViewHeader'
import { MouseEvent, PropsWithChildren, useCallback, useEffect, useRef } from 'react'

export type IDraggableOverWindowProps = PropsWithChildren & {
  title: string
  open: boolean
  onClose: () => void
}

type DragState = {
  top: number
  left: number
  origin?: { x: number; y: number }
}

export function DraggableOverWindow(props: IDraggableOverWindowProps): JSX.Element {
  const { title, open, onClose, children } = props
  const windowRef = useRef<HTMLDivElement | null>(null)
  const headerRef = useRef<HTMLDivElement | null>(null)
  const centeredRef = useRef<boolean>(false)
  const dragStateRef = useRef<DragState>({
    top: 100,
    left: 100,
  })
  function applyWindowPosition() {
    if (windowRef.current) {
      windowRef.current.style.top = dragStateRef.current.top + 'px'
      windowRef.current.style.left = dragStateRef.current.left + 'px'
    }
  }
  function applyCursor(cursor?: string) {
    if (headerRef.current) {
      headerRef.current.style.cursor = cursor || (dragStateRef.current.origin ? 'grabbing' : 'grab')
    }
  }

  const centerWindow = useCallback(() => {
    const window = windowRef.current
    if (!window) {
      return
    }
    const parent = window.parentElement
    if (!parent) {
      return
    }
    const top = parent.clientHeight / 2 - window.clientHeight / 2
    const left = parent.clientWidth / 2 - window.clientWidth / 2
    console.log({ top, left })
    const dragState = dragStateRef.current
    dragStateRef.current = { ...dragState, top, left }
    applyWindowPosition()
    centeredRef.current = true
  }, [])

  function onClickExit() {
    onClose()
  }
  function onMouseDown(event: MouseEvent<HTMLElement>) {
    event.stopPropagation()
    event.preventDefault()
    dragStateRef.current.origin = {
      x: event.screenX,
      y: event.screenY,
    }
    applyCursor()
  }
  function onMouseMove(event: MouseEvent<HTMLElement>) {
    const dragState = dragStateRef.current
    if (!dragState.origin || !windowRef.current) {
      return
    }
    event.stopPropagation()
    event.preventDefault()
    const delta = {
      x: event.screenX - dragState.origin.x,
      y: event.screenY - dragState.origin.y,
    }
    const maxTop = (windowRef.current.parentElement?.clientHeight || 3000) - windowRef.current.clientHeight
    const maxLeft = (windowRef.current.parentElement?.clientWidth || 3000) - windowRef.current.clientWidth
    const top = Math.max(0, Math.min(maxTop, dragState.top + delta.y))
    const left = Math.max(0, Math.min(maxLeft, dragState.left + delta.x))
    dragStateRef.current = {
      top,
      left,
      origin: {
        x: left === 0 || left === maxLeft ? dragState.origin.x : event.screenX,
        y: top === 0 || top === maxTop ? dragState.origin.y : event.screenY,
      },
    }
    applyWindowPosition()
  }
  function onMouseUp(event: MouseEvent<HTMLElement>) {
    applyCursor('grab')
    if (!dragStateRef.current.origin) {
      return
    }
    event.stopPropagation()
    event.preventDefault()
    delete dragStateRef.current.origin
  }

  function setWindowRef(el: HTMLDivElement | null) {
    windowRef.current = el
    applyWindowPosition()
  }
  function setHeaderRef(el: HTMLDivElement | null) {
    headerRef.current = el
    applyCursor()
  }

  useEffect(() => {
    const moveListener = (event: any): any => {
      onMouseMove(event as MouseEvent<HTMLElement>)
    }
    document.body.addEventListener('mousemove', moveListener)
    const upListener = (event: any): any => {
      onMouseUp(event as MouseEvent<HTMLElement>)
    }
    document.body.addEventListener('mouseup', upListener)
    document.body.addEventListener('mouseleave', upListener)

    return () => {
      document.body.removeEventListener('mousemove', moveListener)
      document.body.removeEventListener('mouseup', upListener)
      document.body.removeEventListener('mouseleave', upListener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!open || centeredRef.current) {
      return
    }
    centerWindow()
  }, [open, centerWindow])

  return (
    <Portal container={() => document.body}>
      <Paper
        component="div"
        ref={setWindowRef}
        sx={{
          display: open ? 'block' : 'none',
          position: 'absolute',
          zIndex: 1400,
          width: 500,
          height: 400,
          maxWidth: '100%',
          maxHeight: '100%',
          overflow: 'hidden',
          borderRadius: '16px',
          boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.4)',
        }}
      >
        <Stack height="100%">
          <ViewHeader
            ref={setHeaderRef}
            title={title}
            exitButtonVisible={true}
            onClickExit={onClickExit}
            onMouseDown={onMouseDown}
          />
          <Box flex={1}>{children}</Box>
        </Stack>
      </Paper>
    </Portal>
  )
}
