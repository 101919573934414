import { useTranslation } from 'react-i18next'
import { IAuthorizationsChangeProps } from './AuthorizationsChange.types'
import { useSnackbar } from 'notistack'
import { Authorization } from '../../models/Authorization'
import * as dalPermission from '../../dal/DalPermission'
import { ActionType } from '../../shared/Constants'
import { Utils } from '../../shared/Utils'
import msgIds from '../../locales/msgIds'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { AuthorizationsList } from '../authorizationsList/AuthorizationsList'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import { AddIco, InfoIco } from '../icons'
import { InfoTooltip } from '../infoTooltip/InfoTooltip'

export function AuthorizationsChange(props: IAuthorizationsChangeProps): JSX.Element {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  function restoreLosedAuthorization(authorization: Authorization) {
    try {
      if (props.doxId) {
        const abortController = new AbortController()
        dalPermission.createPermission(abortController.signal, {
          profileId: authorization.profileId,
          action: ActionType.viewDox,
          targetId: props.doxId,
          startAt: new Date(),
        })
        const index = props.lostedAuthorizations.findIndex((p) => p === authorization)
        props.lostedAuthorizations.splice(index, 1)
        enqueueSnackbar(t(msgIds.MSG_DOX_UPDATED_SUCCESSFULLY), { variant: 'success' })
      }
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    }
  }

  function confirm() {
    props.onConfirm()
  }

  function cancel() {
    props.onCancel()
  }

  // ugly
  const stackProps: any = { ...props }
  delete stackProps.doxId
  delete stackProps.oldParentDoxId
  delete stackProps.newParentDoxId
  delete stackProps.doxIdToDelete
  delete stackProps.onConfirm
  delete stackProps.onCancel

  return (
    <Stack spacing={2} justifyContent="space-between" {...stackProps}>
      <Stack spacing={0}>
        <SubtitleWithInfoTooltip
          subtitle={t(msgIds.MSG_AUTHORIZATIONS_CHANGE_LOST_AUTH_LABEL)}
          info={
            <Typography style={{ whiteSpace: 'pre-wrap' }}>
              {t(msgIds.MSG_AUTHORIZATIONS_CHANGE_LOST_AUTH_INFO)}
            </Typography>
          }
        />
        <AuthorizationsList
          authorizations={props.lostedAuthorizations}
          actionsFor={(authorization) => {
            if (props.oldParentDoxId) {
              return [
                <IconButton
                  key={0}
                  onClick={() => restoreLosedAuthorization(authorization)}
                  title={`Compensazione autorizzazione che verrebbe persa da ${authorization.dstIdentity?.getIdentityInverse()}`}
                >
                  <AddIco />
                </IconButton>,
              ]
            } else {
              return []
            }
          }}
        />
      </Stack>

      {props.newParentDoxId && (
        <Stack spacing={0}>
          <SubtitleWithInfoTooltip
            subtitle={t(msgIds.MSG_AUTHORIZATIONS_CHANGE_GAINED_AUTH_LABEL)}
            info={
              <Typography style={{ whiteSpace: 'pre-wrap' }}>
                {t(msgIds.MSG_AUTHORIZATIONS_CHANGE_GAINED_AUTH_INFO)}
              </Typography>
            }
          />
          <AuthorizationsList authorizations={props.gainedAuthorizations} actionsFor={() => []} />
        </Stack>
      )}
      <ViewActions disablePadding>
        <ViewActionsButton defaultAction onClick={confirm}>
          {props.doxIdToDelete ? t(msgIds.MSG_CONTINUE) : t(msgIds.MSG_CONFIRM)}
        </ViewActionsButton>
        <ViewActionsButton onClick={cancel}>{t(msgIds.MSG_CANCEL)}</ViewActionsButton>
      </ViewActions>
    </Stack>
  )
}

interface ISubtitleWithInfoTooltipProps {
  subtitle: string
  info: JSX.Element
}

function SubtitleWithInfoTooltip(props: ISubtitleWithInfoTooltipProps): JSX.Element {
  return (
    <Stack spacing={1} direction="row">
      <Typography variant="subtitle2">{props.subtitle}</Typography>
      <InfoTooltip title={props.info} placement="top" arrow>
        <Box>
          <InfoIco />
        </Box>
      </InfoTooltip>
    </Stack>
  )
}
