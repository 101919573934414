import { PageStepper } from '../pageStepper/PageStepper'
import { PageStep } from '../pageStepper/PageStep'
import { Authorizations } from '../authorizations/Authorizations'
import { IAuthorizationsStepperProps } from './AuthorizationsStepper.types'
import { ViewHeader } from '../viewHeader/ViewHeader'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { StepperStateBase, useStackState } from '../../hooks/Stack'
import { Button, Stack, useTheme } from '@mui/material'
import { ViewContent } from '../viewContent/ViewContent'
import { Account } from '../../models/Account'
import { Dox } from '../../models/Dox'
import { Utils } from '../../shared/Utils'
import { Document } from '../../models/Document'
import { enqueueSnackbar } from 'notistack'
import AccountInfoStructure from '../accountInfoStructure/AccountInfoStructure'
import { Authorization } from '../../models/Authorization'
import { useArchiveContext } from '../../contexts/ArchiveContext'
import { useChange } from '../../hooks/Change'
import { AuthorizationInheritance } from '../authorizationInheritance/AuthorizationInheritance'
import log from '../../shared/Logger'
import { AccountsSearch } from '../accountsSearch/AccountsSearch'
import { PrivacyIco } from '../icons'
import { useAuthContext } from '../../contexts/AuthContext'
import ContractVersionViewer from '../contractVersionViewer/ContractVersionViewer'
import { ProfileInformations } from '../profileInformations/ProfileInformations'
import { AccountEditorContextProvider } from '../../contexts/AccountEditorContext'
import { ContractType, ProfileType } from '../../shared/Constants'

type StepperState = StepperStateBase & {
  structureAccount?: Account
  element?: Dox | Document
  authorization?: Authorization
  grantorProfileId?: number
  contractOwnerProfileId?: number
  informationsProfileId?: number
  informationsOwnerProfileId?: number
  mandatoryFieldsNames?: string[]
  optionalFieldsNames?: string[]
  newAuthorizedProfileId?: number
}

export function AuthorizationsStepper(props: IAuthorizationsStepperProps): JSX.Element {
  const { t } = useTranslation()
  const theme = useTheme()
  const authContext = useAuthContext()
  const stack = useStackState<StepperState>([])
  const archiveContext = useArchiveContext()

  useChange(() => {
    stack.setItems([{ step: 'authorizations', element: props.element }])
  }, [props.element])

  function onClickExit() {
    props.onClickExit()
  }

  function onClickAvatar(account: Account) {
    stack.push({ step: 'structure_details', structureAccount: account })
  }

  async function onAccountSelected(account: Account) {
    try {
      await Utils.authorizeStructureToViewElement(
        props.element,
        account,
        authContext.loggedAccount?.profile?.profilePreferences?.permissions?.defaultViewDurationDays
      )
      enqueueSnackbar(t(msgIds.MSG_AUTHORIZATION_ADDED_MESSAGE), { variant: 'success' })
      stack.pop({ newAuthorizedProfileId: account.profile?.profileId })
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    }
  }

  function onClickAuthorizationInfo(authorization: Authorization) {
    stack.push({ step: 'auth_inheritance', element: stack.head?.element, authorization: authorization })
  }

  function onShowDoxAuthorizations(doxId: number) {
    const dox = archiveContext.rwArchiveDoxes.getDox(doxId)
    if (!dox) {
      log.error('unable to find dox', { doxId })
      return
    }
    stack.push({ step: 'authorizations', element: dox })
  }

  async function onShowPrivacyPolicy(structureAccount: Account) {
    if (authContext.loggedAccount?.profile && structureAccount.profile?.profileId) {
      stack.push({
        step: 'privacy_policy',
        grantorProfileId: authContext.loggedAccount?.profile?.profileId,
        contractOwnerProfileId: structureAccount.profile?.profileId,
      })
    }
  }

  function onAddMissingInfo(ownerProfileId: number, mandatoryFieldsNames: string[], optionalFieldsNames: string[]) {
    stack.push({
      step: 'profile_informations',
      informationsProfileId: authContext.loggedAccount?.profile?.profileId,
      informationsOwnerProfileId: ownerProfileId,
      mandatoryFieldsNames: mandatoryFieldsNames,
      optionalFieldsNames: optionalFieldsNames,
    })
  }

  const backButtonVisible = stack.length > 1
  const headerTitle = stepTitle(stack.head)

  const stackProps: any = { ...props }
  delete stackProps.element
  delete stackProps.onClickExit

  return (
    <PageStepper {...stackProps} activeStep={stack.head?.step}>
      <ViewHeader
        title={t(headerTitle)}
        backButtonVisible={backButtonVisible}
        onClickBack={stack.pop}
        exitButtonVisible={true}
        onClickExit={onClickExit}
      />
      <PageStep step="authorizations">
        <ViewContent>
          {stack.head?.element && (
            <Authorizations
              element={stack.head?.element}
              newAuthorizeProfileId={stack.head?.newAuthorizedProfileId}
              onClickAddStructure={() => stack.push({ step: 'structure_search', element: stack.head?.element })}
              onClickAuthorizationInfo={onClickAuthorizationInfo}
              onShowPrivacyPolicy={onShowPrivacyPolicy}
              onClickAvatar={onClickAvatar}
              style={{ flexGrow: 1 }}
            />
          )}
        </ViewContent>
      </PageStep>
      <PageStep step="structure_search">
        <ViewContent display="flex" flexDirection="column" disablePadding={true}>
          {stack.head?.element && (
            <AccountsSearch
              searchOption="structures"
              onCancel={stack.pop}
              onAccountSelected={onAccountSelected}
              onClickAvatar={onClickAvatar}
              goToAccountPageOption={false}
              showPrivacyPolicy={false}
            />
          )}
        </ViewContent>
      </PageStep>
      <PageStep step="structure_details">
        <ViewContent>
          {stack.head?.structureAccount && (
            <Stack>
              <Button
                variant="contained"
                sx={{ textTransform: 'none', alignSelf: 'flex-start', color: theme.palette.common.gray11 }}
                onClick={() => stack.head?.structureAccount && onShowPrivacyPolicy(stack.head?.structureAccount)}
              >
                <PrivacyIco />
                <span style={{ width: '5px' }} />
                {t(msgIds.MSG_CONTRACT_PRIVACY_POLICY)}
              </Button>
              <AccountInfoStructure account={stack.head.structureAccount} />
            </Stack>
          )}
        </ViewContent>
      </PageStep>
      <PageStep step="privacy_policy">
        <ViewContent disablePadding={true} overflow={'auto'}>
          {stack.head?.contractOwnerProfileId && stack.head?.grantorProfileId && (
            <ContractVersionViewer
              isEditable={true}
              sectionsEditability={
                stack.head?.contractOwnerProfileId === 0 ? 'allExceptMandatory' : 'allExceptNotRevocable'
              }
              contractOwnerProfileId={stack.head?.contractOwnerProfileId}
              contractType={ContractType.privacyPolicy}
              targetProfileType={ProfileType.customer}
              grantorProfileId={stack.head?.grantorProfileId}
              onAddMissingInfo={(mandatoryFieldsNames, optionalFieldsNames) => {
                if (!stack.head?.contractOwnerProfileId) return
                onAddMissingInfo(stack.head?.contractOwnerProfileId, mandatoryFieldsNames, optionalFieldsNames)
              }}
              disablePadding={true}
              onSaved={() => {
                stack.pop()
              }}
              onCanceled={() => {
                stack.pop()
              }}
            />
          )}
        </ViewContent>
      </PageStep>
      <PageStep step="profile_informations">
        <ViewContent disablePadding={true} overflow={'auto'}>
          {stack.head?.informationsProfileId && (
            <AccountEditorContextProvider>
              <ProfileInformations
                isInDialog={true}
                profileId={stack.head?.informationsProfileId}
                ownerProfileId={stack.head?.informationsOwnerProfileId}
                mandatoryFieldsNames={stack.head?.mandatoryFieldsNames}
                optionalFieldsNames={stack.head?.optionalFieldsNames}
              />
            </AccountEditorContextProvider>
          )}
        </ViewContent>
      </PageStep>
      <PageStep step="auth_inheritance">
        <ViewContent>
          {stack.head?.element && stack.head.authorization && (
            <AuthorizationInheritance
              element={stack.head.element}
              authorization={stack.head.authorization}
              onShowDoxAuthorizations={onShowDoxAuthorizations}
            />
          )}
        </ViewContent>
      </PageStep>
    </PageStepper>
  )
}

function stepTitle(step: any) {
  switch (step) {
    case 'main':
      return msgIds.MSG_AUTHORIZATIONS
    case 'structure_search':
      return msgIds.MSG_AUTHORIZATIONS
    case 'structure_details':
      return msgIds.MSG_AUTHORIZATIONS
    case 'auth_inheritance':
      return msgIds.MSG_AUTHORIZATION_DETAILS_DIALOG_TITLE
    case 'privacy_policy':
      return msgIds.MSG_CONTRACT_PRIVACY_POLICY
    case 'profile_informations':
      return msgIds.MSG_PROFILE_INFORMATIONS
    default:
      return msgIds.MSG_AUTHORIZATIONS
  }
}
