import { Autocomplete, TextField } from '@mui/material'
import * as dalAccount from '../../dal/DalAccount'
import { useTranslation } from 'react-i18next'
import { IProfileSearchInput } from './ProfileSearchInput.types'
import { useEffect, useMemo, useState } from 'react'
import { Account, AccountInfo } from '../../models/Account'
import { AccountSearchTypes } from '../../shared/Constants'
import { useAuthContext } from '../../contexts/AuthContext'
import log from '../../shared/Logger'
import AccountIdentity from '../identities/AccountIdentity'
import { PaginatedResponse } from '../../shared/types/PaginatedResponse'
import { Utils } from '../../shared/Utils'

export default function ProfileSearchInput(props: IProfileSearchInput): JSX.Element {
  const { t } = useTranslation()
  const authContext = useAuthContext()
  const [query, setQuery] = useState<string>('')
  const [openAutocomplete, setOpenAutocomplete] = useState(false)
  const [page, setPage] = useState<number>(0)
  const [pages, setPages] = useState<{ [page: number]: PaginatedResponse<Account> }>([])
  const [hasMore, setHasMore] = useState<boolean>(false)
  const [isTriggerVisible, setIsTriggerVisible] = useState(false)
  const [observer, setObserver] = useState<IntersectionObserver | null>(null)

  function onLoadTrigger(event: any, details: any) {
    if (observer && accounts.length - 3 - details.index === 0) {
      observer.disconnect()
      observer.observe(event.currentTarget)
    }
  }

  useEffect(() => {
    const o = new IntersectionObserver((entries) => {
      const visible = entries.find((entry) => entry.intersectionRatio > 0)
      if (visible) {
        log.debug({ visible })
        setIsTriggerVisible(true)
      }
    }, {})
    setObserver(o)

    return () => {
      o.disconnect()
    }
  }, [])

  function handleAccountPage(accountsPage: PaginatedResponse<Account>) {
    log.debug({ accountsPage })
    if (accountsPage.page === 0) {
      setPages({ 0: accountsPage })
    } else {
      setPages((pages) => {
        return { ...pages, [accountsPage.page]: accountsPage }
      })
    }
    setIsTriggerVisible(false)
    setHasMore(accountsPage.hasMore)
  }

  const accounts: Account[] = useMemo(() => {
    return Object.keys(pages)
      .map((k) => parseInt(k, 10))
      .toSorted()
      .flatMap((k) => pages[k].rows)
  }, [pages])

  useEffect(() => {
    log.debug({ isTriggerVisible, hasMore })
    if (!isTriggerVisible || !hasMore) {
      return
    }
    setPage((p) => p + 1)
  }, [hasMore, isTriggerVisible])

  useEffect(() => {
    log.debug({ useEffect: 'reset page and hasMore' })
    setHasMore(false)
    setPage(0)
  }, [query, props.searchOption, authContext.linkedStructureProfileId])

  useEffect(() => {
    const loadAccounts = async () => {
      try {
        if (abortController.signal.aborted) {
          log.debug({ msg: 'Skipped calling loading accounts because of abort', page })
          return
        }
        log.debug({ loadAccounts: 'Loading accounts', page })
        const accountsPage = await getAccounts(
          abortController.signal,
          props.searchOption,
          cleanQuery,
          page,
          authContext.linkedStructureProfileId
        )
        if (abortController.signal.aborted) {
          log.debug({ msg: 'Skipped loading accounts because of abort', page })
          return
        }
        if (accountsPage) {
          handleAccountPage(accountsPage)
        }
      } catch (err) {
        Utils.enqueueSnackbarError2(err, t)
      }
    }

    log.debug({ useEffect: 'load accounts', query })
    const cleanQuery = query.replace(/\s/g, '')
    if (cleanQuery.length === 0) {
      setPages({})
      return
    }
    const abortController = new AbortController()
    let timeoutId: any = 0
    if (page === 0) {
      setPages({})
      log.debug({ msg: 'Scheduled loading accounts', page })
      timeoutId = setTimeout(loadAccounts, 300)
    } else {
      loadAccounts()
    }

    return () => {
      log.debug({ msg: 'Aborting loading accounts', page })
      abortController.abort()
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [page, query, props.searchOption, authContext.linkedStructureProfileId])

  function onChangeValue(value: (string | Account)[], reason: string) {
    if (!props.onChangeSelection) {
      return
    }
    if (reason === 'blur') {
      props.onChangeSelection(props.selection)
      return
    }
    if (value.length !== 0 && typeof value[0] === 'string') {
      if (reason === 'createOption' && accounts.length !== 0) {
        props.onChangeSelection([accounts[0]])
      } else {
        props.onChangeSelection(props.selection)
      }
    } else {
      props.onChangeSelection(value as Account[])
    }
  }

  function onClickAccount(account: Account) {
    if (props.onClickAvatar) {
      props.onClickAvatar(account)
    } else {
      onChangeValue([account], 'selectOption')
    }
  }

  return (
    <Autocomplete
      freeSolo
      open={accounts.length !== 0 && openAutocomplete}
      onFocus={() => setOpenAutocomplete(true)}
      onBlur={() => setOpenAutocomplete(false)}
      onClose={() => setQuery('')}
      onInputChange={(_event, input) => setQuery(input)}
      autoSelect={false}
      clearOnBlur={true}
      blurOnSelect={true}
      value={props.selection.length !== 0 ? props.selection[0] : null}
      onChange={(_, account, reason) => onChangeValue(account ? [account] : [], reason)}
      options={accounts}
      getOptionLabel={(account: any): string => {
        if (Array.isArray(account) && account.length !== 0) {
          return account[0].getIdentity()
        } else if (account.getIdentity) {
          return account.getIdentity()
        } else {
          log.debug({ getOptionLabelError: account })
          return ''
        }
      }}
      renderOption={(liProps, account, details) => {
        return (
          <li {...liProps} style={{ cursor: 'pointer' }} onLoad={(event) => onLoadTrigger(event, details)}>
            <AccountIdentity
              account={account}
              infoToShow={getAccountInfoToShow(props.searchOption)}
              showProfileInfo={true}
              avatarClicked={onClickAccount}
            />
          </li>
        )
      }}
      renderInput={(params) => {
        return <TextField autoFocus margin="dense" label={props.label} type="text" variant="standard" {...params} />
      }}
    />
  )
}

async function getAccounts(
  abortSignal: AbortSignal,
  option: AccountSearchTypes,
  query: string,
  page: number,
  linkedStructureProfileId?: number
) {
  switch (option) {
    case 'structures':
      return dalAccount.getStructureAccounts(abortSignal, page, 15, query)
    case 'operators':
      return dalAccount.getOperatorAccounts(abortSignal, page, 15, query)
    case 'collaborators':
      if (linkedStructureProfileId) {
        return dalAccount.getCollaboratorAccounts(abortSignal, page, 15, linkedStructureProfileId, query)
      } else {
        // TODO buro
        throw new Error('getAccounts no linkedStructureProfileId')
      }
    case 'customers':
      return dalAccount.getCustomerAccounts(abortSignal, page, 15, query)
  }
}

function getAccountInfoToShow(option: AccountSearchTypes): AccountInfo[] {
  switch (option) {
    case 'structures':
      return ['main']
    case 'operators':
      return ['main', 'linked']
    case 'collaborators':
      return ['main', 'principal']
    case 'customers':
      return ['main']
    default:
      return []
  }
}
