import { ProfileType, ProfileType_all, ProfileVisibility, ProfileVisibility_all } from '../shared/Constants'
import { FePreferences } from './FePreferences'
import { IProfilePreferencesJson, ProfilePreferences } from './ProfilePreferences'

export interface IProfileJson {
  user_id: number
  profile_id: number
  res_id: string
  type: number
  title: string
  avatar_image: string
  alias: string
  feature_set_id: number
  linked_profile_id: number
  principal_profile_id: number
  visibility: number
  expired_at: string
  preferences?: IProfilePreferencesJson
  dashboard_preferences?: string
}

export class Profile {
  userId: number = 0
  profileId: number = 0
  resId: string = ''
  type: ProfileType_all = ProfileType.none
  title: string = ''
  avatarImage: string = ''
  alias: string = ''
  featureSetId: number = 0
  linkedProfileId: number = 0
  principalProfileId: number = 0
  visibility: ProfileVisibility_all = ProfileVisibility.none
  expiredAt: string = ''
  profilePreferences?: ProfilePreferences
  fePreferences?: FePreferences

  constructor() {}

  public static serialize(obj: Profile): IProfileJson {
    return {
      user_id: obj.userId,
      profile_id: obj.profileId,
      res_id: obj.resId,
      type: obj.type,
      title: obj.title,
      avatar_image: obj.avatarImage,
      alias: obj.alias,
      feature_set_id: obj.featureSetId,
      linked_profile_id: obj.linkedProfileId,
      principal_profile_id: obj.principalProfileId,
      visibility: obj.visibility,
      expired_at: obj.expiredAt,
      preferences: obj.profilePreferences ? ProfilePreferences.serialize(obj.profilePreferences) : undefined,
      dashboard_preferences: obj.fePreferences ? JSON.stringify(FePreferences.serialize(obj.fePreferences)) : undefined,
    }
  }

  public static deserialize(json: IProfileJson): Profile {
    const res = new Profile()
    res.userId = json.user_id
    res.profileId = json.profile_id
    res.resId = json.res_id
    res.type = json.type
    res.title = json.title
    res.avatarImage = json.avatar_image
    res.alias = json.alias
    res.featureSetId = json.feature_set_id
    res.linkedProfileId = json.linked_profile_id
    res.principalProfileId = json.principal_profile_id
    res.visibility = json.visibility
    res.expiredAt = json.expired_at
    res.profilePreferences = json.preferences ? ProfilePreferences.deserialize(json.preferences) : undefined
    try {
      if (json.dashboard_preferences) {
        const dp = JSON.parse(json.dashboard_preferences)
        res.fePreferences = FePreferences.deserialize(dp)
      }
    } catch (err) {
      res.fePreferences = undefined
    }
    return res
  }
}
