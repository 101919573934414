import { SxProps, Theme } from '@mui/material'
import { ReactNode, createContext, useContext } from 'react'

export type TutorialStep = {
  markdown: string
  icon?: ReactNode
}

export type Tutorial = {
  id: string
  icon: ReactNode
  title: string
  steps: TutorialStep[]
  stepIndex: number
}

export type TutorialAction = 'previous' | 'stop' | 'next'

export interface ITutorialContextState {
  tutorials: Tutorial[]
  showTutorial: (tutorialId: string) => void
  updateTutorial: (tutorialId: string, action: TutorialAction) => void
}

export const TutorialContext = createContext<ITutorialContextState>({
  tutorials: [],
  showTutorial: () => {},
  updateTutorial: () => {},
})

export function useTutorialContext() {
  return useContext(TutorialContext)
}

export const iconProps: SxProps<Theme> = { height: 40, width: 40 }
