import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import it from './locales/it'
import en from './locales/en'

i18n.use(initReactI18next).init({
  lng: 'it',
  fallbackLng: 'it',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    it: {
      translation: it,
    },
    en: {
      translation: en,
    },
  },
})

export default i18n
