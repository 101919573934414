import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function StopIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 15.999999,7.9999998 V 15.999999 H 7.9999998 V 7.9999998 h 7.9999992 m 2.000001,-2 H 5.9999998 V 18 H 18 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
