import { Button, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { useNotificationsContext } from '../../contexts/NotificationsContext'
import { INotificationsCommandBarProps } from './NotificationsCommandsBar.types'
import { useAuthContext } from '../../contexts/AuthContext'
import { isConsumer } from '../../shared/Constants'

export function NotificationsCommandBar(props: INotificationsCommandBarProps): JSX.Element {
  const { onGoTo } = props
  const { t } = useTranslation()
  const authContext = useAuthContext()
  const { archiveAllNotPinned } = useNotificationsContext()

  function onArchiveAll() {
    archiveAllNotPinned()
  }

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" px={3} py={2}>
      <Button onClick={() => onGoTo('/notifications')} style={{ textTransform: 'none' }} variant="text">
        {t(msgIds.MSG_NOTIFICATIONS_ARCHIVE_LINK)}
      </Button>
      {isConsumer(authContext.loggedProfileType) && (
        <Button
          onClick={() =>
            onGoTo('/account_configurations', {
              profileId: authContext.loggedProfileId ?? 0,
              scrollTo: 'notificationsSettings',
            })
          }
          style={{ textTransform: 'none' }}
          variant="text"
        >
          {t(msgIds.MSG_NOTIFICATIONS_SETTINGS_LINK)}
        </Button>
      )}
      <Button onClick={onArchiveAll} style={{ textTransform: 'none' }} variant="text">
        {t(msgIds.MSG_NOTIFICATIONS_ARCHIVE_ALL_BUTTON)}
      </Button>
    </Stack>
  )
}
