import { Box, Button, Stack, SxProps, Theme, ThemeProvider, Typography, useTheme } from '@mui/material'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'
import { getProfileBasedTheme } from '../../themes/ThemeUtils'
import { AppType, AppType_all, ProfileType } from '../../shared/Constants'
import ProIcon from '../../assets/images/pro.svg'
import UserIcon from '../../assets/images/user.svg'

export interface ILoginRegistrationBoxProps {
  appType: AppType_all
  sx?: SxProps<Theme>
  onLogin: () => void
  onRegister: () => void
}

export function LoginRegistrationBox(props: ILoginRegistrationBoxProps): JSX.Element {
  const { appType, onLogin, onRegister, sx } = props
  const theme = getProfileBasedTheme(appType === AppType.business ? ProfileType.operatorAdmin : ProfileType.customer)
  const { t } = useTranslation()
  return (
    <ThemeProvider theme={theme}>
      <Stack gap={2} alignItems="stretch" sx={sx}>
        <Box
          sx={{
            width: '100%',
            height: 100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={appType === AppType.business ? ProIcon : UserIcon} alt="Icon" style={{ height: '100%' }} />
        </Box>
        <Typography textAlign="center">
          {appType === AppType.business ? t(msgIds.MSG_USER_BUSINESS) : t(msgIds.MSG_USER_PRIVATE)}
        </Typography>
        <Button onClick={onLogin} variant="contained">
          {t(msgIds.MSG_LOGIN_BUTTON_TITLE)}
        </Button>
        <Button onClick={onRegister} variant="outlined">
          {t(msgIds.MSG_REGISTER_BUTTON_TITLE)}
        </Button>
      </Stack>
    </ThemeProvider>
  )
}
