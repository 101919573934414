import { useMemo, useState } from 'react'
import { Notifications } from '../../components/notifications/Notifications'
import { Account } from '../../models/Account'
import { StyledDialog } from '../../dialogs/styledDialog/StyledDialog'
import { useTranslation } from 'react-i18next'
import {
  ContractType,
  NotificationArgumentTranslationMap,
  NotificationArgumentType,
  ProfileType,
} from '../../shared/Constants'
import msgIds from '../../locales/msgIds'
import CommandBar from '../../components/commandBar/CommandBar'
import { Divider, Paper, Stack, Breakpoint } from '@mui/material'
import { PageContainer, PageContent } from '../../components/pageContainer/PageContainer'
import { FiltersBar, FiltersBarFilter } from '../../components/filtersBar/FiltersBar'
import { Utils, dateShortOptions } from '../../shared/Utils'
import { INotificationsFilters } from '../../components/notificationsFiltersForm/NotificationsFiltersForm.types'
import { NotificationsFiltersForm } from '../../components/notificationsFiltersForm/NotificationsFiltersForm'
import { useNotificationsContext } from '../../contexts/NotificationsContext'
import { useNavigate } from 'react-router'
import {
  NotificationsStepper,
  NotificationStepperState,
} from '../../components/notificationsStepper/NotificationsStepper'
import { ContractVersion } from '../../models/ContractVersion'
import { Document } from '../../models/Document'
import { Dox } from '../../models/Dox'
import { Treatment } from '../../models/Treatment'

type BarFilter = FiltersBarFilter & {
  key: string
}

export default function NotificationsPage(): JSX.Element {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { filters, setArchivedFilters } = useNotificationsContext()
  const [openFiltersDialog, setOpenFiltersDialog] = useState(false)
  const [showNotifications, setShowNotifications] = useState(false)
  const [notificationStepperState, setNotificationStepperState] = useState<NotificationStepperState>()
  const [notificationDialogMaxWidth, setNotificationDialogMaxWidth] = useState<Breakpoint>('sm')

  function onClickAvatar(account: Account) {
    setNotificationStepperState({ step: 'account', account })
    setShowNotifications(true)
  }

  function onDoxDetails(dox: Dox, treatment?: Treatment) {
    setNotificationStepperState({
      step: 'dox_details',
      dox,
      treatment,
    })
    setShowNotifications(true)
  }

  function onShowDocumentDetails(document: Document, revisionId?: number) {
    setNotificationStepperState({
      step: 'document_details',
      document,
      revisionId,
    })
    setShowNotifications(true)
  }

  function onShowContractVersion(
    contractOwnerProfileId: number,
    contractType: ContractType,
    targetProfileType: ProfileType,
    contractVersion: ContractVersion
  ) {
    setNotificationStepperState({
      step: 'contract_version',
      contractOwnerProfileId,
      contractType,
      targetProfileType,
      contractVersion,
    })
    setShowNotifications(true)
  }

  const filtersBar = useMemo(() => {
    const filtersList: BarFilter[] = []
    if (filters.fromDate) {
      filtersList.push({
        key: 'fromDate',
        keyLabel: t(msgIds.MSG_NOTIFICATIONS_FILTER_FROM_KEY),
        valueLabel: Utils.toLocaleDateString(filters.fromDate, i18n, dateShortOptions) || filters.fromDate.toString(),
      })
    }
    if (filters.toDate) {
      filtersList.push({
        key: 'toDate',
        keyLabel: t(msgIds.MSG_NOTIFICATIONS_FILTER_TO_KEY),
        valueLabel: Utils.toLocaleDateString(filters.toDate, i18n, dateShortOptions) || filters.toDate.toString(),
      })
    }
    if (filters.argumentType) {
      filtersList.push({
        key: 'argumentType',
        keyLabel: t(msgIds.MSG_NOTIFICATIONS_FILTER_ARGUMENT),
        valueLabel: t(NotificationArgumentTranslationMap[filters.argumentType]),
      })
    }
    if (filters.authorAccount) {
      filtersList.push({
        key: 'authorAccount',
        keyLabel: t(msgIds.MSG_NOTIFICATIONS_FILTER_OPERATOR_KEY),
        valueLabel: filters.authorAccount.getIdentity(),
      })
    }
    if (filters.authorLinkedAccount) {
      filtersList.push({
        key: 'authorLinkedAccount',
        keyLabel: t(msgIds.MSG_NOTIFICATIONS_FILTER_STRUCTURE_KEY),
        valueLabel: filters.authorLinkedAccount.getIdentity(),
      })
    }
    return filtersList
  }, [filters, t, i18n])

  function closeFiltersDialog() {
    setOpenFiltersDialog(false)
  }

  function onChangeFilters(filters: INotificationsFilters) {
    setArchivedFilters(filters)
    closeFiltersDialog()
  }

  function onDeleteFilter(filter: BarFilter) {
    const copy = { ...filters }
    if (filter.key === 'argumentType') {
      copy.argumentType = NotificationArgumentType.none
    } else {
      delete (copy as any)[filter.key]
    }
    setArchivedFilters(copy)
  }

  return (
    <PageContainer>
      <CommandBar style={{ minHeight: 'auto' }} title={t(msgIds.MSG_NOTIFICATIONS_ARCHIVE_TITLE)} commands={[]} />
      <PageContent alignItems="center">
        <Paper
          sx={{
            width: '100%',
            maxWidth: 'md',
            display: 'flex',
            overflow: 'hidden',
            flexDirection: 'column',
            gap: 0,
            padding: 0,
          }}
        >
          <Stack direction="row" padding={1}>
            <FiltersBar
              filters={filtersBar}
              onClickFilters={() => setOpenFiltersDialog(true)}
              onClickDeleteFilter={onDeleteFilter}
            />
          </Stack>
          <Divider />
          <Stack overflow="auto">
            <Notifications
              maxWidth="md"
              filter="archived"
              onClickAvatar={onClickAvatar}
              onCloseDialog={() => {}}
              onShowDoxDetails={onDoxDetails}
              onShowDocumentDetails={onShowDocumentDetails}
              onShowContractVersion={onShowContractVersion}
            />
          </Stack>
        </Paper>
      </PageContent>

      <StyledDialog open={openFiltersDialog} onClose={closeFiltersDialog}>
        <NotificationsFiltersForm
          sx={{ flex: 1 }}
          filters={filters}
          onChangeFilters={onChangeFilters}
          onCancel={closeFiltersDialog}
        />
      </StyledDialog>

      <StyledDialog
        open={showNotifications}
        onClose={() => setShowNotifications(false)}
        minHeight={100}
        maxWidth={notificationDialogMaxWidth}
      >
        <NotificationsStepper
          notificationStepperState={notificationStepperState}
          onGoTo={(path, state) => {
            navigate(path, { state: state })
            setShowNotifications(false)
          }}
          onClickExit={() => setShowNotifications(false)}
          setMaxWidth={setNotificationDialogMaxWidth}
        />
      </StyledDialog>
    </PageContainer>
  )
}
