import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'

export interface IFileContentProps {
  mimetype: string
  filename: string
  data: string
}

const elementStyle = {
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
  overflow: 'scroll',
}
export function FileContent(props: IFileContentProps): JSX.Element {
  const { t } = useTranslation()
  const { mimetype, filename, data } = props
  if (['image/jpeg', 'image/png'].includes(mimetype)) {
    return <img style={elementStyle} src={data} alt={filename} />
  } else if (['application/pdf'].includes(mimetype)) {
    return <object data={data} aria-label={filename} type={mimetype} width="100%" height="100%" style={elementStyle} />
  } else {
    return <Typography>{t(msgIds.MSG_DOCUMENT_EDITOR_NOT_AVAILABLE_VIEWER)}</Typography>
  }
}
