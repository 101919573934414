import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function DetailsIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 3.0102538,1.9841292 V 22.014403 H 5.939209 l 2.0302734,-2.030274 1.9995117,2.030274 2.0017089,-2.030274 1.999512,2.030274 1.999511,-2.030274 1.999512,2.030274 h 3.02124 V 1.9841292 H 17.940673 L 16.000488,3.9858382 13.970215,1.9841292 11.970703,3.9858382 9.9689941,1.9841292 7.9694824,3.9858382 5.939209,1.9841292 Z m 1.9995119,2.001709 h 0.109863 L 6.5654296,5.4096663 7.9804687,6.8005354 9.3845215,5.4008772 9.9689941,4.8098128 10.555664,5.4008772 11.970703,6.8093245 13.385742,5.4008772 13.979004,4.8010237 14.594238,5.4096663 16.035645,6.8247054 17.435302,5.37451 18.784423,3.9858382 h 0.206544 V 20.014891 h -0.186768 l -1.408447,-1.434814 -1.426026,-1.445801 -1.423828,1.445801 -0.575683,0.584472 -0.575684,-0.584472 -1.423828,-1.445801 -1.426025,1.445801 -0.5756839,0.584472 -0.5734862,-0.584472 -1.4150392,-1.434815 -1.4260254,1.423829 -1.4436034,1.4458 H 5.0097657 Z M 10.984131,8.956053 v 1.999512 h 6.000732 V 8.956053 Z M 6.9851074,8.9846175 V 10.984129 H 8.9846191 V 8.9846175 Z m 0,4.0056155 v 1.999511 h 1.9995117 v -1.999511 z m 3.9990236,0 v 1.999511 h 6.000732 v -1.999511 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
