import { INotificationContextDoxIdsJson, NotificationContextDoxIds } from './NotificationContextDoxIds'

export interface INotificationContextFlattenDoxJson {
  flatten_dox_ids: INotificationContextDoxIdsJson[]
}

export class NotificationContextFlattenDox {
  flattenDoxIds: NotificationContextDoxIds[] = []

  constructor() {}

  public static serialize(obj: NotificationContextFlattenDox): INotificationContextFlattenDoxJson {
    return {
      flatten_dox_ids: NotificationContextDoxIds.serializeArray(obj.flattenDoxIds),
    }
  }

  public static deserialize(json: INotificationContextFlattenDoxJson): NotificationContextFlattenDox {
    const res = new NotificationContextFlattenDox()
    res.flattenDoxIds = NotificationContextDoxIds.deserializeArray(json.flatten_dox_ids)
    return res
  }
}
