import { Box, Breakpoint, Dialog } from '@mui/material'
import { useLayout } from '../../hooks/Layout'
import { IStyledDialogProps } from './StyledDialog.types'

export function StyledDialog(props: IStyledDialogProps): JSX.Element {
  const { minHeight, ...rest } = props
  const { isMobile } = useLayout()

  const minH = minHeight ?? 400

  return (
    <Dialog
      PaperProps={{ sx: { borderRadius: isMobile ? '0px' : '16px' } }}
      maxWidth={'sm' as Breakpoint}
      fullWidth={true}
      fullScreen={isMobile}
      {...rest}
    >
      <Box
        sx={{
          minHeight: minH,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        flexGrow={1}
      >
        {props.children}
      </Box>
    </Dialog>
  )
}
