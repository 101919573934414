import { ListItemIcon, ListItemText, Menu, MenuItem, PopoverProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Tutorial, useTutorialContext } from '../../contexts/TutorialContext'

export type ITutorialsMenuProps = {
  anchorEl?: PopoverProps['anchorEl']
  open: boolean
  onClose: () => void
}

export function TutorialsMenu(props: ITutorialsMenuProps): JSX.Element {
  const { open, onClose, anchorEl } = props
  const { t } = useTranslation()
  const { tutorials, showTutorial } = useTutorialContext()
  function onClickTutorial(tutorial: Tutorial) {
    showTutorial(tutorial.id)
    onClose()
  }
  return (
    <Menu
      sx={{ mt: '45px' }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={onClose}
    >
      {tutorials.map((tutorial) => (
        <MenuItem key={tutorial.title} onClick={() => onClickTutorial(tutorial)}>
          <ListItemIcon>{tutorial.icon}</ListItemIcon>
          <ListItemText>{t(tutorial.title)}</ListItemText>
        </MenuItem>
      ))}
    </Menu>
  )
}
