import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

export function CircularProgressWithLabel(props: CircularProgressProps & { label: number }) {
  const variant = props.value === 0 ? 'indeterminate' : 'determinate'
  return (
    <Box padding={1} sx={{ display: 'inline-flex', position: 'relative' }}>
      <CircularProgress variant={variant} {...props} />
      {props.label > 0 && (
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div">{`${Math.round(props.label)}%`}</Typography>
        </Box>
      )}
    </Box>
  )
}
