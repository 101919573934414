import { Stack } from '@mui/material'
import { IPageStepperProps } from './PageStepper.types'
import { PageStepperContext } from './PageStepperContext'

export function PageStepper(props: IPageStepperProps): JSX.Element {
  const { activeStep } = props

  const stackProps = { ...props }
  delete stackProps.activeStep

  return (
    <Stack direction="column" overflow="hidden" {...stackProps}>
      <PageStepperContext.Provider value={{ activeStep }}>{props.children}</PageStepperContext.Provider>
    </Stack>
  )
}
