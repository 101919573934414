import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function NotificationSystemIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 11.922363,1.9973128 A 1.395835,1.395835 0 0 0 10.5271,3.3925765 V 4.5087874 A 6.41427,6.41427 0 0 0 5.4074707,10.836912 v 5.583252 l -1.861084,1.861084 v 0.931641 H 20.296143 V 18.281248 L 18.435058,16.420164 V 10.836912 A 6.4913049,6.4913049 0 0 0 13.317627,4.5087874 V 3.3925765 A 1.395835,1.395835 0 0 0 11.922363,1.9973128 Z m 0,4.185791 a 4.6527799,4.6527799 0 0 1 4.651611,4.6538082 v 6.512695 H 7.2685546 V 10.836912 A 4.6527799,4.6527799 0 0 1 11.922363,6.1831038 Z m -0.93164,1.861084 v 3.7221682 h 1.861084 V 8.0441878 Z m 0,5.5832522 v 1.861084 h 1.861084 V 13.62744 Z m -0.929444,6.514893 a 1.86658,1.86658 0 0 0 1.861084,1.861083 1.86658,1.86658 0 0 0 1.861084,-1.861083 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
