import { Card, SxProps, Theme, useTheme } from '@mui/material'
import { IInputContainerProps } from './InputContainer.types'

export function InputContainer(props: IInputContainerProps): JSX.Element {
  const { variant, sx, children } = props
  const theme = useTheme()
  const defaultSx: SxProps<Theme> =
    (variant || 'list') === 'list'
      ? {
          height: 300,
          overflow: 'auto',
        }
      : {}
  return (
    <Card
      variant="outlined"
      sx={{
        borderColor: theme.palette.common.gray6,
        ...defaultSx,
        ...sx,
      }}
    >
      {children}
    </Card>
  )
}
