import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function RedoIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 11.5,7.9999998 c 2.65,0 5.049999,0.99 6.9,2.6000002 L 22,6.9999999 V 15.999999 H 13 L 16.62,12.38 C 15.229999,11.22 13.46,10.5 11.5,10.5 c -3.5399999,0 -6.5499998,2.31 -7.5999997,5.499999 l -2.3699999,-0.78 C 2.9200003,11.03 6.8500003,7.9999998 11.5,7.9999998 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
