import { ProfileType, RetentionRules, RetentionRules_all } from '../shared/Constants'
import { Utils } from '../shared/Utils'

export interface IDoxJson {
  id: number
  is_diary: boolean
  name: string
  notes: string
  parent_id: number
  owner_profile_id: number
  target_profile_id: number
  treatment_id?: number
  retention_rules?: number
  retention_start_at?: string
  retention_end_at?: string
  retention_end_behavior: number
  retention_notice_at?: string
  retention_inherited_from: number
  content_start_at?: string
  content_end_at?: string
  content_dox_count: number
  content_document_count: number
  content_draft_document_count: number
  delivered_at?: string
  delivered_by_profile_id: number
  delivered_by_identity: string
  created_at: string
  updated_at: string
  children: IDoxJson[]
}

export class Dox {
  id: number = 0
  isRoot: boolean = false
  name: string = ''
  notes: string = ''
  parentId: number = 0
  ownerProfileId: number = 0
  targetProfileId: number = 0
  treatmentId?: number = undefined
  retentionRules?: RetentionRules_all = RetentionRules.none
  retentionStartAt?: Date
  retentionEndAt?: Date
  retentionEndBehavior: number = 0
  retentionNoticeAt?: Date
  retentionInheritedFrom: number = 0
  contentStartAt?: Date
  contentEndAt?: Date
  contentDoxCount: number = 0
  contentDocumentCount: number = 0
  contentDraftDocumentCount: number = 0
  deliveredAt?: Date
  deliveredByProfileId: number = 0
  deliveredByIdentity: string = ''
  createdAt: Date = new Date(0)
  updatedAt: Date = new Date(0)
  children: Dox[] = []

  ownerProfileType: ProfileType = ProfileType.none
  archiveName: string = ''
  parentsRoute: string = ''
  parentsRouteWithRoot: string = ''
  completeRoute: string = ''

  _isRetentionEnabled: boolean = false
  get isRetentionEnabled(): boolean {
    return !!this.treatmentId || !!this.retentionInheritedFrom
  }

  constructor() {}

  public static serialize(obj: Dox): IDoxJson {
    return {
      id: obj.id,
      is_diary: obj.isRoot,
      name: obj.name,
      notes: obj.notes,
      parent_id: obj.parentId,
      owner_profile_id: obj.ownerProfileId,
      target_profile_id: obj.targetProfileId,
      treatment_id: obj.treatmentId,
      retention_rules: obj.retentionRules,
      retention_start_at: obj.retentionStartAt ? (Utils.toDate(obj.retentionStartAt, true) as string) : undefined,
      retention_end_at: obj.retentionEndAt ? (Utils.toDate(obj.retentionEndAt, true) as string) : undefined,
      retention_end_behavior: obj.retentionEndBehavior,
      retention_notice_at: obj.retentionNoticeAt ? (Utils.toDate(obj.retentionNoticeAt, true) as string) : undefined,
      retention_inherited_from: obj.retentionInheritedFrom,
      content_start_at: obj.contentStartAt?.toISOString(),
      content_end_at: obj.contentEndAt?.toISOString(),
      content_dox_count: obj.contentDoxCount,
      content_document_count: obj.contentDocumentCount,
      content_draft_document_count: obj.contentDraftDocumentCount,
      delivered_at: obj.deliveredAt?.toISOString(),
      delivered_by_profile_id: obj.deliveredByProfileId,
      delivered_by_identity: obj.deliveredByIdentity,
      created_at: obj.createdAt.toISOString(),
      updated_at: obj.updatedAt.toISOString(),
      children: Dox.serializeArray(obj.children),
    }
  }

  public static deserialize(json: IDoxJson): Dox {
    const res = new Dox()
    res.id = json.id
    res.isRoot = json.is_diary
    res.name = json.name
    res.notes = json.notes
    res.parentId = json.parent_id
    res.ownerProfileId = json.owner_profile_id
    res.targetProfileId = json.target_profile_id
    res.treatmentId = json.treatment_id
    res.retentionRules = json.retention_rules
    res.retentionStartAt = json.retention_start_at ? new Date(json.retention_start_at) : undefined
    res.retentionEndAt = json.retention_end_at ? new Date(json.retention_end_at) : undefined
    res.retentionEndBehavior = json.retention_end_behavior
    res.retentionNoticeAt = json.retention_notice_at ? new Date(json.retention_notice_at) : undefined
    res.retentionInheritedFrom = json.retention_inherited_from
    res.contentStartAt = json.content_start_at ? new Date(json.content_start_at) : undefined
    res.contentEndAt = json.content_end_at ? new Date(json.content_end_at) : undefined
    res.contentDoxCount = json.content_dox_count
    res.contentDocumentCount = json.content_document_count
    res.contentDraftDocumentCount = json.content_draft_document_count
    res.deliveredAt = json.delivered_at ? new Date(json.delivered_at) : undefined
    res.deliveredByProfileId = json.delivered_by_profile_id
    res.deliveredByIdentity = json.delivered_by_identity
    res.createdAt = new Date(json.created_at)
    res.updatedAt = new Date(json.updated_at)
    res.children = Dox.deserializeArray(json.children)
    return res
  }

  public static serializeArray(objs?: Dox[]): IDoxJson[] {
    if (!objs) return []

    const jsons = objs.map((p) => {
      return Dox.serialize(p)!
    })
    return jsons
  }

  public static deserializeArray(json?: IDoxJson[]): Dox[] {
    if (!json) return []

    const res = json.map((p) => {
      return Dox.deserialize(p)!
    })
    return res
  }

  public getCopy(nested: boolean) {
    const res = new Dox()
    res.id = this.id
    res.isRoot = this.isRoot
    res.name = this.name
    res.notes = this.notes
    res.parentId = this.parentId
    res.ownerProfileId = this.ownerProfileId
    res.targetProfileId = this.targetProfileId
    res.treatmentId = this.treatmentId
    res.retentionRules = this.retentionRules
    res.retentionStartAt = this.retentionStartAt
    res.retentionEndAt = this.retentionEndAt
    res.retentionEndBehavior = this.retentionEndBehavior
    res.retentionNoticeAt = this.retentionNoticeAt
    res.retentionInheritedFrom = this.retentionInheritedFrom
    res.contentStartAt = this.contentStartAt
    res.contentEndAt = this.contentEndAt
    res.contentDoxCount = this.contentDoxCount
    res.contentDocumentCount = this.contentDocumentCount
    res.contentDraftDocumentCount = this.contentDraftDocumentCount
    res.deliveredAt = this.deliveredAt
    res.deliveredByProfileId = this.deliveredByProfileId
    res.deliveredByIdentity = this.deliveredByIdentity
    res.createdAt = this.createdAt
    res.updatedAt = this.updatedAt
    res.children = []

    res.ownerProfileType = this.ownerProfileType
    res.archiveName = this.archiveName
    res.parentsRoute = this.parentsRoute
    res.parentsRouteWithRoot = this.parentsRouteWithRoot
    res.completeRoute = this.completeRoute

    if (nested) {
      this.children.forEach((child) => {
        res.children.push(child.getCopy(nested))
      })
    }

    return res
  }

  public iterateThroughtChilds(includeThis: boolean, action: (dox: Dox) => void) {
    if (includeThis) {
      action(this)
    }
    this.children.forEach((dox) => dox.iterateThroughtChilds(true, action))
  }
}
