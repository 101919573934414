import { Utils } from '../shared/Utils'

export interface INotificationContextRetentionJson {
  id: number
  owner_profile_id: number
  author_profile_id: number
  target_profile_id: number
  document_id?: number
  dox_id: number
  treatment_id: number
  notes: string
  start_at?: string
  end_at?: string
  created_at?: string
  updated_at?: string
}

export class NotificationContextRetention {
  id: number = 0
  ownerProfileId: number = 0
  authorProfileId: number = 0
  targetProfileId: number = 0
  documentId?: number
  doxId: number = 0
  treatmentId: number = 0
  notes: string = ''
  startAt?: Date
  endAt?: Date
  createdAt?: Date
  updatedAt?: Date

  constructor() {}

  public static serialize(obj: NotificationContextRetention): INotificationContextRetentionJson {
    return {
      id: obj.id,
      owner_profile_id: obj.ownerProfileId,
      author_profile_id: obj.authorProfileId,
      target_profile_id: obj.targetProfileId,
      document_id: obj.documentId,
      dox_id: obj.doxId,
      treatment_id: obj.treatmentId,
      notes: obj.notes,
      start_at: obj.startAt ? (Utils.toDate(obj.startAt, true) as string) : undefined,
      end_at: obj.endAt ? (Utils.toDate(obj.endAt, true) as string) : undefined,
      created_at: obj.createdAt?.toISOString(),
      updated_at: obj.updatedAt?.toISOString(),
    }
  }

  public static deserialize(json: INotificationContextRetentionJson): NotificationContextRetention {
    const res = new NotificationContextRetention()
    res.id = json.id
    res.ownerProfileId = json.owner_profile_id
    res.authorProfileId = json.author_profile_id
    res.targetProfileId = json.target_profile_id
    res.documentId = json.document_id
    res.doxId = json.dox_id
    res.treatmentId = json.treatment_id
    res.notes = json.notes
    res.startAt = json.start_at ? new Date(json.start_at) : undefined
    res.endAt = json.end_at ? new Date(json.end_at) : undefined
    res.createdAt = json.created_at ? new Date(json.created_at) : undefined
    res.updatedAt = json.updated_at ? new Date(json.updated_at) : undefined
    return res
  }
}
