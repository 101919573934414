import { PDX_API_URL } from '../shared/Constants'
import log from '../shared/Logger'
import { axiosInstance } from '../contexts/AuthContext'
import { UriComposer } from '../shared/types/UriComposer'
import { IPriceJson, Price } from '../models/Price'

// ********************
// GET

export function getPrices(abortSignal: AbortSignal) {
  const funcName = 'getPrices'
  const url = UriComposer.create(`${PDX_API_URL}/v1/billing/prices`).getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Price.deserializeArray(resp.data as IPriceJson[])
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// POST

// ********************
// PATCH

// ********************
// DELETE
