import { useTranslation } from 'react-i18next'
import { ITreatmentListItemProps } from './TreatmentListItem.types'
import { ListItemText, ListItemTextProps } from '@mui/material'
import { TreatmentLegalBaseTranslationMap } from '../../shared/Constants'

export function TreatmentListItem(props: ITreatmentListItemProps & ListItemTextProps): JSX.Element {
  const { treatment } = props
  const { t } = useTranslation()
  return (
    <ListItemText
      primary={`${treatment.code} - ${treatment.shortDescription}`}
      secondary={t(TreatmentLegalBaseTranslationMap[treatment.legalBase])}
    />
  )
}
