import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function ClockIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 11.99,1.9999999 C 6.4700001,1.9999999 1.9999999,6.48 1.9999999,12 c 0,5.52 4.4700002,10 9.9900001,10 C 17.52,22 22,17.52 22,12 22,6.48 17.52,1.9999999 11.99,1.9999999 Z M 12,19.999999 C 7.5800001,19.999999 4,16.42 4,12 4,7.5800001 7.5800001,4 12,4 c 4.42,0 7.999999,3.5800001 7.999999,8 0,4.42 -3.579999,7.999999 -7.999999,7.999999 z M 12.5,7 H 11 v 6 l 5.25,3.15 0.75,-1.23 -4.5,-2.67 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
