export interface IPreferencesNotificationsJson {
  user_private_message?: boolean
  upcoming_profile_suspension?: boolean
  profile_suspension?: boolean
  upcoming_profile_deletion?: boolean
  profile_deletion_by_system?: boolean
  profile_deletion_by_owner?: boolean
  upcoming_user_deletion?: boolean
  user_deletion_by_system?: boolean
  user_deletion_by_owner?: boolean
  contract_updated?: boolean
  dossier_delivered?: boolean
  document_revision_created?: boolean
  document_viewed?: boolean
  document_downloaded?: boolean
  data_processing_granted?: boolean
  data_processing_updated?: boolean
  data_processing_suspended?: boolean
  upcoming_data_processing_expiration?: boolean
  data_processing_expired?: boolean
  privacy_policy_updated?: boolean
  privacy_policy_consent_updated?: boolean
  profile_attached?: boolean
  profile_deattached?: boolean
  dox_delivered?: boolean
  dox_received?: boolean
  permission_view_updated?: boolean
}

export class PreferencesNotifications {
  userPrivateMessage?: boolean = false
  upcomingProfileSuspension?: boolean = false
  profileSuspension?: boolean = false
  upcomingProfileDeletion?: boolean = false
  profileDeletionBySystem?: boolean = false
  profileDeletionByOwner?: boolean = false
  upcomingUserDeletion?: boolean = false
  userDeletionBySystem?: boolean = false
  userDeletionByOwner?: boolean = false
  contractUpdated?: boolean = false
  dossierDelivered?: boolean = false
  documentRevisionCreated?: boolean = false
  documentViewed?: boolean = false
  documentDownloaded?: boolean = false
  dataProcessingGranted?: boolean = false
  dataProcessingUpdated?: boolean = false
  dataProcessingSuspended?: boolean = false
  upcomingDataProcessingExpiration?: boolean = false
  dataProcessingExpired?: boolean = false
  privacyPolicyUpdated?: boolean = false
  privacyPolicyConsentUpdated?: boolean = false
  profileAttached?: boolean = false
  profileDeattached?: boolean = false
  doxDelivered?: boolean = false
  doxReceived?: boolean = false
  permissionViewUpdated?: boolean = false

  constructor() {}

  public static serialize(obj: PreferencesNotifications): IPreferencesNotificationsJson {
    return {
      user_private_message: obj.userPrivateMessage,
      upcoming_profile_suspension: obj.upcomingProfileSuspension,
      profile_suspension: obj.profileSuspension,
      upcoming_profile_deletion: obj.upcomingProfileDeletion,
      profile_deletion_by_system: obj.profileDeletionBySystem,
      profile_deletion_by_owner: obj.profileDeletionByOwner,
      upcoming_user_deletion: obj.upcomingUserDeletion,
      user_deletion_by_system: obj.userDeletionBySystem,
      user_deletion_by_owner: obj.userDeletionByOwner,
      contract_updated: obj.contractUpdated,
      dossier_delivered: obj.dossierDelivered,
      document_revision_created: obj.documentRevisionCreated,
      document_viewed: obj.documentViewed,
      document_downloaded: obj.documentDownloaded,
      data_processing_granted: obj.dataProcessingGranted,
      data_processing_updated: obj.dataProcessingUpdated,
      data_processing_suspended: obj.dataProcessingSuspended,
      upcoming_data_processing_expiration: obj.upcomingDataProcessingExpiration,
      data_processing_expired: obj.dataProcessingExpired,
      privacy_policy_updated: obj.privacyPolicyUpdated,
      privacy_policy_consent_updated: obj.privacyPolicyConsentUpdated,
      profile_attached: obj.profileAttached,
      profile_deattached: obj.profileDeattached,
      dox_delivered: obj.doxDelivered,
      dox_received: obj.doxReceived,
      permission_view_updated: obj.permissionViewUpdated,
    }
  }

  public static deserialize(json: IPreferencesNotificationsJson): PreferencesNotifications {
    const res = new PreferencesNotifications()
    res.userPrivateMessage = json.user_private_message
    res.upcomingProfileSuspension = json.upcoming_profile_suspension
    res.profileSuspension = json.profile_suspension
    res.upcomingProfileDeletion = json.upcoming_profile_deletion
    res.profileDeletionBySystem = json.profile_deletion_by_system
    res.profileDeletionByOwner = json.profile_deletion_by_owner
    res.upcomingUserDeletion = json.upcoming_user_deletion
    res.userDeletionBySystem = json.user_deletion_by_system
    res.userDeletionByOwner = json.user_deletion_by_owner
    res.contractUpdated = json.contract_updated
    res.dossierDelivered = json.dossier_delivered
    res.documentRevisionCreated = json.document_revision_created
    res.documentViewed = json.document_viewed
    res.documentDownloaded = json.document_downloaded
    res.dataProcessingGranted = json.data_processing_granted
    res.dataProcessingUpdated = json.data_processing_updated
    res.dataProcessingSuspended = json.data_processing_suspended
    res.upcomingDataProcessingExpiration = json.upcoming_data_processing_expiration
    res.dataProcessingExpired = json.data_processing_expired
    res.privacyPolicyUpdated = json.privacy_policy_updated
    res.privacyPolicyConsentUpdated = json.privacy_policy_consent_updated
    res.profileAttached = json.profile_attached
    res.profileDeattached = json.profile_deattached
    res.doxDelivered = json.dox_delivered
    res.doxReceived = json.dox_received
    res.permissionViewUpdated = json.permission_view_updated
    return res
  }
}
