import { TableCell } from '@mui/material'
import { Fragment } from 'react'
import { IMultilineTableCellProps } from './MultilineTableCell.types'

export function MultilineTableCell(props: IMultilineTableCellProps): JSX.Element {
  const createMultilineText = (text: string) => {
    return text
      ? text.split('\n').map((item, index) => (
          <Fragment key={index}>
            {item}
            <br />
          </Fragment>
        ))
      : ''
  }

  return <TableCell>{createMultilineText(props.text)}</TableCell>
}
