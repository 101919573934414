import { DownloadIco, DoxIco, NotificationAuthorizationIco, TermsConditionsIco, TreatmentIco, UserIco } from '../icons'
import NoteIco from '../icons/NoteIco'
import { ActionType } from '../../shared/Constants'
import { History } from '../../models/History'

export function icoFromType(history: History) {
  switch (history.subjectType) {
    case 'permissions': {
      switch (history.subjectPermissionType) {
        // case ActionType.dataProcessing:
        //   return AuthorizationIco
        case ActionType.alwaysDownload:
          return DownloadIco
        case ActionType.viewDox:
          return DoxIco
        case ActionType.viewDocuments:
          return NoteIco
        // case ActionType.viewProfiles:
        //   return UserIco
        default:
          return undefined
      }
    }
    case 'dox':
      return DoxIco
    case 'documents':
      return NoteIco
    case 'contracts':
      return TermsConditionsIco
    case 'consents':
      return NotificationAuthorizationIco
    case 'profiles':
      return UserIco
    case 'treatments':
      return TreatmentIco
    // case 'invitations':
    //   return InvitationIco
    default:
      return undefined
  }
}
