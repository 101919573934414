import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function DocViewedIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 7.9605174,3.0153172 2.5706249,8.4052098 V 19.185 a 1.80103,1.80103 0 0 0 1.7995605,1.795165 H 12.565986 V 19.000428 H 4.5547557 V 9.3060887 H 8.8591991 V 4.9708836 h 5.7062989 v 2.0148925 h 1.986329 V 3.0153172 Z M 13.855781,8.87103 a 4.5103712,4.5103712 0 0 0 0.131836,9.019779 4.3431013,4.3431013 0 0 0 2.913575,-1.111815 l 0.20874,0.20874 v 0.555909 l 3.469482,3.469482 1.039306,-1.041504 -3.469482,-3.469484 h -0.553711 l -0.20874,-0.20874 A 4.3431013,4.3431013 0 0 0 18.496406,13.379819 4.5102153,4.5102153 0 0 0 13.855781,8.87103 Z m 0.0022,1.388672 a 3.1224567,3.1224567 0 0 1 3.251961,3.120117 3.1094742,3.1094742 0 0 1 -3.122315,3.122318 3.1224567,3.1224567 0 0 1 -0.129639,-6.242435 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
