import { Collapse, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { DoxTemplate } from '../../models/DoxTemplate'
import { IDoxesTemplatesEditorProps } from './DoxesTemplatesEditor.types'
import { MouseEvent, useEffect, useState } from 'react'
import {
  AddIco,
  ChevronRightIco,
  DeleteIco,
  EditIco,
  ExpandMoreIco,
  RetentionDisabledIco,
  RetentionIco,
} from '../icons'
import { useAuthContext } from '../../contexts/AuthContext'
import { RetentionRules, isOperator } from '../../shared/Constants'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { DoxTemplateEditor } from '../doxTemplateEditor/DoxTemplateEditor'
import DialogTemplate from '../template/dialogTemplate/DialogTemplate'
import { parseInt } from 'lodash'
import { Utils } from '../../shared/Utils'

export function DoxesTemplatesEditor(props: IDoxesTemplatesEditorProps): JSX.Element {
  const { doxes, isEditMode, onChangeDoxes } = props

  return (
    <DoxesTemplatesList
      parentTreatmentId={0}
      doxes={doxes}
      isEditMode={isEditMode}
      level={0}
      onChangeDoxes={onChangeDoxes}
    />
  )
}

type DoxAction = 'add' | 'delete' | 'edit'

interface IDoxesTemplatesList {
  doxes: DoxTemplate[]
  parentTreatmentId: number
  level: number
  isEditMode: boolean
  onChangeDoxes: (doxes: DoxTemplate[]) => void
}

function DoxesTemplatesList(props: IDoxesTemplatesList): JSX.Element {
  const { doxes, parentTreatmentId, isEditMode, level, onChangeDoxes } = props
  const { t } = useTranslation()
  const [toDeleteIndex, setToDeleteIndex] = useState<number>(-1)
  const [doxInEditing, setDoxInEditing] = useState<[DoxTemplate, number] | undefined>()
  const [editingIndex, setEditingIndex] = useState<number>(-1)
  const [parentIndex, setParentIndex] = useState<number>(-1)
  const [expandedChildrenIndexes, setExpandedChildrenIndexes] = useState<number[]>([])
  const [doxInEditingParent, setDoxInEditingParent] = useState<DoxTemplate | undefined>()
  const [errors, setErrors] = useState<any>({})

  function onActionDox(index: number, action: DoxAction) {
    switch (action) {
      case 'delete':
        setToDeleteIndex(index)
        break
      case 'add':
        {
          const parent = doxes[index]
          const name = Utils.computeDefaultDoxName(parent.children, t)
          const dox = {
            name,
            treatmentId: 0,
            retentionRules: RetentionRules.none,
            automaticallySetEndRetentionDate: true,
            children: [],
          }
          setDoxInEditing([dox, index])
          setDoxInEditingParent(parent)
          setParentIndex(index)
        }
        break
      case 'edit':
        {
          setDoxInEditingParent(undefined)
          const dox = doxes[index]
          setDoxInEditing([dox, index])
          setEditingIndex(index)
        }
        break

      default:
        break
    }
  }

  function onSaveDox(dox: DoxTemplate, index: number) {
    if (editingIndex >= 0) {
      const doxWithSameName = doxes.find((d, i) => i !== index && d.name.trim() === dox.name.trim())
      if (doxWithSameName) {
        setErrors({ name: msgIds.MSG_VAL_ERR_DUPLICATED_NAME })
        return
      }
      const copy = [...doxes]
      copy.splice(editingIndex, 1, dox)
      onChangeDoxes(copy)
    } else if (parentIndex >= 0) {
      const copy = [...doxes]
      const parent = { ...copy[parentIndex] }
      const children = [...parent.children]
      const doxWithSameName = children.find((d) => d.name.trim() === dox.name.trim())
      if (doxWithSameName) {
        setErrors({ name: msgIds.MSG_VAL_ERR_DUPLICATED_NAME })
        return
      }
      parent.children = children
      children.push(dox)
      copy.splice(parentIndex, 1, parent)
      onChangeDoxes(copy)
      setExpandedChildrenIndexes((e) => {
        return [...e, parentIndex]
      })
    }

    onCancelDox()
  }

  function onCancelDox() {
    setErrors({})
    setParentIndex(-1)
    setEditingIndex(-1)
  }

  function onDeleteConfirmation(index: number) {
    const copy = [...doxes]
    copy.splice(index, 1)
    onChangeDoxes(copy)
  }

  function onChangeChildren(index: number, children: DoxTemplate[]) {
    const copy = [...doxes]
    const dox = copy[index]
    dox.children = children
    onChangeDoxes(copy)
  }

  return (
    <>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }} dense={true} disablePadding>
        {doxes.map((dox, i) => {
          const expanded = props.level === 0 || expandedChildrenIndexes.indexOf(i) !== -1
          return (
            <DoxTemplateListItem
              key={i}
              dox={dox}
              index={i}
              level={level}
              parentTreatmentId={parentTreatmentId}
              isEditMode={isEditMode}
              childrenExpanded={expanded}
              onActionDox={onActionDox}
              onChangeChildren={(children) => onChangeChildren(i, children)}
            />
          )
        })}
      </List>
      <SimpleDialog
        title={t(msgIds.MSG_DOXES_TEMPLATES_DELETE_ELEMENT_DIALOG_CONFIRM_TITLE)}
        content={t(msgIds.MSG_DOXES_TEMPLATES_DELETE_ELEMENT_DIALOG_CONFIRM_BODY)}
        actionsStyle="yesNO"
        isOpen={toDeleteIndex >= 0}
        onClose={(result) => {
          if (result.userChoice === 'yes') {
            onDeleteConfirmation(toDeleteIndex)
          }
          setToDeleteIndex(-1)
        }}
      />
      <DialogTemplate
        title={t(msgIds.MSG_DOX_TEMPLATE_EDITOR_DIALOG_TITLE)}
        isOpen={parentIndex >= 0 || editingIndex >= 0}
        onClose={onCancelDox}
        isFullscreenForMobile={true}
        showActions={false}
        actions={[]}
        maxWidth="sm"
      >
        {doxInEditing && (
          <DoxTemplateEditor
            dox={doxInEditing[0]}
            disableTreatment={!!doxInEditingParent?.treatmentId || !!parentTreatmentId}
            errors={errors}
            onSave={(dox) => onSaveDox(dox, doxInEditing[1])}
            onCancel={onCancelDox}
          />
        )}
      </DialogTemplate>
    </>
  )
}

interface IDoxTemplateListItemProps {
  dox: DoxTemplate
  index: number
  level: number
  parentTreatmentId: number
  isEditMode: boolean
  childrenExpanded?: boolean
  onActionDox: (index: number, action: DoxAction) => void
  onChangeChildren: (doxes: DoxTemplate[]) => void
}

const iconSizePx = 34

function DoxTemplateListItem(props: IDoxTemplateListItemProps): JSX.Element {
  const { dox, index, parentTreatmentId, isEditMode, onChangeChildren } = props
  const [childrenExpanded, setChildrenExpanded] = useState(props.childrenExpanded === true)
  const hasChildren = dox.children.length !== 0
  const children = dox.children

  useEffect(() => {
    setChildrenExpanded(props.childrenExpanded === true)
  }, [props.childrenExpanded])

  function toggleChildren(event: MouseEvent<HTMLButtonElement, any>) {
    event.stopPropagation()
    setChildrenExpanded((flag) => !flag)
  }

  const levelPadding = props.level * 2 * 8
  const paddingLeft = hasChildren ? levelPadding : iconSizePx + levelPadding

  return (
    <>
      <ListItem
        disablePadding
        disableGutters={true}
        sx={{ width: '100%', paddingLeft: `${paddingLeft}px` }}
        secondaryAction={
          isEditMode && (
            <>
              <IconButton size="small" onClick={() => props.onActionDox(index, 'delete')}>
                <DeleteIco />
              </IconButton>
              <IconButton size="small" onClick={() => props.onActionDox(index, 'edit')}>
                <EditIco />
              </IconButton>
              <IconButton size="small" onClick={() => props.onActionDox(index, 'add')}>
                <AddIco />
              </IconButton>
            </>
          )
        }
        dense={true}
      >
        {hasChildren && (
          <IconButton size="small" onClick={toggleChildren}>
            {childrenExpanded ? <ExpandMoreIco /> : <ChevronRightIco />}
          </IconButton>
        )}
        <ListItem disableGutters={true} dense={true} sx={{ paddingLeft: '8px' }}>
          <DoxTemplateItem dox={dox} parentTreatmentId={parentTreatmentId} />
        </ListItem>
      </ListItem>
      <Collapse in={childrenExpanded} mountOnEnter={true} timeout="auto" unmountOnExit={false}>
        <DoxesTemplatesList
          parentTreatmentId={dox.treatmentId || parentTreatmentId}
          isEditMode={isEditMode}
          doxes={children}
          level={props.level + 1}
          onChangeDoxes={onChangeChildren}
        />
      </Collapse>
    </>
  )
}

interface IDoxTemplateItemProps {
  dox: DoxTemplate
  parentTreatmentId: number
}

function DoxTemplateItem(props: IDoxTemplateItemProps): JSX.Element {
  const { dox, parentTreatmentId } = props
  const authContext = useAuthContext()
  const isUserOperator = isOperator(authContext.loggedProfileType)

  return (
    <>
      <ListItemIcon sx={{ minWidth: iconSizePx }}>
        {isUserOperator && (!!dox.treatmentId || !!parentTreatmentId) ? (
          <RetentionIco opacity={!!parentTreatmentId ? 0.5 : 1} />
        ) : (
          <RetentionDisabledIco opacity={0.5} />
        )}
      </ListItemIcon>
      <ListItemText primary={dox.name} primaryTypographyProps={{ noWrap: true, textOverflow: 'ellipsis' }} />
    </>
  )
}
