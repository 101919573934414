import { INotificationContextDocumentJson, NotificationContextDocument } from './NotificationContextDocument'

export interface INotificationContextDocumentRevisionJson {
  id: number
  mimetype: string
  name: string
  filename: string
  size: number
  description: string
  creator_identity: string
  edited_at: string
  archived_at: string
  document_id: number
  document?: INotificationContextDocumentJson
}

export class NotificationContextDocumentRevision {
  id: number = 0
  mimetype: string = ''
  name: string = ''
  filename: string = ''
  size: number = 0
  description: string = ''
  creatorIdentity: string = ''
  editedAt: Date = new Date(0)
  archivedAt: Date = new Date(0)
  documentId: number = 0
  document?: NotificationContextDocument

  constructor() {}

  public static serialize(obj: NotificationContextDocumentRevision): INotificationContextDocumentRevisionJson {
    return {
      id: obj.id,
      mimetype: obj.mimetype,
      name: obj.name,
      filename: obj.filename,
      size: obj.size,
      description: obj.description,
      creator_identity: obj.creatorIdentity,
      edited_at: obj.editedAt.toISOString(),
      archived_at: obj.archivedAt.toISOString(),
      document_id: obj.documentId,
      document: obj.document ? NotificationContextDocument.serialize(obj.document) : undefined,
    }
  }

  public static deserialize(json: INotificationContextDocumentRevisionJson): NotificationContextDocumentRevision {
    const res = new NotificationContextDocumentRevision()
    res.id = json.id
    res.mimetype = json.mimetype
    res.name = json.name
    res.filename = json.filename
    res.size = json.size
    res.description = json.description
    res.creatorIdentity = json.creator_identity
    res.editedAt = new Date(json.edited_at)
    res.archivedAt = new Date(json.archived_at)
    res.documentId = json.document_id
    res.document = json.document ? NotificationContextDocument.deserialize(json.document) : undefined
    return res
  }
}
