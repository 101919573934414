import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function DiaryCustomerIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 21,5.0000032 A 11.804665,11.804665 0 0 0 17.5,4.5000033 c -1.95,0 -4.05,0.4 -5.5,1.5 -1.45,-1.1 -3.5500001,-1.5 -5.5000001,-1.5 -1.95,0 -4.0499999,0.4 -5.49999991,1.5 V 20.650003 A 0.53769499,0.53769499 0 0 0 1.5,21.150003 c 0.1,0 0.15,-0.05 0.2500001,-0.05 a 12.205445,12.205445 0 0 1 4.7499998,-1.1 c 1.95,0 4.0500001,0.399999 5.5000001,1.5 a 12.045255,12.045255 0 0 1 5.5,-1.5 10.178315,10.178315 0 0 1 4.749999,1.05 0.45075499,0.45075499 0 0 0 0.250001,0.05 0.53769499,0.53769499 0 0 0 0.499999,-0.499999 V 6.0000033 A 6.8205799,6.8205799 0 0 0 21,5.0000032 Z M 3,18.500004 V 7.0000032 a 11.62039,11.62039 0 0 1 3.4999999,-0.5 A 12.734,12.734 0 0 1 11,7.4900032 V 18.990003 a 12.734,12.734 0 0 0 -4.5000001,-0.99 A 11.62039,11.62039 0 0 0 3,18.500004 Z m 18,0 a 11.62039,11.62039 0 0 0 -3.5,-0.500001 12.734,12.734 0 0 0 -4.5,0.99 V 7.4900032 a 12.519135,12.519135 0 0 1 4.5,-0.99 11.62039,11.62039 0 0 1 3.5,0.5 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
