import { Components, PaletteOptions, Theme } from '@mui/material'

export const typography = {}

export const common = {
  content1: '#F19436',
  content2: '#749D47',
  content3: '#A050B6',
  content4: '#3576CB',
  gray0: '#010101',
  gray1: '#212121',
  gray2: '#424242',
  gray3: '#616161',
  gray4: '#757575',
  gray5: '#9e9e9e',
  gray6: '#bdbdbd',
  gray7: '#e0e0e0',
  gray8: '#eeeeee',
  gray9: '#f5f5f5',
  gray10: '#fafafa',
  gray11: '#ffffff',
  memoText: '#ffffe0',
}

export const defaultPalette: Partial<PaletteOptions> = {
  secondary: {
    light: common.gray2,
    main: common.gray1,
    dark: common.gray0,
  },
}

export const muiComponentsOverrides: Components<Omit<Theme, 'components'>> | undefined = {
  MuiListItemButton: {
    styleOverrides: {
      root: ({ ownerState, theme }) => {
        if (ownerState.selected) {
          return {
            backgroundColor: theme.palette.primary.lighter + ' !important',
          }
        } else {
          return {
            backgroundColor: 'transparent',
          }
        }
      },
    },
  },
}
