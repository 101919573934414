import { IconButton, Stack, TextField, TextFieldProps } from '@mui/material'
import { CloseIco, SearchIco } from '../icons'
import { IFakeSearchInputProps } from './FakeSearchInput.types'

export function FakeSearchInput(props: IFakeSearchInputProps & TextFieldProps): JSX.Element {
  const { onClickSearch, onClickClear, ...rest } = props
  return (
    <TextField
      contentEditable={false}
      InputProps={{
        endAdornment: (
          <Stack direction="row">
            <IconButton onClick={onClickSearch}>
              <SearchIco />
            </IconButton>
            <IconButton onClick={onClickClear}>
              <CloseIco />
            </IconButton>
          </Stack>
        ),
      }}
      {...rest}
    />
  )
}
