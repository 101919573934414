import { useTranslation } from 'react-i18next'
import { EditorFormContainer } from '../editorFormContainer/EditorFormContainer'
import { IAccountsDataManagementProps } from './AccountsDataManagement.types'
import { Box, Button, IconButton, List, ListItem, Stack, Typography } from '@mui/material'
import { SwitchRow } from '../switchRow/SwitchRow'
import msgIds from '../../locales/msgIds'
import * as dalPermission from '../../dal/DalPermission'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import { useEffect, useRef, useState } from 'react'
import { ActionType } from '../../shared/Constants'
import { Permission } from '../../models/Permission'
import { Utils } from '../../shared/Utils'
import { InputContainer } from '../inputContainer/InputContainer'
import { Treatment } from '../../models/Treatment'
import { TreatmentListItem } from '../treatmentListItem/TreatmentListItem'
import { DeleteIco } from '../icons'
import * as dalTreatment from '../../dal/DalTreatment'
import TreatmentListDialog from '../../dialogs/treatmentListDialog/TreatmentListDialog'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'
import { ISimpleDialogData } from '../../dialogs/simpleDialog/SimpleDialog.types'
import { ITreatmentListDialogData } from '../../dialogs/treatmentListDialog/TreatmentListDialog.types'

export function AccountsDataManagement(props: IAccountsDataManagementProps): JSX.Element {
  const { profileId, structureProfileId, isEditMode, isLoading, setIsLoading } = props
  const { t } = useTranslation()
  const [initialized, setInitialized] = useState(false)
  const [visibilityAllPermission, setVisibilityAllPermission] = useState<Permission>()
  const [permissions, setPermissions] = useState<Permission[]>([])
  const [visibilityAll, setVisibilityAll] = useState<boolean>(false)
  const [allTreatments, setAllTreatments] = useState<Treatment[]>([])
  const treatmentsIds = permissions.filter((p) => p.action === ActionType.viewDataTreatedFor).map((p) => p.targetId)

  // dialogs
  const [simpleDialogData, setSimpleDialogData] = useState<ISimpleDialogData>()
  const [simpleDialogOpen, setSimpleDialogOpen] = useState(false)
  const [treatmentListDialogData, setTreatmentListDialogData] = useState<ITreatmentListDialogData>()
  const [treatmentListDialogOpen, setTreatmentListDialogOpen] = useState(false)

  useEffect(() => {
    setAllTreatments([])
  }, [structureProfileId])

  useEffect(() => {
    if (props.permissions) {
      const res = props.permissions?.find((p) => p.action === ActionType.viewAllTreatedData)
      setVisibilityAllPermission(res)
      setVisibilityAll(!!res)
      setInitialized(true)
    }
  }, [props.permissions])

  // ********************
  // infinite scroll
  const [hasMore, setHasMore] = useState(true)
  const pageNum = useRef(0)
  const elementRef = useRef(null)

  useEffect(() => {
    const abortController = new AbortController()
    const observer = new IntersectionObserver(
      (entries) => {
        const firstEntry = entries[0]
        if (firstEntry && firstEntry.isIntersecting && hasMore) {
          loadPermissions(abortController.signal)
        }
      },
      {
        threshold: 0.8,
      }
    )
    if (observer && elementRef.current) {
      observer.observe(elementRef.current)
    }

    return () => {
      observer?.disconnect()
      abortController.abort()
    }
  }, [permissions])

  useEffect(() => {
    const abortController = new AbortController()
    if (initialized && !visibilityAll) {
      loadPermissions(abortController.signal)
    }

    return () => {
      abortController.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId, structureProfileId, initialized, visibilityAll])

  const loadPermissions = async (abortSignal: AbortSignal) => {
    try {
      setIsLoading(true)
      const paginatedPermissions = await dalPermission.getCollaboratorPermissionsByAction(
        abortSignal,
        pageNum.current,
        9,
        structureProfileId,
        profileId,
        ActionType.viewDataTreatedFor
      )
      // deduplication
      const uniqueData = paginatedPermissions.rows.filter((p) => !permissions.map((p) => p.id).includes(p.id))
      setPermissions(sortPermissions([...permissions, ...uniqueData]))

      pageNum.current += 1
      setHasMore(paginatedPermissions.hasMore)
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  async function onChange(key: string, enabled: boolean) {
    try {
      setIsLoading(true)
      const abortController = new AbortController()
      if (enabled) {
        const permission = await dalPermission.createPermission(abortController.signal, {
          profileId: profileId,
          action: ActionType.viewAllTreatedData,
          targetId: structureProfileId,
          startAt: new Date(),
        })
        setVisibilityAllPermission(permission)
        setVisibilityAll(true)
      } else {
        if (visibilityAllPermission) {
          await dalPermission.deletePermission(abortController.signal, visibilityAllPermission.id)
          setVisibilityAllPermission(undefined)
          setVisibilityAll(false)
        }
      }
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  async function onClickAddTreatment() {
    let _allTreatments = allTreatments
    if (_allTreatments.length === 0) {
      try {
        setIsLoading(true)
        const abortController = new AbortController()
        _allTreatments = await dalTreatment.getTreatments(abortController.signal, structureProfileId, true, false)
        setAllTreatments(_allTreatments)
      } catch (err) {
        Utils.enqueueSnackbarError2(err, t)
      } finally {
        setIsLoading(false)
      }
    }

    const availableTreatments = _allTreatments.filter((t) => !treatmentsIds.includes(t.id))
    setTreatmentListDialogOpen(true)
    setTreatmentListDialogData({
      treatments: availableTreatments,
      onResult: async (result) => {
        setTreatmentListDialogOpen(false)
        if (result.userChoice !== 'yes') return
        const selectedTreatment = result.treatment
        if (selectedTreatment) {
          onAddTreatment(selectedTreatment)
        }
      },
    })
  }

  async function onAddTreatment(treatment: Treatment) {
    const abortController = new AbortController()
    try {
      setIsLoading(true)
      const permission = await dalPermission.createPermission(abortController.signal, {
        profileId: profileId,
        action: ActionType.viewDataTreatedFor,
        targetId: treatment.id,
        startAt: new Date(),
      })
      permission.targetTreatment = treatment
      setPermissions((ps) => {
        return sortPermissions([permission, ...ps])
      })
      setTreatmentListDialogOpen(false)
    } catch (error) {
      Utils.enqueueSnackbarError2(error, t)
    } finally {
      setIsLoading(false)
    }
  }

  const onDeleteTreatment = (treatment: Treatment) => {
    setSimpleDialogOpen(true)
    setSimpleDialogData({
      title: t(msgIds.MSG_AUTHORIZATIONS),
      content: t(msgIds.MSG_COLLABORATOR_AUTHORIZATION_DELETE_CONFIRM),
      actionsStyle: 'yesNO',
      onClose: async (result) => {
        if (result.userChoice !== 'yes') return
        setSimpleDialogOpen(false)
        onDeleteTreatmentConfirm(treatment)
      },
    })
  }

  async function onDeleteTreatmentConfirm(treatment: Treatment) {
    const permission = permissions.find(
      (p) => p.action === ActionType.viewDataTreatedFor && p.targetId === treatment.id
    )
    if (!permission) {
      return
    }
    const abortController = new AbortController()
    try {
      await dalPermission.deletePermission(abortController.signal, permission.id)
      setPermissions((ps) => {
        return ps.filter((p) => p.id !== permission.id)
      })
    } catch (error) {
      Utils.enqueueSnackbarError2(error, t)
    }
  }

  function onCancel() {
    props.onCancel && props.onCancel()
  }

  function sortPermissions(permissions: Permission[]) {
    return permissions.sort((a, b) => {
      if (!a.targetTreatment && !b.targetTreatment) {
        return 0
      }
      if (!a.targetTreatment) {
        return 1
      }
      if (!b.targetTreatment) {
        return -1
      }
      if (a.targetTreatment.code < b.targetTreatment.code) {
        return -1
      }
      if (a.targetTreatment.code > b.targetTreatment.code) {
        return 1
      }
      return 0
    })
  }

  return (
    <EditorFormContainer spacing={1}>
      {simpleDialogData && <SimpleDialog {...simpleDialogData} isOpen={simpleDialogOpen}></SimpleDialog>}
      {treatmentListDialogData && (
        <TreatmentListDialog
          {...treatmentListDialogData}
          isOpen={treatmentListDialogOpen}
          onClose={() => setTreatmentListDialogOpen(false)}
        />
      )}

      <SwitchRow
        disabled={!isEditMode}
        label={t(msgIds.MSG_USERS_DATA_VISIBILITY_ALL_LABEL)}
        checked={visibilityAll}
        onChange={(_, checked) => onChange('visibilityAll', checked)}
      />
      {initialized && !visibilityAll && (
        <Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography py={1}>{t(msgIds.MSG_USERS_DATA_VISIBILITY_BY_FINALITY_LABEL)}</Typography>
            {isEditMode && (
              <Button onClick={onClickAddTreatment}>{t(msgIds.MSG_DOXES_TEMPLATES_LIST_ADD_BUTTON)}</Button>
            )}
          </Stack>
          <InputContainer>
            <List sx={{ minWidth: '320px' }}>
              {permissions
                .map((p) => p.targetTreatment)
                .map(
                  (treatment) =>
                    treatment && (
                      <ListItem
                        key={treatment!.id}
                        secondaryAction={
                          isEditMode && (
                            <IconButton onClick={() => onDeleteTreatment(treatment)}>
                              <DeleteIco />
                            </IconButton>
                          )
                        }
                      >
                        <TreatmentListItem treatment={treatment} />
                      </ListItem>
                    )
                )}
            </List>

            {permissions.length > 0 && hasMore && !isLoading && (
              <Box
                ref={elementRef}
                sx={{
                  textAlign: 'center',
                  minHeight: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {t(msgIds.MSG_LOAD_MORE_ELEMENTS)}
              </Box>
            )}
          </InputContainer>
        </Stack>
      )}
      {isEditMode && (
        <ViewActions justifyContent={'center'} sx={{ paddingTop: 6 }}>
          <ViewActionsButton onClick={onCancel}>{t(msgIds.MSG_CLOSE)}</ViewActionsButton>
        </ViewActions>
      )}
    </EditorFormContainer>
  )
}
