import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function FlagUK(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 13.229166 8.9958333">
      <g transform="matrix(0.04687497,0,0,0.06375002,10.745379,2.3905311)">
        <path
          d="m -228.88212,103.61255 h 21.16666 l 92.78056,-46.919449 c -5.33364,0 -15.32184,-0.599017 -20.0981,0.01023 -1.22379,0.155928 -11.52596,5.568951 -13.53467,6.573662 -9.07627,4.539545 -17.73308,8.864953 -26.81111,13.405556 -9.07627,4.539545 -17.73309,8.864954 -26.81112,13.405557 -4.65384,2.327628 -24.20655,11.364034 -26.69222,13.524444"
          style={{ fill: '#cf142b', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none', strokeWidth: 0.0352778 }}
        />
        <path
          d="m -229.2349,47.1681 v 9.525001 l 61.87546,0.278694 -61.87546,30.765752 v 15.875003 h 0.35278 c 2.48567,-2.16041 22.03838,-11.196816 26.69222,-13.524444 9.07803,-4.540603 17.73485,-8.866012 26.81112,-13.405557 9.07803,-4.540603 17.73484,-8.866011 26.81111,-13.405556 2.00871,-1.004711 12.31088,-6.417734 13.53467,-6.573662 4.77626,-0.609247 14.76446,-0.01023 20.0981,-0.01023 l -92.78056,46.919449 h 10.23056 l 85.72501,-42.686116 v 42.686116 h 9.525 V 47.1681 H -229.2349"
          style={{ fill: '#ffffff', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none', strokeWidth: 0.0352778 }}
        />
        <path
          d="m -229.2349,56.693101 v 31.044446 l 61.87546,-30.765752 z"
          style={{ fill: '#00247d', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none', strokeWidth: 0.0352778 }}
        />
        <path
          d="M -64.48767,103.61255 H 21.23751 L -64.48767,60.926434 Z"
          style={{ fill: '#00247d', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none', strokeWidth: 0.0352778 }}
        />
        <path
          d="m -197.4849,103.61255 h 85.72501 V 60.926434 Z"
          style={{ fill: '#00247d', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none', strokeWidth: 0.0352778 }}
        />
        <path
          d="M 52.9875,56.693101 -8.88777,56.971795 52.9875,87.737547 Z"
          style={{ fill: '#00247d', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none', strokeWidth: 0.0352778 }}
        />
        <path
          d="M 52.9875,93.029214 C 38.86674,85.55738 24.14751,78.609422 9.71491,71.390882 2.38101,67.722699 -4.68972,64.188218 -11.92378,60.573657 -16.5046,58.284834 -17.49943,56.693101 -24.271,56.693101 c -5.40914,0 -10.81828,0 -16.29798,0.140758 l 93.2037,46.778691 H 52.9875 V 93.029214"
          style={{ fill: '#cf142b', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none', strokeWidth: 0.0352778 }}
        />
        <path
          d="M 52.9875,93.029214 V 87.737547 L -8.88777,56.971795 52.9875,56.693101 V 47.1681 H -74.01267 v 56.44445 h 9.525 V 60.926434 l 85.72518,42.686116 h 31.39721 l -93.2037,-46.778691 c 5.4797,-0.140758 10.88884,-0.140758 16.29798,-0.140758 6.77157,0 7.7664,1.591733 12.34722,3.880556 7.23406,3.614561 14.30479,7.149042 21.63869,10.817225 14.4326,7.21854 29.15183,14.166498 43.27259,21.638332"
          style={{ fill: '#ffffff', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none', strokeWidth: 0.0352778 }}
        />
        <path
          d="M 52.9875,-21.623395 -8.74878,9.4208755 H 52.9875 Z"
          style={{ fill: '#00247d', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none', strokeWidth: 0.0352778 }}
        />
        <path
          d="M -229.2349,-21.623395 V 9.4208755 l 61.87546,-0.27834 z"
          style={{ fill: '#00247d', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none', strokeWidth: 0.0352778 }}
        />
        <path
          d="m -228.88212,-37.498395 h -0.35278 v 10.58334 c 14.12099,7.47173 28.84029,14.41965 43.27278,21.6383605 7.33355,3.66798 14.40462,7.20246 21.63833,10.81702 4.58082,2.28917 5.57601,3.8809 12.34723,3.88055 5.40949,0 10.81863,0 16.29833,-0.14076 l -93.20389,-46.7785105"
          style={{ fill: '#cf142b', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none', strokeWidth: 0.0352778 }}
        />
        <path
          d="M 52.63472,-37.498395 H 31.46807 L -61.31267,9.4208755 c 5.334,0 15.32185,0.5990195 20.09846,-0.01 1.22378,-0.15628 11.5256,-5.56895 13.53467,-6.57365 9.07627,-4.5399 17.73273,-8.86535 26.81111,-13.4058405 9.07627,-4.53955 17.73273,-8.86492 26.81097,-13.40542 4.65403,-2.32777 24.20661,-11.36413 26.69218,-13.52448"
          style={{ fill: '#cf142b', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none', strokeWidth: 0.0352778 }}
        />
        <path
          d="m 21.23751,-37.498395 h -85.72518 l 0.13935,42.9639305 z"
          style={{ fill: '#00247d', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none', strokeWidth: 0.0352778 }}
        />
        <path
          d="M 52.9875,9.4208755 H -8.74878 L 52.9875,-21.623395 v -15.875 h -0.35278 c -2.48557,2.16035 -22.03815,11.19671 -26.69218,13.52448 -9.07824,4.540501 -17.7347,8.86587 -26.81097,13.40542 -9.07838,4.5404905 -17.73484,8.8659405 -26.81111,13.4058405 -2.00907,1.0047 -12.31089,6.41737 -13.53467,6.57365 -4.77661,0.6089005 -14.76446,0.01 -20.09846,0.01 L 31.46807,-37.498294 H 21.23751 L -64.34832,5.4656355 -64.48767,-37.498294 h -9.525 V 18.945875 H 52.9875 V 9.4208755"
          style={{ fill: '#ffffff', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none', strokeWidth: 0.0352778 }}
        />
        <path
          d="M 52.9875,47.1681 V 18.945875 H -74.01267 v -56.44427 h -28.22222 v 56.44427 H -229.2349 V 47.1681 h 127.00001 v 56.44445 h 28.22222 V 47.1681 H 52.9875"
          style={{ fill: '#cf142b', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none', strokeWidth: 0.0352778 }}
        />
        <path
          d="m -111.75989,-37.498395 h -85.72501 l 85.72501,42.6859405 z"
          style={{ fill: '#00247d', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none', strokeWidth: 0.0352778 }}
        />
        <path
          d="m -102.23489,-37.498395 h -9.525 V 5.1875455 L -197.4849,-37.498395 h -31.39722 l 93.20389,46.7785105 c -5.4797,0.14076 -10.88884,0.14076 -16.29833,0.14076 -6.77122,3.5e-4 -7.76641,-1.59138 -12.34723,-3.88055 -7.23371,-3.61456 -14.30478,-7.14904 -21.63833,-10.81702 -14.43249,-7.2187105 -29.15179,-14.1666305 -43.27278,-21.6383605 v 5.29166 l 61.87546,30.7659305 -61.87546,0.27834 v 9.5249995 h 127.00001 v -56.44427"
          style={{ fill: '#ffffff', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none', strokeWidth: 0.0352778 }}
        />
      </g>
    </SvgIcon>
  )
}
