import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function MenuIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 2.9992676,6.0007309 V 8.0002427 H 20.999268 V 6.0007309 Z m 0,4.9987791 v 1.999512 H 20.999268 V 10.99951 Z m 0,5.000977 v 1.999512 H 20.999268 v -1.999512 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
