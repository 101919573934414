import { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { ITutorialContextState, Tutorial, TutorialAction, TutorialContext } from './TutorialContext'
import { TutorialView } from '../components/tutorialView/TutorialView'
import { DraggableOverWindow } from '../components/draggableOverWindow/DraggableOverWindow'
import { useAuthContext } from './AuthContext'
import { isConsumer, isOperator } from '../shared/Constants'
import { initCustomerTutorials } from '../guide/CustomerTutorials'
import { initOperatorTutorials } from '../guide/OperatorTutorials'

function applyTutorialAction(tutorial: Tutorial, action: TutorialAction): Tutorial {
  if (action === 'previous' && tutorial.stepIndex > 0) {
    return { ...tutorial, stepIndex: tutorial.stepIndex - 1 }
  } else if (action === 'next' && tutorial.stepIndex < tutorial.steps.length - 1) {
    return { ...tutorial, stepIndex: tutorial.stepIndex + 1 }
  } else if (action === 'stop') {
    return { ...tutorial, stepIndex: 0 }
  } else {
    return tutorial
  }
}

export function TutorialContextProvider(props: PropsWithChildren): JSX.Element {
  const { children } = props
  const authContext = useAuthContext()
  const [tutorials, setTutorials] = useState<Tutorial[]>([])
  const [currentTutorialId, setCurrentTutorialId] = useState<string | undefined>()
  const visibleTutorial = tutorials.find(({ id }) => id === currentTutorialId)
  const openTutorialWindow = Boolean(visibleTutorial)
  const onCloseTutorialWindow = () => setCurrentTutorialId(undefined)

  useEffect(() => {
    if (!authContext.loggedProfileType) return

    if (isConsumer(authContext.loggedProfileType)) {
      setTutorials(initCustomerTutorials)
    } else if (isOperator(authContext.loggedProfileType)) {
      setTutorials(initOperatorTutorials)
    } else {
      setTutorials([])
    }
  }, [initCustomerTutorials, initOperatorTutorials, authContext.loggedProfileType])

  const { tutorialTitle, disabledActions } = useMemo(() => {
    const disabledActions: TutorialAction[] = []
    if (!visibleTutorial) {
      return { tutorialTitle: undefined, disabledActions }
    }
    if (visibleTutorial.stepIndex >= visibleTutorial.steps.length - 1) {
      disabledActions.push('next')
    }
    if (visibleTutorial.stepIndex <= 0) {
      disabledActions.push('previous')
    }
    return {
      tutorialTitle: `(${visibleTutorial.stepIndex + 1}/${visibleTutorial.steps.length}) ${visibleTutorial.title}`,
      disabledActions,
    }
  }, [visibleTutorial])

  //useEffect(() => {
  //  // load tutorial (or tutorial state) from somewhere
  //  // setTutorials(...)
  //}, [])

  function showTutorial(tutorialId: string) {
    setCurrentTutorialId(tutorialId)
  }

  function updateTutorial(tutorialId: string, action: TutorialAction) {
    if (action === 'stop') {
      setCurrentTutorialId(undefined)
    }
    setTutorials((tutorials) => {
      const index = tutorials.findIndex(({ id }) => id === tutorialId)
      if (index === -1) {
        return tutorials
      }
      return tutorials.map((t, i) => {
        if (i === index) {
          return applyTutorialAction(t, action)
        }
        return t
      })
    })
  }

  const value: ITutorialContextState = {
    tutorials,
    showTutorial,
    updateTutorial,
  }
  return (
    <TutorialContext.Provider value={value}>
      {children}
      <DraggableOverWindow title={tutorialTitle || ''} open={openTutorialWindow} onClose={onCloseTutorialWindow}>
        {visibleTutorial && (
          <TutorialView
            tutorial={visibleTutorial}
            disabledActions={disabledActions}
            onAction={(action) => updateTutorial(visibleTutorial.id, action)}
            sx={{ height: '100%' }}
          />
        )}
      </DraggableOverWindow>
    </TutorialContext.Provider>
  )
}
