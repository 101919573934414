import { ProfileType } from '../shared/Constants'
import { themeOperatorLight } from './ThemeOperatorLight'
import { themeTutorLight } from './ThemeTutorLight'
import { themeStructureLight } from './ThemeStructureLight'
import { themeCustomerLight } from './ThemeCustomerLight'

export const getProfileBasedTheme = (profileType: ProfileType | undefined) => {
  switch (profileType) {
    case ProfileType.customer:
      return themeCustomerLight
    case ProfileType.operatorAdmin:
      return themeOperatorLight
    case ProfileType.structure:
      return themeStructureLight
    case ProfileType.tutor:
      return themeTutorLight
    case ProfileType.operatorInt:
      return themeOperatorLight
    case ProfileType.operatorExt:
      return themeOperatorLight
    default:
      return themeTutorLight
  }
}
