import { Gender_all, PDX_API_URL, ProfileType, ProfileVisibility } from '../shared/Constants'
import log from '../shared/Logger'
import { axiosInstance } from '../contexts/AuthContext'
import { IAccountJson, Account } from '../models/Account'
import { UriComposer } from '../shared/types/UriComposer'
import { PaginatedResponse } from '../shared/types/PaginatedResponse'
import { AxiosResponse } from 'axios'
import { ProfilePreferences } from '../models/ProfilePreferences'
import { FePreferences } from '../models/FePreferences'

// ********************
// GET

// ********************
// /profiles/:id

export function getAccountFromProfileId(
  abortSignal: AbortSignal,
  profileId: number,
  loadLinkedAccount: boolean,
  loadAllowedActions: boolean
): Promise<Account> {
  const funcName = 'getAccountFromProfileId'
  const url = UriComposer.create(`${PDX_API_URL}/v1/profiles/${profileId}`)
    .addParamBool('with_linked_account', loadLinkedAccount)
    .addParamBool('with_allowed_actions', loadAllowedActions)
    .getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Account.deserialize(resp.data as IAccountJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function getLoggedAvailableAccounts(abortSignal: AbortSignal) {
  const funcName = 'getLoggedAvailableAccounts'
  const url = UriComposer.create(`${PDX_API_URL}/v1/profiles`)
    .addParamBool('with_linked_account', true)
    .getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Account.deserializeArray(resp.data as IAccountJson[])
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// POST

export function createNewAccount(abortSignal: AbortSignal, parms: any): Promise<Account> {
  const funcName = 'createNewAccount'
  let url = `${PDX_API_URL}/v1/auth/register`

  return axiosInstance
    .post(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Account.deserialize(resp.data as IAccountJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// /profiles/search

function deserializeResponse(resp: AxiosResponse<any, any>) {
  let desResp = new PaginatedResponse<Account>()
  desResp.rows = resp.data.rows.map((json: IAccountJson) => {
    const data = Account.deserialize(json as IAccountJson)
    return data
  })
  desResp.page = resp.data.page
  desResp.offset = resp.data.offset
  desResp.limit = resp.data.limit
  desResp.total = resp.data.total
  return desResp
}

export function getStructureAccounts(
  abortSignal: AbortSignal,
  pageNum: number,
  pageSize: number,
  identity?: string,
  name?: string,
  fiscalCode?: string,
  vatNumber?: string,
  isDataProcessingActive?: boolean
): Promise<PaginatedResponse<Account>> {
  const funcName = 'getStructureAccounts'
  let url = `${PDX_API_URL}/v1/profiles/search`

  const parms = {
    offset: pageNum * pageSize,
    limit: pageSize,
    sort: 'name:asc',
    types: [ProfileType.structure],
    data_processing: isDataProcessingActive,
    is_placeholder: false,
    identity: identity,
    name: name,
    fiscal_code: fiscalCode,
    vat_number: vatNumber,
  }

  console.log(parms)

  return axiosInstance
    .post(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = deserializeResponse(resp)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function getOperatorAccounts(
  abortSignal: AbortSignal,
  pageNum: number,
  pageSize: number,
  identity?: string,
  name?: string,
  lastname?: string,
  isDataProcessingActive?: boolean
): Promise<PaginatedResponse<Account>> {
  const funcName = 'getOperatorAccounts'
  let url = `${PDX_API_URL}/v1/profiles/search`

  const parms = {
    offset: pageNum * pageSize,
    limit: pageSize,
    sort: 'lastname:asc,name:asc',
    types: [ProfileType.operatorAdmin, ProfileType.operatorInt, ProfileType.operatorExt],
    data_processing: isDataProcessingActive,
    visibility: ProfileVisibility.all,
    is_placeholder: false,
    with_linked_account: true,
    with_principal_account: true,
    identity: identity,
    name: name,
    last_name: lastname,
  }

  return axiosInstance
    .post(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = deserializeResponse(resp)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function getCollaboratorAccounts(
  abortSignal: AbortSignal,
  pageNum: number,
  pageSize: number,
  structureProfileId: number,
  identity?: string,
  name?: string,
  lastname?: string
): Promise<PaginatedResponse<Account>> {
  const funcName = 'getCollaboratorAccounts'
  let url = `${PDX_API_URL}/v1/profiles/search`

  const parms = {
    offset: pageNum * pageSize,
    limit: pageSize,
    sort: 'lastname:asc,name:asc',
    types: [ProfileType.operatorAdmin, ProfileType.operatorInt, ProfileType.operatorExt],
    linked_profile_ids: [structureProfileId],
    is_placeholder: false,
    with_linked_account: true,
    with_principal_account: true,
    identity: identity,
    name: name,
    last_name: lastname,
  }

  return axiosInstance
    .post(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = deserializeResponse(resp)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function getCustomerAccounts(
  abortSignal: AbortSignal,
  pageNum: number,
  pageSize: number,
  identity?: string,
  name?: string,
  lastname?: string,
  address?: string,
  phone?: string,
  mobilePhone?: string
): Promise<PaginatedResponse<Account>> {
  const funcName = 'getCustomerAccounts'
  let url = `${PDX_API_URL}/v1/profiles/search`

  const parms = {
    offset: pageNum * pageSize,
    limit: pageSize,
    sort: 'lastname:asc,name:asc',
    types: [ProfileType.customer],
    status: 2,
    identity: identity,
    name: name,
    last_name: lastname,
    address: address,
    phone: phone,
    mobile_phone: mobilePhone,
  }

  return axiosInstance
    .post(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = deserializeResponse(resp)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// PATCH

export type UpdateUserArgs = {
  defaultBusinessProfile?: number | null
  defaultConsumerProfile?: number | null
  email?: string
  name?: string
  lastname?: string
  gender?: Gender_all
  birthdate?: string
  birthplace?: string
  fiscalCode?: string
  vatNumber?: string
  // for billing purposes
  country?: string
  city?: string
  province?: string
  street?: string
  streetNumber?: string
  zip?: string
  phone?: string
  mobilePhone?: string
  sdiCode?: string
  pec?: string
}

export function updateUser(abortSignal: AbortSignal, userId: number, args: UpdateUserArgs) {
  const funcName = 'updateUser'
  const url = `${PDX_API_URL}/v1/auth/${userId}`

  let parms = {
    default_business_profile: args.defaultBusinessProfile,
    default_consumer_profile: args.defaultConsumerProfile,
    email: args.email,
    name: args.name,
    lastname: args.lastname,
    gender: args.gender,
    birthdate: args.birthdate,
    birthplace: args.birthplace,
    fiscal_code: args.fiscalCode,
    vat_number: args.vatNumber,
    // for billing purposes
    country: args.country,
    city: args.city,
    province: args.province,
    street: args.street,
    street_number: args.streetNumber,
    zip: args.zip,
    phone: args.phone,
    mobile_phone: args.mobilePhone,
    sdi_code: args.sdiCode,
    pec: args.pec,
  }
  return axiosInstance
    .patch(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Account.deserialize(resp.data as IAccountJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export type UpdateProfileArgs = {
  alias?: string
  avatarImage?: string | null
  title?: string
  phone?: string
  mobilePhone1?: string
  mobilePhone2?: string
  email1?: string
  email2?: string
  email3?: string
  email4?: string
  country?: string
  city?: string
  province?: string
  street?: string
  streetNumber?: string
  zip?: string
  website?: string
  preferences?: ProfilePreferences
  fePreferences?: FePreferences
  extra?: string
  visibility?: number
}

export function updateProfile(abortSignal: AbortSignal, profileId: number, args: UpdateProfileArgs) {
  const funcName = 'updateProfile'
  const url = `${PDX_API_URL}/v1/profiles/${profileId}`

  let parms = {
    alias: args.alias,
    avatar_image: args.avatarImage,
    title: args.title,
    phone: args.phone,
    mobile_phone1: args.mobilePhone1,
    mobile_phone2: args.mobilePhone2,
    email1: args.email1,
    email2: args.email2,
    email3: args.email3,
    email4: args.email4,
    country: args.country,
    city: args.city,
    province: args.province,
    street: args.street,
    street_number: args.streetNumber,
    zip: args.zip,
    website: args.website,
    preferences: args.preferences ? ProfilePreferences.serialize(args.preferences) : undefined,
    dashboard_preferences: args.fePreferences ? JSON.stringify(FePreferences.serialize(args.fePreferences)) : undefined,
    extra: args.extra,
    visibility: args.visibility,
  }
  return axiosInstance
    .patch(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Account.deserialize(resp.data as IAccountJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function updateBillingData(abortSignal: AbortSignal, userId: number, parms: any) {
  const funcName = 'updateBillingData'
  const url = `${PDX_API_URL}/v1/auth/${userId}`
  return axiosInstance
    .patch(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Account.deserialize(resp.data as IAccountJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// DELETE

export function deleteProfile(abortSignal: AbortSignal, profileId: number) {
  const funcName = 'deleteProfile'
  let url = `${PDX_API_URL}/v1/profiles/${profileId}`

  return axiosInstance
    .delete(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = resp.data == profileId
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}
