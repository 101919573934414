import { ConsentMode, PDX_API_URL } from '../shared/Constants'
import log from '../shared/Logger'
import { axiosInstance } from '../contexts/AuthContext'
import { ContractSectionAction } from '../models/ContractSectionAction'
import { ContractSection, IContractSectionJson } from '../models/ContractSection'

// ********************
// POST

export function createNewContractSection(
  abortSignal: AbortSignal,
  contractId: number,
  text: string,
  consentMode: ConsentMode,
  actions: ContractSectionAction[]
): Promise<ContractSection> {
  const funcName = 'createNewContractSection'
  let url = `${PDX_API_URL}/v1/contracts/${contractId}/sections`

  return axiosInstance
    .post(
      url,
      {
        text: text,
        consent_mode: consentMode,
        actions: ContractSectionAction.serializeArray(actions),
      },
      {
        headers: {
          'Accept-Version': '1.0.x',
        },
        signal: abortSignal,
      }
    )
    .then((resp) => {
      const reply = ContractSection.deserialize(resp.data as IContractSectionJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// PATCH

export type UpdateContractSectionArgs = {
  text?: string
  beforeOf?: number
  consentMode?: number
  actions?: ContractSectionAction[]
}

export function updateContractSection(
  abortSignal: AbortSignal,
  contractId: number,
  contractSectionid: number,
  args: UpdateContractSectionArgs
) {
  const funcName = 'updateContractSection'
  let url = `${PDX_API_URL}/v1/contracts/${contractId}/sections/${contractSectionid}`

  const parms = {
    text: args.text,
    before_of: args.beforeOf,
    consent_mode: args.consentMode,
    actions: args.actions,
  }
  return axiosInstance
    .patch(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = ContractSection.deserialize(resp.data as IContractSectionJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function updateContractSectionTreatments(
  abortSignal: AbortSignal,
  contractId: number,
  sectionId: number,
  treatmentsToAdd: number[],
  treatmentsToRemove: number[]
): Promise<number[]> {
  const funcName = 'updateContractSectionTreatments'
  let url = `${PDX_API_URL}/v1/contracts/${contractId}/sections/${sectionId}/treatments`

  return axiosInstance
    .patch(
      url,
      {
        treatments_to_add: treatmentsToAdd,
        treatments_to_remove: treatmentsToRemove,
      },
      {
        headers: {
          'Accept-Version': '1.0.x',
        },
        signal: abortSignal,
      }
    )
    .then((resp) => {
      const reply = resp.data
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// DELETE

export function deleteContractSection(
  abortSignal: AbortSignal,
  contractId: number,
  sectionId: number
): Promise<boolean> {
  const funcName = 'deleteContractSection'
  let url = `${PDX_API_URL}/v1/contracts/${contractId}/sections/${sectionId}`

  return axiosInstance
    .delete(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = resp.data != 0
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}
