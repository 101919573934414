import { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../contexts/AuthContext'
import msgIds from '../../locales/msgIds'
import * as dalContract from '../../dal/DalContract'
import { Contract } from '../../models/Contract'
import { ContractType, ContractTypeTranslationMap, ProfileType } from '../../shared/Constants'
import { Box, IconButton, Paper, useTheme } from '@mui/material'
import { IContractsPageInit } from './ContractsPage.types'
import { GridColDef } from '@mui/x-data-grid'
import CommandBar from '../../components/commandBar/CommandBar'
import { ICommand } from '../../components/commandBar/CommandBar.types'
import { EditIco, PrivacyNewIco } from '../../components/icons'
import ResponsiveDataGrid from '../../components/responsiveDataGrid/ResponsiveDataGrid'
import { Utils } from '../../shared/Utils'
import { IContractPageInit } from '../ContractPage/ContractPage.types'
import { StyledDialog } from '../../dialogs/styledDialog/StyledDialog'
import { ViewHeader } from '../../components/viewHeader/ViewHeader'
import { ViewContent } from '../../components/viewContent/ViewContent'
import { ContractEditor } from '../../components/contractEditor/ContractEditor'
import { IContractEditorData } from '../../components/contractEditor/ContractEditor.types'

export default function ContractsPage() {
  const authContext = useAuthContext()
  const location = useLocation()
  const navigate = useNavigate()

  const [state, setState] = useState<IContractsPageInit>(location.state)
  const { t } = useTranslation()
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const [contracts, setContracts] = useState<Contract[]>([]) // to show items

  // dialogs
  const [contractEditorDialogData, setContractEditorDialogData] = useState<IContractEditorData>()
  const [contractEditorDialogOpen, setContractEditorDialogOpen] = useState(false)

  useEffect(() => {
    // TODO exidea: check init params and verify if login or other actions are required

    if (!isLoading) {
      loadContracts()
    }
  }, [])

  async function loadContracts() {
    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const data = await dalContract.getPublishedContracts(abortController.signal, {
        targetProfileType: ProfileType.customer,
        ownerProfileId:
          state.ownerProfileId || authContext.loggedProfileId || authContext.linkedStructureProfileId || 0,
        getSections: false,
        ignoreSectionRead: true,
        contractType: ContractType.privacyPolicy,
      })
      setContracts(data)
    } catch (err) {
      enqueueSnackbar(t(Utils.getErrorMessageId(err)), { variant: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  function onContractEditorOpen(contract?: Contract) {
    setContractEditorDialogOpen(true)
    setContractEditorDialogData({
      contract: contract,
      onResult: (result) => {
        setContractEditorDialogOpen(false)
        if (result.userChoice !== 'yes') return
        if (result.contract) {
          onContractEditorClose(result.contract)
        }
      },
    })
  }

  function onContractEditorClose(updatedContract: Contract) {
    let updatedContracts
    const contractExists = contracts.some((contract) => contract.id === updatedContract.id)
    if (contractExists) {
      updatedContracts = contracts.map((contract) => (contract.id === updatedContract.id ? updatedContract : contract))
    } else {
      updatedContracts = [...contracts, updatedContract]
    }
    // order by type and name
    updatedContracts.sort((a, b) => {
      const typeComparison = a.type - b.type
      if (typeComparison !== 0) {
        return typeComparison
      }
      return a.name.localeCompare(b.name)
    })
    setContracts(updatedContracts)
  }

  const commands: ICommand[] =
    contracts.length > 0
      ? []
      : [
          {
            commandText: t(msgIds.MSG_CONTRACT_NEW).toString(),
            icon: <PrivacyNewIco />,
            onClick: () => {
              onContractEditorOpen()
            },
            tooltipText: '',
          },
        ]

  const columns: GridColDef<Contract>[] = [
    {
      field: 'name',
      headerName: t(msgIds.MSG_CONTRACT_NAME).toString(),
      minWidth: 120,
      flex: 1.5,
      renderCell: ({ row }) => row.name,
    },
    {
      field: 'type',
      headerName: t(msgIds.MSG_CONTRACT_TYPE).toString(),
      minWidth: 120,
      flex: 1,
      renderCell: ({ row }) => t(ContractTypeTranslationMap[row.type]).toString(),
    },
    {
      field: 'edit',
      headerName: t(msgIds.MSG_MODIFY).toString(),
      minWidth: 100,
      flex: 0.5,
      renderCell: ({ row }) => (
        <IconButton
          size="small"
          edge="start"
          color="inherit"
          aria-label={t(msgIds.MSG_CLEAR)!}
          sx={{ marginRight: 1, color: theme.palette.common.gray4 }}
          onClick={(event) => {
            event.stopPropagation()
            onContractEditorOpen(row)
          }}
        >
          <EditIco />
        </IconButton>
      ),
    },
  ]

  return (
    <>
      <Box>
        <CommandBar title={t(msgIds.MSG_CONTRACT_TITLE)} commands={commands} />
      </Box>
      <Box p={2} my={2}>
        <Paper>
          <ResponsiveDataGrid
            sx={{ minHeight: '120px' }}
            rows={contracts}
            columns={columns}
            onRowClick={({ row }) =>
              navigate('/contracts/versions', {
                state: { contractId: row.id, contractType: row.type } as IContractPageInit,
              })
            }
            hideFooter={true}
            disableRowSelectionOnClick={true}
            disableColumnMenu={true}
          />
        </Paper>
      </Box>

      {contractEditorDialogData && (
        <StyledDialog
          maxWidth="sm"
          open={contractEditorDialogOpen}
          onClose={() => setContractEditorDialogOpen(false)}
          minHeight={100}
        >
          <ViewHeader
            title={t(msgIds.MSG_CONTRACT_EDITOR_DIALOG_TITLE)}
            exitButtonVisible={true}
            onClickExit={() => {
              setContractEditorDialogOpen(false)
            }}
          />
          <ViewContent>
            <ContractEditor {...contractEditorDialogData} />
          </ViewContent>
        </StyledDialog>
      )}
    </>
  )
}
