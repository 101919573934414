import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function SaveIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 16.999999,3 H 4.9999999 C 3.8899999,3 3,3.8999998 3,4.9999999 V 18.999999 C 3,20.1 3.8899999,21 4.9999999,21 H 18.999999 C 20.1,21 21,20.1 21,18.999999 V 6.9999999 Z m 2,15.999999 H 4.9999999 V 4.9999999 H 16.17 l 2.829999,2.8299999 z M 12,12 c -1.66,0 -3.0000003,1.34 -3.0000003,3 0,1.659999 1.3400003,3 3.0000003,3 1.66,0 3,-1.340001 3,-3 0,-1.66 -1.34,-3 -3,-3 z M 5.9999998,5.9999998 H 15 V 9.9999997 H 5.9999998 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
