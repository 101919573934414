import { IAccountsSearchProps } from './AccountsSearch.types'
import { useTranslation } from 'react-i18next'
import { useAuthContext } from '../../contexts/AuthContext'
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemButton,
  Stack,
  useTheme,
} from '@mui/material'
import { CloseIco, DetailsIco, PrivacyIco, SearchIco } from '../icons'
import { useEffect, useRef, useState } from 'react'
import msgIds from '../../locales/msgIds'
import { Account, AccountInfo } from '../../models/Account'
import * as dalAccount from '../../dal/DalAccount'
import * as dalContract from '../../dal/DalContract'
import { PaginatedResponse } from '../../shared/types/PaginatedResponse'
import AccountIdentity from '../identities/AccountIdentity'
import { useNavigate } from 'react-router'
import { ContractType, ProfileType, RegistrationPurpose } from '../../shared/Constants'
import { Utils } from '../../shared/Utils'
import { ISimpleDialogData } from '../../dialogs/simpleDialog/SimpleDialog.types'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'
import { IRegistrationPageProps } from '../../pages/RegistrationPage/RegistrationPage.types'

export function AccountsSearch(props: IAccountsSearchProps): JSX.Element {
  const { searchOption, onAccountSelected } = props
  const theme = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const authContext = useAuthContext()
  const accountsRef = useRef<Account[]>([]) // to manage items loading without re-render
  const inputRef = useRef<HTMLInputElement>(null) // to get and set filter text
  const itemsListRef = useRef<HTMLDivElement>(null) // for scrolling to top automatically on search change
  const [accounts, setAccounts] = useState<Account[]>([]) // to show items

  // dialogs
  const [simpleDialogData, setSimpleDialogData] = useState<ISimpleDialogData>()
  const [simpleDialogOpen, setSimpleDialogOpen] = useState(false)

  useEffect(() => {
    resetSearch()
    setTimeout(() => {
      inputRef.current?.focus()
    }, 300)
  }, [])

  // ********************
  // infinite scroll
  const [isLoading, setIsLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const pageNum = useRef(0)
  const elementRef = useRef(null)

  useEffect(() => {
    const abortController = new AbortController()
    if (searchOption === 'collaborators' || searchOption === 'customers') {
      fetchAccounts(abortController.signal)
    }

    return () => {
      abortController.abort()
    }
  }, [searchOption])

  useEffect(() => {
    const abortController = new AbortController()
    const observer = new IntersectionObserver(
      (entries) => {
        const firstEntry = entries[0]
        if (firstEntry && firstEntry.isIntersecting && hasMore) {
          fetchAccounts(abortController.signal)
        }
      },
      {
        threshold: 1,
      }
    )
    if (observer && elementRef.current) {
      observer.observe(elementRef.current)
    }

    return () => {
      observer?.disconnect()
      abortController.abort()
    }
  }, [accounts])

  const fetchAccounts = async (signal: AbortSignal) => {
    const searchText = inputRef.current?.value
    const canSearchWithoutFilters = searchOption === 'collaborators' || searchOption === 'customers'
    if (!canSearchWithoutFilters) {
      if (!searchText || !(searchText?.replace(/\s/g, '').length > 0)) return
    }
    setIsLoading(true)

    try {
      let data = new PaginatedResponse<Account>()
      switch (searchOption) {
        case 'structures':
          data = await dalAccount.getStructureAccounts(signal, pageNum.current, 15, searchText)
          break
        case 'operators':
          data = await dalAccount.getOperatorAccounts(signal, pageNum.current, 15, searchText)
          break
        case 'collaborators':
          if (authContext.linkedStructureProfileId) {
            data = await dalAccount.getCollaboratorAccounts(
              signal,
              pageNum.current,
              15,
              authContext.linkedStructureProfileId,
              searchText
            )
          }
          break
        case 'customers':
          data = await dalAccount.getCustomerAccounts(signal, pageNum.current, 15, searchText)
          break
      }

      // deduplication
      const currentAccontIds = accountsRef.current.map((o) => o.profile?.profileId)
      const uniqueData = data.rows.filter((p) => p.profile && !currentAccontIds.includes(p.profile.profileId))
      accountsRef.current = [...accountsRef.current, ...uniqueData]
      setAccounts(accountsRef.current)

      pageNum.current += 1
      setHasMore(data.hasMore)
    } catch (err) {
      console.log(err)
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  const resetSearch = (clearSearchText: boolean = true) => {
    if (clearSearchText && inputRef.current?.value) {
      if (inputRef.current) {
        inputRef.current.value = ''
      }
    }

    if (pageNum.current > 0 || accountsRef.current.length > 0 || !hasMore) {
      itemsListRef.current?.scroll({ top: 0 }) // scroll list to top
      pageNum.current = 0
      accountsRef.current = []
      setAccounts(accountsRef.current)
      setHasMore(true)
    }
  }

  const getAccountInfoToShow = (): AccountInfo[] => {
    switch (searchOption) {
      case 'structures':
        return ['main']
      case 'operators':
        return ['main', 'linked']
      case 'collaborators':
        return ['main', 'principal']
      case 'customers':
        return ['main']
      default:
        return []
    }
  }

  function onShowPrivacyPolicy(account: Account) {
    navigate(`/contracts/versions/viewer`, {
      state: {
        grantorProfileId: authContext.loggedProfileId,
        targetProfileType: authContext.loggedProfileType,
        contractOwnerProfileId: account.profile?.profileId,
        contractType: ContractType.privacyPolicy,
        isEditable: true,
      },
    })
  }

  function onGoToAccountPage(account: Account) {
    if (account.profile) {
      navigate(`/account_card`, {
        state: {
          profileId: account.profile?.profileId,
        },
      })
    }
  }

  async function onAddCustomerPlaceholder() {
    if (!authContext.linkedStructureProfileId) return

    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const contracts = await dalContract.getPublishedContracts(abortController.signal, {
        targetProfileType: ProfileType.customer,
        ownerProfileId: authContext.linkedStructureProfileId,
        getSections: true,
        ignoreSectionRead: true,
        contractType: ContractType.privacyPolicy,
      })
      if (contracts.length === 0) {
        setSimpleDialogOpen(true)
        setSimpleDialogData({
          title: t(msgIds.MSG_CREATE_NEW_PLACEHOLDER_USER_TITLE),
          content: t(msgIds.MSG_CREATE_NEW_PLACEHOLDER_USER_BODY),
          actionsStyle: 'Ok',
          onClose: async (result) => {
            setSimpleDialogOpen(false)
          },
        })
      } else {
        const state = {
          profileType: ProfileType.customer,
          purpose: RegistrationPurpose.placeholderRegistration,
          contractTypes: [ContractType.privacyPolicy],
        } as IRegistrationPageProps
        navigate('/registration', { state: state })
        props.onCancel()
      }
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingX: 0,
      }}
    >
      {simpleDialogData && <SimpleDialog {...simpleDialogData} isOpen={simpleDialogOpen}></SimpleDialog>}

      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          borderRadius: '10px',
          backgroundColor: theme.palette.common.gray8,
          marginY: 2,
          marginX: 2,
        }}
      >
        <SearchIco sx={{ color: theme.palette.common.gray5, marginLeft: 1, marginBottom: 1 }} />

        <InputBase
          sx={{
            height: '50px',
            marginLeft: '20px',
            marginRight: '20px',
            flexGrow: 2,
          }}
          inputRef={inputRef}
          placeholder={t(msgIds.MSG_SEARCH) as string}
          inputProps={{ 'aria-label': 'search' }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (accountsRef.current.length > 0) {
              setHasMore(false)
            }
          }}
          onKeyDown={async (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (event.key === 'Enter') {
              event.preventDefault()
              resetSearch(false)
              const abortController = new AbortController()
              await fetchAccounts(abortController.signal)
            }
          }}
        />

        <IconButton
          size="small"
          edge="start"
          color="inherit"
          aria-label={t(msgIds.MSG_CLEAR)!}
          sx={{ marginRight: 1, color: theme.palette.common.gray5 }}
          onClick={() => resetSearch()}
        >
          <CloseIco />
        </IconButton>
      </Box>

      {searchOption === 'customers' && (
        <Button
          sx={{
            alignSelf: 'flex-end',
            justifyContent: 'flex-end',
            marginRight: 2,
            textTransform: 'none',
          }}
          onClick={onAddCustomerPlaceholder}
        >
          {t(msgIds.MSG_CREATE_NEW_PLACEHOLDER_USER)}
        </Button>
      )}

      <Box
        sx={{
          pt: 0,
          flexGrow: 2,
          overflowY: 'auto',
          minHeight: 400,
          maxHeight: 600,
          marginX: 0,
        }}
      >
        <List sx={{ pt: 0 }}>
          {accounts.map((account, index) => (
            <ListItem key={index} disableGutters sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
              <ListItemButton onClick={() => onAccountSelected(account)}>
                <Stack direction={'row'} sx={{ flexGrow: 1 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <AccountIdentity
                      account={account}
                      infoToShow={getAccountInfoToShow()}
                      showProfileInfo={true}
                      avatarClicked={props.onClickAvatar}
                    />
                  </Box>
                  {props.showPrivacyPolicy && (
                    <IconButton
                      size="medium"
                      onClick={(event) => {
                        event.stopPropagation()
                        props.onCancel()
                        onShowPrivacyPolicy(account)
                      }}
                    >
                      <PrivacyIco />
                    </IconButton>
                  )}
                  {props.goToAccountPageOption && (
                    <IconButton
                      size="medium"
                      onClick={(event) => {
                        event.stopPropagation()
                        props.onCancel()
                        onGoToAccountPage(account)
                      }}
                    >
                      <DetailsIco />
                    </IconButton>
                  )}
                </Stack>
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        {accounts.length > 0 && hasMore && !isLoading && inputRef.current?.value && (
          <Box
            ref={elementRef}
            sx={{
              textAlign: 'center',
              minHeight: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {t(msgIds.MSG_LOAD_MORE_ELEMENTS)}
          </Box>
        )}

        {isLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', minHeight: '50px' }}>
            <CircularProgress color="primary" />
          </Box>
        )}
      </Box>
    </Box>
  )
}
