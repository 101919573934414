import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function VisibilityOnIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 12,6 c 3.79,0 7.17,2.13 8.82,5.5 C 19.17,14.87 15.79,16.999999 12,16.999999 8.2100001,16.999999 4.83,14.87 3.18,11.5 4.83,8.13 8.2100001,6 12,6 M 12,4 C 7,4 2.73,7.11 1,11.5 2.73,15.889999 7,19 12,19 16.999999,19 21.27,15.89 22.999999,11.5 21.27,7.11 16.999999,4 12,4 Z m 0,5 c 1.38,0 2.5,1.12 2.5,2.5 C 14.5,12.88 13.38,14 12,14 10.62,14 9.5000001,12.88 9.5000001,11.5 9.5000001,10.12 10.62,9 12,9 M 12,7 C 9.52,7 7.5,9.0200001 7.5,11.5 7.5,13.98 9.52,16 12,16 14.48,16 16.5,13.98 16.5,11.5 16.5,9.0200001 14.48,7 12,7 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
