export interface IInternalDocumentTemplateJson {
  name: string
  dsc: string
  mimetype: string
  content: string
}

export class InternalDocumentTemplate {
  name: string = ''
  dsc: string = ''
  mimetype: string = ''
  content: string = ''

  constructor() {}

  public static serialize(obj: InternalDocumentTemplate): IInternalDocumentTemplateJson {
    const res: IInternalDocumentTemplateJson = {
      name: obj.name,
      dsc: obj.dsc,
      mimetype: obj.mimetype,
      content: obj.content,
    }
    return res
  }

  public static deserialize(json: IInternalDocumentTemplateJson): InternalDocumentTemplate {
    const res = new InternalDocumentTemplate()
    res.name = json.name
    res.dsc = json.dsc
    res.mimetype = json.mimetype
    res.content = json.content
    return res
  }

  public static serializeArray(objs: InternalDocumentTemplate[]): IInternalDocumentTemplateJson[] {
    const jsons = objs.map((p) => {
      return InternalDocumentTemplate.serialize(p)!
    })
    return jsons
  }

  public static deserializeArray(json: IInternalDocumentTemplateJson[]): InternalDocumentTemplate[] {
    const res = json.map((p) => {
      return InternalDocumentTemplate.deserialize(p)!
    })
    return res
  }
}
