import { createContext, PropsWithChildren, useContext, useState } from 'react'
import { SubscribingAction } from '../shared/Constants'
import { Subscription } from '../models/Subscription'

export interface ISubscriptionContextState {
  subscribingAction?: SubscribingAction
  currentPlanInfo?: Subscription
  setSubscribingAction: (action: SubscribingAction) => void
  setCurrentPlanInfo: (subscription?: Subscription) => void
}

const SubscriptionContext = createContext<ISubscriptionContextState>({
  subscribingAction: undefined,
  currentPlanInfo: undefined,
  setSubscribingAction: (action: SubscribingAction) => {},
  setCurrentPlanInfo: (subscription?: Subscription) => {},
})

export interface ISubscriptionContextProviderProps extends PropsWithChildren {}

export function SubscriptionContextProvider(props: ISubscriptionContextProviderProps): JSX.Element {
  const { children } = props
  const [subscribingAction, setSubscribingAction] = useState<SubscribingAction>()
  const [currentPlanInfo, setCurrentPlanInfo] = useState<Subscription>()

  const state: ISubscriptionContextState = {
    subscribingAction,
    currentPlanInfo,
    setSubscribingAction,
    setCurrentPlanInfo,
  }
  return <SubscriptionContext.Provider value={state}>{children}</SubscriptionContext.Provider>
}

export function useSubscriptionContext() {
  const context = useContext(SubscriptionContext)
  if (!context) {
    throw new Error('useSubscriptionContext must be used within a SubscriptionContextProvider')
  }
  return context
}
