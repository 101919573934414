import { useEffect, useRef, MutableRefObject } from 'react'

type RefsObject = {
  [key: string]: HTMLElement | null
}

export function useScrollToRef(scrollTo: string | undefined): (name: string) => (element: HTMLElement | null) => void {
  const refs: MutableRefObject<RefsObject> = useRef({})
  const isMounted = useRef(false)

  useEffect(() => {
    if (scrollTo && refs.current[scrollTo]) {
      refs.current[scrollTo]!.scrollIntoView({ behavior: 'smooth' })
    }
  }, [scrollTo])

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const setRef = (name: string) => (element: HTMLElement | null) => {
    refs.current[name] = element
    if (element && scrollTo === name) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return setRef
}
