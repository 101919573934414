import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function UserNotAnonymousIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 10.25,13 c 0,0.69 -0.56,1.25 -1.25,1.25 -0.69,0 -1.25,-0.56 -1.25,-1.25 0,-0.69 0.56,-1.25 1.25,-1.25 0.69,0 1.25,0.56 1.25,1.25 z M 15,11.75 c -0.69,0 -1.25,0.56 -1.25,1.25 0,0.69 0.56,1.25 1.25,1.25 0.69,0 1.249999,-0.56 1.249999,-1.25 C 16.249999,12.31 15.69,11.75 15,11.75 Z M 22,12 C 22,17.52 17.52,22 12,22 6.48,22 1.9999999,17.52 1.9999999,12 1.9999999,6.48 6.48,1.9999999 12,1.9999999 17.52,1.9999999 22,6.48 22,12 Z M 10.66,4.12 c 1.4,2.3200001 3.94,3.8800001 6.84,3.8800001 0.459999,0 0.909999,-0.049999 1.34,-0.12 C 17.44,5.56 14.9,4 12,4 11.54,4 11.09,4.05 10.66,4.12 Z M 4.42,9.4700001 C 6.13,8.5 7.45,6.9200001 8.08,5.0300001 6.37,6 5.05,7.5800001 4.42,9.4700001 Z M 19.999999,12 c 0,-0.78 -0.12,-1.53 -0.33,-2.24 C 18.97,9.91 18.25,10 17.5,10 14.37,10 11.58,8.56 9.7400001,6.31 8.6900001,8.8700001 6.6,10.88 4,11.86 4.010005,11.9 4,11.95 4,12 c 0,4.41 3.59,7.999999 8,7.999999 4.41,0 7.999999,-3.589999 7.999999,-7.999999 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
