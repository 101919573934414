import { Document } from '../../models/Document'
import { DocumentRevision } from '../../models/DocumentRevision'
import { ArchiveItemSourceType } from '../../shared/Constants'
import { Utils, ValType } from '../../shared/Utils'

export function validateDocumentInfoForm(document: Document, revision: DocumentRevision) {
  const errors: any = {}
  errors.name = Utils.validateText(revision.name, [{ type: ValType.notNull }, { type: ValType.notEmpty }])
  errors.editedAt = Utils.validateDate(revision.editedAt, [{ type: ValType.notNull }, { type: ValType.notEmpty }])
  if (document.sourceType === ArchiveItemSourceType.externalSource) {
    errors.creatorIdentity = Utils.validateText(revision.creatorIdentity, [
      { type: ValType.notNull },
      { type: ValType.notEmpty },
    ])
  }
  return errors
}
