import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function DownloadIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 12.489258,4.8625471 A 6.758595,6.758595 0 0 0 6.022705,8.4902328 5.4100549,5.4100549 0 0 0 6.609375,19.278807 h 11.726806 a 4.4973,4.4973 0 0 0 0.31421,-8.984619 6.758595,6.758595 0 0 0 -6.161133,-5.4316409 z m -0.637207,1.7907716 a 4.9496149,4.9496149 0 0 1 5.031737,3.9924313 l 0.270264,1.353516 1.379883,0.09888 a 2.6912499,2.6912499 0 0 1 -0.197754,5.376708 H 6.609375 A 3.6054549,3.6054549 0 0 1 6.2116699,10.285399 L 7.1762695,10.186522 7.6289062,9.3295882 A 4.9496149,4.9496149 0 0 1 11.852051,6.6533187 Z m -1.138184,3.6057133 v 2.704833 H 8.41333 l 3.60791,3.607911 3.607911,-3.607911 h -2.300538 v -2.704833 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
