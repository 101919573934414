import { HistoryType, PDX_API_URL } from '../shared/Constants'
import log from '../shared/Logger'
import { axiosInstance } from '../contexts/AuthContext'
import { UriComposer } from '../shared/types/UriComposer'
import { AxiosResponse } from 'axios'
import { PaginatedResponse } from '../shared/types/PaginatedResponse'
import { History, IHistoryJson } from '../models/History'

// ********************
// GET

function deserializeResponse(resp: AxiosResponse<any, any>): PaginatedResponse<History> {
  let desResp = new PaginatedResponse<History>()
  desResp.rows = resp.data.rows.map((json: IHistoryJson) => {
    const data = History.deserialize(json as IHistoryJson)
    return data
  })
  desResp.page = resp.data.page
  desResp.offset = resp.data.offset
  desResp.limit = resp.data.limit
  desResp.total = resp.data.total
  return desResp
}

export type GetHistoryArgs = {
  pageNum: number
  pageSize: number
  subjects?: string
  fromDate?: Date
  toDate?: Date
  authorProfileIds?: number[]
  authorLinkedProfileIds?: number[]
}

export function getHistory(abortSignal: AbortSignal, type: HistoryType, args: GetHistoryArgs) {
  const funcName = 'getHistory'
  const url = UriComposer.create(`${PDX_API_URL}/v1/history`)
    .addParamNumber('offset', args.pageNum * args.pageSize)
    .addParamNumber('limit', args.pageSize)
    .addParamDateNullable('created_from', args.fromDate, true, true)
    .addParamDateNullable('created_to', args.toDate, true, true)
    .addParamIntArray('author_profile_id', args.authorProfileIds)
    .addParamIntArray('author_linked_profile_id', args.authorLinkedProfileIds)
    .addParamString('subject', args.subjects)
    .getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = deserializeResponse(resp)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// POST

// ********************
// PATCH

// ********************
// DELETE
