import { iconProps, Tutorial } from '../contexts/TutorialContext'
import {
  AddIco,
  ArchiveIco,
  AuthorizationIco,
  DesktopIco,
  DetailsIco,
  DiaryCustomerIco,
  DocExternalIco,
  DocInternalIco,
  DoxAddIco,
  DoxIco,
  EditIco,
  InvitationIco,
  MoreVertIco,
  PrivacyIco,
  SaveIco,
  StructureIco,
  TermsConditionsIco,
} from '../components/icons'

export const initCustomerTutorials: Tutorial[] = [
  {
    id: 'interact-with-an-operator',
    icon: <PrivacyIco />,
    title: 'Come interagire con un operatore',
    steps: [
      {
        markdown: `
Tramite PersonalDox puoi autorizzare uno o piu' professionisti ad accedere al tuo archivio. Loro invece possono consegnarti i documenti che producono per te.

Per attivare questo scambio di informazioni e' indispensabile che tu prenda visione dell'informativa e, se necessario, che tu fornisca i consensi per il trattamento dei tuoi dati.

Nella barra in alto, al centro, premi sul pulsante per la ricerca delle strutture:
`,
        icon: <StructureIco sx={iconProps} />,
      },
      {
        markdown: `
Nella finestra che compare in alto hai un campo di ricerca. Scrivi il nome della struttura con la quale vuoi interagire. È sufficiente anche solo una parte del nome. Poi premi invio.

Quando l'hai trovata premi sull'icona dei dettagli per accedere alla sua scheda.
`,
        icon: <DetailsIco sx={iconProps} />,
      },
      {
        markdown: `
Guarda le informazioni della scheda e assicurati che sia la struttura corretta.

Nel menu comandi in alto premi su **Informativa privacy**.
`,
        icon: <PrivacyIco sx={iconProps} />,
      },
      {
        markdown: `
Leggi attentamente l'informativa, fornisci la presa visione e gli eventuali consensi per il trattamento dei dati.

**ATTENZIONE:** questa e' l'informativa privacy pubblicata della struttura alla quale affidi i tuoi dati. È la struttura che decide come utilizzare le tue informazioni.

**PersonalDOX** è lo strumento tramite il quale i dati vengono condivisi e supporta te e la struttura affinchè la condivisione avvenga nel rispetto della normativa sulla privacy.
`,
      },
    ],
    stepIndex: 0,
  },
  {
    id: 'write-a-new-document',
    icon: <DocInternalIco />,
    title: 'Scrivi un nuovo documento',
    steps: [
      {
        markdown: `
Puoi scrivere un documento in due modi:
  - dal tuo **archivio personale**, consigliato per lavori veloci
  - dalla **scrivania**, se contemporaneamente ti serve consultare anche altri documenti
  
Lo strumento per scrivere il documento è lo stesso perciò in questò tutorial vedremo come scrivere un documento dall'archivio.

Nell'area comandi a sinistra, all'interno della sezione **Gestione archivio**, premi **Archivio personale**.
  `,
        icon: <DiaryCustomerIco sx={iconProps} />,
      },
      {
        markdown: `Nella barra dei comandi in alto premi sul comando **Scrivi documento**`,
        icon: <DocInternalIco sx={iconProps} />,
      },
      {
        markdown: `
Inserisci il **Titolo**, la **Data** e il testo del documento.

Puoi associare il documento a uno o più **Dox** del tuo archivio premendo sull'apposito pulsante
`,
        icon: <DoxIco sx={iconProps} />,
      },
      {
        markdown: `
Per memorizzare il contenuto del documento è necessario effettuare il salvataggio. Il documento non viene salvato nel dispositivo ma nello spazio cloud di PersonalDOX.

Per procedere con il salvataggio del documento premere il comando **Salva** nella barra degli strumenti.
`,
        icon: <SaveIco sx={iconProps} />,
      },
      {
        markdown: `
Ogni documento, finanto che è in bozza, può essere modificato e salvato a piacimento. Un documento in bozza può essere visionato solamente dal suo autore.

Per rendere il documento accessibile ad altre persone, è necessario procedre con l'**archiviazione** che rende immutabile l'attuale bozza.

Per procedere con l'archiviazione premere il comando **Archivia** nella barra degli strumenti.
`,
        icon: <ArchiveIco sx={iconProps} />,
      },
      {
        markdown: `
Qualora sia necessario modificare il documento, è necessario creare una revisione in bozza.
La bozza può quindi essere modificata e nuovamente archiviata.
**ATTENZIONE**: ogni documento mantiene sempre tutte le sue versioni archiviate.

Per creare una nuova versione del documento in bozza premere il comando **Crea bozza** nella barra degli strumenti.
`,
        icon: <EditIco sx={iconProps} />,
      },
    ],
    stepIndex: 0,
  },
  {
    id: 'load-a-new-document',
    icon: <DocExternalIco />,
    title: 'Carica un nuovo documento',
    steps: [
      {
        markdown: `
Puoi caricare un documento in due modi:
  - dal tuo **archivio personale**, consigliato per lavori veloci
  - dalla **scrivania**, se contemporaneamente ti serve consultare anche altri documenti
  
Lo strumento per caricare il documento è lo stesso perciò in questò tutorial vedremo come caricare un documento dall'archivio.

Nell'area comandi a sinistra, all'interno della sezione **Gestione archivio**, premi **Archivio personale**.
  `,
        icon: <DiaryCustomerIco sx={iconProps} />,
      },
      {
        markdown: `Nella barra dei comandi in alto premi sul comando **Carica documento**`,
        icon: <DocExternalIco sx={iconProps} />,
      },
      {
        markdown: `
Inserisci il **Titolo**, la **Data** e l'**Autore**.
Puoi trascinare un file nel riquadro o usare il comando per la ricerca di un file presente nel tuo dispositivo.
Se non hai ancora impostato il titolo del documento, verrà valorizzato direttamente con il nome del file che hai selezionato.

Puoi associare il documento a uno o più **Dox** del tuo archivio premendo sull'apposito pulsante.
`,
        icon: <DoxIco sx={iconProps} />,
      },
      {
        markdown: `
Per memorizzare il contenuto del documento è necessario effettuare il salvataggio. Il documento non viene salvato nel dispositivo ma nello spazio cloud di PersonalDOX.

Per procedere con il salvataggio del documento premere il comando **Salva** nella barra degli strumenti.
`,
        icon: <SaveIco sx={iconProps} />,
      },
      {
        markdown: `
Ogni documento, finanto che è in bozza, può essere modificato e salvato a piacimento. Un documento in bozza può essere visionato solamente dal suo autore.

Per rendere il documento accessibile ad altre persone, è necessario procedre con l'**archiviazione** che rende immutabile l'attuale bozza.

Per procedere con l'archiviazione premere il comando **Archivia** nella barra degli strumenti.
`,
        icon: <ArchiveIco sx={iconProps} />,
      },
      {
        markdown: `
Qualora sia necessario modificare il documento, è necessario creare una revisione in bozza.
La bozza può quindi essere modificata e nuovamente archiviata.
**ATTENZIONE**: ogni documento mantiene sempre tutte le sue versioni archiviate.

Per creare una nuova versione del documento in bozza premere il comando **Crea bozza** nella barra degli strumenti.
`,
        icon: <EditIco sx={iconProps} />,
      },
    ],
    stepIndex: 0,
  },
  {
    id: 'organize-your_archive',
    icon: <DiaryCustomerIco />,
    title: 'Organizza il tuo archivio',
    steps: [
      {
        markdown: `
L'archivio è lo spazio dove organizzi e conservi tutti i tuoi documenti. Nell'area comandi a sinistra premi **Archivio personale**.
  `,
        icon: <DiaryCustomerIco sx={iconProps} />,
      },
      {
        markdown: `
Per organizzare un archivio si utilizzano i Dox. Ogni Dox può avere al suo interno un numero qualsiasi di altri Dox.
Puoi aggiungere, modificare, cancellare o spostare tutti i tuoi Dox tranne quello che sta alla radice, che ha nome **ARCHIVIO PERSONALE**.

Ogni Dox ha un pulsante per accedere al menu delle funzioni. Ora proviamo ad aggiungere un nuovo Dox.

Premi il pulsante Comandi che si trova alla destra del Dox che conterrà il nuovo Dox.
  `,
        icon: <MoreVertIco sx={iconProps} />,
      },
      {
        markdown: `
Premi il pulsante **Nuovo** per creare il nuovo Dox.
  `,
        icon: <DoxAddIco sx={iconProps} />,
      },
      {
        markdown: `
Nella finestra che si apre puoi:
  - dare un nome al Dox (obbligatorio)
  - scrivere delle note, ad esempio per indicare cosa contiene (facoltativo)

Premi **Salva** per confermare
  `,
      },
      {
        markdown: `
Gli archivi di PersonalDOX hanno una caratteristica importante che li rende differenti dai classici sistemi per l'archiviazione documentale basati su cartelle.

**Ogni documento può essere associato contemporaneamente a più Dox**.

Per questo motivo non avrai mai bisogno di creare più copie dello stesso documento. In PersonalDOX, infatti, non è disponibile il classico comando di Copia/Incolla dei documenti.
  `,
      },
    ],
    stepIndex: 0,
  },
  {
    id: 'use-your_archive',
    icon: <DiaryCustomerIco />,
    title: 'Usa il tuo archivio',
    steps: [
      {
        markdown: `
L'archivio è lo spazio dove organizzi e conservi tutti i tuoi documenti. Sia quelli che crei tu, sia quelli che ti vengono cosegnati dalle struttue che sono in collegamento con te.

Nell'area comandi a sinistra premi **Archivio personale**.
  `,
        icon: <DiaryCustomerIco sx={iconProps} />,
      },
      {
        markdown: `
Per visualizzare solo una parte del tuo archivio hai a disposizione diversi filtri:
  - nell'area dei **Dox** puoi selezionare uno o più Dox per ottenere solo i documenti a loro associati
  - nella finestra **FILTRI** hai diverse altre opzioni

Puoi inoltre ordinare i documenti elencati per **Titolo** o per **Data**
`,
      },
      {
        markdown: `
Se premi su un documento si apre una finestra che mostra l'**anteprima** dello stesso. Vi sono presenti anche altre schede:
  - **Info**: mostra alcune informazioni sul documento
  - **Dox**: elenca i Dox a cui è associato il doucmento. Da qui puoi anche modificare le associazioni.
  - **Revisioni**: elenca tutte le revisioni e l'eventuale bozza del documento. Da qui puoi effettuare il download di ogni versione.
  `,
      },
      {
        markdown: `
Ogni documento, nella propria riga, mette a disposizione tre diversi comandi:
  - Scrivania
  - Associazione a Dox
  - Opzioni

Nei prossimi passi spieghiamo a cosa servono.
  `,
      },
      {
        markdown: `
Il comando **Scrivania** consente di mettere o togliere un documento dalla scrivania. La scrivania è l'area dove puoi consultare comodamente più documenti nello stesso tempo.

Premilo per mettere un documento nella scrivania, premilo nuovamente per togliere il documento dalla scrivania.
Il pulsante è così raffigurato:
`,
        icon: <DesktopIco sx={iconProps} />,
      },
      {
        markdown: `
Il comando **Associa a Dox** consente di associare (o dissociare) il documento dai dox del tuo archivio.
Si aprie una finestra nella quale puoi navigare tutti i Dox del tuo archivio, crearne di nuovi o modificare quelli esistenti.

Il pulsante è così raffigurato:
`,
        icon: <DoxIco sx={iconProps} />,
      },
      {
        markdown: `
Il comando **Opzioni** apre un menu che elenca le operazioni che puoi fare sul documento stesso.
Le operazioni disponibili variano in base allo stato del documento e alle tue autorizzazioni.
Questo è l'elenco completo:
  - **Associa a Dox**: per associare il documento a uno dei Dox dell'archivio
  - **Autorizzazioni**: per autorizzare una struttura ad accedere al documento\n(NOTA: per la condivisione è più comodo autorizzare l'accesso al Dox)
  - **Dichiara obsoleto**: per invalidare il documento (solo se ne sei l'autore)
  - **Cronologia**: per visualizzare gli eventi riguardanti la vita del documento
  - **Aggiungi alla scrivania**: per visualizzare il documento nella scrivania
  - **Archivia**: per ufficializzare il documento rendendolo immodificabile
  - **Crea/Modifica bozza**: per creare una nuova bozza per la revisione del documento
  - **Download**: per scaricare il documento nel tuo dispositivo
  - **Elimina**: per eliminare il documento (se ne sei l'autore e se non è mai stato visualizzato da un altro utente)

Il pulsante è così raffigurato:
`,
        icon: <MoreVertIco sx={iconProps} />,
      },
    ],
    stepIndex: 0,
  },
  {
    id: 'share-dox-and-documents',
    icon: <AuthorizationIco />,
    title: 'Condividi dox e documenti',
    steps: [
      {
        markdown: `
L'archivio è lo spazio dove organizzi e conservi tutti i tuoi documenti. Sia quelli che crei tu, sia quelli che ti vengono cosegnati dalle struttue che sono in collegamento con te.
Per questo tutorial è necessario che nel tuo archivio ci sia già almeno un Dox e un documento archiviato

Nell'area comandi a sinistra premi **Archivio personale**.
`,
        icon: <DiaryCustomerIco sx={iconProps} />,
      },
      {
        markdown: `
Puoi condividere un singolo documento o un intero Dox.
Condividere un Dox è più comodo perchè puoi cambiare i documenti visibili all'operatore semplicemente aggiungendo o rimuovendo i documenti dal Dox condiviso.

In questo tutorial vedremo come condividere un Dox.

Premi il pulsante Comandi che si trova alla destra del Dox da condividere.
  `,
        icon: <MoreVertIco sx={iconProps} />,
      },
      {
        markdown: `
Premi sul comando **Autorizzazioni**.
  `,
        icon: <AuthorizationIco sx={iconProps} />,
      },
      {
        markdown: `
Nella finesta Autorizzazioni vengono elencati i soggetti autorizzati ad accedere al Dox.

Per aggiungere una nuova struttura premi il pulsante in alto a destra:
  `,
        icon: <AddIco sx={iconProps} />,
      },
      {
        markdown: `
Nella finestra che compare in alto hai un campo di ricerca. Scrivi il nome della struttura che vuoi autorizzare. È sufficiente anche solo una parte del nome. Poi premi invio.

Quando l'hai trovata premi sulla riga corrispondente.
Verrai riportato alla schermata precedente.
  `,
      },
      {
        markdown: `
Nella finestra delle autorizzazioni ora vedi una nuova riga che riposta il nome della struttura autorizzata.
Alla sinistra dell'avatar della struttura è riportato un simbolo che ha uno dei seguenti significati:
  - **simbolo verde**: l'autorizzazione è attiva e la struttura può accedere al Dox e ai documenti associati
  - **simbolo rosso con valigetta**: l'autorizzazione non è attiva perchè non hai ancora preso visione dell'informativa

Se il simbolo è rosso e presenta l'immagine di una valigetta premilo
  `,
        icon: <TermsConditionsIco sx={iconProps} />,
      },
      {
        markdown: `
Ora sei nella pagina che presenta l'informativa privacy pubblicata dalla struttura. Prendi visione dell'informativa e se lo ritieni opportuno fornisci i consensi.
Infine, premi salva per confermare le tue scelte.
  `,
      },
    ],
    stepIndex: 0,
  },
  {
    id: 'authorize-a-structure-to-send-you-an-invitatio',
    icon: <AuthorizationIco />,
    title: 'Autorizza una struttura a mandarti un invito',
    steps: [
      {
        markdown: `
PersonalDox consente alle strutture di coinvolgere dei collaboratori, ad esempio a seguito di assunzione.
In tal caso la struttura manda un invito all'utente privato ma, per poter effettuare tale operazione deve prima essere autorizzata.

Per autorizzare una struttura ad invitarti, nella barra in alto, al centro, premi sul pulsante per la ricerca delle strutture:
`,
        icon: <StructureIco sx={iconProps} />,
      },
      {
        markdown: `
Nella finestra che compare in alto hai un campo di ricerca. Scrivi il nome della struttura con la quale vuoi interagire. È sufficiente anche solo una parte del nome. Poi premi invio.

Quando l'hai trovata premi sull'icona dei dettagli per accedere alla sua scheda.
`,
        icon: <DetailsIco sx={iconProps} />,
      },
      {
        markdown: `
Guarda le informazioni della scheda e assicurati che sia la struttura corretta.

Nel menu comandi in alto premi su **Autorizza invito**.
`,
        icon: <InvitationIco sx={iconProps} />,
      },
      {
        markdown: `
Leggi il messaggio della finestra che si apre e dai conferma premendo **Sì**.
La struttura autorizzata riceverà subito una notifica e avrà **tre** giorni per inviarti l'invito.

Riceverai l'invito della struttura nelle notifiche di PersonalDOX.
Premi sul comando all'interno della notifica per accettare l'invito.
`,
      },
    ],
    stepIndex: 0,
  },
]
