import { IUsersAuthManagementFormProps } from './AccountsViewManagementForm.types'
import DataEditorHeader from '../dataEditorHeader/DataEditorHeader'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { EditIco } from '../icons'
import { AccountsViewManagement } from './AccountsViewManagement'
import { DataEditorContainer } from '../dataEditorContainer/DataEditorContainer'

export function AccountsViewManagementForm(props: IUsersAuthManagementFormProps): JSX.Element {
  const { t } = useTranslation()
  const [isEditMode, setIsEditMode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  return (
    <DataEditorContainer isLoading={isLoading}>
      <DataEditorHeader
        title={t(msgIds.MSG_USERS_AUTH_TITLE)}
        isEditMode={isEditMode}
        viewModeCommands={[
          {
            commandText: '',
            icon: <EditIco />,
            onClick: () => setIsEditMode(true),
            tooltipText: t(msgIds.MSG_MODIFY) ?? undefined,
            disabled: false,
          },
        ]}
        editModeCommands={[]}
      />
      <AccountsViewManagement
        profileId={props.profileId}
        structureProfileId={props.structureProfileId}
        permissions={props.permissions}
        isEditMode={isEditMode}
        onSave={() => setIsEditMode(false)}
        onCancel={() => setIsEditMode(false)}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </DataEditorContainer>
  )
}
