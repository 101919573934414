import { Box, Stack, useTheme } from '@mui/material'
import { TutorialActionBar } from '../tutorialActionBar/TutorialActionBar'
import MarkdownViewerNew from '../markdownEditorNew/MarkdownViewerNew'
import { ITutorialViewProps } from './TutorialView.types'

export function TutorialView(props: ITutorialViewProps): JSX.Element {
  const { tutorial, disabledActions, onAction, sx } = props
  const theme = useTheme()
  const tutorialStep = tutorial.steps[tutorial.stepIndex]
  return (
    <Stack sx={sx}>
      <Box
        flex={1}
        p={2}
        sx={{
          overflow: 'auto',
          maxHeight: 'calc(400px - 150px)',
        }}
      >
        {tutorialStep && (
          <Stack alignItems={'center'}>
            <MarkdownViewerNew initialValue={tutorialStep.markdown} justify={true} />
            {tutorialStep.icon && (
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  padding: 2,
                  borderRadius: 20,
                  backgroundColor: theme.palette.common.gray8,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {tutorialStep.icon}
              </Box>
            )}
          </Stack>
        )}
      </Box>
      <TutorialActionBar disabledActions={disabledActions} onAction={onAction} />
    </Stack>
  )
}
