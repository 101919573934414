export interface INotificationContextDoxIdsJson {
  dox_id: number
  document_ids: number[]
}

export class NotificationContextDoxIds {
  doxId: number = 0
  documentIds: number[] = []

  constructor() {}

  public static serialize(obj: NotificationContextDoxIds): INotificationContextDoxIdsJson {
    return {
      dox_id: obj.doxId,
      document_ids: obj.documentIds,
    }
  }

  public static deserialize(json: INotificationContextDoxIdsJson): NotificationContextDoxIds {
    const res = new NotificationContextDoxIds()
    res.doxId = json.dox_id
    res.documentIds = json.document_ids
    return res
  }

  public static serializeArray(objs: NotificationContextDoxIds[]): INotificationContextDoxIdsJson[] {
    const jsons = objs.map((p) => {
      return NotificationContextDoxIds.serialize(p)!
    })
    return jsons
  }

  public static deserializeArray(json: INotificationContextDoxIdsJson[]): NotificationContextDoxIds[] {
    const res = json.map((p) => {
      return NotificationContextDoxIds.deserialize(p)!
    })
    return res
  }
}
