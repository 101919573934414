import { HistoryContextDocumentRevision, IHistoryContextDocumentRevisionJson } from './HistoryContextDocumentRevision'

export interface IHistoryContextDocumentJson {
  id: number // last archived revision id
  document_id: number
  name: string
  mimetype: string
  author_profile_id: number
  content_type: number
  draft_revision_id?: number
  last_archived_revision_id?: number
  delivered_at?: string
  target_profile_id: number
  owner_profile_id: number
  source_type: number
  last_archived_revision?: IHistoryContextDocumentRevisionJson
  oldRevision?: IHistoryContextDocumentRevisionJson
  newRevision?: IHistoryContextDocumentRevisionJson
  days_countdown?: number
}

export class HistoryContextDocument {
  id: number = 0
  documentId: number = 0
  name: string = ''
  mimetype: string = ''
  authorProfileId: number = 0
  contentType: number = 0
  draftRevisionId?: number
  lastArchivedRevisionId?: number
  deliveredAt?: Date
  targetProfileId: number = 0
  ownerProfileId: number = 0
  sourceType: number = 0
  lastArchivedRevision?: HistoryContextDocumentRevision
  oldRevision?: HistoryContextDocumentRevision
  newRevision?: HistoryContextDocumentRevision
  daysCountdown?: number

  constructor() {}

  public static serialize(obj: HistoryContextDocument): IHistoryContextDocumentJson {
    return {
      id: obj.id,
      document_id: obj.documentId,
      name: obj.name,
      mimetype: obj.mimetype,
      author_profile_id: obj.authorProfileId,
      content_type: obj.contentType,
      draft_revision_id: obj.draftRevisionId,
      last_archived_revision_id: obj.lastArchivedRevisionId,
      delivered_at: obj.deliveredAt ? obj.deliveredAt.toISOString() : undefined,
      target_profile_id: obj.targetProfileId,
      owner_profile_id: obj.ownerProfileId,
      source_type: obj.sourceType,
      last_archived_revision: obj.lastArchivedRevision
        ? HistoryContextDocumentRevision.serialize(obj.lastArchivedRevision)
        : undefined,
      oldRevision: obj.oldRevision ? HistoryContextDocumentRevision.serialize(obj.oldRevision) : undefined,
      newRevision: obj.newRevision ? HistoryContextDocumentRevision.serialize(obj.newRevision) : undefined,
      days_countdown: obj.daysCountdown,
    }
  }

  public static deserialize(json: IHistoryContextDocumentJson): HistoryContextDocument {
    const res = new HistoryContextDocument()
    res.id = json.id
    res.documentId = json.document_id || json.last_archived_revision?.document_id || json.id || 0 // TODO: standardize the BE side structure
    res.name = json.name
    res.mimetype = json.mimetype
    res.authorProfileId = json.author_profile_id
    res.contentType = json.content_type
    res.draftRevisionId = json.draft_revision_id
    res.lastArchivedRevisionId = json.last_archived_revision_id
    res.deliveredAt = json.delivered_at ? new Date(json.delivered_at) : undefined
    res.targetProfileId = json.target_profile_id
    res.sourceType = json.source_type
    res.lastArchivedRevision = json.last_archived_revision
      ? HistoryContextDocumentRevision.deserialize(json.last_archived_revision)
      : undefined
    res.oldRevision = json.oldRevision ? HistoryContextDocumentRevision.deserialize(json.oldRevision) : undefined
    res.newRevision = json.newRevision ? HistoryContextDocumentRevision.deserialize(json.newRevision) : undefined
    res.daysCountdown = json.days_countdown
    return res
  }
}
