import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function NotificationAuthorizationIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 17.925,5.1000033 a 1.09,1.09 0 0 0 -0.425,0.05 v -0.25 a 2.095,2.095 0 0 0 -2.065,-2.1 2.185,2.185 0 0 0 -0.785,0.15 1.9999999,1.9999999 0 0 0 -1.65,-0.9 1.9999999,1.9999999 0 0 0 -2,1.95 c -0.26,0 -0.365,-0.1550001 -0.85,0.075 A 2.8899999,2.8899999 0 0 0 8.4999999,6.6950032 v 7.3049998 l -1.965,-1.05 a 1.71,1.71 0 0 0 -1.8699999,0.2 l -1.665,1.5 5.9999998,6.35 a 3.2049999,3.2049999 0 0 0 2.3600002,1 h 5.29 a 3.3499999,3.3499999 0 0 0 3.349999,-3.349999 V 7.2000033 c -0.105,-1.1500001 -0.849999,-2.1 -2.074999,-2.1 z M 18.499999,18.650004 C 18.4,19.550004 16.95,20.500003 16.95,20.500003 H 11.5 a 5.6899999,5.6899999 0 0 1 -1.2,-0.7 l -4.9,-5.1 0.3999999,-0.35 4.2,2.350001 V 6.0000033 C 10.25,5.7500032 10.45,5.6000032 10.65,5.6000032 A 0.33,0.33 0 0 1 11,6.0000033 v 5.9999997 h 1.5 V 4.1000032 a 0.85499998,0.85499998 0 0 1 0.6,-0.3999999 0.39499999,0.39499999 0 0 1 0.4,0.3999999 V 12.000003 H 15 V 4.8500032 A 0.69999999,0.69999999 0 0 1 15.55,4.4500033 0.49999999,0.49999999 0 0 1 16,4.8500032 v 7.1499998 h 1.5 V 7.1500032 A 0.575,0.575 0 0 1 18,6.7500033 0.66499999,0.66499999 0 0 1 18.499999,7.1500032 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
