import React, { useEffect, useRef, useState } from 'react'
import { ICommand, ICommandBarProps } from './CommandBar.types'
import {
  Box,
  Breakpoint,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  ToolbarProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { MoreVertIco } from '../icons'

const CommandBar = (props: ICommandBarProps & ToolbarProps) => {
  const { title, commands, disabled = false, style, ...rest } = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [mobileMenu, setMobileMenu] = useState(false)
  const theme = useTheme()
  const menuRef = useRef() as React.MutableRefObject<HTMLInputElement>
  const widthBoxRef = useRef() as React.MutableRefObject<HTMLInputElement>

  // Se l'altezza del menu supera la soglia sarà mostrato il menu mobile (v. handleWindowResize)
  const heightThreshold = 50

  // Se la larghezza di widthBoxRef è inferiore a widthBoxRefThreshold mostrare il menù mobile
  const widthBoxRefThreshold = 20

  // Breakpoint adattivo
  let breakpoint: Breakpoint = 'xs'
  if (commands.length > 2 && commands.length < 5) {
    breakpoint = 'sm'
  } else if (commands.length >= 5 && commands.length < 8) {
    breakpoint = 'md'
  } else if (commands.length >= 8) {
    breakpoint = 'lg'
  }
  const isTablet = useMediaQuery(theme.breakpoints.down(breakpoint))
  const borderRadius = '32px'

  const open = !!anchorEl
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCommandClick = (command: ICommand) => {
    setAnchorEl(null)
    if (command.onClick) {
      command.onClick()
    }
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleWindowResize = () => {
    if (widthBoxRef.current.clientWidth < widthBoxRefThreshold || menuRef.current.clientHeight > heightThreshold) {
      setMobileMenu(true)
    } else if (widthBoxRef.current.clientWidth > commands.length * 200) {
      setMobileMenu(false)
    }
  }

  useEffect(() => {
    handleWindowResize()

    //TODO: attivare le righe commentate per abilitare il meccanismo adattivo
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return (
    <Toolbar style={{ width: '100%', paddingLeft: 0, minHeight: 0, paddingRight: 0, ...style }} {...rest}>
      <Grid container alignItems="center" spacing={0} sx={{ flexWrap: 'nowrap' }}>
        <Grid
          sx={{
            backgroundColor: theme.palette.grey['400'],
            py: 1,
            px: 2,
            mb: 0,
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            borderTopRightRadius: borderRadius,
            borderBottomRightRadius: borderRadius,
            alignSelf: 'flex-start',
          }}
          item
          flexGrow={1}
        >
          <Typography variant="body1" noWrap overflow="ellipsis">
            <strong>{title}</strong>
          </Typography>
          <Box ref={widthBoxRef} flexGrow={1}></Box>
        </Grid>
        <Grid px={1} ref={menuRef} item>
          {isTablet || mobileMenu ? (
            <>
              <IconButton
                aria-label="more"
                id="commands-menu-button"
                aria-controls={open ? 'commands-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleMenuClick}
              >
                <MoreVertIco />
              </IconButton>
              <Menu
                id="commands-menu"
                MenuListProps={{ 'aria-labelledby': 'commands-menu-button' }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                {commands.map((command, i) => (
                  <MenuItem key={i} onClick={() => handleCommandClick(command)} disabled={command.disabled}>
                    {command.icon}
                    <span style={{ width: '5px', textTransform: 'none' }} />
                    {command.commandText}
                  </MenuItem>
                ))}
              </Menu>
            </>
          ) : (
            commands.map((command, index) => (
              <Button
                key={index}
                onClick={command.onClick}
                color={command.color || 'secondary'}
                variant="text"
                disabled={command.disabled}
                style={{ textTransform: 'none' }}
              >
                {command.icon}
                <span style={{ width: '5px' }} />
                {command.commandText}
              </Button>
            ))
          )}
        </Grid>
      </Grid>
    </Toolbar>
  )
}

export default CommandBar
