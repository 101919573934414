import {
  RetentionDuration,
  RetentionDuration_all,
  RetentionEndBehavior,
  RetentionEndBehavior_all,
  TreatmentLegalBase,
  TreatmentLegalBase_all,
} from '../shared/Constants'
import { ITreatedDataTypesJson, TreatedDataTypes } from './TreatedDataTypes'

export interface IHistoryContextTreatmentJson {
  id: number
  owner_profile_id: number
  legal_base: number
  data_types?: string
  retention_duration_base: number
  retention_duration_count: number
  retention_end_behavior: number
  retention_notice_days_before_expiration?: number
  code: string
  short_description: string
  description: string
  disabled_at?: string
}

export class HistoryContextTreatment {
  id: number = 0
  ownerProfileId: number = 0
  legalBase: TreatmentLegalBase_all = TreatmentLegalBase.explicitConsent
  dataTypes?: TreatedDataTypes
  retentionDurationBase: RetentionDuration_all = RetentionDuration.none
  retentionDurationCount: number = 0
  retentionEndBehavior: RetentionEndBehavior_all = RetentionEndBehavior.none
  retentionNoticeDaysBeforeExpiration?: number
  code: string = ''
  shortDescription: string = ''
  description: string = ''
  disabledAt?: Date

  constructor() {}

  public static serialize(obj: HistoryContextTreatment): IHistoryContextTreatmentJson {
    return {
      id: obj.id,
      owner_profile_id: obj.ownerProfileId,
      legal_base: obj.legalBase,
      data_types: obj.dataTypes ? JSON.stringify(TreatedDataTypes.serialize(obj.dataTypes)) : undefined,
      retention_duration_base: obj.retentionDurationBase,
      retention_duration_count: obj.retentionDurationCount,
      retention_end_behavior: obj.retentionEndBehavior,
      retention_notice_days_before_expiration: obj.retentionNoticeDaysBeforeExpiration,
      code: obj.code,
      short_description: obj.shortDescription,
      description: obj.description,
      disabled_at: obj.disabledAt?.toISOString(),
    }
  }

  public static deserialize(json: IHistoryContextTreatmentJson): HistoryContextTreatment {
    const res = new HistoryContextTreatment()
    res.id = json.id
    res.ownerProfileId = json.owner_profile_id
    res.legalBase = json.legal_base
    res.dataTypes = json.data_types
      ? TreatedDataTypes.deserialize(JSON.parse(json.data_types) as ITreatedDataTypesJson)
      : undefined
    res.retentionDurationBase = json.retention_duration_base
    res.retentionDurationCount = json.retention_duration_count
    res.retentionEndBehavior = json.retention_end_behavior
    res.retentionNoticeDaysBeforeExpiration = json.retention_notice_days_before_expiration
    res.code = json.code
    res.shortDescription = json.short_description
    res.description = json.description
    res.disabledAt = json.disabled_at ? new Date(json.disabled_at) : undefined
    return res
  }
}
