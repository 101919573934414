import { PDX_API_URL } from '../shared/Constants'
import log from '../shared/Logger'
import { axiosInstance } from '../contexts/AuthContext'
import { UriComposer } from '../shared/types/UriComposer'
import { ContractVersion, IContractVersionJson } from '../models/ContractVersion'

// ********************
// GET

export function getContractVersion(
  abortSignal: AbortSignal,
  contractId: number,
  versionId: number
): Promise<ContractVersion> {
  const funcName = 'getContractVersion'
  const url = UriComposer.create(`${PDX_API_URL}/v1/contracts/${contractId}/versions/${versionId}`).getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = ContractVersion.deserialize(resp.data as IContractVersionJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function downloadContractVersion(
  abortSignal: AbortSignal,
  contractId: number,
  versionId: number,
  grantorProfileId?: number
) {
  const funcName = 'downloadContractVersion'
  const url = UriComposer.create(`${PDX_API_URL}/v1/contracts/${contractId}/versions/${versionId}/download`)
    .addParamNumberNullable('grantor_profile_id', grantorProfileId)
    .getCompleteUri()

  return axiosInstance
    .get(url, {
      responseType: 'arraybuffer',
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = new Uint8Array(resp.data)
      return reply.buffer
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// POST

export function createNewContractVersion(
  abortSignal: AbortSignal,
  contractId: number,
  areNewConsentsRequired: boolean
): Promise<ContractVersion> {
  const funcName = 'createNewContractVersion'
  let url = `${PDX_API_URL}/v1/contracts/${contractId}/versions`

  return axiosInstance
    .post(
      url,
      {
        are_new_consents_required: areNewConsentsRequired,
      },
      {
        headers: {
          'Accept-Version': '1.0.x',
        },
        signal: abortSignal,
      }
    )
    .then((resp) => {
      const reply = ContractVersion.deserialize(resp.data as IContractVersionJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// PATCH

export function publishContractVersion(abortSignal: AbortSignal, contractId: number): Promise<ContractVersion> {
  const funcName = 'publishContractVersion'
  let url = `${PDX_API_URL}/v1/contracts/${contractId}/versions`

  return axiosInstance
    .patch(
      url,
      {
        publish: true,
      },
      {
        headers: {
          'Accept-Version': '1.0.x',
        },
        signal: abortSignal,
      }
    )
    .then((resp) => {
      const reply = ContractVersion.deserialize(resp.data as IContractVersionJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export type UpdateContractVersionMetadataArgs = {
  notes?: string
  isNewAcknowledgmentRequired?: boolean
  avoidNewAcknowledgmentExplanation?: string
  areNewConsentsRequired?: boolean
  avoidNewConsentsExplanation?: string
}

export function updateContractVersionMetadata(
  abortSignal: AbortSignal,
  contractId: number,
  args: UpdateContractVersionMetadataArgs
): Promise<ContractVersion> {
  const funcName = 'updateContractVersionMetadata'
  let url = `${PDX_API_URL}/v1/contracts/${contractId}/versions`

  const parms = {
    notes: args.notes,
    is_new_acknowledgment_required: args.isNewAcknowledgmentRequired,
    are_new_consents_required: args.areNewConsentsRequired,
    avoid_new_acknowledgment_explanation: args.avoidNewAcknowledgmentExplanation,
    avoid_new_consents_explanation: args.avoidNewConsentsExplanation,
  }
  return axiosInstance
    .patch(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = ContractVersion.deserialize(resp.data as IContractVersionJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// DELETE

export function deleteContractVersion(abortSignal: AbortSignal, contractId: number): Promise<boolean> {
  const funcName = 'deleteContractVersion'
  let url = `${PDX_API_URL}/v1/contracts/${contractId}/versions`

  return axiosInstance
    .delete(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = resp.data != 0
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}
