import { INotificationContextContractJson, NotificationContextContract } from './NotificationContextContract'

export interface INotificationContextContractVersionJson {
  id: number
  major_version: number
  minor_version: number
  published_at?: string
  is_new_acknowledgment_required: boolean
  are_new_consents_required: boolean
  has_new_sections_with_consents: boolean
  contract_id: number
  contract?: INotificationContextContractJson
}

export class NotificationContextContractVersion {
  id: number = 0
  majorVersion: number = 0
  minorVersion: number = 0
  publishedAt?: Date
  isNewAcknowledgmentRequired: boolean = false
  areNewConsentsRequired: boolean = false
  hasNewSectionsWithConsents: boolean = false
  contractId: number = 0
  contract?: NotificationContextContract

  constructor() {}

  public static serialize(obj: NotificationContextContractVersion): INotificationContextContractVersionJson {
    return {
      id: obj.id,
      major_version: obj.majorVersion,
      minor_version: obj.minorVersion,
      published_at: obj.publishedAt?.toISOString(),
      is_new_acknowledgment_required: obj.isNewAcknowledgmentRequired,
      are_new_consents_required: obj.areNewConsentsRequired,
      has_new_sections_with_consents: obj.hasNewSectionsWithConsents,
      contract_id: obj.contractId,
      contract: obj.contract ? NotificationContextContract.serialize(obj.contract) : undefined,
    }
  }

  public static deserialize(json: INotificationContextContractVersionJson): NotificationContextContractVersion {
    const res = new NotificationContextContractVersion()
    res.id = json.id
    res.majorVersion = json.major_version
    res.minorVersion = json.minor_version
    res.publishedAt = json.published_at ? new Date(json.published_at) : undefined
    res.isNewAcknowledgmentRequired = json.is_new_acknowledgment_required
    res.areNewConsentsRequired = json.are_new_consents_required
    res.hasNewSectionsWithConsents = json.has_new_sections_with_consents
    res.contractId = json.contract_id
    res.contract = json.contract ? NotificationContextContract.deserialize(json.contract) : undefined
    return res
  }
}
