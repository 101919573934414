import { useLocation } from 'react-router'
import { Paper, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import * as dalAccount from '../../dal/DalAccount'
import { Account } from '../../models/Account'
import { PageContainer, PageContent } from '../../components/pageContainer/PageContainer'
import CommandBar from '../../components/commandBar/CommandBar'
import { PasswordEditorForm } from '../../components/passwordEditor/PasswordEditorForm'
import { TotpEditorForm } from '../../components/totpEditor/TotpEditorForm'
import { ConfigurationsAuthorizationsForm } from '../../components/configurationsAuthorizations/ConfigurationsAuthorizationsForm'
import { NotificationsSettingsForm } from '../../components/notificationsSettings/NotificationsSettingsForm'
import { GeneralSettingsForm } from '../../components/generalSettings/GeneralSettingsForm'
import { useEffect, useState } from 'react'
import { Utils } from '../../shared/Utils'
import { useAuthContext } from '../../contexts/AuthContext'
import { isConsumer, isOperator, isStructure } from '../../shared/Constants'
import { DocumentsTemplatesForm } from '../../components/documentsTemplates/DocumentsTemplatesForm'
import { DoxesTemplatesForm } from '../../components/doxesTemplates/DoxesTemplatesForm'
import { IAccountConfigurationsPageProps } from './AccountConfigurationsPage.types'
import { useScrollToRef } from '../../hooks/ScrollTo'
import { useLayout } from '../../hooks/Layout'
import AccountLoadingIndicator from '../../components/loadingIndicators/AccountLoadingIndicator'

export default function AccountConfigurationsPage() {
  const location = useLocation()
  const state = location.state as IAccountConfigurationsPageProps
  const profileId: number = state.profileId ?? 0
  const scrollTo: string | undefined = state.scrollTo

  const { t } = useTranslation()
  const { isMobile } = useLayout()
  const [isLoading, setIsLoading] = useState(false)
  const authContext = useAuthContext()
  const setRef = useScrollToRef(scrollTo)

  const [account, setAccount] = useState<Account>()

  useEffect(() => {
    async function LoadAccountDetails(abortSignal: AbortSignal) {
      try {
        setIsLoading(true)
        const data = await dalAccount.getAccountFromProfileId(abortSignal, profileId, true, false)
        setAccount(data)
      } catch (err) {
        Utils.enqueueSnackbarError2(err, t)
      } finally {
        setIsLoading(false)
      }
    }

    const abortController = new AbortController()
    if (profileId && profileId !== authContext.loggedProfileId) {
      LoadAccountDetails(abortController.signal)
    } else {
      setAccount(authContext.loggedAccount)
    }

    return () => {
      abortController.abort()
    }
  }, [profileId])

  const consumer = account?.profile ? isConsumer(account.profile.type) : false
  const operator = account?.profile ? isOperator(account.profile.type) : false
  const structure = account?.profile ? isStructure(account.profile.type) : false

  return (
    <PageContainer>
      <CommandBar style={{ minHeight: 'auto' }} title={t(msgIds.MSG_ACCOUNT_CONFIGURATIONS_PAGE_TITLE)} commands={[]} />

      {isLoading ? (
        <AccountLoadingIndicator account={account} isLoading={isLoading} />
      ) : (
        <PageContent>
          {account && (
            <Stack spacing={isMobile ? 4 : 8} width="100%" maxWidth="md" alignItems="stretch">
              {(consumer || operator) && (
                <Paper>
                  <PasswordEditorForm />
                </Paper>
              )}
              {(consumer || operator) && (
                <Paper>
                  <TotpEditorForm />
                </Paper>
              )}
              {consumer && (
                <Paper>
                  <ConfigurationsAuthorizationsForm account={account} />
                </Paper>
              )}
              {consumer && (
                <Paper ref={setRef('notificationsSettings')}>
                  <NotificationsSettingsForm account={account} />
                </Paper>
              )}
              {structure && (
                <Paper>
                  <GeneralSettingsForm account={account} />
                </Paper>
              )}
              {structure && (
                <Paper>
                  <DocumentsTemplatesForm account={account} />
                </Paper>
              )}
              {structure && (
                <Paper>
                  <DoxesTemplatesForm account={account} />
                </Paper>
              )}
            </Stack>
          )}
        </PageContent>
      )}
    </PageContainer>
  )
}
