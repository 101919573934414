import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function AddDocInternalIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 18.224121,1.472168 v 2.8344725 h -2.832276 v 1.4677734 h 2.832276 v 2.8344726 h 1.46997 V 5.7744139 h 2.834474 V 4.3066405 H 19.694091 V 1.472168 Z M 6.5456541,5.6711424 1.5029297,10.713867 v 10.085448 c 0.00311,0.928062 0.7550404,1.680384 1.6831053,1.680909 H 9.5053709 V 20.625732 H 3.359619 V 11.555419 H 7.3872068 V 7.4992674 h 5.3393552 v 1.8852538 h 1.858887 V 5.6711424 Z m 3.8276369,9.1406256 v 0.0023 1.270018 1.272216 h 1.274414 v -1.272216 h 1.267822 v 5.08667 H 11.64331 v 1.281006 h 3.814453 v -1.281006 h -1.267822 v -5.08667 h 1.256836 v 1.261232 h 1.267822 v -1.261232 -0.786621 -0.483397 h -2.524658 -1.274414 -1.267822 v -0.0023 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
