import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ExpandMoreIco } from '../icons'
import { ILeftbarItemsGroup } from './LeftbarData'
import { useLayout } from '../../hooks/Layout'
import { useUiContext } from '../../contexts/UiContext'

interface ILeftbarItemsGroupProps {
  data: ILeftbarItemsGroup
  isExpandedInit: boolean
}

export default function LeftbarItemsGroup(props: ILeftbarItemsGroupProps) {
  const { i18n, t } = useTranslation()
  const navigate = useNavigate()
  const { isMobile } = useLayout()
  const uiContext = useUiContext()
  const [isExpanded, setIsExpanded] = useState(props.isExpandedInit)

  const toggleExpand = (): void => {
    setIsExpanded(!isExpanded)
  }

  const handleNavigation = (path: string, state: any) => {
    navigate(path, { state })
    if (isMobile) {
      uiContext.toggleLeftbar()
    }
  }

  return (
    <Box sx={{ flexGrow: 2, cursor: 'pointer' }}>
      <Box sx={{ marginTop: props.data.isFirst ? 0 : 1.2, display: 'flex' }} onClick={() => toggleExpand()}>
        <Typography
          sx={{
            fontSize: 12,
            fontWeight: 'bold',
            marginLeft: 1,
            marginBottom: -1,
            marginTop: 1.5,
            userSelect: 'none',
            flexGrow: 2,
          }}
        >
          {t(props.data.title)}
        </Typography>
        <ExpandMoreIco
          sx={{
            marginBottom: -1,
            marginTop: 1.2,
            marginRight: 0.4,
            rotate: isExpanded ? '180deg' : '0deg',
          }}
        />
      </Box>

      <Collapse in={isExpanded}>
        <List>
          {props.data.items.map((item, index) => (
            <ListItem key={item.name} disablePadding>
              <ListItemButton
                sx={{ minHeight: 40 }}
                onClick={() => handleNavigation(item.path, item.init)}
                disabled={item.disabled}
              >
                <ListItemIcon sx={{ color: 'inherit', minWidth: 0, marginRight: 2 }}>{item.icon}</ListItemIcon>
                <ListItemText primary={t(item.name)} primaryTypographyProps={{ typography: 'body2' }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </Box>
  )
}
