import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import msgIds from '../../locales/msgIds'
import * as dalTreatment from '../../dal/DalTreatment'
import { ITreatmentEditorPageInit } from './TreatmentEditorPage.types'
import { Treatment } from '../../models/Treatment'
import { Paper, Stack } from '@mui/material'
import CommandBar from '../../components/commandBar/CommandBar'
import { ICommand } from '../../components/commandBar/CommandBar.types'
import { CheckIco, DeleteIco, InterdictIco } from '../../components/icons'
import { Utils } from '../../shared/Utils'
import { ISimpleDialogData } from '../../dialogs/simpleDialog/SimpleDialog.types'
import { useAuthContext } from '../../contexts/AuthContext'
import { ActionType } from '../../shared/Constants'
import { TreatmentDataRetentionEditorForm } from '../../components/treatmentDataRetentionEditor/TreatmentDataRetentionEditorForm'
import { TreatmentBaseEditorForm } from '../../components/treatmentBaseEditor/TreatmentBaseEditorForm'
import { PageContainer, PageContent } from '../../components/pageContainer/PageContainer'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'

export default function TreatmentEditorPage() {
  const location = useLocation()
  const navigate = useNavigate()

  const [state, setState] = useState<ITreatmentEditorPageInit>(location.state)
  const { t } = useTranslation()
  const authContext = useAuthContext()
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [treatment, setTreatment] = useState<Treatment | undefined>(undefined)

  const treatmentEnabled: boolean = !treatment?.disabledAt

  // dialogs
  const [simpleDialogData, setSimpleDialogData] = useState<ISimpleDialogData>()
  const [simpleDialogOpen, setSimpleDialogOpen] = useState(false)

  // ********************
  // component mounting
  useEffect(() => {
    // TODO exidea: check init params and verify if login or other actions are required

    if (!isLoading) {
      loadTreatment()
    }
  }, [state.treatmentId])

  // ********************
  // data fetch
  async function loadTreatment() {
    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const data = await dalTreatment.getTreatment(abortController.signal, state.treatmentId)
      setTreatment(data)
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  async function changeTreatmentEnabledState() {
    try {
      setIsLoading(true)
      console.log('changeTreatmentEnabledState', treatment)
      if (treatment) {
        const abortController = new AbortController()
        await dalTreatment.changeTreatmentEnabledState(abortController.signal, treatment.id, !!treatment.disabledAt)
        await loadTreatment()
      }
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  async function deleteTreatment() {
    try {
      setIsLoading(true)
      if (treatment) {
        const abortController = new AbortController()
        await dalTreatment.deleteTreatment(abortController.signal, treatment.id)
        navigate('/treatments')
      }
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  // ********************
  // command bar
  const commands: ICommand[] = [
    {
      commandText: !treatmentEnabled ? t(msgIds.MSG_TREATMENT_ENABLE) : t(msgIds.MSG_TREATMENT_DISABLE),
      icon: !treatmentEnabled ? <CheckIco /> : <InterdictIco />,
      onClick: async () => {
        const title = t(
          treatmentEnabled ? msgIds.MSG_TREATMENT_CONFIRM_DISABLE_TITLE : msgIds.MSG_TREATMENT_CONFIRM_ENABLE_TITLE
        )
        if (!checkUserCanDo(ActionType.updateTreatments, title)) return

        setSimpleDialogOpen(true)
        setSimpleDialogData({
          title: title,
          content: t(
            treatmentEnabled ? msgIds.MSG_TREATMENT_CONFIRM_DISABLE_TEXT : msgIds.MSG_TREATMENT_CONFIRM_ENABLE_TEXT
          ),
          actionsStyle: 'yesNO',
          onClose: async (result) => {
            setSimpleDialogOpen(false)
            if (result.userChoice !== 'yes') return
            await changeTreatmentEnabledState()
          },
        })
      },
    },
    {
      commandText: t(msgIds.MSG_TREATMENT_DELETE),
      icon: <DeleteIco />,
      onClick: async () => {
        const title = t(msgIds.MSG_TREATMENT_CONFIRM_DELETE_TITLE)
        if (!checkUserCanDo(ActionType.updateTreatments, title)) return

        setSimpleDialogOpen(true)
        setSimpleDialogData({
          title: t(msgIds.MSG_TREATMENT_CONFIRM_DELETE_TITLE),
          content: t(msgIds.MSG_TREATMENT_CONFIRM_DELETE_TEXT),
          actionsStyle: 'yesNO',
          onClose: async (result) => {
            setSimpleDialogOpen(false)
            if (result.userChoice === 'yes') {
              await deleteTreatment()
            }
          },
        })
      },
    },
  ]

  function checkUserCanDo(actionType: ActionType, title: string) {
    if (!authContext.loggedAccount?.canDo(actionType)) {
      setSimpleDialogOpen(true)
      setSimpleDialogData({
        title: title,
        content: t(msgIds.MSG_ERR_ACTION_TYPE_NOT_ALLOWED),
        actionsStyle: 'Ok',
        onClose: (result) => {
          setSimpleDialogOpen(false)
        },
      })
      return false
    } else {
      return true
    }
  }

  return (
    <PageContainer>
      <CommandBar style={{ minHeight: 'auto' }} title={t(msgIds.MSG_TREATMENT_ACTIVITY_DETAILS)} commands={commands} />
      {simpleDialogData && <SimpleDialog {...simpleDialogData} isOpen={simpleDialogOpen}></SimpleDialog>}
      <PageContent>
        <Stack spacing={8} width="100%" maxWidth="md" alignItems="stretch">
          <Paper>{!isLoading && treatment && <TreatmentBaseEditorForm treatment={treatment} />}</Paper>
          <Paper>{!isLoading && treatment && <TreatmentDataRetentionEditorForm treatment={treatment} />}</Paper>
        </Stack>
      </PageContent>
    </PageContainer>
  )
}
