import { AccountsSelectorStepper } from '../../components/accountsSelectorStepper/AccountsSelectorStepper'
import { Account } from '../../models/Account'
import { StyledDialog } from '../styledDialog/StyledDialog'
import { IAccountSelectorDialogProps } from './AccountSelectorDialog.types'

export default function AccountSelectorDialog(props: IAccountSelectorDialogProps) {
  return (
    <StyledDialog
      minHeight={700}
      maxWidth={'md'}
      open={props.isOpen}
      onClose={() => props.onClose({ userChoice: 'abort' })}
    >
      <AccountsSelectorStepper
        searchOptions={props.searchOptions}
        goToAccountPageOption={props.goToAccountPageOption}
        showPrivacyPolicy={props.showPrivacyPolicy}
        onClickExit={() => props.onClose({ userChoice: 'abort' })}
        flexGrow={1}
        onAccountSelected={(account: Account) => props.onResult({ userChoice: 'yes', account: account })}
      />
    </StyledDialog>
  )
}
