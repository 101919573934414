import countries from '../../assets/collections/countries.json'

export type CountryOption = { label: string; value: string; key: string }

export const countryOptions: CountryOption[] = countries.map((c) => {
  return { label: c.fullName, value: c.codat, key: c.initials }
})

export function countryOptionByLabel(label: string | undefined | null): CountryOption | undefined {
  if (!label) {
    return undefined
  }
  return countryOptions.find((c) => c.label === label)
}

export function countryOptionByValue(value: string | undefined | null): CountryOption | undefined {
  if (!value) {
    return undefined
  }
  return countryOptions.find((c) => c.value === value)
}

export function countryOptionByKey(key: string | undefined | null): CountryOption | undefined {
  if (!key) {
    return undefined
  }
  return countryOptions.find((c) => c.key === key)
}
