import { useMemo } from 'react'
import { KeyValueTable } from '../keyValueTable/KeyValueTable'
import { IDoxDetailsProps } from './DoxDetails.types'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'
import { IKeyValueData } from '../keyValueTable/KeyValueTable.types'
import { Utils } from '../../shared/Utils'
import {
  RetentionEndBehavior,
  RetentionRules,
  RetentionRulesTranslationMap,
  isBusiness,
  isConsumer,
} from '../../shared/Constants'
import { useAuthContext } from '../../contexts/AuthContext'
import { Box, Typography, useTheme } from '@mui/material'

export function DoxDetails(props: IDoxDetailsProps): JSX.Element {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const authContext = useAuthContext()
  const sections: { name: string | null; data: IKeyValueData[] }[] = useMemo(() => {
    const dox = props.dox
    const _sections = []

    const firstSectionData = []
    if (
      dox.ownerProfileId === authContext.loggedProfileId ||
      dox.ownerProfileId === authContext.linkedStructureProfileId
    ) {
      firstSectionData.push({
        label: t(msgIds.MSG_DOX_DETAILS_PATH_LABEL),
        consumer: true,
        value: dox.parentsRouteWithRoot,
      })
    }
    firstSectionData.push({ label: t(msgIds.MSG_DOX_DETAILS_NAME_LABEL), consumer: true, value: dox.name })
    firstSectionData.push({ label: t(msgIds.MSG_DOX_DETAILS_NOTES_LABEL), consumer: true, value: dox.notes })

    const contentData = [
      {
        label: t(msgIds.MSG_DOX_DETAILS_CONTENT_START_AT_LABEL),
        consumer: true,
        value: Utils.toLocaleDateString(dox.contentStartAt, i18n) ?? '',
      },
      {
        label: t(msgIds.MSG_DOX_DETAILS_CONTENT_END_AT_LABEL),
        consumer: true,
        value: Utils.toLocaleDateString(dox.contentEndAt, i18n) ?? '',
      },
      {
        label: t(msgIds.MSG_DOX_DETAILS_CONTENT_DOX_COUNT_LABEL),
        consumer: true,
        value: dox.contentDoxCount.toString(),
      },
      {
        label: t(msgIds.MSG_DOX_DETAILS_CONTENT_DOCUMENT_COUNT_LABEL),
        consumer: true,
        value: dox.contentDocumentCount.toString(),
      },
      {
        label: t(msgIds.MSG_DOX_DETAILS_CONTENT_DRAFT_DOCUMENT_COUNT_LABEL),
        consumer: true,
        value: dox.contentDraftDocumentCount.toString(),
      },
    ]

    const deliveredAtData = {
      label: t(msgIds.MSG_DOX_DETAILS_DELIVERED_AT_LABEL),
      consumer: true,
      value: Utils.toLocaleDateString(dox.deliveredAt, i18n) ?? '',
    }
    if (isConsumer(authContext.loggedProfileType)) {
      firstSectionData.push(deliveredAtData)
      _sections.push({ name: null, data: firstSectionData })
      _sections.push({ name: t(msgIds.MSG_DOX_DETAILS_CONTENT_LABEL), data: contentData })
      return _sections
    } else {
      _sections.push({ name: null, data: firstSectionData })
      _sections.push({ name: t(msgIds.MSG_DOX_DETAILS_CONTENT_LABEL), data: contentData })
      _sections.push({ name: t(msgIds.MSG_DOX_DETAILS_DELIVER_SECTION_LABEL), data: [deliveredAtData] })
    }

    const customFormatOptions: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    }

    if (isBusiness(authContext.loggedProfileType) && !!dox.treatmentId) {
      const retentionData = [
        {
          label: t(msgIds.MSG_DOX_DETAILS_FINALITY_LABEL),
          consumer: false,
          value: props.treatment?.shortDescription,
        },
        {
          label: t(msgIds.MSG_DOX_DETAILS_RETENTION_RULES_LABEL),
          consumer: false,
          value: t(RetentionRulesTranslationMap[dox.retentionRules ?? RetentionRules.none]),
        },
        {
          label: t(msgIds.MSG_DOX_DETAILS_RETENTION_START_AT_LABEL),
          consumer: false,
          value: Utils.toLocaleDateString(dox.retentionStartAt, i18n, customFormatOptions),
        },
        {
          label: t(msgIds.MSG_DOX_DETAILS_RETENTION_END_AT_LABEL),
          consumer: false,
          value: Utils.toLocaleDateString(dox.retentionEndAt, i18n, customFormatOptions),
        },
        {
          label: t(msgIds.MSG_DOX_DETAILS_RETENTION_NOTICE_AT_LABEL),
          consumer: false,
          value: Utils.toLocaleDateString(dox.retentionNoticeAt, i18n, customFormatOptions),
        },
        {
          label: t(msgIds.MSG_DOX_DETAILS_RETENTION_END_TERMINATE_LABEL),
          consumer: false,
          value:
            dox.retentionEndBehavior === RetentionEndBehavior.terminate ||
            dox.retentionEndBehavior === RetentionEndBehavior.terminateAndNotify
              ? t(msgIds.MSG_YES)
              : t(msgIds.MSG_NO),
        },
      ]
      _sections.push({ name: t(msgIds.MSG_DOX_DETAILS_RETENTION_SECTION_LABEL), data: retentionData })
    }

    return _sections
  }, [props.dox, props.treatment, t, i18n, authContext.loggedProfileType])

  return (
    <>
      {sections.map((section) => (
        <Box key={section.name}>
          {section.name && (
            <Box sx={{ marginX: 2, backgroundColor: theme.palette.common.gray9 }}>
              <Typography textAlign="center" fontWeight="bold">
                {section.name}
              </Typography>
            </Box>
          )}
          <KeyValueTable data={section.data} hideLastDivider={true} />
        </Box>
      ))}
    </>
  )
}
