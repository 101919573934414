import { PageStepper } from '../pageStepper/PageStepper'
import { PageStep } from '../pageStepper/PageStep'
import { IAccountsSelectorStepperProps } from './AccountsSelectorStepper.types'
import { ViewHeader } from '../viewHeader/ViewHeader'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { StepperStateBase, useStackState } from '../../hooks/Stack'
import { Button, Stack, useTheme } from '@mui/material'
import { ViewContent } from '../viewContent/ViewContent'
import { Account } from '../../models/Account'
import { Dox } from '../../models/Dox'
import { Document } from '../../models/Document'
import AccountInfoStructure from '../accountInfoStructure/AccountInfoStructure'
import { Authorization } from '../../models/Authorization'
import { AccountsSearch } from '../accountsSearch/AccountsSearch'
import { PrivacyIco } from '../icons'
import {
  AccountSearchTypes,
  ContractType,
  ProfileType,
  isCollaborator,
  isConsumer,
  isOperator,
  isStructure,
} from '../../shared/Constants'
import { useChange } from '../../hooks/Change'
import { useNavigate } from 'react-router'
import { useAuthContext } from '../../contexts/AuthContext'
import AccountInfoOperator from '../accountInfoOperator/AccountInfoOperator'
import AccountInfoCustomer from '../accountInfoCustomer/AccountInfoCustomer'
import AccountInfoCollaborator from '../accountInfoCollaborator/AccountInfoCollaborator'

type StepperState = StepperStateBase & {
  account?: Account
  element?: Dox | Document
  authorization?: Authorization
  contractOwnerProfileId?: number
  grantorProfileId?: number
  targetProfileType?: ProfileType
  contractType?: ContractType
  isEditable?: true
}

export function AccountsSelectorStepper(props: IAccountsSelectorStepperProps): JSX.Element {
  const { onClickExit, searchOptions, goToAccountPageOption, showPrivacyPolicy, onAccountSelected, ...rest } = props

  const { t } = useTranslation()
  const theme = useTheme()
  const stack = useStackState<StepperState>([])
  const navigate = useNavigate()
  const authContext = useAuthContext()

  useChange(() => {
    stack.setItems([{ step: 'customer_search' }])
  }, [props.searchOptions])

  function onClickAvatar(account: Account) {
    stack.push({ step: 'account_details', account: account })
  }

  function onClickShowPrivacyPolicy(account: Account) {
    navigate(`/contracts/versions/viewer`, {
      state: {
        grantorProfileId: authContext.loggedProfileId,
        targetProfileType: authContext.loggedProfileType,
        contractOwnerProfileId: account.profile?.profileId,
        contractType: ContractType.privacyPolicy,
        isEditable: true,
      },
    })
  }

  const backButtonVisible = stack.length > 1
  const headerTitle = getTitleFromSearchOption(props.searchOptions, stack.head)

  return (
    <PageStepper {...rest} activeStep={stack.head?.step}>
      <ViewHeader
        title={t(headerTitle)}
        backButtonVisible={backButtonVisible}
        onClickBack={stack.pop}
        exitButtonVisible={true}
        onClickExit={onClickExit}
      />
      <PageStep step="customer_search">
        <ViewContent display="flex" flexDirection="column" disablePadding={true}>
          {
            <AccountsSearch
              searchOption={searchOptions}
              goToAccountPageOption={goToAccountPageOption}
              showPrivacyPolicy={showPrivacyPolicy}
              onCancel={onClickExit}
              onAccountSelected={onAccountSelected}
              onClickAvatar={onClickAvatar}
            />
          }
        </ViewContent>
      </PageStep>
      <PageStep step="account_details">
        <ViewContent>
          {stack.head?.account && (
            <Stack>
              {isStructure(stack.head?.account.profile?.type) && showPrivacyPolicy && (
                <Button
                  variant="contained"
                  sx={{ textTransform: 'none', alignSelf: 'flex-start', color: theme.palette.common.gray11 }}
                  onClick={() => stack.head?.account && onClickShowPrivacyPolicy(stack.head?.account)}
                >
                  <PrivacyIco />
                  <span style={{ width: '5px' }} />
                  {t(msgIds.MSG_CONTRACT_PRIVACY_POLICY)}
                </Button>
              )}

              {isStructure(stack.head?.account.profile?.type) && <AccountInfoStructure account={stack.head.account} />}
              {isOperator(stack.head?.account.profile?.type) && !isCollaborator(stack.head?.account.profile?.type) && (
                <AccountInfoOperator account={stack.head.account} />
              )}
              {isCollaborator(stack.head?.account.profile?.type) && (
                <AccountInfoCollaborator account={stack.head.account} />
              )}
              {isConsumer(stack.head?.account.profile?.type) && <AccountInfoCustomer account={stack.head.account} />}
            </Stack>
          )}
        </ViewContent>
      </PageStep>
    </PageStepper>
  )
}

function getTitleFromSearchOption(searchOptions: AccountSearchTypes, step: any) {
  if (step === 'account_details') {
    return msgIds.MSG_DETAILS
  } else {
    switch (searchOptions) {
      case 'structures':
        return msgIds.MSG_STRUCTURE
      case 'operators':
        return msgIds.MSG_OPERATOR
      case 'collaborators':
        return msgIds.MSG_COLLABORATOR
      case 'customers':
        return msgIds.MSG_USER
      default:
        return ''
    }
  }
}
