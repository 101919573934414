import { Button, Chip, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useEffect, useState } from 'react'
import { useArchiveContext } from '../../contexts/ArchiveContext'
import { useAuthContext } from '../../contexts/AuthContext'
import { Utils } from '../../shared/Utils'
import { useTranslation } from 'react-i18next'
import { EditIco, RetentionDisabledIco, RetentionIco, RetentionStopIco } from '../icons'
import msgIds from '../../locales/msgIds'
import FromToDatePicker from '../fromToDatePicker/FromToDatePicker'
import DialogTemplate from '../template/dialogTemplate/DialogTemplate'
import * as dalDocument from '../../dal/DalDocument'
import { DocumentRetention } from '../../models/DocumentRetention'
import { IDocumentRetentionsProps } from './DocumentRetentions.types'
import { Dox } from '../../models/Dox'
import { ActionType } from '../../shared/Constants'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'
import { ISimpleDialogData } from '../../dialogs/simpleDialog/SimpleDialog.types'
import { useSnackbar } from 'notistack'
import { themeOperatorLight } from '../../themes/ThemeOperatorLight'
import { IDoxDetailsDialogData } from '../../dialogs/doxDetailsDialog/DoxDetailsDialog.types'
import { DoxDetailsDialog } from '../../dialogs/doxDetailsDialog/DoxDetailsDialog'

export default function DocumentRetentions(props: IDocumentRetentionsProps): JSX.Element {
  const { document, isEditable, onClosingDocumentEditor } = props

  const { t, i18n } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const archiveContext = useArchiveContext()
  const authContext = useAuthContext()
  const [documentRetentionInEditing, setDocumentRetentionInEditing] = useState<DocumentRetention | null>(null)
  const [fromDate, setFromDate] = useState<Date | undefined>()
  const [toDate, setToDate] = useState<Date | undefined>()
  const [documentRetentions, setDocumentRetentions] = useState<DocumentRetention[]>([])

  // dialogs
  const [simpleDialogData, setSimpleDialogData] = useState<ISimpleDialogData>()
  const [simpleDialogOpen, setSimpleDialogOpen] = useState(false)
  const [doxDetailsDialogData, setDoxDetailsDialogData] = useState<IDoxDetailsDialogData>()
  const [doxDetailsDialogOpen, setDoxDetailsDialogOpen] = useState(false)

  useEffect(() => {
    const loadDocumentRetention = async (abortSignal: AbortSignal) => {
      try {
        const documentRetention = await dalDocument.getDocumentRetentions(
          abortSignal,
          document.documentId,
          authContext.linkedStructureProfileId
        )
        setDocumentRetentions(documentRetention)
        documentRetention.forEach((p) => (p.dox = archiveContext.rwArchiveDoxes.distinct.find((i) => i.id === p.doxId)))
      } catch (err) {
        Utils.enqueueSnackbarError2(err, t)
      }
    }

    const abortController = new AbortController()
    loadDocumentRetention(abortController.signal)

    return () => {
      abortController.abort()
    }
  }, [authContext.linkedStructureProfileId, t, document.documentId])

  function handleClickRetentionStop(documentRetention: DocumentRetention) {
    if (authContext.loggedAccount?.canDo(ActionType.updateDocumentsRetentions)) {
      let msg = ''
      if (documentRetentions.length > 1) {
        msg = t(msgIds.MSG_DOCUMENT_RETENTION_DIALOG_TERMINATE_DETACHED_BUT_VISIBLE_BODY)
      } else if (document.ownerProfileId !== authContext.linkedStructureProfileId) {
        msg = t(msgIds.MSG_DOCUMENT_RETENTION_DIALOG_TERMINATE_VISIBLE_TO_OWNER_BODY)
      } else if (document.ownerProfileId === authContext.linkedStructureProfileId && !!document.deliveredAt) {
        msg = t(msgIds.MSG_DOCUMENT_RETENTION_DIALOG_TERMINATE_VISIBLE_TO_TARGET_BY_DELIVER_BODY)
      } else if (document.ownerProfileId === authContext.linkedStructureProfileId && !document.deliveredAt) {
        msg = t(msgIds.MSG_DOCUMENT_RETENTION_DIALOG_TERMINATE_DELETED_BODY)
      }
      setSimpleDialogOpen(true)
      setSimpleDialogData({
        title: t(msgIds.MSG_DOCUMENT_RETENTION_DIALOG_TITLE),
        content: msg,
        actionsStyle: 'yesNO',
        onClose: async (result) => {
          setSimpleDialogOpen(false)
          if (result.userChoice === 'yes') {
            await doTerminateDocumentRetention(documentRetention)
          }
        },
      })
    } else {
      setSimpleDialogOpen(true)
      setSimpleDialogData({
        title: t(msgIds.MSG_DOCUMENT_RETENTION_DIALOG_TITLE),
        content: t(msgIds.MSG_FUNCTION_NOT_AVAILABLE),
        actionsStyle: 'Ok',
        onClose: async (result) => {
          setSimpleDialogOpen(false)
        },
      })
    }
  }

  async function doTerminateDocumentRetention(documentRetention: DocumentRetention) {
    try {
      if (documentRetention.documentId) {
        const abortController = new AbortController()
        await dalDocument.terminateDocumentRetention(
          abortController.signal,
          documentRetention.documentId,
          documentRetention.id
        )
        enqueueSnackbar(t(msgIds.MSG_RETENTION_TERMINATED_SUCCESFULLY), { variant: 'success' })
        onClosingDocumentEditor(document, true)
      }
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    }
  }

  function handleClickEdit(documentRetention: DocumentRetention) {
    setFromDate(documentRetention?.startAt ? (Utils.toDate(documentRetention.startAt) as Date) : undefined)
    setToDate(documentRetention?.endAt ? (Utils.toDate(documentRetention.endAt) as Date) : undefined)
    setDocumentRetentionInEditing(documentRetention)
  }

  async function onSubmit() {
    try {
      if (documentRetentionInEditing) {
        const documentRetention = documentRetentions.find((p) => p.id === documentRetentionInEditing.id)
        const fromDateStr = fromDate ? (Utils.toDate(fromDate, true) as string) : undefined
        const toDateStr = toDate ? (Utils.toDate(toDate, true) as string) : undefined
        if (documentRetention && (documentRetention.startAt !== fromDateStr || documentRetention.endAt !== toDateStr)) {
          const abortController = new AbortController()
          const retention = await dalDocument.updateDocumentRetention(
            abortController.signal,
            document.documentId,
            documentRetention.id,
            fromDateStr,
            toDateStr
          )
          documentRetention.startAt = retention.startAt
          documentRetention.endAt = retention.endAt
          documentRetention.updatedAt = retention.updatedAt
        }
      }
      setDocumentRetentionInEditing(null)
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    }
  }

  function onClose() {
    setDocumentRetentionInEditing(null)
  }

  function isPeriodValid(fromDate: Date | undefined, toDate: Date | undefined) {
    return fromDate && toDate && fromDate < toDate
  }

  function onClickDoxChip(dox: Dox) {
    setDoxDetailsDialogOpen(true)
    setDoxDetailsDialogData({
      doxId: dox.id,
      onClose: (result) => {
        setDoxDetailsDialogOpen(false)
      },
    })
  }

  function GetArchiveIco(dox: Dox) {
    if (dox.treatmentId) {
      if (!dox.retentionInheritedFrom) {
        return <RetentionIco />
      } else {
        return <RetentionIco opacity={0.5} />
      }
    } else {
      return <RetentionDisabledIco opacity={0.5} />
    }
  }

  return (
    <Table>
      {simpleDialogData && <SimpleDialog {...simpleDialogData} isOpen={simpleDialogOpen}></SimpleDialog>}
      {doxDetailsDialogData && <DoxDetailsDialog {...doxDetailsDialogData} isOpen={doxDetailsDialogOpen} />}

      <DialogTemplate
        isOpen={!!documentRetentionInEditing}
        title={t(msgIds.MSG_DOCUMENT)}
        isFullscreenForMobile={true}
        maxWidth="sm"
        mt={3}
        onClose={onClose}
        showActions={true}
        actions={[
          <Button onClick={onClose}>{t(msgIds.MSG_CANCEL)}</Button>,
          <Button onClick={onSubmit} disabled={!isPeriodValid(fromDate, toDate)}>
            {t(msgIds.MSG_OK)}
          </Button>,
        ]}
      >
        <FromToDatePicker
          justifyContent="center"
          fromDate={fromDate}
          onChangeFromDate={setFromDate}
          toDate={toDate}
          onChangeToDate={setToDate}
        />
      </DialogTemplate>

      <TableHead>
        <TableRow>
          <TableCell>{t(msgIds.MSG_DOCUMENT_EDITOR_RETENTION_FINALITY_LABEL)}</TableCell>
          <TableCell width={'120px'}>{t(msgIds.MSG_DOCUMENT_EDITOR_RETENTION_START_AT_LABEL)}</TableCell>
          <TableCell width={'120px'}>{t(msgIds.MSG_DOCUMENT_EDITOR_RETENTION_END_AT_LABEL)}</TableCell>
          <TableCell></TableCell>
          <TableCell width={'50px'}></TableCell>
          <TableCell width={'50px'}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {documentRetentions.map((row) => (
          <TableRow
            key={row.id}
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
            }}
          >
            <TableCell component="th" scope="row">
              {row.treatmentDescription}
            </TableCell>
            <TableCell>{Utils.toLocaleDateString(row.startAt, i18n)}</TableCell>
            <TableCell>{Utils.toLocaleDateString(row.endAt, i18n)}</TableCell>
            <TableCell>
              {row.dox && (
                <Chip
                  label={row.dox?.name}
                  onClick={() => row.dox && onClickDoxChip(row.dox)}
                  sx={{
                    background: themeOperatorLight.palette.primary.lighter,
                  }}
                  icon={GetArchiveIco(row.dox)}
                />
              )}
            </TableCell>
            <TableCell>
              {isEditable && !!row.documentId && (
                <IconButton onClick={() => handleClickRetentionStop(row)}>
                  <RetentionStopIco />
                </IconButton>
              )}
            </TableCell>
            <TableCell>
              {isEditable && !!row.documentId && (
                <IconButton onClick={() => handleClickEdit(row)}>
                  <EditIco />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
