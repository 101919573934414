import { List, ListItemButton, ListItemText, ListProps } from '@mui/material'
import { useAuthContext } from '../../contexts/AuthContext'
import { IInternalDocTemplatesListProps } from './InternalDocTemplatesList.types'

export function InternalDocTemplatesList(props: IInternalDocTemplatesListProps & ListProps): JSX.Element {
  const { onClickTemplate, ...rest } = props
  const authContext = useAuthContext()
  const templates =
    authContext.linkedStructureAccount?.profile?.fePreferences?.archiveCfg?.internalDocumentTemplates || []

  return (
    <List {...rest}>
      {Array.isArray(templates) &&
        templates.map((template, i) => (
          <ListItemButton key={i} onClick={() => onClickTemplate(template)}>
            <ListItemText primary={template.name} secondary={template.dsc} />
          </ListItemButton>
        ))}
    </List>
  )
}
