import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function DocExternalIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 7.3894042,3.0036604 1.9995117,8.393553 v 10.779786 a 1.8010249,1.8010249 0 0 0 1.7995605,1.795165 H 11.994873 V 18.988768 H 3.9858399 V 9.294432 H 8.2902832 V 4.9592268 h 5.7041018 v 3.2145997 l 1.986328,-1.984131 V 3.0036604 Z m 8.6110838,5.972168 -6.0007321,6.0007326 h 3.9946291 v 6.020508 H 18 v -6.020508 h 3.999024 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
