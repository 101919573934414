import { TreatedFieldsAcl } from '../contexts/AccountEditorContext'
import msgIds from '../locales/msgIds'
import { Gender_all, ProfileType, isStructure } from './Constants'
import { Utils, ValType } from './Utils'

export type IIdentityArgs = {
  email?: string
  lastname?: string
  name?: string
  gender?: Gender_all
  birthdate?: string
  birthplace?: string
  vatNumber?: string
  fiscalCode?: string
}

export function validateIdentityArgs(
  val: IIdentityArgs,
  fieldsAcl: TreatedFieldsAcl,
  isStructure: boolean
): any | null {
  const errors = {
    email:
      fieldsAcl.registrationEmail === 'editable'
        ? Utils.validateEmail(val.email || '', [{ type: ValType.notNull }, { type: ValType.notEmpty }])
        : undefined,
    lastname:
      fieldsAcl.lastname === 'editable'
        ? Utils.validateText(val.lastname || '', [{ type: ValType.notNull }, { type: ValType.notEmpty }])
        : undefined,
    name:
      fieldsAcl.name === 'editable'
        ? Utils.validateText(val.name || '', [{ type: ValType.notNull }, { type: ValType.notEmpty }])
        : undefined,
    birthdate:
      fieldsAcl.birthdate === 'editable'
        ? Utils.validateDate(val.birthdate || '', [{ type: ValType.notNull }, { type: ValType.notEmpty }])
        : undefined,
    birthplace:
      fieldsAcl.birthplace === 'editable'
        ? Utils.validateText(val.birthplace || '', [{ type: ValType.notNull }, { type: ValType.notEmpty }])
        : undefined,
    fiscalCode:
      fieldsAcl.fiscalCode === 'editable'
        ? isStructure
          ? val.fiscalCode?.length === 11
            ? Utils.validateVatNumber(val.fiscalCode || '', [{ type: ValType.notNull }, { type: ValType.notEmpty }])
            : Utils.validateFiscalCode(val.fiscalCode || '', [{ type: ValType.notNull }, { type: ValType.notEmpty }])
          : Utils.validateFiscalCode(val.fiscalCode || '', [{ type: ValType.notNull }, { type: ValType.notEmpty }])
        : undefined,
    vatNumber:
      fieldsAcl.vatNumber === 'editable'
        ? Utils.validateVatNumber(val.vatNumber || '', [{ type: ValType.notNull }, { type: ValType.notEmpty }])
        : undefined,
  }
  if (Object.values(errors).find((e) => !!e)) {
    return errors
  } else {
    return null
  }
}

export function identityBirthplaceCountryArgLabelMsgId() {
  return msgIds.MSG_PROFILE_EDITOR_IDENTITY_BIRTH_COUNTRY
}

export function identityBirthplaceCityArgLabelMsgId() {
  return msgIds.MSG_PROFILE_EDITOR_IDENTITY_BIRTH_CITY
}

export function identityArgLabelMsgId(key: keyof IIdentityArgs, profileType: ProfileType) {
  switch (key) {
    case 'email':
      return msgIds.MSG_EMAIL
    case 'lastname':
      return msgIds.MSG_LASTNAME
    case 'name':
      return isStructure(profileType) ? msgIds.MSG_COMPANY_NAME : msgIds.MSG_NAME
    case 'gender':
      return msgIds.MSG_TREATED_FIELDS_GENDER
    case 'birthdate':
      return msgIds.MSG_TREATED_FIELDS_BIRTH_DATE
    case 'fiscalCode':
      return msgIds.MSG_TREATED_FIELDS_FISCAL_CODE
    case 'vatNumber':
      return msgIds.MSG_PROFILE_EDITOR_IDENTITY_VAT
    default:
      return null
  }
}
