import { InvitationPurposeType, PDX_API_URL } from '../shared/Constants'
import log from '../shared/Logger'
import { axiosInstance } from '../contexts/AuthContext'
import { UriComposer } from '../shared/types/UriComposer'
import { IInvitationJson, Invitation } from '../models/Invitation'

// ********************
// GET

export function getActiveInvitation(abortSignal: AbortSignal, ownerProfileId: number, targetProfileId: number) {
  const funcName = 'getActiveInvitation'
  const url = UriComposer.create(`${PDX_API_URL}/v1/invitations`)
    .addParamBool('active', true)
    .addParamNumber('owner_profile_id', ownerProfileId)
    .addParamNumber('target_profile_id', targetProfileId)
    .getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Invitation.deserializeArray(resp.data as IInvitationJson[])
      return reply[0]
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// POST

export function createInvitation(abortSignal: AbortSignal, targetProfileId: number, purpose: InvitationPurposeType) {
  const funcName = 'createInvitation'
  const url = `${PDX_API_URL}/v1/invitations`

  const parms = {
    target_profile_id: targetProfileId,
    purpose: purpose,
  }
  return axiosInstance
    .post(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Invitation.deserialize(resp.data as IInvitationJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// PATCH

export function acceptInvitation(abortSignal: AbortSignal, invitationId: number) {
  const funcName = 'acceptInvitation'
  const url = `${PDX_API_URL}/v1/invitations/${invitationId}`

  const parms = {
    accept: true,
  }
  return axiosInstance
    .patch(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Invitation.deserialize(resp.data as IInvitationJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function revokeInvitation(abortSignal: AbortSignal, invitationId: number) {
  const funcName = 'revokeInvitation'
  const url = `${PDX_API_URL}/v1/invitations/${invitationId}`

  const parms = {
    revoke: true,
  }
  return axiosInstance
    .patch(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Invitation.deserialize(resp.data as IInvitationJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// DELETE
