export interface ITaxRateMetadataJson {
  is_business: boolean
}

export class TaxRateMetadata {
  isBusiness: boolean = false

  constructor() {}

  public static serialize(obj: TaxRateMetadata): ITaxRateMetadataJson {
    return {
      is_business: obj.isBusiness,
    }
  }

  public static deserialize(json: ITaxRateMetadataJson): TaxRateMetadata {
    const res = new TaxRateMetadata()
    res.isBusiness = json.is_business
    return res
  }
}
