import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function UserIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 11.509277,4.025389 A 3.9969249,3.9969249 0 0 0 7.9936524,7.9936507 3.9958149,3.9958149 0 0 0 11.990478,11.990477 3.9969249,3.9969249 0 1 0 11.509277,4.025389 Z m 0.481201,1.9709472 A 1.99846,1.99846 0 1 1 9.9931641,7.9936507 2.0043349,2.0043349 0 0 1 11.990478,5.9963362 Z m 0,6.9938968 c -2.6679468,0 -7.9936518,1.338873 -7.9936518,3.996826 v 2.99707 H 19.984131 v -2.99707 c 0,-2.657927 -5.32571,-3.996826 -7.993653,-3.996826 z m 0,1.997314 c 2.697923,0 5.796489,1.290054 5.996338,1.999512 v 0.999756 H 5.9963379 v -0.990968 c 0.1998448,-0.719449 3.2962182,-2.0083 5.9941401,-2.0083 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
