import { UpdateUserArgs } from '../dal/DalAccount'
import { Utils, ValType } from './Utils'

export function validateBillingUserArgs(val: UpdateUserArgs): any | null {
  const errors = {
    sdiCode:
      !val.pec || val.sdiCode ? Utils.validateSdiCode(val.sdiCode || '', [{ type: ValType.notEmpty }]) : undefined,
    pec: !val.sdiCode || val.pec ? Utils.validateEmail(val.pec || '', [{ type: ValType.notEmpty }]) : undefined,
    country: Utils.validateText(val.country || '', [{ type: ValType.notEmpty }]),
    city: Utils.validateText(val.city || '', [{ type: ValType.notEmpty }]),
    province: val.country === 'IT' ? Utils.validateText(val.province || '', [{ type: ValType.notEmpty }]) : undefined,
    street: Utils.validateText(val.street || '', [{ type: ValType.notEmpty }]),
    streetNumber: Utils.validateText(val.streetNumber || '', [{ type: ValType.notEmpty }]),
    zip: Utils.validateText(val.zip || '', [{ type: ValType.notEmpty }]),
  }
  if (Object.values(errors).find((e) => !!e)) {
    return errors
  } else {
    return null
  }
}
