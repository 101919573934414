import { ProductFeatures } from '../../models/ProductFeatures'
import { isBusiness, ProfileType, RegistrationPurpose } from '../../shared/Constants'

export interface IProductsPageProps {
  productPlanChoice?: IProductPlanChoice
}

export interface IProductPlanChoice {
  profileType: ProfileType
  productId?: string
  priceId?: string
  isTrial?: boolean
  isFreePlan?: boolean
  purpose?: RegistrationPurpose
  hasBillingData?: boolean
}

export function getTargetProductProfileType(userProfileType: ProfileType) {
  return isBusiness(userProfileType) ? ProfileType.structure : ProfileType.customer
}

export function getUserProfileType(productProfileType: ProfileType) {
  return productProfileType === ProfileType.structure ? ProfileType.operatorAdmin : ProfileType.customer
}

export type FeatureShow = {
  productId: string
  type: 'boolean' | 'number'
  value: boolean | string
}

export type ComparationInfo = {
  key: keyof ProductFeatures
  dsc: string
  values: (FeatureShow | undefined)[]
}
