import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function DoxOrganizedAddIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 3.4738772,1.5007324 c -0.3200456,0 -0.5868758,0.105234 -0.7470704,0.3186035 L 1.7666017,2.9399414 C 1.6064162,3.206811 1.5007325,3.4724413 1.5007325,3.7924805 V 12.16626 c 0,0.693259 0.5847785,1.333741 1.3315431,1.333741 H 12.16626 c 0.693254,0 1.333741,-0.640482 1.333741,-1.333741 V 3.7924805 c 0,-0.3200392 -0.160615,-0.5856695 -0.320801,-0.8525391 L 12.221192,1.8193359 C 12.060997,1.6059665 11.794167,1.5007324 11.474122,1.5007324 Z m 0.1604004,1.331543 h 7.6794434 l 0.533936,0.6416017 H 3.1003419 Z M 2.8322756,4.8076173 H 12.16626 V 12.16626 H 2.8322756 Z m 4.001221,1.289795 v 1.73584 h -1.702881 v 1.331543 h 1.702881 V 10.85669 h 1.331543 V 9.1647953 H 9.839356 V 8.8220219 h 0.00879 V 8.1518558 H 9.839356 V 7.8332523 H 8.1650396 v -1.73584 z m 0.098877,8.8967287 v 4.328614 H 13.528565 V 18.186769 H 8.0507817 l 0.00219,-3.192628 z m 9.3010264,0.0066 c -0.200028,0 -0.365711,0.06658 -0.465821,0.199952 l -0.599854,0.69873 c -0.100125,0.166794 -0.166992,0.333912 -0.166992,0.533936 v 5.233887 c 0,0.433293 0.366034,0.83277 0.832764,0.83277 h 5.83374 c 0.433286,0 0.832764,-0.399477 0.832764,-0.832764 V 16.43335 c 0,-0.200024 -0.09984,-0.367142 -0.199951,-0.533936 l -0.599853,-0.69873 c -0.100125,-0.13335 -0.265793,-0.199951 -0.465821,-0.199951 z m 0.09888,0.832764 h 4.801025 l 0.333984,0.399904 h -5.466797 z m -0.49878,1.232666 h 5.83374 v 4.601075 H 15.8335 Z m 2.500488,0.806397 v 1.08545 h -1.065673 v 0.832764 h 1.065673 v 1.056885 h 0.832764 v -1.056885 h 1.045899 v -0.215333 h 0.0043 v -0.41748 h -0.0043 v -0.199951 h -1.045899 v -1.08545 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
