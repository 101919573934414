import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function InfoIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 11,6.9999996 h 2 V 8.9999998 H 11 Z M 11,11 h 2 v 6 h -2 z m 1,-9.0000004 c -5.5200003,0 -10.0000003,4.48 -10.0000003,10.0000004 0,5.52 4.48,10 10.0000003,10 5.519999,0 10,-4.48 10,-10 C 22,6.4799996 17.52,1.9999996 12,1.9999996 Z M 12,20 c -4.4100003,0 -8.0000003,-3.59 -8.0000003,-8 0,-4.4100004 3.59,-8.0000004 8.0000003,-8.0000004 4.409999,0 8,3.59 8,8.0000004 0,4.41 -3.59,8 -8,8 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
