import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function UserInfoIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 18.817383,1.9907209 v 1.3930665 h 1.393066 V 1.9907209 Z m 0,2.7839357 v 4.1748046 h 1.393066 V 4.7746566 Z m -8.028809,1.208496 A 4.1165799,4.1165799 0 0 0 7.1674804,10.070067 4.1212749,4.1212749 0 0 0 11.285156,14.185545 4.1165799,4.1165799 0 1 0 10.788574,5.9831526 Z m 0.496582,1.9204102 a 2.16675,2.16675 0 0 1 0,4.3330082 2.169105,2.169105 0 0 1 -2.1665036,-2.166504 2.16675,2.16675 0 0 1 2.1665036,-2.1665042 z m -0.373535,6.7917482 -0.09668,0.0022 -0.0066,0.0044 A 13.522435,13.522435 0 0 0 5.8425292,15.824706 C 4.0034211,16.65966 2.9904784,17.775882 2.9904784,18.97119 v 3.019042 H 19.415038 V 18.97119 c 0,-1.195308 -1.013187,-2.311521 -2.85205,-3.146484 a 13.37857,13.37857 0 0 0 -4.658203,-1.10962 z m 0.314209,1.948974 a 11.651045,11.651045 0 0 1 4.530762,0.953614 c 1.244139,0.565428 1.707275,1.142336 1.707275,1.373291 v 1.067871 H 4.9394532 V 18.97119 c 0,-0.230955 0.4629016,-0.807863 1.7072752,-1.373291 A 11.7128,11.7128 0 0 1 11.22583,16.644285 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
