import { Utils } from '../Utils'

export class UriComposer {
  uri: string
  queryStringParams: Map<string, any>

  constructor(path: string) {
    this.uri = path
    this.queryStringParams = new Map()
  }

  public static create(path: string): UriComposer {
    const uriComposer = new UriComposer(path)
    return uriComposer
  }

  public getCompleteUri(): string {
    let __uri = this.uri
    let isFirstParameter = true
    this.queryStringParams.forEach((value, key, map) => {
      if (isFirstParameter) {
        __uri += `?${key}=${value}`
        isFirstParameter = false
      } else {
        __uri += `&${key}=${value}`
      }
    })
    return __uri
  }

  public addParamBool(name: string, value: boolean, bypassIfFalse: boolean = false) {
    if (value || !bypassIfFalse) {
      this.queryStringParams.set(name, value ? 'true' : 'false')
    }
    return this
  }

  public addParamBoolNullable(
    name: string,
    value?: boolean,
    bypassIfNull: boolean = true,
    bypassIfFalse: boolean = false,
    bypassIfTrue: boolean = false
  ) {
    if ((value === true && !bypassIfTrue) || (value === false && !bypassIfFalse)) {
      this.queryStringParams.set(name, value ? 'true' : 'false')
    } else {
      if (!bypassIfNull) {
        this.queryStringParams.set(name, 'null')
      }
    }
    return this
  }

  public addParamNumber(name: string, value: number) {
    this.queryStringParams.set(name, `${value}`)
    return this
  }

  public addParamNumberNullable(name: string, value?: number, bypassIfNull: boolean = true) {
    if (value !== null && value !== undefined) {
      this.queryStringParams.set(name, `${value}`)
    } else {
      if (!bypassIfNull) {
        this.queryStringParams.set(name, 'null')
      }
    }
    return this
  }

  public addParamNumberArray(name: string, values: number[]) {
    this.queryStringParams.set(name, values.join(','))
    return this
  }

  public addParamDate(name: string, value: Date) {
    this.queryStringParams.set(name, Utils.toDate(value, true))
    return this
  }

  public addParamDateNullable(name: string, value?: Date, bypassIfNull: boolean = true, toDateTime: boolean = false) {
    if (value) {
      if (toDateTime) {
        this.queryStringParams.set(name, value.toISOString())
      } else {
        this.queryStringParams.set(name, Utils.toDate(value, true))
      }
    } else {
      if (!bypassIfNull) {
        this.queryStringParams.set(name, 'null')
      }
    }
    return this
  }

  public addParamDateNullableStr(
    name: string,
    value?: string,
    bypassIfNull: boolean = true,
    toDateTime: boolean = false
  ) {
    if (value) {
      const date = new Date(value)
      if (toDateTime) {
        this.queryStringParams.set(name, date.toISOString())
      } else {
        this.queryStringParams.set(name, Utils.toDate(date, true))
      }
    } else {
      if (!bypassIfNull) {
        this.queryStringParams.set(name, 'null')
      }
    }
    return this
  }

  public addParamString(name: string, value?: string, bypassIfNull: boolean = true) {
    if (value) {
      this.queryStringParams.set(name, value)
    } else {
      if (!bypassIfNull) {
        this.queryStringParams.set(name, 'null')
      }
    }
    return this
  }

  public addParamIntArray(name: string, value?: number[], bypassIfNull: boolean = true) {
    if (value && value.length > 0) {
      this.queryStringParams.set(name, value.join(','))
    } else {
      if (!bypassIfNull) {
        this.queryStringParams.set(name, 'null')
      }
    }
    return this
  }
}
