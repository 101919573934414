import { Button, Chip, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { Utils } from '../../shared/Utils'
import DocumentsFiltersDialog from '../documentsFiltersDialog/DocumentsFiltersDialog'
import { useState } from 'react'
import { IDocumentsTableFiltersBarProps } from './DocumentsTableFiltersBar.types'
import { DocumentsQuery } from '../../models/DocumentsQuery'
import { Account } from '../../models/Account'

export default function DocumentsTableFiltersBar(props: IDocumentsTableFiltersBarProps): JSX.Element {
  const { t } = useTranslation()
  const keys = Object.keys(props.filter)
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  function onDelete(key: string) {
    const filter = { ...props.filter }
    delete (filter as any)[key]
    props.onChangeFilter(filter)
  }

  function onChangeFilter(filter: DocumentsQuery) {
    setOpenDialog(false)
    props.onChangeFilter(filter)
  }

  return (
    <>
      <Stack direction="row" alignItems="center">
        <Button onClick={() => setOpenDialog(true)}>{t(msgIds.MSG_DOCS_FILTERS_BAR_BUTTON)}</Button>
        <Stack direction="row" gap={1} flexWrap="wrap">
          {keys.length === 0 && <Typography variant="caption">{t(msgIds.MSG_DOCS_FILTERS_BAR_PLACEHOLDER)}</Typography>}
          {keys.toSorted().map((key) => {
            const value = (props.filter as any)[key]
            return <FilterChip key={key} fkey={key} fvalue={value} onDelete={onDelete} />
          })}
        </Stack>
      </Stack>
      <DocumentsFiltersDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        filter={props.filter}
        onChangeFilter={onChangeFilter}
      />
    </>
  )
}

interface IFilterChipProps {
  // ugly hack because "key" attribute is reserved
  fkey: string
  fvalue: any
  onDelete: (key: string) => void
}

function FilterChip({ fkey, fvalue, onDelete }: IFilterChipProps): JSX.Element {
  const { i18n, t } = useTranslation()
  let key = fkey,
    value = fvalue
  switch (fkey) {
    case 'from':
      key = t(msgIds.MSG_DOCS_FILTER_EDITED_FROM_KEY)
      value = Utils.toLocaleDateString(fvalue, i18n)
      break
    case 'to':
      key = t(msgIds.MSG_DOCS_FILTER_EDITED_TO_KEY)
      value = Utils.toLocaleDateString(fvalue, i18n)
      break
    case 'owners':
      key = t(msgIds.MSG_DOCS_FILTER_STRUCTURE_OWNER_KEY)
      value = mapAccounts(fvalue)
      break
    case 'authors':
      key = t(msgIds.MSG_DOCS_FILTER_OPERATOR_AUTHOR_KEY)
      value = mapAccounts(fvalue)
      break
    case 'onlyDrafts':
      key = t(msgIds.MSG_DOCS_FILTER_ONLY_DRAFTS_KEY)
      value = fvalue ? t(msgIds.MSG_YES) : t(msgIds.MSG_NO)
      break
    case 'createdByMe':
      key = t(msgIds.MSG_DOCS_FILTER_CREATED_BY_ME_KEY)
      value = fvalue ? t(msgIds.MSG_YES) : t(msgIds.MSG_NO)
      break
    case 'showObsolete':
      key = t(msgIds.MSG_DOCS_FILTER_SHOW_OBSOLETE_KEY)
      value = fvalue ? t(msgIds.MSG_YES) : t(msgIds.MSG_NO)
      break

    default:
      break
  }
  const label = `${key}: ${value}`
  return <Chip color="primary" label={label} onDelete={() => onDelete(fkey)} />
}

function mapAccounts(accounts?: any) {
  if (Array.isArray(accounts)) {
    return (accounts as Account[]).map((a) => a.getIdentityInverse()).join(', ')
  }
  return accounts
}
