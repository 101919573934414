import { useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { useUiContext, topbarHeight, leftbarWidth } from '../../contexts/UiContext'
import { useAuthContext } from '../../contexts/AuthContext'
import * as LeftbarData from './LeftbarData'
import LeftbarItemsArea from './LeftbarItemsArea'

export default function Leftbar() {
  const uiContext = useUiContext()
  const theme = useTheme()
  const authContext = useAuthContext()
  const [leftbarData, setLeftbarData] = useState<LeftbarData.ILeftbarItemsArea[]>([])

  useEffect(() => {
    const data = LeftbarData.getLeftbarData(authContext.loggedAccount, authContext.assistedAccount)
    setLeftbarData(data)
  }, [authContext.loggedAccount, authContext.assistedAccount])

  return (
    <MuiDrawer
      open={uiContext.isLeftbarOpened}
      variant="persistent"
      sx={{
        width: leftbarWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: leftbarWidth,
          boxSizing: 'border-box',
        },
        [theme.breakpoints.up('md')]: {
          variant: 'temporary',
        },
      }}
      PaperProps={{
        sx: {
          height: `calc(100% - ${topbarHeight}px)`,
          top: topbarHeight,
        },
      }}
    >
      <Box sx={{ overflow: 'auto' }}>
        <List>
          {leftbarData?.map((area, index) => (
            <ListItem key={index} className={area.tourName} disablePadding sx={{ display: 'flex' }}>
              <LeftbarItemsArea data={area} index={index} />
            </ListItem>
          ))}
        </List>
      </Box>
    </MuiDrawer>
  )
}
