import { Divider, Grid, Stack, Typography } from '@mui/material'
import { IRegistrationSummaryProps, IRegistrationSummarySection } from './RegistrationSummary.types'
import React from 'react'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'
import { RegistrationPurpose } from '../../shared/Constants'

export function RegistrationSummary(props: IRegistrationSummaryProps): JSX.Element {
  const { sections, purpose } = props
  const { t } = useTranslation()

  return (
    <Stack gap={6}>
      {purpose === RegistrationPurpose.contractRevision ? (
        <Typography>{t(msgIds.MSG_REGISTRATION_SUMMARY_CONFIRM_FOR_CONSENTS)}</Typography>
      ) : (
        sections.map((s) => <RegistrationSummarySection key={s.title} title={s.title} fields={s.fields} />)
      )}
    </Stack>
  )
}

function RegistrationSummarySection(props: IRegistrationSummarySection): JSX.Element {
  const { title, fields } = props
  return (
    <Stack gap={1}>
      <Typography fontStyle="italic" align="left">
        {title}
      </Typography>
      <Divider />
      <Grid container spacing={1}>
        {fields.map((f) => (
          <React.Fragment key={f.key}>
            <Grid item xs={4}>
              <Typography>{f.key}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>
                <b>{f.value ?? ''}</b>
              </Typography>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Stack>
  )
}
