import { Stack, StackProps, TextField } from '@mui/material'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import * as dalContract from '../../dal/DalContract'
import { Utils, ValType } from '../../shared/Utils'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import { enqueueSnackbar } from 'notistack'
import { ViewContent } from '../viewContent/ViewContent'
import { IContractEditorData } from './ContractEditor.types'
import { ContractType, ProfileType } from '../../shared/Constants'

export type ContractArgs = {
  name: string
}

const unsetArgs = {
  name: '',
}

export function ContractEditor(props: IContractEditorData & StackProps): JSX.Element {
  const { contract, onResult, ...rest } = props
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState<any>({})
  const [initialArgs, setInitialArgs] = useState<ContractArgs>(unsetArgs)
  const [currentArgs, setCurrentArgs] = useState<ContractArgs>(unsetArgs)
  const [areUnsavedChanges, setAreUnsavedChanges] = useState(false)

  function initFields() {
    const args = { name: contract?.name } as ContractArgs
    setInitialArgs(args)
    setCurrentArgs(args)
  }

  useEffect(() => {
    initFields()
  }, [contract])

  useEffect(() => {
    const check = initialArgs.name !== currentArgs.name
    setAreUnsavedChanges(check)
  }, [currentArgs])

  function onNameChanged(name: string | null) {
    setCurrentArgs({ ...currentArgs, name: name || '' })
    setErrors({ ...errors, name: undefined })
  }

  function validateData(): any | null {
    const errors = {
      name: Utils.validateText(currentArgs.name || '', [{ type: ValType.notNull }, { type: ValType.notEmpty }]),
    }
    if (Object.values(errors).find((e) => !!e)) {
      setErrors(errors)
      return errors
    } else {
      setErrors(null)
      return null
    }
  }

  async function onSave() {
    const errors = validateData()
    setErrors(errors || {})
    if (errors) {
      enqueueSnackbar(t(msgIds.MSG_VAL_ERR_THERE_ARE_FORM_ERRORS), { variant: 'error' })
      return
    }

    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const _contract = contract
        ? await dalContract.updateContract(abortController.signal, contract.id, currentArgs.name)
        : await dalContract.createNewContract(
            abortController.signal,
            ContractType.privacyPolicy,
            ProfileType.customer,
            currentArgs.name
          )
      enqueueSnackbar(t(msgIds.MSG_OPERATION_EXECUTED_SUCCESSFULLY), { variant: 'success' })
      onResult({ userChoice: 'yes', contract: _contract })
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  function onCancel() {
    initFields()
    onResult({ userChoice: 'no' })
  }

  return (
    <Stack alignItems="stretch" justifyContent="space-between" gap={2} {...rest}>
      <ViewContent id="dox-editor-view-content" spacing={2} alignItems="stretch">
        <TextField
          error={!!errors.city}
          helperText={t(errors.city)}
          label={t(msgIds.MSG_NAME)}
          variant="outlined"
          value={currentArgs?.name || ''}
          onChange={(event) => onNameChanged(event.target.value)}
        />
      </ViewContent>
      <ViewActions>
        <ViewActionsButton autoFocus defaultAction onClick={onSave} disabled={!areUnsavedChanges}>
          {t(msgIds.MSG_SAVE)}
        </ViewActionsButton>
        <ViewActionsButton onClick={onCancel}>{t(msgIds.MSG_CANCEL)}</ViewActionsButton>
      </ViewActions>
    </Stack>
  )
}
