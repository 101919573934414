import { Utils } from '../shared/Utils'

export interface IPriceRecurringJson {
  interval: string
  interval_count: number
}

export class PriceRecurring {
  interval: string = ''
  intervalCount: number = 0

  constructor() {}

  public static serialize(obj: PriceRecurring): IPriceRecurringJson {
    return {
      interval: obj.interval,
      interval_count: obj.intervalCount,
    }
  }

  public static deserialize(json: IPriceRecurringJson): PriceRecurring {
    const res = new PriceRecurring()
    res.interval = json.interval
    res.intervalCount = json.interval_count
    return res
  }
}
