import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function PrivacyNewIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 20.148519,0.41249987 V 3.8521651 h 3.439633 V 5.5723352 H 20.148519 V 9.0125854 H 18.428364 V 5.5723352 H 14.98806 V 3.8521651 h 3.440304 V 0.41249987 Z M 9.4207747,1.5688499 12.927801,3.128085 c -0.175944,0.5978101 -0.270776,1.2309451 -0.270776,1.8862801 l 0.0019,0.1819201 L 9.4200115,3.7711201 2.4144853,6.8536653 v 4.7234857 c 0,4.484235 3.0020897,8.68713 7.0055249,9.928485 4.0034558,-1.241355 7.0055468,-5.44425 7.0055468,-9.928485 v -0.559155 c 0.620029,0.298905 1.29305,0.505605 2.001394,0.600435 -0.01854,5.509035 -3.855638,10.69101 -9.0069395,11.96907 C 4.295592,22.306246 0.41250002,17.101981 0.41250002,11.577151 V 5.5723802 Z M 7.4193816,17.581936 3.4159465,13.579231 l 1.4009751,-1.401735 2.60246,2.522745 6.2416034,-6.1670556 c 0.353855,0.56772 0.790101,1.077885 1.293053,1.5141746 l -7.5346564,7.534576"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
