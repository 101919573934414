import { Stack, StackProps } from '@mui/material'

export interface ICenterProps extends StackProps {}

export function Center(props: ICenterProps): JSX.Element {
  const { children, ...rest } = props
  return (
    <Stack alignItems="center" justifyContent="center" {...rest}>
      {children}
    </Stack>
  )
}
