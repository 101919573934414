import { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { Notification } from '../models/Notification'
import { useAuthContext } from './AuthContext'
import * as dalNotification from '../dal/DalNotification'
import { GetNotificationsArgs } from '../dal/DalNotification'
import { Utils } from '../shared/Utils'
import { useTranslation } from 'react-i18next'
import { NotificationsContext } from './NotificationsContext'
import { sortNotifications } from '../components/notification/NotificationUtils'
import { INotificationsFilters } from '../components/notificationsFiltersForm/NotificationsFiltersForm.types'
import { AccountType, NotificationArgumentType } from '../shared/Constants'
import { typesFromArgument } from '../components/notification/NotificationUtils'

export type NotificationsContextProviderProps = PropsWithChildren

const pageSize = 20
function concatNotifications(first: Notification[], second: Notification[]): Notification[] {
  const filterFirst =
    first?.filter((f) => {
      const duplicate = second.find((s) => s.id === f.id)
      return !duplicate
    }) ?? []
  return [...filterFirst, ...second]
}

export function NotificationsContextProvider(props: NotificationsContextProviderProps): JSX.Element {
  const { children } = props
  const { t } = useTranslation()
  const authContext = useAuthContext()
  const [notificationStatsTrigger, setNotificationStatsTrigger] = useState<number>(0)
  const [firstLoad, setFirstLoad] = useState<boolean>(true)
  const [unread, setUnread] = useState<number>(0)
  const [notificationsMapByFilter, setNotificationsMapByFilter] = useState<{
    [filter: string]: Notification[]
  }>({})
  const notifications = notificationsMapByFilter['not_archived'] || []
  const currentNotificationPageNumRef = useRef<number>(-1)
  const [moreNotifications, setMoreNotifications] = useState<boolean>(true)
  const [notificationsPageNum, setNotificationsPageNum] = useState<number>(-1)
  const archivedNotifications = notificationsMapByFilter['archived'] || []
  const currentArchivedNotificationPageNumRef = useRef<number>(-1)
  const [moreArchivedNotifications, setMoreArchivedNotifications] = useState<boolean>(true)
  const [archivedNotificationsPageNum, setArchivedNotificationsPageNum] = useState(-1)
  const [filters, setFilters] = useState<INotificationsFilters>({
    argumentType: NotificationArgumentType.none,
  })
  const isGuest = authContext.loggedAccount?.user?.accountType === AccountType.placeholderUser

  function resetNotifications() {
    currentNotificationPageNumRef.current = -1
    setNotificationsPageNum(-1)
    setNotificationsMapByFilter((m) => {
      return { ...m, not_archived: [] }
    })
    setMoreNotifications(true)
  }

  function resetArchivedNotifications() {
    currentArchivedNotificationPageNumRef.current = -1
    setArchivedNotificationsPageNum(-1)
    setNotificationsMapByFilter((m) => {
      return { ...m, archived: [] }
    })
    setMoreArchivedNotifications(true)
  }

  function reset() {
    setFirstLoad(true)
    setNotificationStatsTrigger((t) => t + 1)
    resetNotifications()
    resetArchivedNotifications()
  }

  useEffect(() => {
    // on login, reset everything
    reset()
  }, [authContext?.loggedProfileId])

  useEffect(() => {
    const abortController = new AbortController()
    const delay = firstLoad ? 0 : 1000 * 60
    const timer = setTimeout(() => {
      if (authContext.loggedAccount && !isGuest) {
        dalNotification
          .getNotificationStats(abortController.signal)
          .then(({ unread }) => {
            setFirstLoad(false)
            setUnread(unread)
            setNotificationStatsTrigger((t) => t + 1)
          })
          .catch(Utils.enqueueSnackbarError(t))
      }
    }, delay)

    return () => {
      clearTimeout(timer)
      abortController.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLoad, notificationStatsTrigger, authContext.loggedAccount, isGuest])

  function loadNotifications() {
    if (!moreNotifications) {
      return
    }
    if (currentNotificationPageNumRef.current < 0) {
      // at first load of notifications, update also stats
      setFirstLoad(true)
    }
    setNotificationsPageNum(currentNotificationPageNumRef.current + 1)
  }

  useEffect(() => {
    const pageNum = notificationsPageNum
    if (pageNum < 0) {
      return
    }
    const abortController = new AbortController()
    dalNotification
      .getNotifications(abortController.signal, { isArchived: false, pageNum, pageSize })
      .then((notifications) => {
        currentNotificationPageNumRef.current = pageNum
        if (notifications.length === 0) {
          setMoreNotifications(false)
        }
        if (pageNum === 0) {
          setMoreNotifications(true)
          setNotificationsMapByFilter((m) => {
            return { ...m, not_archived: notifications }
          })
        } else {
          setNotificationsMapByFilter((m) => {
            const currentNotifications = m['not_archived']
            return { ...m, not_archived: concatNotifications(currentNotifications, notifications) }
          })
        }
      })
      .catch(Utils.enqueueSnackbarError(t))

    return () => abortController.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsPageNum])

  function loadArchivedNotifications() {
    if (!moreArchivedNotifications) {
      return
    }
    setArchivedNotificationsPageNum(currentArchivedNotificationPageNumRef.current + 1)
  }

  useEffect(() => {
    const pageNum = archivedNotificationsPageNum
    if (pageNum < 0) {
      return
    }

    const _filters: GetNotificationsArgs = {
      isArchived: true,
      pageNum: pageNum,
      pageSize: pageSize,
      authorProfileIds: filters.authorAccount?.profile?.profileId
        ? [filters.authorAccount?.profile?.profileId]
        : undefined,
      linkedAuthorProfileIds: filters.authorLinkedAccount?.profile?.profileId
        ? [filters.authorLinkedAccount?.profile?.profileId]
        : undefined,
      fromDate: filters.fromDate,
      toDate: filters.toDate,
      types: filters.argumentType ? typesFromArgument(filters.argumentType) : undefined,
    }

    const abortController = new AbortController()
    dalNotification
      .getNotifications(abortController.signal, _filters)
      .then((notifications) => {
        currentArchivedNotificationPageNumRef.current = pageNum
        if (notifications.length === 0) {
          setMoreArchivedNotifications(false)
        }
        if (pageNum === 0) {
          setMoreArchivedNotifications(true)
          setNotificationsMapByFilter((m) => {
            return { ...m, archived: notifications }
          })
        } else {
          setNotificationsMapByFilter((m) => {
            const currentNotifications = m['archived']
            return { ...m, archived: concatNotifications(currentNotifications, notifications) }
          })
        }
      })
      .catch(Utils.enqueueSnackbarError(t))

    return () => abortController.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archivedNotificationsPageNum, filters])

  function onPinnedUpdated(notification: Notification) {
    setNotificationsMapByFilter((m) => {
      const notifications = m['not_archived']
      const index = notifications.findIndex(({ id }) => id === notification.id)
      if (index === -1) {
        const newNotifications = [notification, ...notifications]
        sortNotifications(newNotifications)
        return { ...m, not_archived: newNotifications }
      } else {
        const currentNotification = notifications[index]
        if (notification.updatedAt >= currentNotification.updatedAt) {
          const { pinned, updatedAt } = notification
          const mergedNotification = { ...currentNotification, pinned, updatedAt }
          const newNotifications = [...notifications]
          newNotifications.splice(index, 1, mergedNotification)
          sortNotifications(newNotifications)
          return { ...m, not_archived: newNotifications }
        }
      }
      return m
    })
  }

  async function togglePinnedStatus(notification: Notification) {
    const abortController = new AbortController()
    const { id, pinned } = notification
    return dalNotification
      .setNotificationPinnedStatus(abortController.signal, id, !pinned)
      .then(onPinnedUpdated)
      .catch(Utils.enqueueSnackbarError(t))
  }

  function onArchivedAtUpdated(notification: Notification) {
    setNotificationsMapByFilter((m) => {
      const notifications = m['not_archived'] || []
      const archivedNotifications = m['archived'] || []
      const index = notifications.findIndex(({ id }) => id === notification.id)
      const indexArchived = archivedNotifications.findIndex(({ id }) => id === notification.id)
      const { archivedAt } = notification
      let newNotifications = notifications
      let newArchivedNotifications = archivedNotifications
      if (index === -1 && !archivedAt) {
        const archivedNotification = archivedNotifications[indexArchived]
        newNotifications = [
          archivedNotification ? { ...archivedNotification, archivedAt } : notification,
          ...notifications,
        ]
        sortNotifications(newNotifications)
      } else if (index !== -1 && archivedAt) {
        newNotifications = [...notifications]
        newNotifications.splice(index, 1)
        sortNotifications(newNotifications)
      }
      if (indexArchived === -1 && archivedAt) {
        const currentNotification = notifications[index]
        newArchivedNotifications = [
          currentNotification ? { ...currentNotification, archivedAt } : notification,
          ...archivedNotifications,
        ]
        sortNotifications(newArchivedNotifications)
      } else if (indexArchived !== -1 && !archivedAt) {
        newArchivedNotifications = [...archivedNotifications]
        newArchivedNotifications.splice(indexArchived, 1)
        sortNotifications(newArchivedNotifications)
      }
      return { ...m, not_archived: newNotifications, archived: newArchivedNotifications }
    })
    setFirstLoad(true)
  }

  async function archive(notification: Notification) {
    const abortController = new AbortController()
    const { id } = notification
    return dalNotification
      .archiveNotification(abortController.signal, id)
      .then(onArchivedAtUpdated)
      .catch(Utils.enqueueSnackbarError(t))
  }

  async function archiveAllNotPinned() {
    const toArchive = notifications.filter((n) => !n.pinned)
    return Promise.all(toArchive.map(archive)).then(() => {
      currentNotificationPageNumRef.current = -1
      setNotificationsPageNum(0)
      setMoreNotifications(true)
      setFirstLoad(true)
    })
  }

  function setArchivedFilters(filters: INotificationsFilters) {
    currentArchivedNotificationPageNumRef.current = 0
    setArchivedNotificationsPageNum(0)
    setNotificationsMapByFilter({})
    setFilters(filters)
  }

  const state = {
    unread,
    notifications,
    moreNotifications,
    archivedNotifications,
    moreArchivedNotifications,
    filters,
    resetNotifications,
    resetArchivedNotifications,
    loadNotifications,
    loadArchivedNotifications,
    togglePinnedStatus,
    archive,
    archiveAllNotPinned,
    setArchivedFilters,
  }
  return <NotificationsContext.Provider value={state}>{children}</NotificationsContext.Provider>
}
