import { ContractType_all, ProfileType_all } from '../shared/Constants'

export interface INotificationContextContractJson {
  id: number
  type: number
  target_profile_type: number
  owner_identity: string
  owner_profile_id?: number
}

export class NotificationContextContract {
  id: number = 0
  type: ContractType_all = 0
  targetProfileType: ProfileType_all = 0
  ownerIdentity: string = ''
  ownerProfileId?: number

  constructor() {}

  public static serialize(obj: NotificationContextContract): INotificationContextContractJson {
    return {
      id: obj.id,
      type: obj.type,
      target_profile_type: obj.targetProfileType,
      owner_identity: obj.ownerIdentity,
      owner_profile_id: obj.ownerProfileId,
    }
  }

  public static deserialize(json: INotificationContextContractJson): NotificationContextContract {
    const res = new NotificationContextContract()
    res.id = json.id
    res.type = json.type
    res.targetProfileType = json.target_profile_type
    res.ownerIdentity = json.owner_identity
    res.ownerProfileId = json.owner_profile_id
    return res
  }
}
