import { createContext, useContext } from 'react'

export interface IPageStepperContext {
  activeStep: any
}

export const PageStepperContext = createContext<IPageStepperContext>({ activeStep: null })

export function usePageStepperContext() {
  return useContext(PageStepperContext)
}
