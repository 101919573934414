import { Divider, Stack, Typography, useTheme } from '@mui/material'
import { IFieldInfoProps } from './FieldInfo.types'

export default function FieldInfo(props: IFieldInfoProps) {
  const theme = useTheme()

  return (
    <Stack direction={'column'} alignItems={'stretch'} marginTop={2}>
      <Typography color={theme.palette.common.gray4} variant="caption" sx={{ userSelect: 'none' }}>
        {props.title}
      </Typography>

      <Typography variant="body2" marginBottom={1}>
        <b>{props.text}</b>
      </Typography>

      {/* <Divider /> */}
    </Stack>
  )
}
