import { INotificationContextContractJson, NotificationContextContract } from './NotificationContextContract'
import {
  INotificationContextContractSectionActionJson,
  NotificationContextContractSectionAction,
} from './NotificationContextContractSectionAction'
import {
  INotificationContextContractVersionJson,
  NotificationContextContractVersion,
} from './NotificationContextContractVersion'

export interface INotificationContextConsentJson {
  id: number
  section_id: number
  profile_id: number
  revoked_at?: string
  revoke_cause: number
  section_read_at?: string
  is_auto_generated: boolean
  updated_at: string
  created_at: string
  actions?: INotificationContextContractSectionActionJson[]
  contract?: INotificationContextContractJson
  version?: INotificationContextContractVersionJson
}

export class NotificationContextConsent {
  id: number = 0
  sectionId: number = 0
  profileId: number = 0
  revokedAt?: Date
  revokeCause: number = 0
  sectionReadAt?: Date
  isAutoGenerated: boolean = false
  updatedAt: Date = new Date(0)
  createdAt: Date = new Date(0)
  actions?: NotificationContextContractSectionAction[]
  contract?: NotificationContextContract
  version?: NotificationContextContractVersion

  constructor() {}

  public static serialize(obj: NotificationContextConsent): INotificationContextConsentJson {
    return {
      id: obj.id,
      section_id: obj.sectionId,
      profile_id: obj.profileId,
      revoked_at: obj.revokedAt?.toISOString(),
      revoke_cause: obj.revokeCause,
      section_read_at: obj.sectionReadAt?.toISOString(),
      is_auto_generated: obj.isAutoGenerated,
      updated_at: obj.updatedAt.toISOString(),
      created_at: obj.createdAt.toISOString(),
      actions: obj.actions ? NotificationContextContractSectionAction.serializeArray(obj.actions) : [],
      contract: obj.contract ? NotificationContextContract.serialize(obj.contract) : undefined,
      version: obj.version ? NotificationContextContractVersion.serialize(obj.version) : undefined,
    }
  }

  public static deserialize(json: INotificationContextConsentJson): NotificationContextConsent {
    const res = new NotificationContextConsent()
    res.id = json.id
    res.sectionId = json.section_id
    res.profileId = json.profile_id
    res.revokedAt = json.revoked_at ? new Date(json.revoked_at) : undefined
    res.revokeCause = json.revoke_cause
    res.sectionReadAt = json.section_read_at ? new Date(json.section_read_at) : undefined
    res.isAutoGenerated = json.is_auto_generated
    res.updatedAt = new Date(json.updated_at)
    res.createdAt = new Date(json.created_at)
    res.actions = json.actions ? NotificationContextContractSectionAction.deserializeArray(json.actions) : []
    ;(res.contract = json.contract ? NotificationContextContract.deserialize(json.contract) : undefined),
      (res.version = json.version ? NotificationContextContractVersion.deserialize(json.version) : undefined)
    return res
  }
}
