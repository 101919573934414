export interface INotificationContextInvitationJson {
  id: number
  created_profile_id: number
  accepted_at?: string
  revoked_at?: string
}

export class NotificationContextInvitation {
  id: number = 0
  createdProfileId: number = 0
  acceptedAt?: Date
  revokedAt?: Date

  constructor() {}

  public static serialize(obj: NotificationContextInvitation): INotificationContextInvitationJson {
    return {
      id: obj.id,
      created_profile_id: obj.createdProfileId,
      accepted_at: obj.acceptedAt?.toISOString(),
      revoked_at: obj.revokedAt?.toISOString(),
    }
  }

  public static deserialize(json: INotificationContextInvitationJson): NotificationContextInvitation {
    const res = new NotificationContextInvitation()
    res.id = json.id
    res.createdProfileId = json.created_profile_id
    res.acceptedAt = json.accepted_at ? new Date(json.accepted_at) : undefined
    res.revokedAt = json.revoked_at ? new Date(json.revoked_at) : undefined
    return res
  }
}
