export interface IGuideCfgJson {
  silenced_ids: string[]
}

export class GuideCfg {
  silencedIds: string[] = []

  constructor() {}

  public static serialize(obj: GuideCfg): IGuideCfgJson {
    return {
      silenced_ids: obj.silencedIds,
    }
  }

  public static deserialize(json: IGuideCfgJson): GuideCfg {
    const res = new GuideCfg()
    res.silencedIds = json.silenced_ids
    return res
  }

  public isGuideItemSilenced(guideId: string): boolean {
    return this.silencedIds.includes(guideId)
  }
}
