import { useNavigate } from 'react-router'
import { LoginOrRegisterView } from './LoginOrRegisterView'
import { AppType, AppType_all, ProfileType, RegistrationPurpose } from '../../shared/Constants'
import { IRegistrationPageProps } from '../../pages/RegistrationPage/RegistrationPage.types'

export function LoginOrRegister(): JSX.Element {
  const navigate = useNavigate()
  function onLogin(appType: AppType_all) {
    navigate(`/login?profileType=${appType === AppType.business ? ProfileType.operatorAdmin : ProfileType.customer}`)
  }
  function onRegister(appType: AppType_all) {
    const _profileType = appType === AppType.business ? ProfileType.operatorAdmin : ProfileType.customer
    const _purpose = RegistrationPurpose.userRegistration
    navigate(`/registration?profileType=${_profileType}&purpose=${_purpose}`)
  }
  return <LoginOrRegisterView onLogin={onLogin} onRegister={onRegister} />
}
