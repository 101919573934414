import {
  Box,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  StackProps,
  TextField,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { IDoxTemplateEditorProps } from './DoxTemplateEditor.types'
import msgIds from '../../locales/msgIds'
import { useEffect, useState } from 'react'
import { TreatmentSelect } from '../treatmentSelect/TreatmentSelect'
import { RetentionRules } from '../../shared/Constants'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import { ViewContent } from '../viewContent/ViewContent'
import { DoxTemplate } from '../../models/DoxTemplate'

export function DoxTemplateEditor(props: IDoxTemplateEditorProps & StackProps): JSX.Element {
  const { dox, disableTreatment, onSave, onCancel, errors, ...stackProps } = props
  const { t } = useTranslation()
  const [draft, setDraft] = useState<DoxTemplate>(dox)

  useEffect(() => {
    setDraft(dox)
  }, [dox])

  function onChangeDraft(key: string, value: any) {
    setDraft((d: any) => {
      return { ...d, [key]: value }
    })
  }

  function onSaveProxy() {
    onSave(draft)
  }

  function onCancelProxy() {
    onCancel()
  }

  return (
    <Stack {...stackProps}>
      <ViewContent spacing={2}>
        <TextField
          error={errors.name}
          helperText={t(errors.name)}
          label={t(msgIds.MSG_DOX_EDITOR_NAME_LABEL)}
          value={draft.name}
          onChange={(event) => onChangeDraft('name', event.target.value)}
        />
        <TreatmentSelect
          disabled={disableTreatment}
          treatmentId={draft.treatmentId}
          onChangeTreatment={(treatment) => onChangeDraft('treatmentId', treatment?.id)}
        />
        {!disableTreatment && !!draft.treatmentId && (
          <Box>
            <Typography variant="subtitle1">{t(msgIds.MSG_DOX_EDITOR_RETENTION_TITLE)}</Typography>
            <Typography variant="subtitle2">{t(msgIds.MSG_DOX_EDITOR_RETENTION_DESCRIPTION)}</Typography>
          </Box>
        )}
        {!!draft.treatmentId && (
          <RadioGroup
            aria-labelledby="retention-rules-radio-buttons-group-label"
            name="retention-rules-radio-buttons-group"
            value={draft.retentionRules}
            onChange={(_event, value) => onChangeDraft('retentionRules', parseInt(value, 10))}
          >
            <FormControlLabel
              disabled={disableTreatment}
              value={RetentionRules.specific}
              control={<Radio />}
              label={t(msgIds.MSG_DOX_EDITOR_RETENTION_SPECIFIC_LABEL)}
            />
            <FormControlLabel
              disabled={disableTreatment}
              value={RetentionRules.centralized}
              control={<Radio />}
              label={t(msgIds.MSG_DOX_EDITOR_RETENTION_CENTRALIZED_LABEL)}
            />
          </RadioGroup>
        )}
        {!disableTreatment && !!draft.treatmentId && (
          <FormControlLabel
            control={
              <Checkbox
                onChange={(_event, checked) => onChangeDraft('automaticallySetEndRetentionDate', checked)}
                checked={draft.automaticallySetEndRetentionDate}
              />
            }
            label={t(msgIds.MSG_DOX_TEMPLATE_EDITOR_AUTO_RETENTION_DATE_LABEL)}
          />
        )}
      </ViewContent>
      <ViewActions>
        <ViewActionsButton autoFocus defaultAction onClick={onSaveProxy}>
          {t(msgIds.MSG_SAVE)}
        </ViewActionsButton>
        <ViewActionsButton onClick={onCancelProxy}>{t(msgIds.MSG_CANCEL)}</ViewActionsButton>
      </ViewActions>
    </Stack>
  )
}
