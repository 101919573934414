import React from 'react'
import { Button, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLayout } from '../../hooks/Layout'
import _ from 'lodash'
import { IImageCommandProps } from './ImageCommand.types'

export default function ImageCommand(props: IImageCommandProps): JSX.Element {
  const { t } = useTranslation()
  const { isMobile } = useLayout()
  const theme = useTheme()

  return (
    <Button
      key={props.id}
      onClick={() => props.onClick && props.onClick(props.id)}
      variant="text"
      disabled={!!props.isEnabled ? !props.isEnabled : true}
      style={{ textTransform: 'none', flexDirection: props.orientation }}
    >
      {props.icon &&
        React.cloneElement(props.icon, {
          color: props.isEnabled ? theme.palette.grey[800] : theme.palette.grey[400],
        })}
      <Typography
        variant="button"
        sx={{
          marginTop: '2px',
          marginBottom: '-4px',
          flexShrink: 0,
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          lineHeight: 1.25,
          fontWeight: 400,
          fontSize: isMobile ? 12 : 14,
          color: props.isEnabled ? theme.palette.grey[800] : theme.palette.grey[400],
          textTransform: 'none',
        }}
      >
        {props.label ? t(props.label) : ''}
      </Typography>
    </Button>
  )
}
