import { Button, Stack, TextField, Link, Typography } from '@mui/material'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useNavigate, useOutletContext } from 'react-router-dom'
import * as dalAuth from '../../dal/DalAuth'
import { useState } from 'react'
import { Utils } from '../../shared/Utils'
import { PDXLogo } from '../images'
import { StyledLoginForm } from '../../shared/StyledControls'
import { isOperator, ProfileType, RegistrationPurpose } from '../../shared/Constants'
import { ILoginViewProps } from './LoginView.types'
import { IRegistrationPageProps } from '../../pages/RegistrationPage/RegistrationPage.types'
import { PasswordInput } from '../passwordInput/PasswordInput'
import { useAuthContext } from '../../contexts/AuthContext'

export function LoginView(): JSX.Element {
  const state: ILoginViewProps = useOutletContext()
  const { profileType, onUserLogged, setIsLoading } = state
  const { t } = useTranslation()
  const authContext = useAuthContext()
  const navigate = useNavigate()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const isBusiness = isOperator(profileType)

  async function onLogin() {
    const abortController = new AbortController()
    try {
      setIsLoading(true)
      authContext.setJwt(undefined)
      const account = await dalAuth.doLogin(abortController.signal, email, password, isBusiness)
      if (account.user?.has2fa) {
        navigate(`/login/2fa?profileType=${profileType}`)
      } else {
        await onUserLogged(abortController.signal, account, profileType)
      }
    } catch (error) {
      Utils.enqueueSnackbarError2(error, t)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <StyledLoginForm>
      <form action="#" onSubmit={(event) => event.preventDefault()}>
        <Stack gap={4}>
          <Stack spacing={1}>
            <PDXLogo
              sx={{
                alignSelf: 'center',
                width: { xs: '70%', sm: '60%' },
                maxWidth: '420px',
                height: 'auto',
              }}
            />
            <Typography textAlign={'center'}>
              {isOperator(profileType) ? t(msgIds.MSG_LOGIN_FOR_OPERATORS) : t(msgIds.MSG_LOGIN_FOR_CUSTOMERS)}
            </Typography>
          </Stack>

          <TextField
            name="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            variant="outlined"
            label={t(msgIds.MSG_EMAIL)}
          />
          <PasswordInput
            sx={{ flexGrow: 1 }}
            label={t(msgIds.MSG_PASSWORD_EDITOR_PASSWORD)}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />

          <Button type="submit" variant="contained" onClick={onLogin}>
            {t(msgIds.MSG_LOGIN_BUTTON_TITLE)}
          </Button>

          <Stack gap={2} alignItems="center">
            <Link component={RouterLink} to="recovery">
              {t(msgIds.MSG_LOGIN_FORGOT_PASSWORD_LINK)}
            </Link>
            <Link
              component={RouterLink}
              to={`/registration?profileType=${profileType}&purpose=${RegistrationPurpose.userRegistration}`}
            >
              {t(msgIds.MSG_LOGIN_REGISTER_LINK)}
            </Link>
            <Link
              component={RouterLink}
              to={
                isOperator(profileType)
                  ? `/login?profileType=${ProfileType.customer}`
                  : `/login?profileType=${ProfileType.operatorAdmin}`
              }
            >
              {isOperator(profileType) ? t(msgIds.MSG_LOGIN_AS_CONSUMER_USER) : t(msgIds.MSG_LOGIN_AS_BUSINESS_USER)}
            </Link>
          </Stack>
        </Stack>
      </form>
    </StyledLoginForm>
  )
}
