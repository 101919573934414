import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import * as dalAuth from '../../dal/DalAuth'
import { Utils, ValType } from '../../shared/Utils'
import { Button, Link, Stack, TextField, Typography, useTheme } from '@mui/material'
import msgIds from '../../locales/msgIds'
import { Link as RouterLink } from 'react-router-dom'
import { PDXLogo } from '../images'
import { StyledLoginForm } from '../../shared/StyledControls'
import { useSnackbar } from 'notistack'

export function PasswordRecovery(): JSX.Element {
  const theme = useTheme()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [email, setEmail] = useState<string>('')
  const [errors, setErrors] = useState<any>({})

  function validateEmail() {
    const error = Utils.validateEmail(email, [{ type: ValType.notEmpty }])
    if (error) {
      setErrors({ email: error })
    }
    return error
  }

  async function onSendEmail() {
    const error = validateEmail()
    if (error) {
      return
    }
    try {
      const abortController = new AbortController()
      const response = await dalAuth.sendPasswordResetMail(abortController.signal, email)
      console.log({ response })
      enqueueSnackbar(t(msgIds.MSG_PASSWORD_RECOVERY_MAIL_SUCCESSFULLY), { variant: 'success' })
    } catch (error) {
      Utils.enqueueSnackbarError2(error, t)
    }
  }

  return (
    <StyledLoginForm>
      <PDXLogo
        sx={{
          alignSelf: 'center',
          width: { xs: '70%', sm: '60%' },
          maxWidth: '420px',
          height: 'auto',
        }}
      />
      <Typography>{t(msgIds.MSG_PASSWORD_RECOVERY_INFO)}</Typography>
      <TextField
        error={Boolean(errors.email)}
        helperText={errors.email ? t(errors.email) : undefined}
        onBlur={validateEmail}
        name="email"
        value={email}
        onChange={(event) => {
          setErrors((errors: any) => {
            return { ...errors, email: undefined }
          })
          setEmail(event.target.value)
        }}
        variant="outlined"
        label={t(msgIds.MSG_EMAIL)}
      />
      <Button variant="contained" onClick={onSendEmail}>
        {t(msgIds.MSG_PASSWORD_RECOVERY_SEND_EMAIL_BUTTON)}
      </Button>
      <Stack gap={2} alignItems="center">
        <Link component={RouterLink} to="/login">
          {t(msgIds.MSG_PASSWORD_RECOVERY_LOGIN_LINK)}
        </Link>
      </Stack>
    </StyledLoginForm>
  )
}
