import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function DoxIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 20.539999,5.23 19.15,3.55 C 18.88,3.21 18.469999,3 18,3 H 6 C 5.53,3 5.1200001,3.21 4.84,3.55 L 3.46,5.23 C 3.1699999,5.5700001 3,6.0200001 3,6.5000001 V 19 c 0,1.1 0.9,2 2.0000001,2 H 19 c 1.1,0 2,-0.9 2,-2 V 6.5000001 c 0,-0.48 -0.17,-0.93 -0.460001,-1.2700001 z M 6.24,5.0000001 H 17.76 L 18.57,5.97 H 5.44 Z M 5.0000001,19 V 8.0000001 H 19 V 19 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
