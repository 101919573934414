import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function RetentionIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 3.9990234,1.9995117 c -0.999999,0 -1.9995117,0.8995128 -1.9995117,1.9995117 v 3.010254 c 0,0.7199992 0.4297564,1.3396975 0.9997558,1.6896972 V 19.999512 c 0,1.099999 1.1017098,1.999512 2.0017091,1.999512 H 18.999756 c 0.899998,0 1.999512,-0.899513 1.999512,-1.999512 V 8.6989746 c 0.569998,-0.3499997 0.999756,-0.969698 0.999756,-1.6896972 v -3.010254 c 0,-1.0999989 -0.999513,-1.9995117 -1.999512,-1.9995117 z m 0,1.9995117 H 19.999512 V 7.0004883 H 3.9990234 Z M 5.0009766,9 H 18.999756 V 19.999512 H 5.0009766 Z M 9,11.999268 v 2.001709 h 6.000732 v -2.001709 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
