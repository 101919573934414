import { useTranslation } from 'react-i18next'
import CommandBar from '../../components/commandBar/CommandBar'
import { PageContainer, PageContent } from '../../components/pageContainer/PageContainer'
import msgIds from '../../locales/msgIds'
import { IWorkingDocument, useWorkingDocumentsContext } from '../../contexts/WorkingDocumentsContext'
import { IconButton, Paper, Stack } from '@mui/material'
import { DocumentEditorNewRef } from '../../components/documentEditor/DocumentEditorNew'
import { DesktopDocumentsMenuMode } from '../../components/desktopDocumentsMenu/DesktopDocumentsMenu.types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { ActionType, ArchiveItemSourceType } from '../../shared/Constants'
import { Utils } from '../../shared/Utils'
import { useAuthContext } from '../../contexts/AuthContext'
import { DesktopMenu } from '../../components/desktopMenu/DesktopMenu'
import { DocumentFile } from '../../shared/types/DocumentFile'
import { useLayout } from '../../hooks/Layout'
import {
  AddDocExternalIco,
  AddDocInternalIco,
  AddDocInternalTemplateIco,
  ClearDesktopIco,
  SettingsIco,
} from '../../components/icons'
import { Center } from '../../components/center/Center'
import { InternalDocumentTemplate } from '../../models/InternalDocumentTemplate'
import { useLocation } from 'react-router'
import { IDesktopPageProps } from './DesktopPage.types'
import { ISimpleDialogData } from '../../dialogs/simpleDialog/SimpleDialog.types'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'
import { IInternalDocTemplateSelectorDialogData } from '../../dialogs/internalDocTemplateSelectorDialog/InternalDocTemplateSelectorDialog.types'
import InternalDocTemplateSelectorDialog from '../../dialogs/internalDocTemplateSelectorDialog/InternalDocTemplateSelectorDialog'
import { useSnackbar } from 'notistack'
import DocumentEditorNew from '../../components/documentEditor/DocumentEditorNew'

export default function DesktopPage() {
  const location = useLocation()
  const state = location.state as IDesktopPageProps
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const { theme, isMobile } = useLayout()
  const wd = useWorkingDocumentsContext()
  const authContext = useAuthContext()
  const [documentNames, setDocumentNames] = useState<Record<string, string>>({})

  const currentDocumentEditorState = wd.getCurrentDesktopDocumentEditorState()
  const currentDocument = currentDocumentEditorState?.document

  useEffect(() => {
    const _documentsInDesktop = wd.workingDocuments.filter((p) => p.isInDesktop)
    const _documentNames: Record<string, string> = _documentsInDesktop.reduce(
      (acc, { documentIID, documentEditorState }) => {
        const _state = documentEditorState
        const _revision = _state.isEditing
          ? _state.draftRevision
          : _state.document.getRevision(_state.revisionId) ??
            _state.document.getLastArchivedRevision() ??
            _state.document.getDraftRevision()
        acc[documentIID] = _revision?.name ?? '...'
        return acc
      },
      {} as Record<string, string>
    )
    setDocumentNames(_documentNames)
  }, [wd.workingDocuments])

  // dialogs
  const [simpleDialogData, setSimpleDialogData] = useState<ISimpleDialogData>()
  const [simpleDialogOpen, setSimpleDialogOpen] = useState(false)
  const [internalDocTemplateSelectorDialogData, setInternalDocTemplateSelectorDialogData] =
    useState<IInternalDocTemplateSelectorDialogData>()
  const [internalDocTemplateSelectorDialogOpen, setInternalDocTemplateSelectorDialogOpen] = useState(false)

  useEffect(() => {
    const documentId = state.documentId
    if (!documentId) {
      return
    }
    const workingDocument = wd.workingDocuments.find((p) => p.documentEditorState.document.documentId === documentId)
    if (workingDocument) {
      wd.setCurrentDesktopDocument(workingDocument.documentIID)
    }
  }, [state.documentId])

  function onClickDocument(workingDocument: IWorkingDocument) {
    wd.setCurrentDesktopDocument(workingDocument.documentIID)
  }

  async function onRemoveDocument(workingDocument: IWorkingDocument) {
    const documentIID = workingDocument.documentIID
    if (wd.isDocumentNotSaved(documentIID)) {
      const checkResult = await checkNotSavedChanges([documentIID])
      if (checkResult.aborted) {
        return
      } else {
        wd.removeDocument(documentIID)
      }
    } else {
      wd.removeDocument(documentIID)
    }
  }

  async function onRemoveAllDocuments() {
    const documentIIDs = wd.getDocumentIIDs('all')
    const notSaveDocumentIIDs = wd.getDocumentIIDs('desktop_not_saved')
    const savedDocumentIIDs = Utils.getArrayDifference(documentIIDs, notSaveDocumentIIDs)
    savedDocumentIIDs.forEach((document) => wd.removeDocument(document))
    const checkResult = await checkNotSavedChanges(notSaveDocumentIIDs)
    if (checkResult.aborted) {
      return
    }
    wd.removeAllDocuments()
  }

  type SavePendingChangesResult = {
    aborted: boolean
    saved: string[]
  }
  async function checkNotSavedChanges(documentIIDs: string[]): Promise<SavePendingChangesResult> {
    const checkResult: SavePendingChangesResult = {
      aborted: false,
      saved: [],
    }
    for (const documentIID of documentIIDs) {
      const documentEditorState = wd.getDocumentEditorState(documentIID)
      const revision = documentEditorState?.draftRevision
      if (revision) {
        await new Promise<void>((resolve) => {
          setSimpleDialogData({
            title: t(msgIds.MSG_DOX_SELECTOR_DIALOG_TITLE),
            content: t(msgIds.MSG_DOCUMENT_NOT_SAVED_CHANGED_DIALOG_BODY, { name: revision.name }),
            actionsStyle: 'YESnoabort',
            onClose: async (result) => {
              setSimpleDialogOpen(false)
              try {
                const _state = wd.getDocumentEditorState(documentIID)
                const _draftDocument = _state?.draftDocument
                if (!_draftDocument) {
                  return
                }
                if (result.userChoice === 'yes') {
                  const _documentIID = await wd.saveDocument(documentIID)
                  checkResult.saved.push(_documentIID)
                  enqueueSnackbar(t(msgIds.MSG_DOCUMENT_SAVED_SUCCESSFULLY), { variant: 'success' })
                } else if (result.userChoice === 'no') {
                } else if (result.userChoice === 'abort') {
                  checkResult.aborted = true
                }
              } catch (err) {
                Utils.enqueueSnackbarError2(err, t)
              }
              resolve() // Resolve promise to go to next document
            },
          })
          setSimpleDialogOpen(true)
        })
      }
    }
    return checkResult
  }

  const [menuMode, setMenuMode] = useState<DesktopDocumentsMenuMode>('compact')

  const hoverTimer = useRef<any>()
  function onHoverMenu() {
    if (hoverTimer.current) {
      return
    }
    hoverTimer.current = setTimeout(() => {
      setMenuMode('expanded')
      hoverTimer.current = undefined
    }, 300)
  }

  function onLeaveMenu() {
    if (hoverTimer.current) {
      clearTimeout(hoverTimer.current)
      hoverTimer.current = undefined
    }
    setMenuMode('compact')
  }

  function toggleMenuMode() {
    setMenuMode((m) => (m === 'expanded' ? 'compact' : 'expanded'))
  }

  function onCreateDocumentFromTemplate(template: InternalDocumentTemplate) {
    if (!authContext.loggedAccount?.canDo(ActionType.createDocuments)) return
    const name = createNewUniqueDocumentName()
    const document = Utils.createDocument(ArchiveItemSourceType.internalSource, authContext, t, name, template.content)
    wd.addDocument(document, true, { isEditing: true })
  }

  function onCreateDocument(sourceType: ArchiveItemSourceType, file?: DocumentFile) {
    if (!authContext.loggedAccount?.canDo(ActionType.createDocuments)) return
    const name = createNewUniqueDocumentName()
    const document = Utils.createDocument(sourceType, authContext, t, name)
    if (file && file.data) {
      // console.log({ file })
      const revision = document.revisions[0]
      if (revision) {
        revision.content = file.data
        revision.mimetype = file.mimetype
        if (file.checksum) {
          revision.checksum = file.checksum
        }
        revision.name = file.name
        revision.filename = file.name
        revision.size = file.size
      }
    }

    wd.addDocument(document, true, {
      isEditing: true,
      draftDocument: document,
      draftRevision: document.getDraftRevision(),
      isClosing: false,
    })
  }

  function createNewUniqueDocumentName() {
    const names =
      wd.workingDocuments.map((workingDocument) => {
        const draftRevision = workingDocument?.documentEditorState.draftRevision
        const name = draftRevision ? draftRevision.name : workingDocument?.documentEditorState.document?.getName()
        return name ?? '...'
      }) ?? []
    const name = Utils.computeDefaultDistinctName(t(msgIds.MSG_DOCUMENT_EDITOR_NEW_INTERNAL_DOCUMENT_NAME), names)
    return name
  }

  const pageRef = useRef<HTMLDivElement>(null)

  const currentDocumentMarkerWidth = 16
  const menuCollapsedWidth = isMobile ? 0 : 54
  const menuExpandedWidth =
    isMobile && pageRef.current ? pageRef.current.clientWidth - currentDocumentMarkerWidth * 2 : 300
  const paddingLeft = isMobile
    ? currentDocumentMarkerWidth * 2 + 'px'
    : menuCollapsedWidth + currentDocumentMarkerWidth * 2 + 'px'

  const commandBarCommands = useMemo(() => {
    const commands = []
    if (
      authContext.linkedStructureAccount?.profile?.fePreferences?.archiveCfg?.internalDocumentTemplates &&
      authContext.linkedStructureAccount?.profile?.fePreferences?.archiveCfg?.internalDocumentTemplates?.length > 0
    ) {
      commands.push({
        commandText: t(msgIds.MSG_COMMAND_CREATE_DOCUMENT_FROM_TEMPLATE),
        tooltipText: t(msgIds.MSG_DESKTOP_CREATE_INTERNAL_DOCUMENT_FROM_TEMPLATE_TOOLTIP),
        icon: <AddDocInternalTemplateIco />,
        onClick: () => {
          createNewDocumentFromTemplate()
        },
      })
    }

    commands.push({
      commandText: t(msgIds.MSG_COMMAND_CREATE_DOCUMENT),
      tooltipText: t(msgIds.MSG_COMMAND_CREATE_DOCUMENT),
      icon: <AddDocInternalIco />,
      onClick: () => {
        onCreateDocument(ArchiveItemSourceType.internalSource)
      },
    })
    commands.push({
      commandText: t(msgIds.MSG_COMMAND_UPLOAD_DOCUMENT),
      tooltipText: t(msgIds.MSG_COMMAND_UPLOAD_DOCUMENT),
      icon: <AddDocExternalIco />,
      onClick: () => {
        onCreateDocument(ArchiveItemSourceType.externalSource)
      },
    })
    commands.push({
      commandText: t(msgIds.MSG_DESKTOP_CLEAR_TOOLTIP),
      tooltipText: t(msgIds.MSG_DESKTOP_CLEAR_TOOLTIP),
      icon: <ClearDesktopIco />,
      onClick: () => {
        onRemoveAllDocuments()
      },
    })
    return commands
  }, [authContext.loggedProfileId, t])

  function createNewDocumentFromTemplate() {
    if (!authContext.loggedAccount?.canDo(ActionType.createDocuments)) return
    const templates =
      authContext.linkedStructureAccount?.profile?.fePreferences?.archiveCfg?.internalDocumentTemplates || []
    if (templates.length > 0) {
      setInternalDocTemplateSelectorDialogOpen(true)
      setInternalDocTemplateSelectorDialogData({
        onResult: (result) => {
          setInternalDocTemplateSelectorDialogOpen(false)
          if (result.userChoice !== 'yes') return
          const newDocument = Utils.createDocument(
            ArchiveItemSourceType.internalSource,
            authContext,
            t,
            undefined,
            result.template.content
          )
          wd.addDocument(newDocument, true, { isEditing: true })
        },
      })
    } else {
      setSimpleDialogOpen(true)
      setSimpleDialogData({
        title: t(msgIds.MSG_DOCUMENTS_TEMPLATES_DIALOG_TITLE),
        content: t(msgIds.MSG_DOCUMENTS_TEMPLATES_TEMPLATES_LIST_EMPTY),
        actionsStyle: 'Ok',
        onClose: () => {
          setSimpleDialogOpen(false)
        },
      })
    }
  }

  const documentEditorRef = useRef<DocumentEditorNewRef | null>(null)

  return (
    <PageContainer>
      {simpleDialogData && <SimpleDialog {...simpleDialogData} isOpen={simpleDialogOpen}></SimpleDialog>}
      {internalDocTemplateSelectorDialogData && (
        <InternalDocTemplateSelectorDialog
          {...internalDocTemplateSelectorDialogData}
          isOpen={internalDocTemplateSelectorDialogOpen}
          onClose={() => setInternalDocTemplateSelectorDialogOpen(false)}
        />
      )}

      <Stack direction="row" alignItems="stretch">
        {isMobile && (
          <Center sx={{ width: 54, backgroundColor: theme.palette.grey[400] }}>
            <IconButton onClick={() => toggleMenuMode()} sx={{ flexShrink: 0 }}>
              <SettingsIco />
            </IconButton>
          </Center>
        )}
        <CommandBar
          style={{ minHeight: 'auto', width: 'auto', flex: 1 }}
          title={currentDocument ? documentNames[currentDocument.iid] : ''}
          commands={commandBarCommands}
        />
      </Stack>
      <PageContent
        ref={pageRef}
        flexDirection="row"
        justifyContent="stretch"
        alignItems="stretch"
        sx={{ position: 'relative', padding: 0 }}
      >
        <DesktopMenu
          sx={{
            zIndex: 25,
            position: 'absolute',
            height: '100%',
            left: 0,
            top: 0,
            bottom: 0,
          }}
          onMouseEnter={onHoverMenu}
          onMouseLeave={onLeaveMenu}
          menuMode={menuMode}
          collapsedWith={menuCollapsedWidth}
          expandedWith={menuExpandedWidth}
          toggleMenuMode={toggleMenuMode}
          onCreateDocumentFromTemplate={onCreateDocumentFromTemplate}
          onCreateDocument={onCreateDocument}
          onRemoveDocument={onRemoveDocument}
          onRemoveAllDocuments={onRemoveAllDocuments}
          onClickDocument={onClickDocument}
          documentNames={documentNames}
        />
        <Stack p={2} flex={1} pl={paddingLeft}>
          <Paper sx={{ flex: 1, display: 'flex', maxHeight: '100%' }}>
            {currentDocument && (
              <DocumentEditorNew
                ref={documentEditorRef}
                documentIID={currentDocument.iid}
                isEditable={true}
                isDesktop={true}
                onClosingDocumentEditor={() => {
                  wd.removeDocument(currentDocument.iid)
                }}
                onEditedDocumentAssignedDoxChanged={() => {}}
                onDraftNameChanged={(documentIID, name) => {
                  const _documentNames = { ...documentNames, [documentIID]: name }
                  setDocumentNames(_documentNames)
                }}
              />
            )}
          </Paper>
        </Stack>
      </PageContent>
    </PageContainer>
  )
}
