import { ContractType, ContractType_all, ContractVersionState, ProfileType } from '../shared/Constants'
import { ContractVersion, IContractVersionJson } from './ContractVersion'

export interface IContractJson {
  id: number
  name: string
  type: number
  public: boolean
  target_profile_type: number
  author_profile_id: number
  owner_profile_id: number
  created_at: string
  updated_at: string
  versions: IContractVersionJson[]
}

export class Contract {
  id: number = 0
  name: string = ''
  type: ContractType_all = ContractType.privacyPolicy
  public: boolean = false
  targetProfileType: number = ProfileType.none
  authorProfileId: number = 0
  ownerProfileId: number = 0
  createdAt: Date = new Date(0)
  updatedAt: Date = new Date(0)
  versions: ContractVersion[] = []

  _draftVersion: ContractVersion | undefined
  get draftVersion(): ContractVersion | undefined {
    return this.versions.find((p) => p.state === ContractVersionState.draft)
  }

  _publishedVersion: ContractVersion | undefined
  get publishedVersion(): ContractVersion | undefined {
    return this.versions.find((p) => p.state === ContractVersionState.published)
  }

  constructor() {}

  public static serialize(obj: Contract): IContractJson {
    return {
      id: obj.id,
      name: obj.name,
      type: obj.type,
      public: obj.public,
      target_profile_type: obj.targetProfileType,
      author_profile_id: obj.authorProfileId,
      owner_profile_id: obj.ownerProfileId,
      created_at: obj.createdAt.toISOString(),
      updated_at: obj.updatedAt.toISOString(),
      versions: obj.versions ? ContractVersion.serializeArray(obj.versions) : [],
    }
  }

  public static deserialize(json: IContractJson): Contract {
    const res = new Contract()
    res.id = json.id
    res.name = json.name
    res.type = json.type
    res.public = json.public
    res.targetProfileType = json.target_profile_type
    res.authorProfileId = json.author_profile_id
    res.ownerProfileId = json.owner_profile_id
    res.createdAt = new Date(json.created_at)
    res.updatedAt = new Date(json.updated_at)
    res.versions = json.versions ? ContractVersion.deserializeArray(json.versions) : []
    return res
  }

  public static serializeArray(objs: Contract[]): IContractJson[] {
    const jsons = objs.map((p) => {
      return Contract.serialize(p)!
    })
    return jsons
  }

  public static deserializeArray(json: IContractJson[]): Contract[] {
    const res = json.map((p) => {
      return Contract.deserialize(p)!
    })
    return res
  }
}
