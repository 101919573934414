import { Utils } from '../shared/Utils'
import { IPriceRecurringJson, PriceRecurring } from './PriceRecurring'

export interface IPriceJson {
  id: string
  product_id: string
  unit_amount: number
  created_at: string
  recurring?: IPriceRecurringJson
}

export class Price {
  id: string = ''
  productId: string = ''
  amount: number = 0
  createdAt: Date = new Date(0)
  recurring?: PriceRecurring

  _amountDsc: string = ''
  get amountDsc(): string {
    return Utils.toCurrencyFormat(this.amount / 100, false, 2)
  }

  constructor() {}

  public static serialize(obj: Price): IPriceJson {
    return {
      id: obj.id,
      product_id: obj.productId,
      unit_amount: obj.amount,
      created_at: obj.createdAt.toISOString(),
      recurring: obj.recurring ? PriceRecurring.serialize(obj.recurring) : undefined,
    }
  }

  public static deserialize(json: IPriceJson): Price {
    const res = new Price()
    res.id = json.id
    res.productId = json.product_id
    res.amount = json.unit_amount
    res.createdAt = new Date(json.created_at)
    res.recurring = json.recurring ? PriceRecurring.deserialize(json.recurring) : undefined
    return res
  }

  public static serializeArray(objs: Price[]): IPriceJson[] {
    const jsons = objs.map((p) => {
      return Price.serialize(p)!
    })
    return jsons
  }

  public static deserializeArray(json: IPriceJson[]): Price[] {
    const res = json.map((p) => {
      return Price.deserialize(p)!
    })
    return res
  }
}
