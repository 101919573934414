import { useRef, useState } from 'react'

// references:
// https://legacy.reactjs.org/docs/hooks-faq.html#how-do-i-implement-getderivedstatefromprops
// https://legacy.reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html
// this works better than "useEffect(..., [props.selectedDoxIds])" because it avoids the re-rendering so the UI glitch

/**
 * useChange works like useEffect but it executes the callback immediately, without wait for next rendering.
 * It does not support a "release" function to call, if you need use useEffect
 *
 * @param {() => void} callback
 * @param {any[]} values
 */
export function useChange(callback: () => void, values: any[]) {
  const previousValue = useRef<any[]>([])
  if (isChanged(previousValue.current, values)) {
    previousValue.current = values
    callback()
  }
}

function isChanged(valuesX: any[], valuesY: any[]): boolean {
  if (valuesX.length !== valuesY.length) {
    return true
  }
  for (let i = 0, len = valuesX.length; i < len; ++i) {
    if (!Object.is(valuesX[i], valuesY[i])) {
      return true
    }
  }
  return false
}
