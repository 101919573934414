export interface IProfileDetailsJson {
  profile_id: number
  owner_profile_id: number

  phone: string
  mobile_phone1: string
  mobile_phone2: string
  email1: string
  email2: string
  email3: string
  email4: string
  website: string

  country: string
  city: string
  province: string
  street: string
  street_number: string
  zip: string
  extra: string
}

export class ProfileDetails {
  profileId: number = 0
  ownerProfileId: number = 0

  phone: string = ''
  mobilePhone1: string = ''
  mobilePhone2: string = ''
  email1: string = ''
  email2: string = ''
  email3: string = ''
  email4: string = ''
  website: string = ''

  country: string = ''
  city: string = ''
  province: string = ''
  street: string = ''
  streetNumber: string = ''
  zip: string = ''
  extra: string = ''

  constructor() {}

  public static serialize(obj: ProfileDetails): IProfileDetailsJson {
    return {
      profile_id: obj.profileId,
      owner_profile_id: obj.ownerProfileId,

      phone: obj.phone,
      mobile_phone1: obj.mobilePhone1,
      mobile_phone2: obj.mobilePhone2,
      email1: obj.email1,
      email2: obj.email2,
      email3: obj.email3,
      email4: obj.email4,
      website: obj.website,

      country: obj.country,
      city: obj.city,
      province: obj.province,
      street: obj.street,
      street_number: obj.streetNumber,
      zip: obj.zip,
      extra: obj.extra,
    }
  }

  public static deserialize(json: IProfileDetailsJson): ProfileDetails {
    const res = new ProfileDetails()
    res.profileId = json.profile_id
    res.ownerProfileId = json.owner_profile_id

    res.phone = json.phone
    res.mobilePhone1 = json.mobile_phone1
    res.mobilePhone2 = json.mobile_phone2
    res.email1 = json.email1
    res.email2 = json.email2
    res.email3 = json.email3
    res.email4 = json.email4
    res.website = json.website

    res.country = json.country
    res.city = json.city
    res.province = json.province
    res.street = json.street
    res.streetNumber = json.street_number
    res.zip = json.zip
    res.extra = json.extra

    return res
  }

  public static serializeArray(objs: ProfileDetails[]): IProfileDetailsJson[] {
    const jsons = objs.map((p) => {
      return ProfileDetails.serialize(p)!
    })
    return jsons
  }

  public static deserializeArray(json: IProfileDetailsJson[]): ProfileDetails[] {
    const res = json.map((p) => {
      return ProfileDetails.deserialize(p)!
    })
    return res
  }

  public getStreetInfo(): string {
    const parts: string[] = []
    if (this.street) parts.push(this.street)
    if (this.streetNumber) parts.push(`${this.streetNumber}`)
    return parts.join(' ')
  }

  public getCityInfo(): string {
    const parts: string[] = []
    if (this.city) parts.push(`${this.city}`)
    if (this.province) parts.push(`(${this.province})`)
    return parts.join(' ')
  }

  public getAddressInfo(street: boolean, zip: boolean, city: boolean, country: boolean): string {
    const parts: string[] = []
    if (street) parts.push(this.getStreetInfo())
    if (zip) parts.push(this.zip)
    if (city) parts.push(this.getCityInfo())
    if (country) parts.push(this.country)
    return parts.join(' - ')
  }
}
