import { Position, StepType } from '@reactour/tour'
import MarkdownViewerNew from '../components/markdownEditorNew/MarkdownViewerNew'
import { Checkbox, FormControlLabel, Stack } from '@mui/material'
import msgIds from '../locales/msgIds'
import { useTranslation } from 'react-i18next'
import { useTourStateContext } from '../contexts/TourStateContext'

interface IHintContentProps {
  tourName: string
  text: string
}

function HintContent(props: IHintContentProps): JSX.Element {
  const { tourName, text } = props
  const { t } = useTranslation()
  const tourStateContext = useTourStateContext()

  return (
    <Stack spacing={1}>
      <MarkdownViewerNew justify={true} initialValue={text} />
      <FormControlLabel
        control={
          <Checkbox
            checked={!!tourStateContext.tourToNeverShowAgain}
            onChange={(event) => tourStateContext.setTourToNeverShowAgain(event.target.checked ? tourName : '')}
            sx={{ marginLeft: -1.4 }}
          />
        }
        label={t(msgIds.MSG_HINT_NEVER_SHOW_AGAIN)}
        componentsProps={{
          typography: { sx: { fontSize: '14px' } },
        }}
      />
    </Stack>
  )
}

export type StepFactoryArgs = {
  tourName: string
  selector?: string
  text: string
  action?: (elem: Element | null) => void
  actionAfter?: (elem: Element | null) => void
}
export function stepFactory(args: StepFactoryArgs): StepType {
  let _step = {
    selector: `.${args.selector}`,
    content: <HintContent tourName={args.tourName} text={args.text} />,
    action: args.action,
    actionAfter: args.actionAfter,
  }
  return args.selector
    ? _step
    : {
        ..._step,
        selector: '.new-entry-tour-intro',
        position: 'center' as Position,
        padding: 0,
      }
}
