import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { Link as RouterLink, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import * as dalAuth from '../../dal/DalAuth'
import * as dalPermission from '../../dal/DalPermission'
import { Utils } from '../../shared/Utils'
import { PDXLogo } from '../images'
import { StyledLoginForm } from '../../shared/StyledControls'
import { ILoginViewProps } from '../loginView/LoginView.types'
import { isOperator } from '../../shared/Constants'

export function LoginGuestView(): JSX.Element {
  const { token } = useParams()

  const state: ILoginViewProps = useOutletContext()
  const { profileType, onUserLogged, setIsLoading } = state
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [load, setLoad] = useState(false)
  const [isSuccess, setIsSuccess] = useState<boolean>()

  useEffect(() => {
    setTimeout(() => {
      setLoad(true)
    }, 1000)
  }, [])

  useEffect(() => {
    async function authenticateGuest(abortSignal: AbortSignal, jwt: string) {
      try {
        setIsLoading(true)
        const { account, permissionId } = await dalAuth.authenticateGuest(abortSignal, jwt)
        if (account.user?.hasPin) {
          navigate(`/login/guest/2fa?profileType=${profileType}`)
        } else {
          const permission = await dalPermission.getPermission(abortSignal, permissionId)
          await onUserLogged(abortController.signal, account, profileType, permission)
        }
        setIsSuccess(true)
      } catch (err) {
        setIsSuccess(false)
        Utils.enqueueSnackbarError2(err, t)
      } finally {
        setIsLoading(false)
      }
    }

    const abortController = new AbortController()
    if (token && load) {
      authenticateGuest(abortController.signal, token)
    }

    return () => {
      abortController.abort()
    }
  }, [load])

  return (
    <StyledLoginForm>
      <Stack spacing={1}>
        <PDXLogo
          sx={{
            alignSelf: 'center',
            width: { xs: '70%', sm: '60%' },
            maxWidth: '420px',
            height: 'auto',
          }}
        />
        <Typography textAlign={'center'}>
          {isOperator(profileType) ? t(msgIds.MSG_LOGIN_FOR_GUEST_OPERATORS) : t(msgIds.MSG_LOGIN_FOR_GUEST_CUSTOMERS)}
        </Typography>
        {isSuccess === false ? (
          <Typography textAlign={'center'} sx={{ marginTop: '20px', paddingTop: 3, paddingBottom: 1 }}>
            {t(msgIds.MSG_LOGIN_FOR_GUEST_AUTHORIZATION_CHECK_FAILED)}
          </Typography>
        ) : (
          <Stack spacing={1}>
            <Typography textAlign={'center'} sx={{ marginTop: '20px', paddingTop: 3, paddingBottom: 1 }}>
              {t(msgIds.MSG_LOGIN_FOR_GUEST_AUTHORIZATION_CHECK_IN_PROGRESS)}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', minHeight: '50px', marginTop: 10 }}>
              <CircularProgress color="primary" />
            </Box>
          </Stack>
        )}
      </Stack>
    </StyledLoginForm>
  )
}
