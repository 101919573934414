import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function VerticalGrabberIco(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg fill={props.color ?? 'currentColor'} viewBox="0 0 24 24">
        <path d="m 1.191169,0 a 0.39705656,0.39705656 0 0 0 -0.397134,0.39713401 0.39705656,0.39705656 0 0 0 0.0026,0.039783 h -0.0026 V 5.2439385 A 1.9058713,1.9058713 0 0 0 0,6.7896666 1.9058713,1.9058713 0 0 0 0.794035,8.335396 v 4.76747 0.03978 h 0.0026 A 0.39705656,0.39705656 0 0 0 1.191169,13.5 0.39705656,0.39705656 0 0 0 1.585744,13.142649 h 0.0026 v -0.03978 -0.981086 -0.39411 -3.062143 a 1.9058713,1.9058713 0 0 0 0.317567,0.03001 1.9058713,1.9058713 0 0 0 0.476468,-0.0684 v 4.475727 0.03978 h 0.0023 A 0.39705656,0.39705656 0 0 0 2.779474,13.5 0.39705656,0.39705656 0 0 0 3.1740488,13.142649 h 0.0023 V 13.102866 12.12178 11.72767 8.200691 A 1.9058713,1.9058713 0 0 0 3.8117438,6.7896666 1.9058713,1.9058713 0 0 0 3.1763758,5.3702672 V 3.2377943 2.8602032 0.43691731 h -0.0023 a 0.39705656,0.39705656 0 0 0 0.0023,-0.0397833 A 0.39705656,0.39705656 0 0 0 2.779474,0 0.39705656,0.39705656 0 0 0 2.38234,0.39713401 a 0.39705656,0.39705656 0 0 0 0.0023,0.039783 H 2.38234 V 4.9452152 A 1.9058713,1.9058713 0 0 0 1.905871,4.8837952 1.9058713,1.9058713 0 0 0 1.588303,4.9138072 V 3.2377943 2.8602032 0.43691731 h -0.0026 A 0.39705656,0.39705656 0 0 0 1.588303,0.39713401 0.39705656,0.39705656 0 0 0 1.191169,0 Z" />
      </svg>
    </SvgIcon>
  )
}
