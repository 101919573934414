import { IPermissionJson, Permission } from './Permission'

export interface IAuthorizationActionJson {
  is_allowed: boolean
  is_implicit: boolean
  is_suspended: boolean
  is_expired: boolean
  permissions: IPermissionJson[]
}

export class AuthorizationAction {
  isAllowed: boolean = false
  isImplicit: boolean = false
  isSuspended: boolean = false
  isExpired: boolean = false
  permissions: Permission[] = []

  constructor() {}

  public static Serialize(obj: AuthorizationAction): IAuthorizationActionJson {
    return {
      is_allowed: obj.isAllowed,
      is_implicit: obj.isImplicit,
      is_suspended: obj.isSuspended,
      is_expired: obj.isExpired,
      permissions: Permission.serializeArray(obj.permissions),
    }
  }

  public static Deserialize(json: IAuthorizationActionJson): AuthorizationAction {
    const res = new AuthorizationAction()
    res.isAllowed = json.is_allowed
    res.isImplicit = json.is_implicit
    res.isSuspended = json.is_suspended
    res.isExpired = json.is_expired
    res.permissions = Permission.deserializeArray(json.permissions)
    return res
  }

  public static serializeArray(objs: AuthorizationAction[]): IAuthorizationActionJson[] {
    const jsons = objs.map((p) => {
      return AuthorizationAction.Serialize(p)!
    })
    return jsons
  }

  public static deserializeArray(json: IAuthorizationActionJson[]): AuthorizationAction[] {
    const res = json.map((p) => {
      return AuthorizationAction.Deserialize(p)!
    })
    return res
  }

  public static serializeDictionary(objs: { [key: string]: AuthorizationAction }): {
    [key: string]: IAuthorizationActionJson
  } {
    const jsons = Object.keys(objs).reduce((acc, key) => {
      const json = objs[key]
      if (json) {
        const data = AuthorizationAction.Serialize(json)!
        acc[key] = data
      }
      return acc
    }, {} as { [key: string]: IAuthorizationActionJson })

    return jsons
  }

  public static deserializeDictionary(jsons: { [key: string]: IAuthorizationActionJson }): {
    [key: string]: AuthorizationAction
  } {
    const res = Object.keys(jsons).reduce((acc, key) => {
      const json = jsons[key]
      if (json) {
        const data = AuthorizationAction.Deserialize(json)!
        acc[key] = data
      }
      return acc
    }, {} as { [key: string]: AuthorizationAction })

    return res
  }
}
