import { Theme, useMediaQuery, useTheme } from '@mui/material'

export type Layout = {
  theme: Theme
  isPhone: boolean
  isMobile: boolean
  isTablet: boolean
}

export function useLayout() {
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'))
  return { theme, isPhone, isMobile, isTablet } as Layout
}
