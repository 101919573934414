import { useEffect, useRef, useState } from 'react'
import { IAccountsViewManagementProps } from './AccountsViewManagement.types'
import * as dalPermission from '../../dal/DalPermission'
import { useTranslation } from 'react-i18next'
import { Permission } from '../../models/Permission'
import { ActionType } from '../../shared/Constants'
import { Utils } from '../../shared/Utils'
import { EditorFormContainer } from '../editorFormContainer/EditorFormContainer'
import { SwitchRow } from '../switchRow/SwitchRow'
import msgIds from '../../locales/msgIds'
import { Box, Button, IconButton, List, ListItem, Stack, Typography } from '@mui/material'
import { InputContainer } from '../inputContainer/InputContainer'
import { DeleteIco } from '../icons'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import { Account } from '../../models/Account'
import { ISimpleDialogData } from '../../dialogs/simpleDialog/SimpleDialog.types'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'
import AccountIdentity from '../identities/AccountIdentity'
import { IAccountSelectorDialogData } from '../../dialogs/accountSelectorDialog/AccountSelectorDialog.types'
import AccountSelectorDialog from '../../dialogs/accountSelectorDialog/AccountSelectorDialog'

export function AccountsViewManagement(props: IAccountsViewManagementProps): JSX.Element {
  const { profileId, structureProfileId, isEditMode, isLoading, setIsLoading } = props
  const { t } = useTranslation()
  const [initialized, setInitialized] = useState(false)
  const [visibilityAllPermission, setVisibilityAllPermission] = useState<Permission>()
  const [permissions, setPermissions] = useState<Permission[]>([])
  const [visibilityAll, setVisibilityAll] = useState<boolean>(false)

  // dialogs
  const [simpleDialogData, setSimpleDialogData] = useState<ISimpleDialogData>()
  const [simpleDialogOpen, setSimpleDialogOpen] = useState(false)
  const [accountsSelectorDialogData, setAccountsSelectorDialogData] = useState<IAccountSelectorDialogData>()
  const [accountsSelectorDialogOpen, setAccountsSelectorDialogOpen] = useState(false)

  useEffect(() => {
    if (props.permissions) {
      const res = props.permissions?.find((p) => p.action === ActionType.viewAllCustomers)
      setVisibilityAllPermission(res)
      setVisibilityAll(!!res)
      setInitialized(true)
    }
  }, [props.permissions])

  // ********************
  // infinite scroll
  const [hasMore, setHasMore] = useState(true)
  const pageNum = useRef(0)
  const elementRef = useRef(null)

  useEffect(() => {
    const abortController = new AbortController()
    const observer = new IntersectionObserver(
      (entries) => {
        const firstEntry = entries[0]
        if (firstEntry && firstEntry.isIntersecting && hasMore) {
          loadPermissions(abortController.signal)
        }
      },
      {
        threshold: 0.8,
      }
    )
    if (observer && elementRef.current) {
      observer.observe(elementRef.current)
    }

    return () => {
      observer?.disconnect()
      abortController.abort()
    }
  }, [permissions])

  useEffect(() => {
    const abortController = new AbortController()
    if (initialized && !visibilityAll) {
      loadPermissions(abortController.signal)
    }

    return () => {
      abortController.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId, structureProfileId, initialized, visibilityAll])

  const loadPermissions = async (abortSignal: AbortSignal) => {
    try {
      setIsLoading(true)
      const paginatedPermissions = await dalPermission.getCollaboratorPermissionsByAction(
        abortSignal,
        pageNum.current,
        15,
        structureProfileId,
        profileId,
        ActionType.viewProfiles
      )
      // deduplication
      const uniqueData = paginatedPermissions.rows.filter((p) => !permissions.map((p) => p.id).includes(p.id))
      setPermissions([...permissions, ...uniqueData])

      pageNum.current += 1
      setHasMore(paginatedPermissions.hasMore)
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  async function onChange(key: string, enabled: boolean) {
    try {
      setIsLoading(true)
      const abortController = new AbortController()
      if (enabled) {
        const permission = await dalPermission.createPermission(abortController.signal, {
          profileId: profileId,
          action: ActionType.viewAllCustomers,
          targetId: structureProfileId,
          startAt: new Date(),
        })
        setVisibilityAllPermission(permission)
        setVisibilityAll(true)
      } else {
        if (visibilityAllPermission) {
          await dalPermission.deletePermission(abortController.signal, visibilityAllPermission.id)
          setVisibilityAllPermission(undefined)
          setVisibilityAll(false)
        }
      }
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  function onClickAddUser() {
    setAccountsSelectorDialogOpen(true)
    setAccountsSelectorDialogData({
      searchOptions: 'customers',
      goToAccountPageOption: false,
      showPrivacyPolicy: false,
      onResult: async (result) => {
        setAccountsSelectorDialogOpen(false)
        if (result.userChoice !== 'yes') return
        onAddCustomer(result.account)
      },
    })
  }

  async function onAddCustomer(account: Account) {
    const targetId = account.profile?.profileId
    if (!targetId) {
      return
    }
    const abortController = new AbortController()
    try {
      setIsLoading(true)
      const permission = await dalPermission.createPermission(abortController.signal, {
        profileId: profileId,
        action: ActionType.viewProfiles,
        targetId: targetId,
        startAt: new Date(),
      })
      permission.targetAccount = account
      setPermissions((ps) => {
        return [permission, ...ps]
      })
    } catch (error) {
      Utils.enqueueSnackbarError2(error, t)
    } finally {
      setIsLoading(false)
    }
  }

  const onClickDeleteCustomer = (account: Account) => {
    setSimpleDialogOpen(true)
    setSimpleDialogData({
      title: t(msgIds.MSG_AUTHORIZATIONS),
      content: t(msgIds.MSG_COLLABORATOR_AUTHORIZATION_DELETE_CONFIRM),
      actionsStyle: 'yesNO',
      onClose: async (result) => {
        if (result.userChoice !== 'yes') return
        setSimpleDialogOpen(false)
        onDeleteCustomerConfirm(account)
      },
    })
  }

  async function onDeleteCustomerConfirm(account: Account) {
    const customerProfileId = account.profile?.profileId
    if (!customerProfileId) return

    const permission = permissions.find((p) => p.targetId === customerProfileId)
    if (!permission) {
      return
    }
    try {
      setIsLoading(true)
      const abortController = new AbortController()
      await dalPermission.deletePermission(abortController.signal, permission.id)
      setPermissions((ps) => {
        return ps.filter((p) => p.id !== permission.id)
      })
    } catch (error) {
      Utils.enqueueSnackbarError2(error, t)
    } finally {
      setIsLoading(false)
    }
  }

  function onCancel() {
    props.onCancel && props.onCancel()
  }

  return (
    <EditorFormContainer spacing={1}>
      {simpleDialogData && <SimpleDialog {...simpleDialogData} isOpen={simpleDialogOpen}></SimpleDialog>}
      {accountsSelectorDialogData && (
        <AccountSelectorDialog
          {...accountsSelectorDialogData}
          isOpen={accountsSelectorDialogOpen}
          onClose={() => setAccountsSelectorDialogOpen(false)}
        />
      )}

      <SwitchRow
        disabled={!isEditMode}
        label={t(msgIds.MSG_USERS_AUTH_VISIBILITY_ALL_LABEL)}
        checked={visibilityAll}
        onChange={(_, checked) => onChange('visibilityAll', checked)}
      />
      {initialized && !visibilityAll && (
        <Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography py={1}>{t(msgIds.MSG_USERS_AUTH_VISIBILITY_BY_USERS_LABEL)}</Typography>
            {isEditMode && <Button onClick={onClickAddUser}>{t(msgIds.MSG_DOXES_TEMPLATES_LIST_ADD_BUTTON)}</Button>}
          </Stack>
          <InputContainer>
            <List sx={{ minWidth: '320px' }}>
              {permissions
                .map((p) => p.targetAccount)
                .map(
                  (account) =>
                    account &&
                    account.profile && (
                      <ListItem
                        key={account.profile?.profileId}
                        secondaryAction={
                          isEditMode && (
                            <IconButton onClick={() => onClickDeleteCustomer(account)}>
                              <DeleteIco />
                            </IconButton>
                          )
                        }
                      >
                        <AccountIdentity account={account} infoToShow={['main']} showProfileInfo={true} />
                      </ListItem>
                    )
                )}
            </List>

            {permissions.length > 0 && hasMore && !isLoading && (
              <Box
                ref={elementRef}
                sx={{
                  textAlign: 'center',
                  minHeight: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {t(msgIds.MSG_LOAD_MORE_ELEMENTS)}
              </Box>
            )}
          </InputContainer>
        </Stack>
      )}
      {isEditMode && (
        <ViewActions justifyContent={'center'} sx={{ paddingTop: 6 }}>
          <ViewActionsButton onClick={onCancel}>{t(msgIds.MSG_CLOSE)}</ViewActionsButton>
        </ViewActions>
      )}
    </EditorFormContainer>
  )
}
