import { useCallback, useEffect, useRef, useState } from 'react'

export interface InfiniteLoadingLoaderResult {
  count: number
}

export interface InfiniteLoadingLoaderArgs {
  pageNum: number
  pageSize: number
  signal: AbortSignal
}

export interface InfiniteLoadingArgs {
  pageSize: number
  loader: (args: InfiniteLoadingLoaderArgs) => Promise<InfiniteLoadingLoaderResult>
}

export interface InfiniteLoading {
  loadMore: () => void
  reload: () => void
  currentPage: number
  hasMore: boolean
  isLoading: boolean
}

export function useInfiniteLoading(args: InfiniteLoadingArgs): InfiniteLoading {
  const { pageSize } = args
  const currentPageRef = useRef<number>(-1)
  const [hasMore, setHasMore] = useState<boolean>(true)
  const [currentPage, setCurrentPage] = useState<number>(-1)
  const [loading, setLoading] = useState<number>(0)
  const isLoading = loading > 0
  const [reloadTrigger, setReloadTrigger] = useState<number>(0)

  function loadMore() {
    console.log({ useInfiniteLoading: 'loadMore' })
    if (!hasMore) {
      return
    }
    setCurrentPage(() => currentPageRef.current + 1)
  }

  const reload = useCallback(() => {
    console.log({ useInfiniteLoading: 'reload' })
    currentPageRef.current = -1
    setCurrentPage(() => 0)
    setReloadTrigger((t) => t + 1)
  }, [])

  useEffect(() => {
    const pageNum = currentPage
    if (pageNum < 0) {
      return
    }
    const abortController = new AbortController()
    setLoading((l) => l + 1)
    const loaderArgs: InfiniteLoadingLoaderArgs = { pageNum, pageSize, signal: abortController.signal }
    console.log({ useInfiniteLoading: 'useEffect: calling loader', ...loaderArgs })
    args
      .loader(loaderArgs)
      .then(({ count }) => {
        currentPageRef.current = pageNum
        if (count < pageSize) {
          setHasMore(false)
        } else if (pageNum === 0) {
          setHasMore(true)
        }
      })
      .catch(() => {})
      .finally(() => setLoading((l) => l - 1))

    return () => abortController.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, reloadTrigger])

  return {
    currentPage,
    loadMore,
    reload,
    hasMore,
    isLoading,
  }
}
