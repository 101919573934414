import { Stack, StackProps } from '@mui/material'
import { IPageContainerProps } from './PageContainer.types'
import { useLayout } from '../../hooks/Layout'
import { ForwardedRef, forwardRef } from 'react'

export function PageContainer(props: IPageContainerProps & StackProps): JSX.Element {
  const { ...rest } = props
  return <Stack height="100%" maxHeight="100%" alignItems="stretch" overflow="hidden" {...rest}></Stack>
}

export interface IPageContent {
  disablePadding?: boolean
}

export const PageContent = forwardRef(
  (props: IPageContent & StackProps, ref: ForwardedRef<HTMLDivElement>): JSX.Element => {
    const { disablePadding, children, ...rest } = props
    const { isMobile } = useLayout()
    return (
      <Stack
        ref={ref}
        flex={1}
        overflow="auto"
        alignItems="center"
        py={disablePadding ? 0 : 4}
        px={isMobile || disablePadding ? 0 : 4}
        {...rest}
      >
        {children}
      </Stack>
    )
  }
)
