import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { ITreatmentBaseEditorFormProps } from './TreatmentBaseEditorForm.types'
import { useState } from 'react'
import DataEditorHeader from '../dataEditorHeader/DataEditorHeader'
import { EditIco } from '../icons'
import TreatmentBaseEditor from './TreatmentBaseEditor'
import { DataEditorContainer } from '../dataEditorContainer/DataEditorContainer'

export function TreatmentBaseEditorForm(props: ITreatmentBaseEditorFormProps): JSX.Element {
  const { t } = useTranslation()
  const [isEditMode, setIsEditMode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  return (
    <DataEditorContainer isLoading={isLoading}>
      <DataEditorHeader
        title={t(msgIds.MSG_TREATMENT_BASIC_FEATURES)}
        isEditMode={isEditMode}
        viewModeCommands={[
          {
            commandText: '',
            icon: <EditIco />,
            onClick: () => setIsEditMode(true),
            tooltipText: t(msgIds.MSG_MODIFY) ?? undefined,
            disabled: false,
          },
        ]}
        editModeCommands={[]}
      />
      <TreatmentBaseEditor
        treatment={props.treatment}
        isEditMode={isEditMode}
        disabled={false}
        onSave={() => setIsEditMode(false)}
        onCancel={() => setIsEditMode(false)}
        setIsLoading={setIsLoading}
      />
    </DataEditorContainer>
  )
}
