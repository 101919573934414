import './App.css'
import { Routes, Route } from 'react-router-dom'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useTranslation } from 'react-i18next'
import 'dayjs/locale/it'
import 'dayjs/locale/en'
import DynamicThemeProvider from './components/providers/DynamicThemeProvider'
import { AuthContextProvider } from './contexts/AuthContext'
import { UiContextProvider } from './contexts/UiContext'
import { SnackbarProvider } from 'notistack'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { ArchiveContextProvider } from './contexts/ArchiveContext'
import { DocumentCacheContextProvider } from './contexts/DocumentCacheContext'
import { NotificationsContextProvider } from './contexts/NotificationsContextProvider'
import { AccountEditorContextProvider } from './contexts/AccountEditorContext'
import { WorkingDocumentsContextProvider } from './contexts/WorkingDocumentsContextProvider'
import { SubscriptionContextProvider } from './contexts/SubscriptionContext'
import AccountConfigurationsPage from './pages/AccountConfigurationsPage/AccountConfigurationsPage'
import ArchivePage from './pages/ArchivePage/ArchivePage'
import ContractsPage from './pages/ContractsPage/ContractsPage'
import ContractPage from './pages/ContractPage/ContractPage'
import ContractVersionEditorPage from './pages/ContractVersionEditorPage/ContractVersionEditorPage'
import ContractVersionViewerPage from './pages/ContractVersionViewerPage/ContractVersionViewerPage'
import NotificationsPage from './pages/NotificationsPage/NotificationsPage'
import AccountEditorPage from './pages/AccountEditorPage/AccountEditorPage'
import TreatmentEditorPage from './pages/TreatmentEditorPage/TreatmentEditorPage'
import TreatmentsPage from './pages/TreatmentsPage/TreatmentsPage'
// import AccountPresentation from './pages/AccountPresentationPage/AccountPresentationPage'
import NoticeBoardPage from './pages/NoticeBoardPage/NoticeBoardPage'
import DesktopPage from './pages/DesktopPage/DesktopPage'
import MainTemplate from './components/template/mainTemplate/MainTemplate'
import NotFoundPage from './pages/NotFoundPage/NotFoundPage'
import EventHistoryPage from './pages/EventHistoryPage/EventHistoryPage'
import AccountAuthorizationsPage from './pages/AccountAuthorizationsPage/AccountAuthorizationsPage'
import AccountCardPage from './pages/AccountCardPage/AccountCardPage'
import ProductsPage from './pages/ProductsPage/ProductsPage'
import SubscriptionsPage from './pages/SubscriptionsPage/SubscriptionsPage'
import { Login2FaView } from './components/login2FaView/Login2FaView'
import { Login2FaRecoveryView } from './components/login2FaRecoveryView/Login2FaRecoveryView'
import { LoginTemplate } from './components/template/loginTemplate/LoginTemplate'
import { LoginView } from './components/loginView/LoginView'
import { PasswordRecovery } from './components/passwordRecovery/PasswordRecovery'
import { RegistrationPage } from './pages/RegistrationPage/RegistrationPage'
import { LoginOrRegisterPage } from './pages/LoginOrRegisterPage/LoginOrRegisterPage'
import { TutorialContextProvider } from './contexts/TutorialContextProvider'
import PaymentMethodPage from './pages/PaymentMethodPage/PaymentMethodPage'
import { APP_ENV, STRIPE_PUBLIC_KEY } from './shared/Constants'
import InvoicesPage from './pages/InvoicesPage/InvoicesPage'
import { PasswordReset } from './components/passwordReset/PasswordReset'
import UserActivationPage from './pages/UserActivationPage/UserActivationPage'
import { LoginGuestView } from './components/loginGuestView/LoginGuestView'
import { LoginGuest2FaView } from './components/loginGuest2FaView/LoginGuest2FaView'
import { itIT, enUS } from '@mui/x-date-pickers/locales'
import { useMemo } from 'react'
import { TourStateContextProvider } from './contexts/TourStateContext'
import { TourStepsContextProvider } from './contexts/TourStepsContext'

// Make sure to call `loadStripe` outside of a component’s render to avoid recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)
if (!STRIPE_PUBLIC_KEY) {
  console.error('**Stripe publishable key environment variable not set**')
}

function App() {
  const { i18n } = useTranslation()
  const locale = useMemo(() => {
    switch (i18n.language) {
      case 'en':
        return enUS

      default:
        return itIT
    }
  }, [i18n.language])
  // https://mui.com/x/react-date-pickers/adapters-locale/#custom-formats
  const localeText = locale.components.MuiLocalizationProvider.defaultProps.localeText
  return (
    <AuthContextProvider>
      <UiContextProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language} localeText={localeText}>
          <DynamicThemeProvider>
            <TourStateContextProvider>
              <TourStepsContextProvider>
                <ArchiveContextProvider>
                  <NotificationsContextProvider>
                    <DocumentCacheContextProvider>
                      <WorkingDocumentsContextProvider>
                        <SubscriptionContextProvider>
                          <TutorialContextProvider>
                            <Routes>
                              <Route path="/login" element={<LoginTemplate />}>
                                <Route index element={<LoginView />} />
                                <Route path="recovery" element={<PasswordRecovery />} />
                                <Route path="password_reset/:token" element={<PasswordReset />} />
                                <Route path="2fa" element={<Login2FaView />} />
                                <Route path="2fa/recovery" element={<Login2FaRecoveryView />} />
                                <Route path="guest/:token" element={<LoginGuestView />} />
                                <Route path="guest/2fa" element={<LoginGuest2FaView />} />
                              </Route>

                              {/* Not protected routes */}
                              <Route path="/" element={<MainTemplate protectedRoutes={false} />}>
                                <Route path="/login-or-register" element={<LoginOrRegisterPage />} />
                                <Route path="/registration" element={<RegistrationPage />} />
                                <Route path="/user_activation/:token" element={<UserActivationPage />} />
                                {APP_ENV !== 'demo' && (
                                  <Route path="/billing">
                                    <Route path="products" element={<ProductsPage />} />
                                  </Route>
                                )}
                              </Route>

                              {/* Protected routes */}
                              <Route path="/" element={<MainTemplate protectedRoutes={true} />}>
                                <Route index element={<NoticeBoardPage />} />
                                <Route path="/notifications" element={<NotificationsPage />} />
                                <Route path="/notice_board" element={<NoticeBoardPage />} />
                                <Route path="/desktop" element={<DesktopPage />} />
                                <Route
                                  path="/archive"
                                  element={
                                    <SnackbarProvider>
                                      <ArchivePage />
                                    </SnackbarProvider>
                                  }
                                />
                                <Route
                                  path="/account_editor"
                                  element={
                                    <AccountEditorContextProvider>
                                      <AccountEditorPage />
                                    </AccountEditorContextProvider>
                                  }
                                />
                                {/* <Route path="/account_presentation" element={<AccountPresentation />} /> */}
                                <Route path="/account_card" element={<AccountCardPage />} />
                                <Route path="/account_configurations" element={<AccountConfigurationsPage />} />
                                <Route path="/account_authorizations" element={<AccountAuthorizationsPage />} />

                                <Route path="/contracts">
                                  <Route index element={<ContractsPage />} />
                                  <Route path="versions" element={<ContractPage />} />
                                  <Route path="versions/editor" element={<ContractVersionEditorPage />} />
                                  <Route path="versions/viewer" element={<ContractVersionViewerPage />} />
                                </Route>

                                <Route path="/treatments">
                                  <Route index element={<TreatmentsPage />} />
                                  <Route path="editor" element={<TreatmentEditorPage />} />
                                </Route>

                                <Route path="/history" element={<EventHistoryPage />} />

                                {APP_ENV !== 'demo' && (
                                  <Route path="/billing">
                                    <Route index element={<ProductsPage />} />
                                    <Route path="products" element={<ProductsPage />} />
                                    <Route path="subscriptions" element={<SubscriptionsPage />} />
                                    <Route path="invoices" element={<InvoicesPage />} />
                                    <Route
                                      path="payment_method"
                                      element={
                                        <Elements stripe={stripePromise}>
                                          <PaymentMethodPage />
                                        </Elements>
                                      }
                                    />
                                  </Route>
                                )}
                              </Route>

                              {/* Pagina 404 - rotta catch-all */}
                              <Route path="*" element={<NotFoundPage />} />
                            </Routes>
                          </TutorialContextProvider>
                        </SubscriptionContextProvider>
                      </WorkingDocumentsContextProvider>
                    </DocumentCacheContextProvider>
                  </NotificationsContextProvider>
                </ArchiveContextProvider>
              </TourStepsContextProvider>
            </TourStateContextProvider>
          </DynamicThemeProvider>
        </LocalizationProvider>
      </UiContextProvider>
    </AuthContextProvider>
  )
}

export default App
