import { IPriceJson, Price } from './Price'
import { IProductMetadataJson, ProductMetadata } from './ProductMetadata'
import { ITaxRateJson, TaxRate } from './TaxRate'

export interface IProductJson {
  id: string
  name: string
  dsc: string
  metadata: IProductMetadataJson

  tax_rate?: ITaxRateJson
  prices?: IPriceJson[]
}

export class Product {
  id: string = ''
  name: string = ''
  dsc: string = ''
  metadata: ProductMetadata = new ProductMetadata()

  taxRate?: TaxRate = new TaxRate()
  prices: Price[] = []

  constructor() {}

  public static serialize(obj: Product): IProductJson {
    return {
      id: obj.id,
      name: obj.name,
      dsc: obj.dsc,
      metadata: ProductMetadata.serialize(obj.metadata),

      tax_rate: obj.taxRate ? TaxRate.serialize(obj.taxRate) : undefined,
      prices: obj.prices ? Price.serializeArray(obj.prices) : [],
    }
  }

  public static deserialize(json: IProductJson): Product {
    const res = new Product()
    res.id = json.id
    res.name = json.name
    res.dsc = json.dsc
    res.metadata = ProductMetadata.deserialize(json.metadata)

    res.taxRate = json.tax_rate ? TaxRate.deserialize(json.tax_rate) : undefined
    res.prices = json.prices ? Price.deserializeArray(json.prices) : []
    return res
  }

  public static serializeArray(objs: Product[]): IProductJson[] {
    const jsons = objs.map((p) => {
      return Product.serialize(p)!
    })
    return jsons
  }

  public static deserializeArray(json: IProductJson[]): Product[] {
    const res = json.map((p) => {
      return Product.deserialize(p)!
    })
    return res
  }
}
