import { CircularProgress, Divider, IconButton, Stack } from '@mui/material'
import { IAuthorizationsProps } from './Authorizations.types'
import { AddIco, DeleteIco, InfoIco } from '../icons'
import { Authorization } from '../../models/Authorization'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'
import { Dox } from '../../models/Dox'
import { Document } from '../../models/Document'
import { AuthorizationsList } from '../authorizationsList/AuthorizationsList'
import { useCallback, useEffect, useState } from 'react'
import { useChange } from '../../hooks/Change'
import { useAuthContext } from '../../contexts/AuthContext'
import * as dalPermission from '../../dal/DalPermission'
import { ActionType, isConsumer, isOperator } from '../../shared/Constants'
import { Utils } from '../../shared/Utils'
import log from '../../shared/Logger'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'
import { enqueueSnackbar } from 'notistack'
import { ElementTypeAndDescription } from '../elementTypeAndDescription/ElementTypeAndDescription'
import { ISimpleDialogData } from '../../dialogs/simpleDialog/SimpleDialog.types'

export function Authorizations(props: IAuthorizationsProps): JSX.Element {
  const {
    element,
    newAuthorizeProfileId,
    onClickAddStructure,
    onClickAuthorizationInfo,
    onShowPrivacyPolicy,
    onClickAvatar,
    ...rest
  } = props
  const { t } = useTranslation()
  const authContext = useAuthContext()
  const [authorizations, setAuthorizations] = useState<Authorization[]>([])
  const [reloadTrigger, setReloadTrigger] = useState<number>(0)
  const [deletingAuth, setDeletingAuth] = useState<Authorization | null>(null)
  const [loading, setLoading] = useState(0)

  // dialogs
  const [simpleDialogData, setSimpleDialogData] = useState<ISimpleDialogData>()
  const [simpleDialogOpen, setSimpleDialogOpen] = useState(false)

  useChange(() => {
    setAuthorizations([])
  }, [props.element])

  useEffect(() => {
    const abortController = new AbortController()
    loadAuthorizations(abortController.signal)

    return () => {
      abortController.abort()
    }
  }, [reloadTrigger, props.element, t, authContext.loggedProfileId, authContext.loggedProfileType])

  const loadAuthorizations = useCallback(
    async (abortSignal: AbortSignal) => {
      try {
        setLoading((l) => l + 1)
        const _authorizations =
          element instanceof Dox
            ? await dalPermission.getDoxAuthorizations(abortSignal, element.id, [])
            : await dalPermission.getDocumentAuthorizations(abortSignal, element.documentId, [])

        if (isConsumer(authContext.loggedProfileType)) {
          setAuthorizations(
            _authorizations.filter(
              (p) => p.profileId !== authContext.loggedProfileId && !isOperator(p.dstIdentity?.profile?.type)
            )
          )
        } else if (isOperator(authContext.loggedProfileType)) {
          setAuthorizations(_authorizations.filter((p) => !isOperator(p.dstIdentity?.profile?.type)))
        }
        if (newAuthorizeProfileId) {
          const _authorization = _authorizations.find((p) => p.profileId === newAuthorizeProfileId)
          if (_authorization) {
            checkAuthorizationForProfile(_authorization)
          }
        }
      } catch (err) {
        Utils.enqueueSnackbarError2(err, t)
      } finally {
        setLoading((l) => l - 1)
      }
    },
    [element, authContext.loggedProfileType, authContext.loggedProfileId]
  )

  function checkAuthorizationForProfile(authorization: Authorization) {
    let msg = ''
    if (authorization.isVisualizationExpired) {
      msg = t(msgIds.MSG_AUTHORIZATION_ADDED_DIALOG_CONTENT1)
    } else if (authorization.isVisualizationSuspended) {
      msg = t(msgIds.MSG_AUTHORIZATION_ADDED_DIALOG_CONTENT2)
    } else if (authorization.isVisualizationRevoked) {
      msg = t(msgIds.MSG_AUTHORIZATION_ADDED_DIALOG_CONTENT3, {
        identity: authorization.dstIdentity?.getIdentityInverse(),
      })
    }
    if (msg) {
      setSimpleDialogOpen(true)
      setSimpleDialogData({
        title: t(msgIds.MSG_AUTHORIZATION_ADDED_DIALOG_TITLE),
        content: msg,
        actionsStyle: 'Ok',
        onClose: (result) => {
          setSimpleDialogOpen(false)
        },
      })
    }
  }

  async function reloadAuthorizations() {
    const abortController = new AbortController()
    loadAuthorizations(abortController.signal)
  }

  function canDelete(authorization: Authorization) {
    return authorization.isVisualizationInherited === false
  }

  function onDelete(authorization: Authorization) {
    setDeletingAuth(authorization)
  }

  async function deleteAuth(authorization: Authorization) {
    try {
      const isDox = props.element instanceof Dox
      const action = isDox ? ActionType.viewDox : ActionType.viewDocuments
      const elementId = isDox ? (props.element as Dox).id : (props.element as Document).documentId
      const permission = authorization.actions[Authorization.action_view].permissions.find((p) => {
        // Permission.targetId dichiarato come string, ma in realtà contiente intero.
        // look at Athorization.getPermissionIdForVisualizationOfTarget
        console.log({ targetId: p.targetId, elementId, s: `${elementId}`, action })
        return p.targetId === elementId && p.action === action
      })
      if (!permission) {
        log.error({ message: 'permission not found from authorization', authorization })
        Utils.enqueueSnackbarError(t)(new Error(msgIds.MSG_AN_ERROR_HAS_OCCURRED))
        return
      }

      const abortController = new AbortController()
      await dalPermission.deletePermission(abortController.signal, permission.id)
      removeAuth(authorization)
      enqueueSnackbar(t(msgIds.MSG_AUTHORIZATION_DELETED_MESSAGE), { variant: 'success' })
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    }
  }

  function removeAuth(authorization: Authorization) {
    setAuthorizations((authorizations) => {
      return authorizations.filter((auth) => auth !== authorization)
    })
  }

  function onAuthDetails(authorization: Authorization) {
    onClickAuthorizationInfo(authorization)
  }

  function onAdd() {
    if (onClickAddStructure) {
      onClickAddStructure()
    }
  }

  return (
    <Stack spacing={1} {...rest}>
      {simpleDialogData && <SimpleDialog {...simpleDialogData} isOpen={simpleDialogOpen}></SimpleDialog>}

      <SimpleDialog
        title={t(msgIds.MSG_AUTHORIZATION_DELETE_DIALOG_TITLE)}
        content={t(msgIds.MSG_AUTHORIZATION_DELETE_DIALOG_CONTENT)}
        actionsStyle="yesNO"
        maxWidth="xs"
        isOpen={!!deletingAuth}
        onClose={(result) => {
          if (result.userChoice === 'yes' && deletingAuth) {
            deleteAuth(deletingAuth)
          }
          setDeletingAuth(null)
        }}
      />
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
        <ElementTypeAndDescription element={props.element} />
        <IconButton onClick={onAdd}>
          <AddIco />
        </IconButton>
      </Stack>
      <Divider />
      {loading > 0 && (
        <Stack flexGrow={1} justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      )}
      {loading === 0 && (
        <AuthorizationsList
          authorizations={authorizations}
          reloadAuthorizations={reloadAuthorizations}
          onShowPrivacyPolicy={onShowPrivacyPolicy}
          onClickAvatar={onClickAvatar}
          actionsFor={(authorization) => {
            if (canDelete(authorization)) {
              return [
                <IconButton key={1} edge="end" onClick={() => onDelete(authorization)}>
                  <DeleteIco />
                </IconButton>,
              ]
            } else {
              return [
                <IconButton key={2} edge="end" onClick={() => onAuthDetails(authorization)}>
                  <InfoIco />
                </IconButton>,
              ]
            }
          }}
        />
      )}
    </Stack>
  )
}
