import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function MsgIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 19.999999,4.0000033 H 3.9999999 a 2.005875,2.005875 0 0 0 -2,2 V 18.000003 a 2.005875,2.005875 0 0 0 2,2.000001 H 19.999999 A 2.005875,2.005875 0 0 0 22,18.000003 V 6.0000033 a 2.005875,2.005875 0 0 0 -2.000001,-2 z m 0,13.9999997 H 3.9999999 V 8.0000032 L 12,13.000003 19.999999,8.0000032 Z M 12,10.990003 3.9999999,6.0000033 H 19.999999 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
