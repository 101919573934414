import { useEffect, useRef } from 'react'
import Viewer from '@toast-ui/editor/dist/toastui-editor-viewer'
import { IMarkdownViewerNewProps } from './MarkdownEditorNew.types'

export default function MarkdownViewerNew(props: IMarkdownViewerNewProps) {
  const ref = useRef(null)
  const viewerInstRef = useRef<Viewer | null>(null)

  useEffect(() => {
    if (!viewerInstRef.current) {
      viewerInstRef.current = new Viewer({
        el: ref.current!,
        usageStatistics: false,
        ...props,
      })
    } else {
      viewerInstRef.current.setMarkdown(props.initialValue || '')
    }
  }, [props.initialValue])

  return <div ref={ref} className={props.justify ? 'markdown-viewer justify' : 'markdown-viewer'} />
}
