import { useRef, useState } from 'react'
import _ from 'lodash'
import * as dalDocument from '../dal/DalDocument'
import log from '../shared/Logger'
import {
  WorkingDocumentsContext,
  WorkingDocumentsFilter,
  IWorkingDocumentsContextState,
  IWorkingDocument,
} from './WorkingDocumentsContext'
import { Document } from '../models/Document'
import { IDocumentEditorState } from '../components/documentEditor/DocumentEditor.types'
import {
  WorkingDocumentsContextProviderState,
  IWorkingDocumentsContextProviderProps,
} from './WorkingDocumentsContextProvider.types'
import { ArchiveItemSourceType, isPersonalDoxMimetype } from '../shared/Constants'
import { DocumentRevision } from '../models/DocumentRevision'

export function WorkingDocumentsContextProvider(props: IWorkingDocumentsContextProviderProps): JSX.Element {
  const { children } = props
  const stateRef = useRef<WorkingDocumentsContextProviderState>({
    workingDocuments: [],
    currentDesktopDocumentIID: '',
  })
  const [state, setState] = useState<WorkingDocumentsContextProviderState>(stateRef.current)

  function getDocumentIIDs(filter: WorkingDocumentsFilter = 'desktop_not_saved') {
    const state = stateRef.current
    let _workingDocuments: IWorkingDocument[] = []
    switch (filter) {
      case 'all':
        _workingDocuments = state.workingDocuments.filter(
          (workingDocument) => workingDocument.documentEditorState.canSave === true
        )
        break
      case 'desktop_not_saved':
        _workingDocuments = state.workingDocuments.filter(
          (workingDocument) =>
            workingDocument.isInDesktop === true && workingDocument.documentEditorState.canSave === true
        )
        break
      case 'archive_not_saved':
        _workingDocuments = state.workingDocuments.filter(
          (workingDocument) =>
            workingDocument.isInDesktop === false && workingDocument.documentEditorState.canSave === true
        )
        break
    }
    return _workingDocuments.map((d) => d.documentIID)
  }

  function getWorkingDocument(documentIID: string) {
    const documentDocument = stateRef.current.workingDocuments.find((p) => p.documentIID === documentIID)
    return documentDocument
  }

  function getDocumentEditorState(documentIID: string) {
    const documentDocument = stateRef.current.workingDocuments.find((p) => p.documentIID === documentIID)
    const documentEditorState = documentDocument?.documentEditorState
    return documentEditorState
  }

  function getCurrentDesktopDocumentEditorState() {
    const res = stateRef.current.workingDocuments.find(
      (p) => p.documentIID === stateRef.current.currentDesktopDocumentIID
    )?.documentEditorState
    return res
  }

  function isDocumentNotSaved(documentIID: string) {
    const editorState = getDocumentEditorState(documentIID)
    return editorState && editorState.canSave === true ? true : false
  }

  function putInDesktop(documentIID: string) {
    const state = stateRef.current
    const index = state.workingDocuments.findIndex((p) => p.documentIID === documentIID)
    if (index === -1) {
      return
    }
    const updatedWorkingDocument = { ...state.workingDocuments[index], isInDesktop: true }
    const copy = [...state.workingDocuments]
    copy[index] = updatedWorkingDocument
    stateRef.current = { ...state, workingDocuments: copy }
    setState((s) => stateRef.current)
  }

  function addDocument(document: Document, isInDesktop: boolean, editorState?: Partial<IDocumentEditorState>) {
    const state = stateRef.current
    const workingDocuments = state.workingDocuments
    if (document.documentId) {
      const dd = findDocument(workingDocuments, document.iid)
      if (dd) {
        if (dd?.isInDesktop !== isInDesktop) {
          const _workingDocument = { ...dd, isInDesktop }
          stateRef.current = {
            ...state,
            workingDocuments: [_workingDocument, ...workingDocuments],
            currentDesktopDocumentIID: document.iid,
          }
        }
        setState((s) => stateRef.current)
        return
      }
    }
    const workingDocument: IWorkingDocument = {
      documentIID: document.iid,
      addedAt: new Date(),
      isInDesktop: isInDesktop,
      currentRevisionName: '',
      documentEditorState: {
        isClosing: false,
        isEditing: false,
        isEditable: true,
        canSave: false,
        sectionIndex: 0,
        revisionId: document.lastArchivedRevisionId ?? document.draftRevisionId ?? 0,
        ...editorState,
        documentIID: document.iid,
        document,
        markdownContent: '',
        markdownChecksum: '',
      },
    }
    stateRef.current = {
      ...state,
      workingDocuments: [workingDocument, ...workingDocuments],
      currentDesktopDocumentIID: document.iid,
    }
    setState((s) => stateRef.current)
  }

  function removeDocument(documentIID: string) {
    const state = stateRef.current
    const index = findDocumentIndex(state.workingDocuments, documentIID)
    if (index === -1) {
      return
    }
    const copy = [...state.workingDocuments]
    copy.splice(index, 1)
    let newCurrentDocumentIID = state.currentDesktopDocumentIID
    if (documentIID === state.currentDesktopDocumentIID) {
      newCurrentDocumentIID = copy.length === 0 ? '' : copy[0].documentIID
    }
    stateRef.current = { ...state, workingDocuments: copy, currentDesktopDocumentIID: newCurrentDocumentIID }
    setState((s) => stateRef.current)
  }

  function removeAllDocuments() {
    const state = stateRef.current
    stateRef.current = { ...state, workingDocuments: [], currentDesktopDocumentIID: '' }
    setState((s) => stateRef.current)
  }

  function updateDocumentEditorState(documentIId: string, editorState?: Partial<IDocumentEditorState>) {
    const state = stateRef.current
    const index = findDocumentIndex(state.workingDocuments, documentIId)
    if (index === -1) {
      setState((s) => state)
      return
    }
    const currentDocumentEditorState = state.workingDocuments[index].documentEditorState

    const isIdentical = editorState
      ? Object.entries(editorState).every(([key, value]) => {
          return currentDocumentEditorState[key as keyof IDocumentEditorState] === value
        })
      : true
    if (isIdentical) {
      console.log('No changes detected, skipping update')
      return
    }

    const updatedDocumentEditorState: IDocumentEditorState = { ...currentDocumentEditorState, ...editorState }
    const updatedWorkingDocument = {
      ...state.workingDocuments[index],
      documentEditorState: updatedDocumentEditorState,
    }
    const copy = [...state.workingDocuments]
    copy[index] = updatedWorkingDocument
    stateRef.current = { ...state, workingDocuments: copy }
    setState((s) => stateRef.current)
  }

  function remapDocumentEditorState(oldDocumentIID: string, newDocumentIID: string) {
    const state = stateRef.current
    const index = state.workingDocuments.findIndex((p) => p.documentIID === oldDocumentIID)
    if (index === -1) {
      setState((s) => state)
      return
    }
    const oldWorkingDocument = state.workingDocuments[index]
    const remappedWorkingDocuments = state.workingDocuments.toSpliced(index, 1, {
      ...oldWorkingDocument,
      documentIID: newDocumentIID,
    })
    const currentDesktopDocumentIID =
      state.currentDesktopDocumentIID === oldDocumentIID ? newDocumentIID : oldDocumentIID
    stateRef.current = {
      ...state,
      currentDesktopDocumentIID: currentDesktopDocumentIID,
      workingDocuments: remappedWorkingDocuments,
    }
    setState((s) => stateRef.current)
  }

  async function saveDocument(documentIID: string) {
    const state = stateRef.current
    const desktopDocumentIndex = state.workingDocuments.findIndex((p) => p.documentIID === documentIID)
    if (desktopDocumentIndex === -1) return documentIID

    const _documentEditorState = getDocumentEditorState(documentIID)
    const document = _documentEditorState?.draftDocument
    const revision = _documentEditorState?.draftRevision
    if (!document || !revision) return documentIID

    const isNewDocument = document.documentId === 0
    const abortController = new AbortController()
    let savedDocument: Document
    if (isNewDocument) {
      savedDocument =
        document.sourceType === ArchiveItemSourceType.internalSource
          ? await dalDocument.createInternalDocument(abortController.signal, document, revision)
          : await dalDocument.createExternalDocument(abortController.signal, document, revision, (progress) => {
              log.debug({ downloadDocumentRevision: 'download update progress', progress })
              //args.state.patchUpload(uploadId, { progress })
            })
    } else {
      savedDocument =
        document.sourceType === ArchiveItemSourceType.internalSource
          ? await dalDocument.updateInternalDocument(abortController.signal, document, revision)
          : await dalDocument.updateExternalDocument(abortController.signal, document, revision, (progress) => {
              log.debug({ downloadDocumentRevision: 'download update progress', progress })
              //args.state.patchUpload(uploadId, { progress })
            })
    }

    savedDocument.doxIds = isNewDocument ? savedDocument.doxIds : document.doxIds
    const savedRevision = savedDocument.revisions.find((p) => !p.archivedAt)
    if (!savedRevision) {
      throw new Error('missing saved draft revision')
    }
    savedRevision.content = revision.content
    savedDocument.revisions.forEach((savedRev) => {
      const oldRev = document.revisions.find((r) => r.revisionId === savedRev.revisionId)
      if (oldRev) {
        savedRev.content = oldRev.content
      }
    })

    updateDocumentEditorState(documentIID, {
      document: savedDocument,
      draftDocument: Document.clone(savedDocument),
      draftRevision: DocumentRevision.clone(savedRevision),
      revisionId: savedRevision.revisionId,
      markdownContent: isPersonalDoxMimetype(revision.mimetype) === true ? (savedRevision.content as string) : '',
      markdownChecksum: savedRevision.checksum,
      canSave: false,
    })

    if (isNewDocument) {
      remapDocumentEditorState(documentIID, savedDocument.iid)
      return savedDocument.iid
    }
    return documentIID
  }

  async function archiveDocument(documentIID: string) {
    const state = stateRef.current
    const desktopDocumentIndex = state.workingDocuments.findIndex((p) => p.documentIID === documentIID)
    if (desktopDocumentIndex === -1) return

    const _documentEditorState = getDocumentEditorState(documentIID)
    const document = _documentEditorState?.document
    if (!document) return
    const draftRevision = document.revisions.find((p) => p.revisionId === document.draftRevisionId)
    if (!draftRevision) return

    const abortController = new AbortController()
    const savedDocument = await dalDocument.archiveDocument(abortController.signal, document.documentId)

    savedDocument.doxIds = document.doxIds
    const savedRevision = savedDocument.revisions.find((p) => p.revisionId === draftRevision.revisionId)
    if (!savedRevision) {
      throw new Error('missing saved draft revision')
    }
    savedRevision.content = draftRevision.content
    savedDocument.revisions.forEach((savedRev) => {
      const oldRev = document.revisions.find((r) => r.revisionId === savedRev.revisionId)
      if (oldRev) {
        savedRev.content = oldRev.content
      }
    })

    updateDocumentEditorState(documentIID, {
      document: savedDocument,
      draftDocument: Document.clone(savedDocument),
      draftRevision: DocumentRevision.clone(savedRevision),
      revisionId: savedRevision.revisionId,
      isEditing: false,
      markdownContent: isPersonalDoxMimetype(savedRevision.mimetype) === true ? (savedRevision.content as string) : '',
      markdownChecksum: savedRevision.checksum,
    })
  }

  function setCurrentDesktopDocument(documentIID: string) {
    const state = stateRef.current
    const index = state.workingDocuments.findIndex((p) => p.documentIID === documentIID)
    if (index === -1) {
      return
    }
    stateRef.current = { ...state, currentDesktopDocumentIID: documentIID }
    setState((s) => stateRef.current)
  }

  const value: IWorkingDocumentsContextState = {
    getDocumentIIDs,
    getWorkingDocument,
    getDocumentEditorState,
    getCurrentDesktopDocumentEditorState,
    isDocumentNotSaved,
    workingDocuments: stateRef.current.workingDocuments,
    currentDesktopDocumentIID: stateRef.current.currentDesktopDocumentIID,

    putInDesktop,

    addDocument,
    removeDocument,
    removeAllDocuments,
    updateDocumentEditorState,
    remapDocumentEditorState,
    saveDocument,
    archiveDocument,

    setCurrentDesktopDocument,
  }

  return <WorkingDocumentsContext.Provider value={value}>{children}</WorkingDocumentsContext.Provider>
}

export function findDocument(workingDocuments: IWorkingDocument[], documentIID: string): IWorkingDocument | undefined {
  return workingDocuments.find((p) => p.documentIID === documentIID)
}

export function findDocumentIndex(workingDocuments: IWorkingDocument[], documentIID: string): number {
  return workingDocuments.findIndex((p) => p.documentIID === documentIID)
}
