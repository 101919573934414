import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function OperatorIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 11.509277,4.025389 A 3.9969249,3.9969249 0 0 0 7.9936524,7.9936507 3.9958149,3.9958149 0 0 0 11.990478,11.990477 3.9969249,3.9969249 0 1 0 11.509277,4.025389 Z m 0.481201,1.9709472 A 1.99846,1.99846 0 1 1 9.9931641,7.9936507 2.0043349,2.0043349 0 0 1 11.990478,5.9963362 Z m 0,6.9938968 L 13.5,15.00073 11.999268,19.973143 10.500732,15.00073 Z m 0,0 c -2.6679465,0 -7.9936518,1.338874 -7.9936518,3.996826 v 2.99707 H 19.984131 v -2.99707 c 0,-2.657926 -5.32571,-3.996826 -7.993653,-3.996826 z m -5.9897456,3.999023 v 0.997559 h -0.00439 v -0.990968 c 5.548e-4,-0.002 0.00379,-0.0046 0.00439,-0.0066 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
