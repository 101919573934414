import { useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'
import * as dalHistory from '../../dal/DalHistory'
import * as dalAccount from '../../dal/DalAccount'
import * as dalTreatment from '../../dal/DalTreatment'
import * as dalDocument from '../../dal/DalDocument'
import * as dalDox from '../../dal/DalDox'
import { GetHistoryArgs } from '../../dal/DalHistory'
import { PageContainer, PageContent } from '../../components/pageContainer/PageContainer'
import { History } from '../../models/History'
import msgIds from '../../locales/msgIds'
import { Utils, dateShortOptions } from '../../shared/Utils'
import { InfiniteLoadingLoaderArgs, useInfiniteLoading } from '../../hooks/InfiniteLoading'
import { useObserver } from '../../hooks/Observer'
import { Box, CircularProgress, IconButton, Paper, Stack, TableContainer } from '@mui/material'
import { LoadMore } from '../../components/loadMore/LoadMore'
import CommandBar from '../../components/commandBar/CommandBar'
import { EventHistoryTable } from '../../components/eventHistoryTable/EventHistoryTable'
import { AutorenewIco } from '../../components/icons'
import { FiltersBar, FiltersBarFilter } from '../../components/filtersBar/FiltersBar'
import { StyledDialog } from '../../dialogs/styledDialog/StyledDialog'
import { EventHistoryFiltersForm } from '../../components/eventHistoryFiltersForm/EventHistoryFiltersForm'
import { IEventHistoryFilters } from '../../components/eventHistoryFiltersForm/EventHistoryFiltersForm.types'
import { Center } from '../../components/center/Center'
import { ElementTypeAndDescription } from '../../components/elementTypeAndDescription/ElementTypeAndDescription'
import { Document } from '../../models/Document'
import { Dox } from '../../models/Dox'
import { IEventHistoryPageInit } from './EventHistoryPage.types'
import { useAuthContext } from '../../contexts/AuthContext'
import { PaginatedResponse } from '../../shared/types/PaginatedResponse'
import { isStructure } from '../../shared/Constants'
import { Treatment } from '../../models/Treatment'
import { Account } from '../../models/Account'

type BarFilter = FiltersBarFilter & {
  key: string
}

export default function EventHistoryPage() {
  const { t, i18n } = useTranslation()
  const { state } = useLocation()
  const [history, setHistory] = useState<History[]>([])
  const authContext = useAuthContext()
  const [openFiltersDialog, setOpenFiltersDialog] = useState(false)
  const [filters, setFilters] = useState<IEventHistoryFilters>({})
  const [treatments, setTreatments] = useState<Treatment[]>()

  const historyType = state.historyType
  const targetProfileId = state.targetProfileId
  const doxId: number | undefined = state.doxId
  const documentId: number | undefined = state.documentId
  const [element, setElement] = useState<Dox | Document | Account | undefined>()

  useEffect(() => {
    const abortController = new AbortController()
    if (doxId) {
      const loadDox = async () => {
        try {
          const dox = await dalDox.getDoxDetails(abortController.signal, doxId)
          setElement(dox)
        } catch (error) {
          Utils.enqueueSnackbarError2(error, t)
        }
      }
      loadDox()
    } else if (documentId) {
      const loadDocument = async () => {
        try {
          const doc = await dalDocument.getDocument(abortController.signal, documentId)
          setElement(doc)
        } catch (error) {
          Utils.enqueueSnackbarError2(error, t)
        }
      }
      loadDocument()
    } else if (targetProfileId) {
      const loadAccount = async () => {
        try {
          const doc = await dalAccount.getAccountFromProfileId(abortController.signal, targetProfileId, false, false)
          setElement(doc)
        } catch (error) {
          Utils.enqueueSnackbarError2(error, t)
        }
      }
      loadAccount()
    }
    return () => abortController.abort()
  }, [doxId, documentId, targetProfileId])

  const LoadHistory = useCallback(
    async (args: InfiniteLoadingLoaderArgs) => {
      const { pageNum, pageSize, signal } = args
      const query: GetHistoryArgs = {
        pageNum,
        pageSize,
        fromDate: filters.fromDate,
        toDate: filters.toDate,
      }
      try {
        let page = new PaginatedResponse<History>()
        if (historyType === 'mop' && authContext.linkedStructureProfileId) {
          if (filters.treatment) {
            query.subjects = `treatments:${filters.treatment?.id}`
          } else {
            if (treatments) {
              query.subjects = treatments.map((p) => `treatments:${p.id}`).join(',')
            } else {
              const _treatments = await dalTreatment.getTreatments(
                signal,
                authContext.linkedStructureProfileId,
                false,
                false
              )
              setTreatments(_treatments)
              query.subjects = _treatments.map((p) => `treatments:${p.id}`).join(',')
            }
          }
          query.authorProfileIds = [authContext.linkedStructureProfileId]
          query.authorLinkedProfileIds = [authContext.linkedStructureProfileId]
        } else if (historyType === 'account' && state.targetProfileId) {
          const account = await dalAccount.getAccountFromProfileId(signal, state.targetProfileId, true, false)
          if (account.profile?.profileId) {
            query.authorProfileIds = [account.profile.profileId]
            if (isStructure(account.profile?.type)) {
              query.authorLinkedProfileIds = [account.profile.profileId]
            }
          }
        } else if (historyType === 'document' && state.documentId) {
          query.subjects = `documents:${state.documentId}`
          if (filters.authorAccount?.profile?.profileId) {
            query.authorProfileIds = [filters.authorAccount?.profile?.profileId]
          } else if (filters.authorLinkedAccount?.profile?.profileId) {
            query.authorProfileIds = [filters.authorLinkedAccount?.profile?.profileId]
            query.authorLinkedProfileIds = [filters.authorLinkedAccount?.profile?.profileId]
          }
        } else if (historyType === 'dox' && state.doxId) {
          query.subjects = `dox:${state.doxId}`
          if (filters.authorAccount?.profile?.profileId) {
            query.authorProfileIds = [filters.authorAccount?.profile?.profileId]
          } else if (filters.authorLinkedAccount?.profile?.profileId) {
            query.authorProfileIds = [filters.authorLinkedAccount?.profile?.profileId]
            query.authorLinkedProfileIds = [filters.authorLinkedAccount?.profile?.profileId]
          }
        }

        page = await dalHistory.getHistory(signal, historyType, query)

        if (query.pageNum <= 0) {
          setHistory(() => page.rows)
        } else {
          setHistory((history) => history.concat(page.rows))
        }
        return { count: page.rows.length }
      } catch (err) {
        Utils.enqueueSnackbarError2(err, t)
        throw err
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [historyType, filters]
  )

  const { loadMore, hasMore, reload, isLoading, currentPage } = useInfiniteLoading({
    pageSize: 50,
    loader: LoadHistory,
  })
  const loadMoreRef = useObserver<HTMLDivElement>(loadMore)

  useEffect(() => {
    reload()
  }, [filters, historyType, reload])

  const filtersBar = useMemo(() => {
    const filtersList: BarFilter[] = []
    if (filters.fromDate) {
      filtersList.push({
        key: 'fromDate',
        keyLabel: t(msgIds.MSG_EVENT_HISTORY_FILTER_FROM_KEY),
        valueLabel: Utils.toLocaleDateString(filters.fromDate, i18n, dateShortOptions) || filters.fromDate.toString(),
      })
    }
    if (filters.toDate) {
      filtersList.push({
        key: 'toDate',
        keyLabel: t(msgIds.MSG_EVENT_HISTORY_FILTER_TO_KEY),
        valueLabel: Utils.toLocaleDateString(filters.toDate, i18n, dateShortOptions) || filters.toDate.toString(),
      })
    }
    if (filters.authorAccount) {
      filtersList.push({
        key: 'authorAccount',
        keyLabel: t(msgIds.MSG_EVENT_HISTORY_FILTER_OPERATOR_KEY),
        valueLabel: filters.authorAccount.getIdentity(),
      })
    }
    if (filters.authorLinkedAccount) {
      filtersList.push({
        key: 'authorLinkedAccount',
        keyLabel: t(msgIds.MSG_EVENT_HISTORY_FILTER_STRUCTURE_KEY),
        valueLabel: filters.authorLinkedAccount.getIdentity(),
      })
    }
    if (filters.treatment) {
      filtersList.push({
        key: 'treatment',
        keyLabel: t(msgIds.MSG_EVENT_HISTORY_FILTER_TREATMENT),
        valueLabel: `${filters.treatment.code} - ${filters.treatment.shortDescription}`,
      })
    }
    return filtersList
  }, [filters, t, i18n])

  function closeFiltersDialog() {
    setOpenFiltersDialog(false)
  }

  function onChangeFilters(filters: IEventHistoryFilters) {
    setFilters(filters)
    closeFiltersDialog()
  }

  function onDeleteFilter(filter: BarFilter) {
    setFilters((filters) => {
      const copy = { ...filters }
      delete (copy as any)[filter.key]
      return copy
    })
  }

  return (
    <PageContainer>
      <CommandBar style={{ minHeight: 'auto' }} title={t(msgIds.MSG_EVENT_HISTORY_PAGE_TITLE)} commands={[]} />
      <PageContent alignItems="stretch">
        <Paper sx={{ display: 'flex', flexDirection: 'column', overflow: 'auto', gap: 1, padding: 1 }}>
          {element && (
            <Box>
              <ElementTypeAndDescription element={element} />
            </Box>
          )}
          <Stack direction="row">
            <IconButton onClick={reload} size="small">
              <AutorenewIco />
            </IconButton>
            <FiltersBar
              filters={filtersBar}
              onClickFilters={() => setOpenFiltersDialog(true)}
              onClickDeleteFilter={onDeleteFilter}
            />
          </Stack>
          <TableContainer>
            {isLoading && currentPage === 0 ? (
              <Center>
                <CircularProgress />
              </Center>
            ) : (
              <EventHistoryTable history={history} />
            )}
            <LoadMore noMoreToLoad={!hasMore} ref={loadMoreRef} />
          </TableContainer>
        </Paper>
      </PageContent>
      <StyledDialog minHeight="auto" open={openFiltersDialog} onClose={closeFiltersDialog}>
        <EventHistoryFiltersForm
          sx={{ flex: 1 }}
          historyType={historyType}
          filters={filters}
          onChangeFilters={onChangeFilters}
          onCancel={closeFiltersDialog}
        />
      </StyledDialog>
    </PageContainer>
  )
}
