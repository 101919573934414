import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function UndoIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 12.5,7.9999998 c -2.6500002,0 -5.0500003,0.99 -6.9000002,2.6000002 L 1.9999999,6.9999999 V 15.999999 H 11 L 7.3799998,12.38 C 8.7699998,11.22 10.54,10.5 12.5,10.5 c 3.539999,0 6.55,2.31 7.6,5.499999 l 2.37,-0.78 C 21.079999,11.03 17.149999,7.9999998 12.5,7.9999998 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
