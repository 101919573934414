import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function DraftIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 14.888672,2.0698225 a 0.88739998,0.88739998 0 0 0 -0.525147,0.2504883 l -1.621582,1.6215821 3.322266,3.3200683 1.621581,-1.6193847 a 0.88207498,0.88207498 0 0 0 0,-1.2502441 L 15.611572,2.3203108 a 0.88739998,0.88739998 0 0 0 -0.7229,-0.2504883 z m -3.09375,2.8190918 -9.7954103,9.7954107 v 3.322266 h 3.3200683 0.0022 6.712647 c 0.25412,3.030301 1.943963,3.873778 2.979492,3.873778 a 2.00608,2.00608 0 0 0 2.039062,-1.962157 c 0,-1.08 -1.017578,-3.005765 -3.418945,-3.412355 0.06988,-0.495498 0.08288,-1.135986 1.118408,-1.135986 0.794096,0 1.824063,1.237517 2.592774,2.184083 0.705168,0.870358 1.262633,1.563081 1.942382,1.76001 a 1.903465,1.903465 0 0 0 1.678711,-0.292238 2.2789999,2.2789999 0 0 0 0.850343,-1.891845 c 0,-1.537383 -1.658324,-3.743195 -1.817139,-4.01001 -0.19059,-0.311324 -0.508821,-0.837559 -0.191163,-0.964599 0.19693,-0.08259 0.642946,0.322383 1.087647,0.773437 l 1.111816,-1.085449 c -0.13977,-0.17154 -0.331603,-0.36237 -0.560302,-0.591064 a 2.770545,2.770545 0 0 0 -1.727051,-0.793213 1.67404,1.67404 0 0 0 -1.595215,1.817138 c 0,0.864 0.330663,1.358769 0.826171,2.083008 a 11.5914,11.5914 0 0 1 1.175538,2.236817 c 0.228729,0.692443 0.05738,1.219482 -0.228516,1.219482 -0.27316,0 -0.546478,-0.343352 -0.705322,-0.533936 -0.146085,-0.152466 -1.086156,-1.263306 -1.454589,-1.72705 -0.48282,-0.59082 -1.708317,-1.806153 -3.137696,-1.806153 -1.867738,0 -2.466702,1.621459 -2.562012,2.669679 H 6.9104004 L 15.117187,8.21118 Z m 0,2.5070802 0.815185,0.8151855 -8.0244137,8.024413 H 3.7705078 v -0.815185 z m 1.845703,10.7292475 c 1.359493,0.355745 1.82373,1.372218 1.82373,1.753418 a 0.47703999,0.47703999 0 0 1 -0.470214,0.459229 c -0.444705,0 -1.162936,-0.503754 -1.353516,-2.212647 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
