import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function AddDocInternalTemplateIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 18.224121 1.472168 L 18.224121 4.3066406 L 15.391113 4.3066406 L 15.391113 5.7744141 L 18.224121 5.7744141 L 18.224121 8.6088867 L 19.693359 8.6088867 L 19.693359 5.7744141 L 22.529297 5.7744141 L 22.529297 4.3066406 L 19.693359 4.3066406 L 19.693359 1.472168 L 18.224121 1.472168 z M 6.5449219 5.6704102 L 1.5029297 10.713867 L 1.5029297 20.799316 C 1.5060397 21.727377 2.2579712 22.478967 3.1860352 22.479492 L 9.5053711 22.479492 L 9.5053711 20.625 L 3.3588867 20.625 L 3.3588867 11.554688 L 7.387207 11.554688 L 7.387207 7.4985352 L 12.726563 7.4985352 L 12.726563 9.3837891 L 14.585449 9.3837891 L 14.585449 5.6704102 L 6.5449219 5.6704102 z M 17.900391 13.000488 L 17.900391 15 L 19.899902 15 L 19.899902 13.000488 L 17.900391 13.000488 z M 10.372559 14.8125 L 10.372559 14.813965 L 10.372559 16.083984 L 10.372559 17.356934 L 11.646973 17.356934 L 11.646973 16.083984 L 12.915527 16.083984 L 12.915527 21.171387 L 11.642578 21.171387 L 11.642578 22.45166 L 15.457031 22.45166 L 15.457031 21.171387 L 14.189941 21.171387 L 14.189941 16.083984 L 15.446777 16.083984 L 15.446777 17.345215 L 16.713867 17.345215 L 16.713867 16.083984 L 16.713867 15.297363 L 16.713867 14.813965 L 14.189941 14.813965 L 12.915527 14.813965 L 11.646973 14.813965 L 11.646973 14.8125 L 10.372559 14.8125 z M 17.900391 16.744629 L 17.900391 18.745605 L 19.899902 18.745605 L 19.899902 16.744629 L 17.900391 16.744629 z M 17.900391 20.490234 L 17.900391 22.489746 L 19.899902 22.489746 L 19.899902 20.490234 L 17.900391 20.490234 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
