import {
  DocViewedIco,
  DossierIco,
  DownloadIco,
  DoxIco,
  InvitationIco,
  MsgIco,
  NotificationAuthorizationIco,
  NotificationSystemIco,
  OperatorIco,
  PrivacyIco,
  RetentionIco,
  TermsConditionsIco,
} from '../icons'
import NoteIco from '../icons/NoteIco'
import { ActionType, NotificationArgumentType, NotificationsType } from '../../shared/Constants'
import msgIds from '../../locales/msgIds'
import { Utils, dateShortOptions } from '../../shared/Utils'
import { Notification } from '../../models/Notification'
import { i18n } from 'i18next'
import { pdxAccount } from '../../models/Account'

export function icoFromType(notificationType: NotificationsType) {
  switch (notificationType) {
    case NotificationsType.userPrivateMessage:
      return MsgIco
    case NotificationsType.upcomingProfileSuspension:
      return NotificationSystemIco
    case NotificationsType.profileSuspension:
      return NotificationSystemIco
    case NotificationsType.upcomingProfileDeletion:
      return NotificationSystemIco
    case NotificationsType.profileDeletionBySystem:
      return NotificationSystemIco
    case NotificationsType.profileDeletionByOwner:
      return NotificationSystemIco
    case NotificationsType.profileDeletionByOperatorAdmin:
      return NotificationSystemIco
    case NotificationsType.upcomingUserDeletion:
      return NotificationSystemIco
    case NotificationsType.userDeletionBySystem:
      return NotificationSystemIco
    case NotificationsType.userDeletionByOwner:
      return NotificationSystemIco
    case NotificationsType.contractUpdated:
      return TermsConditionsIco
    case NotificationsType.dossierDelivered:
      return DossierIco
    case NotificationsType.documentRevisionCreated:
      return NoteIco
    case NotificationsType.documentRevisionViewed:
      return DocViewedIco
    case NotificationsType.documentRevisionDownloaded:
      return DownloadIco
    case NotificationsType.dataProcessingGranted:
      return NotificationAuthorizationIco
    case NotificationsType.dataProcessingUpdated:
      return NotificationAuthorizationIco
    case NotificationsType.dataProcessingSuspended:
      return NotificationAuthorizationIco
    case NotificationsType.dataProcessingRestored:
      return NotificationAuthorizationIco
    case NotificationsType.upcomingDataProcessingExpiration:
      return NotificationAuthorizationIco
    case NotificationsType.dataProcessingExpired:
      return NotificationAuthorizationIco
    case NotificationsType.privacyPolicyUpdated:
      return PrivacyIco
    case NotificationsType.privacyPolicyConsentUpdated:
      return PrivacyIco
    case NotificationsType.profileAttached:
      return NotificationSystemIco
    case NotificationsType.profileDeattached:
      return NotificationSystemIco
    case NotificationsType.upcomingSubscriptionTrialExpiration:
      return NotificationSystemIco
    case NotificationsType.upcomingDocumentRetentionExpiration:
      return NoteIco
    case NotificationsType.documentRetentionExpired:
      return RetentionIco
    case NotificationsType.upcomingDoxRetentionExpiration:
      return DoxIco
    case NotificationsType.doxRetentionExpired:
      return RetentionIco
    case NotificationsType.doxDelivered:
      return DoxIco
    case NotificationsType.doxReceived:
      return DoxIco
    case NotificationsType.invitationPermitted:
      return InvitationIco
    case NotificationsType.invitationReceived:
      return InvitationIco
    case NotificationsType.invitationAccepted:
      return OperatorIco
    default:
  }
}

export function accountFromType(notification: Notification) {
  switch (notification.type) {
    case NotificationsType.userPrivateMessage:
      return notification.context?.author
    case NotificationsType.upcomingProfileSuspension:
      return pdxAccount
    case NotificationsType.profileSuspension:
      return pdxAccount
    case NotificationsType.upcomingProfileDeletion:
      return pdxAccount
    case NotificationsType.profileDeletionBySystem:
      return pdxAccount
    case NotificationsType.profileDeletionByOwner:
      return pdxAccount
    case NotificationsType.profileDeletionByOperatorAdmin:
      return pdxAccount
    case NotificationsType.upcomingUserDeletion:
      return pdxAccount
    case NotificationsType.userDeletionBySystem:
      return pdxAccount
    case NotificationsType.userDeletionByOwner:
      return pdxAccount
    case NotificationsType.contractUpdated:
      return notification.context?.author
    case NotificationsType.dossierDelivered:
      return notification.context?.author
    case NotificationsType.documentRevisionCreated:
      return notification.context?.author
    case NotificationsType.documentRevisionViewed:
      return notification.context?.author
    case NotificationsType.documentRevisionDownloaded:
      return notification.context?.author
    case NotificationsType.dataProcessingGranted:
      return notification.context?.author
    case NotificationsType.dataProcessingUpdated:
      return notification.context?.author
    case NotificationsType.dataProcessingSuspended:
      return notification.context?.author
    case NotificationsType.dataProcessingRestored:
      return notification.context?.author
    case NotificationsType.upcomingDataProcessingExpiration:
      return notification.context?.author
    case NotificationsType.dataProcessingExpired:
      return notification.context?.author
    case NotificationsType.privacyPolicyUpdated:
      return notification.context?.author
    case NotificationsType.privacyPolicyConsentUpdated:
      return notification.context?.author
    case NotificationsType.profileAttached:
      return notification.context?.author
    case NotificationsType.profileDeattached:
      return notification.context?.author
    case NotificationsType.upcomingSubscriptionTrialExpiration:
      return pdxAccount
    case NotificationsType.upcomingDocumentRetentionExpiration:
      return notification.context?.author
    case NotificationsType.documentRetentionExpired:
      return pdxAccount
    case NotificationsType.upcomingDoxRetentionExpiration:
      return notification.context?.author
    case NotificationsType.doxRetentionExpired:
      return pdxAccount
    case NotificationsType.doxDelivered:
      return notification.context?.author
    case NotificationsType.doxReceived:
      return notification.context?.author
    case NotificationsType.invitationPermitted:
      return notification.context?.author
    case NotificationsType.invitationReceived:
      return notification.context?.author
    case NotificationsType.invitationAccepted:
      return notification.context?.author
    default:
  }
}

export function tooltipFromType(notificationType: NotificationsType, t: any) {
  switch (notificationType) {
    case NotificationsType.userPrivateMessage:
      return undefined
    case NotificationsType.upcomingProfileSuspension:
      return undefined
    case NotificationsType.profileSuspension:
      return undefined
    case NotificationsType.upcomingProfileDeletion:
      return undefined
    case NotificationsType.profileDeletionBySystem:
      return undefined
    case NotificationsType.profileDeletionByOwner:
      return undefined
    case NotificationsType.profileDeletionByOperatorAdmin:
      return undefined
    case NotificationsType.upcomingUserDeletion:
      return undefined
    case NotificationsType.userDeletionBySystem:
      return undefined
    case NotificationsType.userDeletionByOwner:
      return undefined
    case NotificationsType.contractUpdated:
      return undefined
    case NotificationsType.dossierDelivered:
      return t(msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_DOSSIER)
    case NotificationsType.documentRevisionCreated:
      return t(msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_DOCUMENT)
    case NotificationsType.documentRevisionViewed:
      return t(msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_DOCUMENT)
    case NotificationsType.documentRevisionDownloaded:
      return t(msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_DOCUMENT)
    case NotificationsType.dataProcessingGranted:
      return t(msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_DATA_PROCESSING_SETTINGS)
    case NotificationsType.dataProcessingUpdated:
      return t(msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_DATA_PROCESSING_SETTINGS)
    case NotificationsType.dataProcessingSuspended:
      return t(msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_DATA_PROCESSING_SETTINGS)
    case NotificationsType.dataProcessingRestored:
      return t(msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_DATA_PROCESSING_SETTINGS)
    case NotificationsType.upcomingDataProcessingExpiration:
      return t(msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_DATA_PROCESSING_SETTINGS)
    case NotificationsType.dataProcessingExpired:
      return t(msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_DATA_PROCESSING_SETTINGS)
    case NotificationsType.privacyPolicyUpdated:
      return t(msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_CONTRACT)
    case NotificationsType.privacyPolicyConsentUpdated:
      return t(msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_CONSENTS)
    case NotificationsType.profileAttached:
      return undefined
    case NotificationsType.profileDeattached:
      return undefined
    case NotificationsType.upcomingSubscriptionTrialExpiration:
      return undefined
    case NotificationsType.upcomingDocumentRetentionExpiration:
      return t(msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_DOCUMENT)
    case NotificationsType.documentRetentionExpired:
      return undefined
    case NotificationsType.upcomingDoxRetentionExpiration:
      return t(msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_DOX)
    case NotificationsType.doxRetentionExpired:
      return undefined
    case NotificationsType.doxDelivered:
      return t(msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_DOX)
    case NotificationsType.doxReceived:
      return t(msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_DOX)
    case NotificationsType.invitationPermitted:
      return t(msgIds.MSG_NOTIFICATION_TOOLTIP_SEND_INVITATION)
    case NotificationsType.invitationReceived:
      return t(msgIds.MSG_NOTIFICATION_TOOLTIP_ACCEPT_INVITATION)
    case NotificationsType.invitationAccepted:
      return t(msgIds.MSG_NOTIFICATION_TOOLTIP_SHOW_COLLABORATOR_CARD)
    default:
  }
}

export function argumentFromType(notificationType: NotificationsType, t: any) {
  switch (notificationType) {
    case NotificationsType.userPrivateMessage:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_MESSAGE)
    case NotificationsType.upcomingProfileSuspension:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_PROFILE_MANAGEMENT)
    case NotificationsType.profileSuspension:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_PROFILE_MANAGEMENT)
    case NotificationsType.upcomingProfileDeletion:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_PROFILE_MANAGEMENT)
    case NotificationsType.profileDeletionBySystem:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_PROFILE_MANAGEMENT)
    case NotificationsType.profileDeletionByOwner:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_PROFILE_MANAGEMENT)
    case NotificationsType.profileDeletionByOperatorAdmin:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_PROFILE_MANAGEMENT)
    case NotificationsType.upcomingUserDeletion:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_ACCOUNT_MANAGEMENT)
    case NotificationsType.userDeletionBySystem:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_ACCOUNT_MANAGEMENT)
    case NotificationsType.userDeletionByOwner:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_ACCOUNT_MANAGEMENT)
    case NotificationsType.contractUpdated:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_PDX_CONTRACTS)
    case NotificationsType.dossierDelivered:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_DOSSIER)
    case NotificationsType.documentRevisionCreated:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_DOCUMENT)
    case NotificationsType.documentRevisionViewed:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_DOCUMENT)
    case NotificationsType.documentRevisionDownloaded:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_DOCUMENT)
    case NotificationsType.dataProcessingGranted:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_PRIVACY_SETTINGS)
    case NotificationsType.dataProcessingUpdated:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_PRIVACY_SETTINGS)
    case NotificationsType.dataProcessingSuspended:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_PRIVACY_SETTINGS)
    case NotificationsType.dataProcessingRestored:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_PRIVACY_SETTINGS)
    case NotificationsType.upcomingDataProcessingExpiration:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_PRIVACY_SETTINGS)
    case NotificationsType.dataProcessingExpired:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_PRIVACY_SETTINGS)
    case NotificationsType.privacyPolicyUpdated:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_PRIVACY_POLICY)
    case NotificationsType.privacyPolicyConsentUpdated:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_PRIVACY_POLICY)
    case NotificationsType.profileAttached:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_ACCOUNT_MANAGEMENT)
    case NotificationsType.profileDeattached:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_ACCOUNT_MANAGEMENT)
    case NotificationsType.upcomingSubscriptionTrialExpiration:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_ACCOUNT_MANAGEMENT)
    case NotificationsType.upcomingDocumentRetentionExpiration:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_ARCHIVE_MANAGEMENT)
    case NotificationsType.documentRetentionExpired:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_ARCHIVE_MANAGEMENT)
    case NotificationsType.upcomingDoxRetentionExpiration:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_ARCHIVE_MANAGEMENT)
    case NotificationsType.doxRetentionExpired:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_ARCHIVE_MANAGEMENT)
    case NotificationsType.doxDelivered:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_ARCHIVE_MANAGEMENT)
    case NotificationsType.doxReceived:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_ARCHIVE_MANAGEMENT)
    case NotificationsType.invitationPermitted:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_INVITATION)
    case NotificationsType.invitationReceived:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_INVITATION)
    case NotificationsType.invitationAccepted:
      return t(msgIds.MSG_NOTIFICATION_ARGUMENT_INVITATION)
    default:
  }
}

export function typesFromArgument(argumentType: NotificationArgumentType) {
  switch (argumentType) {
    case NotificationArgumentType.none:
      return [0]
    case NotificationArgumentType.message:
      return [NotificationsType.userPrivateMessage]
    case NotificationArgumentType.profileManagement:
      return [
        NotificationsType.upcomingProfileSuspension,
        NotificationsType.profileSuspension,
        NotificationsType.upcomingProfileDeletion,
        NotificationsType.profileDeletionBySystem,
        NotificationsType.profileDeletionByOwner,
        NotificationsType.profileDeletionByOperatorAdmin,
      ]
    case NotificationArgumentType.accountManagement:
      return [
        NotificationsType.upcomingUserDeletion,
        NotificationsType.userDeletionBySystem,
        NotificationsType.userDeletionByOwner,
        NotificationsType.upcomingSubscriptionTrialExpiration,
      ]
    case NotificationArgumentType.pdxContract:
      return [NotificationsType.contractUpdated]
    case NotificationArgumentType.dossier:
      return [NotificationsType.dossierDelivered]
    case NotificationArgumentType.document:
      return [
        NotificationsType.documentRevisionCreated,
        NotificationsType.documentRevisionViewed,
        NotificationsType.documentRevisionDownloaded,
      ]
    case NotificationArgumentType.privacySettings:
      return [
        NotificationsType.dataProcessingGranted,
        NotificationsType.dataProcessingUpdated,
        NotificationsType.dataProcessingSuspended,
        NotificationsType.dataProcessingRestored,
        NotificationsType.upcomingDataProcessingExpiration,
        NotificationsType.dataProcessingExpired,
      ]
    case NotificationArgumentType.privacyPolicy:
      return [NotificationsType.privacyPolicyUpdated, NotificationsType.privacyPolicyConsentUpdated]
    case NotificationArgumentType.archiveManagement:
      return [
        NotificationsType.profileAttached,
        NotificationsType.profileDeattached,
        NotificationsType.upcomingDocumentRetentionExpiration,
        NotificationsType.documentRetentionExpired,
        NotificationsType.upcomingDoxRetentionExpiration,
        NotificationsType.doxRetentionExpired,
        NotificationsType.doxDelivered,
        NotificationsType.doxReceived,
      ]
    case NotificationArgumentType.invitation:
      return [
        NotificationsType.invitationPermitted,
        NotificationsType.invitationReceived,
        NotificationsType.invitationAccepted,
      ]
    default:
      return []
  }
}

export function bodyFromBodyNotification(notification: Notification, t: any, i18n: i18n) {
  switch (notification.bodyId) {
    case 'MSG_BODY_UPCOMING_CUSTOMER_PROFILE_SUSPENSION':
      return t(msgIds.MSG_NOTIFICATION_BODY_UPCOMING_CUSTOMER_PROFILE_SUSPENSION, {
        daysCountDown: notification.context?.values?.daysCountdown,
        profileTypeDsc: Utils.getProfileTypeDsc(t, notification.context?.author?.profile?.type),
      })
    case 'MSG_BODY_UPCOMING_OPERATOR_AUTO_PROFILE_SUSPENSION':
      return t(msgIds.MSG_NOTIFICATION_BODY_UPCOMING_OPERATOR_AUTO_PROFILE_SUSPENSION, {
        daysCountDown: notification.context?.values?.daysCountdown,
        profileTypeDsc: Utils.getProfileTypeDsc(t, notification.context?.author?.profile?.type),
        identity: notification?.context?.author?.linkedAccount?.getIdentityInverse(),
      })
    case 'MSG_BODY_CUSTOMER_PROFILE_SUSPENSION':
      return t(msgIds.MSG_NOTIFICATION_BODY_CUSTOMER_PROFILE_SUSPENSION, {
        profileTypeDsc: Utils.getProfileTypeDsc(t, notification.context?.author?.profile?.type),
      })
    case 'MSG_BODY_OPERATOR_AUTO_PROFILE_SUSPENSION':
      return t(msgIds.MSG_NOTIFICATION_BODY_OPERATOR_AUTO_PROFILE_SUSPENSION, {
        profileTypeDsc: Utils.getProfileTypeDsc(t, notification.context?.author?.profile?.type),
        identity: notification?.context?.accountLinked?.getIdentityInverse(),
      })
    case 'MSG_BODY_UPCOMING_CUSTOMER_PROFILE_DELETION':
      return t(msgIds.MSG_NOTIFICATION_BODY_UPCOMING_CUSTOMER_PROFILE_DELETION, {
        profileTypeDsc: Utils.getProfileTypeDsc(t, notification.context?.author?.profile?.type),
        daysCountDown: notification.context?.values?.daysCountdown,
      })
    case 'MSG_BODY_UPCOMING_OPERATOR_AUTO_PROFILE_DELETION':
      return t(msgIds.MSG_NOTIFICATION_BODY_UPCOMING_OPERATOR_AUTO_PROFILE_DELETION, {
        profileTypeDsc: Utils.getProfileTypeDsc(t, notification.context?.author?.profile?.type),
        identity: notification?.context?.accountLinked?.getIdentityInverse(),
        daysCountDown: notification.context?.values?.daysCountdown,
      })
    case 'MSG_BODY_CUSTOMER_PROFILE_DELETION_BY_SYSTEM':
      return t(msgIds.MSG_NOTIFICATION_BODY_CUSTOMER_PROFILE_DELETION_BY_SYSTEM, {
        profileTypeDsc: Utils.getProfileTypeDsc(t, notification.context?.author?.profile?.type),
      })
    case 'MSG_BODY_OPERATOR_AUTO_PROFILE_DELETION_BY_SYSTEM':
      return t(msgIds.MSG_NOTIFICATION_BODY_OPERATOR_AUTO_PROFILE_DELETION_BY_SYSTEM, {
        profileTypeDsc: Utils.getProfileTypeDsc(t, notification.context?.author?.profile?.type),
        identity: notification?.context?.accountLinked?.getIdentityInverse(),
      })
    case 'MSG_BODY_CUSTOMER_PROFILE_DELETION_BY_OWNER':
      return t(msgIds.MSG_NOTIFICATION_BODY_CUSTOMER_PROFILE_DELETION_BY_OWNER, {
        profileTypeDsc: Utils.getProfileTypeDsc(t, notification.context?.author?.profile?.type),
      })
    case 'MSG_BODY_OPERATOR_AUTO_PROFILE_DELETION_BY_OWNER':
      return t(msgIds.MSG_NOTIFICATION_BODY_OPERATOR_AUTO_PROFILE_DELETION_BY_OWNER, {
        profileTypeDsc: Utils.getProfileTypeDsc(t, notification.context?.author?.profile?.type),
        identity: notification?.context?.accountLinked?.getIdentityInverse(),
      })
    case 'MSG_BODY_COLLABORATOR_PROFILE_DELETION_BY_OWNER':
      return t(msgIds.MSG_NOTIFICATION_BODY_COLLABORATOR_PROFILE_DELETION_BY_OWNER, {
        profileTypeDsc: Utils.getProfileTypeDsc(t, notification.context?.author?.profile?.type),
        identity: notification?.context?.accountLinked?.getIdentityInverse(),
      })
    case 'MSG_BODY_COLLABORATOR_PROFILE_DELETION_BY_OPERATOR_ADMIN':
      return t(msgIds.MSG_NOTIFICATION_BODY_COLLABORATOR_PROFILE_DELETION_BY_OPERATOR_ADMIN, {
        profileTypeDsc: Utils.getProfileTypeDsc(t, notification.context?.author?.profile?.type),
        identity: notification?.context?.accountLinked?.getIdentityInverse(),
      })
    case 'MSG_BODY_UPCOMING_USER_DELETION':
      return t(msgIds.MSG_NOTIFICATION_BODY_UPCOMING_USER_DELETION, {
        daysCountDown: notification.context?.values?.daysCountdown,
      })
    case 'MSG_BODY_USER_DELETION_BY_SYSTEM':
      return t(msgIds.MSG_NOTIFICATION_BODY_USER_DELETION_BY_SYSTEM)
    case 'MSG_BODY_USER_DELETION_BY_OWNER':
      return t(msgIds.MSG_NOTIFICATION_BODY_USER_DELETION_BY_OWNER)
    case 'MSG_BODY_PRIVACY_POLICY_UPDATED_BY_EXIDEA_WITH_BREAKING_CHANGE':
      return t(msgIds.MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_EXIDEA_WITH_BREAKING_CHANGE, {
        targetProfileType: Utils.getProfileTypeDsc(
          t,
          notification.context?.contractVersion?.contract?.targetProfileType
        ),
      })
    case 'MSG_BODY_PRIVACY_POLICY_UPDATED_BY_EXIDEA_WITH_NO_BREAKING_CHANGE':
      return t(msgIds.MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_EXIDEA_WITH_NO_BREAKING_CHANGE, {
        targetProfileType: Utils.getProfileTypeDsc(
          t,
          notification.context?.contractVersion?.contract?.targetProfileType
        ),
      })
    case 'MSG_BODY_TERMS_OF_USE_UPDATED_BY_EXIDEA_WITH_BREAKING_CHANGE':
      return t(msgIds.MSG_NOTIFICATION_BODY_TERMS_OF_USE_UPDATED_BY_EXIDEA_WITH_BREAKING_CHANGE, {
        targetProfileType: Utils.getProfileTypeDsc(
          t,
          notification.context?.contractVersion?.contract?.targetProfileType
        ),
      })
    case 'MSG_BODY_TERMS_OF_USE_UPDATED_BY_EXIDEA_WITH_NO_BREAKING_CHANGE':
      return t(msgIds.MSG_NOTIFICATION_BODY_TERMS_OF_USE_UPDATED_BY_EXIDEA_WITH_NO_BREAKING_CHANGE, {
        targetProfileType: Utils.getProfileTypeDsc(
          t,
          notification.context?.contractVersion?.contract?.targetProfileType
        ),
      })
    case 'MSG_BODY_DATA_PROCESSOR_UPDATED_BY_EXIDEA_WITH_BREAKING_CHANGE':
      return t(msgIds.MSG_NOTIFICATION_BODY_DATA_PROCESSOR_UPDATED_BY_EXIDEA_WITH_BREAKING_CHANGE)
    case 'MSG_BODY_DATA_PROCESSOR_UPDATED_BY_EXIDEA_WITH_NO_BREAKING_CHANGE':
      return t(msgIds.MSG_NOTIFICATION_BODY_DATA_PROCESSOR_UPDATED_BY_EXIDEA_WITH_NO_BREAKING_CHANGE)
    case 'MSG_BODY_DOX_DELIVERED':
      return t(msgIds.MSG_NOTIFICATION_BODY_DOX_DELIVERED)
    case 'MSG_BODY_DOX_RECEIVED':
      return t(msgIds.MSG_NOTIFICATION_BODY_DOX_RECEIVED)
    case 'MSG_BODY_DOCUMENT_REVISION_CREATED_BY_CUSTOMER':
      return t(msgIds.MSG_NOTIFICATION_BODY_DOCUMENT_REVISION_CREATED_BY_CUSTOMER, {
        title: notification.context?.documentRevision?.name,
        editedAt:
          Utils.toLocaleDateString(notification.context?.documentRevision?.editedAt, i18n, dateShortOptions) ?? "' '",
        creatorIdentity: notification.context?.documentRevision?.creatorIdentity,
      })
    case 'MSG_BODY_DOCUMENT_REVISION_CREATED_BY_STRUCTURE':
      return t(msgIds.MSG_NOTIFICATION_BODY_DOCUMENT_REVISION_CREATED_BY_STRUCTURE, {
        title: notification.context?.documentRevision?.name,
        editedAt:
          Utils.toLocaleDateString(notification.context?.documentRevision?.editedAt, i18n, dateShortOptions) ?? "' '",
        creatorIdentity: notification.context?.documentRevision?.creatorIdentity,
      })
    case 'MSG_BODY_DOCUMENT_REVISION_VIEWED':
      return t(msgIds.MSG_NOTIFICATION_BODY_DOCUMENT_VIEWED, {
        title: notification.context?.documentRevision?.name,
        editedAt:
          Utils.toLocaleDateString(notification.context?.documentRevision?.editedAt, i18n, dateShortOptions) ?? "' '",
        creatorIdentity: notification.context?.documentRevision?.creatorIdentity,
      })
    case 'MSG_BODY_DOCUMENT_REVISION_DOWNLOADED':
      return t(msgIds.MSG_NOTIFICATION_BODY_DOCUMENT_REVISION_DOWNLOADED, {
        title: notification.context?.documentRevision?.name,
        editedAt:
          Utils.toLocaleDateString(notification.context?.documentRevision?.editedAt, i18n, dateShortOptions) ?? "' '",
        creatorIdentity: notification.context?.documentRevision?.creatorIdentity,
      })
    case 'MSG_BODY_DATA_PROCESSING_GRANTED':
      return t(msgIds.MSG_NOTIFICATION_BODY_DATA_PROCESSING_GRANTED, {
        startAt: Utils.toLocaleDateString(notification.context?.permission?.startAt, i18n, dateShortOptions) ?? "' '",
        endAt: Utils.toLocaleDateString(notification.context?.permission?.endAt, i18n, dateShortOptions) ?? "' '",
      })
    case 'MSG_BODY_DATA_PROCESSING_UPDATED':
      return t(msgIds.MSG_NOTIFICATION_BODY_DATA_PROCESSING_UPDATED, {
        startAt: Utils.toLocaleDateString(notification.context?.permission?.startAt, i18n, dateShortOptions) ?? "' '",
        endAt: Utils.toLocaleDateString(notification.context?.permission?.endAt, i18n, dateShortOptions) ?? "' '",
      })
    case 'MSG_BODY_DATA_PROCESSING_DELETED':
      return t(msgIds.MSG_NOTIFICATION_BODY_DATA_PROCESSING_DELETED)
    case 'MSG_BODY_DATA_PROCESSING_SUSPENDED':
      return t(msgIds.MSG_NOTIFICATION_BODY_DATA_PROCESSING_SUSPENDED)
    case 'MSG_BODY_DATA_PROCESSING_RESTORED':
      return t(msgIds.MSG_NOTIFICATION_BODY_DATA_PROCESSING_RESTORED)
    case 'MSG_BODY_UPCOMING_DATA_PROCESSING_EXPIRATION_CUSTOMER':
      return t(msgIds.MSG_NOTIFICATION_BODY_UPCOMING_DATA_PROCESSING_EXPIRATION_CUSTOMER, {
        daysCountDown: notification.context?.values?.daysCountdown,
        identity: notification?.context?.target?.getIdentityInverse(),
      })
    case 'MSG_BODY_UPCOMING_DATA_PROCESSING_EXPIRATION_OPERATOR':
      return t(msgIds.MSG_NOTIFICATION_BODY_UPCOMING_DATA_PROCESSING_EXPIRATION_OPERATOR, {
        daysCountDown: notification.context?.values?.daysCountdown,
        identity: notification.context?.author?.getIdentityInverse(),
        birthdate: notification.context?.author?.userDetails?.birthdate,
      })
    case 'MSG_BODY_DATA_PROCESSING_EXPIRED_CUSTOMER':
      return t(msgIds.MSG_NOTIFICATION_BODY_DATA_PROCESSING_EXPIRED_CUSTOMER, {
        identity: notification.context?.target?.getIdentityInverse(),
      })
    case 'MSG_BODY_DATA_PROCESSING_EXPIRED_OPERATOR':
      return t(msgIds.MSG_NOTIFICATION_BODY_DATA_PROCESSING_EXPIRED_OPERATOR, {
        identity: notification.context?.author?.getIdentityInverse(),
        birthdate: notification.context?.author?.userDetails?.birthdate,
      })
    case 'MSG_BODY_PRIVACY_POLICY_UPDATED_BY_STRUCTURE_WITH_BREAKING_CHANGE':
      return notification.context?.contractVersion?.isNewAcknowledgmentRequired
        ? t(msgIds.MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_STRUCTURE_WITH_BREAKING_CHANGE1)
        : t(msgIds.MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_STRUCTURE_WITH_BREAKING_CHANGE2)
    case 'MSG_BODY_PRIVACY_POLICY_UPDATED_BY_STRUCTURE_WITH_NO_BREAKING_CHANGE':
      return t(msgIds.MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_STRUCTURE_WITH_NO_BREAKING_CHANGE)
    case 'MSG_BODY_PRIVACY_POLICY_UPDATED_BY_STRUCTURE_WITH_NO_BREAKING_CHANGE_AND_NEW_SECTIONS_WITH_CONSENT':
      return t(
        msgIds.MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_STRUCTURE_WITH_NO_BREAKING_CHANGE_AND_NEW_SECTIONS_WITH_CONSENT
      )
    case 'MSG_BODY_PRIVACY_POLICY_CONSENT_UPDATED':
      if (!!notification.context?.consent?.revokedAt) {
        return t(msgIds.MSG_NOTIFICATION_BODY_PRIVACY_POLICY_CONSENT_UPDATED1)
      } else {
        if (!!notification.context?.consent?.actions?.find((p) => p.action === ActionType.dataProcessing)) {
          return t(msgIds.MSG_NOTIFICATION_BODY_PRIVACY_POLICY_CONSENT_UPDATED2)
        } else {
          return t(msgIds.MSG_NOTIFICATION_BODY_PRIVACY_POLICY_CONSENT_UPDATED3)
        }
      }
    case 'MSG_BODY_PROFILE_ATTACHED':
      return t(msgIds.MSG_NOTIFICATION_BODY_PROFILE_ATTACHED)
    case 'MSG_BODY_PROFILE_DETACHED':
      return t(msgIds.MSG_NOTIFICATION_BODY_PROFILE_DETACHED, {
        identity: notification.context?.target?.getIdentityInverse(),
      })
    case 'MSG_BODY_SUBSCRIPTION_TRIAL_UPCOMING_EXPIRATION':
      return t(msgIds.MSG_NOTIFICATION_BODY_SUBSCRIPTION_TRIAL_UPCOMING_EXPIRATION, {
        subscriptionId: notification.context?.subscription?.id,
        trialEndAt:
          Utils.toLocaleDateString(notification.context?.subscription?.trialEndAt, i18n, dateShortOptions) ?? "' '",
      })
    case 'MSG_BODY_UPCOMING_DOCUMENT_RETENTION_EXPIRATION':
      return t(msgIds.MSG_NOTIFICATION_BODY_UPCOMING_DOCUMENT_RETENTION_EXPIRATION, {
        daysCountDown: notification.context?.values?.daysCountdown,
      })
    case 'MSG_BODY_DOCUMENT_RETENTION_EXPIRED':
      return t(msgIds.MSG_NOTIFICATION_BODY_DOCUMENT_RETENTION_EXPIRED, {
        title: notification.context?.document?.lastArchivedRevision?.name,
        editedAt:
          Utils.toLocaleDateString(
            notification.context?.document?.lastArchivedRevision?.editedAt,
            i18n,
            dateShortOptions
          ) ?? "' '",
        creatorIdentity: notification.context?.document?.lastArchivedRevision?.creatorIdentity,
      })
    case 'MSG_BODY_UPCOMING_DOX_RETENTION_EXPIRATION':
      return t(msgIds.MSG_NOTIFICATION_BODY_UPCOMING_DOX_RETENTION_EXPIRATION, {
        daysCountDown: notification.context?.values?.daysCountdown,
      })
    case 'MSG_BODY_DOX_RETENTION_EXPIRED':
      return t(msgIds.MSG_NOTIFICATION_BODY_DOX_RETENTION_EXPIRED)
    case 'MSG_BODY_INVITATION_PERMITTED':
      return t(msgIds.MSG_NOTIFICATION_BODY_INVITATION_PERMITTED)
    case 'MSG_BODY_INVITATION_RECEIVED_COLLABORATE':
      return t(msgIds.MSG_NOTIFICATION_BODY_INVITATION_RECEIVED_COLLABORATE)
    case 'MSG_BODY_INVITATION_ACCEPTED_COLLABORATE':
      return t(msgIds.MSG_NOTIFICATION_BODY_INVITATION_ACCEPTED_COLLABORATE)
    default:
  }
}

export function sortNotifications(notifications: Notification[]) {
  notifications.sort((a, b) => {
    if (a.pinned && !b.pinned) {
      return -1
    }
    if (!a.pinned && b.pinned) {
      return 1
    }
    return b.createdAt.getTime() - a.createdAt.getTime()
  })
}
