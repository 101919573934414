import { Stack, StackProps } from '@mui/material'
import FromToDatePicker from '../fromToDatePicker/FromToDatePicker'
import { IEventHistoryFilters, IEventHistoryFiltersFormProps } from './EventHistoryFiltersForm.types'
import { FakeSearchInput } from '../fakeSearchInput/FakeSearchInput'
import { PageStepper } from '../pageStepper/PageStepper'
import { useStackState } from '../../hooks/Stack'
import { PageStep } from '../pageStepper/PageStep'
import { AccountsSearch } from '../accountsSearch/AccountsSearch'
import { AccountSearchTypes } from '../../shared/Constants'
import { Account } from '../../models/Account'
import { ViewContent } from '../viewContent/ViewContent'
import AccountInfoStructure from '../accountInfoStructure/AccountInfoStructure'
import AccountInfoOperator from '../accountInfoOperator/AccountInfoOperator'
import { ViewHeader } from '../viewHeader/ViewHeader'
import { ViewActions } from '../viewActions/ViewActions'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import { useEffect, useState } from 'react'
import { TreatmentSelect } from '../treatmentSelect/TreatmentSelect'

type StepperState = {
  step?: 'form' | 'search' | 'account'
  searchOption?: AccountSearchTypes
  account?: Account
}

export function EventHistoryFiltersForm(props: IEventHistoryFiltersFormProps & StackProps): JSX.Element {
  const { filters: filtersProp, onChangeFilters, onCancel, ...rest } = props
  const { t } = useTranslation()
  const stack = useStackState<StepperState>([{ step: 'form' }])
  const [filters, setFilters] = useState<IEventHistoryFilters>(filtersProp)

  useEffect(() => {
    setFilters(filtersProp)
  }, [filtersProp])

  function onClickSearchStructure() {
    stack.push({ step: 'search', searchOption: 'structures' })
  }

  function onClickSearchOperator() {
    stack.push({ step: 'search', searchOption: 'operators' })
  }

  function onAccountSelected(account: Account) {
    if (stack.head?.searchOption === 'structures') {
      setFilters((filters) => {
        return { ...filters, authorLinkedAccount: account }
      })
    } else {
      setFilters((filters) => {
        return { ...filters, authorAccount: account }
      })
    }
    stack.pop()
  }

  function onClickAvatar(account: Account) {
    stack.push({ step: 'account', account, searchOption: stack.head?.searchOption })
  }

  function onOk() {
    onChangeFilters(filters)
  }

  function onCancelProxy() {
    setFilters(filtersProp)
    onCancel()
  }

  return (
    <>
      <ViewHeader
        title={t(msgIds.MSG_EVENT_HISTORY_FILTERS_FORM_TITLE)}
        backButtonVisible={stack.length > 1}
        onClickBack={stack.pop}
        exitButtonVisible={true}
        onClickExit={onCancelProxy}
      />
      <PageStepper spacing={4} {...rest} activeStep={stack.head?.step}>
        <PageStep step="form">
          <ViewContent>
            <Stack spacing={4}>
              <FromToDatePicker
                justifyContent="space-between"
                fromDate={filters.fromDate}
                onChangeFromDate={(date) =>
                  setFilters((filters) => {
                    return { ...filters, fromDate: date }
                  })
                }
                toDate={filters.toDate}
                onChangeToDate={(date) =>
                  setFilters((filters) => {
                    return { ...filters, toDate: date }
                  })
                }
              />
              {(props.historyType === 'document' || props.historyType === 'dox') && (
                <>
                  <FakeSearchInput
                    placeholder={t(msgIds.MSG_EVENT_HISTORY_FILTERS_FORM_SEARCH_STRUCTURE_PLACEHOLDER) as string}
                    onFocus={onClickSearchStructure}
                    onClickSearch={onClickSearchStructure}
                    onClickClear={() =>
                      setFilters((filters) => {
                        return { ...filters, authorLinkedAccount: undefined }
                      })
                    }
                    value={filters.authorLinkedAccount?.getIdentity() || ''}
                  />
                  <FakeSearchInput
                    placeholder={t(msgIds.MSG_EVENT_HISTORY_FILTERS_FORM_SEARCH_OPERATOR_PLACEHOLDER) as string}
                    onFocus={onClickSearchOperator}
                    onClickSearch={onClickSearchOperator}
                    onClickClear={() =>
                      setFilters((filters) => {
                        return { ...filters, authorAccount: undefined }
                      })
                    }
                    value={filters.authorAccount?.getIdentity() || ''}
                  />
                </>
              )}
              {props.historyType === 'mop' && (
                <TreatmentSelect
                  treatmentId={filters.treatment?.id}
                  onChangeTreatment={(treatment) =>
                    setFilters((filters) => {
                      return { ...filters, treatment: treatment }
                    })
                  }
                />
              )}
            </Stack>
          </ViewContent>
          <ViewActions>
            <ViewActionsButton defaultAction onClick={onOk}>
              {t(msgIds.MSG_OK)}
            </ViewActionsButton>
            <ViewActionsButton onClick={onCancelProxy}>{t(msgIds.MSG_CANCEL)}</ViewActionsButton>
          </ViewActions>
        </PageStep>
        <PageStep step="search">
          {stack.head?.searchOption && (
            <AccountsSearch
              searchOption={stack.head.searchOption}
              onCancel={stack.pop}
              onAccountSelected={onAccountSelected}
              onClickAvatar={onClickAvatar}
              goToAccountPageOption={false}
              showPrivacyPolicy={false}
            />
          )}
        </PageStep>
        <PageStep step="account">
          {stack.head?.account &&
            stack.head.searchOption &&
            (stack.head.searchOption === 'structures' ? (
              <AccountInfoStructure account={stack.head.account} />
            ) : (
              <AccountInfoOperator account={stack.head.account} />
            ))}
        </PageStep>
      </PageStepper>
    </>
  )
}
