import { PDX_API_URL } from '../shared/Constants'
import log from '../shared/Logger'
import { axiosInstance } from '../contexts/AuthContext'
import { IAccountJson, Account } from '../models/Account'

// ********************
// GET

// ********************
// POST

export function activateNewProfile(abortSignal: AbortSignal, parms: any): Promise<Account> {
  const funcName = 'activateNewProfile'
  let url = `${PDX_API_URL}/v1/profiles`

  return axiosInstance
    .post(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Account.deserialize(resp.data as IAccountJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// PATCH

export type AttachPlaceholderArgs = {
  avatarImage?: string
  country?: string
  city?: string
  province?: string
  street?: string
  streetNumber?: string
  zip?: string
  phone?: string
  mobilePhone1?: string
  mobilePhone2?: string
  email1?: string
  email2?: string
  email3?: string
  email4?: string
}

export function attachPlaceholder(
  abortSignal: AbortSignal,
  toAttachProfileId: number,
  placeholderProfileId: number,
  args: AttachPlaceholderArgs
) {
  const funcName = 'updateBillingData'
  const url = `${PDX_API_URL}/v1/profiles/${toAttachProfileId}`

  let parms = {
    ...args,
    attach_placeholder_profile_id: placeholderProfileId,
  }
  return axiosInstance
    .patch(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Account.deserialize(resp.data as IAccountJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// DELETE
