import { Stack } from '@mui/material'
import LoadingOverlay from '../loadingOverlay/LoadingOverlay'
import { IDataEditorContainerProps } from './DataEditorContainer.types'

export function DataEditorContainer(props: IDataEditorContainerProps): JSX.Element {
  const { children, isLoading, ...rest } = props
  return (
    <Stack paddingBottom={3} spacing={0} sx={{ position: 'relative' }} {...rest}>
      {children}
      <LoadingOverlay isLoading={!!isLoading}></LoadingOverlay>
    </Stack>
  )
}
