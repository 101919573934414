import React, { useState } from 'react'
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import * as dalAccount from '../../dal/DalAccount'
import { useTranslation } from 'react-i18next'
import { useAuthContext } from '../../contexts/AuthContext'
import msgIds from '../../locales/msgIds'
import {
  AccountIco,
  DashboardIco,
  EuroIco,
  HelpIco,
  LogoutIco,
  OperatorIco,
  PrivacyDataProcessorIco,
  PrivacyIco,
  ProductsIco,
  SubscriptionsIco,
  TermsConditionsIco,
  UserIco,
} from '../icons'
import { useNavigate } from 'react-router-dom'
import { ContractType, ProfileType, isOperator } from '../../shared/Constants'
import FlagItaly from '../images/FlagItaly'
import FlagUK from '../images/FlagUK'
import { TutorialsMenu } from '../tutorialsMenu/TutorialsMenu'
import { useLayout } from '../../hooks/Layout'
import { Utils } from '../../shared/Utils'
import { ISimpleDialogData } from '../../dialogs/simpleDialog/SimpleDialog.types'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'

interface TopbarMenuProps {
  tourStepName: string
}

export default function TopbarMenu(props: TopbarMenuProps) {
  const { isPhone } = useLayout()
  const authContext = useAuthContext()
  const navigate = useNavigate()
  const { i18n, t } = useTranslation()
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)
  const [tutorialsMenuAnchorEl, setTutorialsMenuAnchorEl] = useState<HTMLElement | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  // dialogs
  const [simpleDialogData, setSimpleDialogData] = useState<ISimpleDialogData>()
  const [simpleDialogOpen, setSimpleDialogOpen] = useState(false)

  function setLocale_it() {
    i18n.changeLanguage('it')
    setAnchorElUser(null)
  }

  function setLocale_en() {
    i18n.changeLanguage('en')
    setAnchorElUser(null)
  }

  function navigateToNoticeBoard() {
    navigate('/notice_board', {})
    setAnchorElUser(null)
  }

  function showProducts() {
    const profileType = isOperator(authContext.loggedProfileType) ? ProfileType.operatorAdmin : ProfileType.customer
    navigate(`/billing/products?profileType=${profileType}`)
    setAnchorElUser(null)
  }

  function showSubscriptions() {
    navigate(`/billing/subscriptions`)
    setAnchorElUser(null)
  }

  function showInvoices() {
    navigate(`/billing/invoices`)
    setAnchorElUser(null)
  }

  function activeteNewOperatorProfile() {
    navigate(`/billing/products?profileType=${ProfileType.operatorAdmin}&activateNewProfile=true`)
    setAnchorElUser(null)
  }

  async function activeteNewConsumerProfile() {
    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const accounts = await dalAccount.getLoggedAvailableAccounts(abortController.signal)
      const customerAccount = accounts.filter((p) => p.profile?.type === ProfileType.customer)[0]
      let content = t(msgIds.MSG_ACTIVATE_NEW_CONSUMER_PROFILE_BODY)
      if (customerAccount) {
        if (customerAccount.profile?.expiredAt) {
          content = content + `\n${t(msgIds.MSG_ACTIVATE_NEW_CONSUMER_PROFILE_EXPIRED_BODY)}`
        }
        setSimpleDialogOpen(true)
        setSimpleDialogData({
          title: t(msgIds.MSG_ACTIVATE_NEW_CONSUMER_PROFILE_TITLE),
          content: content,
          actionsStyle: 'Ok',
          onClose: async (result2) => {
            setSimpleDialogOpen(false)
          },
        })
      } else {
        navigate(`/billing/products?profileType=${ProfileType.customer}&activateNewProfile=true`)
        setAnchorElUser(null)
      }
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  function showContractPrivacyPolicy() {
    navigate(`/contracts/versions/viewer`, {
      state: {
        grantorProfileId: authContext.loggedProfileId,
        targetProfileType: authContext.loggedProfileType,
        contractType: ContractType.privacyPolicy,
        contractOwnerProfileId: 0,
        isEditable: true,
      },
    })
    setAnchorElUser(null)
  }

  function showContractTermsOfUse() {
    navigate(`/contracts/versions/viewer`, {
      state: {
        grantorProfileId: authContext.loggedProfileId,
        targetProfileType: authContext.loggedProfileType,
        contractType: ContractType.termsOfUse,
        contractOwnerProfileId: 0,
        isEditable: true,
      },
    })
    setAnchorElUser(null)
  }

  function showContractDataProcessor() {
    navigate(`/contracts/versions/viewer`, {
      state: {
        grantorProfileId: authContext.loggedProfileId,
        targetProfileType: authContext.loggedProfileType,
        contractType: ContractType.dataProcessor,
        contractOwnerProfileId: 0,
        isEditable: true,
      },
    })
    setAnchorElUser(null)
  }

  function logout() {
    const profileType = authContext.loggedProfileType
    authContext.resetJwt()
    authContext.resetLoggedAccount()
    authContext.resetAssistedAccount()
    authContext.resetDestinationAfterLogin()
    navigate(`/login?profileType=${profileType}`, { state: {}, replace: true })
  }

  const handleSubmenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setTutorialsMenuAnchorEl(event.currentTarget)
  }

  return (
    <Box className={props.tourStepName}>
      {simpleDialogData && <SimpleDialog {...simpleDialogData} isOpen={simpleDialogOpen}></SimpleDialog>}
      <Tooltip title={t(msgIds.MSG_USER_MENU)}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          color="inherit"
          onClick={(event) => setAnchorElUser(event.currentTarget)}
        >
          <AccountIco />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={() => setAnchorElUser(null)}
      >
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <LogoutIco />
          </ListItemIcon>
          <ListItemText>{t(msgIds.MSG_LOGOUT)}</ListItemText>
        </MenuItem>
        {isPhone && [
          <Divider key="divider" />,
          <MenuItem key="noticeBoard" onClick={navigateToNoticeBoard}>
            <ListItemIcon>
              <DashboardIco />
            </ListItemIcon>
            <ListItemText>{t(msgIds.MSG_NOTICE_BOARD)}</ListItemText>
          </MenuItem>,
          <MenuItem key="noticeGuide" onClick={handleSubmenuOpen}>
            <ListItemIcon>
              <HelpIco />
            </ListItemIcon>
            <ListItemText>{t(msgIds.MSG_NOTICE_GUIDE)}</ListItemText>
          </MenuItem>,
          <TutorialsMenu
            key="tutorialsMenu"
            anchorEl={tutorialsMenuAnchorEl}
            open={Boolean(tutorialsMenuAnchorEl)}
            onClose={() => {
              setAnchorElUser(null)
              setTutorialsMenuAnchorEl(null)
            }}
          />,
        ]}
        <Divider />
        <Typography
          sx={{
            fontSize: 12,
            fontWeight: 'bold',
            marginLeft: 1,
            marginBottom: 0,
            marginTop: 1.5,
            userSelect: 'none',
            flexGrow: 2,
          }}
        >
          {t(msgIds.MSG_LANGUAGE)}
        </Typography>{' '}
        <MenuItem onClick={setLocale_it}>
          <ListItemIcon>
            <FlagItaly />
          </ListItemIcon>
          <ListItemText>{t(msgIds.MSG_LANGUAGE_ITALIAN)}</ListItemText>
        </MenuItem>
        <MenuItem onClick={setLocale_en}>
          <ListItemIcon>
            <FlagUK />
          </ListItemIcon>
          <ListItemText>{t(msgIds.MSG_LANGUAGE_ENGLISH)}</ListItemText>
        </MenuItem>
        <Divider />
        <Typography
          sx={{
            fontSize: 12,
            fontWeight: 'bold',
            marginLeft: 1,
            marginBottom: 0,
            marginTop: 1.5,
            userSelect: 'none',
            flexGrow: 2,
          }}
        >
          {t(msgIds.MSG_SUBSCRIPTION)}
        </Typography>
        <MenuItem onClick={showProducts}>
          <ListItemIcon>
            <ProductsIco />
          </ListItemIcon>
          <ListItemText>{t(msgIds.MSG_PRODUCTS)}</ListItemText>
        </MenuItem>
        <MenuItem onClick={showSubscriptions}>
          <ListItemIcon>
            <SubscriptionsIco />
          </ListItemIcon>
          <ListItemText>{t(msgIds.MSG_SUBSCRIPTION)}</ListItemText>
        </MenuItem>
        <MenuItem onClick={showInvoices}>
          <ListItemIcon>
            <EuroIco />
          </ListItemIcon>
          <ListItemText>{t(msgIds.MSG_INVOICES)}</ListItemText>
        </MenuItem>
        <MenuItem onClick={activeteNewOperatorProfile}>
          <ListItemIcon>
            <OperatorIco />
          </ListItemIcon>
          <ListItemText>{t(msgIds.MSG_ACTIVATE_NEW_OPERATOR_PROFILE)}</ListItemText>
        </MenuItem>
        <Stack spacing={1} direction={'row'} sx={{ alignItems: 'center' }}>
          <MenuItem onClick={activeteNewConsumerProfile}>
            <ListItemIcon>
              <UserIco />
            </ListItemIcon>
            <ListItemText>{t(msgIds.MSG_ACTIVATE_NEW_CONSUMER_PROFILE)}</ListItemText>
          </MenuItem>
          {isLoading && <CircularProgress size={26} />}
        </Stack>
        <Divider />
        <Typography
          sx={{
            fontSize: 12,
            fontWeight: 'bold',
            marginLeft: 1,
            marginBottom: 0,
            marginTop: 1.5,
            userSelect: 'none',
            flexGrow: 2,
          }}
        >
          {t(msgIds.MSG_PERSONALDOX)}
        </Typography>
        <MenuItem onClick={showContractPrivacyPolicy}>
          <ListItemIcon>
            <PrivacyIco />
          </ListItemIcon>
          <ListItemText>{t(msgIds.MSG_CONTRACT_PRIVACY_POLICY)}</ListItemText>
        </MenuItem>
        <MenuItem onClick={showContractTermsOfUse}>
          <ListItemIcon>
            <TermsConditionsIco />
          </ListItemIcon>
          <ListItemText>{t(msgIds.MSG_CONTRACT_TERMS_OF_USE)}</ListItemText>
        </MenuItem>
        {authContext.loggedProfileType === ProfileType.operatorAdmin && (
          <MenuItem onClick={showContractDataProcessor}>
            <ListItemIcon>
              <PrivacyDataProcessorIco />
            </ListItemIcon>
            <ListItemText>{t(msgIds.MSG_CONTRACT_DATA_PROCESSOR)}</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </Box>
  )
}
