import { Stack, useTheme } from '@mui/material'

import { IFieldsPanelProps } from './FieldsPanel.types'

export default function FieldsPanel(props: IFieldsPanelProps) {
  const theme = useTheme()

  return (
    <Stack
      direction={'column'}
      alignItems={'stretch'}
      paddingX={2}
      paddingY={2}
      sx={{ backgroundColor: theme.palette.common.gray11, borderRadius: 2 }}
    >
      {props.children}
    </Stack>
  )
}
