export interface IContractSectionActionJson {
  action: string
  target: string
}

export class ContractSectionAction {
  action: string = ''
  target: string = ''

  constructor()
  constructor(action: string)
  constructor(action?: string) {
    this.action = action || ''
  }

  public static serialize(obj: ContractSectionAction): IContractSectionActionJson {
    return {
      action: obj.action,
      target: obj.target,
    }
  }

  public static deserialize(json: IContractSectionActionJson): ContractSectionAction {
    const res = new ContractSectionAction()
    res.action = json.action
    res.target = json.target
    return res
  }

  public static serializeArray(objs: ContractSectionAction[]): IContractSectionActionJson[] {
    const jsons = objs.map((p) => {
      return ContractSectionAction.serialize(p)!
    })
    return jsons
  }

  public static deserializeArray(json: IContractSectionActionJson[]): ContractSectionAction[] {
    const res = json.map((p) => {
      return ContractSectionAction.deserialize(p)!
    })
    return res
  }
}
