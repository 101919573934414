import { createTheme } from '@mui/material'
import { common, defaultPalette, muiComponentsOverrides, typography } from './ThemeCommon'

export const themeStructureLight = createTheme({
  palette: {
    ...defaultPalette,
    mode: 'light',
    primary: {
      lighter: '#d4d69e', // Theme_Color7
      light: '#9ea325', // Theme_Color2
      main: '#94990d', // Theme_Color1
      dark: '#7f8305', // Theme_Color0
      darker: '#353700', // Theme_ColorShadow
      contrastText: '#fff',
      lighterGray: common.gray11,
      lightGray: common.gray10,
      gray: common.gray3,
      darkGray: common.gray1,
      darkerGray: common.gray0,
    },
    common: {
      content1: common.content1,
      content2: common.content2,
      content3: common.content3,
      content4: common.content4,
      gray0: common.gray0,
      gray1: common.gray1,
      gray2: common.gray2,
      gray3: common.gray3,
      gray4: common.gray4,
      gray5: common.gray5,
      gray6: common.gray6,
      gray7: common.gray7,
      gray8: common.gray8,
      gray9: common.gray9,
      gray10: common.gray10,
      gray11: common.gray11,
      memoText: common.memoText,
    },
  },
  typography: typography,
  components: muiComponentsOverrides,
})
