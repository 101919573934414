import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function SubscriptionsIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 19.999999,1.9999999 H 3.9999999 c -1.11,0 -2,0.89 -2,2 V 15 c 0,1.11 0.89,2 2,2 h 3.9999999 v 4.999999 l 4.0000002,-2 3.999999,2 V 17 h 4 c 1.11,0 2,-0.89 2,-2 V 3.9999999 c 0,-1.11 -0.89,-2 -2,-2 z M 19.999999,15 H 3.9999999 V 13 H 19.999999 Z m 0,-5.0000003 H 3.9999999 V 3.9999999 H 19.999999 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
