import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { IDoxSelectorDialogProps } from './DoxSelectorDialog.types'
import { DoxSelector } from '../../components/doxSelector/DoxSelector'
import { useEffect, useState } from 'react'
import { useChange } from '../../hooks/Change'
import { PageStepper } from '../../components/pageStepper/PageStepper'
import { PageStep } from '../../components/pageStepper/PageStep'
import { useStackState } from '../../hooks/Stack'
import { DoxEditor } from '../../components/doxEditor/DoxEditor'
import { Dox } from '../../models/Dox'
import { StyledDialog } from '../styledDialog/StyledDialog'
import { ViewHeader } from '../../components/viewHeader/ViewHeader'
import { isConsumer } from '../../shared/Constants'
import { useAuthContext } from '../../contexts/AuthContext'
import { useArchiveContext } from '../../contexts/ArchiveContext'
import { Utils } from '../../shared/Utils'

export default function DoxSelectorDialog(props: IDoxSelectorDialogProps) {
  const { t } = useTranslation()
  const authContext = useAuthContext()
  const archiveContext = useArchiveContext()
  const [doxInEditing, setDoxInEditing] = useState<Dox | undefined>(undefined)
  const [editorMode, setEditorMode] = useState<'new' | 'edit'>('new')
  const stack = useStackState(['dox_selector'])
  const [expandedDoxIds, setExpandedDoxIds] = useState<Set<number>>(new Set())

  useEffect(() => {
    if (!props.isOpen) {
      setExpandedDoxIds(new Set())
    } else {
      const marked = (props.selectedDoxIds || [])
        .concat(props.undeterminedDoxIds || [])
        .concat(props.visibleDoxIds || [])
      const expanded: number[] = []
      const assignableDoxes = archiveContext.getArchiveDoxesFromAcl(props.assignableDoxesAcl)
      assignableDoxes.roots.forEach((dox) => {
        expandedDox(dox, marked, expanded)
      })
      setExpandedDoxIds(new Set(expanded))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen])

  useChange(() => {
    stack.setItems(['dox_selector'])
  }, [props.selectionMode, props.selectedDoxIds])

  function onUpdated(dox: Dox) {
    if (editorMode === 'new') {
      const branch: number[] = [dox.id]
      const assignableDoxes = archiveContext.getArchiveDoxesFromAcl(props.assignableDoxesAcl)
      getBranchFromLeaf(assignableDoxes.roots, dox.id, branch)
      setExpandedDoxIds((ids) => new Set(Array.from(ids).concat(branch)))
    }
    stack.pop()
  }

  function onConfirm(selectedDoxIds: number[], undeterminedDoxIds: number[]) {
    const initSelectionDoxIds = props.selectedDoxIds || []
    const initMarkedDoxIds = Utils.getArrayUnion(initSelectionDoxIds, props.undeterminedDoxIds || [])
    const currentMarkedDoxIds = Utils.getArrayUnion(selectedDoxIds, undeterminedDoxIds)
    const addedDoxIds = Utils.getArrayDifference(selectedDoxIds, initSelectionDoxIds)
    const removedDoxIds = Utils.getArrayDifference(initMarkedDoxIds, currentMarkedDoxIds)

    console.log('')
    console.log(`addedDoxIds ${addedDoxIds}`)
    console.log(`removedDoxIds ${removedDoxIds}`)

    props.onResult({ userChoice: 'yes', addedDoxIds, removedDoxIds })
  }

  function headerTitle(step: any) {
    switch (step) {
      case 'dox_selector':
        return t(msgIds.MSG_DOX_SELECTOR_DIALOG_TITLE)
      case 'dox_editor':
        return editorMode === 'new'
          ? t(msgIds.MSG_DOX_EDITOR_DIALOG_NEW_DOX_TITLE)
          : t(msgIds.MSG_DOX_EDITOR_DIALOG_MODIFY_DOX_TITLE)
      default:
        return 'error'
    }
  }

  return (
    <StyledDialog open={props.isOpen} onClose={() => props.onClose({ userChoice: 'abort' })}>
      <ViewHeader
        title={headerTitle(stack.head)}
        backButtonVisible={stack.length > 1}
        onClickBack={stack.pop}
        exitButtonVisible={true}
        onClickExit={() => props.onClose({ userChoice: 'abort' })}
      />
      <PageStepper activeStep={stack.head} flexGrow={1}>
        <PageStep step="dox_selector">
          <DoxSelector
            key={`dox-selector`}
            selectionMode={props.selectionMode}
            assignableDoxesAcl={props.assignableDoxesAcl}
            showRoot={props.showRoot}
            undeterminedDoxIds={props.undeterminedDoxIds}
            disabledDoxId={props.disabledDoxId}
            selectedDoxIds={props.selectedDoxIds}
            visibleDoxIds={Array.from(expandedDoxIds)}
            onExpandDox={(dox, expanded) => {
              setExpandedDoxIds((ids) => {
                const s = new Set(Array.from(ids))
                if (expanded) {
                  s.add(dox.id)
                } else {
                  s.delete(dox.id)
                }
                return s
              })
            }}
            onCancel={() => props.onClose({ userChoice: 'no' })}
            onConfirm={onConfirm}
            onEditDox={(dox) => {
              setEditorMode('edit')
              setDoxInEditing(dox)
              stack.push('dox_editor')
            }}
            onAddDox={(dox) => {
              setEditorMode('new')
              let targetProfileId = isConsumer(authContext.loggedProfileType)
                ? authContext.loggedProfileId!
                : authContext.assistedAccountProfileId!
              const newDox = archiveContext.getLatestRwArchiveDoxes().createNewChildDox(dox, targetProfileId, t)
              setDoxInEditing(newDox)
              stack.push('dox_editor')
            }}
          />
        </PageStep>
        <PageStep step="dox_editor">
          {doxInEditing && (
            <DoxEditor mode={editorMode} dox={doxInEditing} onCancel={stack.pop} onUpdated={onUpdated} />
          )}
        </PageStep>
      </PageStepper>
    </StyledDialog>
  )
}

function isSelected(dox: Dox, selectedDoxIds: number[]): boolean {
  return selectedDoxIds.indexOf(dox.id) !== -1
}

function expandedDox(dox: Dox, selectedDoxIds: number[], doxIds: number[]): boolean {
  const expanded = dox.children.reduce((res, dox) => {
    const expanded = expandedDox(dox, selectedDoxIds, doxIds)
    if (expanded) {
      doxIds.push(dox.parentId)
    }
    return res || expanded
  }, false)

  return expanded || isSelected(dox, selectedDoxIds)
}

function getBranchFromLeaf(doxes: Dox[], leaf: number, branch: number[]): boolean {
  const dox = doxes.find((d) => d.id === leaf)
  if (dox) {
    return true
  } else {
    for (const dox of doxes) {
      if (dox.children && getBranchFromLeaf(dox.children, leaf, branch)) {
        branch.push(dox.id)
        return true
      }
    }
  }
  return false
}
