import { InvitationPurposeType, InvitationPurposeType_all } from '../shared/Constants'
import { Account, IAccountJson } from './Account'

export interface IInvitationJson {
  id: number
  owner_profile_id: number
  author_profile_id: number
  target_profile_id: number
  purpose: number
  revoked_at?: string
  accepted_at?: string
  created_at: string
  updated_at: string
  owner?: IAccountJson
  author?: IAccountJson
  target?: IAccountJson
}

export class Invitation {
  id: number = 0
  ownerProfileId: number = 0
  authorProfileId: number = 0
  targetProfileId: number = 0
  purpose: InvitationPurposeType_all = InvitationPurposeType.collaborate
  revokedAt?: Date
  acceptedAt?: Date
  createdAt: Date = new Date(0)
  updatedAt: Date = new Date(0)
  owner?: Account
  author?: Account
  target?: Account

  constructor() {}

  public static serialize(obj: Invitation): IInvitationJson {
    return {
      id: obj.id,
      owner_profile_id: obj.ownerProfileId,
      author_profile_id: obj.authorProfileId,
      target_profile_id: obj.targetProfileId,
      purpose: obj.purpose,
      revoked_at: obj.revokedAt?.toISOString(),
      accepted_at: obj.acceptedAt?.toISOString(),
      created_at: obj.createdAt.toISOString(),
      updated_at: obj.updatedAt.toISOString(),
      owner: obj.owner ? Account.serialize(obj.owner) : undefined,
      author: obj.author ? Account.serialize(obj.author) : undefined,
      target: obj.target ? Account.serialize(obj.target) : undefined,
    }
  }

  public static deserialize(json: IInvitationJson): Invitation {
    const res = new Invitation()
    res.id = json.id
    res.ownerProfileId = json.owner_profile_id
    res.authorProfileId = json.author_profile_id
    res.targetProfileId = json.target_profile_id
    res.purpose = json.purpose
    res.revokedAt = json.revoked_at ? new Date(json.revoked_at) : undefined
    res.acceptedAt = json.accepted_at ? new Date(json.accepted_at) : undefined
    res.createdAt = new Date(json.created_at)
    res.updatedAt = new Date(json.updated_at)
    res.owner = json.owner ? Account.deserialize(json.owner) : undefined
    res.author = json.author ? Account.deserialize(json.author) : undefined
    res.target = json.target ? Account.deserialize(json.target) : undefined
    return res
  }

  public static serializeArray(objs: Invitation[]): IInvitationJson[] {
    const jsons = objs.map((p) => {
      return Invitation.serialize(p)!
    })
    return jsons
  }

  public static deserializeArray(json: IInvitationJson[]): Invitation[] {
    const res = json.map((p) => {
      return Invitation.deserialize(p)!
    })
    return res
  }
}
