import { Account } from './Account'

export type DocumentsQuery = {
  targetProfileId?: number
  doxIds?: number[]
  /**
   * filter by editedAt (from)
   *
   */
  from?: Date
  /**
   * filter by editedAt (to)
   *
   */
  to?: Date
  /**
   * filter by owner of the document
   *
   */
  owners?: Account[]
  /**
   * filter by author of the document
   *
   */
  authors?: Account[]
  /**
   * filter by document that is or have a draft
   *
   */
  onlyDrafts?: boolean
  /**
   * filter by document created by the current user
   *
   */
  createdByMe?: boolean
  /**
   * show also obsolete documents
   *
   */
  showObsolete?: boolean
  /**
   * the field name to sort by
   *
   */
  sortBy?: SortQuery

  pageIndex?: number
  pageSize?: number
}

export type SortQuery = {
  field: string
  order: Order
}

export type Order = 'asc' | 'desc'

export function invertOrder(order: Order) {
  if (order === 'desc') {
    return 'asc'
  } else {
    return 'desc'
  }
}
