import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function EuroIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 15,18.499999 c -2.51,0 -4.68,-1.42 -5.7600003,-3.499999 H 15 l 0.999999,-2 H 8.5799999 c -0.05,-0.33 -0.08,-0.66 -0.08,-1 0,-0.34 0.03,-0.67 0.08,-1 H 15 L 15.999999,8.9999997 H 9.2399997 C 10.32,6.9199998 12.5,5.4999999 15,5.4999999 c 1.609999,0 3.09,0.5899999 4.23,1.5699999 l 1.77,-1.77 C 19.41,3.8699998 17.299999,3 15,3 11.08,3 7.7599998,5.5099998 6.5199999,8.9999997 H 3 L 1.9999999,11 h 4.0599999 c -0.04,0.33 -0.06,0.66 -0.06,1 0,0.34 0.019999,0.67 0.06,1 H 3 l -1.0000001,2 h 4.52 C 7.7599998,18.489999 11.08,21 15,21 c 2.31,0 4.41,-0.87 6,-2.300001 l -1.780001,-1.77 C 18.09,17.91 16.62,18.499999 15,18.499999 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
