import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function StructureIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 6.0007324,1.7490234 V 2.9992675 H 4.5 V 20.100586 H 2.9992675 V 22.5 H 4.5 19.500732 20.999268 V 20.100586 H 19.500732 V 2.9992675 H 18 V 1.7490234 Z M 6.4951171,5.60083 H 17.505615 v 14.499756 h -2.504883 v -3.697998 h -2.001709 v 3.697998 H 6.4951171 Z M 9,7.4992675 v 1.9951172 h 1.999512 V 7.4992675 Z m 3.999023,0 v 1.9951172 h 2.001709 V 7.4992675 Z M 9,11.500488 v 1.995117 h 1.999512 v -1.995117 z m 3.999023,0 v 1.995117 h 2.001709 V 11.500488 Z M 9,15.899413 v 1.995119 h 1.999512 v -1.995119 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
