import { Box, List, ListItem, ListItemIcon, Stack, useTheme } from '@mui/material'
import { IAuthorizationsListProps } from './AuthorizationsList.types'
import { CheckIco, ClockIco, PauseIco, TermsConditionsIco } from '../icons'
import AccountIdentity from '../identities/AccountIdentity'
import { Authorization } from '../../models/Authorization'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import * as dalPermission from '../../dal/DalPermission'
import { useState } from 'react'
import { ISimpleDialogData } from '../../dialogs/simpleDialog/SimpleDialog.types'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'
import { dateShortOptions, Utils } from '../../shared/Utils'
import { useAuthContext } from '../../contexts/AuthContext'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router'

const iconSize = 24
const iconSizePx = `${iconSize}px`
const iconPadding = 8
const iconPaddingPx = `${iconPadding}px`
const iconBorderRadiusPx = `${(iconSize + iconPadding * 2) / 2}px`
const iconBoxStyle = {
  flexShrink: 0,
  borderRadius: iconBorderRadiusPx,
  width: iconSizePx,
  height: iconSizePx,
  padding: iconPaddingPx,
}

export function AuthorizationsList(props: IAuthorizationsListProps): JSX.Element {
  const { authorizations, reloadAuthorizations, onShowPrivacyPolicy, onClickAvatar } = props
  const { t, i18n } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const authContext = useAuthContext()
  const [isLoading, setIsLoading] = useState(false)

  // dialogs
  const [simpleDialogData, setSimpleDialogData] = useState<ISimpleDialogData>()
  const [simpleDialogOpen, setSimpleDialogOpen] = useState(false)

  const defaultViewDurationDays =
    authContext.loggedAccount?.profile?.profilePreferences?.permissions?.defaultViewDurationDays ?? 14

  function renewAuthorizationRequestConfirm(authorization: Authorization) {
    const state = authState(authorization)
    const expirationDate = authorization.permissionsIdsForVisualization
      ? authorization.permissionsIdsForVisualization[0]?.endAt
      : undefined

    setSimpleDialogOpen(true)
    setSimpleDialogData({
      title:
        state === 'allowed'
          ? t(msgIds.MSG_AUTHORIZATION_RENEWAL_CONFIRM_TITLE)
          : t(msgIds.MSG_AUTHORIZATION_RENEW_CONFIRM_TITLE),
      content:
        state === 'allowed'
          ? t(msgIds.MSG_AUTHORIZATION_RENEWAL_CONFIRM_BODY, {
              date: Utils.toLocaleDateString(expirationDate, i18n, dateShortOptions),
              days: defaultViewDurationDays,
            })
          : t(msgIds.MSG_AUTHORIZATION_RENEW_CONFIRM_BODY, { days: defaultViewDurationDays }),
      actionsStyle: 'yesNO',
      onClose: async (result2) => {
        setSimpleDialogOpen(false)
        if (result2.userChoice === 'yes') {
          renewExpiredAuthorization(authorization)
        }
      },
    })
  }

  async function renewExpiredAuthorization(authorization: Authorization) {
    try {
      setIsLoading(true)
      const permissionId = authorization.permissionsIdsForVisualization[0].id
      const abortController = new AbortController()
      await dalPermission.updatePermission(
        abortController.signal,
        permissionId,
        new Date(),
        Utils.daysFromToday(defaultViewDurationDays, false) as Date
      )
      if (reloadAuthorizations) {
        await reloadAuthorizations()
      }
      enqueueSnackbar(t(msgIds.MSG_OPERATION_EXECUTED_SUCCESSFULLY), { variant: 'success' })
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  function showPrivacyPolicyAndGrantConsents(authorization: Authorization) {
    if (onShowPrivacyPolicy && authorization.dstIdentity) {
      onShowPrivacyPolicy(authorization.dstIdentity)
    }
  }

  function reactivateDataProcessingRequestConfirm(authorization: Authorization) {
    setSimpleDialogOpen(true)
    setSimpleDialogData({
      title: t(msgIds.MSG_AUTHORIZATION_REACTIVATE_DATA_PROCESSING_CONFIRM_TITLE),
      content: t(msgIds.MSG_AUTHORIZATION_REACTIVATE_DATA_PROCESSING_CONFIRM_BODY),
      actionsStyle: 'yesNO',
      onClose: async (result2) => {
        setSimpleDialogOpen(false)
        if (result2.userChoice === 'yes') {
          reactviateDataProcessing(authorization)
        }
      },
    })
  }

  async function reactviateDataProcessing(authorization: Authorization) {
    const _permission = authorization.dataProcessingPermission
    if (!_permission) return

    try {
      setIsLoading(true)
      const abortController = new AbortController()
      await dalPermission.updatePermissionSuspensionState(abortController.signal, _permission.id, false)
      if (reloadAuthorizations) {
        await reloadAuthorizations()
      }
      enqueueSnackbar(t(msgIds.MSG_OPERATION_EXECUTED_SUCCESSFULLY), { variant: 'success' })
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <List>
      {simpleDialogData && <SimpleDialog {...simpleDialogData} isOpen={simpleDialogOpen}></SimpleDialog>}
      {authorizations.map((authorization, index) => (
        <ListItem
          key={index}
          secondaryAction={<Stack direction="row">{props.actionsFor(authorization)}</Stack>}
          disablePadding
        >
          <ListItemIcon title={t(authStateLocalizedKey(authorization)) || ''} sx={{ minWidth: 24 }}>
            {
              {
                allowed: (
                  <Box
                    sx={{
                      ...iconBoxStyle,
                      backgroundColor: theme.palette.success.main,
                    }}
                  >
                    <CheckIco
                      sx={{ cursor: 'pointer', color: theme.palette.background.paper }}
                      onClick={() => renewAuthorizationRequestConfirm(authorization)}
                    />
                  </Box>
                ),
                expired: (
                  <Box
                    sx={{
                      ...iconBoxStyle,
                      backgroundColor: theme.palette.error.main,
                    }}
                  >
                    <ClockIco
                      sx={{ cursor: 'pointer', color: theme.palette.background.paper }}
                      onClick={() => renewAuthorizationRequestConfirm(authorization)}
                    />
                  </Box>
                ),
                revoked: (
                  <Box
                    sx={{
                      ...iconBoxStyle,
                      backgroundColor: theme.palette.error.main,
                    }}
                  >
                    <TermsConditionsIco
                      sx={{ cursor: 'pointer', color: theme.palette.background.paper }}
                      onClick={() => showPrivacyPolicyAndGrantConsents(authorization)}
                    />
                  </Box>
                ),
                suspended: (
                  <Box
                    sx={{
                      ...iconBoxStyle,
                      backgroundColor: theme.palette.error.main,
                    }}
                  >
                    <PauseIco
                      sx={{ cursor: 'pointer', color: theme.palette.background.paper }}
                      onClick={() => reactivateDataProcessingRequestConfirm(authorization)}
                    />
                  </Box>
                ),
                unknown: undefined,
              }[authState(authorization)]
            }
          </ListItemIcon>

          <Box paddingY={1}>
            {authorization.dstIdentity && (
              <AccountIdentity
                account={authorization.dstIdentity}
                infoToShow={['main']}
                showProfileInfo={true}
                avatarClicked={() => {
                  onClickAvatar && authorization.dstIdentity && onClickAvatar(authorization.dstIdentity)
                }}
              />
            )}
          </Box>
        </ListItem>
      ))}
    </List>
  )
}

type AuthorizationState = 'allowed' | 'expired' | 'revoked' | 'suspended' | 'unknown'
function authState(authorization: Authorization): AuthorizationState {
  if (authorization.isVisualizationAllowed) {
    return 'allowed'
  } else if (authorization.isVisualizationExpired) {
    return 'expired'
  } else if (authorization.isVisualizationRevoked) {
    return 'revoked'
  } else if (authorization.isVisualizationSuspended) {
    return 'suspended'
  } else {
    return 'unknown'
  }
}

function authStateLocalizedKey(authorization: Authorization): string {
  if (authorization.isVisualizationAllowed) {
    return msgIds.MSG_AUTHORIZATIONS_VISUALIZATION_ALLOWED
  } else if (authorization.isVisualizationExpired) {
    return msgIds.MSG_AUTHORIZATIONS_VISUALIZATION_EXPIRED
  } else if (authorization.isVisualizationRevoked) {
    return msgIds.MSG_AUTHORIZATIONS_VISUALIZATION_REVOKED
  } else if (authorization.isVisualizationSuspended) {
    return msgIds.MSG_AUTHORIZATIONS_VISUALIZATION_SUSPENDED
  } else {
    return msgIds.MSG_AUTHORIZATIONS_VISUALIZATION_UNKNOWN
  }
}
