import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function VersionsIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 16.013475,6.4344752 v 0.5352901 12.0239107 0.557655 1.44642 H 12.00584 10.001771 3.7894549 C 2.7874188,20.997751 1.986,20.195806 1.986,19.193791 V 8.3725203 L 7.396883,3.00225 h 8.616592 z M 14.00991,4.9656901 H 8.278562 V 9.2541904 H 3.9900679 V 18.993676 H 14.00991 V 6.9697653 6.4344752 Z"
          style={{
            fill: props.color,
          }}
        />
        <path
          d="M 17.233237,4.5183427 V 6.150616 h 0.310618 v 1.2217683 0.4446118 9.9982819 h -0.310618 v 1.66638 h 1.977 V 18.279379 17.815278 7.8169961 7.3723843 4.5183427 Z"
          style={{
            fill: props.color,
          }}
        />
        <path
          d="m 20.43,6.0320827 v 1.3020209 h 0.24777 V 8.3086754 8.6633301 16.638692 H 20.43 v 1.329226 h 1.577 V 17.008893 16.638692 8.6633301 8.3086754 6.0320827 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
