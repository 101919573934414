import { ITreatmentListDialogProps } from './TreatmentListDialog.types'
import { Treatment } from '../../models/Treatment'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { StyledDialog } from '../styledDialog/StyledDialog'
import { ViewHeader } from '../../components/viewHeader/ViewHeader'
import TreatmentList from '../../components/treatmentList/TreatmentList'
import { Typography } from '@mui/material'
import { ViewContent } from '../../components/viewContent/ViewContent'

export default function TreatmentListDialog(props: ITreatmentListDialogProps) {
  const { t } = useTranslation()

  return (
    <StyledDialog open={props.isOpen} onClose={() => props.onClose({ userChoice: 'abort' })}>
      <ViewHeader
        exitButtonVisible={true}
        onClickExit={() => props.onClose({ userChoice: 'abort' })}
        title={t(msgIds.MSG_TREATED_CUSTOM_FIELD_TITLE)}
      />
      <ViewContent id="treatment-list" spacing={2} alignItems="stretch" padding={0}>
        {props.treatments.length ? (
          <TreatmentList
            treatments={props.treatments}
            onItemSelect={(treatment: Treatment) => props.onResult({ userChoice: 'yes', treatment: treatment })}
          />
        ) : (
          <Typography padding={4}>{t(msgIds.MSG_TREATMENT_NO_SELECTABLE_ITEMS)}</Typography>
        )}
      </ViewContent>
    </StyledDialog>
  )
}
