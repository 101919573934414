import { createContext, useContext } from 'react'
import { Document } from '../models/Document'
import { IDocumentEditorState } from '../components/documentEditor/DocumentEditor.types'

export interface IWorkingDocument {
  documentIID: string
  addedAt: Date
  documentEditorState: IDocumentEditorState
  isInDesktop: boolean
  currentRevisionName: string
}

export type WorkingDocumentsFilter = 'all' | 'desktop_not_saved' | 'archive_not_saved'

export interface IWorkingDocumentsContextState {
  getDocumentIIDs: (filter: WorkingDocumentsFilter) => string[]
  getWorkingDocument: (documentIID: string) => IWorkingDocument | undefined
  getDocumentEditorState: (documentIID: string) => IDocumentEditorState | undefined
  getCurrentDesktopDocumentEditorState: () => IDocumentEditorState | undefined
  isDocumentNotSaved: (documentIID: string) => boolean

  workingDocuments: IWorkingDocument[]
  currentDesktopDocumentIID: string // in desktop

  putInDesktop: (documentIID: string) => void

  addDocument: (document: Document, isInDesktop: boolean, editorState?: Partial<IDocumentEditorState>) => void
  removeDocument: (documentIID: string) => void
  removeAllDocuments: () => void
  updateDocumentEditorState: (documentIId: string, editorState?: Partial<IDocumentEditorState>) => void
  remapDocumentEditorState: (oldDocumentIID: string, newDocumentIID: string) => void
  saveDocument: (documentIID: string) => Promise<string>
  archiveDocument: (documentIID: string) => void

  setCurrentDesktopDocument: (documentIID: string) => void
}

export const WorkingDocumentsContext = createContext<IWorkingDocumentsContextState>({
  getDocumentIIDs: (filter: WorkingDocumentsFilter) => [],
  getWorkingDocument: (documentIID: string) => undefined,
  getDocumentEditorState: (documentIID: string) => undefined,
  getCurrentDesktopDocumentEditorState: () => undefined,
  isDocumentNotSaved: (documentIID: string) => false,

  workingDocuments: [],
  currentDesktopDocumentIID: '',

  putInDesktop: (documentIID: string) => {},

  addDocument: (document: Document, isInDesktop: boolean, editorState?: Partial<IDocumentEditorState>) => {},
  removeDocument: (documentIID: string) => {},
  removeAllDocuments: () => {},
  updateDocumentEditorState: (documentIID: string, editorState?: Partial<IDocumentEditorState>) => {},
  remapDocumentEditorState: (oldDocumentIID: string, newDocumentIID: string) => {},
  saveDocument: async (documentIID: string) => '',
  archiveDocument: (documentIID: string) => {},

  setCurrentDesktopDocument: (documentIID: string) => {},
})

export function useWorkingDocumentsContext() {
  return useContext(WorkingDocumentsContext)
}
