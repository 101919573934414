import { ConsentRevokeCause, ConsentRevokeCause_all } from '../shared/Constants'
import { HistoryContextConsentAction, IHistoryContextConsentActionJson } from './HistoryContextConsentAction'
import { HistoryContextContract, IHistoryContextContractJson } from './HistoryContextContract'
import { HistoryContextContractVersion, IHistoryContextContractVersionJson } from './HistoryContextContractVersion'

export interface IHistoryContextConsentJson {
  id: number
  section_id: number
  profile_id: number
  is_auto_generated: boolean
  section_read_at?: string
  revoked_at?: string
  revoke_cause: number
  updated_at: string
  created_at: string
  contract?: IHistoryContextContractJson
  version?: IHistoryContextContractVersionJson
  actions: IHistoryContextConsentActionJson[]
}

export class HistoryContextConsent {
  id: number = 0
  sectionId: number = 0
  profileId: number = 0
  isAutoGenerated: boolean = false
  sectionReadAt?: Date
  revokedAt?: Date
  revokeCause: ConsentRevokeCause_all = ConsentRevokeCause.none
  updatedAt: Date = new Date(0)
  createdAt: Date = new Date(0)
  contract?: HistoryContextContract
  version?: HistoryContextContractVersion
  actions: HistoryContextConsentAction[] = []

  constructor() {}

  public static serialize(obj: HistoryContextConsent): IHistoryContextConsentJson {
    return {
      id: obj.id,
      section_id: obj.sectionId,
      profile_id: obj.profileId,
      is_auto_generated: obj.isAutoGenerated,
      section_read_at: obj.sectionReadAt ? obj.sectionReadAt.toISOString() : undefined,
      revoked_at: obj.revokedAt?.toISOString(),
      revoke_cause: obj.revokeCause,
      updated_at: obj.updatedAt.toISOString(),
      created_at: obj.createdAt.toISOString(),
      contract: obj.contract ? HistoryContextContract.serialize(obj.contract) : undefined,
      version: obj.version ? HistoryContextContractVersion.serialize(obj.version) : undefined,
      actions: HistoryContextConsentAction.serializeArray(obj.actions),
    }
  }

  public static deserialize(json: IHistoryContextConsentJson): HistoryContextConsent {
    const res = new HistoryContextConsent()
    res.id = json.id
    res.sectionId = json.section_id
    res.profileId = json.profile_id
    res.isAutoGenerated = json.is_auto_generated
    res.sectionReadAt = json.section_read_at ? new Date(json.section_read_at) : undefined
    res.revokedAt = json.revoked_at ? new Date(json.revoked_at) : undefined
    res.revokeCause = json.revoke_cause
    res.updatedAt = new Date(json.updated_at)
    res.createdAt = new Date(json.created_at)
    res.contract = json.contract ? HistoryContextContract.deserialize(json.contract) : undefined
    res.version = json.version ? HistoryContextContractVersion.deserialize(json.version) : undefined
    res.actions = HistoryContextConsentAction.deserializeArray(json.actions)
    return res
  }
}
