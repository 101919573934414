import { ConsentRevokeCause, ConsentRevokeCause_all, ConsentStatus, ConsentStatus_all } from '../shared/Constants'
import { Utils } from '../shared/Utils'

export interface IConsentJson {
  id: number
  status: number
  profile_id: number
  section_id: number
  version_id: number
  contract_id: number
  is_auto_generated: boolean
  section_read_at?: string
  grantor_revoke_disabled: boolean
  revoke_cause: number
  revoked_at?: string
  shared_fields: string[]
  created_at: string
  updated_at: string
}

export class Consent {
  id: number = 0
  status: ConsentStatus_all = ConsentStatus.none
  profileId: number = 0
  sectionId: number = 0
  versionId: number = 0
  contractId: number = 0
  isAutoGenerated: boolean = false
  sectionReadAt?: Date
  isGrantorRevokeDisabled: boolean = false
  revokeCause: ConsentRevokeCause_all = ConsentRevokeCause.none
  revokedAt?: Date
  sharedFields: string[] = []
  createdAt: Date = new Date(0)
  updatedAt: Date = new Date(0)

  constructor() {}

  public static serialize(obj: Consent): IConsentJson {
    return {
      id: obj.id,
      status: obj.status,
      profile_id: obj.profileId,
      section_id: obj.sectionId,
      version_id: obj.versionId,
      contract_id: obj.contractId,
      is_auto_generated: obj.isAutoGenerated,
      section_read_at: obj.sectionReadAt ? (Utils.toDate(obj.sectionReadAt, true) as string) : undefined,
      grantor_revoke_disabled: obj.isGrantorRevokeDisabled,
      revoke_cause: obj.revokeCause,
      revoked_at: obj.revokedAt ? (Utils.toDate(obj.revokedAt, true) as string) : undefined,
      shared_fields: obj.sharedFields,
      created_at: obj.createdAt.toISOString(),
      updated_at: obj.updatedAt.toISOString(),
    }
  }

  public static deserialize(json: IConsentJson): Consent {
    const res = new Consent()
    res.id = json.id
    res.status = json.status
    res.profileId = json.profile_id
    res.sectionId = json.section_id
    res.versionId = json.version_id
    res.contractId = json.contract_id
    res.isAutoGenerated = json.is_auto_generated
    res.sectionReadAt = json.section_read_at ? new Date(json.section_read_at) : undefined
    res.isGrantorRevokeDisabled = json.grantor_revoke_disabled
    res.revokeCause = json.revoke_cause
    res.revokedAt = json.revoked_at ? new Date(json.revoked_at) : undefined
    res.sharedFields = json.shared_fields
    res.createdAt = new Date(json.created_at)
    res.updatedAt = new Date(json.updated_at)
    return res
  }

  public static serializeArray(objs: Consent[]): IConsentJson[] {
    const jsons = objs.map((p) => {
      return Consent.serialize(p)!
    })
    return jsons
  }

  public static deserializeArray(json: IConsentJson[]): Consent[] {
    const res = json.map((p) => {
      return Consent.deserialize(p)!
    })
    return res
  }
}
