export interface IPermissionActionEmailJson {
  sent: boolean
  address: string
}

export class PermissionActionEmail {
  sent: boolean = false
  address: string = ''

  constructor() {}

  public static serialize(obj: PermissionActionEmail): IPermissionActionEmailJson {
    return {
      sent: obj.sent,
      address: obj.address,
    }
  }

  public static deserialize(json: IPermissionActionEmailJson): PermissionActionEmail {
    const res = new PermissionActionEmail()
    res.sent = json.sent
    res.address = json.address
    return res
  }
}
