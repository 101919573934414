import {
  Alert,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import {
  ContractType,
  ContractVersionState,
  ContractVersionStateColorMap,
  ContractVersionStateTranslationMap,
  TreatedFieldName,
} from '../../shared/Constants'
import { dateTimeShortOptions, Utils } from '../../shared/Utils'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import { TreatmentsAccordion } from '../treatmentsAccordion/TreatmentsAccordion'
import MarkdownViewerNew from '../markdownEditorNew/MarkdownViewerNew'
import { ContractSectionAgreement } from '../contractSectionAgreement/ContractSectionAgreement'
import { IContractVersionViewerDataProps } from './ContractVersionViewerData.types'
import { useLayout } from '../../hooks/Layout'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'

export function ContractVersionViewerData(props: IContractVersionViewerDataProps): JSX.Element {
  const {
    consentsSnapshot,
    missingInformations,
    isNewAcknoledgmentRequired,
    acceptedSections,
    contractType,
    editableSections,
    editableSectionsFields,
    disablePadding,
    availableMobilePhoneFields,
    availableMailFields,
    onChangeFieldOption,
    onChange,
    isLoading,
    simpleDialogData,
    simpleDialogOpen,
    showMissingInfoFields,
    showVersionChanges,
  } = props
  const { t, i18n } = useTranslation()
  const { isMobile, isTablet, theme } = useLayout()

  function getPaddingX() {
    if (isMobile) {
      return 2
    } else if (isTablet) {
      return 8
    } else {
      return 16
    }
  }

  function getContractNotAvailableDsc() {
    if (consentsSnapshot) {
      return undefined
    }
    switch (contractType) {
      case ContractType.privacyPolicy:
        return t(msgIds.MSG_CONTRACT_PRIVACY_POLICY_NOT_AVAILABLE)
      case ContractType.termsOfUse:
        return t(msgIds.MSG_CONTRACT_TERMS_OF_USE_NOT_AVAILABLE)
      case ContractType.dataProcessor:
        return t(msgIds.MSG_CONTRACT_DATA_PROCESSOR_NOT_AVAILABLE)
      default:
        return t(msgIds.MSG_CONTRACT_NOT_AVAILABLE)
    }
  }

  if (!consentsSnapshot) {
    return (
      <Stack flexGrow={1} justifyContent="center" alignItems="center">
        {isLoading ? <CircularProgress /> : <Typography variant="h6">{getContractNotAvailableDsc()}</Typography>}
      </Stack>
    )
  }

  return (
    <Stack gap={4}>
      {simpleDialogData && <SimpleDialog {...simpleDialogData} isOpen={simpleDialogOpen}></SimpleDialog>}
      <Stack direction="row" alignItems="center" gap={4}>
        <Typography variant="h6">
          <strong>
            {t(msgIds.MSG_CONTRACT_VERSION)} {consentsSnapshot?.getVersionString()}
          </strong>
        </Typography>
        <Typography
          variant="h6"
          color={ContractVersionStateColorMap[consentsSnapshot?.state || ContractVersionState.none]}
        >
          <strong>{t(ContractVersionStateTranslationMap[consentsSnapshot?.state || ContractVersionState.none])}</strong>
        </Typography>
        {!!consentsSnapshot.consentsDate ? (
          <Typography>
            {t(msgIds.MSG_CONTRACT_VERSION_VIEWER_CONSENT_DATE)}:{' '}
            {consentsSnapshot && Utils.toLocaleDateString(consentsSnapshot.consentsDate, i18n, dateTimeShortOptions)}
          </Typography>
        ) : (
          <Typography>
            {t(msgIds.MSG_CONTRACT_DATE)}:{' '}
            {consentsSnapshot && Utils.toLocaleDateString(consentsSnapshot.publishedAt, i18n)}
          </Typography>
        )}
      </Stack>
      {isNewAcknoledgmentRequired && (
        <Alert severity="warning">{t(msgIds.MSG_CONTRACT_VERSION_VIEWER_NEW_ACKNOWLEDGMENT_REQUIRED)}</Alert>
      )}
      {consentsSnapshot?.notes && showVersionChanges && (
        <Paper elevation={0} sx={{ px: 2, pt: 2, pb: 3, backgroundColor: theme.palette.common.gray9 }}>
          <Stack spacing={1}>
            <Typography variant="body1" fontWeight="600">
              {t(msgIds.MSG_CONTRACT_VERSION_VIEWER_CHANGES)}
            </Typography>
            <Divider />
            <Typography>{consentsSnapshot.notes}</Typography>
          </Stack>
        </Paper>
      )}
      {consentsSnapshot?.sections.map((section) => (
        <Paper key={section.id} elevation={0} sx={{ px: disablePadding ? 0 : getPaddingX(), pt: 2, pb: 3 }}>
          <Stack>
            {contractType === ContractType.privacyPolicy && section.treatments.length > 0 && (
              <TreatmentsAccordion treatments={section.treatments} />
            )}
            <MarkdownViewerNew initialValue={section.text} />
            {!!acceptedSections[section.id] && (
              <ContractSectionAgreement
                contractType={contractType}
                consentMode={section.consentMode}
                isDataProcessingSection={section.isDataProcessingSection}
                areConsentsDisabled={!editableSections.find((p) => p.id === section.id)}
                agreement={acceptedSections[section.id].granted || false}
                onChangeAgreement={(value) => onChange(section, value)}
              />
            )}
            {showMissingInfoFields &&
              !!acceptedSections[section.id] &&
              acceptedSections[section.id].selectedMailOptionRequired &&
              acceptedSections[section.id].granted && (
                <FormControl fullWidth sx={{ marginTop: 2 }}>
                  <InputLabel id="field-mail-label">{t(msgIds.MSG_TREATED_FIELDS_EMAIL)}</InputLabel>
                  <Select
                    labelId="field-mail-label"
                    label={t(msgIds.MSG_TREATED_FIELDS_EMAIL)}
                    disabled={!editableSectionsFields.find((p) => p === section)}
                    value={acceptedSections[section.id].selectedMailOption?.name || ''}
                    onChange={(event) => {
                      const selectedField = availableMailFields.find((p) => p.name === event.target.value)
                      selectedField && onChangeFieldOption(section, TreatedFieldName.email, selectedField)
                    }}
                  >
                    {availableMailFields.map((field) => (
                      <MenuItem key={field.name} value={field.name}>
                        {field.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

            {showMissingInfoFields &&
              !!acceptedSections[section.id] &&
              acceptedSections[section.id].selectedMobilePhoneOptionRequired &&
              acceptedSections[section.id].granted && (
                <FormControl fullWidth sx={{ marginTop: 2 }}>
                  <InputLabel id="field-mibile_phone-label">{t(msgIds.MSG_TREATED_FIELDS_MOBILE_PHONE)}</InputLabel>
                  <Select
                    labelId="field-mibile_phone-label"
                    label={t(msgIds.MSG_TREATED_FIELDS_MOBILE_PHONE)}
                    disabled={!editableSectionsFields.find((p) => p === section)}
                    value={acceptedSections[section.id].selectedMobilePhoneOption?.name || ''}
                    onChange={(event) => {
                      const selectedField = availableMobilePhoneFields.find((p) => p.name === event.target.value)
                      selectedField && onChangeFieldOption(section, TreatedFieldName.mobilePhone, selectedField)
                    }}
                  >
                    {availableMobilePhoneFields.map((field) => (
                      <MenuItem key={field.name} value={field.name}>
                        {field.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
          </Stack>
        </Paper>
      ))}

      {missingInformations && (
        <Paper elevation={0} sx={{ px: disablePadding ? 0 : 2, pt: 2, pb: 3 }}>
          <Typography variant="h6" color={theme.palette.error.main} sx={{ marginBottom: 2 }}>
            {t(msgIds.MSG_CONSENT_MISSING_INFORMATIONS_TITLE)}
          </Typography>
          <Stack direction={'row'}>
            <Typography variant="body1">{t(msgIds.MSG_NECESSARY)}:</Typography>
            <Typography variant="body1" color={theme.palette.common.gray4} sx={{ marginLeft: 1 }}>
              {missingInformations.mandatoryFieldsNamesLocalized}
            </Typography>
          </Stack>
          <Stack direction={'row'}>
            <Typography variant="body1">{t(msgIds.MSG_OPTIONAL)}:</Typography>
            <Typography variant="body1" color={theme.palette.common.gray4} sx={{ marginLeft: 1 }}>
              {missingInformations.optionalFieldsNamesLocalized}
            </Typography>
          </Stack>
          <ViewActionsButton
            defaultAction
            onClick={() =>
              props.onAddMissingInfo(missingInformations.mandatoryFieldsNames, missingInformations.optionalFieldsNames)
            }
            sx={{ marginTop: 2 }}
          >
            {t(msgIds.MSG_CONTRACT_VERSION_ADD_MISSING_INFO_BUTTON)}
          </ViewActionsButton>
        </Paper>
      )}
    </Stack>
  )
}
