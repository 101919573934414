import { Account } from '../../models/Account'
import { PageContainer, PageContent } from '../../components/pageContainer/PageContainer'
import CommandBar from '../../components/commandBar/CommandBar'
import { Paper, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import msgIds from '../../locales/msgIds'
import * as dalAccount from '../../dal/DalAccount'
import * as dalPermission from '../../dal/DalPermission'
import { useEffect, useState } from 'react'
import { Utils } from '../../shared/Utils'
import { useAuthContext } from '../../contexts/AuthContext'
import { AccountsDataManagementForm } from '../../components/accountsDataManagement/AccountsDataManagementForm'
import { AccountsViewManagementForm } from '../../components/accountsViewManagement/AccountsViewManagementForm'
import { BooleanPermissionsManagementForm } from '../../components/booleanPermissionsManagement/BooleanPermissionsManagementForm'
import { ActionType } from '../../shared/Constants'
import { Permission } from '../../models/Permission'
import { IAccountAuthorizationsPageProps } from './AccountAuthorizationsPage.types'
import AccountLoadingIndicator from '../../components/loadingIndicators/AccountLoadingIndicator'

export default function AccountAuthorizationsPage() {
  const location = useLocation()
  const state = location.state as IAccountAuthorizationsPageProps
  const profileId: number = state.profileId

  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const authContext = useAuthContext()

  const [account, setAccount] = useState<Account>()
  // This collection contains base permissions for collaborator.
  // Do not contains list of permissions for specific customer o treatment
  const [permissions, setPermissions] = useState<Permission[]>()

  const structureProfileId = authContext.linkedStructureProfileId

  useEffect(() => {
    async function LoadAccountDetails(abortSignal: AbortSignal) {
      try {
        setIsLoading(true)
        const data = await dalAccount.getAccountFromProfileId(abortSignal, profileId, true, false)
        setAccount(data)
        const permissions = await dalPermission.getCollaboratorPermissions(abortSignal, profileId)
        setPermissions(permissions)
      } catch (err) {
        Utils.enqueueSnackbarError2(err, t)
      } finally {
        setIsLoading(false)
      }
    }

    setAccount(undefined)
    setPermissions(undefined)
    const abortController = new AbortController()
    if (profileId && profileId !== authContext.loggedProfileId) {
      LoadAccountDetails(abortController.signal)
    } else {
      setAccount(authContext.loggedAccount)
    }

    return () => {
      abortController.abort()
    }
  }, [profileId, authContext.loggedAccount, authContext.loggedProfileId])

  function pageTitle(): string {
    if (isLoading) {
      return t(msgIds.MSG_LOADING_IN_PROGRESS)
    }
    return t(msgIds.MSG_ACCOUNT_AUTHORIZATIONS_PAGE_TITLE, { identity: account?.getIdentityInverse() })
  }

  return (
    <PageContainer>
      <CommandBar style={{ minHeight: 'auto' }} title={t(pageTitle())} commands={[]} />

      {isLoading ? (
        <AccountLoadingIndicator account={account} isLoading={isLoading} />
      ) : (
        <PageContent>
          {account && (
            <Stack spacing={8} width="100%" maxWidth="md" alignItems="stretch">
              {structureProfileId && profileId && (
                <Paper>
                  <AccountsDataManagementForm
                    profileId={profileId}
                    structureProfileId={structureProfileId}
                    permissions={permissions}
                  />
                </Paper>
              )}
              {structureProfileId && profileId && (
                <Paper>
                  <AccountsViewManagementForm
                    profileId={profileId}
                    structureProfileId={structureProfileId}
                    permissions={permissions}
                  />
                </Paper>
              )}
              {structureProfileId && profileId && (
                <Paper>
                  <BooleanPermissionsManagementForm
                    title={msgIds.MSG_PRIVACY_MANAGEMENT_TITLE}
                    profileId={profileId}
                    structureProfileId={structureProfileId}
                    permissions={permissions}
                    actionsLabels={[
                      {
                        action: ActionType.viewPrivacyPlant,
                        label: msgIds.MSG_PRIVACY_MANAGEMENT_VIEW_LABEL,
                        onIfOthersOn: [ActionType.updatePrivacyPlant],
                      },
                      {
                        action: ActionType.updatePrivacyPlant,
                        label: msgIds.MSG_PRIVACY_MANAGEMENT_EDIT_LABEL,
                        offIfOthersOff: [ActionType.viewPrivacyPlant],
                      },
                    ]}
                  />
                </Paper>
              )}
              {structureProfileId && profileId && (
                <Paper>
                  <BooleanPermissionsManagementForm
                    title={msgIds.MSG_INVITATIONS_MANAGEMENT_TITLE}
                    profileId={profileId}
                    structureProfileId={structureProfileId}
                    permissions={permissions}
                    actionsLabels={[
                      { action: ActionType.createInvitations, label: msgIds.MSG_INVITATIONS_MANAGEMENT_ENABLED_LABEL },
                    ]}
                  />
                </Paper>
              )}
              {structureProfileId && profileId && (
                <Paper>
                  <BooleanPermissionsManagementForm
                    title={msgIds.MSG_COLLABORATORS_MANAGEMENT_TITLE}
                    profileId={profileId}
                    structureProfileId={structureProfileId}
                    permissions={permissions}
                    actionsLabels={[
                      {
                        action: ActionType.viewOperatorPermissions,
                        label: msgIds.MSG_COLLABORATORS_MANAGEMENT_VIEW_AUTH_LABEL,
                        onIfOthersOn: [
                          ActionType.createOperatorPermissions,
                          ActionType.deleteOperatorPermissions,
                          ActionType.updateCollaboratorProfiles,
                        ],
                      },
                      {
                        action: ActionType.createOperatorPermissions,
                        label: msgIds.MSG_COLLABORATORS_MANAGEMENT_ADD_AUTH_LABEL,
                        offIfOthersOff: [ActionType.viewOperatorPermissions],
                      },
                      {
                        action: ActionType.deleteOperatorPermissions,
                        label: msgIds.MSG_COLLABORATORS_MANAGEMENT_REMOVE_AUTH_LABEL,
                        offIfOthersOff: [ActionType.viewOperatorPermissions],
                      },
                      {
                        action: ActionType.updateCollaboratorProfiles,
                        label: msgIds.MSG_COLLABORATORS_MANAGEMENT_EDIT_PROFILE_LABEL,
                        offIfOthersOff: [ActionType.viewOperatorPermissions],
                      },
                    ]}
                  />
                </Paper>
              )}
            </Stack>
          )}
        </PageContent>
      )}
    </PageContainer>
  )
}
