import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function NewReleasesIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 8.5997319,1.5596895 -1.8413086,3.12012 -3.5991214,0.8789 0.3208009,3.68043 -2.4807131,2.7201995 2.4807131,2.79933 -0.3208009,3.68042 3.5991214,0.8811 1.8413086,3.12012 3.4409181,-1.44141 3.359619,1.44141 1.92041,-3.20142 3.599121,-0.7998 -0.320801,-3.68042 2.401611,-2.79933 -2.401611,-2.7201995 0.320801,-3.68043 -3.599121,-0.8789 -1.92041,-3.12012 -3.359619,1.43921 z m 0.8789063,2.47851 2.5620118,1.12061 2.559814,-1.12061 1.439208,2.40162 2.720216,0.63941 -0.239502,2.79931 1.839111,2.0807995 -1.839111,2.15993 0.239502,2.8015 -2.720216,0.55811 -1.439208,2.48071 -2.559814,-1.1206 -2.5620118,1.1206 -1.439209,-2.48071 -2.720215,-0.55811 0.239502,-2.8015 -1.8391114,-2.15993 1.8391114,-2.0807995 -0.239502,-2.79931 2.720215,-0.56031 z m 6.4511708,4.23194 -5.618407,5.6403895 -1.8544923,-1.8545 -1.4062501,1.40186 3.2761234,3.27612 0.672363,-0.67457 6.350097,-6.3698695 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
