import React, { useEffect, useMemo, useRef, useState } from 'react'
import { ArchiveDoxes } from '../shared/types/ArchiveDoxes'
import { ArchiveTypes } from '../models/ArchiveTypes'
import { Dox } from '../models/Dox'
import { DoxTemplate } from '../models/DoxTemplate'
import { Treatment } from '../models/Treatment'

export type ArchiveDoxesAcl = 'rw' | 'ro'

export interface IArchiveContextState {
  archiveType: ArchiveTypes
  rwArchiveDoxes: ArchiveDoxes
  roArchiveDoxes: ArchiveDoxes
  getLatestRwArchiveDoxes: () => ArchiveDoxes
  getLatestRoArchiveDoxes: () => ArchiveDoxes
  getArchiveDoxesFromAcl: (acl: ArchiveDoxesAcl) => ArchiveDoxes
  setArchiveType: (archiveType: ArchiveTypes) => void
  setRWArchiveDoxes: (archiveDoxes: ArchiveDoxes) => void
  setROArchiveDoxes: (archiveDoxes: ArchiveDoxes) => void
  // manipulation actions
  addDox: (dox: Dox, isRW: boolean) => void
  removeDox: (dox: Dox, isRW: boolean) => void
  replaceDox: (doxId: number, newDox: Dox, isRW: boolean) => void
  moveDox: (dox: Dox, newParentId: number, isRW: boolean) => void
  createOrganizedDoxFromTemplate: (
    parentDox: Dox,
    doxTemplate: DoxTemplate,
    treatments: Treatment[],
    isRW: boolean
  ) => Promise<void>
}

const initialState: IArchiveContextState = {
  archiveType: ArchiveTypes.none,
  rwArchiveDoxes: new ArchiveDoxes(),
  roArchiveDoxes: new ArchiveDoxes(),
  getLatestRwArchiveDoxes: () => {
    return new ArchiveDoxes()
  },
  getLatestRoArchiveDoxes: () => {
    return new ArchiveDoxes()
  },
  getArchiveDoxesFromAcl: (acl: ArchiveDoxesAcl) => {
    return new ArchiveDoxes()
  },
  setArchiveType: (archiveType: ArchiveTypes) => {},
  setRWArchiveDoxes: (archiveDoxes: ArchiveDoxes) => {},
  setROArchiveDoxes: (archiveDoxes: ArchiveDoxes) => {},
  // manipulation actions
  addDox: () => {},
  removeDox: () => {},
  replaceDox: () => {},
  moveDox: () => {},
  createOrganizedDoxFromTemplate: async () => {},
}

interface IProps {
  children?: React.ReactNode
}

const ArchiveContext = React.createContext<IArchiveContextState>(initialState)
export const ArchiveContextProvider: React.FC<IProps> = ({ children }) => {
  const [archiveType, setArchiveType] = useState<ArchiveTypes>(ArchiveTypes.none)
  const [rwArchiveDoxes, setRWArchiveDoxes] = useState<ArchiveDoxes>(new ArchiveDoxes())
  const [roArchiveDoxes, setROArchiveDoxes] = useState<ArchiveDoxes>(new ArchiveDoxes())

  const rwArchiveDoxesRef = useRef(rwArchiveDoxes)
  const roArchiveDoxesRef = useRef(roArchiveDoxes)

  useEffect(() => {
    rwArchiveDoxesRef.current = rwArchiveDoxes
  }, [rwArchiveDoxes])

  useEffect(() => {
    roArchiveDoxesRef.current = roArchiveDoxes
  }, [roArchiveDoxes])

  function getArchiveDoxesFromAcl(acl: ArchiveDoxesAcl) {
    return acl === 'rw' ? rwArchiveDoxes : roArchiveDoxes
  }

  // for callbacks to avoid closure property freeze (es. on dialogs return)
  const getLatestRwArchiveDoxes = () => rwArchiveDoxesRef.current
  const getLatestRoArchiveDoxes = () => roArchiveDoxesRef.current

  const addDox = (dox: Dox, isRW: boolean) => {
    if (isRW) {
      const rwArchiveDoxesCopy = rwArchiveDoxes.getCopy()
      rwArchiveDoxesCopy.addDox(dox)
      setRWArchiveDoxes(rwArchiveDoxesCopy)
    } else {
      const roArchiveDoxesCopy = roArchiveDoxes.getCopy()
      roArchiveDoxesCopy.addDox(dox)
      setROArchiveDoxes(roArchiveDoxesCopy)
    }
  }

  const removeDox = (dox: Dox, isRW: boolean) => {
    if (isRW) {
      const rwArchiveDoxesCopy = rwArchiveDoxes.getCopy()
      rwArchiveDoxesCopy.removeDox(dox)
      setRWArchiveDoxes(rwArchiveDoxesCopy.getCopy())
    } else {
      const roArchiveDoxesCopy = roArchiveDoxes.getCopy()
      roArchiveDoxesCopy.removeDox(dox)
      setROArchiveDoxes(roArchiveDoxesCopy.getCopy())
    }
  }

  const replaceDox = (doxId: number, newDox: Dox, isRW: boolean) => {
    if (isRW) {
      const rwArchiveDoxesCopy = rwArchiveDoxes.getCopy()
      rwArchiveDoxesCopy.replaceDox(doxId, newDox)
      setRWArchiveDoxes(rwArchiveDoxesCopy.getCopy())
    } else {
      const roArchiveDoxesCopy = roArchiveDoxes.getCopy()
      roArchiveDoxesCopy.replaceDox(doxId, newDox)
      setROArchiveDoxes(roArchiveDoxesCopy.getCopy())
    }
  }

  const moveDox = (dox: Dox, newParentId: number, isRW: boolean) => {
    if (isRW) {
      const rwArchiveDoxesCopy = rwArchiveDoxes.getCopy()
      rwArchiveDoxesCopy.moveDox(dox, newParentId)
      setRWArchiveDoxes(rwArchiveDoxesCopy.getCopy())
    } else {
      const roArchiveDoxesCopy = roArchiveDoxes.getCopy()
      roArchiveDoxesCopy.moveDox(dox, newParentId)
      setROArchiveDoxes(roArchiveDoxesCopy.getCopy())
    }
  }

  const createOrganizedDoxFromTemplate = async (
    parentDox: Dox,
    doxTemplate: DoxTemplate,
    treatments: Treatment[],
    isRW: boolean
  ) => {
    if (isRW) {
      await rwArchiveDoxes.createOrganizedDoxFromTemplate(parentDox, doxTemplate, treatments)
      setRWArchiveDoxes(rwArchiveDoxes.getCopy())
    } else {
      await roArchiveDoxes.createOrganizedDoxFromTemplate(parentDox, doxTemplate, treatments)
      setROArchiveDoxes(roArchiveDoxes.getCopy())
    }
  }

  const contextValue = useMemo<IArchiveContextState>(
    () => ({
      archiveType,
      rwArchiveDoxes,
      roArchiveDoxes,
      getLatestRwArchiveDoxes,
      getLatestRoArchiveDoxes,
      getArchiveDoxesFromAcl,
      setArchiveType,
      setRWArchiveDoxes,
      setROArchiveDoxes,
      addDox,
      removeDox,
      replaceDox,
      moveDox,
      createOrganizedDoxFromTemplate,
    }),
    [
      archiveType,
      rwArchiveDoxes,
      roArchiveDoxes,
      getLatestRwArchiveDoxes,
      getLatestRoArchiveDoxes,
      getArchiveDoxesFromAcl,
      setArchiveType,
      setRWArchiveDoxes,
      setROArchiveDoxes,
      addDox,
      removeDox,
      replaceDox,
      moveDox,
      createOrganizedDoxFromTemplate,
    ]
  )

  return <ArchiveContext.Provider value={contextValue}>{children}</ArchiveContext.Provider>
}

export const useArchiveContext = (): IArchiveContextState => {
  const context = React.useContext(ArchiveContext)
  if (!context) {
    throw new Error('useArchiveContext must be used inside ArchiveContextProvider')
  }
  return context
}
