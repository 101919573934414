import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material'

export const InfoTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 380,
    fontSize: theme.typography.pxToRem(12),
  },
}))
