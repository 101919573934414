import { useEffect, useState } from 'react'
import { CommuneOption, mapCommuneToOption } from '../shared/types/Commune'
import { Utils } from '../shared/Utils'
import { useTranslation } from 'react-i18next'

export function useCommuneOptions(): CommuneOption[] {
  const { t } = useTranslation()
  const [communeOptions, setCommuneOptions] = useState<CommuneOption[]>([])

  useEffect(() => {
    import('../assets/collections/communes.json')
      .then(({ default: communes }) => {
        const options = communes.map(mapCommuneToOption)
        setCommuneOptions(options)
      })
      .catch(Utils.enqueueSnackbarError(t))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return communeOptions
}
