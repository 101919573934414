import { useTranslation } from 'react-i18next'
import { IAuthorizationInheritance } from './AuthorizationInheritance.types'
import { Dox } from '../../models/Dox'
import { Authorization } from '../../models/Authorization'
import { Divider, IconButton, List, ListItem, ListItemText, Stack, Typography } from '@mui/material'
import { AuthorizationIco } from '../icons'
import msgIds from '../../locales/msgIds'
import { useArchiveContext } from '../../contexts/ArchiveContext'

export function AuthorizationInheritance(props: IAuthorizationInheritance) {
  const archiveContext = useArchiveContext()
  const { element, authorization } = props
  const { t } = useTranslation()

  function inheritance(authorization: Authorization): Dox[] {
    const elementId = element instanceof Dox ? element.id : element.documentId
    const inheritedFromDoxIds = authorization.getDoxIdsFromWhichIsInherited(elementId)
    const inheritedFromDox = inheritedFromDoxIds
      .map((p) => archiveContext.rwArchiveDoxes.getDox(p))
      .filter((p) => !!p) as Dox[]
    return inheritedFromDox
  }

  function onParentAuth(dox: Dox) {
    props.onShowDoxAuthorizations(dox.id)
  }

  return (
    <Stack spacing={1}>
      <Typography>{t(msgIds.MSG_AUTHORIZATION_DETAILS_DIALOG_MESSAGE)}</Typography>
      <Divider />
      <List>
        {inheritance(authorization).map((dox) => (
          <ListItem
            key={dox.id}
            secondaryAction={
              <IconButton sx={{ marginLeft: '10px' }} onClick={() => onParentAuth(dox)}>
                <AuthorizationIco />
              </IconButton>
            }
            disablePadding
          >
            <ListItemText primaryTypographyProps={{ style: { paddingRight: '60px' } }}>
              {dox.completeRoute || dox.name}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Stack>
  )
}
