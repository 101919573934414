import { iconProps, Tutorial } from '../contexts/TutorialContext'
import {
  ArchiveIco,
  CloseIco,
  DiaryStructureIco,
  DocExternalIco,
  DocInternalIco,
  DoxAddIco,
  DoxIco,
  EditIco,
  MoreVertIco,
  NewReleasesIco,
  PrivacyCustomerIco,
  PrivacyIco,
  PrivacyNewIco,
  RetentionIco,
  SaveIco,
  SectionAddIco,
  TermsConditionsIco,
  TreatmentIco,
  UserIco,
} from '../components/icons'

export const initOperatorTutorials: Tutorial[] = [
  {
    id: 'interact-with-a-customer',
    icon: <PrivacyIco />,
    title: 'Interagire con un Utente privato',
    steps: [
      {
        markdown: `
**Termini**
  - **Utente**: tutte le persone fisiche quando trattate come privati cittadini. Il GDPR li chiama **Interessati al trattamento dei dati**.
  - **Struttura**: qualsiasi azienda, organizzazione o studio professionale.

Come prima cosa occorre istruire la piattaforma su come la tua Struttura tratterà i dati personali degli Utenti, configurando:
  - **le attività di trattamento**: affinchè PersonalDOX sappia quali dati personali indendi utilizzare e come conservarli
  - **l'informativa privacy**: affinchè tu possa adempiere all'obbligo di informare gli Utenti su come tratterai i dati personali che loro ti affideranno

Questa fase è molto importante perchè PersonalDOX agirà in base a queste istruzioni. Se ritieni di non avere le competenze necessarie, rivolgiti ad un esperto in materia di Privacy che ti aiuterà a predisporre tutte le informazioni necessarie.
        `,
      },
      {
        markdown: `
Dopo aver effettuato le necessarie configurazioni, con PersonalDOX potrai operare in questi 3 scenari:
  - **Utente non digitalizzato**: quando il tuo Utente non utilizza strumenti digitali
  - **Utente digitalizzato non registrato**: quando il tuo Utente utilizza strumenti digitali e internet ma non è registrato in PersonalDOX
  - **Utente digitalizzato e registrato**: quando il tuo Utente utilizza PersonalDOX con un suo account personale

Nei prossimi passaggi spiegheremo con più dettaglio i tre scenari.
        `,
      },
      {
        markdown: `
**Utente non digitalizzato**
Il tuo Utente non utilizza nè internet nè strumenti digitali, non ha la possibilità di interagire direttamente con te attraverso PersonalDOX.

Tu però, dopo averlo censito nella tua anagrafica aziendale, puoi comunque raccogliere, organizzare e conservare i suoi dati e documenti personali.
Tutte le funzionalità di PersonalDOX saranno disponibili ma non ti sarà possibile inviargli documenti nè riceverne direttamente da lui.
In questo modo PersonalDOX potrà comunque supportarti nella gestione del tuo archivio e nell'adempiere agli obblighi normativi sulla privacy.
        `,
      },
      {
        markdown: `
**Utente digitalizzato non registrato**
Il tuo Utente utilizza internet e strumenti digitali ma non ha un proprio account in PersonalDOX.

In questa situazione per ricevere dei documenti o per consentirgli di scaricare dei documenti, puoi dargli accesso temporaneo ad alcune aree del tuo archivio aziendale.
PersonalDOX invia una mail contenente un collegamento con l'area che hai condiviso. La condivisione può inoltre essere protetta con un codice di sicurezza.
        `,
      },
      {
        markdown: `
**Utente digitalizzato e registrato**
Il tuo Utente ha un proprio account in PersonalDOX tramite il quale gestisce i propri dati e documenti.

Questa è la situazione nella quale entrambi avrete i maggiori vantaggi da PersonalDOX in quanto:
  - i dati del tuo Utente compariranno autmaticamente nella tua anagrafica aziendale
  - l'Utente potrà condividere direttamente con la tua struttura i documenti necessari
  - l'Utente potrà ricevere da te i documenti che desideri consegnare, direttamente nell'area riservata del suo account
        `,
      },
    ],
    stepIndex: 0,
  },
  {
    id: 'configure-data-treatments',
    icon: <TreatmentIco />,
    title: 'Configura le attività di trattamento',
    steps: [
      {
        markdown: `
Ne menu laterale espandi l'area **Modello Organizzativo Privacy** e premi su **Arrività di trattamento**.
        `,
        icon: <TreatmentIco sx={iconProps} />,
      },
      {
        markdown: `
Nella barra dei comandi in alto premi sul pulsante **Nuovo trattamento**.
        `,
        icon: <PrivacyNewIco sx={iconProps} />,
      },
      {
        markdown: `
La pagina è suddivisa in due aree:
  - **Caratteristiche di base**: consente di documentare alcuni aspetti giuridici del trattamento ma anche di indicare a PersonalDOX quali informazioni dell'Utente devi trattare
  - **Conservazione dei dati**: istruisce PersonalDOX su come deve supportarti nella manutenzione periodica dell'archivio
        `,
      },
    ],
    stepIndex: 0,
  },
  {
    id: 'create-privacy-policy',
    icon: <TermsConditionsIco />,
    title: "Crea l'informativa privacy",
    steps: [
      {
        markdown: `
Ne menu laterale espandi l'area **Modello Organizzativo Privacy** e premi su **Informative privacy**.
        `,
        icon: <PrivacyCustomerIco sx={iconProps} />,
      },
      {
        markdown: `
Nella barra dei comandi in alto premi sul pulsante **Nuovo contratto** oppure premi sulla riga dell'informativa sulla quale vuoi agire.
        `,
        icon: <PrivacyNewIco sx={iconProps} />,
      },
      {
        markdown: `
Nella barra dei comandi in alto premi sul pulsante **Nuova versione** oppure, se è già presente una versione in bozza, premi sulla riga della versione che vuoi modificare.
        `,
        icon: <PrivacyNewIco sx={iconProps} />,
      },
      {
        markdown: `
Modifica il testo della prima sezione dell'informativa. Se necessario, tramite il pulsante **Aggiungi sezioni**, puoi aggiungere altre sezioni funzionali alla raccolta dei consensi esplicitii.
        `,
        icon: <SectionAddIco sx={iconProps} />,
      },
      {
        markdown: `
Ogni sezione, quando è in modalità **scrittura**, mostra il pulsante **Aggiungi trattamenti dei dati**. Premendolo si apre l'elenco delle attività di trattamento che hai configurato in precedenza.
**NOTA**: se non sai ancora come configurare le attività di trattamento, guarda il tutorial **Configura le attività di trattamento**.

Associare le attività di trattamento alle sezioni dell'informativa è importante perchè, nel momento in cui l'Utente prende visione dell'informativa o rilascia dei consensi, si innescano i meccanismi che permettono a PersonalDOX di supportarti nelle attività di trattamento dei dati personali.
        `,
      },
      {
        markdown: `
Dopo che hai creato le sezioni dell'informativa, associandovi anche le attività di trattamento, provvedi al loro salvataggio premendo il pulsante **Salva**.

Se ritieni che l'informativa privacy sia pronta per essere presentata ai tuoi Utenti, rendila ufficiale premendo nella barra dei comandi in alto il comando **Pubblica**.
`,
        icon: <NewReleasesIco sx={iconProps} />,
      },
      {
        markdown: `
Ogni volta che desideri modificare un'informativa privacy già pubblicata è necessario che crei una nuova bozza del documento.
Utilizza i campi contenuti nell'area **Informazioni** per descrivere agli Utenti la natura delle modifiche e per istruire PersonalDOX in merito alla necessità di richiedere una nuova presa vizione e/o dei nuovi consensi.

Ogni volta che pubblichi una nuova versione dell'informativa privacy, tutti gli Utenti con i quali hai un trattamento di dati personali in corso riceveranno una notifica che li informerà.
`,
      },
    ],
    stepIndex: 0,
  },
  {
    id: 'select-assisted-user',
    icon: <UserIco />,
    title: "Seleziona l'utente attivo",
    steps: [
      {
        markdown: `
Quando desideri accedere ai dati di un Utente devi innantizutto selezionarlo.

Nella barra in alto, al centro, premi sul pulsante per la ricerca dei tuoi utenti.
          `,
        icon: <UserIco sx={iconProps} />,
      },
      {
        markdown: `
Nella finestra che compare in alto hai un campo di ricerca. Puoi scriverci il **nome** o il **cognome** dell'Utente da selezionare. È sufficiente anche solo una parte. Poi premi invio.

Se desideri vedere rapiramente i suoi dati per accertarti che sia l'Utente corretto premi sull'**avatar**.
Quando hai trovato l'utente premi sulla riga corrispondente.
`,
      },
      {
        markdown: `
Ora l'Utente selezionato compare in basso nella barra laterale. Se espandi la sezione **Gestione utente** potrai accedere agli archivi che lo riguardano.

Per de-selezionare l'Utente senza selezionarne un altro premi sul pulante posto alla destra del nome.
        `,
        icon: <CloseIco sx={iconProps} />,
      },
    ],
    stepIndex: 0,
  },
  {
    id: 'write-a-new-document',
    icon: <DocInternalIco />,
    title: 'Scrivi un nuovo documento',
    steps: [
      {
        markdown: `
**Premessa**
Questo tutorial richiede che tu abbia già selezionato l'Utente i cui dati vuoi utilizzare. Se non sai come fare consulta il tutorial **Seleziona l'utente attivo**. 

Puoi scrivere un documento in due modi:
  - dall'**archivio aziendale**, consigliato per lavori veloci
  - dalla **scrivania**, se contemporaneamente ti serve consultare anche altri documenti
  
Lo strumento per scrivere il documento è lo stesso perciò in questò tutorial vedremo come scrivere un documento dall'archivio.

Nell'area comandi a sinistra, all'interno della sezione **Gestione utente**, premi **Archivio aziendale**.
  `,
        icon: <DiaryStructureIco sx={iconProps} />,
      },
      {
        markdown: `Nella barra dei comandi in alto premi sul comando **Scrivi documento**`,
        icon: <DocInternalIco sx={iconProps} />,
      },
      {
        markdown: `
Inserisci il **Titolo**, la **Data** e il testo del documento.

Puoi associare il documento a uno o più **Dox** del tuo archivio premendo sull'apposito pulsante.
**NOTA1**: se il documento contiene dei dati personali è necessario che tutti i Dox ai quali è associato siano abbinati ad una attività di trattamento.
Se non hai ancora configurato le attività di trattamento consulta il tutorial **Configura le attività di trattamento**.

**NOTA2**: se ritieni che il documento non contenga dati personali puoi attivare l'opzione **Non contiene dati personali**. Così facendo PersonalDOX non applicherà al documento alcuni controlli connessi agli obblighi della privacy.
`,
        icon: <DoxIco sx={iconProps} />,
      },
      {
        markdown: `
Per memorizzare il contenuto del documento è necessario effettuare il salvataggio. Il documento non viene salvato nel dispositivo ma nello spazio cloud di PersonalDOX.

Per procedere con il salvataggio del documento premere il comando **Salva** nella barra degli strumenti.
`,
        icon: <SaveIco sx={iconProps} />,
      },
      {
        markdown: `
Ogni documento, finanto che è in bozza, può essere modificato e salvato a piacimento. Un documento in bozza può essere visionato solamente dal suo autore.

Per rendere il documento accessibile ad altre persone, è necessario procedre con l'**archiviazione** che rende immutabile l'attuale bozza.

Per procedere con l'archiviazione premere il comando **Archivia** nella barra degli strumenti.
`,
        icon: <ArchiveIco sx={iconProps} />,
      },
      {
        markdown: `
Qualora sia necessario modificare il documento, è necessario creare una revisione in bozza.
La bozza può quindi essere modificata e nuovamente archiviata.
**ATTENZIONE**: ogni documento mantiene sempre tutte le sue versioni archiviate.

Per creare una nuova versione del documento in bozza premere il comando **Crea bozza** nella barra degli strumenti.
`,
        icon: <EditIco sx={iconProps} />,
      },
    ],
    stepIndex: 0,
  },
  {
    id: 'load-a-new-document',
    icon: <DocExternalIco />,
    title: 'Carica un nuovo documento',
    steps: [
      {
        markdown: `
**Premessa**
Questo tutorial richiede che tu abbia già selezionato l'Utente i cui dati vuoi utilizzare. Se non sai come fare consulta il tutorial **Seleziona l'utente attivo**. 

Puoi caricare un documento in due modi:
  - dall'**archivio aziendale**, consigliato per lavori veloci
  - dalla **scrivania**, se contemporaneamente ti serve consultare anche altri documenti
  
Lo strumento per caricare il documento è lo stesso perciò in questò tutorial vedremo come scrivere un documento dall'archivio.

Nell'area comandi a sinistra, all'interno della sezione **Gestione utente**, premi **Archivio aziendale**.
  `,
        icon: <DiaryStructureIco sx={iconProps} />,
      },
      {
        markdown: `Nella barra dei comandi in alto premi sul comando **Carica documento**`,
        icon: <DocExternalIco sx={iconProps} />,
      },
      {
        markdown: `
Inserisci il **Titolo**, la **Data** e l'**Autore**.
Puoi trascinare un file nel riquadro o usare il comando per la ricerca di un file presente nel tuo dispositivo.
Se non hai ancora impostato il titolo del documento, verrà valorizzato direttamente con il nome del file che hai selezionato.

Puoi associare il documento a uno o più **Dox** del tuo archivio premendo sull'apposito pulsante.
**NOTA1**: se il documento contiene dei dati personali è necessario che tutti i Dox ai quali è associato siano abbinati ad una attività di trattamento.
Se non hai ancora configurato le attività di trattamento consulta il tutorial **Configura le attività di trattamento**.

**NOTA2**: se ritieni che il documento non contenga dati personali puoi attivare l'opzione **Non contiene dati personali**. Così facendo PersonalDOX non applicherà al documento alcuni controlli connessi agli obblighi della privacy.
`,
        icon: <DoxIco sx={iconProps} />,
      },
      {
        markdown: `
Per memorizzare il contenuto del documento è necessario effettuare il salvataggio. Il documento non viene salvato nel dispositivo ma nello spazio cloud di PersonalDOX.

Per procedere con il salvataggio del documento premere il comando **Salva** nella barra degli strumenti.
`,
        icon: <SaveIco sx={iconProps} />,
      },
      {
        markdown: `
Ogni documento, finanto che è in bozza, può essere modificato e salvato a piacimento. Un documento in bozza può essere visionato solamente dal suo autore.

Per rendere il documento accessibile ad altre persone, è necessario procedre con l'**archiviazione** che rende immutabile l'attuale bozza.

Per procedere con l'archiviazione premere il comando **Archivia** nella barra degli strumenti.
`,
        icon: <ArchiveIco sx={iconProps} />,
      },
      {
        markdown: `
Qualora sia necessario modificare il documento, è necessario creare una revisione in bozza.
La bozza può quindi essere modificata e nuovamente archiviata.
**ATTENZIONE**: ogni documento mantiene sempre tutte le sue versioni archiviate.

Per creare una nuova versione del documento in bozza premere il comando **Crea bozza** nella barra degli strumenti.
`,
        icon: <EditIco sx={iconProps} />,
      },
    ],
    stepIndex: 0,
  },
  {
    id: 'organize-structure-archive',
    icon: <DiaryStructureIco />,
    title: "Organizza l'archivio aziendale",
    steps: [
      {
        markdown: `
**Premessa**
Questo tutorial richiede che tu abbia già selezionato l'Utente i cui dati vuoi utilizzare. Se non sai come fare consulta il tutorial **Seleziona l'utente attivo**. 

L'archivio è lo spazio dove organizzi e conservi i documenti trattati dalla tua Struttura. Per ciascun Utente hai un archivio separato.

Nell'area comandi a sinistra, all'interno della sezione **Gestione utente**, premi **Archivio aziendale**.
  `,
        icon: <DiaryStructureIco sx={iconProps} />,
      },
      {
        markdown: `
Per organizzare un archivio si utilizzano i Dox. Ogni Dox può avere al suo interno un numero qualsiasi di altri Dox.
Puoi aggiungere, modificare, cancellare o spostare tutti i tuoi Dox tranne quello che sta alla radice, che ha nome **ARCHIVIO AZIENDALE**.

Ogni Dox ha un pulsante per accedere al menu delle funzioni. Ora proviamo ad aggiungere un nuovo Dox.

Premi il pulsante Comandi che si trova alla destra del Dox che conterrà il nuovo Dox.
  `,
        icon: <MoreVertIco sx={iconProps} />,
      },
      {
        markdown: `
Premi il pulsante **Nuovo** per creare il nuovo Dox.
  `,
        icon: <DoxAddIco sx={iconProps} />,
      },
      {
        markdown: `
Nella finestra che si apre puoi:
  - dare un nome al Dox (obbligatorio)
  - scrivere delle note, ad esempio per indicare cosa contiene (facoltativo)
  - associare il Dox ad una attività di trattamento di dati personali (facoltativo)

**NOTA**: se associ il Dox ad una attività di trattamento dati, tutti i documenti associati ad esso e ai Dox annidati vengono trattati dai PersonalDOX secondo le regole che hai impostato per detta attività di trattamento dati.

Premi **Salva** per confermare
  `,
      },
      {
        markdown: `
Gli archivi di PersonalDOX hanno una caratteristica importante che li rende differenti dai classici sistemi per l'archiviazione documentale basati su cartelle.

**Ogni documento può essere associato contemporaneamente a più Dox**.

Per questo motivo non avrai mai bisogno di creare più copie dello stesso documento. In PersonalDOX, infatti, non è disponibile il classico comando di Copia/Incolla dei documenti.
  `,
      },
    ],
    stepIndex: 0,
  },
  {
    id: 'documents-retention',
    icon: <TreatmentIco />,
    title: 'La conservazione dei documenti',
    steps: [
      {
        markdown: `
Tutti i documenti che entrano nel tuo archivio aziendale, se contengono dati personali, devono essere conservati con delle finalità precise. 
Per questo motivo, quando organizzi tali documenti nel tuo archivio, devi abbinarli a Dox che siano associati ad una attività di trattamento.

Se non sai come PersonalDOX ti consente di configurare le attività di trattamento della tua Struttura consulta il tutorial **Configura le attività di trattamento**.

Ogni Dox associato ad una attività di trattamento è affiancato da questo simbolo:
          `,
        icon: <RetentionIco sx={iconProps} />,
      },
      {
        markdown: `
Quando crei o modifichi un Dox, se non è all'interno di un Dox già associato ad una attività di trattamento, puoi decidere le regole che PersonalDOX applicherà a tutti i documenti in esso contenuti.
Le regole vengono automaticamente applicate a tutti i Dox annidati.

Le opzioni disponibili sono:
  - **Specifica per ciascuno**: ogni documento ha il suo specifico periodo di conservazione. Puoi modificare il periodo di conserazione nella scheda **Conservazione** del documento, visualizzabile premendo sulla riga del documento stesso
  - **Uguale per tutti**: tutti i documenti sono conservati per lo stesso periodo di tempo. Puoi modificare il periodo di conserazione nelle impostazioni del Dox
          `,
      },
      {
        markdown: `
Al termine del periodo di conservazione PersonalDOX applica il comportamento che hai impostato nell'**Attività di trattamento** associata al Dox stesso.

**NOTA**: se nel Dox la data di fine conservazione non è impostata, i documenti sono conservati a tempo indeterminato.
Usa questa modalità di conservazione deve essere utilizzata solo quando è legittima. Puoi utilizzare il campo **Note** del Dox per tenere traccia dei motivi di questa scelta.
        `,
      },
      {
        markdown: `
Puoi interrompere la conservazione dei documenti manualmente.

Se il Dox è impostato con la modalità **Uguale per tutti** utilizza il comando **Interrompi conservazione** dal menu **Comandi** del Dox.

Se il Dox è impostato ocn la modalità **Specifica per ciascuno** usa il comando presente nella scheda **Conservazione** del documento, visualizzabile premendo sulla riga del documento stesso.
        `,
      },
      {
        markdown: `
Quando si conclude la conservazione di un documento, questo viene trattato nel seguente modo:

- se è conservato anche per altre finalità continua a essere visibile all'interno dell'archivio
- se è di proprietà della tua Struttura e non lo hai mai consegnato all'Utente, viene cancellato. Questa operazione è irreversibile
- se non è di proprietà della tua Struttura viene rimosso dal tuo archivio ma continua ad essere visibile al legittimo proprietario
        `,
      },
    ],
    stepIndex: 0,
  },
]
