import { IPermissionActionEmailJson, PermissionActionEmail } from './PermissionActionEmail'
import { IPermissionActionOtpJson, PermissionActionOtp } from './PermissionActionOtp'
import { IPermissionActionTokenJson, PermissionActionToken } from './PermissionActionToken'

export interface IPermissionCommandJson {
  email_action?: IPermissionActionEmailJson
  pin_action?: IPermissionActionOtpJson
  token_action?: IPermissionActionTokenJson
}

export class PermissionCommand {
  emailAction?: PermissionActionEmail
  pinAction?: PermissionActionOtp
  tokenAction?: PermissionActionToken

  constructor() {}

  public static serialize(obj: PermissionCommand): IPermissionCommandJson {
    return {
      email_action: obj.emailAction ? PermissionActionEmail.serialize(obj.emailAction) : undefined,
      pin_action: obj.pinAction ? PermissionActionOtp.serialize(obj.pinAction) : undefined,
      token_action: obj.tokenAction ? PermissionActionToken.serialize(obj.tokenAction) : undefined,
    }
  }

  public static deserialize(json: IPermissionCommandJson): PermissionCommand {
    const res = new PermissionCommand()
    res.emailAction = json.email_action ? PermissionActionEmail.deserialize(json.email_action) : undefined
    res.pinAction = json.pin_action ? PermissionActionOtp.deserialize(json.pin_action) : undefined
    res.tokenAction = json.token_action ? PermissionActionToken.deserialize(json.token_action) : undefined
    return res
  }

  public static serializeArray(objs: PermissionCommand[]): IPermissionCommandJson[] {
    const jsons = objs.map((p) => {
      return PermissionCommand.serialize(p)!
    })
    return jsons
  }

  public static deserializeArray(json: IPermissionCommandJson[]): PermissionCommand[] {
    const res = json.map((p) => {
      return PermissionCommand.deserialize(p)!
    })
    return res
  }
}
