import { IconButton, Stack, useTheme } from '@mui/material'
import { IDownload, useDocumentCacheContext } from '../../contexts/DocumentCacheContext'
import { CircularProgressWithLabel } from '../circularProgress/CircularProgressWithLabel'
import { CloseIco } from '../icons'
import { IDownloaderSnackbarActionProps } from './DownloaderSnackbarAction.types'
import { useEffect, useRef } from 'react'

export function DownloaderSnackbarAction(props: IDownloaderSnackbarActionProps): JSX.Element {
  const theme = useTheme()
  const documentCacheContext = useDocumentCacheContext()
  const download: IDownload | undefined = documentCacheContext.downloadMap[props.downloadId]
  const progress = download?.data ? 100 : download?.progress
  const timerRef = useRef<any>()

  useEffect(() => {
    if (!download.data || timerRef.current) {
      return
    }
    timerRef.current = setTimeout(props.onClose, 2000)
    return () => {
      clearTimeout(timerRef.current)
      timerRef.current = undefined
    }
  }, [download?.data, props.onClose])

  return (
    <Stack direction="row">
      {!download?.data && <CircularProgressWithLabel label={progress} value={progress} size={24} />}
      <IconButton onClick={props.onClose}>
        <CloseIco style={{ color: theme.palette.background.paper }} />
      </IconButton>
    </Stack>
  )
}
