import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function ArrowDownIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 19.999999,12 -1.41,-1.41 L 13,16.17 V 3.9999999 H 11 V 16.17 L 5.4199998,10.58 3.9999999,12 12,19.999999 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
