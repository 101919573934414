import { IDisplayFieldProps } from './DisplayField.types'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'

export default function DisplayField(props: IDisplayFieldProps) {
  const { label, value, variant = 'body2' } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box display="flex" sx={{ flexDirection: { xs: 'column', md: 'row' }, my: 0 }} gap={isMobile ? 0 : 1}>
      <Typography color="grey" variant={variant}>
        {label}:
      </Typography>
      <Typography variant={variant}>{value || '\u00A0'}</Typography>
    </Box>
  )
}
