import { TreatedFieldsAcl } from '../../contexts/AccountEditorContext'
import { IIdentityArgs, identityArgLabelMsgId } from '../../shared/IdentityArgs'
import msgIds from '../../locales/msgIds'
import { UpdateUserArgs } from '../../dal/DalAccount'
import { Gender, ProfileType } from '../../shared/Constants'
import { IRegistrationSummarySectionField } from '../registrationSummary/RegistrationSummary.types'
import { CommuneOption, communeOptionByValue } from '../../shared/types/Commune'
import { countryOptionByKey, countryOptionByValue } from '../../shared/types/Country'

export function summarySectionFromIdentity(
  data: IIdentityArgs,
  acl: TreatedFieldsAcl,
  profileType: ProfileType,
  communeOptions: CommuneOption[],
  t: any
): IRegistrationSummarySectionField[] {
  return Object.entries(acl).reduce((fields, [key, acl]) => {
    if (acl !== 'editable' || !Object.hasOwn(data, key)) {
      return fields
    }
    const value = data[key as keyof IIdentityArgs]
    const keyMsgId = identityArgLabelMsgId(key as keyof IIdentityArgs, profileType)
    if (key === 'birthplace' && typeof value === 'string') {
      const birthplaceCommune = communeOptionByValue(communeOptions, value)
      const birthCountry: string | undefined = birthplaceCommune
        ? countryOptionByKey('IT')?.label
        : countryOptionByValue(value)?.label
      const fieldCountry: IRegistrationSummarySectionField = {
        key: t(msgIds.MSG_PROFILE_EDITOR_IDENTITY_BIRTH_COUNTRY),
        value: birthCountry,
      }
      fields.push(fieldCountry)
      if (birthplaceCommune) {
        const fieldCommune: IRegistrationSummarySectionField = {
          key: t(msgIds.MSG_PROFILE_EDITOR_IDENTITY_BIRTH_CITY),
          value: birthplaceCommune.label,
        }
        fields.push(fieldCommune)
      }
    } else if (key === 'gender' && value && typeof value !== 'string') {
      const field: IRegistrationSummarySectionField = {
        key: keyMsgId ? t(keyMsgId) : key,
        value: value === Gender.male ? 'M' : 'F',
      }
      fields.push(field)
    } else {
      const field: IRegistrationSummarySectionField = {
        key: keyMsgId ? t(keyMsgId) : key,
        value: `${value}`,
      }
      fields.push(field)
    }
    return fields
  }, [] as IRegistrationSummarySectionField[])
}

export function summarySectionFromBilling(data: UpdateUserArgs, t: any): IRegistrationSummarySectionField[] {
  const fields: IRegistrationSummarySectionField[] = []
  fields.push({
    key: t(msgIds.MSG_PROFILE_EDITOR_BILLING_SDI),
    value: data.sdiCode,
  })
  fields.push({
    key: t(msgIds.MSG_PROFILE_EDITOR_BILLING_PEC),
    value: data.pec,
  })
  fields.push({
    key: t(msgIds.MSG_TREATED_FIELDS_COUNTRY),
    value: data.country,
  })
  fields.push({
    key: t(msgIds.MSG_TREATED_FIELDS_PROVINCE),
    value: data.province,
  })
  fields.push({
    key: t(msgIds.MSG_TREATED_FIELDS_CITY),
    value: data.city,
  })
  fields.push({
    key: t(msgIds.MSG_TREATED_FIELDS_STREET),
    value: data.street,
  })
  fields.push({
    key: t(msgIds.MSG_TREATED_FIELDS_STREET_NUMBER),
    value: data.streetNumber,
  })
  fields.push({
    key: t(msgIds.MSG_TREATED_FIELDS_ZIP),
    value: data.zip,
  })
  return fields
}
