import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function ChronologyIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 13.007812,3.0014632 A 9.0054999,9.0054999 0 0 0 4.0012207,12.005858 H 0.99975586 l 3.89355464,3.893554 0.070312,0.138429 4.0407715,-4.031983 H 6.0029296 a 7.0372899,7.0372899 0 1 1 2.0610353,4.943848 L 6.6445312,18.369139 A 9.0024699,9.0024699 0 1 0 13.007812,3.0014632 Z M 12.005859,8.004637 v 5.003174 l 4.251709,2.520264 0.77124,-1.281006 -3.522216,-2.0896 V 8.004637 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
