import provinces from '../../assets/collections/provinces.json'

export type ProvinceOption = { label: string; value: string }

export const provinceOptions: ProvinceOption[] = provinces.map((c) => {
  return { label: c.fullName, value: c.initials }
})

export function provinceOptionByLabel(label: string | undefined | null): ProvinceOption | undefined {
  if (!label) {
    return undefined
  }
  return provinceOptions.find((c) => c.label === label)
}

export function provinceOptionByValue(value: string | undefined | null): ProvinceOption | undefined {
  if (!value) {
    return undefined
  }
  return provinceOptions.find((c) => c.value === value)
}
