import { useEffect, useState } from 'react'
import { ITreatmentBaseEditorProps } from './TreatmentBaseEditor.types'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import _ from 'lodash'
import msgIds from '../../locales/msgIds'
import {
  CustomerVisibilityRules,
  CustomerVisibilityRulesTranslationMap,
  TreatedFields,
  TreatedFieldsTranslationMap,
  TreatmentLegalBase,
  TreatmentLegalBaseTranslationMap,
} from '../../shared/Constants'
import * as dalTreatment from '../../dal/DalTreatment'
import { Utils, ValType } from '../../shared/Utils'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { UpdateTreatmentArgs } from '../../dal/DalTreatment'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import { EditorFormContainer } from '../editorFormContainer/EditorFormContainer'
import { ISimpleDialogData } from '../../dialogs/simpleDialog/SimpleDialog.types'
import { ITreatmentCustomFieldDialogData } from '../../dialogs/treatmentCustomFieldDialog/TreatmentCustomFieldDialog.types'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'
import TreatmentCustomFieldDialog from '../../dialogs/treatmentCustomFieldDialog/TreatmentCustomFieldDialog'
import { TreatedDataTypes } from '../../models/TreatedDataTypes'
import { TreatedField } from '../../models/TreatedField'
import { DeleteIco, EditIco, InfoIco } from '../icons'

export default function TreatmentBaseEditor(props: ITreatmentBaseEditorProps) {
  const { treatment, disabled, isEditMode, setIsLoading } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const [errors, setErrors] = useState<any>({})
  const [args, setArgs] = useState<UpdateTreatmentArgs>({})
  const [areUnsavedChanges, setAreUnsavedChanges] = useState(false)

  // dialogs
  const [simpleDialogData, setSimpleDialogData] = useState<ISimpleDialogData>()
  const [simpleDialogOpen, setSimpleDialogOpen] = useState(false)
  const [treatmentCustomFieldDialogData, setTreatmentCustomFieldDialogData] =
    useState<ITreatmentCustomFieldDialogData>()
  const [treatmentCustomFieldDialogOpen, setTreatmentCustomFieldDialogOpen] = useState(false)

  const legalBases = Utils.enumToArray(TreatmentLegalBase, t, TreatmentLegalBaseTranslationMap)
  const selectedGroups: string[] = []
  const partiallySelectedGroups: string[] = []
  TreatedFields.forEach(({ group, fields }) => {
    const selectedGroupFields = fields.filter(
      (groupField) => !!args.dataTypes?.fields.find((field) => field.fieldName === groupField)
    )
    if (selectedGroupFields.length === fields.length) {
      selectedGroups.push(group)
    } else if (selectedGroupFields.length > 0) {
      partiallySelectedGroups.push(group)
    }
  })
  const selectedTreatedDataTypes = args?.dataTypes?.fields.map((field, i) => field.fieldName) || []
  const isMandatory = (fieldName: string) => {
    return args?.dataTypes?.fields.find((field) => field.fieldName === fieldName)?.mandatory || false
  }
  const customerVisibilityRules = Utils.enumToArray(
    CustomerVisibilityRules,
    t,
    CustomerVisibilityRulesTranslationMap
  ).filter((p) => p.value !== '0')

  function initFields() {
    setArgs({
      legalBase: treatment.legalBase,
      code: treatment.code,
      shortDescription: treatment.shortDescription,
      description: treatment.description,
      customerVisibilityRules: treatment.customerVisibilityRules,
      dataTypes: treatment.dataTypes,
    })
  }

  useEffect(() => {
    initFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treatment])

  useEffect(() => {
    const check =
      args?.legalBase !== treatment?.legalBase ||
      args?.code !== treatment?.code ||
      args?.shortDescription !== treatment?.shortDescription ||
      args?.description !== treatment?.description ||
      args?.customerVisibilityRules !== treatment?.customerVisibilityRules ||
      JSON.stringify(args?.dataTypes) !== JSON.stringify(treatment?.dataTypes)
    setAreUnsavedChanges(check)
  }, [args])

  const handleSelectTreatedFieldsGroup = (group: string) => {
    const updatedArgs = _.cloneDeep(args) ?? {}

    const groupFields = TreatedFields.find((item) => item.group === group)?.fields || []
    const selectedGroupFields = groupFields.filter(
      (groupField) => !!args.dataTypes?.fields.find((field) => field.fieldName === groupField)
    )
    const unSelectedGroupFields = groupFields.filter(
      (groupField) => !args.dataTypes?.fields.find((field) => field.fieldName === groupField)
    )

    updatedArgs.dataTypes = updatedArgs.dataTypes || new TreatedDataTypes()
    updatedArgs.dataTypes.fields = updatedArgs.dataTypes.fields || []
    if (selectedGroupFields.length === 0) {
      unSelectedGroupFields.forEach((field) => {
        const fieldToAdd = new TreatedField()
        fieldToAdd.fieldName = field
        updatedArgs.dataTypes?.fields.push(fieldToAdd)
      })
    } else {
      updatedArgs.dataTypes.fields =
        updatedArgs.dataTypes?.fields.filter((field) => selectedGroupFields.indexOf(field.fieldName) === -1) || []
    }
    setArgs(updatedArgs)
  }

  const handleSelectTreatedDataType = (fieldName: string) => {
    const updatedArgs = _.cloneDeep(args)
    updatedArgs.dataTypes = updatedArgs.dataTypes || new TreatedDataTypes()

    if (selectedTreatedDataTypes.indexOf(fieldName) === -1) {
      const newField = new TreatedField()
      newField.fieldName = fieldName
      newField.description = ''
      updatedArgs.dataTypes.fields.push(newField)
    } else {
      updatedArgs.dataTypes.fields = updatedArgs.dataTypes.fields.filter((field) => field.fieldName !== fieldName)
    }
    setArgs(updatedArgs)
  }

  const handleSelectTreatedDataTypeMandatory = (fieldName: string, mandatory: boolean) => {
    const updatedArgs = _.cloneDeep(args)
    updatedArgs.dataTypes = updatedArgs.dataTypes || new TreatedDataTypes()

    updatedArgs.dataTypes.fields = updatedArgs.dataTypes.fields.map((field) => {
      if (field.fieldName === fieldName) {
        field.mandatory = mandatory
      }
      return field
    })
    setArgs(updatedArgs)
  }

  function addCustomField() {
    setTreatmentCustomFieldDialogOpen(true)
    setTreatmentCustomFieldDialogData({
      customFields: args.dataTypes?.customFields,
      customField: undefined,
      onResult: async (result) => {
        setTreatmentCustomFieldDialogOpen(false)
        if (result.userChoice !== 'yes') return
        if (result.customField) {
          const updatedValue = _.cloneDeep(args)
          updatedValue.dataTypes = updatedValue.dataTypes || new TreatedDataTypes()
          updatedValue.dataTypes.customFields.push(result.customField)
          setArgs(updatedValue)
        }
      },
    })
  }

  async function updateCustomField(customField: TreatedField) {
    setTreatmentCustomFieldDialogOpen(true)
    setTreatmentCustomFieldDialogData({
      customFields: args.dataTypes?.customFields,
      customField: customField,
      onResult: async (result) => {
        setTreatmentCustomFieldDialogOpen(false)
        if (result.userChoice !== 'yes') return
        if (result.customField) {
          const updatedValue = _.cloneDeep(args)
          const prv = updatedValue.dataTypes?.customFields.find((p) => p.fieldName === customField.fieldName)
          Object.assign(prv!, result.customField)
          setArgs(updatedValue)
        }
      },
    })
  }

  const removeCustomField = (fieldName: string) => {
    setSimpleDialogOpen(true)
    setSimpleDialogData({
      title: t(msgIds.MSG_TREATMENT_DELETE_CUSTOM_FIELD_TITLE),
      content: t(msgIds.MSG_TREATMENT_DELETE_CUSTOM_FIELD_TEXT),
      actionsStyle: 'yesNO',
      onClose: async (result) => {
        setSimpleDialogOpen(false)
        if (result.userChoice !== 'yes') return
        const updatedValue = _.cloneDeep(args)
        updatedValue.dataTypes = updatedValue.dataTypes || new TreatedDataTypes()
        updatedValue.dataTypes.customFields =
          updatedValue.dataTypes.customFields.filter((field) => field.fieldName !== fieldName) || []
        setArgs(updatedValue)
      },
    })
  }

  function validateData(val: UpdateTreatmentArgs) {
    const errors = {
      code: Utils.validateText(val.code || '', [{ type: ValType.notNull }, { type: ValType.notEmpty }]),
      shortDescription: Utils.validateText(val.shortDescription || '', [
        { type: ValType.notNull },
        { type: ValType.notEmpty },
      ]),
    }
    if (Object.values(errors).find((e) => !!e)) {
      return errors
    } else {
      return null
    }
  }

  async function onSave() {
    const treatmentId = treatment?.id
    if (!treatmentId || !args) return

    const errors = validateData(args)
    setErrors(errors || {})
    if (errors) {
      enqueueSnackbar(t(msgIds.MSG_VAL_ERR_THERE_ARE_FORM_ERRORS), { variant: 'error' })
      return
    }

    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const updatedTreatment = await dalTreatment.updateTreatment(abortController.signal, treatmentId, args)
      treatment.legalBase = updatedTreatment.legalBase
      treatment.code = updatedTreatment.code
      treatment.shortDescription = updatedTreatment.shortDescription
      treatment.description = updatedTreatment.description
      treatment.customerVisibilityRules = updatedTreatment.customerVisibilityRules
      treatment.dataTypes = updatedTreatment.dataTypes
      setAreUnsavedChanges(false)
      props.onSave && props.onSave()
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  function onCancel() {
    initFields()
    setErrors({})
    props.onCancel && props.onCancel()
  }

  return (
    <EditorFormContainer>
      {simpleDialogData && <SimpleDialog {...simpleDialogData} isOpen={simpleDialogOpen}></SimpleDialog>}
      {treatmentCustomFieldDialogData && (
        <TreatmentCustomFieldDialog
          {...treatmentCustomFieldDialogData}
          isOpen={treatmentCustomFieldDialogOpen}
          onClose={() => setTreatmentCustomFieldDialogOpen(false)}
        />
      )}

      <Stack>
        <Typography sx={{ py: 1 }} variant="body2">
          {t(msgIds.MSG_TREATMENT_INTRO)}
        </Typography>
        <Typography sx={{ py: 1 }} variant="body2">
          {t(msgIds.MSG_TREATMENT_ATTENTION_WARNING)}
        </Typography>
        <Typography sx={{ py: 1 }} variant="body2">
          <strong>{t(msgIds.MSG_TREATMENT_ATTENTION)}</strong>
          &nbsp;
          {t(msgIds.MSG_TREATMENT_PRIVACY_EXPERT_RECOMMENDATION)}
        </Typography>
      </Stack>

      <TextField
        error={!!errors.code}
        helperText={t(errors.code)}
        disabled={disabled || !isEditMode}
        label={t(msgIds.MSG_TREATMENT_INTERNAL_CODE)}
        variant="outlined"
        value={args.code || ''}
        onChange={(event) => setArgs({ ...args, code: event.target.value })}
      />

      <TextField
        error={!!errors.shortDescription}
        helperText={t(errors.shortDescription)}
        disabled={disabled || !isEditMode}
        label={t(msgIds.MSG_TREATMENT_SHORT_DESCRIPTION)}
        variant="outlined"
        value={args.shortDescription || ''}
        onChange={(event) => setArgs({ ...args, shortDescription: event.target.value })}
      />

      <TextField
        error={!!errors.description}
        helperText={t(errors.description)}
        disabled={disabled || !isEditMode}
        label={t(msgIds.MSG_TREATMENT_PURPOSE)}
        variant="outlined"
        multiline
        rows={4}
        value={args.description || ''}
        onChange={(event) => setArgs({ ...args, description: event.target.value })}
      />

      <Divider sx={{ my: 2 }} />

      <Stack>
        <Typography variant="body1">
          <strong>{t(msgIds.MSG_TREATMENT_LEGAL_BASES_TITLE)}</strong>
        </Typography>
        <Typography variant="body2" mt={2}>
          <strong>{t(msgIds.MSG_TREATMENT_ATTENTION)}</strong>
          &nbsp;
          {t(msgIds.MSG_TREATMENT_LEGAL_BASE_DESCRIPTION)}
        </Typography>
      </Stack>

      <FormControl sx={{ mt: 4 }}>
        <InputLabel id="legal-base-label">{t(msgIds.MSG_TREATMENT_LEGAL_BASES_TITLE)}</InputLabel>
        <Select
          disabled={disabled || !isEditMode}
          labelId="legal-base-label"
          id="legal-base-select"
          value={args.legalBase ? legalBases.find((lb) => lb.value === args.legalBase?.toString())?.value : '0'}
          label={t(msgIds.MSG_TREATMENT_LEGAL_BASES_TITLE)}
          onChange={(event) => {
            setArgs({ ...args, legalBase: parseInt(event.target.value) as TreatmentLegalBase })
          }}
        >
          <MenuItem value={''}></MenuItem>
          {legalBases.map((item) => (
            <MenuItem key={item.value} value={item.value} selected={item.value === args.legalBase?.toString()}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Divider sx={{ my: 2 }} />

      <Stack>
        <Typography variant="body1" mb={1}>
          <strong>{t(msgIds.MSG_TREATED_FIELDS_TEXT1)}</strong>
        </Typography>

        {TreatedFields.map((treatedFieldsGroup) => (
          <Box key={`group-${treatedFieldsGroup.group}`}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => handleSelectTreatedFieldsGroup(treatedFieldsGroup.group)}
                  checked={selectedGroups.indexOf(treatedFieldsGroup.group) !== -1 || false}
                  indeterminate={partiallySelectedGroups.indexOf(treatedFieldsGroup.group) !== -1 || false}
                  disabled={disabled || !isEditMode}
                />
              }
              label={<strong>{t(TreatedFieldsTranslationMap.groups[treatedFieldsGroup.group]).toString()}</strong>}
            />
            {treatedFieldsGroup.fields.map((field, i) => (
              <Box
                key={`dataTypes-field-${i}`}
                ml={1}
                sx={{ maxWidth: '400px', minWidth: '200px' }}
                display="flex"
                justifyContent="space-between"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedTreatedDataTypes.indexOf(field) !== -1 || false}
                      onChange={() => handleSelectTreatedDataType(field)}
                      disabled={disabled || !isEditMode}
                    />
                  }
                  label={t(TreatedFieldsTranslationMap.fields[field]).toString()}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disabled || !isEditMode || selectedTreatedDataTypes.indexOf(field) === -1}
                      onChange={() => handleSelectTreatedDataTypeMandatory(field, !isMandatory(field))}
                      checked={isMandatory(field)}
                    />
                  }
                  label={t(msgIds.MSG_TREATMENT_FIELD_MANDATORY)}
                />
              </Box>
            ))}
          </Box>
        ))}
      </Stack>

      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <FormControl>
          <InputLabel id="customer-visibility-rules-label">{t(msgIds.MSG_CUSTOMER_VISIBILITY_RULES)}</InputLabel>
          <Select
            disabled={disabled || !isEditMode}
            labelId="customer-visibility-rules-label"
            id="customer-visibility-rules-select"
            value={
              args.customerVisibilityRules
                ? customerVisibilityRules.find((cv) => cv.value === args.customerVisibilityRules?.toString())?.value
                : '0'
            }
            label={t(msgIds.MSG_CUSTOMER_VISIBILITY_RULES)}
            onChange={(event) =>
              setArgs({ ...args, customerVisibilityRules: parseInt(event.target.value) as CustomerVisibilityRules })
            }
          >
            <MenuItem value={''}></MenuItem>
            {customerVisibilityRules.map((item) => (
              <MenuItem
                key={item.value}
                value={item.value}
                selected={item.value === args.customerVisibilityRules?.toString()}
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <IconButton
          disabled={disabled || !isEditMode}
          onClick={() => {
            setSimpleDialogOpen(true)
            setSimpleDialogData({
              title: t(msgIds.MSG_CUSTOMER_VISIBILITY_RULES),
              content: t(msgIds.MSG_CUSTOMER_VISIBILITY_RULES_INFO),
              actionsStyle: 'Ok',
              onClose: async (result) => {
                setSimpleDialogOpen(false)
              },
            })
          }}
          size="small"
        >
          <InfoIco />
        </IconButton>
      </Stack>

      <Box display="flex" flexDirection="column" gap={1} mt={3}>
        <Box mb={2} display="flex" alignItems="center" gap={1}>
          <Typography variant="body1">
            <strong>{t(msgIds.MSG_TREATED_FIELDS_TEXT2)}</strong>
          </Typography>
          <Button variant="outlined" autoFocus onClick={() => addCustomField()} disabled={disabled || !isEditMode}>
            {t(msgIds.MSG_ADD)}
          </Button>
        </Box>
        {args?.dataTypes?.customFields.map((customField, index) => (
          <Box key={`dataTypes-customField-${index}`} display="flex" flexDirection="row" alignItems="center">
            <Box display="flex" flexDirection="column" flexGrow={1}>
              <Typography
                variant="body2"
                sx={{ color: disabled || !isEditMode ? theme.palette.common.gray5 : theme.palette.common.gray0 }}
              >
                <strong>{t(msgIds.MSG_TREATMENT_FIELD_NAME)}:</strong> {customField.fieldName}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: disabled || !isEditMode ? theme.palette.common.gray5 : theme.palette.common.gray0 }}
              >
                <strong>{t(msgIds.MSG_TREATMENT_FIELD_DESCRIPTION)}:</strong> {customField.description}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: disabled || !isEditMode ? theme.palette.common.gray5 : theme.palette.common.gray0 }}
              >
                <strong>{t(msgIds.MSG_TREATMENT_FIELD_MANDATORY)}:</strong>{' '}
                {customField.mandatory ? t(msgIds.MSG_YES) : t(msgIds.MSG_NO)}
              </Typography>
            </Box>
            <Box mx={1}>
              <IconButton disabled={disabled || !isEditMode} onClick={() => updateCustomField(customField)}>
                <EditIco />
              </IconButton>
            </Box>
            <Box>
              <IconButton
                disabled={disabled || !isEditMode}
                onClick={() => removeCustomField(customField.fieldName)}
                color="error"
              >
                <DeleteIco />
              </IconButton>
            </Box>
          </Box>
        ))}
      </Box>

      {isEditMode && (
        <ViewActions justifyContent={'center'} sx={{ paddingTop: 6 }}>
          <ViewActionsButton autoFocus defaultAction onClick={onSave} disabled={!areUnsavedChanges}>
            {t(msgIds.MSG_SAVE)}
          </ViewActionsButton>
          <ViewActionsButton onClick={onCancel}>{t(msgIds.MSG_CANCEL)}</ViewActionsButton>
        </ViewActions>
      )}
    </EditorFormContainer>
  )
}
