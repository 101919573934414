import { PDX_API_URL } from '../shared/Constants'
import log from '../shared/Logger'
import { axiosInstance } from '../contexts/AuthContext'
import { UriComposer } from '../shared/types/UriComposer'
import { PaginatedResponse } from '../shared/types/PaginatedResponse'
import { AxiosResponse } from 'axios'
import { Document, IDocumentJson } from '../models/Document'
import { DocumentRevision, IDocumentRevisionJson } from '../models/DocumentRevision'
import { DocumentRetention, IDocumentRetentionJson } from '../models/DocumentRetention'

// ********************
// GET

function deserializeResponse(resp: AxiosResponse<any, any>): PaginatedResponse<Document> {
  let desResp = new PaginatedResponse<Document>()
  desResp.rows = resp.data.rows.map((json: IDocumentJson) => {
    const data = Document.deserialize(json as IDocumentJson)
    return data
  })
  desResp.page = resp.data.page
  desResp.offset = resp.data.offset
  desResp.limit = resp.data.limit
  desResp.total = resp.data.total
  return desResp
}

export function getDocuments(
  abortSignal: AbortSignal,
  pageNum: number,
  pageSize: number,
  sort: string,
  targetProfileId: number,
  onlyDrafts: boolean,
  onlyAuthorized: boolean,
  authorizedProfileId?: number,
  isDelivered?: boolean,
  fromDate?: string,
  toDate?: string,
  ownerProfileIds?: number[],
  authorProfileIds?: number[],
  loadObsolete?: boolean,
  doxIds?: number[],
  types?: number[],
  expandOptions?: string
): Promise<PaginatedResponse<Document>> {
  const funcName = 'getDocuments'
  let url = UriComposer.create(`${PDX_API_URL}/v1/documents`)
    .addParamNumber('offset', pageNum * pageSize)
    .addParamNumber('limit', pageSize)
    .addParamString('sort', sort)
    .addParamNumber('target_profile_id', targetProfileId)
    .addParamBool('draft', onlyDrafts, true)
    .addParamBool('authorized_only', onlyAuthorized, true)
    .addParamNumberNullable('authorized_profile_id', authorizedProfileId)
    .addParamBoolNullable('delivered', isDelivered, true, true)
    .addParamDateNullableStr('edited_from', fromDate, true, true)
    .addParamDateNullableStr('edited_until', toDate, true, true)
    .addParamIntArray('owner_profile_id', ownerProfileIds)
    .addParamIntArray('author_profile_id', authorProfileIds)
    .addParamBoolNullable('obsolete', !loadObsolete ? false : undefined)
    .addParamIntArray('dox_id', doxIds)
    .addParamIntArray('type', types)
    .addParamString('expand', expandOptions)
    .getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = deserializeResponse(resp)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function getDocument(abortSignal: AbortSignal, documentId: number): Promise<Document> {
  const funcName = 'getDocument'
  const url = UriComposer.create(`${PDX_API_URL}/v1/documents/${documentId}`)
    .addParamString('expand', 'dox_ids,is_in_diary,all_revisions')
    .getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Document.deserialize(resp.data as IDocumentJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function getDocumentRevision(
  abortSignal: AbortSignal,
  documentId: number,
  revisionId: number
): Promise<DocumentRevision> {
  const funcName = 'getDocumentRevision'
  const url = UriComposer.create(`${PDX_API_URL}/v1/documents/${documentId}/revisions/${revisionId}`).getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = DocumentRevision.deserialize(resp.data as IDocumentRevisionJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function getDocumentRevisionContent(
  abortSignal: AbortSignal,
  documentId: number,
  revisionId: number,
  isForViewOnly: boolean,
  progressCallback: (percent: number) => void
): Promise<ArrayBuffer> {
  const funcName = 'getDocumentRevisionContent'
  const url = UriComposer.create(`${PDX_API_URL}/v1/documents/${documentId}/revisions/${revisionId}/download`)
    .addParamBool('quiet', isForViewOnly)
    .getCompleteUri()

  return axiosInstance
    .get(url, {
      responseType: 'arraybuffer',
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
      onDownloadProgress: (progressEvent) => {
        const estimatedSize = parseInt(progressEvent.event.srcElement.getResponseHeader('x-estimated-size'))
        if (estimatedSize) {
          let percent = Math.round((progressEvent.loaded * 100) / estimatedSize)
          percent = percent <= 100 ? percent : 100
          if (progressCallback) {
            progressCallback(percent)
          }
        }
      },
    })
    .then((resp) => {
      return resp.data
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function getDocumentRetentions(
  abortSignal: AbortSignal,
  documentId: number,
  ownerProfileId?: number
): Promise<DocumentRetention[]> {
  const funcName = 'getDocumentRetentions'
  const url = UriComposer.create(`${PDX_API_URL}/v1/documents/${documentId}/retentions`)
    .addParamNumberNullable('owner_profile_id', ownerProfileId)
    .getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = DocumentRetention.deserializeArray(resp.data as IDocumentRetentionJson[])
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// POST

export function createInternalDocument(
  abortSignal: AbortSignal,
  document: Document,
  revision: DocumentRevision
): Promise<Document> {
  const funcName = 'createInternalDocument'
  const url = `${PDX_API_URL}/v1/documents`

  const parms = {
    target_profile_id: document.targetProfileId,
    name: revision.name,
    description: revision.description,
    edited_at: revision.editedAt,
    content: revision.content,
    mimetype: revision.mimetype,
    checksum: revision.checksum,
    is_anonymous: !!document.anonymousAt,
    dox_ids: document.doxIds,
  }
  return axiosInstance
    .post(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Document.deserialize(resp.data as IDocumentJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function createExternalDocument(
  abortSignal: AbortSignal,
  document: Document,
  revision: DocumentRevision,
  progressCallback: (percent: number) => void
): Promise<Document> {
  const funcName = 'createExternalDocument'
  const url = `${PDX_API_URL}/v1/documents`

  const formData = new FormData()
  formData.append('target_profile_id', document.targetProfileId.toString())
  formData.append('name', revision.name)
  formData.append('description', revision.description)
  formData.append('edited_at', revision.editedAt)
  formData.append('creator_identity', revision.creatorIdentity)
  formData.append('mimetype', revision.mimetype)
  formData.append('filename', revision.filename)
  formData.append('checksum', revision.checksum.toString())
  formData.append('is_anonymous', (!!document.anonymousAt).toString())
  formData.append('dox_ids', document.doxIds.toString())
  formData.append('file', new Blob([revision.content], { type: revision.mimetype }), revision.filename)

  return axiosInstance
    .post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total) {
          let percent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          percent = percent <= 100 ? percent : 100
          if (progressCallback) {
            progressCallback(percent)
          }
        }
      },
    })
    .then((resp) => {
      const reply = Document.deserialize(resp.data as IDocumentJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// PATCH

export function updateInternalDocument(
  abortSignal: AbortSignal,
  document: Document,
  revision: DocumentRevision
): Promise<Document> {
  const funcName = 'updateInternalDocument'
  const url = `${PDX_API_URL}/v1/documents/${document.documentId}`

  const parms = {
    name: revision.name,
    description: revision.description,
    edited_at: revision.editedAt,
    content: revision.content,
    mimetype: revision.mimetype,
    checksum: revision.checksum,
    is_anonymous: !!document.anonymousAt,
    dox_ids: document.doxIds,
  }
  return axiosInstance
    .patch(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Document.deserialize(resp.data as IDocumentJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function updateExternalDocument(
  abortSignal: AbortSignal,
  document: Document,
  revision: DocumentRevision,
  progressCallback: (percent: number) => void
): Promise<Document> {
  const funcName = 'updateExternalDocument'
  const url = `${PDX_API_URL}/v1/documents/${document.documentId}`

  const formData = new FormData()
  formData.append('name', revision.name)
  formData.append('description', revision.description)
  formData.append('edited_at', revision.editedAt)
  formData.append('creator_identity', revision.creatorIdentity)
  formData.append('mimetype', revision.mimetype)
  formData.append('filename', revision.filename)
  formData.append('checksum', revision.checksum.toString())
  formData.append('is_anonymous', (!!document.anonymousAt).toString())
  formData.append('dox_ids', document.doxIds?.toString())
  formData.append('file', new Blob([revision.content], { type: revision.mimetype }), revision.filename)

  return axiosInstance
    .patch(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total) {
          let percent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          percent = percent <= 100 ? percent : 100
          if (progressCallback) {
            progressCallback(percent)
          }
        }
      },
    })
    .then((resp) => {
      const reply = Document.deserialize(resp.data as IDocumentJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function archiveDocument(abortSignal: AbortSignal, documentId: number): Promise<Document> {
  const funcName = 'archiveDocument'
  const url = `${PDX_API_URL}/v1/documents/${documentId}?expand=is_in_diary,dox_ids`

  const parms = {
    archive: true,
  }

  return axiosInstance
    .patch(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Document.deserialize(resp.data as IDocumentJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function changeIsAnonymousState(
  abortSignal: AbortSignal,
  documentId: number,
  isAnonymous: boolean
): Promise<Document> {
  const funcName = 'changeIsAnonymousState'
  const url = `${PDX_API_URL}/v1/documents/${documentId}`

  const parms = {
    is_anonymous: isAnonymous,
  }

  return axiosInstance
    .patch(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Document.deserialize(resp.data as IDocumentJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function markAsObsolete(abortSignal: AbortSignal, documentId: number): Promise<Document> {
  const funcName = 'markAsObsolete'
  const url = `${PDX_API_URL}/v1/documents/${documentId}`

  const parms = {
    obsolete: true,
  }

  return axiosInstance
    .patch(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Document.deserialize(resp.data as IDocumentJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function updateDocumentRetention(
  abortSignal: AbortSignal,
  documentId: number,
  documentRetentionId: number,
  fromDate?: string,
  toDate?: string
): Promise<DocumentRetention> {
  const funcName = 'updateDocumentRetention'
  const url = `${PDX_API_URL}/v1/documents/${documentId}/retentions/${documentRetentionId}`

  const parms = {
    start_at: fromDate,
    end_at: toDate,
  }

  return axiosInstance
    .patch(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = DocumentRetention.deserialize(resp.data as IDocumentRetentionJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// DELETE

export function deleteDocument(abortSignal: AbortSignal, documentId: number): Promise<number> {
  const funcName = 'deleteDocument'
  const url = `${PDX_API_URL}/v1/documents/${documentId}`

  return axiosInstance
    .delete(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = resp.data.document_id
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function terminateDocumentRetention(
  abortSignal: AbortSignal,
  documentId: number,
  retentionId: number
): Promise<number> {
  const funcName = 'terminateDocumentRetention'
  const url = `${PDX_API_URL}/v1/documents/${documentId}/retentions/${retentionId}`

  return axiosInstance
    .delete(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = resp.data.retention_id
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}
