import { ActionType } from '../shared/Constants'
import { Account, IAccountJson, pdxAccount } from './Account'
import { HistoryContextPermission, IHistoryContextPermissionJson } from './HistoryContextPermission'
import { HistoryContextDox, IHistoryContextDoxJson } from './HistoryContextDox'
import { HistoryContextDocument, IHistoryContextDocumentJson } from './HistoryContextDocument'
import { HistoryContextTreatment, IHistoryContextTreatmentJson } from './HistoryContextTreatment'
import { HistoryContextContractVersion, IHistoryContextContractVersionJson } from './HistoryContextContractVersion'
import { HistoryContextConsent, IHistoryContextConsentJson } from './HistoryContextConsent'
import { HistoryContextInvitation, IHistoryContextInvitationJson } from './HistoryContextInvitation'
import { HistoryContextDoxUpdate, IHistoryContextDoxUpdateJson } from './HistoryContextDoxUpdate'
import { HistoryContextDocumentRevision, IHistoryContextDocumentRevisionJson } from './HistoryContextDocumentRevision'

export type HistorySubjectType =
  | 'permissions'
  | 'dox'
  | 'documents'
  | 'treatments'
  | 'contracts'
  | 'consents'
  | 'profiles'
  | 'invitations'

export interface IHistoryJson {
  id: number
  created_at: string
  author?: IAccountJson
  author_linked?: IAccountJson
  target?: IAccountJson
  type: string
  event_created_at: string
  subject: string
  context?:
    | IHistoryContextPermissionJson
    | IHistoryContextDoxJson
    | IHistoryContextDoxUpdateJson
    | IHistoryContextDocumentJson
    | IHistoryContextDocumentRevisionJson
    | IHistoryContextTreatmentJson
    | IHistoryContextContractVersionJson
    | IHistoryContextConsentJson
    | IHistoryContextInvitationJson
}

export class History {
  id: number = 0
  createdAt: Date = new Date(0)
  author?: Account
  authorLinked?: Account
  target?: Account
  type: string = ''
  eventCreatedAt: Date = new Date(0)
  subject: string = ''
  contextPermission?: HistoryContextPermission
  contextDox?: HistoryContextDox
  contextDoxUpdate?: HistoryContextDoxUpdate
  contextDocument?: HistoryContextDocument
  contextDocumentRevision?: HistoryContextDocumentRevision
  contextTreatment?: HistoryContextTreatment
  contextContractVersion?: HistoryContextContractVersion
  contextConsent?: HistoryContextConsent
  contextInvitation?: HistoryContextInvitation

  constructor() {}

  public static serialize(obj: History): IHistoryJson {
    const res = {
      id: obj.id,
      created_at: obj.createdAt.toISOString(),
      author: obj.author ? Account.serialize(obj.author) : undefined,
      author_linked: obj.authorLinked ? Account.serialize(obj.authorLinked) : undefined,
      target: obj.target ? Account.serialize(obj.target) : undefined,
      type: obj.type,
      event_created_at: obj.eventCreatedAt.toISOString(),
      subject: obj.subject,
    } as IHistoryJson

    switch (obj.subjectType) {
      case 'permissions':
        res.context = obj.contextPermission ? HistoryContextPermission.serialize(obj.contextPermission) : undefined
        break
      case 'dox':
        if (obj.contextDox) {
          res.context = obj.contextDox ? HistoryContextDox.serialize(obj.contextDox) : undefined
        } else {
          res.context = obj.contextDoxUpdate ? HistoryContextDoxUpdate.serialize(obj.contextDoxUpdate) : undefined
        }
        break
      case 'documents':
        res.context = obj.contextDocument ? HistoryContextDocument.serialize(obj.contextDocument) : undefined
        break
      case 'treatments':
        res.context = obj.contextTreatment ? HistoryContextTreatment.serialize(obj.contextTreatment) : undefined
        break
      case 'contracts':
        res.context = obj.contextContractVersion
          ? HistoryContextContractVersion.serialize(obj.contextContractVersion)
          : undefined
        break
      case 'consents':
        res.context = obj.contextConsent ? HistoryContextConsent.serialize(obj.contextConsent) : undefined
        break
      case 'invitations':
        res.context = obj.contextInvitation ? HistoryContextInvitation.serialize(obj.contextInvitation) : undefined
        break
    }
    return res
  }

  public static deserialize(json: IHistoryJson): History {
    const res = new History()
    res.id = json.id
    res.createdAt = new Date(json.created_at)
    res.author = json.author ? Account.deserialize(json.author) : pdxAccount
    res.authorLinked = json.author_linked ? Account.deserialize(json.author_linked) : undefined
    res.target = json.target ? Account.deserialize(json.target) : undefined
    res.type = json.type
    res.eventCreatedAt = new Date(json.event_created_at)
    res.subject = json.subject

    const subjectInfo = res.subject?.split(':')
    const subjectType = subjectInfo[0] as HistorySubjectType | undefined
    switch (subjectType) {
      case 'permissions':
        res.contextPermission = json.context
          ? HistoryContextPermission.deserialize(json.context as IHistoryContextPermissionJson)
          : undefined
        break
      case 'dox':
        if ((json.context as IHistoryContextDoxUpdateJson).new && (json.context as IHistoryContextDoxUpdateJson).old) {
          res.contextDoxUpdate = json.context
            ? HistoryContextDoxUpdate.deserialize(json.context as IHistoryContextDoxUpdateJson)
            : undefined
        } else {
          res.contextDox = json.context
            ? HistoryContextDox.deserialize(json.context as IHistoryContextDoxJson)
            : undefined
        }
        break
      case 'documents':
        if ((json.context as IHistoryContextDocumentRevisionJson).document) {
          res.contextDocumentRevision = json.context
            ? HistoryContextDocumentRevision.deserialize(json.context as IHistoryContextDocumentRevisionJson)
            : undefined
        } else {
          res.contextDocument = json.context
            ? HistoryContextDocument.deserialize(json.context as IHistoryContextDocumentJson)
            : undefined
        }
        break
      case 'treatments':
        res.contextTreatment = json.context
          ? HistoryContextTreatment.deserialize(json.context as IHistoryContextTreatmentJson)
          : undefined
        break
      case 'contracts':
        res.contextContractVersion = json.context
          ? HistoryContextContractVersion.deserialize(json.context as IHistoryContextContractVersionJson)
          : undefined
        break
      case 'consents':
        res.contextConsent = json.context
          ? HistoryContextConsent.deserialize(json.context as IHistoryContextConsentJson)
          : undefined
        break
      case 'invitations':
        res.contextInvitation = json.context
          ? HistoryContextInvitation.deserialize(json.context as IHistoryContextInvitationJson)
          : undefined
        break
    }
    return res
  }

  public static serializeArray(objs: History[]): IHistoryJson[] {
    const jsons = objs.map((p) => {
      return History.serialize(p)!
    })
    return jsons
  }

  public static deserializeArray(json: IHistoryJson[]): History[] {
    const res = json.map((p) => {
      return History.deserialize(p)!
    })
    return res
  }

  _subjectType?: HistorySubjectType
  get subjectType(): HistorySubjectType | undefined {
    return this.subject?.split(':')[0] as HistorySubjectType | undefined
  }

  _subjectPermissionType?: ActionType
  get subjectPermissionType(): ActionType | undefined {
    return this.subject?.split(':')[1] as ActionType | undefined
  }
}
