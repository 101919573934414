import {
  Alert,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { VisibilityOffIco, VisibilityOnIco } from '../icons'
import { useState } from 'react'
import { PasswordStrengthIndicator } from '../passwordStrengthIndicator/PasswordStrengthIndicator'

export interface IPasswordEditorFieldsProps {
  errors: any
  password: string
  passwordConfirmation: string
  onChangePassword: (password: string) => void
  onChangePasswordConfirmation: (passwordConfirmation: string) => void
}
export function PasswordEditorFields(props: IPasswordEditorFieldsProps): JSX.Element {
  const { errors, password, passwordConfirmation, onChangePassword, onChangePasswordConfirmation } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)

  function handleMouseDownPassword(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
  }

  return (
    <Stack gap={4}>
      <Alert severity="info" sx={{ whiteSpace: 'pre-line' }}>
        {t(msgIds.MSG_PASSWORD_EDITOR_INFO)}
      </Alert>
      <FormControl variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">{t(msgIds.MSG_PASSWORD_EDITOR_PASSWORD)}</InputLabel>
        <OutlinedInput
          error={!!errors.password}
          id="outlined-adornment-password"
          value={password}
          onChange={(event) => onChangePassword(event.target.value)}
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword((p) => !p)}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOffIco /> : <VisibilityOnIco />}
              </IconButton>
            </InputAdornment>
          }
          label={t(msgIds.MSG_PASSWORD_EDITOR_PASSWORD)}
        />
        {!!errors.password && (
          <FormHelperText sx={{ color: theme.palette.error.dark }}>{t(errors.password)}</FormHelperText>
        )}
      </FormControl>

      <PasswordStrengthIndicator password={password} />

      <FormControl variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password-confirmation">
          {t(msgIds.MSG_PASSWORD_EDITOR_PASSWORD_CONFIRMATION)}
        </InputLabel>
        <OutlinedInput
          error={!!errors.passwordConfirmation}
          id="outlined-adornment-password-confirmation"
          value={passwordConfirmation}
          onChange={(event) => onChangePasswordConfirmation(event.target.value)}
          type={showPasswordConfirmation ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password confirmation visibility"
                onClick={() => setShowPasswordConfirmation((p) => !p)}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPasswordConfirmation ? <VisibilityOffIco /> : <VisibilityOnIco />}
              </IconButton>
            </InputAdornment>
          }
          label={t(msgIds.MSG_PASSWORD_EDITOR_PASSWORD_CONFIRMATION)}
        />
        {!!errors.passwordConfirmation && (
          <FormHelperText sx={{ color: theme.palette.error.dark }}>{t(errors.passwordConfirmation)}</FormHelperText>
        )}
      </FormControl>
    </Stack>
  )
}
