import { ITotpCheckDialogProps } from './TotpCheckDialog.types'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import * as dalAuth from '../../dal/DalAuth'
import msgIds from '../../locales/msgIds'
import { StyledDialog } from '../styledDialog/StyledDialog'
import { ViewHeader } from '../../components/viewHeader/ViewHeader'
import { Typography } from '@mui/material'
import { useState } from 'react'
import { ViewContent } from '../../components/viewContent/ViewContent'
import { PasswordInput } from '../../components/passwordInput/PasswordInput'
import { ViewActions } from '../../components/viewActions/ViewActions'
import { ViewActionsButton } from '../../components/viewActions/ViewActionsButton'
import { Utils } from '../../shared/Utils'

export default function TotpCheckDialog(props: ITotpCheckDialogProps) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [code, setCode] = useState<string>('')

  async function onCheckTotp() {
    try {
      setIsLoading(true)
      const abortController = new AbortController()
      console.log(code)
      await dalAuth.checkTotp(abortController.signal, code)
      setCode('')
      props.onClose({ userChoice: 'yes' })
    } catch (error) {
      Utils.enqueueSnackbarError2(error, t)
    } finally {
      setIsLoading(false)
    }
  }

  function onClose() {
    props.onClose({ userChoice: 'abort' })
  }

  return (
    <StyledDialog open={props.isOpen} onClose={onClose} minHeight="auto">
      <ViewHeader
        title={t(msgIds.MSG_TOTP_EDITOR_DIALOG_DISABLE_TITLE)}
        onClickExit={() => props.onClose({ userChoice: 'abort' })}
        exitButtonVisible={true}
      />
      <ViewContent spacing={2}>
        <Typography textAlign={'justify'}>
          {props.text && props.text.split('\n').map((line, index) => <p key={index}>{line}</p>)}
        </Typography>
        <PasswordInput
          sx={{ flexGrow: 1 }}
          id="disable-totp-input"
          label={t(msgIds.MSG_TOTP_EDITOR_CODE_INPUT_LABEL)}
          value={code}
          onChange={(event) => setCode(event.target.value)}
        />
      </ViewContent>
      <ViewActions>
        <ViewActionsButton defaultAction disabled={!code} onClick={onCheckTotp}>
          {t(msgIds.MSG_OK)}
        </ViewActionsButton>
        <ViewActionsButton onClick={onClose}>{t(msgIds.MSG_CANCEL)}</ViewActionsButton>
      </ViewActions>
    </StyledDialog>
  )
}
