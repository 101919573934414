import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function AsteriskIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 9.9997559,1.99951 c -0.2833261,0 -0.5202521,0.09618 -0.711914,0.2878418 C 9.0961798,2.4790138 9,2.7159397 9,2.9992659 V 6.8005354 L 5.699707,4.8999007 C 5.4663801,4.7665707 5.2170994,4.737012 4.9504393,4.81201 4.6837794,4.8870082 4.4839159,5.0423056 4.3505859,5.275633 L 2.3510742,8.7253402 C 2.2177339,8.9586676 2.1833299,9.2127979 2.25,9.4877914 2.3166651,9.7627842 2.4671124,9.9674985 2.7004395,10.100828 l 3.3002929,1.898438 -3.3002929,1.900635 C 2.4671175,14.03323 2.3166699,14.237944 2.25,14.512938 2.18334,14.787931 2.217744,15.042061 2.3510745,15.275389 l 1.9995117,3.449706 c 0.1333305,0.233325 0.3331935,0.386427 0.5998534,0.461427 0.26666,0.075 0.5159409,0.04763 0.7492676,-0.0857 L 9,17.200192 v 3.799074 c 0,0.283328 0.09618,0.520253 0.2878419,0.711914 0.1916619,0.191662 0.4285879,0.287842 0.711914,0.287842 h 4.0012201 c 0.283328,-1e-6 0.520253,-0.09618 0.711915,-0.287842 0.19166,-0.191661 0.287841,-0.428586 0.287841,-0.711914 v -3.799074 l 3.298096,1.900637 c 0.233328,0.133335 0.484805,0.160692 0.751466,0.08569 0.266659,-0.075 0.466522,-0.228099 0.599853,-0.461426 l 1.999512,-3.449707 c 0.133335,-0.233327 0.167743,-0.487458 0.10107,-0.76245 -0.06667,-0.274999 -0.217112,-0.479713 -0.45044,-0.613042 L 18,11.999266 21.300294,10.100828 c 0.233322,-0.1333301 0.383769,-0.3380438 0.450438,-0.6130366 0.06666,-0.2749932 0.03225,-0.5291238 -0.10107,-0.7624512 L 19.650147,5.275633 C 19.516816,5.0423053 19.316953,4.8870079 19.050294,4.81201 18.783633,4.737012 18.532156,4.7665705 18.298828,4.8999007 L 15.000732,6.8005354 V 2.9992659 c 0,-0.2833262 -0.09618,-0.5202521 -0.287841,-0.7119141 C 14.521229,2.09569 14.284304,1.99951 14.000976,1.99951 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
