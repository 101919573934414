import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { ITreatmentDataRetentionEditorFormProps } from './TreatmentDataRetentionEditorForm.types'
import { useState } from 'react'
import DataEditorHeader from '../dataEditorHeader/DataEditorHeader'
import { EditIco } from '../icons'
import TreatmentDataRetentionEditor from './TreatmentDataRetentionEditor'
import { DataEditorContainer } from '../dataEditorContainer/DataEditorContainer'

export function TreatmentDataRetentionEditorForm(props: ITreatmentDataRetentionEditorFormProps): JSX.Element {
  const { t } = useTranslation()
  const [isEditMode, setIsEditMode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  return (
    <DataEditorContainer isLoading={isLoading}>
      <DataEditorHeader
        title={t(msgIds.MSG_TREATMENT_DATA_RETENTION)}
        isEditMode={isEditMode}
        viewModeCommands={[
          {
            commandText: '',
            icon: <EditIco />,
            onClick: () => setIsEditMode(true),
            tooltipText: t(msgIds.MSG_MODIFY) ?? undefined,
            disabled: false,
          },
        ]}
        editModeCommands={[]}
      />
      <TreatmentDataRetentionEditor
        treatment={props.treatment}
        isEditMode={isEditMode}
        disabled={false}
        onSave={() => setIsEditMode(false)}
        onCancel={() => setIsEditMode(false)}
        setIsLoading={setIsLoading}
      />
    </DataEditorContainer>
  )
}
