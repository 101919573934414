import { IWordCheckDialogProps } from './WordCheckDialog.types'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import msgIds from '../../locales/msgIds'
import { StyledDialog } from '../styledDialog/StyledDialog'
import { ViewHeader } from '../../components/viewHeader/ViewHeader'
import { TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { ViewContent } from '../../components/viewContent/ViewContent'
import { ViewActions } from '../../components/viewActions/ViewActions'
import { ViewActionsButton } from '../../components/viewActions/ViewActionsButton'
import { useSnackbar } from 'notistack'

export default function WordCheckDialog(props: IWordCheckDialogProps) {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState<any>({})
  const [word, setWord] = useState<string>('')

  function validateData() {
    const errors = {
      word: word !== t(msgIds.MSG_WORD_CONFIRM_CLOSE) ? t(msgIds.MSG_WORD_CONFIRM_INVALID) : undefined,
    }
    if (Object.values(errors).find((e) => !!e)) {
      return errors
    } else {
      return null
    }
  }

  async function onCheckWord() {
    const errors = validateData()
    setErrors(errors || {})
    if (errors) {
      enqueueSnackbar(t(msgIds.MSG_WORD_CONFIRM_INVALID), { variant: 'error' })
      return
    }

    setWord('')
    props.onClose({ userChoice: 'yes' })
  }

  function onClose() {
    props.onClose({ userChoice: 'abort' })
  }

  return (
    <StyledDialog open={props.isOpen} onClose={onClose} minHeight="auto">
      <ViewHeader
        title={t(msgIds.MSG_WORD_CONFIRM_DIALOG_TITLE)}
        onClickExit={() => props.onClose({ userChoice: 'abort' })}
        exitButtonVisible={true}
      />
      <ViewContent spacing={2}>
        <Typography textAlign={'justify'}>
          {props.text && props.text.split('\n').map((line, index) => <p key={index}>{line}</p>)}
        </Typography>
        <Typography>{t(msgIds.MSG_WORD_CONFIRM_DIALOG_BODY, { word: t(msgIds.MSG_WORD_CONFIRM_CLOSE) })}</Typography>
        <TextField
          error={!!errors.word}
          helperText={t(errors.word)}
          label={t(msgIds.MSG_WORD_CONFIRM_FIELD_LABEL)}
          variant="outlined"
          value={word}
          onChange={(event) => {
            setWord(event.target.value)
            setErrors({})
          }}
        />
      </ViewContent>
      <ViewActions>
        <ViewActionsButton defaultAction disabled={!word} onClick={onCheckWord}>
          {t(msgIds.MSG_OK)}
        </ViewActionsButton>
        <ViewActionsButton onClick={onClose}>{t(msgIds.MSG_CANCEL)}</ViewActionsButton>
      </ViewActions>
    </StyledDialog>
  )
}
