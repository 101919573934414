import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, OutlinedInputProps } from '@mui/material'
import { useState } from 'react'
import { VisibilityOffIco, VisibilityOnIco } from '../icons'

export interface IPasswordInputProps extends Omit<OutlinedInputProps, 'type' | 'endAdornment'> {}

export function PasswordInput(props: IPasswordInputProps): JSX.Element {
  const { id, label, sx, style, ...rest } = props
  const [showPassword, setShowPassword] = useState(false)
  return (
    <FormControl variant="outlined" sx={sx} style={style}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <OutlinedInput
        id={id}
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword((p) => !p)}
              onMouseDown={(event: any) => event.preventDefault()}
              edge="end"
            >
              {showPassword ? <VisibilityOffIco /> : <VisibilityOnIco />}
            </IconButton>
          </InputAdornment>
        }
        label={label}
        {...rest}
      />
    </FormControl>
  )
}
