import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function DashboardIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 5.0009766,2.9816878 A 2.005875,2.005875 0 0 0 2.9992675,4.9811995 V 18.982176 a 2.00587,2.00587 0 0 0 2.0017091,1.999512 H 18.999756 a 2.00587,2.00587 0 0 0 1.999512,-1.999512 V 4.9811995 A 2.005875,2.005875 0 0 0 18.999756,2.9816878 Z m 0,1.9995117 H 18.999756 V 18.982176 H 5.0009766 Z M 7.0004883,6.9807112 V 10.981932 H 9.9997559 V 6.9807112 Z m 3.9990237,0 v 4.0012208 h 6.000732 V 6.9807112 Z M 7.0004883,11.981688 v 4.99878 h 4.9987797 v -4.99878 z m 5.9985347,0 v 4.99878 h 4.001221 v -4.99878 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
