import { Stack } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { IFromToDatePickerProps } from './FromToDatePicker.types'

export default function FromToDatePicker(props: IFromToDatePickerProps): JSX.Element {
  const { t } = useTranslation()
  const { disabled, fromDate, toDate, onChangeFromDate, onChangeToDate, ...rest } = props
  return (
    <Stack direction="row" gap={2} paddingTop={1} flexWrap="wrap" {...rest}>
      <DatePicker
        label={t(msgIds.MSG_DOCS_FILTER_EDITED_FROM_KEY)}
        value={fromDate ? dayjs(fromDate) : null}
        onChange={(value) => {
          const newDate = value?.toDate()
          if (newDate && newDate.getTime() !== fromDate?.getTime()) {
            onChangeFromDate(value?.toDate())
          }
        }}
        maxDate={toDate ? dayjs(toDate) : undefined}
        disabled={disabled}
      />
      <DatePicker
        label={t(msgIds.MSG_DOCS_FILTER_EDITED_TO_KEY)}
        value={toDate ? dayjs(toDate) : null}
        onChange={(value) => {
          const newDate = value?.toDate()
          if (newDate && newDate.getTime() !== toDate?.getTime()) {
            onChangeToDate(value?.toDate())
          }
        }}
        minDate={fromDate ? dayjs(fromDate) : undefined}
        disabled={disabled}
      />
    </Stack>
  )
}
