import { Button, Stack, TextField, Typography } from '@mui/material'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router'
import { useState } from 'react'
import { PDXLogo } from '../images'
import { Utils } from '../../shared/Utils'
import * as dalAuth from '../../dal/DalAuth'
import { StyledLoginForm } from '../../shared/StyledControls'
import { ILoginViewProps } from '../loginView/LoginView.types'
import { isOperator } from '../../shared/Constants'
import { PasswordInput } from '../passwordInput/PasswordInput'

export type ILogin2FaRecoveryViewProps = {}

export function Login2FaRecoveryView(props: ILogin2FaRecoveryViewProps): JSX.Element {
  const state: ILoginViewProps = useOutletContext()
  const { profileType, onUserLogged, setIsLoading } = state
  const { t } = useTranslation()
  const [otp, setOtp] = useState<string>('')

  async function onLogin() {
    if (!otp) {
      return
    }
    try {
      setIsLoading(true)
      const abortController = new AbortController()
      // TODO: implement authentication with emergency TOTP
      const account = await dalAuth.checkTotp(abortController.signal, otp)
      await onUserLogged(abortController.signal, account, profileType)
    } catch (error) {
      Utils.enqueueSnackbarError2(error, t)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <StyledLoginForm>
      <Stack spacing={1}>
        <PDXLogo
          sx={{
            alignSelf: 'center',
            width: { xs: '70%', sm: '60%' },
            maxWidth: '420px',
            height: 'auto',
          }}
        />
        <Typography textAlign={'center'}>
          {isOperator(profileType) ? t(msgIds.MSG_LOGIN_FOR_OPERATORS) : t(msgIds.MSG_LOGIN_FOR_CUSTOMERS)}
        </Typography>
      </Stack>
      <Typography>{t(msgIds.MSG_OTP_RECOVERY_INFO)}</Typography>

      <PasswordInput
        sx={{ flexGrow: 1 }}
        label={t(msgIds.MSG_TOTP_EDITOR_CODE_INPUT_LABEL)}
        value={otp}
        onChange={(event) => setOtp(event.target.value)}
      />
      <Button variant="contained" onClick={onLogin}>
        {t(msgIds.MSG_LOGIN_BUTTON_TITLE)}
      </Button>
    </StyledLoginForm>
  )
}
