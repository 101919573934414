import { ArchiveItemSourceType, ArchiveItemSourceType_all } from '../shared/Constants'
import { Utils } from '../shared/Utils'

export interface IHistoryContextPermissionViewDocumentJson {
  id: number
  target_profile_id: number
  author_profile_id: number
  owner_profile_id: number
  source_type: number
  content_type: number
  delivered_at?: string
  draft_revision_id: number
  last_archived_revision_id: number
}

export class HistoryContextPermissionViewDocument {
  id: number = 0
  targetProfileId: number = 0
  authorProfileId: number = 0
  ownerProfileId: number = 0
  sourceType: ArchiveItemSourceType_all = ArchiveItemSourceType.none
  contentType: number = 0
  deliveredAt?: Date
  draftRevisionId: number = 0
  lastArchivedRevisionId: number = 0

  constructor() {}

  public static serialize(obj: HistoryContextPermissionViewDocument): IHistoryContextPermissionViewDocumentJson {
    return {
      id: obj.id,
      target_profile_id: obj.targetProfileId,
      author_profile_id: obj.authorProfileId,
      owner_profile_id: obj.ownerProfileId,
      source_type: obj.sourceType,
      content_type: obj.contentType,
      delivered_at: obj.deliveredAt ? (Utils.toDate(obj.deliveredAt, true) as string) : undefined,
      draft_revision_id: obj.draftRevisionId,
      last_archived_revision_id: obj.lastArchivedRevisionId,
    }
  }

  public static deserialize(json: IHistoryContextPermissionViewDocumentJson): HistoryContextPermissionViewDocument {
    const res = new HistoryContextPermissionViewDocument()
    res.id = json.id
    res.targetProfileId = json.target_profile_id
    res.authorProfileId = json.author_profile_id
    res.ownerProfileId = json.owner_profile_id
    res.sourceType = json.source_type
    res.contentType = json.content_type
    res.deliveredAt = json.delivered_at ? new Date(json.delivered_at) : undefined
    res.draftRevisionId = json.draft_revision_id
    res.lastArchivedRevisionId = json.last_archived_revision_id
    return res
  }
}
