import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Paper, Stack, Typography } from '@mui/material'
import msgIds from '../../locales/msgIds'
import * as dalAuth from '../../dal/DalAuth'
import CommandBar from '../../components/commandBar/CommandBar'
import { PageContainer, PageContent } from '../../components/pageContainer/PageContainer'
import { useNavigate, useParams } from 'react-router'
import { useAuthContext } from '../../contexts/AuthContext'
import { Utils } from '../../shared/Utils'
import { AxiosError } from 'axios'

export default function UserActivationPage() {
  const { token } = useParams()

  const authContext = useAuthContext()
  if (token) {
    authContext.setJwt(token)
  }

  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [isUserActivated, setIsUserActivated] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    const activateUser = async (abortSignal: AbortSignal) => {
      if (!token) return
      try {
        setIsLoading(true)
        const { email, profileType } = await dalAuth.activateUser(abortSignal, token)
        setIsUserActivated(true)
        await Utils.wait(4000)
        navigate(`/login?profileType=${profileType}`)
      } catch (err) {
        if ((err as AxiosError)?.code !== 'ERR_CANCELED') {
          setIsUserActivated(false)
        }
        Utils.enqueueSnackbarError2(err, t)
      } finally {
        setIsLoading(false)
      }
    }

    const abortController = new AbortController()
    activateUser(abortController.signal)

    return () => {
      abortController.abort()
    }
  }, [token])

  const getActivationMessage = () => {
    if (isUserActivated === undefined) {
      return (
        <>
          <Typography textAlign={'center'} margin={2} variant="h4">
            {t(msgIds.MSG_USER_ACTIVATION_IN_PROGRESS)}
          </Typography>
          <Typography textAlign={'center'} margin={2} variant="body1">
            {t(msgIds.MSG_USER_ACTIVATION_WAIT)}
          </Typography>
        </>
      )
    } else if (isUserActivated === true) {
      return (
        <>
          <Typography textAlign={'center'} margin={2} variant="h4">
            {t(msgIds.MSG_USER_ACTIVATION_DONE)}
          </Typography>
          <Typography textAlign={'center'} margin={2} variant="body1">
            {t(msgIds.MSG_USER_ACTIVATION_INFO1)}
          </Typography>
        </>
      )
    } else if (isUserActivated === false) {
      return (
        <>
          <Typography textAlign={'center'} margin={2} variant="h4">
            {t(msgIds.MSG_USER_ACTIVATION_FAILED)}
          </Typography>
        </>
      )
    }
  }

  return (
    <PageContainer>
      <CommandBar title={t(msgIds.MSG_USER_ACTIVATION_TITLE)} commands={[]} />
      <PageContent>
        <Stack spacing={8} width="100%" maxWidth="sm" alignItems="stretch">
          <Paper sx={{ paddingX: 2, paddingY: 6, borderRadius: 2 }}>
            <Typography variant="body1">{getActivationMessage()}</Typography>
          </Paper>
        </Stack>
      </PageContent>
    </PageContainer>
  )
}
