import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function TermsConditionsIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 20,6.0000034 h -3.999999 v -2 A 1.9931051,1.9931051 0 0 0 14,2.0000033 H 10 A 1.9931051,1.9931051 0 0 0 8.0000001,4.0000034 v 2 H 4 a 1.9846301,1.9846301 0 0 0 -1.99,2 L 1.999995,19.000004 a 1.9930901,1.9930901 0 0 0 2,1.999999 H 20 a 1.99307,1.99307 0 0 0 2.000001,-1.999999 V 8.0000034 a 1.9930901,1.9930901 0 0 0 -2.000001,-2 z m -10,-2 h 4 v 2 H 10 Z M 20,19.000004 H 4 V 8.0000034 h 16 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
