export interface IPreferencesPermissionsJson {
  default_view_duration_days: number
}

export class PreferencesPermissions {
  defaultViewDurationDays: number = 0

  constructor() {}

  public static serialize(obj: PreferencesPermissions): IPreferencesPermissionsJson {
    return {
      default_view_duration_days: obj.defaultViewDurationDays,
    }
  }

  public static deserialize(json: IPreferencesPermissionsJson): PreferencesPermissions {
    const res = new PreferencesPermissions()
    res.defaultViewDurationDays = json.default_view_duration_days
    return res
  }
}
