export interface IPermissionActionTokenJson {
  invalidated: boolean
}

export class PermissionActionToken {
  invalidated: boolean = false

  constructor() {}

  public static serialize(obj: PermissionActionToken): IPermissionActionTokenJson {
    return {
      invalidated: obj.invalidated,
    }
  }

  public static deserialize(json: IPermissionActionTokenJson): PermissionActionToken {
    const res = new PermissionActionToken()
    res.invalidated = json.invalidated
    return res
  }
}
