import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function DocInternalIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 7.4044192,3.005493 2.0145264,8.3953856 V 19.175171 a 1.80103,1.80103 0 0 0 1.7995605,1.795166 H 12.009888 V 18.9906 H 3.9986572 V 9.296265 H 8.3031005 V 4.9610596 H 14.0094 v 2.0148923 h 1.986328 V 3.005493 Z m 4.6054688,5.972168 v 1.999512 2.001709 h 2.006104 v -2.001709 h 1.99292 v 8.000244 H 14.0094 v 2.01709 h 6.000732 v -2.01709 h -1.995117 v -8.000244 h 1.977539 v 1.984131 h 1.99292 V 10.977173 9.740112 8.977661 h -3.970459 -2.006103 -1.99292 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
