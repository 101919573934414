import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function ArchiveIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 16,9 H 13.45 V 6 h -2.9 V 9 H 8.0000001 L 12,13 Z M 19,3 H 4.99 C 3.88,3 3,3.9 3,5.0000001 V 19 c 0,1.1 0.88,2 1.99,2 H 19 c 1.1,0 2,-0.9 2,-2 V 5.0000001 C 21,3.9 20.1,3 19,3 Z m 0,16 H 5.0000001 V 16 H 8.56 c 0.69,1.19 1.97,2 3.45,2 1.48,0 2.75,-0.81 3.45,-2 H 19 Z m 0,-5 h -4.99 c 0,1.1 -0.9,2 -2,2 -1.1,0 -2,-0.9 -2,-2 H 5.0000001 L 4.9899951,5.0000001 H 18.999996 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
