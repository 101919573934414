import { IAuthorizationsChangeDialogProps } from './AuthorizationsChangeDialog.types'
import { StyledDialog } from '../styledDialog/StyledDialog'
import { AuthorizationsChangeStepper } from '../../components/authorizationsChangeStepper/AuthorizationsChangeStepper'

export default function AuthorizationsChangeDialog(props: IAuthorizationsChangeDialogProps) {
  function onConfirm() {
    props.onClose({ userChoice: 'yes' })
  }

  function onCancel() {
    props.onClose({ userChoice: 'no' })
  }

  return (
    <StyledDialog open={props.isOpen} onClose={onCancel}>
      <AuthorizationsChangeStepper
        action={props.action}
        doxId={props.doxId}
        oldParentDoxId={props.oldParentDoxId}
        newParentDoxId={props.newParentDoxId}
        doxIdToDelete={props.doxIdToDelete}
        lostedAuthorizations={props.lostedAuthorizations}
        gainedAuthorizations={props.gainedAuthorizations}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    </StyledDialog>
  )
}
