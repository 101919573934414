import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function PauseIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 8.9999997,15.999999 H 11 V 7.9999998 H 8.9999997 Z M 12,1.9999999 C 6.4799999,1.9999999 2,6.4799998 2,12 2,17.52 6.4799999,21.999999 12,21.999999 17.52,21.999999 21.999999,17.52 21.999999,12 21.999999,6.4799998 17.52,1.9999999 12,1.9999999 Z M 12,19.999999 C 7.5899998,19.999999 3.9999999,16.41 3.9999999,12 3.9999999,7.5899998 7.5899998,3.9999999 12,3.9999999 c 4.41,0 7.999999,3.5899999 7.999999,8.0000001 0,4.41 -3.589999,7.999999 -7.999999,7.999999 z m 1,-4 h 2 V 7.9999998 h -2 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
