export interface ITreatedFieldJson {
  field_name: string
  description: string
  mandatory: boolean
}

export class TreatedField {
  fieldName: string = ''
  description: string = ''
  mandatory: boolean = false

  constructor() {}

  public static serialize(obj: TreatedField): ITreatedFieldJson {
    return {
      field_name: obj.fieldName,
      description: obj.description,
      mandatory: obj.mandatory,
    }
  }

  public static deserialize(json: ITreatedFieldJson): TreatedField {
    const res = new TreatedField()
    res.fieldName = json.field_name
    res.description = json.description
    res.mandatory = json.mandatory
    return res
  }

  public static serializeArray(objs: TreatedField[]): ITreatedFieldJson[] {
    const jsons = objs.map((p) => {
      return TreatedField.serialize(p)!
    })
    return jsons
  }

  public static deserializeArray(json: ITreatedFieldJson[]): TreatedField[] {
    const res = json.map((p) => {
      return TreatedField.deserialize(p)!
    })
    return res
  }
}
