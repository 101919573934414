import { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import _ from 'lodash'

import msgIds from '../../locales/msgIds'
import * as dalContract from '../../dal/DalContract'
import * as dalContractVersion from '../../dal/DalContractVersion'
import { Contract } from '../../models/Contract'
import { Box, Button, IconButton, Paper, Typography, useMediaQuery, useTheme } from '@mui/material'
import { IContractPageInit } from './ContractPage.types'
import { GridCellParams, GridColDef } from '@mui/x-data-grid'
import {
  ContractType,
  ContractTypeTranslationMap,
  ContractVersionState,
  ContractVersionStateColorMap,
  ContractVersionStateTranslationMap,
} from '../../shared/Constants'
import { ContractVersion } from '../../models/ContractVersion'
import CommandBar from '../../components/commandBar/CommandBar'
import { ICommand } from '../../components/commandBar/CommandBar.types'
import { DownloadIco, PrivacyNewIco } from '../../components/icons'
import ResponsiveDataGrid from '../../components/responsiveDataGrid/ResponsiveDataGrid'
import { Utils } from '../../shared/Utils'
import { createBlob } from '../../contexts/DocumentCacheContext'

export default function ContractPage() {
  const location = useLocation()
  const state = location.state as IContractPageInit
  const navigate = useNavigate()
  const theme = useTheme()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const [contract, setContract] = useState<Contract>() // to show items

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // ********************
  // component mounting
  useEffect(() => {
    // TODO exidea: check init params and verify if login or other actions are required

    if (!isLoading) {
      loadContract()
    }
  }, [])

  // ********************
  // data fetch
  async function loadContract() {
    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const data = await dalContract.getContract(abortController.signal, state.contractId)
      setContract(data)
    } catch (err) {
      enqueueSnackbar(t(Utils.getErrorMessageId(err)), { variant: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  // ********************
  // data changes
  async function createNewContractVersion() {
    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const data = await dalContractVersion.createNewContractVersion(
        abortController.signal,
        contract!.id,
        contract?.versions.length === 0
      )
      const updatedContract = _.cloneDeep(contract) ?? new Contract()
      updatedContract.versions.push(data)
      setContract(updatedContract)
      navigate('/contracts/versions/editor', { state: { contractId: state.contractId, versionId: data.id } })
    } catch (err) {
      enqueueSnackbar(t(Utils.getErrorMessageId(err)), { variant: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  async function downloadContractVersion(contractVersion: ContractVersion) {
    if (!contractVersion) return

    const abortController = new AbortController()
    try {
      const arrayBuffer = await dalContractVersion.downloadContractVersion(
        abortController.signal,
        contractVersion.contractId,
        contractVersion.id
      )
      const blob = createBlob([arrayBuffer], 'application/pdf')
      downloadBlob(blob, state.contractType)
    } catch (error) {
      Utils.enqueueSnackbarError2(error, t)
    }
  }

  function downloadBlob(blob: Blob, contractType: ContractType) {
    const objectUrl = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = objectUrl
    a.download = t(ContractTypeTranslationMap[contractType])
    document.body.appendChild(a)
    a.click()

    // Clean up
    setTimeout(() => {
      document.body.removeChild(a)
      URL.revokeObjectURL(objectUrl)
    }, 100)
  }

  const handleCellClick = ({ row, field, ...params }: GridCellParams) => {
    if (field !== 'downloadLink') {
      //console.log('handleCellClick', row, field)
      navigate('/contracts/versions/editor', { state: { contractId: state.contractId, versionId: row.id } })
    }
  }

  const columns: GridColDef<ContractVersion>[] = [
    {
      field: 'version',
      headerName: t(msgIds.MSG_CONTRACT_VERSION).toString(),
      minWidth: 120,
      maxWidth: 250,
      flex: 0.5,
      renderCell: ({ row }) => (
        <Typography>
          {t(msgIds.MSG_CONTRACT_VERSION)} {row.majorVersion}.{row.minorVersion}
        </Typography>
      ),
    },
    {
      field: 'state',
      headerName: t(msgIds.MSG_CONTRACT_STATE).toString(),
      minWidth: 120,
      maxWidth: 250,
      flex: 0.5,
      renderCell: ({ row }) => (
        <Typography color={ContractVersionStateColorMap[row?.state || ContractVersionState.none]}>
          {t(ContractVersionStateTranslationMap[row.state])}
        </Typography>
      ),
    },
    {
      field: 'updatedAt',
      type: 'dateTime',
      valueGetter: ({ row }) => {
        if (!row.updatedAt) {
          return null
        }
        try {
          return new Date(row.updatedAt)
        } catch (e) {
          return null
        }
      },
      headerName: t(msgIds.MSG_CONTRACT_DATE).toString(),
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'downloadLink',
      headerName: t(msgIds.MSG_CONTRACT_DOWNLOAD).toString(),
      flex: 0.5,
      minWidth: 100,
      renderCell: ({ value, row }) =>
        isMobile ? (
          <Button
            sx={{ p: 0 }}
            variant="text"
            size="small"
            onClick={(e) => {
              console.log(e)
              downloadContractVersion(row)
            }}
          >
            <DownloadIco sx={{ mr: 0.5, transform: 'translateY(-1px)' }} /> Download
          </Button>
        ) : (
          <IconButton
            size={isMobile ? 'small' : 'medium'}
            onClick={(e) => {
              console.log(row)
              downloadContractVersion(row)
            }}
          >
            <DownloadIco />
          </IconButton>
        ),
    },
  ]

  const commands: ICommand[] = [
    {
      commandText: t(msgIds.MSG_CONTRACT_NEW_VERSION).toString(),
      icon: <PrivacyNewIco />,
      onClick: () => {
        createNewContractVersion()
      },
      tooltipText: '',
      disabled: contract?.versions.some((p) => p.state === ContractVersionState.draft) ?? true,
    },
  ]

  return (
    <>
      <Box>
        <CommandBar title={t(msgIds.MSG_CONTRACT_PRIVACY_POLICY)} commands={commands} />
      </Box>
      <Box p={2} my={2}>
        <Paper>
          <ResponsiveDataGrid
            sx={{ minHeight: '120px' }}
            rows={contract?.versions || []}
            columns={columns}
            onCellClick={handleCellClick}
            hideFooter={true}
            initialState={{
              sorting: {
                sortModel: [{ field: 'updatedAt', sort: 'desc' }],
              },
            }}
            disableRowSelectionOnClick={true}
            disableColumnMenu={true}
          />
        </Paper>
      </Box>
    </>
  )
}
