import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function DoxAddIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 15.522736,13.983496 h -0.0144 v 0.515115 h -2.509521 v 2.534925 H 11.000337 V 14.498611 H 8.4450956 v -1.99899 H 11.000337 V 9.8966254 h 1.998478 v 2.6029956 h 2.509521 v 0.477 h 0.0144 z m 4.997198,-8.8234808 c 0.240279,0.400305 0.480067,0.800115 0.480067,1.280175 V 18.999991 c 0,1.03989 -0.960121,2.00001 -2.000004,2.00001 H 5.0000058 c -1.1201491,0 -2.0000056,-0.96012 -2.0000056,-2.00001 V 6.4401902 c 0,-0.48006 0.1600275,-0.87987 0.4003065,-1.280175 L 4.8399783,3.4800751 C 5.0802708,3.16002 5.4800583,3 5.9601274,3 H 17.960113 c 0.480069,0 0.879857,0.16002 1.120149,0.4800751 z M 6.1999024,5.0000101 5.3997933,5.9601302 H 18.519928 L 17.720338,5.0000101 Z M 18.999996,18.999991 V 7.9601103 H 5.0000058 V 18.999991 H 18.999996"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
