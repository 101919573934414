import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function SelectedIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 18.999999,3 H 4.9999999 C 3.8999998,3 3,3.8999998 3,4.9999999 V 18.999999 C 3,20.1 3.8999998,21 4.9999999,21 H 18.999999 C 20.1,21 21,20.1 21,18.999999 V 4.9999999 C 21,3.8999998 20.1,3 18.999999,3 Z m 0,15.999999 H 4.9999999 V 4.9999999 H 18.999999 Z m -1.01,-9.9999993 -1.41,-1.4199999 L 9.9899997,14.17 7.4099998,11.6 l -1.42,1.41 3.9999999,3.989999 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
