import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  LinearProgress,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import { useCallback, useEffect, useMemo, useRef, useState, forwardRef, useImperativeHandle } from 'react'
import {
  AddToDesktopIco,
  ArchiveIco,
  CloseIco,
  DoxIco,
  DraftIco,
  EditIco,
  ExpandMoreIco,
  InfoIco,
  PreviewIco,
  RetentionIco,
  SaveIco,
  VersionsIco,
} from '../icons'
import log from '../../shared/Logger'
import DocumentInfoForm from './DocumentInfoForm'
import DocumentInfoDetails from './DocumentInfoDetails'
import DocumentDoxDetails from './DocumentDoxDetails'
import DocumentContent from './DocumentContent'
import { ActionType, isBusiness, isOperator, isPersonalDoxMimetype } from '../../shared/Constants'
import DocumentUpload from './DocumentUpload'
import DocumentRevisionsTable from './DocumentRevisionsTable'
import { useAuthContext } from '../../contexts/AuthContext'
import DocumentRetentions from './DocumentRetentions'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'
import { useLayout } from '../../hooks/Layout'
import _ from 'lodash'
import { IImageCommandProps } from '../imageCommand/ImageCommand.types'
import ImageCommand from '../imageCommand/ImageCommand'
import { Utils } from '../../shared/Utils'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'
import { ISimpleDialogData } from '../../dialogs/simpleDialog/SimpleDialog.types'
import { Document } from '../../models/Document'
import { useWorkingDocumentsContext } from '../../contexts/WorkingDocumentsContext'
import { useSnackbar } from 'notistack'
import MarkdownEditorNew from '../markdownEditorNew/MarkdownEditorNew'
import { MarkdownEditorNewRef } from '../markdownEditorNew/MarkdownEditorNew.types'
import { useDocumentEditorStateNew } from './DocumentEditorStateHooks'

export interface IDocumentEditorNewProps {
  documentIID: string
  selectedRevisionId?: number
  isEditable: boolean
  isDesktop: boolean
  onDraftNameChanged?: (documentIID: string, namne: string) => void
  onClosingDocumentEditor: (document?: Document, remove?: boolean) => void
  onEditedDocumentAssignedDoxChanged: (doxIds: number[]) => void
}

export interface DocumentEditorNewRef {
  onCloseDocumentEditor: () => void
}

const DocumentEditorNew = forwardRef((props: IDocumentEditorNewProps, ref) => {
  const { isEditable, selectedRevisionId, onDraftNameChanged } = props
  const [documentIID, setDocumentIID] = useState(props.documentIID)
  const wd = useWorkingDocumentsContext()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const { isMobile } = useLayout()
  const authContext = useAuthContext()
  const [tabIndex, setTabIndex] = useState(0)
  const [isToClose, setIsToClose] = useState(false)

  useEffect(() => {
    if (props.documentIID !== documentIID) {
      pushDocumentEditorState(false, tabIndex, canSave, isEditing)
    }
    setDocumentIID(props.documentIID)
  }, [props.documentIID])

  const {
    document,
    revision,
    isTransmitting,
    progress,
    updateCacheContent,
    isEditing,
    draftMarkdownContentRef,
    draftMarkdownChecksumRef,
    areDraftChanges,
    canSave,
    canArchive,
    restoreDraftContent,
    setDocument,
    setRevision,
    evaluateDraftChanges,
    pushDocumentEditorState,
    pullDocumentEditorState,
  } = useDocumentEditorStateNew({
    documentIID,
    tabIndex,
    selectedRevisionId,
    onInitDraftMarkdownEditor,
  })

  const editorRef = useRef<MarkdownEditorNewRef | null>(null)

  // dialogs
  const [simpleDialogData, setSimpleDialogData] = useState<ISimpleDialogData>()
  const [simpleDialogOpen, setSimpleDialogOpen] = useState(false)

  const canCreateDraft = document && document.authorProfileId === authContext.loggedProfileId
  const commands: IImageCommandProps[] = useMemo(() => {
    const commonComands = () => {
      if (!document) return []

      const archivedRevision = document.getLastArchivedRevision()
      const draftRevision = document.getDraftRevision()
      if (isEditing) {
        return [closeDraftCommand, saveCommand, archiveCommand]
      } else {
        if (!archivedRevision && draftRevision) {
          return [contentCommand, infoCommand, doxCommand, revisionsCommand, editDraftCommand, archiveCommand]
        } else if (archivedRevision && draftRevision) {
          return [contentCommand, infoCommand, doxCommand, revisionsCommand, editDraftCommand, archiveCommand]
        } else if (archivedRevision && !draftRevision) {
          if (canCreateDraft) {
            return [contentCommand, infoCommand, doxCommand, revisionsCommand, createDraftCommand]
          } else {
            return [contentCommand, infoCommand, doxCommand, revisionsCommand]
          }
        }
      }
      return []
    }

    let _commands =
      isBusiness(authContext.loggedProfileType) && !isEditing
        ? commonComands().concat([retentionCommand])
        : commonComands()
    if (!isEditable) {
      _commands = _commands.filter((p) => !['createDraft', 'editDraft', 'closeDraft', 'save', 'archive'].includes(p.id))
      _commands.push(addToDesktopCommand)
    }

    return _commands
  }, [isEditing, document, canCreateDraft, authContext.loggedProfileType])

  const sections = useMemo(() => commands.filter(({ type }) => type === 'section'), [commands])
  useEffect(() => {
    const _sectionIndex = sections.length > tabIndex ? tabIndex : 0
    setTabIndex(_sectionIndex)
  }, [sections.length])

  const section: IImageCommandProps = sections[tabIndex] || contentCommand

  const onSelectedRevisionChanged = useCallback(
    (revisionId: number, showContent: boolean) => {
      wd.updateDocumentEditorState(documentIID, { revisionId: revisionId })
      if (showContent) {
        setTabIndex(0)
      }
    },
    [document]
  )

  function canDo(commandId: string) {
    switch (commandId) {
      case 'save':
        return canSave
      case 'archive':
        return canArchive
      default:
        return true
    }
  }

  async function onClickAction(commandId: string) {
    if (commandId === 'createDraft') {
      wd.updateDocumentEditorState(documentIID, { isEditing: true })
      setTabIndex(0)
    } else if (commandId === 'editDraft') {
      wd.updateDocumentEditorState(documentIID, { isEditing: true })
      setTabIndex(0)
    } else if (commandId === 'closeDraft') {
      manageCloseDraftCommand()
    } else if (commandId === 'save') {
      await manageSaveCommand()
    } else if (commandId === 'archive') {
      await manageArchiveCommand()
    } else if (commandId === 'addToDesktop') {
      if (
        isOperator(authContext.loggedProfileType) &&
        document.targetProfileId !== authContext.assistedAccountProfileId
      ) {
        setSimpleDialogOpen(true)
        setSimpleDialogData({
          title: t(msgIds.MSG_DOCUMENT_SHOW_TITLE),
          content: t(msgIds.MSG_DOCUMENT_WRONG_ASSISTED_CUSTUMER),
          actionsStyle: 'Ok',
          onClose: async () => {
            setSimpleDialogOpen(false)
          },
        })
      } else {
        const workingDocument = wd.workingDocuments.find((p) => p.documentIID === document.iid)
        if (workingDocument && !workingDocument?.isInDesktop) {
          wd.putInDesktop(documentIID)
        }
      }
    } else {
      log.error({ DocumentEditorOnClickAction: 'unknown command', commandId })
    }
  }

  function manageCloseDraftCommand() {
    if (areDraftChanges) {
      setSimpleDialogOpen(true)
      setSimpleDialogData({
        title: t(msgIds.MSG_DOCUMENT),
        content: `${revision?.name}\n\n${t(msgIds.MSG_DOCUMENT_CHANGES_WILL_BE_LOST)}\n${t(msgIds.MSG_DO_YOU_CONFIRM)}`,
        actionsStyle: 'yesNO',
        onClose: (result: any) => {
          setSimpleDialogOpen(false)
          if (result.userChoice === 'yes') {
            doStopEditingMode()
          }
        },
      })
    } else {
      doStopEditingMode()
    }
  }

  function doStopEditingMode() {
    wd.updateDocumentEditorState(documentIID, { isEditing: false })
    if (document.documentId === 0) {
      props.onClosingDocumentEditor(document)
    }
  }

  useImperativeHandle(ref, () => ({
    onCloseDocumentEditor,
  }))

  function onCloseDocumentEditor() {
    const { canSave } = evaluateDraftChanges()
    pushDocumentEditorState(true, tabIndex, canSave, false)
    setIsToClose(true)
  }

  useEffect(() => {
    if (isToClose) {
      props.onClosingDocumentEditor(document)
    }
  }, [isToClose])

  async function manageSaveCommand() {
    try {
      pushDocumentEditorState(false, tabIndex, canSave, isEditing)
      const _documentIID = await wd.saveDocument(documentIID)
      if (_documentIID !== documentIID) {
        setDocumentIID(_documentIID)
      }
      pullDocumentEditorState(_documentIID)
      updateCacheContent(_documentIID)
      enqueueSnackbar(t(msgIds.MSG_DOCUMENT_SAVED_SUCCESSFULLY), { variant: 'success' })
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    }
  }

  async function manageArchiveCommand() {
    if (!authContext.loggedAccount?.canDo(ActionType.updateDocuments)) {
      setSimpleDialogOpen(true)
      setSimpleDialogData({
        title: t(msgIds.MSG_DOCUMENT_EDITOR_COMMAND_ARCHIVE),
        content: t(msgIds.MSG_ERR_ACTION_TYPE_NOT_ALLOWED),
        actionsStyle: 'Ok',
        onClose: () => {
          setSimpleDialogOpen(false)
        },
      })
    } else {
      try {
        await wd.archiveDocument(documentIID)
        updateCacheContent(documentIID)
        enqueueSnackbar(t(msgIds.MSG_DOCUMENT_SAVED_SUCCESSFULLY), { variant: 'success' })
      } catch (err) {
        Utils.enqueueSnackbarError2(err, t)
      }
    }
  }

  function initializeMarkdownEditor() {
    if (isEditing && revision && isPersonalDoxMimetype(revision.mimetype) === true && editorRef.current) {
      editorRef.current?.initializeText(draftMarkdownContentRef.current, draftMarkdownChecksumRef.current)
    }
  }

  function onInitDraftMarkdownEditor() {
    editorRef.current?.initializeText(draftMarkdownContentRef.current, draftMarkdownChecksumRef.current)
  }

  const handleContentChanged = useCallback(
    (md: string, checksum: string) => {
      draftMarkdownContentRef.current = md
      draftMarkdownChecksumRef.current = checksum
      evaluateDraftChanges()
    },
    [evaluateDraftChanges]
  )

  const fontSize = isMobile ? 12 : undefined
  return (
    <Stack justifyContent="stretch" sx={{ flex: 1, overflow: 'hidden' }}>
      {simpleDialogData && <SimpleDialog {...simpleDialogData} isOpen={simpleDialogOpen}></SimpleDialog>}

      <Stack direction="row" alignItems="stretch">
        {!isEditing && (
          <>
            <Tabs
              value={tabIndex}
              onChange={(_, index) => setTabIndex(index)}
              sx={{ flexShrink: 0 }}
              scrollButtons="auto"
            >
              {commands
                .filter((c) => c.type === 'section')
                .map((command) => (
                  <Tab
                    key={command.id}
                    icon={command.icon}
                    wrapped={isMobile}
                    sx={{ fontSize, textTransform: 'none' }}
                    label={command.label ? t(command.label) : ''}
                  />
                ))}
            </Tabs>
            <Divider orientation="vertical" flexItem />
          </>
        )}
        {commands
          .filter((c) => c.type === 'action')
          .map((command) => (
            <Box key={command.id} display={'flex'} height={72}>
              <ImageCommand {...command} onClick={onClickAction} isEnabled={canDo(command.id)} />
            </Box>
          ))}
      </Stack>
      <Divider />
      {isTransmitting ? (
        <Stack padding={2} spacing={2} alignItems="stretch" justifyContent="center">
          <Typography align="center">{t(msgIds.MSG_DOCUMENT_EDITOR_TRANSMISSION_IN_PROGRESS)}</Typography>
          <LinearProgress variant="determinate" value={progress} />
        </Stack>
      ) : (
        <>
          {isEditing && revision && document ? (
            <>
              {section.id === 'content' && (
                <Stack
                  flex={1}
                  flexDirection={isMobile ? 'column-reverse' : 'row'}
                  alignItems="stretch"
                  sx={{ overflow: 'auto' }}
                >
                  {isPersonalDoxMimetype(revision.mimetype) === true && (
                    <MarkdownEditorNew
                      ref={editorRef}
                      height="unset"
                      style={{ flexGrow: 1, boxSizing: 'border-box' }}
                      onEditorLoaded={initializeMarkdownEditor}
                      onContentChanged={handleContentChanged}
                    />
                  )}
                  {isPersonalDoxMimetype(revision.mimetype) === false && (
                    <DocumentUpload
                      errors={{}}
                      style={{ flexGrow: 1 }}
                      revision={revision}
                      onChangeRevision={setRevision}
                      onRestoreSavedDraftContent={() => {
                        restoreDraftContent()
                      }}
                    />
                  )}
                  {isPersonalDoxMimetype(revision.mimetype) === undefined && (
                    <Box width="100%" height="100%">
                      <Typography>{t(msgIds.MSG_DOCUMENT_EDITOR_DRAFT_PREPARATION_IN_PROGRESS)}</Typography>
                    </Box>
                  )}
                  <Divider orientation="vertical" flexItem />

                  <Accordion elevation={0} defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIco />}>
                      <Typography variant="body1">
                        <strong>{t(msgIds.MSG_DOCUMENT_EDITOR_INFO)}</strong>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack style={{ overflowY: 'auto', width: isMobile ? 'auto' : '435px' }}>
                        <DocumentInfoForm
                          style={{ width: isMobile ? 'auto' : '400px' }}
                          document={document}
                          onChangeDocument={setDocument}
                          revision={revision}
                          onChangeRevision={(documentIID, revision) => {
                            setRevision(revision)
                            onDraftNameChanged && onDraftNameChanged(documentIID, revision.name)
                          }}
                        />
                        <DocumentDoxDetails
                          applyChangesNow={!(isEditing && document.documentId === 0)}
                          document={document}
                          onEditedDocumentAssignedDoxChanged={props.onEditedDocumentAssignedDoxChanged}
                          isEditable={isEditable}
                        />
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </Stack>
              )}
            </>
          ) : (
            <Stack justifyContent="stretch" alignItems="stretch" sx={{ flex: 1, overflow: 'auto' }}>
              {revision && section.id === 'info' && <DocumentInfoDetails document={document} revision={revision} />}
              {revision && section.id === 'dox' && (
                <DocumentDoxDetails
                  applyChangesNow={!(isEditing && document.documentId === 0)}
                  document={document}
                  onEditedDocumentAssignedDoxChanged={props.onEditedDocumentAssignedDoxChanged}
                  isEditable={isEditable}
                />
              )}
              {revision && section.id === 'content' && (
                <DocumentContent document={document} revision={revision} isLoading={isTransmitting} />
              )}
              {revision && section.id === 'revisions' && (
                <Box padding={2}>
                  <DocumentRevisionsTable
                    document={document}
                    revision={revision}
                    onChangeRevisionId={onSelectedRevisionChanged}
                  />
                </Box>
              )}
              {revision && section.id === 'retention' && (
                <Box padding={2}>
                  <DocumentRetentions
                    document={document}
                    isEditable={isEditable}
                    onClosingDocumentEditor={props.onClosingDocumentEditor}
                  />
                </Box>
              )}
            </Stack>
          )}
        </>
      )}
    </Stack>
  )
})

export default DocumentEditorNew

const infoCommand: IImageCommandProps = {
  id: 'info',
  icon: <InfoIco />,
  label: msgIds.MSG_DOCUMENT_EDITOR_COMMAND_INFO,
  type: 'section',
  orientation: 'column',
}
const doxCommand: IImageCommandProps = {
  id: 'dox',
  icon: <DoxIco />,
  label: msgIds.MSG_DOCUMENT_EDITOR_COMMAND_DOX,
  type: 'section',
  orientation: 'column',
}
const createDraftCommand: IImageCommandProps = {
  id: 'createDraft',
  icon: <EditIco sx={{ color: 'gray' }} />,
  label: msgIds.MSG_DOCUMENT_EDITOR_COMMAND_CREATE_DRAFT,
  type: 'action',
  orientation: 'column',
}
const editDraftCommand: IImageCommandProps = {
  id: 'editDraft',
  icon: <DraftIco sx={{ color: 'gray' }} />,
  label: msgIds.MSG_DOCUMENT_EDITOR_COMMAND_EDIT_DRAFT,
  type: 'action',
  orientation: 'column',
}
const closeDraftCommand: IImageCommandProps = {
  id: 'closeDraft',
  icon: <CloseIco />,
  label: msgIds.MSG_DOCUMENT_EDITOR_COMMAND_CLOSE_DRAFT,
  type: 'action',
  orientation: 'column',
}
const contentCommand: IImageCommandProps = {
  id: 'content',
  icon: <PreviewIco />,
  label: msgIds.MSG_DOCUMENT_EDITOR_COMMAND_CONTENT,
  type: 'section',
  orientation: 'column',
}
const revisionsCommand: IImageCommandProps = {
  id: 'revisions',
  icon: <VersionsIco />,
  label: msgIds.MSG_DOCUMENT_EDITOR_COMMAND_REVISIONS,
  type: 'section',
  orientation: 'column',
}
const retentionCommand: IImageCommandProps = {
  id: 'retention',
  icon: <RetentionIco />,
  label: msgIds.MSG_DOCUMENT_EDITOR_COMMAND_RETENTION,
  type: 'section',
  orientation: 'column',
}
const saveCommand: IImageCommandProps = {
  id: 'save',
  icon: <SaveIco />,
  label: msgIds.MSG_DOCUMENT_EDITOR_COMMAND_SAVE,
  type: 'action',
  orientation: 'column',
}
const archiveCommand: IImageCommandProps = {
  id: 'archive',
  icon: <ArchiveIco />,
  label: msgIds.MSG_DOCUMENT_EDITOR_COMMAND_ARCHIVE,
  type: 'action',
  orientation: 'column',
}
const addToDesktopCommand: IImageCommandProps = {
  id: 'addToDesktop',
  icon: <AddToDesktopIco />,
  label: msgIds.MSG_DOC_COMMAND_ADD_DOCUMENT_TO_DESKTOP,
  type: 'action',
  orientation: 'column',
}
