import { HistoryContextDox, IHistoryContextDoxJson } from './HistoryContextDox'

export interface IHistoryContextDoxUpdateJson {
  old?: IHistoryContextDoxJson
  new?: IHistoryContextDoxJson
}

export class HistoryContextDoxUpdate {
  old?: HistoryContextDox
  new?: HistoryContextDox

  constructor() {}

  public static serialize(obj: HistoryContextDoxUpdate): IHistoryContextDoxUpdateJson {
    return {
      old: obj.old ? HistoryContextDox.serialize(obj.old) : undefined,
      new: obj.new ? HistoryContextDox.serialize(obj.new) : undefined,
    }
  }

  public static deserialize(json: IHistoryContextDoxUpdateJson): HistoryContextDoxUpdate {
    const res = new HistoryContextDoxUpdate()
    res.old = json.old ? HistoryContextDox.deserialize(json.old) : undefined
    res.new = json.new ? HistoryContextDox.deserialize(json.new) : undefined
    return res
  }
}
