import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAuthContext } from '../../contexts/AuthContext'
import msgIds from '../../locales/msgIds'
import * as dalTreatment from '../../dal/DalTreatment'
import { Box, Checkbox, FormControlLabel, Paper, useMediaQuery, useTheme } from '@mui/material'
import { ITreatmentsPageInit } from '../TreatmentsPage/TreatmentsPage.types'
import { Treatment } from '../../models/Treatment'
import {
  CustomerVisibilityRules,
  RetentionDurationTranslationMap,
  RetentionDurationTranslationMapPlural,
  TreatmentLegalBase,
  TreatmentLegalBaseTranslationMap,
} from '../../shared/Constants'
import CommandBar from '../../components/commandBar/CommandBar'
import { ICommand } from '../../components/commandBar/CommandBar.types'
import { InterdictIco, PrivacyNewIco } from '../../components/icons'
import { GridColDef } from '@mui/x-data-grid'
import ResponsiveDataGrid from '../../components/responsiveDataGrid/ResponsiveDataGrid'
import { Utils } from '../../shared/Utils'

export default function TreatmentsPage() {
  const authContext = useAuthContext()
  const location = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()
  const [state, setState] = useState<ITreatmentsPageInit>(location.state)
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [treatments, setTreatments] = useState<Treatment[]>([]) // to show items
  const [allTreatments, setAllTreatments] = useState<Treatment[]>([])
  const [showOnlyActive, setShowOnlyActive] = useState(true) // to filter items

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    // TODO exidea: check init params and verify if login or other actions are required

    const loadTreatments = async (abortSignal: AbortSignal) => {
      try {
        setIsLoading(true)
        const data = await dalTreatment.getTreatments(
          abortSignal,
          authContext.linkedStructureProfileId || 0,
          false,
          false
        )
        setAllTreatments(data)
        setTreatments(showOnlyActive ? data.filter((p) => !p.disabledAt) : [...data])
      } catch (err) {
        Utils.enqueueSnackbarError2(err, t)
      } finally {
        setIsLoading(false)
      }
    }

    const abortController = new AbortController()
    loadTreatments(abortController.signal)

    return () => {
      abortController.abort()
    }
  }, [])

  useEffect(() => {
    setTreatments(showOnlyActive ? allTreatments.filter((p) => !p.disabledAt) : [...allTreatments])
  }, [showOnlyActive])

  // ********************
  // data changes
  async function createNewTreatment() {
    try {
      setIsLoading(true)
      // TODO Exidea: if are loaded only active treatments the new interpolated internal code can be already used
      const parsedTreatmentCodes: number[] =
        allTreatments.length > 0
          ? allTreatments
              .map((tr) => /TT(\d+)/.exec(tr.code))
              .filter((code) => code && code?.length == 2)
              .map((match: RegExpExecArray | null) => (match ? parseInt(match[1]) : -1))
          : [0]
      const newCodeIndex = Math.max(...parsedTreatmentCodes) + 1
      const newCode = `TT${newCodeIndex.toFixed(0).padStart(3, '0')}`
      const abortController = new AbortController()
      const data = await dalTreatment.createNewTreatment(
        abortController.signal,
        newCode,
        TreatmentLegalBase.explicitConsent,
        CustomerVisibilityRules.documentsRetention
      )
      setAllTreatments([...allTreatments, data])
      setAllTreatments([...treatments, data])
      navigate('/treatments/editor', { state: { treatmentId: data.id } })
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  // ********************
  // command bar
  const commands: ICommand[] = [
    {
      commandText: t(msgIds.MSG_NEW_TREATMENT).toString(),
      icon: <PrivacyNewIco />,
      onClick: () => {
        createNewTreatment()
      },
      tooltipText: t(msgIds.MSG_NEW_TREATMENT_TOOLTIP_TEXT).toString(),
    },
  ]

  const columns: GridColDef<Treatment>[] = [
    {
      field: 'code',
      headerName: t(msgIds.MSG_TREATMENT_ACTIVITIES_CODE).toString(),
      minWidth: 80,
      flex: 0.5,
      renderCell: ({ value, row }) => value,
    },
    {
      field: 'shortDescription',
      headerName: t(msgIds.MSG_TREATMENT_FINALITY).toString(),
      minWidth: 250,
      maxWidth: 400,
      flex: 1,
    },
    {
      field: 'legalBase',
      headerName: t(msgIds.MSG_TREATMENT_LEGAL_BASE).toString(),
      minWidth: 150,
      flex: 0.5,
      renderCell: ({ row }) => <span>{t(TreatmentLegalBaseTranslationMap[row.legalBase])}</span>,
    },
    {
      field: 'retentionDurationCount',
      headerName: t(msgIds.MSG_TREATMENT_RETENTION).toString(),
      flex: 0.5,
      minWidth: 100,
      renderCell: ({ value, row }) => (
        <>
          {value > 0 ? (
            <span>
              {value}{' '}
              {t(
                value === 1
                  ? RetentionDurationTranslationMap[row.retentionDurationBase]
                  : RetentionDurationTranslationMapPlural[row.retentionDurationBase]
              )}
            </span>
          ) : (
            <span></span>
          )}
        </>
      ),
    },
    {
      field: 'disabledAt',
      headerName: t(msgIds.MSG_TREATMENT_NOT_ACTIVE).toString(),
      flex: 0.5,
      minWidth: 50,
      renderCell: ({ value }) => (value ? <InterdictIco fontSize={isMobile ? 'small' : 'medium'} /> : ''),
    },
  ]

  return (
    <>
      <Box>
        <CommandBar title={t(msgIds.MSG_TREATMENT_ACTIVITIES)} commands={commands} />
      </Box>
      <Box p={2} my={2}>
        <Paper>
          <Box m={1}>
            <FormControlLabel
              control={<Checkbox checked={showOnlyActive} onChange={() => setShowOnlyActive(!showOnlyActive)} />}
              label={t(msgIds.MSG_SHOW_ONLY_ACTIVE_TREATMENTS)}
            />
          </Box>
          <ResponsiveDataGrid
            sx={{ minHeight: '100px' }}
            rows={treatments}
            columns={columns}
            onRowClick={({ row }) => navigate('/treatments/editor', { state: { treatmentId: row.id } })}
            hideFooter={true}
            disableRowSelectionOnClick={true}
            disableColumnMenu={true}
          />
        </Paper>
      </Box>
    </>
  )
}
