import { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAuthContext } from '../../contexts/AuthContext'
import msgIds from '../../locales/msgIds'
import * as dalContractVersion from '../../dal/DalContractVersion'
import * as dalContractSection from '../../dal/DalContractSection'
import * as dalContract from '../../dal/DalContract'
import { Box, Paper, Stack, Typography, useTheme } from '@mui/material'
import _ from 'lodash'
import { ContractVersionsComparation, IContractVersionEditorPageInit } from './ContractVersionEditorPage.types'
import { ContractVersion } from '../../models/ContractVersion'
import {
  isCollaborator,
  ConsentMode,
  ContractType,
  ContractVersionState,
  ContractVersionStateColorMap,
  ContractVersionStateTranslationMap,
  ActionType,
} from '../../shared/Constants'
import CommandBar from '../../components/commandBar/CommandBar'
import { ICommand } from '../../components/commandBar/CommandBar.types'
import { DeleteIco, NewReleasesIco, SectionAddIco } from '../../components/icons'
import * as dalTreatment from '../../dal/DalTreatment'
import { Treatment } from '../../models/Treatment'
import { ContractSection } from '../../models/ContractSection'
import { ContractSectionAction } from '../../models/ContractSectionAction'
import { Utils, dateTimeShortOptions } from '../../shared/Utils'
import { Contract } from '../../models/Contract'
import { ISimpleDialogData } from '../../dialogs/simpleDialog/SimpleDialog.types'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'
import { ContractInfoEditorForm } from '../../components/contractInfoEditor/ContractInfoEditorForm'
import { PageContainer, PageContent } from '../../components/pageContainer/PageContainer'
import { ContractSectionEditorForm } from '../../components/contractSectionEditor/ContracSectionEditorForm'
import { useScrollToRef } from '../../hooks/ScrollTo'

export default function ContractVersionEditorPage() {
  const authContext = useAuthContext()
  const navigate = useNavigate()
  const theme = useTheme()
  const [sectionReference, setSectionReference] = useState<string | undefined>(undefined)
  const setRef = useScrollToRef(sectionReference)

  const location = useLocation()
  const [state, setState] = useState<IContractVersionEditorPageInit>(location.state)
  const { t, i18n } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [contract, setContract] = useState<Contract>() // to show items
  const [contractVersion, setContractVersion] = useState<ContractVersion>() // to show items
  const [versionsComparation, setVersionsComparation] = useState<ContractVersionsComparation>()
  const [treatments, setTreatments] = useState<Treatment[]>([])
  const [contractType, setContractType] = useState<ContractType>(ContractType.none)
  const [isAuthorizedToEdit, setIsAuthorizedToEdit] = useState(false)

  // dialogs
  const [simpleDialogData, setSimpleDialogData] = useState<ISimpleDialogData>()
  const [simpleDialogOpen, setSimpleDialogOpen] = useState(false)

  // ********************
  // component mounting
  useEffect(() => {
    // TODO exidea: check init params and verify if login or other actions are required

    const abortController = new AbortController()
    loadContractVersion(abortController.signal)

    return () => {
      abortController.abort()
    }
  }, [isAuthorizedToEdit])

  // ********************
  // data fetch
  async function loadContractVersion(abortSignal: AbortSignal) {
    try {
      setIsLoading(true)
      const treatments = await dalTreatment.getTreatments(
        abortSignal,
        authContext.linkedStructureProfileId || 0,
        true,
        false
      )
      setTreatments(treatments)

      let version: ContractVersion | undefined
      const contract = await dalContract.getContract(abortSignal, state.contractId!)
      setContract(contract)
      setContractType(contract?.type)
      version = contract?.versions.find((p) => p.id === state.versionId)
      setContractVersion(version)

      const publishedVersion = contract.publishedVersion
      const draftVersion = contract.draftVersion
      const comparation =
        publishedVersion && draftVersion
          ? comparePrivacyPolicyVersions(publishedVersion, draftVersion)
          : new ContractVersionsComparation()
      setVersionsComparation(comparation)

      setIsAuthorizedToEdit(
        (version?.state === ContractVersionState.draft &&
          (contract?.type !== ContractType.privacyPolicy ||
            (contract?.type === ContractType.privacyPolicy &&
              (!isCollaborator(authContext.loggedProfileType) ||
                (isCollaborator(authContext.loggedProfileType) &&
                  authContext.loggedAccount?.canDo(ActionType.updatePrivacyPlant)))))) ??
          false
      )
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  function comparePrivacyPolicyVersions(v1: ContractVersion, v2: ContractVersion) {
    const v1AllTreatedFields = getInfoFromSections(v1.sections, undefined)
    const v1DataProcessingSectionTreatedFields = getInfoFromSections(v1.sections, true)
    const v1ConsentSectionsTreatedFields = getInfoFromSections(v1.sections, false)
    const v2AllTreatedFields = getInfoFromSections(v2.sections, undefined)
    const v2DataProcessingSectionTreatedFields = getInfoFromSections(v2.sections, true)
    const v2ConsentSectionsTreatedFields = getInfoFromSections(v2.sections, false)

    const comparation = new ContractVersionsComparation()

    comparation.removedFields = v1AllTreatedFields.filter((field) => !v2AllTreatedFields.includes(field))
    comparation.addedFields = v2AllTreatedFields.filter((field) => !v1AllTreatedFields.includes(field))
    comparation.dataProcessingRemovedFields = v1DataProcessingSectionTreatedFields.filter(
      (field) => !v2DataProcessingSectionTreatedFields.includes(field)
    )
    comparation.consentsRemovedFields = v1ConsentSectionsTreatedFields.filter(
      (field) => !v2ConsentSectionsTreatedFields.includes(field)
    )
    comparation.dataProcessingAddedFields = v2DataProcessingSectionTreatedFields.filter(
      (field) => !v1DataProcessingSectionTreatedFields.includes(field)
    )
    comparation.consentsAddedFields = v2ConsentSectionsTreatedFields.filter(
      (field) => !v1ConsentSectionsTreatedFields.includes(field)
    )
    return comparation
  }

  function getInfoFromSections(sections: ContractSection[], onlyDataProcessingSection?: boolean) {
    let filteredSections = sections
    if (onlyDataProcessingSection !== undefined) {
      filteredSections =
        onlyDataProcessingSection === true
          ? sections.filter((p) => !!p.actions.find((o) => o.action === ActionType.dataProcessing))
          : sections.filter(
              (p) => p.actions.length === 0 || !p.actions.find((o) => o.action === ActionType.dataProcessing)
            )
    }

    const treatedFields = filteredSections
      .flatMap((section) => section.treatments)
      .flatMap((treatment) => treatment?.dataTypes?.fields)
      .map((field) => field?.fieldName)
      .filter((fieldName, index, self) => fieldName !== undefined && self.indexOf(fieldName) === index) as string[]

    return treatedFields
  }

  // ********************
  // data changes
  async function createNewContractSection() {
    try {
      setIsLoading(true)
      if (contractVersion) {
        const hasDataProcessingSection = contractVersion.sections?.some((p) => p.isDataProcessingSection)
        const abortController = new AbortController()
        return await dalContractSection.createNewContractSection(
          abortController.signal,
          contractVersion.contractId,
          '',
          ConsentMode.optional,
          hasDataProcessingSection ? [] : [new ContractSectionAction('data_processing')]
        )
      }
    } catch (err) {
      enqueueSnackbar(t(Utils.getErrorMessageId(err)), { variant: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  function publishContractVersion() {
    if (contractVersion) {
      // check if there is a data processing section
      if (
        contractType === ContractType.privacyPolicy &&
        !contractVersion.sections.some((p) => p.isDataProcessingSection)
      ) {
        setSimpleDialogOpen(true)
        setSimpleDialogData({
          title: t(msgIds.MSG_CONTRACT_PUBLICATION),
          content: t(msgIds.MSG_CONTRACT_DATA_PROCESSING_SECTION_ABSENT),
          actionsStyle: 'Ok',
          onClose: async (result) => {
            setSimpleDialogOpen(false)
          },
        })
        return
      }

      let msg: string

      // check if is the first version to publish
      // if (contract?.versions.length === 1) {
      //   msg = msgIds.MSG_ARE_YOU_SURE_TO_PUBLISH_VERSION
      // } else {
      //   if (contractType === ContractType.privacyPolicy) {
      //     msg = contractVersion.areNewConsentsRequired
      //       ? msgIds.MSG_ARE_YOU_SURE_TO_PUBLISH_PRIVACY_POLICY_VERSION_WITH_NEW_CONSENTS_REQUIRED
      //       : msgIds.MSG_ARE_YOU_SURE_TO_PUBLISH_PRIVACY_POLICY_VERSION_WITHOUT_NEW_CONSENTS_REQUIRED
      //   } else {
      //     msg = contractVersion.areNewConsentsRequired
      //       ? msgIds.MSG_ARE_YOU_SURE_TO_PUBLISH_GENERIC_VERSION_WITH_NEW_CONSENTS_REQUIRED
      //       : msgIds.MSG_ARE_YOU_SURE_TO_PUBLISH_GENERIC_VERSION_WITHOUT_NEW_CONSENTS_REQUIRED
      //   }
      // }
      msg = msgIds.MSG_ARE_YOU_SURE_TO_PUBLISH_VERSION

      setSimpleDialogOpen(true)
      setSimpleDialogData({
        title: t(msgIds.MSG_CONTRACT_PUBLISH),
        content: t(msg),
        actionsStyle: 'yesNO',
        onClose: async (result) => {
          setSimpleDialogOpen(false)
          if (result.userChoice === 'yes') {
            try {
              setIsLoading(true)
              const abortController = new AbortController()
              const data = await dalContractVersion.publishContractVersion(
                abortController.signal,
                contractVersion.contractId
              )
              setContractVersion(data)
              navigate('/contracts/versions', { state: { contractId: contractVersion.contractId } })
            } catch (err) {
              enqueueSnackbar(t(Utils.getErrorMessageId(err)), { variant: 'error' })
            } finally {
              setIsLoading(false)
            }
          }
        },
      })
    }
  }

  async function deleteContractVersion() {
    if (contractVersion) {
      setSimpleDialogOpen(true)
      setSimpleDialogData({
        title: t(msgIds.MSG_CONTRACT_DELETE_VERSION),
        content: t(msgIds.MSG_CONTRACT_DELETE_VERSION_CONFIRM),
        actionsStyle: 'yesNO',
        onClose: async (result) => {
          setSimpleDialogOpen(false)
          if (result.userChoice === 'yes') {
            try {
              setIsLoading(true)
              const abortController = new AbortController()
              const data = await dalContractVersion.deleteContractVersion(
                abortController.signal,
                contractVersion.contractId
              )
              navigate('/contracts/versions', { state: { contractId: contractVersion.contractId } })
            } catch (err) {
              enqueueSnackbar(t(Utils.getErrorMessageId(err)), { variant: 'error' })
            } finally {
              setIsLoading(false)
            }
          }
        },
      })
    }
  }

  async function deleteContractSection(sectionId?: number) {
    if (contractVersion?.contractId && sectionId) {
      setSimpleDialogOpen(true)
      setSimpleDialogData({
        title: t(msgIds.MSG_CONTRACT_DELETE_SECTION),
        content: t(msgIds.MSG_CONTRACT_DELETE_SECTION_CONFIRM),
        actionsStyle: 'yesNO',
        onClose: async (result) => {
          setSimpleDialogOpen(false)
          if (result.userChoice === 'yes') {
            try {
              setIsLoading(true)
              const abortController = new AbortController()
              const ok = await dalContractSection.deleteContractSection(
                abortController.signal,
                contractVersion.contractId,
                sectionId
              )
              if (ok) {
                loadContractVersion(abortController.signal)
              }
            } catch (err) {
              enqueueSnackbar(t(Utils.getErrorMessageId(err)), { variant: 'error' })
            } finally {
              setIsLoading(false)
            }
          }
        },
      })
    }
  }

  async function moveContractSection(sectionIndex: number, up: boolean = false) {
    try {
      setIsLoading(true)
      if (contractVersion?.contractId && contractVersion?.sections?.length) {
        if ((sectionIndex === 0 && up) || (sectionIndex === contractVersion.sections.length - 1 && !up)) {
          return
        }
        const abortController = new AbortController()
        const section = contractVersion.sections[sectionIndex]
        if (up) {
          const prevSection = contractVersion.sections[sectionIndex - 1]
          await dalContractSection.updateContractSection(
            abortController.signal,
            contractVersion.contractId,
            section.id,
            { beforeOf: prevSection.id }
          )
        } else {
          const nextSection = contractVersion.sections[sectionIndex + 1]
          await dalContractSection.updateContractSection(
            abortController.signal,
            contractVersion.contractId,
            section.id,
            { beforeOf: nextSection.beforeOf }
          )
        }
        await loadContractVersion(abortController.signal)
      }
    } catch (err) {
      enqueueSnackbar(t(Utils.getErrorMessageId(err)), { variant: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  function getOtherSectionsTreatmentsIds(sectionId: number) {
    const otherSections = contractVersion?.sections.filter((p) => p.id !== sectionId) ?? []
    const treatmentIds = otherSections.flatMap((p) => p.treatments.map((o) => o.id))
    return treatmentIds
  }

  const commands: ICommand[] = [
    {
      commandText: t(msgIds.MSG_CONTRACT_ADD_SECTION),
      icon: <SectionAddIco />,
      onClick: async () => {
        const newSection = await createNewContractSection()
        const abortController = new AbortController()
        await loadContractVersion(abortController.signal)
        setSectionReference(newSection?.reference)
      },
      tooltipText: '',
      disabled: contractVersion?.state !== ContractVersionState.draft,
    },
    {
      commandText: t(msgIds.MSG_CONTRACT_PUBLISH),
      icon: <NewReleasesIco />,
      onClick: () => {
        publishContractVersion()
      },
      tooltipText: '',
      disabled: contractVersion?.state !== ContractVersionState.draft,
    },
    {
      commandText: t(msgIds.MSG_CONTRACT_DELETE_VERSION),
      icon: <DeleteIco />,
      onClick: () => {
        deleteContractVersion()
      },
      tooltipText: '',
      color: 'error',
      disabled: contractVersion?.state !== ContractVersionState.draft,
    },
  ]

  return (
    <PageContainer>
      <CommandBar
        style={{ minHeight: 'auto' }}
        title={t(msgIds.MSG_CONTRACT_EDIT_VERSION_TITLE)}
        disabled={isLoading}
        commands={commands}
      />

      {simpleDialogData && <SimpleDialog {...simpleDialogData} isOpen={simpleDialogOpen}></SimpleDialog>}

      <PageContent>
        <Box width="100%" maxWidth="lg" alignItems="stretch">
          <Stack direction={'row'} alignSelf={'start'} gap={4} paddingBottom={3}>
            <Typography variant="h6">
              <strong>
                {t(msgIds.MSG_CONTRACT_VERSION)} {contractVersion?.getVersionString()}
              </strong>
            </Typography>
            <Typography
              variant="h6"
              color={ContractVersionStateColorMap[contractVersion?.state || ContractVersionState.none]}
            >
              <strong>
                {t(ContractVersionStateTranslationMap[contractVersion?.state || ContractVersionState.none])}
              </strong>
            </Typography>
            {contractVersion?.publishedAt && (
              <Typography variant="h6" color={theme.palette.common.gray4}>
                <strong>{Utils.toLocaleDateString(contractVersion?.publishedAt, i18n, dateTimeShortOptions)}</strong>
              </Typography>
            )}
          </Stack>
        </Box>

        <Stack spacing={4} width="100%" maxWidth="lg" alignItems="stretch">
          {contractType === ContractType.privacyPolicy && contractVersion && (
            <Paper>
              <ContractInfoEditorForm
                contractVersion={contractVersion}
                comparation={versionsComparation}
                isAuthorizedToEdit={isAuthorizedToEdit}
              />
            </Paper>
          )}
          {contractVersion?.sections.map((section, index) => (
            <Paper key={index} ref={setRef(section.reference)}>
              <ContractSectionEditorForm
                contractSection={section}
                contractType={contractType}
                contractId={contractVersion?.contractId}
                sectionIndex={index}
                treatments={treatments}
                isAuthorizedToEdit={isAuthorizedToEdit}
                moveContractSection={moveContractSection}
                deleteContractSection={deleteContractSection}
                getOtherSectionsTreatmentsIds={getOtherSectionsTreatmentsIds}
              />
            </Paper>
          ))}
        </Stack>
      </PageContent>
    </PageContainer>
  )
}
