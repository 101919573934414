import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { IDoxEditorDialogProps } from './DoxEditorDialog.types'
import { DoxEditor } from '../../components/doxEditor/DoxEditor'
import { StyledDialog } from '../styledDialog/StyledDialog'
import { ViewHeader } from '../../components/viewHeader/ViewHeader'

export default function DoxEditorDialog(props: IDoxEditorDialogProps) {
  const { t } = useTranslation()

  return (
    <StyledDialog open={props.isOpen} onClose={() => props.onClose({ userChoice: 'abort' })}>
      <ViewHeader
        exitButtonVisible={true}
        onClickExit={() => props.onClose({ userChoice: 'abort' })}
        title={
          props.dox.id === 0
            ? t(msgIds.MSG_DOX_EDITOR_DIALOG_NEW_DOX_TITLE)
            : t(msgIds.MSG_DOX_EDITOR_DIALOG_MODIFY_DOX_TITLE)
        }
      />
      <DoxEditor
        flexGrow={1}
        mode={props.mode}
        dox={props.dox}
        onUpdated={(updatedDox) => props.onResult({ userChoice: 'yes', updatedDox })}
        onCancel={() => props.onClose({ userChoice: 'no' })}
      />
    </StyledDialog>
  )
}
