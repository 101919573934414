import { ArchiveDoxesAcl } from '../../contexts/ArchiveContext'
import { Dox } from '../../models/Dox'

export enum DoxSelectorMode {
  selectMultiple,
  selectMultipleWithRetention,
  selectSingleExceptSelfAndChilds,
  selectSingleWithRetentionExceptSelfAndChilds,
}

export interface IDoxSelectorInProps {
  selectionMode: DoxSelectorMode
  assignableDoxesAcl: ArchiveDoxesAcl
  showRoot?: boolean
  selectedDoxIds?: number[]
  undeterminedDoxIds?: number[]
  disabledDoxId?: number
  visibleDoxIds?: number[]
}

export interface IDoxSelectorOutProps {
  onEditDox: (dox: Dox) => void
  onAddDox: (dox: Dox) => void
  onConfirm: (selectedDoxIds: number[], undeterminedDoxIds: number[]) => void
  onCancel: () => void
  onExpandDox: (dox: Dox, expanded: boolean) => void
}

export interface IDoxSelectorProps extends IDoxSelectorInProps, IDoxSelectorOutProps {}
