import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { CountryOption, countryOptionByLabel, countryOptions } from '../../shared/types/Country'
import { SimpleAutocompleteTextField } from '../simpleAutocompleteTextField/SimpleAutocompleteTextField'
import { Alert, Stack, TextField } from '@mui/material'
import { ProvinceOption, provinceOptionByLabel, provinceOptions } from '../../shared/types/Province'
import { IAccountEditorBillingFieldsProps } from '../accountEditorBillingFields/AccountEditorBillingFields.types'

export function AccountEditorBillingFields(props: IAccountEditorBillingFieldsProps): JSX.Element {
  const { disabled, isEditMode, errors, args, onChangeArgs } = props
  const { t } = useTranslation()
  const country = countryOptionByLabel(args.country)
  const province = provinceOptionByLabel(args.province)

  return (
    <Stack gap={4}>
      <Alert severity="info">{t(msgIds.MSG_PROFILE_EDITOR_BILLING_INFO)}</Alert>
      <TextField
        error={!!errors.sdiCode}
        helperText={t(errors.sdiCode)}
        disabled={disabled || !isEditMode}
        label={t(msgIds.MSG_PROFILE_EDITOR_BILLING_SDI)}
        variant="outlined"
        value={args.sdiCode || ''}
        onChange={(event) => onChangeArgs({ ...args, sdiCode: event.target.value }, 'sdiCode')}
      />
      <TextField
        error={!!errors.pec}
        helperText={t(errors.pec)}
        disabled={disabled || !isEditMode}
        label={t(msgIds.MSG_PROFILE_EDITOR_BILLING_PEC)}
        variant="outlined"
        value={args.pec || ''}
        onChange={(event) => onChangeArgs({ ...args, pec: event.target.value }, 'pec')}
      />
      <SimpleAutocompleteTextField
        error={!!errors.country}
        helperText={t(errors.country)}
        label={t(msgIds.MSG_TREATED_FIELDS_COUNTRY)}
        options={countryOptions}
        disabled={disabled || !isEditMode}
        value={country || null}
        onChange={(option: CountryOption | null) =>
          onChangeArgs(
            {
              ...args,
              country: option ? option.label : undefined,
              province: option?.key === 'IT' ? args.province : '',
            },
            'country'
          )
        }
      />
      {args.country === 'Italia' && (
        <SimpleAutocompleteTextField
          error={!!errors.province}
          helperText={t(errors.province)}
          label={t(msgIds.MSG_TREATED_FIELDS_PROVINCE)}
          options={provinceOptions}
          disabled={disabled || !isEditMode}
          value={province || null}
          onChange={(option: ProvinceOption | null) =>
            onChangeArgs(
              {
                ...args,
                province: option ? option.label : undefined,
              },
              'province'
            )
          }
        />
      )}
      <TextField
        error={!!errors.city}
        helperText={t(errors.city)}
        disabled={disabled || !isEditMode}
        label={t(msgIds.MSG_TREATED_FIELDS_CITY)}
        variant="outlined"
        value={args.city || ''}
        onChange={(event) => onChangeArgs({ ...args, city: event.target.value }, 'city')}
      />
      <TextField
        error={!!errors.street}
        helperText={t(errors.street)}
        disabled={disabled || !isEditMode}
        label={t(msgIds.MSG_TREATED_FIELDS_STREET)}
        variant="outlined"
        value={args.street || ''}
        onChange={(event) => onChangeArgs({ ...args, street: event.target.value }, 'street')}
      />
      <TextField
        error={!!errors.streetNumber}
        helperText={t(errors.streetNumber)}
        disabled={disabled || !isEditMode}
        label={t(msgIds.MSG_TREATED_FIELDS_STREET_NUMBER)}
        variant="outlined"
        value={args.streetNumber || ''}
        onChange={(event) => onChangeArgs({ ...args, streetNumber: event.target.value }, 'streetNumber')}
      />
      <TextField
        error={!!errors.zip}
        helperText={t(errors.zip)}
        disabled={disabled || !isEditMode}
        label={t(msgIds.MSG_TREATED_FIELDS_ZIP)}
        variant="outlined"
        value={args.zip || ''}
        onChange={(event) => onChangeArgs({ ...args, zip: event.target.value }, 'zip')}
      />
    </Stack>
  )
}
