import { Stack } from '@mui/material'
import { IViewContentProps } from './ViewContent.types'

export function ViewContent(props: IViewContentProps): JSX.Element {
  const { disablePadding, ...rest } = props
  return (
    <Stack direction="column" padding={disablePadding ? 0 : 3} flexGrow={1} {...rest}>
      {props.children}
    </Stack>
  )
}
