import { DashboardItem, IDashboardItemJson } from './DashboardItem'

export interface IDashboardCfgJson {
  is_initialized: boolean
  items: IDashboardItemJson[]
}

export class DashboardCfg {
  isInitialized: boolean = false
  items: DashboardItem[] = []

  constructor() {}

  public static serialize(obj: DashboardCfg): IDashboardCfgJson {
    return {
      is_initialized: obj.isInitialized,
      items: DashboardItem.serializeArray(obj.items),
    }
  }

  public static deserialize(json: IDashboardCfgJson): DashboardCfg {
    const res = new DashboardCfg()
    res.isInitialized = json.is_initialized
    res.items = DashboardItem.deserializeArray(json.items)
    return res
  }
}
