import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material'
import { ITreatmentsAccordionProps } from './TreatmentsAccordion.types'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'
import { DeleteIco, ExpandMoreIco } from '../icons'
import DisplayField from '../displayField/DisplayField'
import { TreatmentLegalBaseTranslationMap } from '../../shared/Constants'
import { Utils } from '../../shared/Utils'
import { TreatedDataTypes } from '../../models/TreatedDataTypes'

export function TreatmentsAccordion(props: ITreatmentsAccordionProps): JSX.Element {
  const { treatments, editMode, onRemoveTreatment } = props
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <Accordion sx={{ backgroundColor: theme.palette.grey['100'] }} elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIco />}>
        <Typography variant="body1">
          <strong>{t(msgIds.MSG_CONTRACT_TREATMENTS_LIST)}</strong>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box gap={2} display="flex" flexDirection="column">
          {treatments?.map((treatment) => (
            <Box gap={1} key={treatment.id}>
              <Grid alignItems="center" container>
                <Grid
                  flexGrow={1}
                  sx={{ gap: 1, display: 'flex', flexDirection: 'column', maxWidth: 'calc(100% - 44px)' }}
                  item
                >
                  <DisplayField label={t(msgIds.MSG_TREATMENT_PURPOSE)} value={treatment.shortDescription} />
                  <DisplayField
                    label={t(msgIds.MSG_TREATMENT_LEGAL_BASE)}
                    value={t(TreatmentLegalBaseTranslationMap[treatment.legalBase]).toString()}
                  />
                  <DisplayField
                    label={t(msgIds.MSG_TREATMENT_RETENTION)}
                    value={Utils.formatRetentionDuration(
                      t,
                      treatment.retentionDurationBase,
                      treatment.retentionDurationCount
                    )}
                  />
                  <DisplayField
                    label={t(msgIds.MSG_TREATMENT_MANDATORY_FIELDS)}
                    value={Utils.collectTreatedFields(t, treatment.dataTypes ?? new TreatedDataTypes(), true)}
                  />
                  <DisplayField
                    label={t(msgIds.MSG_TREATMENT_OPTIONAL_FIELDS)}
                    value={Utils.collectTreatedFields(t, treatment.dataTypes ?? new TreatedDataTypes(), false)}
                  />
                </Grid>
                <Grid item>
                  {editMode && onRemoveTreatment && (
                    <IconButton onClick={() => onRemoveTreatment(treatment)}>
                      <DeleteIco />
                    </IconButton>
                  )}
                </Grid>
              </Grid>

              {treatments.findIndex((p) => p === treatment) !== treatments.length - 1 && <Divider sx={{ mt: 2 }} />}
            </Box>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}
