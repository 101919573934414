import { ActionType } from '../shared/Constants'
import {
  HistoryContextPermissionViewDocument,
  IHistoryContextPermissionViewDocumentJson,
} from './HistoryContextPermissionViewDocument'
import {
  HistoryContextPermissionViewDox,
  IHistoryContextPermissionViewDoxJson,
} from './HistoryContextPermissionViewDox'

export interface IHistoryContextPermissionJson {
  id: number
  action: ActionType
  author_id: number
  profile_id: number
  target_id: number
  start_at: string
  end_at: string
  consent_id: number
  is_auto_generated: boolean
  is_auto_deleted: boolean
  contract_version_confirmed: boolean | null
  contract_version_confirmed_at: string | null
  contract_version_to_confirm: number | null
  has_pin: boolean
  dependent_actions: string | null
  dox?: IHistoryContextPermissionViewDoxJson
  document?: IHistoryContextPermissionViewDocumentJson
}

export class HistoryContextPermission {
  id: number = 0
  action: ActionType = ActionType.none
  authorId: number = 0
  profileId: number = 0
  targetId: number = 0
  startAt: Date = new Date(0)
  endAt: string = ''
  consentId: number = 0
  isAutoGenerated: boolean = false
  isAutoDeleted: boolean = false
  contractVersionConfirmed: boolean | null = null
  contractVersionConfirmedAt: string | null = null
  contractVersionToConfirm: number | null = null
  dependentActions: ActionType[] = []
  hasPin: boolean = false
  dox?: HistoryContextPermissionViewDox
  document?: HistoryContextPermissionViewDocument

  constructor() {}

  public static serialize(obj: HistoryContextPermission): IHistoryContextPermissionJson {
    return {
      id: obj.id,
      action: obj.action,
      author_id: obj.authorId,
      profile_id: obj.profileId,
      target_id: obj.targetId,
      start_at: obj.startAt.toISOString(),
      end_at: obj.endAt,
      consent_id: obj.consentId,
      is_auto_generated: obj.isAutoGenerated,
      is_auto_deleted: obj.isAutoDeleted,
      contract_version_confirmed: obj.contractVersionConfirmed,
      contract_version_confirmed_at: obj.contractVersionConfirmedAt,
      contract_version_to_confirm: obj.contractVersionToConfirm,
      has_pin: obj.hasPin,
      dependent_actions: obj.dependentActions.join(','),
      dox: obj.dox ? HistoryContextPermissionViewDox.serialize(obj.dox) : undefined,
      document: obj.document ? HistoryContextPermissionViewDocument.serialize(obj.document) : undefined,
    }
  }

  public static deserialize(json: IHistoryContextPermissionJson): HistoryContextPermission {
    const res = new HistoryContextPermission()
    res.id = json.id
    res.action = json.action
    res.authorId = json.author_id
    res.profileId = json.profile_id
    res.targetId = json.target_id
    res.startAt = new Date(json.start_at)
    res.endAt = json.end_at
    res.consentId = json.consent_id
    res.isAutoGenerated = json.is_auto_generated
    res.isAutoDeleted = json.is_auto_deleted
    res.contractVersionConfirmed = json.contract_version_confirmed
    res.contractVersionConfirmedAt = json.contract_version_confirmed_at
    res.contractVersionToConfirm = json.contract_version_to_confirm
    res.hasPin = json.has_pin
    res.dependentActions = json.dependent_actions ? (json.dependent_actions.split(',') as ActionType[]) : []
    res.dox = json.dox ? HistoryContextPermissionViewDox.deserialize(json.dox) : undefined
    res.document = json.document ? HistoryContextPermissionViewDocument.deserialize(json.document) : undefined
    return res
  }
}
