import { useTranslation } from 'react-i18next'
import { Box, Divider, List, ListItem, Typography } from '@mui/material'
import { useLayout } from '../../hooks/Layout'
import msgIds from '../../locales/msgIds'
import { ArchiveItemSourceType } from '../../shared/Constants'
import { IDocumentRevisionProps } from './DocumentRevisionProps'
import { Utils } from '../../shared/Utils'
import { DefaultTFuncReturn } from 'i18next'

export default function DocumentInfoDetails(props: IDocumentRevisionProps): JSX.Element {
  const { document, revision } = props
  const { t, i18n } = useTranslation()
  const { isMobile } = useLayout()

  const infos: { label: string; value?: string | DefaultTFuncReturn }[] = [
    { label: t(msgIds.MSG_DOCUMENT_EDITOR_REVISION_NAME_LABEL), value: revision?.name },
    {
      label: t(msgIds.MSG_DOCUMENT_EDITOR_REVISION_EDITED_AT_LABEL),
      value: Utils.toLocaleDateString(revision?.editedAt, i18n) ?? '',
    },
    { label: t(msgIds.MSG_DOCUMENT_EDITOR_REVISION_CREATOR_IDENTITY_LABEL), value: revision?.creatorIdentity },
    {
      label: t(msgIds.MSG_DOCUMENT_EDITOR_REVISION_MIMETYPE_LABEL),
      value:
        document.sourceType === ArchiveItemSourceType.internalSource
          ? t(msgIds.MSG_DOCUMENT_SOURCE_PDX_EDITOR)
          : t(msgIds.MSG_DOCUMENT_SOURCE_LOADED_FILE),
    },
  ]
  if (document.sourceType === ArchiveItemSourceType.externalSource) {
    infos.push({ label: t(msgIds.MSG_DIMENSION), value: Utils.displayByteSize(revision?.size) })
    infos.push({ label: t(msgIds.MSG_DOCUMENT_ORIGINAL_FILE_NAME), value: revision?.filename })
  }

  return (
    <Box>
      {revision && (
        <List>
          {infos.map((i) => (
            <Box key={i.label}>
              <ListItem
                sx={{ display: 'flex', justifyContent: 'stretch', gap: 2, alignItems: 'center', flexWrap: 'wrap' }}
              >
                <Typography sx={{ flex: isMobile ? 1 : 0.5, flexShrink: 0 }}>{i.label}</Typography>
                <Typography sx={{ flex: isMobile ? 1 : 1.5, flexShrink: 0 }}>{i.value}</Typography>
              </ListItem>
              <Divider sx={{ marginX: 2, marginY: 1 }} />
            </Box>
          ))}
        </List>
      )}
    </Box>
  )
}
