import { IInternalDocumentTemplateJson, InternalDocumentTemplate } from './InternalDocumentTemplate'
import { IOrganizedDoxTemplateJson, OrganizedDoxTemplate } from './OrganizedDoxTemplate'

export interface IArchiveCfgJson {
  auto_save_time?: number
  internal_document_templates?: IInternalDocumentTemplateJson[]
  organized_dox_templates?: IOrganizedDoxTemplateJson[]
}

export class ArchiveCfg {
  autoSaveTime?: number
  internalDocumentTemplates?: InternalDocumentTemplate[]
  organizedDoxTemplates?: OrganizedDoxTemplate[]

  constructor() {}

  public static serialize(obj: ArchiveCfg): IArchiveCfgJson {
    const res: IArchiveCfgJson = {
      auto_save_time: obj.autoSaveTime,
      internal_document_templates: obj.internalDocumentTemplates
        ? InternalDocumentTemplate.serializeArray(obj.internalDocumentTemplates)
        : undefined,
      organized_dox_templates: obj.organizedDoxTemplates
        ? OrganizedDoxTemplate.serializeArray(obj.organizedDoxTemplates)
        : undefined,
    }
    return res
  }

  public static deserialize(json: IArchiveCfgJson): ArchiveCfg {
    const res = new ArchiveCfg()
    res.autoSaveTime = json.auto_save_time
    res.internalDocumentTemplates = json.internal_document_templates
      ? InternalDocumentTemplate.deserializeArray(json.internal_document_templates)
      : undefined
    res.organizedDoxTemplates = json.organized_dox_templates
      ? OrganizedDoxTemplate.deserializeArray(json.organized_dox_templates)
      : undefined
    return res
  }
}
