export interface IHistoryContextDoxJson {
  id?: number
  name?: string
  owner_profile_id?: number
  target_profile_id?: number
  days_countdown?: number
}

export class HistoryContextDox {
  id?: number
  name?: string
  ownerProfileId?: number
  targetProfileId?: number
  daysCountdown?: number

  constructor() {}

  public static serialize(obj: HistoryContextDox): IHistoryContextDoxJson {
    return {
      id: obj.id,
      name: obj.name,
      owner_profile_id: obj.ownerProfileId,
      target_profile_id: obj.targetProfileId,
      days_countdown: obj.daysCountdown,
    }
  }

  public static deserialize(json: IHistoryContextDoxJson): HistoryContextDox {
    const res = new HistoryContextDox()
    res.id = json.id
    res.name = json.name
    res.ownerProfileId = json.owner_profile_id
    res.targetProfileId = json.target_profile_id
    res.daysCountdown = json.days_countdown
    return res
  }
}
