import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function SwapVertIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 16.000001,17.010003 v -7.01 H 14 v 7.01 h -3 l 4,3.99 4.000001,-3.99 z M 9,3.0000033 5.0000001,6.9900034 h 3 V 14.000004 H 10 V 6.9900034 h 3 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
