import { ActionType } from '../shared/Constants'
import { Account, IAccountJson } from './Account'
import { ITreatmentJson, Treatment } from './Treatment'

export interface IPermissionJson {
  id: number
  author_id: number
  profile_id: number
  owner_profile_id: number
  start_at: string
  end_at?: string
  action: string
  target_id: number
  consent_id: number | undefined
  contract_version_to_confirm: number | undefined
  contract_version_confirmed: number | undefined
  contract_version_confirmed_at: string | undefined
  is_auto_generated: number
  is_suspended: boolean
  is_expired: boolean
  is_inherited: boolean
  is_valid: boolean
  created_at: string
  updated_at: string
  suspended_at: string | undefined
  author: IAccountJson | undefined
  granted: IAccountJson | undefined
  target_account: IAccountJson | undefined
  target_treatment: ITreatmentJson | undefined
  email_to?: string
  has_pin: boolean
  dependent_actions: string
}

export class Permission {
  id: number = 0
  authorId: number = 0
  profileId: number = 0
  ownerProfileId: number = 0
  startAt: Date = new Date(0)
  endAt?: Date
  action: string = ''
  targetId: number = 0
  consentId: number | undefined
  contractVersionToConfirm?: number
  contractVersionConfirmed?: number
  contractVersionConfirmedAt?: string
  isAutoGenerated: number = 0
  isSuspended: boolean = false
  isExpired: boolean = false
  isInherited: boolean = false
  isValid: boolean = false
  createdAt: Date = new Date(0)
  updatedAt: Date = new Date(0)
  suspendedAt?: string
  author?: Account
  granted?: Account
  targetAccount?: Account
  targetTreatment?: Treatment
  emailTo?: string
  hasPin: boolean = false
  dependentActions: ActionType[] = []

  constructor() {}

  public static serialize(obj: Permission): IPermissionJson {
    return {
      id: obj.id,
      author_id: obj.authorId,
      profile_id: obj.profileId,
      owner_profile_id: obj.ownerProfileId,
      start_at: obj.startAt.toISOString(),
      end_at: obj.endAt ? obj.endAt.toISOString() : undefined,
      action: obj.action,
      target_id: obj.targetId,
      consent_id: obj.consentId,
      contract_version_to_confirm: obj.contractVersionToConfirm,
      contract_version_confirmed: obj.contractVersionConfirmed,
      contract_version_confirmed_at: obj.contractVersionConfirmedAt,
      is_auto_generated: obj.isAutoGenerated,
      is_suspended: obj.isSuspended,
      is_expired: obj.isExpired,
      is_inherited: obj.isInherited,
      is_valid: obj.isValid,
      created_at: obj.createdAt.toISOString(),
      updated_at: obj.updatedAt.toISOString(),
      suspended_at: obj.suspendedAt,
      author: obj.author ? Account.serialize(obj.author) : undefined,
      granted: obj.granted ? Account.serialize(obj.granted) : undefined,
      target_account: obj.targetAccount ? Account.serialize(obj.targetAccount) : undefined,
      target_treatment: obj.targetTreatment ? Treatment.serialize(obj.targetTreatment) : undefined,
      email_to: obj.emailTo,
      has_pin: obj.hasPin,
      dependent_actions: obj.dependentActions.join(','),
    }
  }

  public static deserialize(json: IPermissionJson): Permission {
    const res = new Permission()
    res.id = json.id
    res.authorId = json.author_id
    res.profileId = json.profile_id
    res.ownerProfileId = json.owner_profile_id
    res.startAt = new Date(json.start_at)
    res.endAt = json.end_at ? new Date(json.end_at) : undefined
    res.action = json.action
    res.targetId = json.target_id
    res.consentId = json.consent_id
    res.contractVersionToConfirm = json.contract_version_to_confirm
    res.contractVersionConfirmed = json.contract_version_confirmed
    res.contractVersionConfirmedAt = json.contract_version_confirmed_at
    res.isAutoGenerated = json.is_auto_generated
    res.isSuspended = json.is_suspended
    res.isExpired = json.is_expired
    res.isInherited = json.is_inherited
    res.isValid = json.is_valid
    res.createdAt = new Date(json.created_at)
    res.updatedAt = new Date(json.updated_at)
    res.suspendedAt = json.suspended_at
    res.author = json.author ? Account.deserialize(json.author) : undefined
    res.granted = json.granted ? Account.deserialize(json.granted) : undefined
    res.targetAccount = json.target_account ? Account.deserialize(json.target_account) : undefined
    res.targetTreatment = json.target_treatment ? Treatment.deserialize(json.target_treatment) : undefined
    res.emailTo = json.email_to
    res.hasPin = json.has_pin
    res.dependentActions = json.dependent_actions ? (json.dependent_actions.split(',') as ActionType[]) : []
    return res
  }

  public static serializeArray(objs: Permission[]): IPermissionJson[] {
    const jsons = objs.map((p) => {
      return Permission.serialize(p)!
    })
    return jsons
  }

  public static deserializeArray(json: IPermissionJson[]): Permission[] {
    const res = json.map((p) => {
      return Permission.deserialize(p)!
    })
    return res
  }
}
