import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { IOrganizedDoxTemplateSelectorDialogProps } from './OrganizedDoxTemplateSelectorDialog.types'
import { StyledDialog } from '../styledDialog/StyledDialog'
import { ViewHeader } from '../../components/viewHeader/ViewHeader'
import { OrganizedDoxTemplatesList } from '../../components/organizedDoxTemplatesList/OrganizedDoxTemplatesList'
import { OrganizedDoxTemplate } from '../../models/OrganizedDoxTemplate'

export default function OrganizedDoxTemplateSelectorDialog(props: IOrganizedDoxTemplateSelectorDialogProps) {
  const { t } = useTranslation()

  function onClickDoxTemplate(template: OrganizedDoxTemplate) {
    props.onResult({ userChoice: 'yes', template: template })
  }

  return (
    <StyledDialog open={props.isOpen} onClose={() => props.onClose({ userChoice: 'abort' })}>
      <ViewHeader
        exitButtonVisible={true}
        onClickExit={() => props.onClose({ userChoice: 'abort' })}
        title={t(msgIds.MSG_DOX_TEMPLATES_DIALOG_TITLE)}
      />
      <OrganizedDoxTemplatesList sx={{ minHeight: 300 }} onClickTemplate={onClickDoxTemplate} />
    </StyledDialog>
  )
}
