import { IDialogTemplateProps } from './DialogTemplate.types'
import { Breakpoint, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@mui/material'
import { ViewHeader } from '../../viewHeader/ViewHeader'
import { ViewActions } from '../../viewActions/ViewActions'

export const defaultDialogTemplateProps = {
  isFullscreenForMobile: true,
  maxWidth: 'sm' as Breakpoint,
  mt: 3,
  canAbort: true,
}

export default function DialogTemplate(props: IDialogTemplateProps): JSX.Element {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Dialog
      open={props.isOpen}
      PaperProps={{ sx: { borderRadius: isMobile ? '0px' : '16px' } }}
      maxWidth={props?.maxWidth ?? 'xl'}
      fullScreen={props.isFullscreenForMobile && isMobile}
      fullWidth={true}
      onClose={() => {
        props.canAbort && props.onClose({ userChoice: 'abort' })
      }}
    >
      <DialogTitle sx={{ m: 0, p: 0 }}>
        <ViewHeader
          title={props.title}
          exitButtonVisible={props.canAbort}
          onClickExit={() => props.onClose({ userChoice: 'abort' })}
        />
      </DialogTitle>

      <DialogContent
        sx={{
          maxWidth: props.maxWidth ?? 'xl',
          p: props?.p,
          pt: props?.pt,
          pb: props?.pb,
          pl: props?.pl,
          pr: props?.pr,
          px: props?.px,
          py: props?.py,
          m: props?.m,
          mt: props?.mt,
          mb: props?.mb,
          ml: props?.ml,
          mr: props?.mr,
          mx: props?.mx,
          my: props?.my,
          ...props.dialogContentProps?.sx,
        }}
      >
        {props.children}
      </DialogContent>
      {props?.showActions && (
        <DialogActions sx={{ p: 0, m: 0 }}>
          <ViewActions>{props.actions}</ViewActions>
        </DialogActions>
      )}
    </Dialog>
  )
}
