export interface INotificationContextPermissionJson {
  id: number
  start_at?: string
  end_at?: string
}

export class NotificationContextPermission {
  id: number = 0
  startAt?: Date
  endAt?: Date

  constructor() {}

  public static serialize(obj: NotificationContextPermission): INotificationContextPermissionJson {
    return {
      id: obj.id,
      start_at: obj.startAt?.toISOString(),
      end_at: obj.endAt?.toISOString(),
    }
  }

  public static deserialize(json: INotificationContextPermissionJson): NotificationContextPermission {
    const res = new NotificationContextPermission()
    res.id = json.id
    res.startAt = json.start_at ? new Date(json.start_at) : undefined
    res.endAt = json.end_at ? new Date(json.end_at) : undefined
    return res
  }
}
