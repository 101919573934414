import { ArchiveCfg, IArchiveCfgJson } from './ArchiveCfg'
import { DashboardCfg, IDashboardCfgJson } from './DashboardCfg'
import { GuideCfg, IGuideCfgJson } from './GuideCfg'

export interface IFePreferencesJson {
  guide_cfg?: IGuideCfgJson
  dashboard_items?: IDashboardCfgJson
  archive_cfg?: IArchiveCfgJson
}

export class FePreferences {
  guideCfg?: GuideCfg
  dashboardCfg?: DashboardCfg
  archiveCfg?: ArchiveCfg

  constructor() {}

  public static serialize(obj: FePreferences): IFePreferencesJson {
    return {
      guide_cfg: obj.guideCfg ? GuideCfg.serialize(obj.guideCfg) : undefined,
      dashboard_items: obj.dashboardCfg ? DashboardCfg.serialize(obj.dashboardCfg) : undefined,
      archive_cfg: obj.archiveCfg ? ArchiveCfg.serialize(obj.archiveCfg) : undefined,
    }
  }

  public static deserialize(json: IFePreferencesJson): FePreferences {
    const res = new FePreferences()
    res.guideCfg = json.guide_cfg ? GuideCfg.deserialize(json.guide_cfg) : undefined
    res.dashboardCfg = json.dashboard_items ? DashboardCfg.deserialize(json.dashboard_items) : undefined
    res.archiveCfg = json.archive_cfg ? ArchiveCfg.deserialize(json.archive_cfg) : undefined
    return res
  }
}
