import { Gender, Gender_all } from '../shared/Constants'

export interface IUserDetailsJson {
  user_id: number

  name: string
  lastname: string
  gender: number
  birthdate: string
  birthplace: string
  fiscal_code: string
  vat_number: string
  pec: string
  sdi_code: string

  phone: string
  mobile_phone: string
  country: string
  city: string
  province: string
  street: string
  street_number: string
  zip: string

  created_at: string
  updated_at: string
}

export class UserDetails {
  userId: number = 0

  name: string = ''
  lastname: string = ''
  gender: Gender_all = Gender.none
  birthdate: string = ''
  birthplace: string = ''
  fiscalCode: string = ''
  vatNumber: string = ''
  pec: string = ''
  sdiCode: string = ''

  phone: string = ''
  mobilePhone: string = ''
  country: string = ''
  city: string = ''
  province: string = ''
  street: string = ''
  streetNumber: string = ''
  zip: string = ''

  createdAt: Date = new Date(0)
  updatedAt: Date = new Date(0)

  _hasBillingData: boolean = false
  get hasBillingData(): boolean {
    return (
      (!!this.sdiCode || !!this.pec) &&
      !!this.country &&
      !!this.city &&
      !!this.province &&
      !!this.street &&
      !!this.streetNumber &&
      !!this.zip
    )
  }

  constructor() {}

  public static serialize(obj: UserDetails): IUserDetailsJson {
    return {
      user_id: obj.userId,

      name: obj.name,
      lastname: obj.lastname,
      gender: obj.gender,
      birthdate: obj.birthdate,
      birthplace: obj.birthplace,
      fiscal_code: obj.fiscalCode,
      vat_number: obj.vatNumber,
      pec: obj.pec,
      sdi_code: obj.sdiCode,

      phone: obj.phone,
      mobile_phone: obj.mobilePhone,
      country: obj.country,
      city: obj.city,
      province: obj.province,
      street: obj.street,
      street_number: obj.streetNumber,
      zip: obj.zip,

      created_at: obj.createdAt.toISOString(),
      updated_at: obj.updatedAt.toISOString(),
    }
  }

  public static deserialize(json: IUserDetailsJson): UserDetails {
    const res = new UserDetails()
    res.userId = json.user_id

    res.name = json.name
    res.lastname = json.lastname
    res.gender = json.gender
    res.birthdate = json.birthdate
    res.birthplace = json.birthplace
    res.fiscalCode = json.fiscal_code
    res.vatNumber = json.vat_number
    res.pec = json.pec
    res.sdiCode = json.sdi_code

    res.phone = json.phone
    res.mobilePhone = json.mobile_phone
    res.country = json.country
    res.city = json.city
    res.province = json.province
    res.street = json.street
    res.streetNumber = json.street_number
    res.zip = json.zip

    res.createdAt = new Date(json.created_at)
    res.updatedAt = new Date(json.updated_at)
    return res
  }
}
