import { useTranslation } from 'react-i18next'
import { useStackState } from '../../hooks/Stack'
import msgIds from '../../locales/msgIds'
import { AuthorizationsChange } from '../authorizationsChange/AuthorizationsChange'
import { PageStep } from '../pageStepper/PageStep'
import { PageStepper } from '../pageStepper/PageStepper'
import { ViewContent } from '../viewContent/ViewContent'
import { ViewHeader } from '../viewHeader/ViewHeader'
import { IAuthorizationsChangeStepperProps } from './AuthorizationsChangeStepper.types'
import { DoxDeleteConfirm } from '../doxDeleteConfirm/DoxDeleteConfirm'

export function AuthorizationsChangeStepper(props: IAuthorizationsChangeStepperProps): JSX.Element {
  const { t } = useTranslation()
  const stack = useStackState(getInitialStep())

  function getInitialStep() {
    switch (props.action) {
      case 'moveDox': {
        return ['changes']
      }
      case 'deleteDox': {
        if (props.lostedAuthorizations.length > 0) {
          return ['changes']
        } else {
          return ['confirmation']
        }
      }
    }
  }

  function onConfirmChanges() {
    if (!props.newParentDoxId) {
      // In this case, the user ask to delete a dox,
      // then go to the deletedox dialog box for confirmation,
      // ask for confirmation for the cancellation of the dox
      // and passes the answer to the caller's page
      stack.push('confirmation')
    } else {
      props.onConfirm()
    }
  }
  function onCancel() {
    props.onCancel()
  }

  function stepTitle(step: any) {
    switch (step) {
      case 'changes':
        return t(msgIds.MSG_DOCUMENTS_VISIBILITY_CHANGES)
      case 'confirmation':
        return t(msgIds.MSG_DOX_DELETE_CONFIRM_TITLE)
      default:
        return t(msgIds.MSG_LOADING_IN_PROGRESS)
    }
  }

  return (
    <PageStepper id="authorizations-change-stepper" activeStep={stack.head} flexGrow={1}>
      <ViewHeader title={stepTitle(stack.head)} backButtonVisible={stack.length > 1} onClickBack={stack.pop} />

      <PageStep step="changes">
        <ViewContent>
          <AuthorizationsChange
            doxId={props.doxId}
            oldParentDoxId={props.oldParentDoxId}
            newParentDoxId={props.newParentDoxId}
            doxIdToDelete={props.doxIdToDelete}
            lostedAuthorizations={props.lostedAuthorizations}
            gainedAuthorizations={props.gainedAuthorizations}
            id="authorizations-change"
            onConfirm={onConfirmChanges}
            onCancel={onCancel}
            flexGrow={1}
          />
        </ViewContent>
      </PageStep>
      <PageStep step="confirmation">
        <ViewContent overflow="hidden">
          {props.doxIdToDelete && (
            <DoxDeleteConfirm
              disablePadding
              doxId={props.doxIdToDelete}
              onConfirm={props.onConfirm}
              onCancel={props.lostedAuthorizations.length > 0 ? stack.pop : onCancel}
              flexGrow={1}
            />
          )}
        </ViewContent>
      </PageStep>
    </PageStepper>
  )
}
