import { PDX_API_URL, RetentionRules_all } from '../shared/Constants'
import log from '../shared/Logger'
import { axiosInstance } from '../contexts/AuthContext'
import { UriComposer } from '../shared/types/UriComposer'
import { PaginatedResponse } from '../shared/types/PaginatedResponse'
import { Dox, IDoxJson } from '../models/Dox'
import { AxiosResponse } from 'axios'
import { Utils } from '../shared/Utils'

// ********************
// GET

function deserializeResponse(resp: AxiosResponse<any, any>): PaginatedResponse<Dox> {
  const desResp = new PaginatedResponse<Dox>()
  desResp.rows = resp.data.rows.map((json: IDoxJson) => {
    const data = Dox.deserialize(json as IDoxJson)
    return data
  })
  desResp.page = resp.data.page
  desResp.offset = resp.data.offset
  desResp.limit = resp.data.limit
  desResp.total = resp.data.total
  return desResp
}

export function getDoxesForCustomerOwnedByStructure(
  abortSignal: AbortSignal,
  customerProfileId: number
): Promise<PaginatedResponse<Dox>> {
  const funcName = 'getDoxesForCustomerOwnedByStructure'
  const url = UriComposer.create(`${PDX_API_URL}/v1/dox`)
    .addParamNumber('profile_id', customerProfileId)
    .addParamBool('is_perspective', true)
    .getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = deserializeResponse(resp)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function getDoxesForGuestSharedByStructure(
  abortSignal: AbortSignal,
  guestProfileId: number,
  grantorProfileId: number
): Promise<PaginatedResponse<Dox>> {
  const funcName = 'getDoxesForGuestSharedByStructure'
  const url = UriComposer.create(`${PDX_API_URL}/v1/dox`)
    .addParamNumber('grantor_profile_id', grantorProfileId)
    .addParamNumber('authorized_profile_id', guestProfileId)
    .addParamBool('is_perspective', false)
    .getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = deserializeResponse(resp)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function getDoxesOfCustomerSharedWithStructure(
  abortSignal: AbortSignal,
  customerProfileId: number
): Promise<PaginatedResponse<Dox>> {
  const funcName = 'getDoxesOfCustomerSharedWithStructure'
  const url = UriComposer.create(`${PDX_API_URL}/v1/dox`)
    .addParamNumber('profile_id', customerProfileId)
    .addParamBool('is_perspective', false)
    .getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = deserializeResponse(resp)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function getDoxesOwned(abortSignal: AbortSignal, onlyReceived?: boolean): Promise<PaginatedResponse<Dox>> {
  const funcName = 'getDoxesOwned'
  const url = UriComposer.create(`${PDX_API_URL}/v1/dox`)
    .addParamBoolNullable('only_received', onlyReceived)
    .getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = deserializeResponse(resp)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function getDoxesOwnedAndSharedWith(
  abortSignal: AbortSignal,
  authorizedProfileId: number
): Promise<PaginatedResponse<Dox>> {
  const funcName = 'getDoxesOwnedAndSharedWith'
  const url = UriComposer.create(`${PDX_API_URL}/v1/dox`)
    .addParamNumber('authorized_profile_id', authorizedProfileId)
    .getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = deserializeResponse(resp)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function getDoxDetails(abortSignal: AbortSignal, doxId: number): Promise<Dox> {
  const funcName = 'getDoxDetails'
  const url = UriComposer.create(`${PDX_API_URL}/v1/dox/${doxId}?subtree=false`).getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Dox.deserialize(resp.data as IDoxJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function getDoxContent(
  abortSignal: AbortSignal,
  doxId: number,
  ownedOnly: boolean,
  progressCallback: (percent: number) => void
): Promise<ArrayBuffer> {
  const funcName = 'getDoxContent'
  const url = UriComposer.create(`${PDX_API_URL}/v1/dox/${doxId}/download`)
    .addParamBool('owned_only', ownedOnly)
    .getCompleteUri()

  return axiosInstance
    .get(url, {
      responseType: 'arraybuffer',
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
      onDownloadProgress: (progressEvent) => {
        const estimatedSize = parseInt(progressEvent.event.srcElement.getResponseHeader('x-estimated-size'))
        if (estimatedSize) {
          let percent = Math.round((progressEvent.loaded * 100) / estimatedSize)
          percent = percent <= 100 ? percent : 100
          if (progressCallback) {
            progressCallback(percent)
          }
        }
      },
    })
    .then((resp) => {
      return resp.data
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// POST

export function createDox(
  abortSignal: AbortSignal,
  name: string,
  notes: string,
  targetProfileId: number,
  parentId: number,
  treatmentId?: number,
  retentionRules?: RetentionRules_all,
  retentionStartAt?: Date,
  retentionEndAt?: Date
): Promise<Dox> {
  const funcName = 'createDox'
  const url = `${PDX_API_URL}/v1/dox`

  const parms = {
    name: name,
    notes: notes,
    target_profile_id: targetProfileId,
    parent_id: parentId,
    treatment_id: treatmentId,
    retention_rules: retentionRules,
    retention_start_at: retentionStartAt ? Utils.toDate(retentionStartAt, true) : undefined,
    retention_end_at: retentionEndAt ? Utils.toDate(retentionEndAt, true) : undefined,
  }
  return axiosInstance
    .post(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Dox.deserialize(resp.data as IDoxJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// PATCH

export function updateDox(
  abortSignal: AbortSignal,
  doxId: number,
  name: string,
  notes: string,
  treatmentId?: number,
  retentionRules?: RetentionRules_all,
  retentionStartAt?: Date,
  retentionEndAt?: Date
): Promise<Dox> {
  const funcName = 'updateDox'
  const url = `${PDX_API_URL}/v1/dox/${doxId}`

  const parms = {
    name: name,
    notes: notes,
    treatment_id: treatmentId,
    retention_rules: retentionRules,
    retention_start_at: retentionStartAt ? Utils.toDate(retentionStartAt, true) : undefined,
    retention_end_at: retentionEndAt ? Utils.toDate(retentionEndAt, true) : undefined,
  }
  return axiosInstance
    .patch(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Dox.deserialize(resp.data as IDoxJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function moveDox(
  abortSignal: AbortSignal,
  doxId: number, // dox to move
  newName: string, // new name to assign to moved dox
  parentId: number
): Promise<Dox> {
  const funcName = 'moveDox'
  const url = `${PDX_API_URL}/v1/dox/${doxId}`

  const parms = {
    name: newName,
    parent_id: parentId,
  }
  return axiosInstance
    .patch(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Dox.deserialize(resp.data as IDoxJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function addDocumentsToDox(abortSignal: AbortSignal, doxId: number, documentIds: number[]): Promise<number[]> {
  const funcName = 'addDocumentsToDox'
  const url = `${PDX_API_URL}/v1/dox/${doxId}/documents`

  const parms = {
    documents_to_add: documentIds,
  }
  return axiosInstance
    .patch(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = resp.data as number[]
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function removeDocumentsFromDox(
  abortSignal: AbortSignal,
  doxId: number,
  documentIds: number[],
  stopRetentionIfNeeded: boolean
): Promise<number[]> {
  const funcName = 'removeDocumentsFromDox'
  const url = `${PDX_API_URL}/v1/dox/${doxId}/documents`

  const parms = {
    documents_to_remove: documentIds,
    // NOTE: recursive_removal indicates that removal of a document can be requested
    //       also for a document that is associated with a lower level dox.
    //       If false it is more efficient because it is not necessary to check the nested doxes.
    recursive_removal: false,
    stop_retention_if_needed: stopRetentionIfNeeded,
  }
  return axiosInstance
    .patch(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = resp.data as number[]
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function deliverDox(abortSignal: AbortSignal, doxId: number): Promise<Dox> {
  const funcName = 'deliverDox'
  const url = `${PDX_API_URL}/v1/dox/${doxId}`

  const parms = {
    deliver: true,
  }
  return axiosInstance
    .patch(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Dox.deserialize(resp.data as IDoxJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// DELETE

export function deleteDox(abortSignal: AbortSignal, doxId: number): Promise<number> {
  const funcName = 'deleteDox'
  const url = `${PDX_API_URL}/v1/dox/${doxId}`

  return axiosInstance
    .delete(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = resp.data.id
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function terminateDoxRetention(
  abortSignal: AbortSignal,
  doxId: number
): Promise<{ retentionId: number; doxDeleted: boolean }> {
  const funcName = 'terminateDoxRetention'
  const url = `${PDX_API_URL}/v1/dox/${doxId}/retentions`

  return axiosInstance
    .delete(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = { retentionId: resp.data.retention_id, doxDeleted: resp.data.dox_deleted }
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}
