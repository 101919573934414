import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function UploadDocIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 11.990478,3.9792514 5.9897459,9.9799837 h 3.994629 V 16.000492 H 13.98999 V 9.9799837 h 4.001221 z M 4.9833984,17.984623 v 1.999511 H 18.984375 v -1.999511 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
