import { useTranslation } from 'react-i18next'
import { Box, IconButton, Stack, StackProps, Tooltip, Typography, useTheme } from '@mui/material'
import { INotificationProps } from './Notification.types'
import {
  argumentFromType,
  bodyFromBodyNotification,
  icoFromType,
  accountFromType,
  tooltipFromType,
} from './NotificationUtils'
import * as dalDocument from '../../dal/DalDocument'
import * as dalDox from '../../dal/DalDox'
import * as dalTreatment from '../../dal/DalTreatment'
import * as dalContract from '../../dal/DalContract'
import * as dalContractVersion from '../../dal/DalContractVersion'
import * as dalInvitation from '../../dal/DalInvitation'
import AccountIdentity from '../identities/AccountIdentity'
import { Utils, dateTimeShortOptions } from '../../shared/Utils'
import { ArchiveIco, PinIco } from '../icons'
import { useNotificationsContext } from '../../contexts/NotificationsContext'
import { AccountInfo } from '../../models/Account'
import {
  ConsentRevokeCause,
  ContractType,
  InvitationPurposeType,
  NotificationsType,
  ProfileType,
} from '../../shared/Constants'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { NotificationContextDocumentRevision } from '../../models/NotificationContextDocumentRevision'
import { NotificationContextDocument } from '../../models/NotificationContextDocument'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'
import { ISimpleDialogData } from '../../dialogs/simpleDialog/SimpleDialog.types'
import msgIds from '../../locales/msgIds'
import { AxiosError } from 'axios'
import { NotificationContextRetention } from '../../models/NotificationContextRetention'
import { NotificationContextFlattenDox } from '../../models/NotificationContextFlattenDox'
import { useSnackbar } from 'notistack'
import { NotificationContextInvitation } from '../../models/NotificationContextInvitation'
import { NotificationContextPermission } from '../../models/NotificationContextPermission'
import { useAuthContext } from '../../contexts/AuthContext'

export default function Notification(props: INotificationProps & StackProps): JSX.Element {
  const {
    notification,
    onClickAvatar,
    onCloseDialog,
    onShowDocumentDetails,
    onShowDoxDetails,
    onShowContractVersion,
    ...rest
  } = props
  const { enqueueSnackbar } = useSnackbar()
  const { t, i18n } = useTranslation()
  const authContext = useAuthContext()
  const navigate = useNavigate()
  const theme = useTheme()
  const [isLoading, setIsLoading] = useState(false)
  const { togglePinnedStatus, archive } = useNotificationsContext()
  const [bodyClassName, setBodyClassName] = useState<string | undefined>('ellipsis-2')
  const backgroundColor =
    !notification.archivedAt && notification.pinned ? theme.palette.primary.lighter : theme.palette.background.default
  const leftIcon = icoFromType(notification.type)
  const author = accountFromType(notification)
  const argument = argumentFromType(notification.type, t)
  const body = bodyFromBodyNotification(notification, t, i18n)
  const date = Utils.toLocaleDateString(notification.createdAt, i18n, dateTimeShortOptions)

  // dialogs
  const [simpleDialogData, setSimpleDialogData] = useState<ISimpleDialogData>()
  const [simpleDialogOpen, setSimpleDialogOpen] = useState(false)

  const getAccountInfoToShow = (profileType?: ProfileType): AccountInfo[] => {
    switch (profileType) {
      case ProfileType.structure:
        return ['main']
      case ProfileType.operatorAdmin:
        return ['main', 'linked']
      case ProfileType.operatorExt:
      case ProfileType.operatorInt:
        return ['main', 'principal']
      case ProfileType.customer:
        return ['main']
      default:
        return []
    }
  }

  function onClickBody() {
    setBodyClassName((c) => {
      if (c === 'ellipsis-2') {
        return 'no-ellipsis'
      } else {
        return 'ellipsis-2'
      }
    })
  }

  async function showResource() {
    switch (notification.type) {
      case NotificationsType.userPrivateMessage:
      case NotificationsType.upcomingProfileSuspension:
      case NotificationsType.profileSuspension:
      case NotificationsType.upcomingProfileDeletion:
      case NotificationsType.profileDeletionBySystem:
      case NotificationsType.profileDeletionByOwner:
      case NotificationsType.profileDeletionByOperatorAdmin:
      case NotificationsType.upcomingUserDeletion:
      case NotificationsType.userDeletionBySystem:
      case NotificationsType.userDeletionByOwner:
      case NotificationsType.contractUpdated: {
        const contractId = notification.context?.contractVersion?.contract?.id
        const contractOwnerProfileId = notification.context?.contractVersion?.contract?.ownerProfileId ?? 0
        const contractType = notification.context?.contractVersion?.contract?.type
        const targetProfileType = notification.context?.contractVersion?.contract?.targetProfileType
        const versionId = notification.context?.contractVersion?.id
        const grantorProfileId = authContext.loggedProfileId ?? 0
        const atDate = notification.context?.contractVersion?.publishedAt
        if (contractId && contractType && targetProfileType && versionId) {
          await showContractVersionWithConsents(
            contractId,
            contractOwnerProfileId,
            contractType,
            targetProfileType,
            versionId,
            grantorProfileId,
            atDate
          )
        }
        break
      }
      case NotificationsType.dossierDelivered:
      case NotificationsType.documentRevisionCreated: {
        const documentId = (notification.context?.documentRevision as NotificationContextDocumentRevision)?.document?.id
        const revisionId = (notification.context?.documentRevision as NotificationContextDocumentRevision)?.id
        if (documentId) {
          await showDocument(documentId, revisionId)
        }
        break
      }
      case NotificationsType.documentRevisionViewed: {
        const documentId = (notification.context?.documentRevision as NotificationContextDocumentRevision)?.documentId
        const revisionId = (notification.context?.documentRevision as NotificationContextDocumentRevision)?.id
        if (documentId) {
          await showDocument(documentId, revisionId)
        }
        break
      }
      case NotificationsType.documentRevisionDownloaded: {
        const documentId = (notification.context?.documentRevision as NotificationContextDocumentRevision)?.documentId
        const revisionId = (notification.context?.documentRevision as NotificationContextDocumentRevision)?.id
        if (documentId) {
          await showDocument(documentId, revisionId)
        }
        break
      }
      case NotificationsType.dataProcessingGranted:
      case NotificationsType.dataProcessingUpdated:
      case NotificationsType.dataProcessingSuspended:
      case NotificationsType.dataProcessingRestored:
      case NotificationsType.upcomingDataProcessingExpiration:
      case NotificationsType.dataProcessingExpired:
      case NotificationsType.privacyPolicyUpdated: {
        const contractId = notification.context?.contractVersion?.contract?.id
        const contractOwnerProfileId = notification.context?.contractVersion?.contract?.ownerProfileId ?? 0
        const contractType = notification.context?.contractVersion?.contract?.type
        const targetProfileType = notification.context?.contractVersion?.contract?.targetProfileType
        const versionId = notification.context?.contractVersion?.id
        const grantorProfileId = authContext.loggedProfileId ?? 0
        const atDate = notification.context?.contractVersion?.publishedAt
        if (contractId && contractType && targetProfileType && versionId) {
          await showContractVersionWithConsents(
            contractId,
            contractOwnerProfileId,
            contractType,
            targetProfileType,
            versionId,
            grantorProfileId,
            atDate
          )
        }
        break
      }
      case NotificationsType.privacyPolicyConsentUpdated: {
        const contractId = notification.context?.consent?.contract?.id
        const contractOwnerProfileId = notification.context?.consent?.contract?.ownerProfileId
        const contractType = notification.context?.consent?.contract?.type
        const targetProfileType = notification.context?.consent?.contract?.targetProfileType
        const versionId = notification.context?.consent?.version?.id
        const grantorProfileId = author?.profile?.profileId
        const atDate = notification.context?.consent?.revokedAt ?? notification.context?.consent?.createdAt
        if (
          contractId &&
          contractOwnerProfileId &&
          contractType &&
          targetProfileType &&
          versionId &&
          grantorProfileId
        ) {
          await showContractVersionWithConsents(
            contractId,
            contractOwnerProfileId,
            contractType,
            targetProfileType,
            versionId,
            grantorProfileId,
            atDate
          )
        }
        break
      }
      case NotificationsType.profileAttached:
      case NotificationsType.profileDeattached:
      case NotificationsType.upcomingSubscriptionTrialExpiration:
      case NotificationsType.upcomingDocumentRetentionExpiration: {
        const documentId = (notification.context?.retention as NotificationContextRetention)?.documentId
        if (documentId) {
          await showDocument(documentId)
        }
        break
      }
      case NotificationsType.documentRetentionExpired:
      case NotificationsType.upcomingDoxRetentionExpiration: {
        const doxId = (notification.context?.retention as NotificationContextRetention)?.doxId
        if (doxId) {
          await showDox(doxId)
        }
        break
      }
      case NotificationsType.doxRetentionExpired:
      case NotificationsType.doxDelivered: {
        const flattenDoxIds = (notification.context?.flattenDox as NotificationContextFlattenDox)?.flattenDoxIds
        if (flattenDoxIds.length > 0) {
          const doxId = flattenDoxIds[0].doxId
          await showDox(doxId)
        }
        break
      }
      case NotificationsType.doxReceived: {
        const flattenDoxIds = (notification.context?.flattenDox as NotificationContextFlattenDox)?.flattenDoxIds
        if (flattenDoxIds.length > 0) {
          const doxId = flattenDoxIds[0].doxId
          await showDox(doxId)
        }
        break
      }
      case NotificationsType.invitationPermitted: {
        const profileId = notification.context?.author?.profile?.profileId
        if (profileId) {
          await sendInvite(profileId)
        }
        break
      }
      case NotificationsType.invitationReceived: {
        const invitationId = (notification.context?.permission as NotificationContextPermission)?.id
        if (invitationId) {
          await acceptInvite(invitationId)
        }
        break
      }
      case NotificationsType.invitationAccepted: {
        const createdProfileId = (notification.context?.invitation as NotificationContextInvitation)?.createdProfileId
        if (createdProfileId) {
          await showAccountCard(createdProfileId)
        }
        break
      }
      default:
    }
  }

  async function showDocument(documentId: number, revisionId?: number) {
    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const document = await dalDocument.getDocument(abortController.signal, documentId)
      if (document) {
        onShowDocumentDetails(document, revisionId)
      }
    } catch (err) {
      if ((err as AxiosError)?.response?.status === 403) {
        setSimpleDialogOpen(true)
        setSimpleDialogData({
          title: t(msgIds.MSG_DOCUMENT_SHOW_TITLE),
          content: t(msgIds.MSG_DOCUMENT_NOT_AVAILABLE),
          actionsStyle: 'Ok',
          onClose: async (result) => {
            setSimpleDialogOpen(false)
          },
        })
      } else {
        Utils.enqueueSnackbarError2(err, t)
      }
    } finally {
      setIsLoading(false)
    }
  }

  async function showDox(doxId: number) {
    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const dox = await dalDox.getDoxDetails(abortController.signal, doxId)
      if (dox) {
        const treatment = dox.treatmentId
          ? await dalTreatment.getTreatment(abortController.signal, dox.treatmentId)
          : undefined
        onShowDoxDetails(dox, treatment)
      }
    } catch (err) {
      if ((err as AxiosError)?.response?.status === 403) {
        setSimpleDialogOpen(true)
        setSimpleDialogData({
          title: t(msgIds.MSG_HISTORY_SHOW_DOX_TITLE),
          content: t(msgIds.MSG_HISTORY_SHOW_DOX_NOT_AVAILABLE),
          actionsStyle: 'Ok',
          onClose: async (result) => {
            setSimpleDialogOpen(false)
          },
        })
      } else {
        Utils.enqueueSnackbarError2(err, t)
      }
    } finally {
      setIsLoading(false)
    }
  }

  async function showContractVersion(
    contractId: number,
    contractOwnerProfileId: number,
    contractType: ContractType,
    targetProfileType: ProfileType,
    versionId: number
  ) {
    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const contractVersion = await dalContractVersion.getContractVersion(abortController.signal, contractId, versionId)
      onShowContractVersion(contractOwnerProfileId, contractType, targetProfileType, contractVersion)
    } catch (err) {
      if ((err as AxiosError)?.response?.status === 403) {
        setSimpleDialogOpen(true)
        setSimpleDialogData({
          title: t(msgIds.MSG_HISTORY_SHOW_CONTRACT_TITLE),
          content: t(msgIds.MSG_HISTORY_SHOW_CONTRACT_NOT_AVAILABLE),
          actionsStyle: 'Ok',
          onClose: async (result) => {
            setSimpleDialogOpen(false)
          },
        })
      } else {
        Utils.enqueueSnackbarError2(err, t)
      }
    } finally {
      setIsLoading(false)
    }
  }

  async function showContractVersionWithConsents(
    contractId: number,
    contractOwnerProfileId: number,
    contractType: ContractType,
    targetProfileType: ProfileType,
    versionId: number,
    grantorProfileId: number,
    atDate?: Date
  ) {
    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const contracts = await dalContract.getPublishedContracts(abortController.signal, {
        targetProfileType: targetProfileType,
        ownerProfileId: contractOwnerProfileId,
        getSections: false,
        ignoreSectionRead: true,
        contractType: contractType,
      })
      const contract = contracts[0]
      if (contract) {
        const lastPublishedContractVersion = contract.versions[0]
        if (lastPublishedContractVersion && lastPublishedContractVersion.id === versionId) {
          navigate(`/contracts/versions/viewer`, {
            state: {
              grantorProfileId: grantorProfileId,
              targetProfileType: targetProfileType,
              contractOwnerProfileId: contractOwnerProfileId,
              contractType: contractType,
              isEditable: true,
            },
          })
          props.onCloseDialog()
          return
        }
      }
      const consentsHistory = await dalContract.getContractConsents(abortController.signal, {
        grantorProfileId: grantorProfileId,
        contractId: contractId,
        versionId: versionId,
        getSectionsText: true,
        atDate: atDate,
      })
      const consentsSnapshot = consentsHistory[0]
      if (consentsSnapshot) {
        // To consistently represent the state of consent, delete revokedAt if caused
        // by contract publication and after the publication of the contract
        consentsSnapshot.sections.forEach((section) => {
          if (
            atDate &&
            section.consent?.revokedAt &&
            section.consent?.revokeCause &&
            section.consent.revokeCause === ConsentRevokeCause.automatically &&
            section.consent?.revokedAt > atDate
          ) {
            section.consent.revokedAt = undefined
          }
        })
        onShowContractVersion(contractOwnerProfileId, contractType, targetProfileType, consentsSnapshot)
      }
    } catch (err) {
      if ((err as AxiosError)?.response?.status === 403) {
        setSimpleDialogOpen(true)
        setSimpleDialogData({
          title: t(msgIds.MSG_HISTORY_SHOW_CONTRACT_TITLE),
          content: t(msgIds.MSG_HISTORY_SHOW_CONTRACT_NOT_AVAILABLE),
          actionsStyle: 'Ok',
          onClose: async (result) => {
            setSimpleDialogOpen(false)
          },
        })
      } else {
        Utils.enqueueSnackbarError2(err, t)
      }
    } finally {
      setIsLoading(false)
    }
  }

  async function sendInvite(profileId: number) {
    setSimpleDialogOpen(true)
    setSimpleDialogData({
      title: t(msgIds.MSG_INVITATION_SEND_TITLE),
      content: t(msgIds.MSG_INVITATION_SEND_CONFIRM),
      actionsStyle: 'yesNO',
      onClose: async (result) => {
        setSimpleDialogOpen(false)
        if (result.userChoice !== 'yes') return
        await sendInviteForCollaboration(profileId)
      },
    })
  }

  async function sendInviteForCollaboration(profileId: number) {
    try {
      setIsLoading(true)
      const abortController = new AbortController()
      await dalInvitation.createInvitation(abortController.signal, profileId, InvitationPurposeType.collaborate)
      enqueueSnackbar(t(msgIds.MSG_INVITATION_SENDED_SUCCESSFULLY), { variant: 'success' })
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  async function acceptInvite(invitationId: number) {
    setSimpleDialogOpen(true)
    setSimpleDialogData({
      title: t(msgIds.MSG_INVITATION_ACCEPTANCE_TITLE),
      content: t(msgIds.MSG_INVITATION_ACCEPTANCE_CONFIRM),
      actionsStyle: 'yesNO',
      onClose: async (result) => {
        setSimpleDialogOpen(false)
        if (result.userChoice !== 'yes') return
        await acceptInviteForCollaboration(invitationId)
      },
    })
  }

  async function acceptInviteForCollaboration(invitationId: number) {
    try {
      setIsLoading(true)
      const abortController = new AbortController()
      await dalInvitation.acceptInvitation(abortController.signal, invitationId)
      enqueueSnackbar(t(msgIds.MSG_INVITATION_SENDED_SUCCESSFULLY), { variant: 'success' })
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  function showAccountCard(profileId: number) {
    navigate(`/account_card`, {
      state: {
        profileId: profileId,
      },
    })
    onCloseDialog()
  }

  return (
    <Stack px={3} py={2} direction="row" alignItems="flex-start" spacing={1} sx={{ backgroundColor }} {...rest}>
      {simpleDialogData && <SimpleDialog {...simpleDialogData} isOpen={simpleDialogOpen}></SimpleDialog>}
      {leftIcon && (
        <Tooltip title={tooltipFromType(notification.type, t)}>
          <IconButton onClick={() => showResource()}>{leftIcon({})}</IconButton>
        </Tooltip>
      )}
      <Stack flexGrow={1} spacing={1}>
        {author && (
          <AccountIdentity
            account={author}
            infoToShow={getAccountInfoToShow(author.profile?.type)}
            showProfileInfo={true}
            avatarClicked={onClickAvatar}
          />
        )}
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap-reverse">
            {argument && <Typography variant="subtitle2">{argument}</Typography>}
            {date && (
              <Typography variant="body2" color={theme.palette.secondary.light}>
                {date}
              </Typography>
            )}
          </Stack>
          {body && (
            <Typography onClick={onClickBody} variant="caption" className={bodyClassName} sx={{ cursor: 'pointer' }}>
              {body}
            </Typography>
          )}
        </Box>
      </Stack>
      {!notification.archivedAt && (
        <Stack>
          <IconButton onClick={() => archive(notification)}>
            <ArchiveIco />
          </IconButton>
          <IconButton onClick={() => togglePinnedStatus(notification)}>
            <PinIco sx={{ transform: !notification.pinned ? 'rotate(90deg)' : 'unset' }} />
          </IconButton>
        </Stack>
      )}
    </Stack>
  )
}
