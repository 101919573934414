export interface IProductFeaturesJson {
  prf_showcase?: boolean // operator
  treatments_conf?: boolean // operator
  prv_policy_editor?: boolean // operator
  prv_plant_hist?: boolean // operator
  prv_policy_consents?: boolean // operator
  min_anagraphic?: boolean // operator
  shared_docs_read?: boolean // operator
  shared_docs_dwn?: boolean // operator
  company_archives?: boolean // operator
  data_retention?: boolean // operator
  dox_and_doc_hist?: boolean // operator, customer
  users_hist?: boolean // operator
  int_docs_editor?: boolean // operator, customer
  ext_docs_editor?: boolean // operator, customer
  dox_delivery?: boolean // operator
  search_engine?: boolean // customer
  data_processing?: boolean // customer
  archive?: boolean // customer
  dox_and_docs_sharing?: boolean // customer
  dox_and_docs_receiving?: boolean // customer
  operators_hist?: boolean // customer
  treatments_max_count?: number // operator
}

export class ProductFeatures {
  prfShowcase?: boolean
  treatmentsConf?: boolean
  prvPolicyEditor?: boolean
  prvPlantHist?: boolean
  prvPolicyConsents?: boolean
  minAnagraphic?: boolean
  sharedDocsRead?: boolean
  sharedDocsDwn?: boolean
  companyArchives?: boolean
  dataRetention?: boolean
  doxAndDocHist?: boolean
  usersHist?: boolean
  intDocsEditor?: boolean
  extDocsEditor?: boolean
  doxDelivery?: boolean
  searchEngine?: boolean
  dataProcessing?: boolean
  archive?: boolean
  doxAndDocsSharing?: boolean
  doxAndDocsReceiving?: boolean
  operatorsHist?: boolean
  treatmentsMaxCount?: number // currently not used

  constructor() {}

  public static serialize(obj: ProductFeatures): IProductFeaturesJson {
    return {
      prf_showcase: obj.prfShowcase,
      treatments_conf: obj.treatmentsConf,
      prv_policy_editor: obj.prvPolicyEditor,
      prv_plant_hist: obj.prvPlantHist,
      prv_policy_consents: obj.prvPolicyConsents,
      min_anagraphic: obj.minAnagraphic,
      shared_docs_read: obj.sharedDocsRead,
      shared_docs_dwn: obj.sharedDocsDwn,
      company_archives: obj.companyArchives,
      data_retention: obj.dataRetention,
      dox_and_doc_hist: obj.doxAndDocHist,
      users_hist: obj.usersHist,
      int_docs_editor: obj.intDocsEditor,
      ext_docs_editor: obj.extDocsEditor,
      dox_delivery: obj.doxDelivery,
      search_engine: obj.searchEngine,
      data_processing: obj.dataProcessing,
      archive: obj.archive,
      dox_and_docs_sharing: obj.doxAndDocsSharing,
      dox_and_docs_receiving: obj.doxAndDocsReceiving,
      operators_hist: obj.operatorsHist,
      treatments_max_count: obj.treatmentsMaxCount,
    }
  }

  public static deserialize(json: IProductFeaturesJson): ProductFeatures {
    const res = new ProductFeatures()
    res.prfShowcase = json.prf_showcase
    res.treatmentsConf = json.treatments_conf
    res.prvPolicyEditor = json.prv_policy_editor
    res.prvPlantHist = json.prv_plant_hist
    res.prvPolicyConsents = json.prv_policy_consents
    res.minAnagraphic = json.min_anagraphic
    res.sharedDocsRead = json.shared_docs_read
    res.sharedDocsDwn = json.shared_docs_dwn
    res.companyArchives = json.company_archives
    res.dataRetention = json.data_retention
    res.doxAndDocHist = json.dox_and_doc_hist
    res.usersHist = json.users_hist
    res.intDocsEditor = json.int_docs_editor
    res.extDocsEditor = json.ext_docs_editor
    res.doxDelivery = json.dox_delivery
    res.searchEngine = json.search_engine
    res.dataProcessing = json.data_processing
    res.archive = json.archive
    res.doxAndDocsSharing = json.dox_and_docs_sharing
    res.doxAndDocsReceiving = json.dox_and_docs_receiving
    res.operatorsHist = json.operators_hist
    res.treatmentsMaxCount = json.treatments_max_count
    return res
  }

  public getPropertyValue(propertyName: keyof ProductFeatures): boolean | number {
    return this[propertyName] as boolean | number
  }
}
