import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function DeliverIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 20.630734,8.9611833 h -2.369235 v -3.159 H 7.2048949 a 1.58482,1.58482 0 0 0 -1.452895,0.96265 H 0.99999999 V 8.3518332 H 5.6253949 V 9.7964133 H 2.024935 v 1.5869997 h 3.6004599 v 1.52087 h -2.740845 v 1.587 h 2.740845 v 1.577671 h 1.5795 a 2.36928,2.36928 0 0 0 4.7385551,0 h 4.738549 a 2.369265,2.369265 0 0 0 4.7385,0 h 1.5795 V 12.120213 Z M 9.5741599,16.858738 A 0.78974998,0.78974998 0 1 1 10.36392,16.068973 0.79205998,0.79205998 0 0 1 9.5741599,16.858738 Z m 7.1078391,-2.369265 h -5.354574 a 2.34096,2.34096 0 0 0 -3.5065002,0 H 7.2048949 V 7.3816882 h 9.4771041 z m 2.369265,2.369265 a 0.78974998,0.78974998 0 1 1 0.789735,-0.789765 0.79206499,0.79206499 0 0 1 -0.789759,0.789765 z m -0.789765,-4.7385 v -1.97442 h 1.974396 l 1.547915,1.974395 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
