import { HistoryContextContract, IHistoryContextContractJson } from './HistoryContextContract'

export interface IHistoryContextContractVersionJson {
  id: number
  major_version: number
  minor_version: number
  published_at?: string
  is_new_acknowledgment_required: boolean
  are_new_consents_required: boolean
  has_new_sections_with_consent: boolean
  contract_id: number
  contract?: IHistoryContextContractJson
}

export class HistoryContextContractVersion {
  id: number = 0
  majorVersion: number = 0
  minorVersion: number = 0
  publishedAt?: Date
  isNewAcknowledgmentRequired: boolean = false
  areNewConsentsRequired: boolean = false
  hasNewSectionsWithConsent: boolean = false
  contractId: number = 0
  contract?: HistoryContextContract

  constructor() {}

  public static serialize(obj: HistoryContextContractVersion): IHistoryContextContractVersionJson {
    return {
      id: obj.id,
      major_version: obj.majorVersion,
      minor_version: obj.minorVersion,
      published_at: obj.publishedAt?.toISOString(),
      is_new_acknowledgment_required: obj.isNewAcknowledgmentRequired,
      are_new_consents_required: obj.areNewConsentsRequired,
      has_new_sections_with_consent: obj.hasNewSectionsWithConsent,
      contract_id: obj.contractId,
      contract: obj.contract ? HistoryContextContract.serialize(obj.contract) : undefined,
    }
  }

  public static deserialize(json: IHistoryContextContractVersionJson): HistoryContextContractVersion {
    const res = new HistoryContextContractVersion()
    res.id = json.id
    res.majorVersion = json.major_version
    res.minorVersion = json.minor_version
    res.publishedAt = json.published_at ? new Date(json.published_at) : undefined
    res.isNewAcknowledgmentRequired = json.is_new_acknowledgment_required
    res.areNewConsentsRequired = json.are_new_consents_required
    res.hasNewSectionsWithConsent = json.has_new_sections_with_consent
    res.contractId = json.contract_id
    res.contract = json.contract ? HistoryContextContract.deserialize(json.contract) : undefined
    return res
  }
}
