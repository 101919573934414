import React from 'react'
import { ITreatmentListProps } from './TreatmentList.types'
import { Divider, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import { TreatmentLegalBaseTranslationMap } from '../../shared/Constants'
import { Treatment } from '../../models/Treatment'
import { useTranslation } from 'react-i18next'

export default function TreatmentList(props: ITreatmentListProps) {
  const { t } = useTranslation()

  const handleClick = (treatment: Treatment) => {
    if (props.onItemSelect) {
      props.onItemSelect(treatment)
    }
  }

  return (
    <List sx={{ minWidth: '320px' }}>
      {props.treatments.map((treatment) => (
        <React.Fragment key={treatment.id}>
          <ListItem disableGutters disablePadding>
            <ListItemButton role={undefined} onClick={() => handleClick(treatment)}>
              <ListItemText
                primary={`${treatment.code} - ${treatment.shortDescription}`}
                secondary={t(TreatmentLegalBaseTranslationMap[treatment.legalBase])}
              />
            </ListItemButton>
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  )
}
