import { StepType } from '@reactour/tour'
import { stepFactory } from './GuideUtils'
import { hints } from '../shared/Constants'
import { Layout } from '../hooks/Layout'
import { IUiContextState } from '../contexts/UiContext'

const tourName = hints.HINT_FIRST_LOGIN
export function buildOperatorTourInitialSteps(layout: Layout, uiContextState: IUiContextState) {
  const _steps: StepType[] = []
  _steps.push(
    stepFactory({
      tourName,
      text: `Benvenuto in PersonalDOX\nQuesta guida ti aiuterà a capire dove trovare gli elementi principali dell'interfaccia`,
    })
  )
  _steps.push(
    stepFactory({
      tourName,
      selector: 'search-selector',
      text: layout.isPhone
        ? `Con questo pulsante puoi aprire il menu per la ricerca di altri soggetti all'interno di PersonalDOX\nCon i comandi disponibili puoi cercare le Strutture e i Professionisti.\nPuoi cercare anche tra i tuoi Collaboratori o tra gli Utenti privati per i quali sei autorizzato al trattamento dei dati personali`
        : `Con questi comandi puoi cercare le Strutture e i Professionisti registrati in PersonalDOX.\nPuoi cercare anche tra i tuoi Collaboratori o tra gli Utenti privati per i quali sei autorizzato al trattamento dei dati personali`,
      actionAfter: () => {
        if (layout.isMobile) {
          uiContextState.openLeftbar()
        }
      },
    })
  )
  if (layout.isPhone) {
    _steps.push(
      stepFactory({
        tourName,
        selector: 'leftbar-toggle',
        text: `Con questo pulsante puoi aprire o chiudere la barra laterale dei comandi`,
      })
    )
  }
  _steps.push(
    stepFactory({
      tourName,
      selector: 'logged-operator',
      text: `Quest'area riporta il profilo che stai utilizzando e i comandi per gestirlo`,
    })
  )
  _steps.push(
    stepFactory({
      tourName,
      selector: 'linked-structure',
      text: `Quest'area riporta il profilo della struttura per la quale stai operando e i comandi per gestirla`,
    })
  )
  _steps.push(
    stepFactory({
      tourName,
      selector: 'notifications',
      text: `Questo comando visualizza l'elenco di tutte le notifiche non ancora archiviate`,
    })
  )
  if (!layout.isPhone) {
    _steps.push(
      stepFactory({
        tourName,
        selector: 'notice-board',
        text: `Questo comando ti porta automaticamente nella bacheca del profilo in uso`,
      })
    )
    _steps.push(
      stepFactory({
        tourName,
        selector: 'tutorials',
        text: `Questo comando visualizza l'elenco dei tutorial per imparare ad usare PersonalDOX`,
      })
    )
  }
  _steps.push(
    stepFactory({
      tourName,
      selector: 'profile-info',
      text: layout.isPhone
        ? `Questo comando apre un menu dal quale puoi diconnetterti, andare alla bacheca del profilo, guardare i tutorial disponibili o gestire la tua sottoscrizione`
        : `Questo comando apre un menu dal quale puoi diconnetterti o gestire la tua sottoscrizione`,
    })
  )
  return _steps
}
