import { PageContainer, PageContent } from '../../components/pageContainer/PageContainer'
import CommandBar from '../../components/commandBar/CommandBar'
import { TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import * as dalAccount from '../../dal/DalAccount'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Utils } from '../../shared/Utils'
import { useAuthContext } from '../../contexts/AuthContext'
import { DashboardItemType } from '../../shared/Constants'
import { DashboardItem } from '../../models/DashboardItem'
import { AddIco } from '../../components/icons'
import { StyledDialog } from '../../dialogs/styledDialog/StyledDialog'
import { ViewHeader } from '../../components/viewHeader/ViewHeader'
import { ViewActions } from '../../components/viewActions/ViewActions'
import { ViewActionsButton } from '../../components/viewActions/ViewActionsButton'
import { FePreferences } from '../../models/FePreferences'
import { DashboardItemContextNote } from '../../models/DashboardItemContextNote'
import { NoticeBoard } from '../../components/noticeBoard/NoticeBoard'
import { ViewContent } from '../../components/viewContent/ViewContent'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'
import { IDialogResultBase } from '../../components/template/dialogTemplate/DialogTemplate.types'
import { useLayout } from '../../hooks/Layout'

export default function NoticeBoardPage() {
  const { t } = useTranslation()
  const authContext = useAuthContext()
  const { isMobile } = useLayout()
  const [editingNoteIndex, setEditingNoteIndex] = useState<number>(-1)
  const [editingNote, setEditingNote] = useState<DashboardItem | undefined>()
  const [editingNoteDialogOpen, setEditingNoteDialogOpen] = useState<boolean>(false)
  const noteTextFieldRef = useRef<HTMLInputElement>(null)
  const [deletingNoteIndex, setDeletingNoteIndex] = useState<number>(-1)
  const fePreferences: FePreferences = authContext.loggedAccount?.profile?.fePreferences ?? new FePreferences()
  const { notes, othersNotes } = useMemo(() => {
    const allNotes: DashboardItem[] = fePreferences?.dashboardCfg?.items || []
    const notes: DashboardItem[] = []
    const othersNotes: DashboardItem[] = []
    for (const n of allNotes) {
      if (
        n.contextNote?.customerProfileId === 0 ||
        n.contextNote?.customerProfileId === authContext.assistedAccountProfileId
      ) {
        notes.push(n)
      } else {
        othersNotes.push(n)
      }
    }
    return { notes, othersNotes }
  }, [fePreferences?.dashboardCfg?.items, authContext.assistedAccountProfileId])

  useEffect(() => {
    if (!editingNoteDialogOpen) {
      return
    }
    if (noteTextFieldRef.current) {
      noteTextFieldRef.current?.focus()
    } else {
      const timer = setTimeout(() => {
        noteTextFieldRef.current?.focus()
      }, 0)
      return () => clearTimeout(timer)
    }
  }, [editingNoteDialogOpen])

  async function updateNotes(items: DashboardItem[]) {
    if (!authContext.loggedAccount?.profile || !authContext.loggedProfileId) {
      return
    }
    try {
      const abortController = new AbortController()
      const updatedAccount = await dalAccount.updateProfile(abortController.signal, authContext.loggedProfileId, {
        fePreferences: {
          ...fePreferences,
          dashboardCfg: {
            isInitialized: false,
            ...fePreferences.dashboardCfg,
            items: items.concat(othersNotes),
          },
        },
      })
      authContext.loggedAccount.profile.fePreferences = updatedAccount.profile?.fePreferences
    } catch (error) {
      Utils.enqueueSnackbarError2(error, t)
    }
  }

  function onCreateNote() {
    setEditingNoteIndex(-1)
    const editingNote = new DashboardItem()
    editingNote.type = DashboardItemType.note
    editingNote.contextNote = editingNote.contextNote || new DashboardItemContextNote()
    editingNote.contextNote.schema = 1
    editingNote.contextNote.customerProfileId = authContext.assistedAccountProfileId || 0
    setEditingNote(editingNote)
    setEditingNoteDialogOpen(true)
  }
  function onEditNote(index: number) {
    const note = notes[index]
    setEditingNoteIndex(index)
    setEditingNote(note)
    setEditingNoteDialogOpen(true)
  }
  async function onDeleteNote(index: number) {
    setDeletingNoteIndex(index)
  }
  async function deleteNote(index: number) {
    const note = notes[index]
    if (!note) {
      return
    }
    const items = [...notes]
    items.splice(index, 1)
    return updateNotes(items)
  }

  async function onSaveNote() {
    let items
    if (editingNoteIndex < 0 && editingNote) {
      items = [editingNote, ...notes]
    } else if (editingNote) {
      const note = notes[editingNoteIndex]
      if (!note) {
        return
      }
      items = [...notes]
      items.splice(editingNoteIndex, 1, editingNote)
    }
    if (!items) {
      return
    }
    await updateNotes(items)
    setEditingNoteDialogOpen(false)
  }

  function onChangeEditingNote(text: string) {
    setEditingNote((note) => {
      if (!note) {
        return note
      }
      const contextNote = note.contextNote || new DashboardItemContextNote()
      return { ...note, contextNote: { ...contextNote, text } }
    })
  }

  function onMoveNote(fromIndex: number, toIndex: number) {
    const note = notes[fromIndex]
    if (!note || fromIndex === toIndex) {
      return
    }
    const items: DashboardItem[] = []
    notes.forEach((n, index) => {
      if (index === fromIndex) {
        return
      }
      if (index === toIndex) {
        items.push(note)
      }
      items.push(n)
    })
    if (toIndex >= notes.length) {
      items.push(note)
    }
    return updateNotes(items)
  }

  async function onCloseDeleteDialog(result: IDialogResultBase) {
    if (result.userChoice === 'yes') {
      await deleteNote(deletingNoteIndex)
    }
    setDeletingNoteIndex(-1)
  }

  return (
    <PageContainer>
      <CommandBar
        style={{ minHeight: 'auto' }}
        title={t(msgIds.MSG_NOTICE_BOARD_TITLE)}
        commands={[
          {
            commandText: t(msgIds.MSG_NOTICE_BOARD_ADD_ELEMENT),
            icon: <AddIco />,
            onClick: onCreateNote,
          },
        ]}
      />
      <PageContent sx={{ alignItems: 'stretch', px: isMobile ? 2 : 4 }}>
        <NoticeBoard notes={notes} onDeleteNote={onDeleteNote} onEditNote={onEditNote} onMoveNote={onMoveNote} />
        <StyledDialog open={editingNoteDialogOpen} onClose={() => setEditingNoteDialogOpen(false)}>
          <ViewHeader
            title={t(msgIds.MSG_NOTICE_BOARD_EDIT_ELEMENT_DIALOG_TITLE)}
            exitButtonVisible={true}
            onClickExit={() => setEditingNoteDialogOpen(false)}
          />
          <ViewContent>
            <TextField
              inputRef={noteTextFieldRef}
              multiline={true}
              rows={8}
              value={editingNote?.contextNote?.text}
              onChange={(event) => onChangeEditingNote(event.target.value)}
              sx={{ flex: 1 }}
            />
          </ViewContent>
          <ViewActions>
            <ViewActionsButton defaultAction onClick={onSaveNote}>
              {t(msgIds.MSG_SAVE)}
            </ViewActionsButton>
            <ViewActionsButton onClick={() => setEditingNoteDialogOpen(false)}>
              {t(msgIds.MSG_CANCEL)}
            </ViewActionsButton>
          </ViewActions>
        </StyledDialog>
        <SimpleDialog
          actionsStyle="yesNO"
          title={t(msgIds.MSG_NOTICE_BOARD_DELETE_ELEMENT_DIALOG_CONFIRM_TITLE)}
          content={t(msgIds.MSG_NOTICE_BOARD_DELETE_ELEMENT_DIALOG_CONFIRM_BODY)}
          isOpen={deletingNoteIndex >= 0}
          onClose={onCloseDeleteDialog}
        />
      </PageContent>
    </PageContainer>
  )
}
