import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import { createRoot } from 'react-dom/client'
import Editor from '@toast-ui/editor'
import { IMarkdownEditorNewProps } from './MarkdownEditorNew.types'
import { RedoIco, UndoIco } from '../icons'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'
import { Utils } from '../../shared/Utils'

const MarkdownEditorNew = forwardRef((props: IMarkdownEditorNewProps, ref) => {
  const { onContentChanged, height, style } = props
  const { t } = useTranslation()
  const componentRef = useRef(null)
  const editorInstRef = useRef<Editor | null>(null)
  const isEditorInitializedRef = useRef(false)
  const isContentInitializingRef = useRef(false)
  const changeTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const textRef = useRef<string | null>(null)
  const checksumRef = useRef<string | null>(null)

  useImperativeHandle(ref, () => ({
    initializeText: (text: string, checksum: string) => {
      if (editorInstRef?.current && text !== undefined && (!checksumRef.current || checksumRef.current !== checksum)) {
        isContentInitializingRef.current = true
        textRef.current = text
        checksumRef.current = checksum
        if (isEditorInitializedRef.current) {
          editorInstRef.current.setMarkdown(text)
        }
        // if (!checksumRef.current) {
        //   checksumRef.current = Utils.getTextSHA256Checksum(text)
        // }
        setTimeout(() => {
          isContentInitializingRef.current = false
          editorInstRef.current?.moveCursorToEnd()
        }, 50)
      }
    },
  }))

  function createUndoButton() {
    const button = document.createElement('button')
    button.className = 'toastui-editor-toolbar-icons last'
    button.style.backgroundImage = 'none'
    button.style.margin = '0'

    const root = createRoot(button)
    root.render(<UndoIco />)

    button.addEventListener('click', () => {
      editorInstRef.current?.exec('undo')
    })

    return button
  }

  function createRedoButton() {
    const button = document.createElement('button')
    button.className = 'toastui-editor-toolbar-icons last'
    button.style.backgroundImage = 'none'
    button.style.margin = '0'

    const root = createRoot(button)
    root.render(<RedoIco />)

    button.addEventListener('click', () => {
      editorInstRef.current?.exec('redo')
    })

    return button
  }

  useEffect(() => {
    if (!editorInstRef.current) {
      const editor = new Editor({
        el: componentRef.current!,
        height: height ?? 'auto',
        autofocus: true,
        usageStatistics: false,
        hideModeSwitch: true,
        initialEditType: 'wysiwyg',
        toolbarItems: [
          [
            {
              el: createUndoButton(),
              name: 'Undo',
              tooltip: t(msgIds.MSG_DOCUMENT_EDITOR_COMMAND_UNDO).toString(),
            },
            {
              el: createRedoButton(),
              name: 'Redo',
              tooltip: t(msgIds.MSG_DOCUMENT_EDITOR_COMMAND_REDO).toString(),
            },
          ],
          ['heading', 'bold', 'italic' /*, 'strike'*/],
          ['ul', 'ol'],
          ['indent', 'outdent'],
          ['hr'],
        ],
        plugins: [],
        events: {
          change: () => {
            if (isEditorInitializedRef.current === false || isContentInitializingRef.current === true) return

            if (changeTimeoutRef.current) {
              clearTimeout(changeTimeoutRef.current)
            }

            // TODO: consider combined tecniques to evaluate if aquire the content like:
            //       - velocity of the writer
            //       - word completion
            changeTimeoutRef.current = setTimeout(() => {
              if (componentRef.current && onContentChanged) {
                const md = editorInstRef.current?.getMarkdown() ?? ''
                const checksum = Utils.getTextSHA256Checksum(md)
                checksumRef.current = checksum
                onContentChanged(md, checksum)
              }
            }, 500)
          },
        },
        ...props,
      })
      editorInstRef.current = editor
      isEditorInitializedRef.current = true
      editor.setMarkdown(textRef.current || '')
      props.onEditorLoaded && props.onEditorLoaded()
    }

    return () => {
      isEditorInitializedRef.current = false
      textRef.current = ''
      checksumRef.current = ''
      if (editorInstRef?.current) {
        // editorInstRef.current.destroy()
        editorInstRef.current = null
      }
      if (changeTimeoutRef.current) {
        clearTimeout(changeTimeoutRef.current)
      }
    }
  }, [componentRef.current])

  return <div ref={componentRef} style={{ ...style }} />
})

export default MarkdownEditorNew
