import { Button, Typography } from '@mui/material'
import { Fragment, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import DialogTemplate from '../../components/template/dialogTemplate/DialogTemplate'
import { ISimpleDialogProps } from './SimpleDialog.types'
import { IDialogResultBase } from '../../components/template/dialogTemplate/DialogTemplate.types'

export default function SimpleDialog(props: ISimpleDialogProps): JSX.Element {
  const { title, actionsStyle, maxWidth, content, canAbort, isOpen } = props
  const { t } = useTranslation()

  function onClose(result: IDialogResultBase) {
    !!props.onClose && props.onClose(result)
  }

  function getActions() {
    const actions: ReactNode[] = []
    actions.push(
      <Button
        key={'action1'}
        autoFocus
        variant={
          actionsStyle === 'Ok' || actionsStyle === 'YESno' || actionsStyle === 'YESnoabort' ? 'contained' : 'outlined'
        }
        sx={{ textTransform: 'none' }}
        onClick={() => onClose({ userChoice: actionsStyle === 'Ok' ? 'ok' : 'yes' })}
      >
        {actionsStyle === 'Ok' ? t(msgIds.MSG_OK) : t(msgIds.MSG_YES)}
      </Button>
    )
    if (
      actionsStyle === 'YESno' ||
      actionsStyle === 'yesNO' ||
      actionsStyle === 'YESnoabort' ||
      actionsStyle === 'yesNOabort'
    ) {
      actions.push(
        <Button
          key={'action2'}
          autoFocus
          variant={actionsStyle === 'yesNO' || actionsStyle === 'yesNOabort' ? 'contained' : 'outlined'}
          sx={{ textTransform: 'none' }}
          onClick={() => onClose({ userChoice: 'no' })}
        >
          {t(msgIds.MSG_NO)}
        </Button>
      )
    }
    if (actionsStyle === 'YESnoabort' || actionsStyle === 'yesNOabort') {
      actions.push(
        <Button
          key={'action3'}
          autoFocus
          variant={'outlined'}
          sx={{ textTransform: 'none' }}
          onClick={() => onClose({ userChoice: 'abort' })}
        >
          {t(msgIds.MSG_ABORT)}
        </Button>
      )
    }
    return actions
  }

  return (
    <DialogTemplate
      isOpen={!!isOpen}
      title={title ?? ''}
      isFullscreenForMobile={true}
      maxWidth={maxWidth ?? 'sm'}
      mt={3}
      canAbort={canAbort ?? true}
      onClose={onClose}
      showActions={true}
      actions={getActions()}
    >
      <Typography textAlign={'justify'}>
        {content &&
          content.split('\n').map((line, index) => (
            <Fragment key={index}>
              {line}
              <br />
            </Fragment>
          ))}
      </Typography>
    </DialogTemplate>
  )
}
