import { IPreferencesNotificationsJson, PreferencesNotifications } from './PreferencesNotifications'
import { IPreferencesPermissionsJson, PreferencesPermissions } from './PreferencesPermissions'

export interface IProfilePreferencesJson {
  permissions?: IPreferencesPermissionsJson
  notifications?: IPreferencesNotificationsJson
}

export class ProfilePreferences {
  permissions?: PreferencesPermissions = new PreferencesPermissions()
  notifications?: PreferencesNotifications = new PreferencesNotifications()

  constructor() {}

  public static serialize(obj: ProfilePreferences): IProfilePreferencesJson {
    return {
      permissions: obj.permissions ? PreferencesPermissions.serialize(obj.permissions) : undefined,
      notifications: obj.notifications ? PreferencesNotifications.serialize(obj.notifications) : undefined,
    }
  }

  public static deserialize(json: IProfilePreferencesJson): ProfilePreferences {
    const res = new ProfilePreferences()
    res.permissions = json.permissions ? PreferencesPermissions.deserialize(json.permissions) : undefined
    res.notifications = json.notifications ? PreferencesNotifications.deserialize(json.notifications) : undefined
    return res
  }
}
