import { Badge, IconButton, IconButtonProps, Tooltip, useTheme } from '@mui/material'
import msgIds from '../../locales/msgIds'
import { t } from 'i18next'
import { NotificationSystemIco } from '../icons'
import { useNotificationsContext } from '../../contexts/NotificationsContext'

export function NotificationButton(props: IconButtonProps): JSX.Element {
  const { unread: count } = useNotificationsContext()
  const theme = useTheme()

  return (
    <Tooltip title={t(msgIds.MSG_NOTIFICATIONS)}>
      <IconButton sx={{ color: theme.palette.primary.contrastText, mx: 0, width: 'auto', minWidth: '48px' }} {...props}>
        <Badge
          badgeContent={count}
          color="error"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <NotificationSystemIco color="inherit" />
        </Badge>
      </IconButton>
    </Tooltip>
  )
}
