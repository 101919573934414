import { ContractType, ContractVersionState, PDX_API_URL, ProfileType } from '../shared/Constants'
import log from '../shared/Logger'
import { axiosInstance } from '../contexts/AuthContext'
import { UriComposer } from '../shared/types/UriComposer'
import { Contract, IContractJson } from '../models/Contract'
import { ContractVersion } from '../models/ContractVersion'

// ********************
// GET
export type GetPublishedContractsArgs = {
  targetProfileType: ProfileType
  ownerProfileId: number
  getSections: boolean
  ignoreSectionRead?: boolean
  contractType?: ContractType
}
export function getPublishedContracts(abortSignal: AbortSignal, args: GetPublishedContractsArgs): Promise<Contract[]> {
  const funcName = 'getPublishedContracts'
  const url = UriComposer.create(`${PDX_API_URL}/v1/contracts`)
    .addParamNumber('by_version_state', ContractVersionState.published)
    .addParamNumber('by_target_type', args.targetProfileType)
    .addParamNumber('by_owner_profile', args.ownerProfileId)
    .addParamBool('sections', args.getSections)
    .addParamBoolNullable('is_public', args.ownerProfileId === 0)
    .addParamBoolNullable('ignore_section_read', args.ignoreSectionRead)
    .addParamNumberNullable('by_type', args.contractType)
    .getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Contract.deserializeArray(resp.data as IContractJson[])
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function getContract(abortSignal: AbortSignal, contractId: number): Promise<Contract> {
  const funcName = 'getContract'
  const url = UriComposer.create(`${PDX_API_URL}/v1/contracts/${contractId}`).getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Contract.deserialize(resp.data as IContractJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function getContractConsents(
  abortSignal: AbortSignal,
  args: {
    grantorProfileId: number
    contractId: number
    versionId?: number
    getSectionsText?: boolean
    atDate?: Date
  }
) {
  const funcName = 'getContractConsents'
  const url = UriComposer.create(`${PDX_API_URL}/v1/contracts/${args.contractId}/consents`)
    .addParamNumber('profile_id', args.grantorProfileId)
    .addParamNumberNullable('version_id', args.versionId)
    .addParamBoolNullable('text', args.getSectionsText, true, true)
    .addParamDateNullable('at_date', args.atDate)
    .getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = ContractVersion.deserializeArray(resp.data)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// POST

export function createNewContract(
  abortSignal: AbortSignal,
  type: ContractType,
  targetProfileType: ProfileType,
  name: string
): Promise<Contract> {
  const funcName = 'createNewContract'
  let url = `${PDX_API_URL}/v1/contracts`

  return axiosInstance
    .post(
      url,
      {
        type: type,
        target_profile_type: targetProfileType,
        name: name,
      },
      {
        headers: {
          'Accept-Version': '1.0.x',
        },
        signal: abortSignal,
      }
    )
    .then((resp) => {
      const reply = Contract.deserialize(resp.data as IContractJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// PATCH

export function updateContract(abortSignal: AbortSignal, contractId: number, name: string) {
  const funcName = 'updateContract'
  let url = `${PDX_API_URL}/v1/contracts/${contractId}`

  const parms = {
    name: name,
  }

  return axiosInstance
    .patch(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Contract.deserialize(resp.data as IContractJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// DELETE
