import { DoxTemplate, IDoxTemplateJson } from './DoxTemplate'

export interface IOrganizedDoxTemplateJson {
  name: string
  dsc: string
  dox_templates: IDoxTemplateJson[]
}

export class OrganizedDoxTemplate {
  name: string = ''
  dsc: string = ''
  doxTemplates: DoxTemplate[] = []

  constructor() {}

  public static Serialize(obj: OrganizedDoxTemplate): IOrganizedDoxTemplateJson {
    const res: IOrganizedDoxTemplateJson = {
      name: obj.name,
      dsc: obj.dsc,
      dox_templates: DoxTemplate.serializeArray(obj.doxTemplates),
    }
    return res
  }

  public static Deserialize(json: IOrganizedDoxTemplateJson): OrganizedDoxTemplate {
    const res = new OrganizedDoxTemplate()
    res.name = json.name
    res.dsc = json.dsc
    res.doxTemplates = DoxTemplate.deserializeArray(json.dox_templates)
    return res
  }

  public static serializeArray(objs: OrganizedDoxTemplate[]): IOrganizedDoxTemplateJson[] {
    const jsons = objs.map((p) => {
      return OrganizedDoxTemplate.Serialize(p)!
    })
    return jsons
  }

  public static deserializeArray(json: IOrganizedDoxTemplateJson[]): OrganizedDoxTemplate[] {
    const res = json.map((p) => {
      return OrganizedDoxTemplate.Deserialize(p)!
    })
    return res
  }
}
