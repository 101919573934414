import { useCallback, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import * as dalDocument from '../../dal/DalDocument'
import { Document } from '../../models/Document'
import { DocumentRevision, DocumentRevisionContentType } from '../../models/DocumentRevision'
import { useWorkingDocumentsContext } from '../../contexts/WorkingDocumentsContext'
import { validateDocumentInfoForm } from './DocumentInfoFormUtils'
import { isPersonalDoxMimetype } from '../../shared/Constants'
import { useDocumentCacheContext } from '../../contexts/DocumentCacheContext'
import { Utils } from '../../shared/Utils'
import { useTranslation } from 'react-i18next'
import { IDocumentEditorState } from './DocumentEditor.types'

export type DocumentEditorStateHookProps = {
  documentIID: string
  tabIndex: number
  selectedRevisionId?: number
  onInitDraftMarkdownEditor: () => void
}

export function useDocumentEditorStateNew(props: DocumentEditorStateHookProps) {
  const { documentIID } = props

  const wd = useWorkingDocumentsContext()

  const [document, setDocument] = useState<Document>(() => {
    const _state = wd.getDocumentEditorState(documentIID)
    if (!_state || !_state.document) {
      throw new Error('document should exist')
    }
    return _state.document
  })
  const [revision, setRevision] = useState<DocumentRevision>(() => {
    const _state = wd.getDocumentEditorState(documentIID)
    if (!_state || !_state.document || !_state.document.revisions || !_state.document.revisions[0]) {
      throw new Error('revision should exist')
    }
    return _state.document.revisions[0]
  })

  const [isEditing, setIsEditing] = useState(false)
  const stateRef = useRef<IDocumentEditorState>({} as IDocumentEditorState)

  useEffect(() => {
    const _state = wd.getDocumentEditorState(documentIID)
    if (!_state) {
      return
    }
    stateRef.current = _state
    const _isClosing = _state.isClosing
    if (_isClosing) return
    const _isEditing = _state.isEditing
    const _revisionId = _state.revisionId
    setIsEditing(_isEditing)

    if (_isEditing) {
      let draftDocument = _state.draftDocument
      let draftRevision = _state.draftRevision
      if (!draftDocument || !draftRevision) {
        const { newDraftDocument, newDraftRevision } = initializeDraft(_state.document)
        draftDocument = newDraftDocument
        draftRevision = newDraftRevision
        wd.updateDocumentEditorState(documentIID, {
          draftDocument,
          draftRevision,
          revisionId: 0,
          canSave: false,
        })
      }
      setSavedDocument(document.documentId ? _state.document : undefined)
      setSavedRevision(_state.document.getDraftRevision() ?? _state.document.getLastArchivedRevision())
      setDocument(draftDocument)
      setRevision(draftRevision)
      if (isPersonalDoxMimetype(draftRevision.mimetype) === true) {
        draftMarkdownContentRef.current = draftRevision.content as string
        draftMarkdownChecksumRef.current = draftRevision.checksum
      }
      onInitDraftMarkdownEditor()
    } else {
      const _savedDocument = _state.document
      const _savedRevision =
        _state.document.getRevision(_revisionId) ?? document.getLastArchivedRevision() ?? document.getDraftRevision()
      if (!_savedRevision) {
        throw new Error('missing revision')
      }
      wd.updateDocumentEditorState(documentIID, {
        draftDocument: undefined,
        draftRevision: undefined,
        revisionId: _savedRevision.revisionId,
        canSave: false,
      })
      setDocument(_savedDocument)
      setRevision(_savedRevision)
      setCanArchive(!!_savedDocument.draftRevisionId)
    }
  }, [documentIID, wd.workingDocuments])

  function initializeDraft(savedDocument: Document) {
    const _savedRevision = savedDocument.getDraftRevision() ?? savedDocument.getLastArchivedRevision()
    if (!_savedRevision) {
      throw new Error('should have almost one revision')
    }
    const _document = Document.clone(savedDocument)
    const _revision = DocumentRevision.clone(_savedRevision)
    if (!_document.draftRevisionId) {
      _revision.revisionId = 0
      _revision.archivedAt = undefined
      _revision.updatedAt = new Date(0)
      _revision.createdAt = new Date(0)
    }

    return {
      newDraftDocument: _document,
      newDraftRevision: _revision,
    }
  }

  /* ---------------------------------------------------------------------------
  Gestone del caching
  --------------------------------------------------------------------------- */
  const { tabIndex } = props
  const { t } = useTranslation()

  const [isTransmitting, setIsTransmitting] = useState(false)
  const [progress, setProgress] = useState(0)
  const revisionsLoading = useRef(new Set<string>())
  const documentCacheContext = useDocumentCacheContext()
  const [cachedDocument, setCachedDocument] = useState<Document | undefined>(
    document ? documentCacheContext?.documentMap[document.documentId] : undefined
  )

  useEffect(() => {
    const documentId = document.documentId

    let _cachedDocument = cachedDocument
    if (cachedDocument?.documentId !== documentId) {
      _cachedDocument = documentCacheContext.documentMap[documentId]
      setCachedDocument(_cachedDocument)
    }

    if (isSameDocument(document, _cachedDocument)) {
      return
    }

    const loadDocument = async (abortSignal: AbortSignal, documentId: number) => {
      try {
        setIsTransmitting(true)
        const _cachedDocument = await dalDocument.getDocument(abortSignal, documentId)
        documentCacheContext.addDocument(_cachedDocument)
        setCachedDocument(_cachedDocument)
        // props.onEditedDocumentAssignedDoxChanged(doc.doxIds)   !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        const _savedDocument = Document.clone(_cachedDocument)
        setDocument(_savedDocument)
        wd.updateDocumentEditorState(document.iid, { document: _savedDocument })
      } catch (err) {
        Utils.enqueueSnackbarError2(err, t)
      } finally {
        setIsTransmitting(false)
      }
    }

    if (documentId !== 0) {
      if (_cachedDocument) {
        const _savedDocument = Document.clone(_cachedDocument)
        setDocument(_savedDocument)
        setIsTransmitting(false)
      } else {
        const abortController = new AbortController()
        loadDocument(abortController.signal, documentId)

        return () => {
          abortController.abort()
        }
      }
    } else {
      setIsTransmitting(false)
    }
  }, [document])

  function isSameDocument(doc1: Document, doc2?: Document) {
    if (!doc2) return false
    if (doc1.iid !== doc2.iid) return false
    if (doc1.updatedAt.getTime() !== doc2.updatedAt.getTime()) return false
    if (doc1.createdAt.getTime() !== doc2.createdAt.getTime()) return false
    if (doc1.revisions.length !== doc2.revisions.length) return false
    return true
  }

  const fetchRevisionContent = useCallback(
    async (abortSignel: AbortSignal) => {
      if (!revision || !revision.mimetype) return // not loaded
      if (!revision.documentId) return // is new
      if (revision.content) return // content already fetched
      if (!cachedDocument) return // DocumentCacheContext crash if it tries to load revision without document
      if (cachedDocument.documentId !== document.documentId) return

      const documentId = revision.documentId
      const revisionId = revision.revisionId ? revision.revisionId : document.lastArchivedRevisionId
      const loadingId = documentId + '-' + revisionId
      const cachedRevision = cachedDocument?.getRevision(revisionId)
      const cachedContent = cachedRevision?.content
      const cachedChecksum = cachedRevision?.checksum

      if (!cachedRevision) {
        return Promise.reject(new Error('Cached revision not founded'))
      }

      const manageContent = (content: DocumentRevisionContentType, checksum: string) => {
        const _revision = DocumentRevision.clone(revision)
        _revision.content = content
        _revision.checksum = checksum
        setRevision(_revision)
        if (isPersonalDoxMimetype(_revision.mimetype) === true) {
          draftMarkdownContentRef.current = content as string
          draftMarkdownChecksumRef.current = checksum
          onInitDraftMarkdownEditor()
        }
        // update working document state
        const _state = stateRef.current
        const _newState: Partial<IDocumentEditorState> = {}
        _newState.document = Document.clone(_state.document)
        _newState.document.replaceRevision(_revision)
        if (isEditing && _state.draftDocument && _state.draftRevision) {
          _newState.draftDocument = Document.clone(_state.draftDocument)
          _newState.draftRevision = DocumentRevision.clone(_revision)
          _newState.draftDocument.replaceRevision(_newState.draftRevision)
        }
        wd.updateDocumentEditorState(document.iid, _newState)
      }

      try {
        setIsTransmitting(true)
        if (cachedRevision && cachedContent && cachedChecksum) {
          manageContent(cachedContent, cachedChecksum)
        } else if (isPersonalDoxMimetype(revision.mimetype) === true) {
          const _revision = await dalDocument.getDocumentRevision(abortSignel, documentId, revisionId)
          const checksum = Utils.getTextSHA256Checksum(_revision.content as string)
          if (checksum !== cachedRevision.checksum) {
            return Promise.reject(new Error('Checksum mismatch'))
          }
          documentCacheContext.setRevisionContent(documentId, revisionId, _revision.content)
          manageContent(_revision.content, checksum)
        } else if (!revisionsLoading.current.has(loadingId)) {
          const arrayBuffer = await dalDocument.getDocumentRevisionContent(
            abortSignel,
            documentId,
            revisionId,
            true,
            setProgress
          )
          const checksum = Utils.getBinarySHA256Checksum(arrayBuffer)
          if (checksum !== cachedRevision.checksum) {
            return Promise.reject(new Error('Checksum mismatch'))
          }
          documentCacheContext.setRevisionContent(revision.documentId, revisionId, arrayBuffer)
          manageContent(arrayBuffer, checksum)
        }
      } catch (err) {
        Utils.enqueueSnackbarError2(err, t, abortSignel.aborted)
      } finally {
        setIsTransmitting(false)
        setProgress(0)
      }
    },
    [documentCacheContext, cachedDocument, document, revision, t]
  )

  useEffect(() => {
    if (tabIndex !== 0) {
      return
    }
    const loadContentAbortController = new AbortController()
    fetchRevisionContent(loadContentAbortController.signal)
  }, [tabIndex, fetchRevisionContent])

  function updateCacheContent(documentIID: string) {
    const documentEditorState = wd.getDocumentEditorState(documentIID)
    if (!documentEditorState) return

    const _savedDocument = documentEditorState.document
    const _cachedDocument = Document.clone(_savedDocument)
    documentCacheContext.addDocument(_cachedDocument)
    setCachedDocument(_cachedDocument)

    const revisionId = documentEditorState.revisionId
    const savedRevision = _savedDocument.revisions.find((p) => p.revisionId === revisionId)
    if (!savedRevision) return

    const _cachedRevision = DocumentRevision.clone(savedRevision)
    documentCacheContext.setRevisionContent(
      _cachedRevision.documentId,
      _cachedRevision.revisionId,
      _cachedRevision.content
    )
    setDocument(_savedDocument)
    setRevision(savedRevision)
  }

  /* ---------------------------------------------------------------------------
  Gestone della bozza
  --------------------------------------------------------------------------- */

  const { onInitDraftMarkdownEditor } = props

  const [savedDocument, setSavedDocument] = useState(document.documentId ? document : undefined)
  const [savedRevision, setSavedRevision] = useState(revision.revisionId ? revision : undefined)

  const [areDraftChanges, setAreDraftChanges] = useState(false)
  const [areDraftErrors, setAreDraftErrors] = useState(false)
  const [canSave, setCanSave] = useState(false)
  const [canArchive, setCanArchive] = useState(false)

  const draftMarkdownContentRef = useRef('') // contains markdown changes from last saved revision
  const draftMarkdownChecksumRef = useRef('')

  const evaluateDraftChanges = useCallback(() => {
    const draftDocument = document
    const draftRevision = revision

    if (!isEditing || !draftDocument || !draftRevision) {
      return {
        areDraftChanges: false,
        areDraftErrors: false,
        canSave: false,
        canArchive: false,
      }
    }

    // evaluate document info changes
    const areDraftInfoChanged = savedRevision
      ? savedRevision?.name !== draftRevision.name ||
        savedRevision?.editedAt !== draftRevision.editedAt ||
        savedRevision?.creatorIdentity !== draftRevision.creatorIdentity ||
        savedDocument?.anonymousAt !== draftDocument.anonymousAt
      : true

    // evaluate document info errors
    const documentInfoFormErrors = validateDocumentInfoForm(draftDocument, draftRevision)
    const areDraftInfoValid = Object.values(documentInfoFormErrors).filter((e) => !!e).length === 0

    // evaluate revision content changes
    const isContentChanged = savedRevision
      ? isPersonalDoxMimetype(draftRevision.mimetype) === true
        ? savedRevision.checksum !== draftMarkdownChecksumRef.current
        : savedRevision.checksum !== draftRevision.checksum
      : true
    const contentLen = DocumentRevision.getContentLength(
      isPersonalDoxMimetype(draftRevision.mimetype) === true
        ? (draftMarkdownContentRef.current as string)
        : draftRevision.content
    )

    // evaluate revision content errors
    const isContentValid = contentLen !== 0

    const _areDraftChanges = areDraftInfoChanged || isContentChanged
    const _areDraftErrors = !(areDraftInfoValid && isContentValid)
    const _canSave = (areDraftInfoChanged || isContentChanged) && areDraftInfoValid && isContentValid
    const _canArchive = (document && !!document.draftRevisionId && !areDraftInfoChanged && !isContentChanged) ?? false

    setAreDraftChanges(_areDraftChanges)
    setAreDraftErrors(_areDraftErrors)
    setCanSave(_canSave)
    setCanArchive(_canArchive)
    return {
      areDraftChanges: _areDraftChanges,
      areDraftErrors: _areDraftErrors,
      canSave: _canSave,
      canArchive: _canArchive,
    }
  }, [savedDocument, savedRevision, document, revision])

  useEffect(() => {
    if (isEditing) {
      evaluateDraftChanges()
    }
  }, [isEditing, evaluateDraftChanges])

  function restoreDraftContent() {
    if (savedDocument && savedRevision && revision) {
      setRevision({
        ...revision,
        content: savedRevision.content,
        checksum: savedRevision.checksum,
      })
      initializeDraft(savedDocument)
    }
  }

  function pushDocumentEditorState(isClosing: boolean, sectionIndex: number, canSave: boolean, forceEditing?: boolean) {
    if (isEditing) {
      const draftDocument = document
      const draftRevision = revision
      if (draftRevision) {
        // if needed inject markdown content into draft revision
        if (isPersonalDoxMimetype(draftRevision.mimetype) === true) {
          draftRevision.content = draftMarkdownContentRef.current
          draftRevision.checksum = draftMarkdownChecksumRef.current
        }

        if (draftDocument) {
          const draftRevisionIndex = draftDocument.revisions.findIndex((p) => !p.archivedAt)
          if (draftRevisionIndex !== -1) {
            draftDocument.revisions[draftRevisionIndex] = draftRevision
          }
        }
      }

      wd.updateDocumentEditorState(documentIID, {
        isEditing: forceEditing ?? isEditing,
        // document,
        draftDocument: draftDocument,
        draftRevision: draftRevision,
        markdownContent: draftMarkdownContentRef.current,
        markdownChecksum: draftMarkdownChecksumRef.current,
        revisionId: revision.revisionId,
        sectionIndex,
        canSave,
        isClosing,
      })
    }
  }

  function pullDocumentEditorState(documentIID: string) {
    const documentEditorState = wd.getDocumentEditorState(documentIID)
    if (!documentEditorState) return
    setSavedDocument(documentEditorState.draftDocument)
    setSavedRevision(documentEditorState.draftRevision)
    draftMarkdownContentRef.current = documentEditorState.markdownContent
    draftMarkdownChecksumRef.current = documentEditorState.markdownChecksum
  }

  return {
    document,
    revision,
    isTransmitting,
    progress,
    updateCacheContent,
    isEditing,
    draftMarkdownContentRef,
    draftMarkdownChecksumRef,
    areDraftChanges,
    areDraftErrors,
    canSave,
    canArchive,
    restoreDraftContent,
    setDocument,
    setRevision,
    evaluateDraftChanges,
    pushDocumentEditorState,
    pullDocumentEditorState,
  }
}
