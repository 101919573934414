import { DashboardItemType, DashboardItemType_all } from '../shared/Constants'
import { DashboardItemContextNote } from './DashboardItemContextNote'

export interface IDashboardItemJson {
  type: number
  widthUnits: number
  heightUnits: number
  context?: string
}

export class DashboardItem {
  type: DashboardItemType_all = DashboardItemType.none
  widthUnits: number = 1
  heightUnits: number = 1
  contextNote?: DashboardItemContextNote

  constructor() {}

  public static serialize(obj: DashboardItem): IDashboardItemJson {
    const res = {
      type: obj.type,
      widthUnits: obj.widthUnits,
      heightUnits: obj.heightUnits,
    } as IDashboardItemJson

    switch (obj.type) {
      case DashboardItemType.note:
        res.context = obj.contextNote ? JSON.stringify(obj.contextNote) : undefined
        break
    }

    return res
  }

  public static deserialize(json: IDashboardItemJson): DashboardItem {
    const res = new DashboardItem()
    res.type = json.type
    res.widthUnits = json.widthUnits
    res.heightUnits = json.heightUnits
    try {
      if (json.context) {
        const contextJson = JSON.parse(json.context)
        switch (json.type) {
          case DashboardItemType.note:
            res.contextNote = contextJson ? DashboardItemContextNote.deserialize(contextJson) : undefined
            break
        }
      }
    } catch (err) {
      res.contextNote = undefined
    }

    return res
  }

  public static serializeArray(objs: DashboardItem[]): IDashboardItemJson[] {
    const jsons = objs.map((p) => {
      return DashboardItem.serialize(p)!
    })
    return jsons
  }

  public static deserializeArray(json: IDashboardItemJson[]): DashboardItem[] {
    const res = json.map((p) => {
      return DashboardItem.deserialize(p)!
    })
    return res
  }
}
