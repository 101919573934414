import { Box, BoxProps, Divider } from '@mui/material'
import Notification from './../notification/Notification'
import { useNotificationsContext } from '../../contexts/NotificationsContext'
import { useEffect, useRef } from 'react'
import { LoadMore } from '../loadMore/LoadMore'
import { INotificationsProps } from './Notifications.types'

export function Notifications(props: INotificationsProps & BoxProps): JSX.Element {
  const {
    filter,
    onClickAvatar,
    onCloseDialog,
    onShowDoxDetails,
    onShowDocumentDetails,
    onShowContractVersion,
    ...rest
  } = props
  const notificationsContext = useNotificationsContext()
  const notifications =
    filter === 'archived' ? notificationsContext.archivedNotifications : notificationsContext.notifications
  const moreNotifications =
    filter === 'archived' ? notificationsContext.moreArchivedNotifications : notificationsContext.moreNotifications
  const loadNotifications =
    filter === 'archived' ? notificationsContext.loadArchivedNotifications : notificationsContext.loadNotifications
  const loadMoreRef = useRef<HTMLDivElement | null>(null)
  const observerRef = useRef<IntersectionObserver | null>(null)

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const visible = entries.find((entry) => entry.intersectionRatio > 0)
      if (!visible) {
        return
      }
      loadNotifications()
    }, {})
    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current)
    }
    observerRef.current = observer
    return () => {
      observer.disconnect()
    }
  }, [loadNotifications])

  function onLoadTrigger(el: HTMLDivElement | null) {
    loadMoreRef.current = el || null
    if (observerRef.current && el) {
      observerRef.current.observe(el)
    }
  }

  return (
    <Box {...rest}>
      {notifications.map((n) => (
        <Box key={n.id} id={'notification-' + filter + '-' + n.id}>
          <Notification
            notification={n}
            onClickAvatar={onClickAvatar}
            onCloseDialog={() => onCloseDialog && onCloseDialog()}
            onShowDoxDetails={onShowDoxDetails}
            onShowDocumentDetails={onShowDocumentDetails}
            onShowContractVersion={onShowContractVersion}
          />
          <Divider />
        </Box>
      ))}
      <LoadMore noMoreToLoad={!moreNotifications} ref={onLoadTrigger} />
    </Box>
  )
}
