import { PageContainer, PageContent } from '../../components/pageContainer/PageContainer'
import CommandBar from '../../components/commandBar/CommandBar'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'
import { Paper, Stack } from '@mui/material'
import { useAuthContext } from '../../contexts/AuthContext'
import * as dalAccount from '../../dal/DalAccount'
import { Account } from '../../models/Account'
import { useLocation, useNavigate } from 'react-router'
import { AccountType, ProfileType, isCollaborator, isConsumer, isOperator, isStructure } from '../../shared/Constants'
import { AccountEditorIdentityForm } from '../../components/accountEditorIdentity/AccountEditorIdentityForm'
import { AccountEditorPresentationForm } from '../../components/accountEditorPresentation/AccountEditorPresentationForm'
import { AccountEditorResidenceForm } from '../../components/accountEditorResidence/AccountEditorResidenceForm'
import { AccountEditorBillingForm } from '../../components/accountEditorBilling/AccountEditorBillingForm'
import { AccountEditorContactsForm } from '../../components/accountEditorContacts/AccountEditorContactsForm'
import { FieldAcl, useAccountEditorContext } from '../../contexts/AccountEditorContext'
import { useEffect, useMemo, useState } from 'react'
import { Utils } from '../../shared/Utils'
import { IAccountEditorPageProps } from './AccountEditorPage.types'
import { ICommand } from '../../components/commandBar/CommandBar.types'
import { AddIco, DeleteIco, OperatorIco } from '../../components/icons'
import { ITotpCheckDialogData } from '../../dialogs/totpCheckDialog/TotpCheckDialog.types'
import TotpCheckDialog from '../../dialogs/totpCheckDialog/TotpCheckDialog'
import { useSnackbar } from 'notistack'
import { IWordCheckDialogData } from '../../dialogs/wordCheckDialog/WordCheckDialog.types'
import WordCheckDialog from '../../dialogs/wordCheckDialog/WordCheckDialog'
import AccountLoadingIndicator from '../../components/loadingIndicators/AccountLoadingIndicator'
import { IAccountSelectorDialogData } from '../../dialogs/accountSelectorDialog/AccountSelectorDialog.types'
import AccountSelectorDialog from '../../dialogs/accountSelectorDialog/AccountSelectorDialog'
import { StyledDialog } from '../../dialogs/styledDialog/StyledDialog'
import { IAccountAttachProps } from '../../components/accountAttach/AccountAttach.types'
import { AccountAttach } from '../../components/accountAttach/AccountAttach'
import { ViewContent } from '../../components/viewContent/ViewContent'
import { ViewHeader } from '../../components/viewHeader/ViewHeader'

export default function AccountEditorPage(): JSX.Element {
  const location = useLocation()
  const state = location.state as IAccountEditorPageProps
  const detailsOwnerProfileId = state.detailsOwnerProfileId
  const profileId = state.profileId

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const authContext = useAuthContext()
  const accountEditorContext = useAccountEditorContext()

  const [account, setAccount] = useState<Account>()

  // dialogs
  const [totpCheckDialogData, setTotpCheckDialogData] = useState<ITotpCheckDialogData>()
  const [totpCheckDialogOpen, setTotpCheckDialogOpen] = useState(false)
  const [wordCheckDialogData, setWordCheckDialogData] = useState<IWordCheckDialogData>()
  const [wordCheckDialogOpen, setWordCheckDialogOpen] = useState(false)
  const [accountsSelectorDialogData, setAccountsSelectorDialogData] = useState<IAccountSelectorDialogData>()
  const [accountsSelectorDialogOpen, setAccountsSelectorDialogOpen] = useState(false)
  const [accountAttachDialogData, setAccountAttachDialogData] = useState<IAccountAttachProps>()
  const [accountAttachDialogOpen, setAccountAttachDialogOpen] = useState(false)

  useEffect(() => {
    async function LoadAccountDetails(abortSignal: AbortSignal) {
      try {
        setIsLoading(true)
        const data = await dalAccount.getAccountFromProfileId(abortSignal, profileId, true, false)
        setAccount(data)
      } catch (err) {
        Utils.enqueueSnackbarError2(err, t)
      } finally {
        setIsLoading(false)
      }
    }

    const abortController = new AbortController()
    if (profileId && profileId !== authContext.loggedProfileId) {
      LoadAccountDetails(abortController.signal)
    } else {
      setAccount(authContext.loggedAccount)
    }

    return () => {
      abortController.abort()
    }
  }, [profileId])

  const consumer = account?.profile ? isConsumer(account.profile.type) : false
  const opeartor = account?.profile ? isOperator(account.profile.type) : false
  const structure = account?.profile ? isStructure(account.profile.type) : false

  useEffect(() => {
    if (!account) return
    accountEditorContext.setFieldsAcl(authContext.loggedAccount, account)
  }, [account])

  function checkFormVisibility(fieldsAcl: FieldAcl[]) {
    return fieldsAcl.some((p) => p === 'editable')
  }

  const isPresentationFormVisible = checkFormVisibility([accountEditorContext.fieldsAcl.avatar])

  const isIdentityFormVisible = checkFormVisibility([
    accountEditorContext.fieldsAcl.registrationEmail,
    accountEditorContext.fieldsAcl.lastname,
    accountEditorContext.fieldsAcl.name,
    accountEditorContext.fieldsAcl.gender,
    accountEditorContext.fieldsAcl.birthdate,
    accountEditorContext.fieldsAcl.birthplace,
    accountEditorContext.fieldsAcl.fiscalCode,
    accountEditorContext.fieldsAcl.vatNumber,
  ])

  const isBillingFormVisible = checkFormVisibility([
    accountEditorContext.fieldsAcl.billingSdiCode,
    accountEditorContext.fieldsAcl.billingPec,
    accountEditorContext.fieldsAcl.billingCountry,
    accountEditorContext.fieldsAcl.billingCity,
    accountEditorContext.fieldsAcl.billingProvince,
    accountEditorContext.fieldsAcl.billingStreet,
    accountEditorContext.fieldsAcl.billingStreetNumber,
    accountEditorContext.fieldsAcl.billingZip,
  ])

  const isResidenceFormVisible = checkFormVisibility([
    accountEditorContext.fieldsAcl.residenceCountry,
    accountEditorContext.fieldsAcl.residenceProvince,
    accountEditorContext.fieldsAcl.residenceCity,
    accountEditorContext.fieldsAcl.residenceStreet,
    accountEditorContext.fieldsAcl.residenceStreetNumber,
    accountEditorContext.fieldsAcl.residenceZip,
  ])

  const isContactsFormVisible = checkFormVisibility([
    accountEditorContext.fieldsAcl.phone,
    accountEditorContext.fieldsAcl.mobilePhone,
    accountEditorContext.fieldsAcl.email,
  ])

  function pageTitle(): string {
    if (isLoading) {
      return t(msgIds.MSG_LOADING_IN_PROGRESS)
    } else if (consumer) {
      if (authContext.loggedProfileId === account?.profile?.profileId) {
        return t(msgIds.MSG_PROFILE_EDITOR_CONSUMER_TITLE)
      } else {
        return t(msgIds.MSG_PROFILE_EDITOR_ABOUT_CONSUMER_TITLE, {
          identity: account?.getIdentityInverse(),
        })
      }
    } else if (structure) {
      return t(msgIds.MSG_PROFILE_EDITOR_STRUCTURE_TITLE)
    } else if (isCollaborator(account?.profile?.type)) {
      if (authContext.loggedProfileId === account?.profile?.profileId) {
        return t(msgIds.MSG_PROFILE_EDITOR_COLLABORATOR_TITLE)
      } else {
        return t(msgIds.MSG_PROFILE_EDITOR_ABOUT_COLLABORATOR_TITLE, {
          identity: account?.getIdentityInverse(),
        })
      }
    } else if (account?.profile?.type === ProfileType.operatorAdmin) {
      return t(msgIds.MSG_PROFILE_EDITOR_BUSINESS_TITLE)
    }
    return t(msgIds.MSG_PROFILE_EDITOR_CONSUMER_TITLE)
  }

  const commandBarCommands = useMemo(() => {
    const commands: ICommand[] = []
    if (!account || isLoading) {
      return commands
    }
    if (
      authContext.loggedProfileId === account?.profile?.profileId ||
      (authContext.loggedProfileType === ProfileType.operatorAdmin && isCollaborator(account?.profile?.type))
    ) {
      commands.push({
        commandText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_ADD_OPERATOR_PROFILE),
        tooltipText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_ADD_OPERATOR_PROFILE),
        icon: <OperatorIco />,
        onClick: addOperatorProfile,
      })
    }
    if (
      isOperator(authContext.loggedProfileType) &&
      isConsumer(account?.profile?.type) &&
      account?.user?.accountType === AccountType.placeholderUser
    ) {
      commands.push({
        commandText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_ATTACH_TO_REAL_USER),
        tooltipText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_ATTACH_TO_REAL_USER),
        icon: <AddIco />,
        onClick: attachToRealUser,
      })
    }
    if (
      authContext.loggedProfileId === account?.profile?.profileId ||
      (authContext.loggedProfileType === ProfileType.operatorAdmin && isCollaborator(account?.profile?.type)) ||
      (isOperator(authContext.loggedProfileType) &&
        isConsumer(account?.profile?.type) &&
        account?.user?.accountType === AccountType.placeholderUser)
    ) {
      commands.push({
        commandText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_CLOSE_PROFILE),
        tooltipText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_CLOSE_PROFILE),
        icon: <DeleteIco />,
        onClick: closeProfile,
      })
    }
    return commands
  }, [t, account, isLoading, authContext.loggedProfileType])

  function addOperatorProfile() {
    // TODO addOperatorProfile
  }

  function attachToRealUser() {
    setAccountsSelectorDialogOpen(true)
    setAccountsSelectorDialogData({
      searchOptions: 'customers',
      goToAccountPageOption: false,
      showPrivacyPolicy: false,
      onResult: async (result) => {
        setAccountsSelectorDialogOpen(false)
        if (result.userChoice !== 'yes') return
        checkFieldsToMerge(result.account)
      },
    })
  }

  function checkFieldsToMerge(accountToAttach: Account) {
    if (!account) return

    setAccountAttachDialogOpen(true)
    setAccountAttachDialogData({
      customerAccount: accountToAttach,
      placeholderAccount: account,
      onClickExit: () => {},
    })
  }

  function closeProfile() {
    if (!account?.profile?.profileId) return

    const operatorClosePlaceholder = account?.user?.accountType === AccountType.placeholderUser
    const operatorAdminCloseCollaborator =
      authContext.loggedProfileType === ProfileType.operatorAdmin &&
      authContext.loggedProfileId !== account?.profile?.profileId

    let msg = ''
    if (operatorClosePlaceholder) {
      msg = t(msgIds.MSG_PROFILE_EDITOR_OPERATOR_CLOSE_PLACEHOLDER_ACCOUNT)
    } else if (operatorAdminCloseCollaborator) {
      msg = t(msgIds.MSG_PROFILE_EDITOR_OPERATOR_CLOSE_COLLABORATOR_PROFILE)
    } else {
      msg = t(msgIds.MSG_PROFILE_EDITOR_CLOSE_DIALOG_ADVERTISING)
    }

    if (authContext.loggedAccount?.user?.has2fa) {
      setTotpCheckDialogOpen(true)
      setTotpCheckDialogData({
        text: msg,
        onClose: async (result) => {
          setTotpCheckDialogOpen(false)
          if (result.userChoice !== 'yes') return
          await doCloseProfile()
        },
      })
    } else {
      setWordCheckDialogOpen(true)
      setWordCheckDialogData({
        text: msg,
        confirmWord: t(msgIds.MSG_WORD_CONFIRM_CLOSE),
        onClose: async (result) => {
          setWordCheckDialogOpen(false)
          if (result.userChoice !== 'yes') return
          await doCloseProfile()
        },
      })
    }
  }

  async function doCloseProfile() {
    if (!account?.profile?.profileId) return

    try {
      setIsLoading(true)
      const abortController = new AbortController()
      await dalAccount.deleteProfile(abortController.signal, account?.profile?.profileId)
      enqueueSnackbar(t(msgIds.MSG_OPERATION_EXECUTED_SUCCESSFULLY), { variant: 'success' })
      navigate(`/notice_board`, {})
    } catch (error) {
      Utils.enqueueSnackbarError2(error, t)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <PageContainer>
      <CommandBar style={{ minHeight: 'auto' }} title={pageTitle()} commands={commandBarCommands} />
      {totpCheckDialogData && <TotpCheckDialog {...totpCheckDialogData} isOpen={totpCheckDialogOpen} />}
      {wordCheckDialogData && <WordCheckDialog {...wordCheckDialogData} isOpen={wordCheckDialogOpen} />}
      {accountsSelectorDialogData && (
        <AccountSelectorDialog
          {...accountsSelectorDialogData}
          isOpen={accountsSelectorDialogOpen}
          onClose={() => setAccountsSelectorDialogOpen(false)}
        />
      )}
      {accountAttachDialogData && (
        <StyledDialog
          maxWidth="lg"
          open={accountAttachDialogOpen}
          onClose={() => setAccountAttachDialogOpen(false)}
          minHeight={100}
        >
          <ViewHeader
            title={t(msgIds.MSG_ACCOUNT_ATTACH_DIALOG_TITLE)}
            exitButtonVisible={true}
            onClickExit={() => {
              setAccountAttachDialogOpen(false)
            }}
          />
          <ViewContent>
            <AccountAttach {...accountAttachDialogData} onClickExit={() => setAccountAttachDialogOpen(false)} />
          </ViewContent>
        </StyledDialog>
      )}

      {isLoading ? (
        <AccountLoadingIndicator account={account} isLoading={isLoading} />
      ) : (
        <PageContent>
          {account && (
            <Stack spacing={8} width="100%" maxWidth="md" alignItems="stretch">
              {isPresentationFormVisible && (
                <Paper>
                  <AccountEditorPresentationForm account={account} />
                </Paper>
              )}
              {isIdentityFormVisible && (
                <Paper>
                  <AccountEditorIdentityForm account={account} />
                </Paper>
              )}
              {isBillingFormVisible && (
                <Paper>
                  <AccountEditorBillingForm account={account} />
                </Paper>
              )}
              {isResidenceFormVisible && (
                <Paper>
                  <AccountEditorResidenceForm account={account} detailsOwnerProfileId={detailsOwnerProfileId} />
                </Paper>
              )}
              {isContactsFormVisible && (
                <Paper>
                  <AccountEditorContactsForm account={account} detailsOwnerProfileId={detailsOwnerProfileId} />
                </Paper>
              )}
            </Stack>
          )}
        </PageContent>
      )}
    </PageContainer>
  )
}
