import { Typography, useTheme } from '@mui/material'
import { Dox } from '../../models/Dox'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { Document } from '../../models/Document'
import { IElementTypeAndDescriptionProps } from './ElementTypeAndDescription.types'
import { Account } from '../../models/Account'

export function ElementTypeAndDescription(props: IElementTypeAndDescriptionProps): JSX.Element {
  const { element } = props
  const theme = useTheme()
  const { t } = useTranslation()

  let elementType: string
  let elementDescription: string | undefined
  if (element instanceof Dox) {
    elementType = t(msgIds.MSG_DOX)
    elementDescription = element.completeRoute || element.name
  } else if (element instanceof Document) {
    elementType = t(msgIds.MSG_DOCUMENT)
    elementDescription = element.getName()
  } else if (element instanceof Account) {
    elementType = t(msgIds.MSG_ACCOUNT_DETAILS_TITLE)
    elementDescription = element.getIdentity()
  } else {
    elementType = ''
    elementDescription = ''
  }

  return (
    <Typography marginLeft={1} title={elementDescription} className="ellipsis-2">
      <b>
        <span style={{ color: theme.palette.common.gray5 }}>{elementType}:</span> {elementDescription}
      </b>
    </Typography>
  )
}
