import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function LogoutIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 13,3 h -2 v 10 h 2 z M 17.829999,5.1699999 16.41,6.5899998 C 17.989999,7.8599998 18.999999,9.8099997 18.999999,12 18.999999,15.87 15.87,18.999999 12,18.999999 8.1299998,18.999999 4.9999999,15.87 4.9999999,12 c 0,-2.1900003 1.01,-4.1400002 2.5799999,-5.4200001 l -1.41,-1.41 C 4.2299998,6.8199999 3,9.2599998 3,12 c 0,4.969999 4.0299997,9 9,9 4.969999,0 9,-4.030001 9,-9 0,-2.7400002 -1.23,-5.1800001 -3.170001,-6.8300001 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
