import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function DeleteIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 9.5009766,2.9992659 8.4990234,3.9990217 H 5.0009766 V 6.0007308 H 18.999756 V 3.9990217 H 15.499512 L 14.499756,2.9992659 Z M 6.0007324,7.0004866 V 18.999754 a 2.0058949,2.0058949 0 0 0 1.9995117,1.999512 H 16.000488 A 2.0058949,2.0058949 0 0 0 18,18.999754 V 7.0004866 Z M 8.0002441,8.9999983 H 16.000488 V 18.999754 H 8.0002441 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
