import {
  Box,
  Divider,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import DoxesNav from '../../components/doxesNav/DoxesNav'
import { AutorenewIco, DoxManageIco, PinIco } from '../../components/icons'
import { useEffect, useRef, useState } from 'react'
import { DoxSelectionMode } from '../../components/doxesNav/DoxesNav.types'
import FormControl from '@mui/material/FormControl'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'
import { IDoxesMenuProps } from './DoxesMenu.types'
import { useAuthContext } from '../../contexts/AuthContext'
import { useLayout } from '../../hooks/Layout'

export default function DoxesMenu(props: IDoxesMenuProps): JSX.Element {
  const authContext = useAuthContext()
  const { isMobile } = useLayout()
  const { t } = useTranslation()
  const [doxSelectionMode, setDoxSelectionMode] = useState(DoxSelectionMode.single)
  const [isMouseOver, setIsMouseOver] = useState(false)
  const mouseOverTimeoutRef = useRef<any>(0)
  const [collapsed, setCollapsed] = useState(false)

  useEffect(() => {
    const c = !props.pin && !isMouseOver
    setCollapsed(c)
    props.onCollapsed(c)
  }, [props, props.pin, props.onCollapsed, isMouseOver])

  function onTogglePin() {
    // fix the glitch
    setIsMouseOver(true)
    props.onChangePin(!props.pin)
  }

  function onChangeDoxSelectionMode(event: SelectChangeEvent<DoxSelectionMode>) {
    setDoxSelectionMode(event.target.value as DoxSelectionMode)
  }

  function onMouseOver() {
    if (mouseOverTimeoutRef.current) {
      clearTimeout(mouseOverTimeoutRef.current)
    }
    mouseOverTimeoutRef.current = setTimeout(() => setIsMouseOver(true), 400)
  }

  function onMouseLeave() {
    if (mouseOverTimeoutRef.current) {
      clearTimeout(mouseOverTimeoutRef.current)
      mouseOverTimeoutRef.current = 0
    }
    setIsMouseOver(false)
  }

  return (
    <Stack gap={2} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} sx={{ maxHeight: '100%', height: '100%' }}>
      {!collapsed ? (
        <>
          <Stack component="div">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              pl={'10px'}
              pr={'10px'}
              pt={'10px'}
              pb={0}
            >
              <Typography variant="h6" component="h6">
                Dox
              </Typography>
              <Stack direction="row">
                {isMobile && (
                  <Tooltip title={t(msgIds.MSG_DOXES_MENU_RELOAD_BUTTON)}>
                    <IconButton size="small" color="inherit" onClick={props.onRequestReload}>
                      <AutorenewIco />
                    </IconButton>
                  </Tooltip>
                )}
                {props.isPinButtonVisible && (
                  <Tooltip title={t(msgIds.MSG_DOXES_MENU_PIN_BUTTON)}>
                    <IconButton
                      color="inherit"
                      size="small"
                      onClick={onTogglePin}
                      onMouseOver={(event) => event.stopPropagation()}
                    >
                      <PinIco sx={{ transform: !props.pin ? 'rotate(90deg)' : 'unset' }} />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            </Stack>
            <Divider sx={{ ml: '10px', mr: '10px', mt: '10px' }} />
            {!authContext.loggedAccount?.isGuest && (
              <FormControl variant="standard" sx={{ pl: '10px', pr: '10px' }}>
                <InputLabel id="dox-selection-mode-label" sx={{ m: '10px' }}>
                  {t(msgIds.MSG_DOXES_SELECTION_MODE_LABEL)}
                </InputLabel>
                <Select
                  labelId="dox-selection-mode-label"
                  value={doxSelectionMode}
                  onChange={onChangeDoxSelectionMode}
                  sx={{ pl: '0px', pr: '10px', pt: '10px', pb: '10px' }}
                >
                  <MenuItem value={DoxSelectionMode.single}>{t(msgIds.MSG_DOXES_SELECTION_MODE_SINGLE)}</MenuItem>
                  <MenuItem value={DoxSelectionMode.multiple}>{t(msgIds.MSG_DOXES_SELECTION_MODE_MULTIPLE)}</MenuItem>
                  <MenuItem value={DoxSelectionMode.hierarchical}>
                    {t(msgIds.MSG_DOXES_SELECTION_MODE_HIERARCHICAL)}
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          </Stack>
          <Box
            sx={{
              position: 'relative',
              overflowY: 'auto',
              flexGrow: 1,
              width: '100%',
              marginBottom: '0px',
            }}
          >
            <Box sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, width: '100%', height: '100%' }}>
              <DoxesNav
                selectionMode={doxSelectionMode}
                selection={props.selection}
                selectedDoxId={props.selectedDoxId}
                onChangeSelection={props.onChangeSelection}
                onDoxCommand={props.onDoxCommand}
              />
            </Box>
          </Box>
        </>
      ) : (
        <Tooltip title={t(msgIds.MSG_DOXES_MENU_SHOW_BUTTON)}>
          <IconButton
            color="inherit"
            size="small"
            onClick={() => props.onChangePin(true)}
            onMouseOver={(event) => event.stopPropagation()}
          >
            <DoxManageIco />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  )
}
