import { Button, Stack, Typography } from '@mui/material'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'

export type IPageStepperNavBarProps = {
  title: string
  backDisabled: boolean
  onBack: () => void
  nextDisabled: boolean
  onNext: () => void
}

export function PageStepperNavBar(props: IPageStepperNavBarProps): JSX.Element {
  const { title, backDisabled, onBack, nextDisabled, onNext } = props
  const { t } = useTranslation()
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Button disabled={backDisabled} onClick={onBack}>
        {t(msgIds.MSG_PREVIOUS_BUTTON_TITLE)}
      </Button>
      <Typography sx={{ flex: 1, textAlign: 'center' }}>{title}</Typography>
      <Button disabled={nextDisabled} onClick={onNext}>
        {t(msgIds.MSG_NEXT_BUTTON_TITLE)}
      </Button>
    </Stack>
  )
}
