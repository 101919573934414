import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function ChevronRightIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 8.5899999,16.590003 4.5800001,-4.59 -4.5800001,-4.5899997 1.41,-1.41 L 16,12.000003 l -6.0000001,6 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
