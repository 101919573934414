import { ContractType, ContractType_all } from '../shared/Constants'

export interface IHistoryContextContractJson {
  id: number
  type: ContractType_all
  public: boolean
  owner_profile_id?: number
  target_profile_type: number
  target_profile_type_label: string
}

export class HistoryContextContract {
  id: number = 0
  type: ContractType_all = ContractType.none
  public: boolean = false
  ownerProfileId?: number
  targetProfileType: number = 0
  targetProfileTypeLabel: string = ''

  constructor() {}

  public static serialize(obj: HistoryContextContract): IHistoryContextContractJson {
    return {
      id: obj.id,
      type: obj.type,
      public: obj.public,
      owner_profile_id: obj.ownerProfileId,
      target_profile_type: obj.targetProfileType,
      target_profile_type_label: obj.targetProfileTypeLabel,
    }
  }

  public static deserialize(json: IHistoryContextContractJson): HistoryContextContract {
    const res = new HistoryContextContract()
    res.id = json.id
    res.type = json.type
    res.public = json.public
    res.ownerProfileId = json.owner_profile_id
    res.targetProfileType = json.target_profile_type
    res.targetProfileTypeLabel = json.target_profile_type_label
    return res
  }
}
