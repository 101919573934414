import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { IPasswordStrengthIndicatorProps } from './PasswordStrengthIndicator.types'

const PasswordStrength = {
  None: 0,
  Unacceptable: 1,
  Weak: 2,
  Ok: 3,
  Strong: 4,
  Secure: 5,
}

export function PasswordStrengthIndicator(props: IPasswordStrengthIndicatorProps): JSX.Element {
  const [passwordStrengthColor, setPasswordStrengthColor] = useState<string | null>(null)

  useEffect(() => {
    if (props.password) {
      const passwordStrength = GetPasswordStrength(props.password)

      switch (passwordStrength) {
        case PasswordStrength.Unacceptable:
          setPasswordStrengthColor('#FE2712')
          break
        case PasswordStrength.Weak:
          setPasswordStrengthColor('#FD5308')
          break
        case PasswordStrength.Ok:
          setPasswordStrengthColor('#FABC02')
          break
        case PasswordStrength.Strong:
          setPasswordStrengthColor('#D0EA2B')
          break
        case PasswordStrength.Secure:
          setPasswordStrengthColor('#66B032')
          break
        default:
          setPasswordStrengthColor('#FFFFFF')
          break
      }
    } else {
      setPasswordStrengthColor('#DDDDDD')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.password])

  const generatePasswordScore = (password: string) => {
    if (password === null) return 0
    const lengthScore = getLengthScore(password)
    const lowerScore = getLowerScore(password)
    const upperScore = getUpperScore(password)
    const digitScore = getDigitScore(password)
    const symbolScore = getSymbolScore(password)
    return lengthScore + lowerScore + upperScore + digitScore + symbolScore
  }

  const getLengthScore = (password: string) => {
    return Math.min(10, password.length) * 6
  }

  const getLowerScore = (password: string) => {
    const rawScore = password.length - password.replace(/[a-z]/g, '').length
    return Math.min(2, rawScore) * 5
  }

  const getUpperScore = (password: string) => {
    const rawScore = password.length - password.replace(/[A-Z]/g, '').length
    return Math.min(2, rawScore) * 5
  }

  const getDigitScore = (password: string) => {
    const rawScore = password.length - password.replace(/[0-9]/g, '').length
    return Math.min(2, rawScore) * 5
  }

  const getSymbolScore = (password: string) => {
    const rawScore = password.replace(/[a-zA-Z0-9]/g, '').length
    return Math.min(2, rawScore) * 5
  }

  const GetPasswordStrength = (password: string) => {
    const score = generatePasswordScore(password)

    if (score < 50) return PasswordStrength.Unacceptable
    else if (score < 60) return PasswordStrength.Weak
    else if (score < 80) return PasswordStrength.Ok
    else if (score < 100) return PasswordStrength.Strong
    else return PasswordStrength.Secure
  }

  return <Box height={10} width={'100%'} sx={{ backgroundColor: passwordStrengthColor }}></Box>
}
