import { Button, Stack, Checkbox, FormControlLabel, useMediaQuery, useTheme } from '@mui/material'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'
import { DatePicker } from '@mui/x-date-pickers'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import ProfileSearchInput from '../profileSearchInput/ProfileSearchInput'
import { DocumentsQuery } from '../../models/DocumentsQuery'
import DialogTemplate from '../template/dialogTemplate/DialogTemplate'

export interface IDocumentsFiltersDialogProps {
  open: boolean
  onClose: () => void
  filter: DocumentsQuery
  onChangeFilter: (filter: DocumentsQuery) => void
}

export default function DocumentsFiltersDialog(props: IDocumentsFiltersDialogProps): JSX.Element {
  const { t } = useTranslation()
  const [filter, setFilter] = useState<DocumentsQuery>(props.filter)

  useEffect(() => {
    setFilter(props.filter)
  }, [props.filter])

  function onClose() {
    setFilter(props.filter)
    props.onClose()
  }
  function onSubmit() {
    props.onChangeFilter(filter)
  }

  return (
    <DialogTemplate
      isOpen={props.open}
      title={t(msgIds.MSG_DOCS_FILTER_DIALOG_TITLE)}
      isFullscreenForMobile={true}
      maxWidth="sm"
      mt={3}
      onClose={onClose}
      showActions={true}
      actions={[
        <Button key="1" onClick={onClose}>
          {t(msgIds.MSG_CANCEL)}
        </Button>,
        <Button key="2" onClick={onSubmit}>
          {t(msgIds.MSG_OK)}
        </Button>,
      ]}
    >
      {/* <DialogContent> */}
      <Stack direction="column" gap={2}>
        <Stack direction="row" gap={2} paddingTop={1} flexWrap="wrap">
          <DatePicker
            label={t(msgIds.MSG_DOCS_FILTER_EDITED_FROM_KEY)}
            defaultValue={filter?.from ? dayjs(filter.from) : null}
            onChange={(value) =>
              setFilter((f) => {
                return { ...f, from: value ? value.toDate() : undefined }
              })
            }
            maxDate={dayjs(new Date())}
          />
          <DatePicker
            label={t(msgIds.MSG_DOCS_FILTER_EDITED_TO_KEY)}
            defaultValue={filter?.to ? dayjs(filter.to) : null}
            onChange={(value) =>
              setFilter((f) => {
                return { ...f, to: value ? value.toDate() : undefined }
              })
            }
            maxDate={dayjs(new Date())}
          />
        </Stack>
        <ProfileSearchInput
          searchOption="structures"
          label={t(msgIds.MSG_DOCS_FILTER_STRUCTURE_OWNER_KEY)}
          selection={filter.owners || []}
          onChangeSelection={(accounts) => {
            setFilter((f) => {
              const f1 = { ...f }
              if (accounts.length === 0) {
                delete f1.owners
              } else {
                f1.owners = accounts
              }
              return f1
            })
          }}
        />
        <ProfileSearchInput
          searchOption="operators"
          label={t(msgIds.MSG_DOCS_FILTER_OPERATOR_AUTHOR_KEY)}
          selection={filter.authors || []}
          onChangeSelection={(accounts) => {
            setFilter((f) => {
              const f1 = { ...f }
              if (accounts.length === 0) {
                delete f1.authors
              } else {
                f1.authors = accounts
              }
              return f1
            })
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={!!filter.onlyDrafts}
              onChange={(_event, checked) =>
                setFilter((f) => {
                  if (checked) {
                    return { ...f, onlyDrafts: checked }
                  }
                  const filter = { ...f }
                  delete filter['onlyDrafts']
                  return filter
                })
              }
            />
          }
          label={t(msgIds.MSG_DOCS_FILTER_ONLY_DRAFTS_KEY)}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={!!filter.createdByMe}
              onChange={(_event, checked) =>
                setFilter((f) => {
                  if (checked) {
                    return { ...f, createdByMe: checked }
                  }
                  const filter = { ...f }
                  delete filter['createdByMe']
                  return filter
                })
              }
            />
          }
          label={t(msgIds.MSG_DOCS_FILTER_CREATED_BY_ME_KEY)}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={!!filter.showObsolete}
              onChange={(_event, checked) =>
                setFilter((f) => {
                  if (checked) {
                    return { ...f, showObsolete: checked }
                  }
                  const filter = { ...f }
                  delete filter['showObsolete']
                  return filter
                })
              }
            />
          }
          label={t(msgIds.MSG_DOCS_FILTER_SHOW_OBSOLETE_KEY)}
        />
      </Stack>
      {/* </DialogContent> */}
      {/* <DialogActions>
        <Button onClick={onClose}>{t(msgIds.MSG_CANCEL)}</Button>
        <Button onClick={onSubmit}>{t(msgIds.MSG_OK)}</Button>
      </DialogActions> */}
    </DialogTemplate>
  )
}
