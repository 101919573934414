import { Box, Grow, IconButton, Stack, StackProps, Tooltip, Typography, useTheme } from '@mui/material'
import { IDesktopDocumentsMenuProps } from './DesktopDocumentsMenu.types'
import { CloseIco, DocExternalIco, DocInternalIco } from '../icons'
import { ArchiveItemSourceType } from '../../shared/Constants'
import { Document } from '../../models/Document'

export function DesktopDocumentsMenu(props: IDesktopDocumentsMenuProps & StackProps): JSX.Element {
  const {
    mode,
    desktopDocuments,
    currentDesktopDocument,
    documentNames,
    onRemoveDesktopDocument: onRemoveDesktopDocument,
    onClickDesktopDocument: onClickDesktopDocument,
    width,
    ...rest
  } = props
  const theme = useTheme()
  const internalDocumentColor = theme.palette.common.content4
  const externalDocumentColor = theme.palette.common.content1
  const foregroundColor = theme.palette.common.gray11
  const documentIconPadding = 2
  const currentMarkWidth = 16
  const internalWidth = width
  const minWidth = internalWidth + currentMarkWidth

  function rowId(document: Document, index: number): string {
    if (document.documentId) {
      return `doc:${document.documentId}`
    } else {
      return `index:${index}`
    }
  }

  return (
    <Stack gap={0.5} {...rest} width={minWidth} sx={{ transition: theme.transitions.create('all') }}>
      {desktopDocuments.map((desktopDocument, index) => {
        const isInternal =
          desktopDocument.documentEditorState.document.sourceType === ArchiveItemSourceType.internalSource
        const isCurrent = desktopDocument === currentDesktopDocument
        const documentColor = isInternal ? internalDocumentColor : externalDocumentColor
        return (
          <Stack key={rowId(desktopDocument.documentEditorState.document, index)} direction="row" alignItems="stretch">
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                transition: theme.transitions.create('width'),
                width: internalWidth,
                position: 'relative',
                cursor: 'pointer',
                backgroundColor: documentColor,
              }}
              onClick={() => onClickDesktopDocument(desktopDocument)}
            >
              <Stack flexDirection="row" alignItems="center">
                {isInternal ? (
                  <DocInternalIco sx={{ padding: documentIconPadding, color: foregroundColor }} />
                ) : (
                  <DocExternalIco sx={{ padding: documentIconPadding, color: foregroundColor }} />
                )}
                <Grow in={mode === 'expanded'}>
                  <Tooltip title={documentNames[desktopDocument.documentIID]}>
                    <Typography color={foregroundColor} className="ellipsis-1" sx={{ flexShrink: 1, minHeight: 0 }}>
                      {documentNames[desktopDocument.documentIID]}
                    </Typography>
                  </Tooltip>
                </Grow>
              </Stack>
              <Grow in={mode === 'expanded'}>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation()
                    onRemoveDesktopDocument(desktopDocument)
                  }}
                >
                  <CloseIco sx={{ color: foregroundColor }} />
                </IconButton>
              </Grow>
            </Stack>
            <Box
              sx={{
                zIndex: 4,
                width: currentMarkWidth,
                backgroundColor: isCurrent ? documentColor : 'transparent',
                borderBottomRightRadius: 200,
                borderTopRightRadius: 200,
              }}
            ></Box>
          </Stack>
        )
      })}
    </Stack>
  )
}
