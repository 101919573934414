import { Box, Divider, IconButton, Stack } from '@mui/material'
import { ArrowLeftIco, ArrowRightIco, StopIco } from '../icons'
import { ITutorialActionBarProps, TutorialActionBarAction } from './TutorialActionBar.types'

export function TutorialActionBar(props: ITutorialActionBarProps): JSX.Element {
  const { disabledActions, onAction } = props
  function isActionDisabled(action: TutorialActionBarAction) {
    if (!disabledActions) {
      return false
    }
    return disabledActions.indexOf(action) !== -1
  }
  return (
    <Box>
      <Divider />
      <Stack p={1} direction="row" justifyContent="space-between">
        <IconButton disabled={isActionDisabled('previous')} onClick={() => onAction('previous')}>
          <ArrowLeftIco />
        </IconButton>
        <IconButton disabled={isActionDisabled('stop')} onClick={() => onAction('stop')}>
          <StopIco />
        </IconButton>
        <IconButton disabled={isActionDisabled('next')} onClick={() => onAction('next')}>
          <ArrowRightIco />
        </IconButton>
      </Stack>
    </Box>
  )
}
