import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function NoteIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 19.013475,6.4344752 v 0.5352901 12.0239107 0.557655 1.44642 H 15.00584 13.001771 6.7894549 c -1.0020361,0 -1.8034546,-0.801945 -1.8034546,-1.80396 V 8.3725203 L 10.396883,3.00225 h 8.616592 z M 17.00991,4.9656901 H 11.278562 V 9.2541904 H 6.9900679 V 18.993676 H 17.00991 V 6.9697653 6.4344752 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
