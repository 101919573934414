import { Typography } from '@mui/material'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'
import LoadingIndicator from './LoadingIndicator'
import { Account } from '../../models/Account'

export interface IAccountLoadingIndicatorProps {
  account?: Account
  isLoading: boolean
}

export default function AccountLoadingIndicator(props: IAccountLoadingIndicatorProps): JSX.Element {
  const { account, isLoading } = props
  const { t } = useTranslation()

  return (
    <>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          {!account && (
            <Typography variant="h5" textAlign="center" marginTop={2}>
              {t(msgIds.MSG_PROFILE_EDITOR_NOT_AVAILABLE_ACCOUNT)}
            </Typography>
          )}
        </>
      )}
    </>
  )
}
