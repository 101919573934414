import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function DesktopIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 3.5186307,2.9992674 c -1.11,0 -1.9495781,0.8928327 -1.9995117,2.001709 v 2.498291 h 1.9995117 v -2.498291 h 4.5 v -2.001709 z m 12.4871133,0 v 2.001709 h 4.5 v 2.498291 h 1.99951 l 0.0023,-2.498291 c -1.02e-4,-1.11 -0.901762,-2.001709 -2.00176,-2.001709 z M 1.519119,17.025736 v 1.999512 c 0,1.1 0.8895117,1.999512 1.9995117,1.999512 h 4.5 v -1.999512 h -4.5 v -1.999512 z m 18.986625,0 v 1.999512 h -4.5 v 1.999512 h 4.5 c 1.099998,0 1.990722,-0.899512 1.990722,-1.999512 l 0.0023,-1.999512 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
