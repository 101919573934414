import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { IAccountEditorBillingFormProps } from './AccountEditorBillingForm.types'
import { AccountEditorBilling } from './AccountEditorBilling'
import { useState } from 'react'
import DataEditorHeader from '../dataEditorHeader/DataEditorHeader'
import { EditIco } from '../icons'
import { DataEditorContainer } from '../dataEditorContainer/DataEditorContainer'

export function AccountEditorBillingForm(props: IAccountEditorBillingFormProps): JSX.Element {
  const { t } = useTranslation()
  const [isEditMode, setIsEditMode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  return (
    <DataEditorContainer isLoading={isLoading}>
      <DataEditorHeader
        title={t(msgIds.MSG_PROFILE_EDITOR_BILLING_TITLE)}
        isEditMode={isEditMode}
        viewModeCommands={[
          {
            commandText: '',
            icon: <EditIco />,
            onClick: () => setIsEditMode(true),
            tooltipText: t(msgIds.MSG_MODIFY) ?? undefined,
            disabled: false,
          },
        ]}
        editModeCommands={[]}
      />
      <AccountEditorBilling
        account={props.account}
        isEditMode={isEditMode}
        disabled={false}
        onSave={() => setIsEditMode(false)}
        onCancel={() => setIsEditMode(false)}
        setIsLoading={setIsLoading}
      />
    </DataEditorContainer>
  )
}
