import { ITaxRateMetadataJson, TaxRateMetadata } from './TaxRateMetadata'

export interface ITaxRateJson {
  id: string
  inclusive: boolean
  jurisdiction: string
  percentage: number
  display_name: string
  description: string
  created_at: string
  metadata: ITaxRateMetadataJson
}

export class TaxRate {
  id: string = ''
  inclusive: boolean = false
  jurisdiction: string = ''
  percentage: number = 0
  displayName: string = ''
  description: string = ''
  createdAt: Date = new Date(0)
  metadata: TaxRateMetadata = new TaxRateMetadata()

  constructor() {}

  public static serialize(obj: TaxRate): ITaxRateJson {
    return {
      id: obj.id,
      inclusive: obj.inclusive,
      jurisdiction: obj.jurisdiction,
      percentage: obj.percentage,
      display_name: obj.displayName,
      description: obj.description,
      created_at: obj.createdAt.toISOString(),
      metadata: TaxRateMetadata.serialize(obj.metadata),
    }
  }

  public static deserialize(json: ITaxRateJson): TaxRate {
    const res = new TaxRate()
    res.id = json.id
    res.inclusive = json.inclusive
    res.jurisdiction = json.jurisdiction
    res.percentage = json.percentage
    res.displayName = json.display_name
    res.description = json.description
    res.createdAt = new Date(json.created_at)
    res.metadata = TaxRateMetadata.deserialize(json.metadata)
    return res
  }

  public static serializeArray(objs: TaxRate[]): ITaxRateJson[] {
    const jsons = objs.map((p) => {
      return TaxRate.serialize(p)!
    })
    return jsons
  }

  public static deserializeArray(json: ITaxRateJson[]): TaxRate[] {
    const res = json.map((p) => {
      return TaxRate.deserialize(p)!
    })
    return res
  }
}
