import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function PrivacyCustomerIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 8.5144048,1.5139199 0,5.2976102 v 5.6755358 c 0,5.222235 3.6707894,10.142205 8.5144048,11.353275 3.7152332,-0.92172 6.7058182,-3.99156 7.9233402,-7.708005 0.05185,0.839985 0.5256,1.534725 1.204101,1.92699 0.3407,0.18894 0.719429,0.265875 1.135988,0.265875 0.41656,0 0.795288,-0.0768 1.135986,-0.265875 0.719328,-0.415875 1.248046,-1.172505 1.248046,-2.0808 0,-1.324185 -1.059844,-2.38404 -2.384032,-2.38404 -0.831609,0 -1.544982,0.418065 -1.959962,1.056885 0.13569,-0.709485 0.210938,-1.43364 0.210938,-2.164305 V 5.2976102 Z m 0,2.0808002 6.6225592,2.9135851 v 4.4648408 c 0,4.238085 -2.838292,8.21205 -6.6225592,9.384525 C 4.7301409,19.185196 1.8918459,15.211231 1.8918459,10.973146 V 6.5083052 Z m 4.3505862,4.1616302 -6.242432,6.1677157 -2.4609376,-2.384025 -1.3227541,1.32495 3.7836917,3.78369 7.567383,-7.5673806 z m 5.912843,10.4919457 c -1.740747,0 -5.183351,0.9471 -5.183351,2.83887 v 1.39965 h 4.350587 6.055663 v -1.39965 c 0,-1.89177 -3.482149,-2.83887 -5.222899,-2.83887 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
