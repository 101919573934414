import { createContext, useContext } from 'react'
import { Notification } from '../models/Notification'
import { INotificationsFilters } from '../components/notificationsFiltersForm/NotificationsFiltersForm.types'
import { NotificationArgumentType } from '../shared/Constants'

export type NotificationFilter = 'archived' | 'not_archived'

export interface INotificationsContextState {
  unread: number
  notifications: Notification[]
  moreNotifications: boolean
  archivedNotifications: Notification[]
  moreArchivedNotifications: boolean
  filters: INotificationsFilters
  resetNotifications: () => void
  resetArchivedNotifications: () => void
  loadNotifications: () => void
  loadArchivedNotifications: () => void
  togglePinnedStatus: (notification: Notification) => void
  archive: (notification: Notification) => void
  archiveAllNotPinned: () => void
  setArchivedFilters: (filters: INotificationsFilters) => void
}

export const NotificationsContext = createContext<INotificationsContextState>({
  unread: 0,
  notifications: [],
  moreNotifications: false,
  archivedNotifications: [],
  moreArchivedNotifications: false,
  filters: { argumentType: NotificationArgumentType.none },
  resetNotifications: () => {},
  resetArchivedNotifications: () => {},
  loadNotifications: () => {},
  loadArchivedNotifications: () => {},
  togglePinnedStatus: () => {},
  archive: () => {},
  archiveAllNotPinned: () => {},
  setArchivedFilters: () => {},
})

export function useNotificationsContext() {
  return useContext(NotificationsContext)
}
