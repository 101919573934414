import { PDX_API_URL } from '../shared/Constants'
import log from '../shared/Logger'
import { axiosInstance } from '../contexts/AuthContext'
import { UriComposer } from '../shared/types/UriComposer'
import { IProductJson, Product } from '../models/Product'

// ********************
// GET

export function getProducts(abortSignal: AbortSignal) {
  const funcName = 'getProducts'
  const url = UriComposer.create(`${PDX_API_URL}/v1/billing/products`).getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Product.deserializeArray(resp.data as IProductJson[])
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// POST

// ********************
// PATCH

// ********************
// DELETE
