import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function PrivacyDataProcessorIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 17.225217,11.541046 c 3.208212,0 5.775028,2.5668 5.775028,5.729685 0,3.16227 -2.566816,5.7291 -5.775028,5.7291 -3.162886,0 -5.683757,-2.56683 -5.683757,-5.7291 0,-3.162885 2.520871,-5.729685 5.683757,-5.729685 z m 2.428975,9.16701 -0.641392,-2.795925 2.153914,-1.833525 -2.795929,-0.22911 -1.099622,-2.612145 -1.100243,2.612145 -2.841877,0.22911 2.154537,1.879485 -0.642015,2.749965 2.429598,-1.466565 z M 9.9997551,0.99932991 19.00038,4.9991851 V 10.61466 c -0.552605,-0.1428 -1.131906,-0.21918 -1.729217,-0.21918 l -0.270715,0.006 V 6.2802152 L 9.9997551,3.19962 2.9996822,6.2799152 v 4.7201108 c 0,4.479825 3.0002102,8.680245 7.0000729,9.92019 0.4035869,-0.1248 0.7966199,-0.28002 1.1778539,-0.46257 0.316662,0.60477 0.72087,1.15611 1.195242,1.637955 -0.750052,0.392385 -1.544188,0.699135 -2.3730959,0.90465 C 4.8797793,21.719941 0.99975006,16.519861 0.99975006,11.000026 V 4.9996051 Z M 7.999823,17.000026 l -3.9998643,-4.00047 1.4001376,-1.40016 2.5997267,2.52027 6.60021,-6.5201257 1.400138,1.4001451 -2.563712,2.5637106 c -0.738876,0.49734 -1.375302,1.13376 -1.872646,1.872645 l -3.56399,3.563985"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
