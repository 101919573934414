import { Button, Stack, Typography, useTheme } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'
import { OperatorIco, UserIco } from '../../components/icons'
import { themeCustomerLight } from '../../themes/ThemeCustomerLight'
import { themeOperatorLight } from '../../themes/ThemeOperatorLight'
import { Center } from '../../components/center/Center'
import { useLayout } from '../../hooks/Layout'

export default function NotFoundPage() {
  const { isMobile } = useLayout()
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <Stack spacing={8} sx={{ alignItems: 'center', paddingTop: 8, paddingX: 2 }}>
      {/* <Typography variant="h2">{t(msgIds.MSG_NOT_FOUND_PAGE_404_ERROR)}</Typography> */}
      <Typography textAlign="center" variant={isMobile ? 'h4' : 'h2'}>
        {t(msgIds.MSG_NOT_FOUND_PAGE_ALERT)}
      </Typography>

      <Stack spacing={4} marginTop={4}>
        <Button
          variant="contained"
          sx={{
            height: 70,
            width: '100%',
            fontSize: isMobile ? '16px' : '20px',
            textTransform: 'none',
            backgroundColor: themeOperatorLight.palette.primary.dark,
            color: theme.palette.common.gray11,
          }}
          onClick={() => {
            navigate('/login?profileType=2')
          }}
        >
          <OperatorIco sx={{ height: '40px', width: '60px' }} />
          <span style={{ width: '15px' }} />
          {t(msgIds.MSG_NOT_FOUND_PAGE_GO_TO_LOGIN_FOR_OPERATORS)}
        </Button>

        <Button
          variant="contained"
          sx={{
            height: 70,
            width: '100%',
            fontSize: isMobile ? '16px' : '20px',
            textTransform: 'none',
            backgroundColor: themeCustomerLight.palette.primary.dark,
            color: theme.palette.common.gray11,
          }}
          onClick={() => {
            navigate('/login?profileType=1')
          }}
        >
          <UserIco sx={{ height: '40px', width: '60px' }} />
          <span style={{ width: '15px' }} />
          {t(msgIds.MSG_NOT_FOUND_PAGE_GO_TO_LOGIN_FOR_CUSTOMERS)}
        </Button>
      </Stack>
    </Stack>
  )
}
