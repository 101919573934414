import { ProfileType, ProfileType_all } from '../shared/Constants'
import { ProductFeatures } from './ProductFeatures'

export interface IProductMetadataJson {
  is_business: string
  profile_type: string
  position: string
  in_evidence: string
  features: string
  feature_set_id: string
  can_trial?: string
  trial_days?: string
}

export class ProductMetadata {
  isBusiness: boolean = false
  profileType: ProfileType_all = ProfileType.none
  position: number = 0
  inEvidence: boolean = false
  features: ProductFeatures = new ProductFeatures()
  featureSetId: number = 0
  canTrial: boolean = false
  trialDays?: number = 0

  constructor() {}

  public static serialize(obj: ProductMetadata): IProductMetadataJson {
    return {
      is_business: obj.isBusiness.toString(),
      profile_type: obj.profileType.toString(),
      position: obj.position.toString(),
      in_evidence: obj.inEvidence.toString(),
      features: JSON.stringify(ProductFeatures.serialize(obj.features)),
      feature_set_id: obj.featureSetId.toString(),
      can_trial: obj.canTrial.toString(),
      trial_days: obj.trialDays ? obj.trialDays.toString() : undefined,
    }
  }

  public static deserialize(json: IProductMetadataJson): ProductMetadata {
    const res = new ProductMetadata()
    res.isBusiness = json.is_business === 'true'
    res.profileType = parseInt(json.profile_type) as ProfileType
    res.position = parseInt(json.position)
    res.inEvidence = json.in_evidence === 'true'
    try {
      if (json.features) {
        const featuresJson = JSON.parse(json.features)
        res.features = ProductFeatures.deserialize(featuresJson)
      }
    } catch (err) {
      res.features = new ProductFeatures()
    }
    res.featureSetId = parseInt(json.feature_set_id)
    res.canTrial = json.can_trial === 'true'
    res.trialDays = json.trial_days ? parseInt(json.trial_days) : undefined
    return res
  }
}
