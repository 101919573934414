import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function AddToDesktopIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 3.574951,2.9992674 c -1.11,0 -1.949578,0.8928327 -1.9995117,2.001709 v 2.498291 H 3.574951 v -2.498291 h 4.5 v -2.001709 z m 12.487061,0 v 2.001709 h 4.5 v 2.498291 h 1.999512 l 0.0023,-2.498291 c -1.58e-4,-1.11 -0.901764,-2.001709 -2.001762,-2.001709 z M 8.0002438,8.0002438 V 10.549072 H 1.5117187 v 2.90039 h 6.4885251 v 2.551026 l 3.9990242,-4.00122 z M 1.5754393,17.026611 v 1.99951 c 0,1.100003 0.8895117,1.999512 1.9995117,1.999512 h 4.5 v -1.999512 h -4.5 v -1.99951 z m 18.9865727,0 v 1.99951 h -4.5 v 1.999512 h 4.5 c 1.099998,0 1.990722,-0.899509 1.990722,-1.999512 l 0.0023,-1.99951 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
