import { IPageStepProps } from './PageStep.types'
import { usePageStepperContext } from './PageStepperContext'

export function PageStep(props: IPageStepProps): JSX.Element {
  const { activeStep } = usePageStepperContext()

  if (activeStep === props.step) {
    return <>{props.children}</>
  } else {
    return <></>
  }
}
