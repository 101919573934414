import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function CollaboratorsIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 11.960064,5.0844709 a 3.4579842,3.4579842 0 0 0 -3.4584957,3.4584962 3.4570239,3.4570239 0 0 0 3.4584957,3.4562989 3.4579842,3.4579842 0 1 0 0,-6.9147951 z m 0,1.7292482 a 1.72899,1.72899 0 1 1 -1.729248,1.729248 1.7340727,1.7340727 0 0 1 1.729248,-1.729248 z M 4.9859433,7.888182 a 1.8877966,1.8877966 0 0 0 -1.8896484,1.887451 1.8872724,1.8872724 0 0 0 1.8896484,1.887451 1.8877966,1.8877966 0 1 0 0,-3.774902 z M 19.008893,7.962889 a 1.8502957,1.8502957 0 0 0 -1.850099,1.8500976 1.8497818,1.8497818 0 0 0 1.850099,1.8500974 1.8502957,1.8502957 0 1 0 0,-3.700195 z M 4.9859433,8.8330062 A 0.94389717,0.94389717 0 1 1 4.0411191,9.775633 0.94667194,0.94667194 0 0 1 4.9859433,8.8330062 Z m 14.0229497,0.054932 a 0.92514673,0.92514673 0 1 1 -0.92505,0.9250488 0.9278664,0.9278664 0 0 1 0.92505,-0.9250488 z m 0,3.2387688 0.69873,0.931641 -0.694337,2.300537 -0.692137,-2.300537 z m 0,0 c -1.0431,0 -2.963588,0.441907 -3.535401,1.320557 -1.241025,-0.386807 -2.568982,-0.582275 -3.513428,-0.582275 l 1.305176,1.740234 -1.298584,4.300049 -1.296386,-4.300049 1.289794,-1.740234 c -0.918872,0 -2.2024006,0.185342 -3.4145503,0.551513 C 7.9099301,12.564771 6.021039,12.135496 4.983746,12.135496 L 5.6978574,13.084715 4.9881405,15.433593 4.280621,13.084715 4.983746,12.135496 c -1.2601013,0 -3.7749023,0.63207 -3.7749023,1.887452 v 1.417236 h 4.0803222 c -0.1563079,0.273012 -0.2460937,0.567112 -0.2460937,0.8833 v 2.592774 H 18.874859 v -2.592774 c 0,-0.344929 -0.106047,-0.664465 -0.290039,-0.958008 h 4.126464 v -1.388671 c 0,-1.230431 -2.467324,-1.850098 -3.702391,-1.850098 z M 2.1558651,14.025145 v 0.470215 h -0.0022 v -0.468018 z m 4.6208496,2.300537 v 0.861328 h -0.0022 v -0.856933 c 4.8e-4,-0.0018 0.00169,-0.0027 0.0022,-0.0044 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
