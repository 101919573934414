import { ITreatmentCustomFieldDialogProps } from './TreatmentCustomFieldDialog.types'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import msgIds from '../../locales/msgIds'
import { TreatedField } from '../../models/TreatedField'
import { StyledDialog } from '../styledDialog/StyledDialog'
import { ViewHeader } from '../../components/viewHeader/ViewHeader'
import TreatmentCustomField from '../../components/treatmentCustomField/TreatmentCustomField'

export default function TreatmentCustomFieldDialog(props: ITreatmentCustomFieldDialogProps) {
  const { t } = useTranslation()

  return (
    <StyledDialog open={props.isOpen} onClose={() => props.onClose({ userChoice: 'abort' })}>
      <ViewHeader
        exitButtonVisible={true}
        onClickExit={() => props.onClose({ userChoice: 'abort' })}
        title={t(msgIds.MSG_TREATED_CUSTOM_FIELD_TITLE)}
      />
      <TreatmentCustomField
        flexGrow={1}
        customFields={props.customFields}
        customField={props.customField}
        onUpdated={(customField: TreatedField) => props.onResult({ userChoice: 'yes', customField: customField })}
        onCancel={() => props.onClose({ userChoice: 'no' })}
      />
    </StyledDialog>
  )
}
