import { useTheme } from '@mui/material'
import { TourProvider } from '@reactour/tour'
import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import * as dalAccount from '../dal/DalAccount'
import { Utils } from '../shared/Utils'
import { useAuthContext } from './AuthContext'
import { FePreferences } from '../models/FePreferences'
import { GuideCfg } from '../models/GuideCfg'
import { useTranslation } from 'react-i18next'
import { useLayout } from '../hooks/Layout'

interface TourStateContextProps {
  tourToNeverShowAgain: string
  setTourToNeverShowAgain: Dispatch<SetStateAction<string>>
}

interface IProps {
  children?: React.ReactNode
}

const TourStateContext = createContext<TourStateContextProps | undefined>(undefined)
export const TourStateContextProvider: React.FC<IProps> = ({ children }) => {
  const theme = useTheme()
  const { isPhone } = useLayout()
  const { t } = useTranslation()
  const authContext = useAuthContext()
  const [tourToNeverShowAgain, setTourToNeverShowAgain] = useState('')

  useEffect(() => {
    setTourToNeverShowAgain('')
  }, [authContext.loggedProfileId])

  async function saveFePreferences() {
    if (!tourToNeverShowAgain) return
    if (!authContext.loggedProfileId) return
    if (!authContext.loggedAccount) return
    if (!authContext.loggedAccount.profile) return

    const profileId = authContext.loggedProfileId
    const fePreferences = authContext.loggedAccount?.profile?.fePreferences ?? new FePreferences()
    const guideCfg = fePreferences.guideCfg ?? new GuideCfg()
    const silencedIds = guideCfg.silencedIds
    if (silencedIds.includes(tourToNeverShowAgain)) return

    silencedIds.push(tourToNeverShowAgain)
    guideCfg.silencedIds = silencedIds
    fePreferences.guideCfg = guideCfg

    try {
      const abortController = new AbortController()
      const updatedAccount = await dalAccount.updateProfile(abortController.signal, profileId, { fePreferences })
      authContext.loggedAccount.profile.fePreferences = updatedAccount.profile?.fePreferences
    } catch (error) {
      Utils.enqueueSnackbarError2(error, t)
    } finally {
      setTourToNeverShowAgain('')
    }
  }

  return (
    <TourStateContext.Provider value={{ tourToNeverShowAgain, setTourToNeverShowAgain }}>
      <TourProvider
        steps={[]}
        styles={{
          maskArea: (base) => ({ ...base, rx: 10 }),
          popover: (base) => ({
            ...base,
            borderRadius: 10,
            marginLeft: 10,
            maxWidth: isPhone ? 'calc(100vw - 100px)' : 400,
          }),
          badge: (base) => ({ ...base, backgroundColor: theme.palette.primary.main }),
        }}
        onClickClose={(e) => {
          saveFePreferences()
          e.setIsOpen(false)
        }}
        onClickMask={(e) => {
          saveFePreferences()
          e.setIsOpen(false)
        }}
      >
        {children}
      </TourProvider>
    </TourStateContext.Provider>
  )
}

export const useTourStateContext = (): TourStateContextProps => {
  const context = useContext(TourStateContext)
  if (!context) {
    throw new Error('useTourStateContext must be used within a TourStateContextProvider')
  }
  return context
}
