import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function EditIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 18.177978,2.017093 a 1.1138097,1.1138097 0 0 0 -0.65918,0.3142089 l -2.034667,2.0346679 4.170409,4.1682129 2.032472,-2.0324706 a 1.1070956,1.1070956 0 0 0 0,-1.5688477 L 19.087647,2.3313019 A 1.1138097,1.1138097 0 0 0 18.177978,2.017093 Z M 14.29541,5.5546906 1.9995117,17.85059 v 4.168212 H 6.1699219 L 18.463623,9.7251007 Z m 0,3.1464844 1.021729,1.0239257 L 5.2470703,19.797366 H 4.2231445 v -1.023925 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
