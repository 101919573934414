import React, { useReducer, useEffect, useRef, useCallback, useMemo } from 'react'
import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import log from '../shared/Logger'
import { Action } from '../shared/types/Action'
import { Account } from '../models/Account'
import { LOGIN_ENABLED, ProfileType } from '../shared/Constants'
import { useLocation, useNavigate } from 'react-router'

export interface LocationState {
  loggedProfileId?: number
  pathname: string
  search: string
  state: any
}

// NOTE: set withCredentials: true to allow cookies to be sent
export const axiosInstance = axios.create({ withCredentials: false })

interface IContextStateData {
  loggedAccount?: Account
  loggedUserId?: number
  loggedProfileId?: number
  loggedProfileType?: ProfileType
  linkedStructureAccount?: Account
  linkedStructureProfileId?: number
  assistedAccount?: Account
  assistedAccountProfileId?: number
  isOnlyPageContentVisible: boolean
}

interface IContextStateActions {
  setLoggedAccount: (account: Account) => void
  resetLoggedAccount: () => void
  setAssistedAccount: (account: Account) => void
  resetAssistedAccount: () => void
  setLinkedStructureAccount: (account: Account) => void
  resetLinkedStructureAccount: () => void
  setJwt: (jwt?: string) => void
  resetJwt: () => void
  navigateToLastLocationAsLogged: () => void
  setDestinationAfterLogin: (location: LocationState) => void
  resetDestinationAfterLogin: () => void
  getDestinationAfterLogin: () => LocationState | undefined
  showOnlyPageContent: () => void
  showCompletePage: () => void
}

export interface IContextState extends IContextStateData, IContextStateActions {}

const initialState: IContextStateData = {
  loggedAccount: undefined,
  loggedUserId: undefined,
  loggedProfileId: undefined,
  loggedProfileType: undefined,
  linkedStructureAccount: undefined,
  linkedStructureProfileId: undefined,
  assistedAccount: undefined,
  assistedAccountProfileId: undefined,
  isOnlyPageContentVisible: false,
}

type Actions =
  | Action<'SET_LOGGED_ACCOUNT', Account>
  | Action<'RESET_LOGGED_ACCOUNT', undefined>
  | Action<'SET_ASSISTED_ACCOUNT', Account>
  | Action<'RESET_ASSISTED_ACCOUNT', undefined>
  | Action<'SET_LINKED_STRUCTURE_ACCOUNT', Account>
  | Action<'RESET_LINKED_STRUCTURE_ACCOUNT', undefined>
  | Action<'HIDE_ALL_EXCEPT_PAGE_CONTENT', undefined>
  | Action<'SHOW_ALL_EXCEPT_PAGE_CONTENT', undefined>

type ReducerFunc = (state: IContextStateData, action: Actions) => IContextStateData
function reducer(state: IContextStateData, action: Actions): IContextStateData {
  switch (action.type) {
    case 'SET_LOGGED_ACCOUNT': {
      log.debug('SET_LOGGED_ACCOUNT')
      return {
        ...state,
        loggedAccount: action.payload,
        loggedUserId: action.payload?.user?.userId,
        loggedProfileId: action.payload?.profile?.profileId,
        loggedProfileType: action.payload?.profile?.type,
        linkedStructureAccount: action.payload?.linkedAccount,
        linkedStructureProfileId: action.payload?.linkedAccount?.profile?.profileId,
      }
    }
    case 'RESET_LOGGED_ACCOUNT': {
      return {
        ...state,
        loggedAccount: undefined,
        loggedUserId: undefined,
        loggedProfileId: undefined,
        loggedProfileType: undefined,
        linkedStructureAccount: undefined,
        linkedStructureProfileId: undefined,
      }
    }
    case 'SET_ASSISTED_ACCOUNT': {
      return {
        ...state,
        assistedAccount: action.payload,
        assistedAccountProfileId: action.payload?.profile?.profileId,
      }
    }
    case 'RESET_ASSISTED_ACCOUNT': {
      return {
        ...state,
        assistedAccount: undefined,
        assistedAccountProfileId: undefined,
      }
    }
    case 'SET_LINKED_STRUCTURE_ACCOUNT': {
      return {
        ...state,
        linkedStructureAccount: action.payload,
        linkedStructureProfileId: action.payload?.profile?.profileId,
      }
    }
    case 'RESET_LINKED_STRUCTURE_ACCOUNT': {
      return {
        ...state,
        linkedStructureAccount: undefined,
        linkedStructureProfileId: undefined,
      }
    }
    case 'HIDE_ALL_EXCEPT_PAGE_CONTENT': {
      return {
        ...state,
        isOnlyPageContentVisible: true,
      }
    }
    case 'SHOW_ALL_EXCEPT_PAGE_CONTENT': {
      return {
        ...state,
        isOnlyPageContentVisible: false,
      }
    }
    default:
      return state
  }
}

interface IProps {
  children?: React.ReactNode
}

const AuthContext = React.createContext<IContextState | undefined>(undefined)

export const AuthContextProvider: React.FC<IProps> = ({ children }) => {
  const [state, dispatch] = useReducer<ReducerFunc>(reducer, initialState as IContextState)
  const location = useLocation()
  const navigate = useNavigate()
  const jwtRef = useRef<string | undefined>(undefined)
  const lastLocationAsLoggedRef = useRef<LocationState | undefined>(undefined)

  // to keep track of the current status
  const stateRef = useRef(state)
  useEffect(() => {
    stateRef.current = state
  }, [state])

  const handleTokenExpiredRef = useRef(() => {
    jwtRef.current = undefined
    if (stateRef.current.loggedProfileType) {
      navigate(`/login?profileType=${stateRef.current.loggedProfileType}`)
    } else {
      navigate(`/login`)
    }
  })

  useEffect(() => {
    const reqInterceptor = axiosInstance.interceptors.request.use(
      function (request: InternalAxiosRequestConfig<any>) {
        if (jwtRef.current) {
          request.headers['authorization'] = `Bearer ${jwtRef.current}`
        }
        return request
      },
      function (error) {
        return Promise.reject(error)
      }
    )

    const resInterceptor = axiosInstance.interceptors.response.use(
      function (response: AxiosResponse<any, any>) {
        if (response.status === 401) {
          jwtRef.current = undefined
          return response
        }
        if (response.data.jwt) {
          jwtRef.current = response.data.jwt
        } else if (
          !response.config.url?.endsWith('/notifications/stats') &&
          response.headers['authorization'] &&
          (response.headers['authorization'] as string).startsWith('Bearer')
        ) {
          const jwt = (response.headers['authorization'] as string).substring(7)
          jwtRef.current = jwt
        }
        return response
      },
      function (error) {
        if (
          error.response &&
          (error.response.data.message === 'Authorization token expired' ||
            error.response.data.message === 'No Authorization was found in request.headers')
        ) {
          handleTokenExpiredRef.current()
        }
        return Promise.reject(error)
      }
    )

    return () => {
      axiosInstance.interceptors.request.eject(reqInterceptor)
      axiosInstance.interceptors.response.eject(resInterceptor)
    }
  }, [])

  useEffect(() => {
    if (!location.pathname.includes('login') && !location.pathname.includes('regist') && LOGIN_ENABLED) {
      const locationState: LocationState = {
        loggedProfileId: state.loggedProfileId,
        pathname: location.pathname,
        search: location.search,
        state: location.state,
      }
      lastLocationAsLoggedRef.current = locationState
    }
  }, [location, state.loggedProfileId])

  const navigateToLastLocationAsLogged = useCallback(() => {
    const locationState = lastLocationAsLoggedRef.current
    if (
      locationState &&
      (!locationState.loggedProfileId || locationState.loggedProfileId === stateRef.current.loggedProfileId)
    ) {
      locationState.search
        ? navigate(`${locationState.pathname}?${locationState.search}`, { state: locationState.state })
        : navigate(`${locationState.pathname}`, { state: locationState.state })
    } else {
      navigate('/')
    }
  }, [navigate])

  const setDestinationAfterLogin = useCallback((locationState: LocationState) => {
    lastLocationAsLoggedRef.current = locationState
  }, [])

  const resetDestinationAfterLogin = useCallback(() => {
    lastLocationAsLoggedRef.current = undefined
  }, [])

  const getDestinationAfterLogin = useCallback((): LocationState | undefined => {
    return lastLocationAsLoggedRef.current
  }, [])

  const setJwt = useCallback((jwt?: string) => {
    jwtRef.current = jwt
  }, [])

  const resetJwt = useCallback(() => {
    jwtRef.current = undefined
  }, [])

  const showOnlyPageContent = useCallback(() => {
    dispatch({ type: 'HIDE_ALL_EXCEPT_PAGE_CONTENT' })
  }, [])

  const showCompletePage = useCallback(() => {
    dispatch({ type: 'SHOW_ALL_EXCEPT_PAGE_CONTENT' })
  }, [])

  const setLoggedAccount = useCallback((account: Account) => {
    dispatch({ type: 'SET_LOGGED_ACCOUNT', payload: account })
  }, [])

  const resetLoggedAccount = useCallback(() => {
    dispatch({ type: 'RESET_LOGGED_ACCOUNT' })
  }, [])

  const setAssistedAccount = useCallback((account: Account) => {
    dispatch({ type: 'SET_ASSISTED_ACCOUNT', payload: account })
  }, [])

  const resetAssistedAccount = useCallback(() => {
    dispatch({ type: 'RESET_ASSISTED_ACCOUNT' })
  }, [])

  const setLinkedStructureAccount = useCallback((account: Account) => {
    dispatch({ type: 'SET_LINKED_STRUCTURE_ACCOUNT', payload: account })
  }, [])

  const resetLinkedStructureAccount = useCallback(() => {
    dispatch({ type: 'RESET_LINKED_STRUCTURE_ACCOUNT' })
  }, [])

  const contextValue = useMemo<IContextState>(
    () => ({
      // context props
      loggedAccount: state.loggedAccount,
      loggedUserId: state.loggedUserId,
      loggedProfileId: state.loggedProfileId,
      loggedProfileType: state.loggedProfileType,
      linkedStructureAccount: state.linkedStructureAccount,
      linkedStructureProfileId: state.linkedStructureProfileId,
      assistedAccount: state.assistedAccount,
      assistedAccountProfileId: state.assistedAccountProfileId,
      isOnlyPageContentVisible: state.isOnlyPageContentVisible,

      // context actions
      setLoggedAccount,
      resetLoggedAccount,
      setAssistedAccount,
      resetAssistedAccount,
      setLinkedStructureAccount,
      resetLinkedStructureAccount,
      setJwt,
      resetJwt,
      navigateToLastLocationAsLogged,
      setDestinationAfterLogin,
      resetDestinationAfterLogin,
      getDestinationAfterLogin,
      showOnlyPageContent,
      showCompletePage,
    }),
    [
      state,
      setLoggedAccount,
      resetLoggedAccount,
      setAssistedAccount,
      resetAssistedAccount,
      setLinkedStructureAccount,
      resetLinkedStructureAccount,
      setJwt,
      resetJwt,
      navigateToLastLocationAsLogged,
      setDestinationAfterLogin,
      resetDestinationAfterLogin,
      getDestinationAfterLogin,
      showOnlyPageContent,
      showCompletePage,
    ]
  )

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}

export const useAuthContext = (): IContextState => {
  const context = React.useContext(AuthContext)
  if (!context) {
    throw new Error('useAuthContext must be used inside AuthContextProvider')
  }
  return context
}
