import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function ArrowRightIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 12,3.9999999 -1.41,1.41 L 16.17,11 H 4.0000003 v 2 H 16.17 l -5.59,5.579999 1.42,1.42 L 19.999999,12 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
