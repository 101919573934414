import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function SectionAddIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 14,10 H 1.9999999 v 2 H 14 Z M 14,6 H 1.9999999 V 8.0000001 H 14 Z m 4,8 v -4 h -2 v 4 h -4 v 2 h 4 v 3.999999 h 2 V 16 h 4 V 14 Z M 1.9999999,16 H 10 V 14 H 1.9999999 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
