import { Paper, Stack } from '@mui/material'
import { LoginRegistrationBox } from '../loginRegistrationBox/LoginRegistrationBox'
import { ILoginOrRegisterViewProps } from './LoginOrRegisterView.types'
import { AppType } from '../../shared/Constants'
import { useLayout } from '../../hooks/Layout'

export function LoginOrRegisterView(props: ILoginOrRegisterViewProps): JSX.Element {
  const { onLogin, onRegister } = props
  const { isMobile } = useLayout()
  const types: AppType[] = [AppType.business, AppType.consumer]
  return (
    <Stack direction={isMobile ? 'column' : 'row'} gap={4} width={isMobile ? 400 : 900} sx={{}}>
      {types.map((type) => (
        <Paper key={type} sx={{ flex: 1, padding: 2 }}>
          <LoginRegistrationBox appType={type} onLogin={() => onLogin(type)} onRegister={() => onRegister(type)} />
        </Paper>
      ))}
    </Stack>
  )
}
