import { Box, Skeleton, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import msgIds from '../../locales/msgIds'
import { AccountType, ProfileType } from '../../shared/Constants'
import { IAccountInfoIdentityProps } from './AccountInfoIdentity.types'
import FieldsPanel from '../fieldsPanel/FieldsPanel'
import FieldInfo from '../fieldInfo/FieldInfo'
import AvatarImage from '../avatarImage/AvatarImage'

export default function AccountInfoIdentity(props: IAccountInfoIdentityProps) {
  const { isLoading, account } = props
  const { t } = useTranslation()

  const hasLastname = () => {
    return account.userDetails?.lastname
  }
  const hasName = () => {
    return account.userDetails?.name
  }
  const hasAnyField = () => {
    return hasLastname() || hasName()
  }

  return (
    <>
      {isLoading && (
        <Stack direction="column" alignItems="center" padding={1} spacing={2}>
          <Skeleton variant="rectangular" width={'100%'} height={130} />
        </Stack>
      )}
      {!isLoading && hasAnyField() && (
        <Stack direction={'column'} alignItems={'stretch'} padding={1} spacing={3}>
          <FieldsPanel>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <AvatarImage
                width={80}
                height={80}
                borderSize={1}
                placeholderIndicatorSize={20}
                identity={account.getIdentityInverse()}
                initials={account.getInitials()}
                image={account.profile?.avatarImage}
                accountType={account.user?.accountType || AccountType.none}
                sx={{ alignSelf: 'center' }}
              />
            </Box>

            {(hasLastname() || hasName()) && (
              <FieldInfo
                title={account.profile?.type === ProfileType.structure ? t(msgIds.MSG_NAME) : t(msgIds.MSG_FULLNAME)}
                text={account.getIdentityInverse()}
              />
            )}
          </FieldsPanel>
        </Stack>
      )}
    </>
  )
}
