import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function RetentionDisabledIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 2.1987305 0.83642578 L 0.78369141 2.2587891 L 3.5170898 4.9775391 C 3.5065484 5.0501987 3.4995117 5.125024 3.4995117 5.2001953 L 3.4995117 7.7578125 C 3.4995117 8.3698257 3.8646112 8.897317 4.3491211 9.1948242 L 4.3491211 18.799805 C 4.3491211 19.73481 5.2862521 20.500488 6.0512695 20.500488 L 17.950195 20.500488 C 18.238249 20.500488 18.550437 20.391145 18.827637 20.206055 L 21.80127 23.163574 L 23.216309 21.741211 L 19.650879 18.194824 L 19.650879 9.1948242 C 20.13538 8.897317 20.500488 8.3698257 20.500488 7.7578125 L 20.500488 5.2001953 C 20.500488 4.2651735 19.649808 3.4995117 18.799805 3.4995117 L 5.2001953 3.4995117 C 5.1023186 3.4995117 5.0037958 3.5109053 4.9072266 3.5302734 L 2.1987305 0.83642578 z M 6.5859375 5.2001953 L 18.799805 5.2001953 L 18.799805 7.7504883 L 9.1494141 7.7504883 L 6.5859375 5.2001953 z M 5.2001953 6.6518555 L 6.3046875 7.7504883 L 5.2001953 7.7504883 L 5.2001953 6.6518555 z M 6.0512695 9.4511719 L 8.0141602 9.4511719 L 10.577637 12 L 9.4511719 12 L 9.4511719 13.702148 L 12.288574 13.702148 L 17.414063 18.799805 L 6.0512695 18.799805 L 6.0512695 9.4511719 z M 10.860352 9.4511719 L 17.950195 9.4511719 L 17.950195 16.50293 L 14.551758 13.123535 L 14.551758 12 L 13.422363 12 L 10.860352 9.4511719 z "
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
