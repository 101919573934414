import { NotificationsType, PDX_API_URL } from '../shared/Constants'
import log from '../shared/Logger'
import { axiosInstance } from '../contexts/AuthContext'
import { UriComposer } from '../shared/types/UriComposer'
import { AxiosResponse } from 'axios'
import { PaginatedResponse } from '../shared/types/PaginatedResponse'
import { INotificationJson, Notification } from '../models/Notification'
import { Utils } from '../shared/Utils'
import { INotificationStatsJson, NotificationStats } from '../models/NotificationStats'

// ********************
// GET

function deserializeResponse(resp: AxiosResponse<any, any>): PaginatedResponse<Notification> {
  let desResp = new PaginatedResponse<Notification>()
  desResp.rows = resp.data.rows.map((json: INotificationJson) => {
    const data = Notification.deserialize(json as INotificationJson)
    return data
  })
  desResp.page = resp.data.page
  desResp.offset = resp.data.offset
  desResp.limit = resp.data.limit
  desResp.total = resp.data.total
  return desResp
}

export type GetNotificationsArgs = {
  pageNum: number
  pageSize: number
  authorProfileIds?: number[]
  linkedAuthorProfileIds?: number[]
  fromDate?: Date
  toDate?: Date
  types?: NotificationsType[]
  isArchived?: boolean
}

export function getNotifications(abortSignal: AbortSignal, args: GetNotificationsArgs) {
  const funcName = 'getNotifications'
  const url = UriComposer.create(`${PDX_API_URL}/v1/notifications`)
    .addParamNumber('offset', args.pageNum * args.pageSize)
    .addParamNumber('limit', args.pageSize)
    .addParamIntArray('author_profile_id', args.authorProfileIds)
    .addParamIntArray('author_linked_profile_id', args.linkedAuthorProfileIds)
    .addParamDateNullable('created_from', args.fromDate, true, true)
    .addParamDateNullable(
      'created_until',
      args.toDate ? (Utils.daysFromDateTime(args.toDate, 1, false) as Date) : undefined,
      true,
      true
    )
    .addParamIntArray('type', args.types)
    .addParamBoolNullable('archived', args.isArchived)
    .getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = deserializeResponse(resp)
      return reply.rows
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function getNotificationStats(abortSignal: AbortSignal, types?: NotificationsType[]) {
  const funcName = 'getNotificationStats'
  const url = UriComposer.create(`${PDX_API_URL}/v1/notifications/stats`)
    .addParamIntArray('type', types)
    .getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = NotificationStats.deserialize(resp.data as INotificationStatsJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// POST

// ********************
// PATCH

export function setNotificationPinnedStatus(abortSignal: AbortSignal, notificationId: number, isPinned: boolean) {
  const funcName = 'setNotificationPinnedStatus'
  const url = `${PDX_API_URL}/v1/notifications/${notificationId}`

  const parms = {
    pin: isPinned,
  }
  return axiosInstance
    .patch(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Notification.deserialize(resp.data as INotificationJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function archiveNotification(abortSignal: AbortSignal, notificationId: number) {
  const funcName = 'archiveNotification'
  const url = `${PDX_API_URL}/v1/notifications/${notificationId}`

  const parms = {
    archive: true,
  }
  return axiosInstance
    .patch(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Notification.deserialize(resp.data as INotificationJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// DELETE
