import { NotificationsType } from '../shared/Constants'
import { Account, IAccountJson } from './Account'
import { INotificationContextConsentJson, NotificationContextConsent } from './NotificationContextConsent'
import {
  INotificationContextContractVersionJson,
  NotificationContextContractVersion,
} from './NotificationContextContractVersion'
import { INotificationContextDocumentJson, NotificationContextDocument } from './NotificationContextDocument'
import {
  INotificationContextDocumentRevisionJson,
  NotificationContextDocumentRevision,
} from './NotificationContextDocumentRevision'
import { INotificationContextFlattenDoxJson, NotificationContextFlattenDox } from './NotificationContextFlattenDox'
import { INotificationContextInvitationJson, NotificationContextInvitation } from './NotificationContextInvitation'
import { INotificationContextPermissionJson, NotificationContextPermission } from './NotificationContextPermission'
import { INotificationContextRetentionJson, NotificationContextRetention } from './NotificationContextRetention'
import {
  INotificationContextSubscriptionJson,
  NotificationContextSubscription,
} from './NotificationContextSubscription'
import { INotificationContextValuesJson, NotificationContextValues } from './NotificationContextValues'

export interface INotificationContextJson {
  author?: IAccountJson
  author_linked?: IAccountJson
  target?: IAccountJson
  subject?:
    | INotificationContextValuesJson
    | INotificationContextDocumentRevisionJson
    | INotificationContextDocumentJson
    | INotificationContextFlattenDoxJson
    | INotificationContextPermissionJson
    | INotificationContextInvitationJson
    | INotificationContextConsentJson
    | INotificationContextContractVersionJson
    | INotificationContextSubscriptionJson
    | INotificationContextRetentionJson
}

export class NotificationContext {
  author?: Account
  accountLinked?: Account
  target?: Account

  values?: NotificationContextValues
  documentRevision?: NotificationContextDocumentRevision
  document?: NotificationContextDocument
  flattenDox?: NotificationContextFlattenDox
  permission?: NotificationContextPermission
  invitation?: NotificationContextInvitation
  consent?: NotificationContextConsent
  contractVersion?: NotificationContextContractVersion
  subscription?: NotificationContextSubscription
  retention?: NotificationContextRetention

  constructor() {}

  public static serialize(obj: NotificationContext, type: NotificationsType): INotificationContextJson {
    const res: INotificationContextJson = {}
    res.author = obj.author ? Account.serialize(obj.author) : undefined
    if (!!obj.author?.linkedAccount) {
      res.author_linked = obj.author.linkedAccount ? Account.serialize(obj.author.linkedAccount) : undefined
    }
    res.target = obj.target ? Account.serialize(obj.target) : undefined
    return res
  }

  public static deserialize(json: INotificationContextJson, type: NotificationsType): NotificationContext {
    const res = new NotificationContext()
    res.author = json.author ? Account.deserialize(json.author) : undefined
    if (res.author) {
      res.author.linkedAccount = json.author_linked ? Account.deserialize(json.author_linked) : undefined
    }
    res.target = json.target ? Account.deserialize(json.target) : undefined

    if (json.subject) {
      switch (type) {
        case NotificationsType.userPrivateMessage:
          break
        case NotificationsType.upcomingProfileSuspension:
          res.values = NotificationContextValues.deserialize(json.subject as INotificationContextValuesJson)
          break
        case NotificationsType.profileSuspension:
          break
        case NotificationsType.upcomingProfileDeletion:
          res.values = NotificationContextValues.deserialize(json.subject as INotificationContextValuesJson)
          break
        case NotificationsType.profileDeletionBySystem:
          break
        case NotificationsType.profileDeletionByOwner:
          break
        case NotificationsType.profileDeletionByOperatorAdmin:
          break
        case NotificationsType.upcomingUserDeletion:
          res.contractVersion = NotificationContextContractVersion.deserialize(
            json.subject as INotificationContextContractVersionJson
          )
          break
        case NotificationsType.userDeletionBySystem:
          break
        case NotificationsType.userDeletionByOwner:
          break
        case NotificationsType.contractUpdated:
          res.contractVersion = NotificationContextContractVersion.deserialize(
            json.subject as INotificationContextContractVersionJson
          )
          break
        case NotificationsType.dossierDelivered:
          break
        case NotificationsType.documentRevisionCreated:
          res.documentRevision = NotificationContextDocumentRevision.deserialize(
            json.subject as INotificationContextDocumentRevisionJson
          )
          break
        case NotificationsType.documentRevisionViewed:
          res.documentRevision = NotificationContextDocumentRevision.deserialize(
            json.subject as INotificationContextDocumentRevisionJson
          )
          break
        case NotificationsType.documentRevisionDownloaded:
          res.documentRevision = NotificationContextDocumentRevision.deserialize(
            json.subject as INotificationContextDocumentRevisionJson
          )
          break
        case NotificationsType.dataProcessingGranted:
          res.permission = NotificationContextPermission.deserialize(json.subject as INotificationContextPermissionJson)
          break
        case NotificationsType.dataProcessingUpdated:
          res.permission = NotificationContextPermission.deserialize(json.subject as INotificationContextPermissionJson)
          break
        case NotificationsType.dataProcessingSuspended:
          res.permission = NotificationContextPermission.deserialize(json.subject as INotificationContextPermissionJson)
          break
        case NotificationsType.dataProcessingRestored:
          res.permission = NotificationContextPermission.deserialize(json.subject as INotificationContextPermissionJson)
          break
        case NotificationsType.upcomingDataProcessingExpiration:
          res.permission = NotificationContextPermission.deserialize(json.subject as INotificationContextPermissionJson)
          break
        case NotificationsType.dataProcessingExpired:
          res.permission = NotificationContextPermission.deserialize(json.subject as INotificationContextPermissionJson)
          break
        case NotificationsType.privacyPolicyUpdated:
          res.contractVersion = NotificationContextContractVersion.deserialize(
            json.subject as INotificationContextContractVersionJson
          )
          break
        case NotificationsType.privacyPolicyConsentUpdated:
          res.consent = NotificationContextConsent.deserialize(json.subject as INotificationContextConsentJson)
          res.contractVersion = NotificationContextContractVersion.deserialize(
            json.subject as INotificationContextContractVersionJson
          )
          break
        case NotificationsType.profileAttached:
          break
        case NotificationsType.profileDeattached:
          break
        case NotificationsType.upcomingSubscriptionTrialExpiration:
          res.subscription = NotificationContextSubscription.deserialize(
            json.subject as INotificationContextSubscriptionJson
          )
          break
        case NotificationsType.upcomingDocumentRetentionExpiration:
          res.values = NotificationContextValues.deserialize(json.subject as INotificationContextValuesJson)
          res.retention = NotificationContextRetention.deserialize(json.subject as INotificationContextRetentionJson)
          break
        case NotificationsType.documentRetentionExpired:
          break
        case NotificationsType.upcomingDoxRetentionExpiration:
          res.values = NotificationContextValues.deserialize(json.subject as INotificationContextValuesJson)
          res.retention = NotificationContextRetention.deserialize(json.subject as INotificationContextRetentionJson)
          break
        case NotificationsType.doxRetentionExpired:
          break
        case NotificationsType.doxDelivered:
          res.flattenDox = NotificationContextFlattenDox.deserialize(json.subject as INotificationContextFlattenDoxJson)
          break
        case NotificationsType.doxReceived:
          res.flattenDox = NotificationContextFlattenDox.deserialize(json.subject as INotificationContextFlattenDoxJson)
          break
        case NotificationsType.invitationPermitted:
          res.permission = NotificationContextPermission.deserialize(json.subject as INotificationContextPermissionJson)
          break
        case NotificationsType.invitationReceived:
          res.permission = NotificationContextPermission.deserialize(json.subject as INotificationContextPermissionJson)
          break
        case NotificationsType.invitationAccepted:
          res.invitation = NotificationContextInvitation.deserialize(json.subject as INotificationContextInvitationJson)
          break
        default:
      }
    }
    return res
  }
}
