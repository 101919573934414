import { IDataEditorHeaderProps } from './DataEditorHeader.types'
import { Box, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material'

export default function DataEditorHeader(props: IDataEditorHeaderProps) {
  return (
    <Box paddingX={2} paddingBottom={0} paddingTop={1} display="flex" flexDirection="column">
      <Grid container alignItems="center" sx={{ minHeight: '36px' }}>
        <Grid item>
          <Typography variant="body1">
            {typeof props.title === 'string' ? <strong>{props.title}</strong> : props.title}
          </Typography>
        </Grid>
        <Grid flexGrow={1} item></Grid>

        {(props.isEditMode ? props.editModeCommands : props.viewModeCommands).map((command, index) => (
          <Tooltip key={index} title={command.tooltipText}>
            <IconButton disabled={command.disabled} size="small" onClick={() => command.onClick && command.onClick()}>
              {command.icon}
            </IconButton>
          </Tooltip>
        ))}
      </Grid>
      <Divider />
    </Box>
  )
}
