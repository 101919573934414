export interface IHistoryContextConsentActionJson {
  action: string
  target: string
}

export class HistoryContextConsentAction {
  action: string = ''
  target: string = ''

  constructor() {}

  public static serialize(obj: HistoryContextConsentAction): IHistoryContextConsentActionJson {
    return {
      action: obj.action,
      target: obj.target,
    }
  }

  public static deserialize(json: IHistoryContextConsentActionJson): HistoryContextConsentAction {
    const res = new HistoryContextConsentAction()
    res.action = json.action
    res.target = json.target
    return res
  }

  public static serializeArray(objs: HistoryContextConsentAction[]): IHistoryContextConsentActionJson[] {
    const jsons = objs.map((p) => {
      return HistoryContextConsentAction.serialize(p)!
    })
    return jsons
  }

  public static deserializeArray(json: IHistoryContextConsentActionJson[]): HistoryContextConsentAction[] {
    const res = json.map((p) => {
      return HistoryContextConsentAction.deserialize(p)!
    })
    return res
  }
}
