import { IDoxesTemplatesFormProps } from './DoxesTemplatesForm.types'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import DataEditorHeader from '../dataEditorHeader/DataEditorHeader'
import msgIds from '../../locales/msgIds'
import { EditIco } from '../icons'
import { DoxesTemplates } from './DoxesTemplates'
import { DataEditorContainer } from '../dataEditorContainer/DataEditorContainer'

export function DoxesTemplatesForm(props: IDoxesTemplatesFormProps): JSX.Element {
  const { account } = props
  const { t } = useTranslation()
  const [isEditMode, setIsEditMode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  return (
    <DataEditorContainer isLoading={isLoading}>
      <DataEditorHeader
        title={t(msgIds.MSG_DOXES_TEMPLATES_TITLE)}
        isEditMode={isEditMode}
        viewModeCommands={[
          {
            commandText: '',
            icon: <EditIco />,
            onClick: () => setIsEditMode(true),
            tooltipText: t(msgIds.MSG_MODIFY) ?? undefined,
            disabled: false,
          },
        ]}
        editModeCommands={[]}
      />
      <DoxesTemplates
        account={account}
        isEditMode={isEditMode}
        onSave={() => setIsEditMode(false)}
        onCancel={() => setIsEditMode(false)}
        setIsLoading={setIsLoading}
      />
    </DataEditorContainer>
  )
}
