import { useEffect, useState } from 'react'
import { Box, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import msgIds from '../../locales/msgIds'
import { dateTimeShortOptions, Utils } from '../../shared/Utils'
import * as dalContract from '../../dal/DalContract'
import {
  ConsentRevokeCause,
  ContractVersionState,
  ContractVersionStateColorMap,
  ContractVersionStateTranslationMap,
} from '../../shared/Constants'
import { useTranslation } from 'react-i18next'
import { IContractVersionsTableProps } from './ContractVersionsTable.types'
import { ContractVersion } from '../../models/ContractVersion'
import { useAuthContext } from '../../contexts/AuthContext'
import { StyledTableRow } from '../../shared/StyledControls'

export function ContractVersionsTable(props: IContractVersionsTableProps): JSX.Element {
  const { t, i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const authContext = useAuthContext()
  const [consentsHistory, setConsentsHistory] = useState<ContractVersion[]>([])

  const grantorProfileId = props.grantorProfileId || authContext.loggedProfileId

  useEffect(() => {
    const loadConsentsSnapshots = async (abortSignal: AbortSignal, grantorProfileId: number) => {
      try {
        setIsLoading(true)
        const consentsHistory = await dalContract.getContractConsents(abortSignal, {
          grantorProfileId: grantorProfileId,
          contractId: props.contractId,
          getSectionsText: true,
        })
        const sortedConsentsHistory = consentsHistory.sort(ContractVersion.compareConsentsDateTime)
        // To consistently represent the state of consent, delete revokedAt info if not caused by user action
        sortedConsentsHistory.forEach((snapshot) => {
          snapshot.sections.forEach((section) => {
            if (section.consent?.revokedAt && section.consent?.revokeCause === ConsentRevokeCause.automatically) {
              section.consent.revokedAt = undefined
            }
          })
        })

        setConsentsHistory(sortedConsentsHistory)
      } catch (err) {
        Utils.enqueueSnackbarError2(err, t)
      } finally {
        setIsLoading(false)
      }
    }

    const abortController = new AbortController()
    if (grantorProfileId) {
      loadConsentsSnapshots(abortController.signal, grantorProfileId)
    }

    return () => {
      abortController.abort()
    }
  }, [props.contractId, grantorProfileId])

  {
    isLoading && (
      <Box sx={{ display: 'flex', justifyContent: 'center', minHeight: '50px' }}>
        <CircularProgress color="primary" />
      </Box>
    )
  }

  return (
    <Table sx={{ minWidth: '100%' }}>
      <TableHead>
        <TableRow>
          <TableCell>{t(msgIds.MSG_CONTRACT_VERSION)}</TableCell>
          <TableCell>{t(msgIds.MSG_CONTRACT_STATE)}</TableCell>
          <TableCell>{t(msgIds.MSG_CONTRACT_VERSION_VIEWER_CONSENT_DATE)}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {consentsHistory &&
          consentsHistory.map((version) => (
            <StyledTableRow
              key={`${version.grantedConsentsDateTime}${version.revokedConsentsDateTime}`}
              onClick={() => props.onClickVersion && props.onClickVersion(version)}
              sx={{
                cursor: props.onClickVersion ? 'pointer' : undefined,
                '&:last-child td, &:last-child th': { border: 0 },
              }}
              hover={true}
            >
              <TableCell>
                {t(msgIds.MSG_CONTRACT_VERSION)} {version?.getVersionString()}
              </TableCell>

              <TableCell sx={{ color: ContractVersionStateColorMap[version.state || ContractVersionState.none] }}>
                {t(ContractVersionStateTranslationMap[version.state || ContractVersionState.none])}
              </TableCell>
              <TableCell>{Utils.toLocaleDateString(version.consentsDate, i18n, dateTimeShortOptions)}</TableCell>
            </StyledTableRow>
          ))}
      </TableBody>
    </Table>
  )
}
