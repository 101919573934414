export interface IHistoryContextPermissionViewDoxJson {
  id: number
  name: string
  owner_profile_id: number
  target_profile_id: number
}

export class HistoryContextPermissionViewDox {
  id: number = 0
  name: string = ''
  ownerProfileId: number = 0
  targetProfileId: number = 0

  constructor() {}

  public static serialize(obj: HistoryContextPermissionViewDox): IHistoryContextPermissionViewDoxJson {
    return {
      id: obj.id,
      name: obj.name,
      owner_profile_id: obj.ownerProfileId,
      target_profile_id: obj.targetProfileId,
    }
  }

  public static deserialize(json: IHistoryContextPermissionViewDoxJson): HistoryContextPermissionViewDox {
    const res = new HistoryContextPermissionViewDox()
    res.id = json.id
    res.name = json.name
    res.ownerProfileId = json.owner_profile_id
    res.targetProfileId = json.target_profile_id
    return res
  }
}
