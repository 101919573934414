import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function FlagItaly(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 13.229166 8.9958333">
      <g transform="matrix(0.00440972,0,0,-0.00449792,0,8.9958377)">
        <path
          d="M 1000,0 H 0 V 2000 H 1000 V 0"
          style={{ fill: '#009246', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
        />
        <path
          d="M 2000,0 H 1000 V 2000 H 2000 V 0"
          style={{ fill: '#ffffff', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
        />
        <path
          d="M 3000,0 H 2000 V 2000 H 3000 V 0"
          style={{ fill: '#ce2b37', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
        />
      </g>
    </SvgIcon>
  )
}
