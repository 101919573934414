export interface ITotpSecretJson {
  secret: string
  qrcode: string
}

export class TotpSecret {
  secret: string = ''
  qrcode: string = ''

  constructor() {}

  public static serialize(obj: TotpSecret): ITotpSecretJson {
    return {
      secret: obj.secret,
      qrcode: obj.qrcode,
    }
  }

  public static deserialize(json: ITotpSecretJson): TotpSecret {
    const res = new TotpSecret()
    res.secret = json.secret
    res.qrcode = json.qrcode
    return res
  }
}
