import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function AuthorizationIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 18.509766,2.0104963 a 2.16547,2.16547 0 0 0 -2.166504,2.1687012 v 1.7314454 h -0.432861 a 0.86870998,0.86870998 0 0 0 -0.865724,0.8657226 v 4.3308105 a 0.86873998,0.86873998 0 0 0 0.865724,0.865723 h 5.19873 a 0.86875999,0.86875999 0 0 0 0.865722,-0.865723 V 6.7763655 A 0.86872999,0.86872999 0 0 0 21.109131,5.9106429 H 20.67627 V 4.1791975 A 2.16547,2.16547 0 0 0 18.509766,2.0104963 Z M 9.3735351,2.0302717 A 2.07764,2.07764 0 0 0 7.3894042,2.9201643 2.047635,2.047635 0 0 0 6.6071778,2.7707503 2.08336,2.08336 0 0 0 4.5241699,4.853758 V 5.0866683 A 2.2770649,2.2770649 0 0 0 4.1088867,5.0449203 2.08334,2.08334 0 0 0 2.0258788,7.127928 V 18.66577 a 3.3311949,3.3311949 0 0 0 3.3310548,3.331055 h 5.4580074 a 3.3522349,3.3522349 0 0 0 2.406006,-1.023926 l 6.082031,-6.339112 -1.724853,-1.524902 a 1.6677499,1.6677499 0 0 0 -1.883057,-0.224121 l -2.006103,1.065674 V 5.994139 A 2.08334,2.08334 0 0 0 11.605957,3.9111312 2.29584,2.29584 0 0 0 11.179687,3.9528792 2.07764,2.07764 0 0 0 9.3735351,2.0302717 Z m 9.1362309,0.8481446 a 1.299285,1.299285 0 0 1 1.298583,1.3007812 V 5.9106429 H 17.211181 V 4.1791975 A 1.299285,1.299285 0 0 1 18.509766,2.8784163 Z M 9.1054687,3.6782209 A 0.41652,0.41652 0 0 1 9.5229492,4.0957015 V 12.001463 H 11.188477 V 5.994139 a 0.417765,0.417765 0 0 1 0.41748,-0.4152832 0.4125,0.4125 0 0 1 0.415283,0.4152832 v 10.722656 l 4.449463,-2.366455 0.424073,0.373535 -4.882325,5.091065 a 1.638355,1.638355 0 0 1 -1.19751,0.516357 H 5.3569336 A 1.671,1.671 0 0 1 3.6914062,18.66577 V 7.127928 a 0.41651749,0.41651749 0 0 1 0.8327637,0 v 4.873535 H 6.1896972 V 4.853758 a 0.41748053,0.41748053 0 0 1 0.834961,0 v 7.147705 H 8.6901855 V 4.0957015 A 0.41652,0.41652 0 0 1 9.1054687,3.6782209 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
