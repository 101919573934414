export type DocumentRevisionContentType = string | ArrayBuffer

export interface IDocumentRevisionJson {
  revision_id: number
  document_id: number
  name: string
  description: string
  edited_at: string
  creator_identity: string
  archived_at: string
  filename: string
  size: number
  mimetype: string
  checksum: string
  created_at: string
  updated_at: string
  content: DocumentRevisionContentType
}

export class DocumentRevision {
  revisionId: number = 0
  documentId: number = 0
  name: string = ''
  description: string = ''
  editedAt: string = ''
  creatorIdentity: string = ''
  archivedAt?: Date
  filename: string = ''
  size: number = 0
  mimetype: string = ''
  checksum: string = ''
  createdAt: Date = new Date(0)
  updatedAt: Date = new Date(0)
  content: DocumentRevisionContentType = ''

  constructor() {}

  public static clone(original: DocumentRevision) {
    const cloned = new DocumentRevision()
    cloned.revisionId = original.revisionId
    cloned.documentId = original.documentId
    cloned.name = original.name
    cloned.description = original.description
    cloned.editedAt = original.editedAt
    cloned.creatorIdentity = original.creatorIdentity
    cloned.archivedAt = original.archivedAt ? new Date(original.archivedAt) : undefined
    cloned.filename = original.filename
    cloned.size = original.size
    cloned.mimetype = original.mimetype
    cloned.checksum = original.checksum
    cloned.createdAt = new Date(original.createdAt)
    cloned.updatedAt = new Date(original.updatedAt)
    if (typeof original.content === 'string') {
      cloned.content = original.content
    } else if (original.content instanceof ArrayBuffer) {
      cloned.content = original.content.slice(0) // create deep copy
    }
    return cloned
  }

  public static serialize(obj: DocumentRevision): IDocumentRevisionJson {
    return {
      revision_id: obj.revisionId,
      document_id: obj.documentId,
      name: obj.name,
      description: obj.description,
      edited_at: obj.editedAt,
      creator_identity: obj.creatorIdentity,
      archived_at: obj.archivedAt ? obj.archivedAt.toISOString() : '',
      filename: obj.filename,
      size: obj.size,
      mimetype: obj.mimetype,
      checksum: obj.checksum,
      created_at: obj.createdAt.toISOString(),
      updated_at: obj.updatedAt.toISOString(),
      content: obj.content,
    }
  }

  public static deserialize(json: IDocumentRevisionJson): DocumentRevision {
    const res = new DocumentRevision()
    res.revisionId = json.revision_id
    res.documentId = json.document_id
    res.name = json.name
    res.description = json.description
    res.editedAt = json.edited_at
    res.creatorIdentity = json.creator_identity
    res.archivedAt = json.archived_at ? new Date(json.archived_at) : undefined
    res.filename = json.filename
    res.size = json.size
    res.mimetype = json.mimetype
    res.checksum = json.checksum
    res.createdAt = new Date(json.created_at)
    res.updatedAt = new Date(json.updated_at)
    res.content = json.content
    return res
  }

  public static getContentLength(content: DocumentRevisionContentType) {
    if (!content) {
      return 0
    }
    if (typeof content === 'string') {
      return content.length
    }
    if (content instanceof ArrayBuffer || ArrayBuffer.isView(content)) {
      const buffer = content as ArrayBuffer
      return buffer.byteLength
    }
    if (Object.hasOwn(content, 'size')) {
      const buffer = content as Blob
      return buffer.size
    }
    if (Object.hasOwn(content, 'length')) {
      const buffer = content as any
      return buffer.length
    }
    return 0
  }

  public static serializeArray(objs: DocumentRevision[]): IDocumentRevisionJson[] {
    const jsons = objs.map((p) => {
      return DocumentRevision.serialize(p)!
    })
    return jsons
  }

  public static deserializeArray(json: IDocumentRevisionJson[]): DocumentRevision[] {
    const res = json.map((p) => {
      return DocumentRevision.deserialize(p)!
    })
    return res
  }
}
