export interface IDashboardItemContextNoteJson {
  schema: number
  text: string
  customerProfileId: number
  bkColor: string
}

export class DashboardItemContextNote {
  schema: number = 0
  text: string = ''
  customerProfileId: number = 0
  bkColor: string = ''

  constructor() {}

  public static serialize(obj: DashboardItemContextNote): IDashboardItemContextNoteJson {
    return {
      schema: obj.schema,
      text: obj.text,
      customerProfileId: obj.customerProfileId,
      bkColor: obj.bkColor,
    }
  }

  public static deserialize(json: IDashboardItemContextNoteJson): DashboardItemContextNote {
    const res = new DashboardItemContextNote()
    res.schema = json.schema
    res.text = json.text
    res.customerProfileId = json.customerProfileId
    res.bkColor = json.bkColor
    return res
  }
}
