export interface INotificationStatsJson {
  unread: number
}

export class NotificationStats {
  unread: number = 0

  constructor() {}

  public static serialize(obj: NotificationStats): INotificationStatsJson {
    return {
      unread: obj.unread,
    }
  }

  public static deserialize(json: INotificationStatsJson): NotificationStats {
    const res = new NotificationStats()
    res.unread = json.unread
    return res
  }
}
