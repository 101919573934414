import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function MoreVertIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 11.999268,3.9990218 A 2.0000001,2.0000001 0 0 0 9.9997561,6.0007309 2.0000001,2.0000001 0 0 0 11.999268,8.0002427 2.0000001,2.0000001 0 0 0 14.000977,6.0007309 2.0000001,2.0000001 0 0 0 11.999268,3.9990218 Z m 0,6.0007326 a 2.0000001,2.0000001 0 0 0 -1.9995119,1.9995116 2.0000001,2.0000001 0 0 0 1.9995119,2.001709 2.0000001,2.0000001 0 0 0 2.001709,-2.001709 2.0000001,2.0000001 0 0 0 -2.001709,-1.9995116 z m 0,6.0007326 a 2.0000001,2.0000001 0 0 0 -1.9995119,1.999512 2.0000001,2.0000001 0 0 0 1.9995119,1.999512 2.0000001,2.0000001 0 0 0 2.001709,-1.999512 2.0000001,2.0000001 0 0 0 -2.001709,-1.999512 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
