import { useEffect, useMemo, useState } from 'react'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import * as dalAccount from '../../dal/DalAccount'
import * as dalInvitation from '../../dal/DalInvitation'
import * as dalPermission from '../../dal/DalPermission'
import { IAccountCardPageProps } from './AccountCardPage.types'
import { Utils, dateTimeShortOptions } from '../../shared/Utils'
import {
  AccountType,
  ActionType,
  ContractType,
  InvitationPurposeType,
  ProfileType,
  isCollaborator,
  isConsumer,
  isOperator,
  isStructure,
} from '../../shared/Constants'
import AccountInfoStructure from '../../components/accountInfoStructure/AccountInfoStructure'
import AccountInfoOperator from '../../components/accountInfoOperator/AccountInfoOperator'
import AccountInfoCollaborator from '../../components/accountInfoCollaborator/AccountInfoCollaborator'
import AccountInfoCustomer from '../../components/accountInfoCustomer/AccountInfoCustomer'
import { useLocation, useNavigate } from 'react-router'
import { Account } from '../../models/Account'
import { PageContainer, PageContent } from '../../components/pageContainer/PageContainer'
import CommandBar from '../../components/commandBar/CommandBar'
import msgIds from '../../locales/msgIds'
import { useAuthContext } from '../../contexts/AuthContext'
import {
  ChronologyIco,
  CollaboratorsIco,
  DocSharedIco,
  EditIco,
  InvitationIco,
  PrivacyIco,
  StructureIco,
} from '../../components/icons'
import { Invitation } from '../../models/Invitation'
import { ISimpleDialogData } from '../../dialogs/simpleDialog/SimpleDialog.types'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'
import { ICommand } from '../../components/commandBar/CommandBar.types'
import { Permission } from '../../models/Permission'
import AccountLoadingIndicator from '../../components/loadingIndicators/AccountLoadingIndicator'
import { useSnackbar } from 'notistack'

export default function AccountCardPage() {
  const location = useLocation()
  const state = location.state as IAccountCardPageProps
  const profileId = state.profileId

  const { i18n, t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const authContext = useAuthContext()
  const [isLoading, setIsLoading] = useState(false)
  const [account, setAccount] = useState<Account>()
  const [activeInvitation, setActiveInvitation] = useState<Invitation>()
  const [inviteProfilePermissions, setInviteProfilePermission] = useState<Permission>()

  // dialogs
  const [simpleDialogData, setSimpleDialogData] = useState<ISimpleDialogData>()
  const [simpleDialogOpen, setSimpleDialogOpen] = useState(false)

  useEffect(() => {
    const loadAccount = async (abortSignal: AbortSignal) => {
      try {
        setIsLoading(true)
        const account = await dalAccount.getAccountFromProfileId(abortSignal, profileId, true, true)
        setAccount(account)

        if (isStructure(account.profile?.type) && account.profile && authContext.loggedProfileId) {
          const invitation = await dalInvitation.getActiveInvitation(
            abortSignal,
            account.profile?.profileId,
            authContext.loggedProfileId
          )
          setActiveInvitation(invitation)
        }

        if (isConsumer(authContext.loggedProfileType) && account.profile?.profileId) {
          const permission = await dalPermission.getInviteProfilePermissions(abortSignal, account.profile?.profileId)
          setInviteProfilePermission(permission)
        }
      } catch (err) {
        Utils.enqueueSnackbarError2(err, t)
      } finally {
        setIsLoading(false)
      }
    }

    const abortController = new AbortController()
    if (profileId) {
      loadAccount(abortController.signal)
    }

    return () => {
      abortController.abort()
    }
  }, [profileId, authContext.loggedProfileId])

  function showStructurePrivacyPolicy() {
    if (account) {
      navigate(`/contracts/versions/viewer`, {
        state: {
          grantorProfileId: authContext.loggedProfileId,
          targetProfileType: ProfileType.customer,
          contractOwnerProfileId: account.profile?.profileId,
          contractType: ContractType.privacyPolicy,
          isEditable: true,
        },
      })
    }
  }

  function showConsumerPrivacyPolicy() {
    if (account) {
      navigate(`/contracts/versions/viewer`, {
        state: {
          grantorProfileId: account.profile?.profileId,
          targetProfileType: ProfileType.customer,
          contractOwnerProfileId: authContext.linkedStructureProfileId,
          contractType: ContractType.privacyPolicy,
          isEditable: account.user?.accountType === AccountType.placeholderUser,
        },
      })
    }
  }

  function showAccountHistory() {
    if (account) {
      navigate('/history', {
        state: {
          historyType: 'account',
          targetProfileId: account.profile?.profileId,
        },
      })
    }
  }

  function editConsumerAccount() {
    if (account) {
      navigate(`/account_editor`, {
        state: {
          detailsOwnerProfileId: account.profile?.profileId,
          profileId: profileId,
        },
      })
    }
  }

  function editCollaboratorAccount() {
    if (account && account.profile?.profileId && authContext.loggedProfileId) {
      let detailsOwnerProfileId = 0
      if (isConsumer(authContext.loggedProfileType)) {
        detailsOwnerProfileId = authContext.loggedProfileType
      } else if (isOperator(authContext.loggedProfileType)) {
        if (account.profile?.profileId && authContext.loggedProfileId !== account.profile?.profileId) {
          detailsOwnerProfileId = account.profile?.profileId
        } else {
          detailsOwnerProfileId = authContext.loggedProfileId
        }
      }

      navigate(`/account_editor`, {
        state: {
          detailsOwnerProfileId: detailsOwnerProfileId,
          profileId: profileId,
        },
      })
    }
  }

  function showLinkedStructure() {
    if (account?.linkedAccount) {
      navigate(`/account_card`, {
        state: {
          profileId: account.linkedAccount.profile?.profileId,
        },
      })
    }
  }

  function showAccountAuthorizations() {
    if (account) {
      navigate(`/account_authorizations`, {
        state: { profileId: account.profile?.profileId },
      })
    }
  }

  function sendOrRevokeInvitation() {
    if (!account?.profile) return

    if (activeInvitation) {
      setSimpleDialogOpen(true)
      setSimpleDialogData({
        title: t(msgIds.MSG_INVITATION_REVOKE_TITLE),
        content: t(msgIds.MSG_INVITATION_REVOKE_CONFIRM),
        actionsStyle: 'yesNO',
        onClose: async (result) => {
          setSimpleDialogOpen(false)
          if (result.userChoice !== 'yes') return
          await revokeInviteForCollaboration()
        },
      })
    } else {
      setSimpleDialogOpen(true)
      setSimpleDialogData({
        title: t(msgIds.MSG_INVITATION_SEND_TITLE),
        content: t(msgIds.MSG_INVITATION_SEND_CONFIRM),
        actionsStyle: 'yesNO',
        onClose: async (result) => {
          setSimpleDialogOpen(false)
          if (result.userChoice !== 'yes') return
          await sendInviteForCollaboration()
        },
      })
    }
  }

  async function sendInviteForCollaboration() {
    if (!account?.profile) return

    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const invitation = await dalInvitation.createInvitation(
        abortController.signal,
        account.profile?.profileId,
        InvitationPurposeType.collaborate
      )
      setActiveInvitation(invitation)
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  async function revokeInviteForCollaboration() {
    if (!activeInvitation) return

    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const invitation = await dalInvitation.revokeInvitation(abortController.signal, activeInvitation?.id)
      setActiveInvitation((p) => {
        return {
          ...activeInvitation,
          revokedAt: new Date(invitation.revokedAt!),
          updatedAt: new Date(invitation.updatedAt),
        } as Invitation
      })
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  function acceptInvitation() {
    if (!activeInvitation) return

    setSimpleDialogOpen(true)
    setSimpleDialogData({
      title: t(msgIds.MSG_INVITATION_ACCEPTANCE_TITLE),
      content: t(msgIds.MSG_INVITATION_ACCEPTANCE_CONFIRM),
      actionsStyle: 'yesNO',
      onClose: async (result) => {
        setSimpleDialogOpen(false)
        if (result.userChoice !== 'yes') return
        await acceptInviteForCollaboration()
      },
    })
  }

  async function acceptInviteForCollaboration() {
    if (!activeInvitation) return

    try {
      setIsLoading(true)
      const abortController = new AbortController()
      await dalInvitation.acceptInvitation(abortController.signal, activeInvitation?.id)
      setActiveInvitation(undefined)
      enqueueSnackbar(t(msgIds.MSG_INVITATION_SENDED_SUCCESSFULLY), { variant: 'success' })
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  function authorizeInvitation() {
    if (inviteProfilePermissions && !inviteProfilePermissions.isExpired) {
      setSimpleDialogOpen(true)
      setSimpleDialogData({
        title: t(msgIds.MSG_INVITATION_AUTHORIZE_TITLE),
        content: `${t(msgIds.MSG_INVITATION_AUTHORIZATION_IMPOSSIBLE)} ${Utils.toLocaleDateString(
          inviteProfilePermissions.endAt,
          i18n,
          dateTimeShortOptions
        )}`,
        actionsStyle: 'Ok',
        onClose: async (result) => {
          setSimpleDialogOpen(false)
        },
      })
    } else {
      setSimpleDialogOpen(true)
      setSimpleDialogData({
        title: t(msgIds.MSG_INVITATION_AUTHORIZE_TITLE),
        content: t(msgIds.MSG_INVITATION_AUTHORIZE_CONFIRM),
        actionsStyle: 'yesNO',
        onClose: async (result) => {
          setSimpleDialogOpen(false)
          if (result.userChoice !== 'yes') return
          if (inviteProfilePermissions) {
            reactivateInviteProfilePermission()
          } else {
            createInviteProfilePermission()
          }
        },
      })
    }
  }

  async function createInviteProfilePermission() {
    if (!account?.profile?.profileId || !authContext.loggedProfileId) return

    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const permission = await dalPermission.createPermission(abortController.signal, {
        profileId: account?.profile?.profileId,
        action: ActionType.inviteProfiles,
        targetId: authContext.loggedProfileId,
        startAt: new Date(),
      })
      setInviteProfilePermission(permission)
      notifyAuthorizeInviteExecuted()
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  async function reactivateInviteProfilePermission() {
    if (!account?.profile?.profileId || !authContext.loggedProfileId || !inviteProfilePermissions) return

    try {
      setIsLoading(true)
      const now = new Date()
      const abortController = new AbortController()
      const permission = await dalPermission.updatePermission(
        abortController.signal,
        inviteProfilePermissions?.id,
        now,
        Utils.daysFromDateTime(now, 3, false) as Date
      )
      setInviteProfilePermission(permission)
      notifyAuthorizeInviteExecuted()
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  function notifyAuthorizeInviteExecuted() {
    setSimpleDialogOpen(true)
    setSimpleDialogData({
      title: t(msgIds.MSG_INVITATION_AUTHORIZE_TITLE),
      content: t(msgIds.MSG_INVITATION_AUTHORIZATION_EXECUTED),
      actionsStyle: 'Ok',
      onClose: async (result) => {
        setSimpleDialogOpen(false)
      },
    })
  }

  function pageTitle(): string {
    if (isLoading) {
      return t(msgIds.MSG_LOADING_IN_PROGRESS)
    }
    switch (account?.profile?.type) {
      case ProfileType.structure:
        return msgIds.MSG_STRUCTURE
      case ProfileType.operatorAdmin:
      case ProfileType.operatorExt:
      case ProfileType.operatorInt:
        return msgIds.MSG_OPERATOR
      case ProfileType.customer:
        return msgIds.MSG_USER
      default:
        return ''
    }
  }

  const commandBarCommands = useMemo(() => {
    const commands: ICommand[] = []
    if (!account || isLoading) {
      return commands
    }
    if (isConsumer(account?.profile?.type)) {
      if (isOperator(authContext.loggedProfileType)) {
        commands.push(
          {
            commandText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_PRIVACY_POLICY),
            tooltipText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_PRIVACY_POLICY),
            icon: <PrivacyIco />,
            onClick: () => {
              showConsumerPrivacyPolicy()
            },
          },
          {
            commandText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_OPERATORS),
            tooltipText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_OPERATORS),
            icon: <CollaboratorsIco />,
            onClick: () => {
              // TODO: show list of collaborators that can view customer
            },
          },
          {
            commandText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_HISTORY),
            tooltipText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_HISTORY),
            icon: <ChronologyIco />,
            onClick: () => {
              showAccountHistory()
            },
          },
          {
            commandText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_MODIFY),
            tooltipText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_MODIFY),
            icon: <EditIco />,
            onClick: () => {
              editConsumerAccount()
            },
          }
        )

        if (
          account.user?.accountType !== AccountType.placeholderUser &&
          (authContext.loggedAccount?.canDo(ActionType.createInvitations) ||
            authContext.loggedAccount?.canDo(ActionType.updateInvitations))
        ) {
          commands.splice(1, 0, {
            commandText: activeInvitation
              ? t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_REVOKE_INVITATION)
              : t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_SEND_INVITATION),
            tooltipText: activeInvitation
              ? t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_REVOKE_INVITATION)
              : t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_SEND_INVITATION),
            icon: <InvitationIco />,
            onClick: () => {
              sendOrRevokeInvitation()
            },
          })
        }
      }
    } else if (isOperator(account?.profile?.type)) {
      if (isConsumer(authContext.loggedProfileType)) {
        commands.push(
          {
            commandText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_LINKED_STRUCTURE),
            tooltipText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_LINKED_STRUCTURE),
            icon: <StructureIco />,
            onClick: () => {
              showLinkedStructure()
            },
          },
          {
            commandText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_HISTORY),
            tooltipText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_HISTORY),
            icon: <ChronologyIco />,
            onClick: () => {
              showAccountHistory()
            },
          }
        )
      } else if (isOperator(authContext.loggedProfileType)) {
        if (
          account.linkedAccount?.profile?.profileId === authContext.linkedStructureProfileId &&
          (authContext.loggedAccount?.canDo(ActionType.createOperatorPermissions) ||
            authContext.loggedAccount?.canDo(ActionType.deleteOperatorPermissions) ||
            (authContext.loggedAccount?.canDo(ActionType.deleteOperatorPermissions) &&
              account.profile?.profileId == authContext.loggedProfileId))
        ) {
          commands.push({
            commandText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_AUTHORIZATIONS),
            tooltipText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_AUTHORIZATIONS),
            icon: <PrivacyIco />,
            onClick: () => {
              showAccountAuthorizations()
            },
          })
        }
        if (
          account.user?.accountType !== AccountType.placeholderUser &&
          account.profile.linkedProfileId !== authContext.linkedStructureProfileId &&
          (authContext.loggedAccount?.canDo(ActionType.createInvitations) ||
            authContext.loggedAccount?.canDo(ActionType.updateInvitations))
        ) {
          commands.splice(0, 0, {
            commandText: activeInvitation
              ? t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_REVOKE_INVITATION)
              : t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_SEND_INVITATION),
            tooltipText: activeInvitation
              ? t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_REVOKE_INVITATION)
              : t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_SEND_INVITATION),
            icon: <InvitationIco />,
            onClick: () => {
              sendOrRevokeInvitation()
            },
          })
        }
        if (
          (authContext.loggedProfileType === ProfileType.operatorAdmin ||
            authContext.loggedAccount?.canDo(ActionType.updateCollaboratorProfiles)) &&
          account.linkedAccount?.profile?.profileId === authContext.linkedStructureProfileId
        ) {
          commands.push({
            commandText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_MODIFY),
            tooltipText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_MODIFY),
            icon: <EditIco />,
            onClick: () => {
              editCollaboratorAccount()
            },
          })
        }
        if (
          authContext.loggedProfileType === ProfileType.operatorAdmin &&
          account.linkedAccount?.profile?.profileId === authContext.linkedStructureProfileId
        ) {
          commands.push({
            commandText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_HISTORY),
            tooltipText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_HISTORY),
            icon: <ChronologyIco />,
            onClick: () => {
              showAccountHistory()
            },
          })
        }
      }
    } else if (isStructure(account?.profile?.type)) {
      if (isConsumer(authContext.loggedProfileType)) {
        commands.push({
          commandText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_PRIVACY_POLICY),
          tooltipText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_PRIVACY_POLICY),
          icon: <PrivacyIco />,
          onClick: () => {
            showStructurePrivacyPolicy()
          },
        })
        if (activeInvitation) {
          commands.push({
            commandText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_ACCEPT_INVITATION),
            tooltipText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_ACCEPT_INVITATION),
            icon: <InvitationIco />,
            disabled: !activeInvitation,
            onClick: () => {
              acceptInvitation()
            },
          })
        }
        commands.push(
          {
            commandText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_AUTHORIZE_INVITATION),
            tooltipText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_AUTHORIZE_INVITATION),
            icon: <InvitationIco />,
            onClick: () => {
              authorizeInvitation()
            },
          },
          {
            commandText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_SHARED_DOCUMENTS),
            tooltipText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_SHARED_DOCUMENTS),
            icon: <DocSharedIco />,
            onClick: () => {
              // TODO: show portion of archive shared by customer
            },
          },
          {
            commandText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_HISTORY),
            tooltipText: t(msgIds.MSG_ACCOUNT_CARD_PAGE_COMMAND_HISTORY),
            icon: <ChronologyIco />,
            onClick: () => {
              showAccountHistory()
            },
          }
        )
      }
    }

    return commands
  }, [t, account, isLoading, authContext.loggedProfileType, activeInvitation, inviteProfilePermissions])

  return (
    <PageContainer>
      <CommandBar style={{ minHeight: 'auto' }} title={t(pageTitle())} commands={commandBarCommands} />
      {simpleDialogData && <SimpleDialog {...simpleDialogData} isOpen={simpleDialogOpen}></SimpleDialog>}

      {isLoading ? (
        <AccountLoadingIndicator account={account} isLoading={isLoading} />
      ) : (
        <PageContent>
          {account && account.profile && (
            <Stack spacing={8} width="100%" maxWidth="md" alignItems="stretch">
              {account && isStructure(account.profile?.type) && <AccountInfoStructure account={account} />}
              {account && isOperator(account.profile?.type) && !isCollaborator(account.profile?.type) && (
                <AccountInfoOperator account={account} />
              )}
              {account && isCollaborator(account.profile?.type) && <AccountInfoCollaborator account={account} />}
              {account && isConsumer(account.profile?.type) && <AccountInfoCustomer account={account} />}{' '}
            </Stack>
          )}
        </PageContent>
      )}
    </PageContainer>
  )
}
