import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function AddIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 18.999999,12.999998 h -5.999998 v 5.999998 H 10.999999 V 12.999998 H 5.0000012 V 10.999995 H 10.999999 V 4.9999979 h 2.000002 v 5.9999971 h 5.999998 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
