export interface INotificationContextSubscriptionJson {
  id: number
  trial_end_at?: string
}

export class NotificationContextSubscription {
  id: number = 0
  trialEndAt?: Date

  constructor() {}

  public static serialize(obj: NotificationContextSubscription): INotificationContextSubscriptionJson {
    return {
      id: obj.id,
      trial_end_at: obj.trialEndAt?.toISOString(),
    }
  }

  public static deserialize(json: INotificationContextSubscriptionJson): NotificationContextSubscription {
    const res = new NotificationContextSubscription()
    res.id = json.id
    res.trialEndAt = json.trial_end_at ? new Date(json.trial_end_at) : undefined
    return res
  }
}
