import { addDays, addWeeks, addYears, addMonths } from 'date-fns'
import { TFunction, i18n } from 'i18next'
import msgIds from '../locales/msgIds'
import dayjs from 'dayjs'
import axios, { AxiosError } from 'axios'
import { HostApiError } from './types/HostApiError'
import log from './Logger'
import { enqueueSnackbar } from 'notistack'
import CryptoJS from 'crypto-js'
import crypto from 'crypto'
import { Account } from '../models/Account'
import * as dalDox from '../dal/DalDox'
import * as dalPermission from '../dal/DalPermission'
import * as dalProduct from '../dal/DalProduct'
import * as dalPrices from '../dal/DalPrice'
import * as dalTaxRates from '../dal/DalTaxRate'
import * as dalContract from '../dal/DalContract'
import * as dalConsent from '../dal/DalConsent'
import { Document } from '../models/Document'
import { Dox } from '../models/Dox'
import {
  ActionType,
  AppType,
  ArchiveItemSourceType,
  ConsentMode,
  ContractAcceptanceStatus,
  ContractType,
  ContractVersionState,
  ProfileType,
  RetentionDuration,
  RetentionDurationTranslationMap,
  RetentionDurationTranslationMapPlural,
  RetentionDuration_all,
  TreatedFieldsTranslationMap,
  isConsumer,
  isOperator,
} from './Constants'
import { ArchiveDoxes } from './types/ArchiveDoxes'
import { Treatment } from '../models/Treatment'
import { ArchiveTypes } from '../models/ArchiveTypes'
import { TreatedDataTypes } from '../models/TreatedDataTypes'
import { DoxTemplate } from '../models/DoxTemplate'
import { IContextState } from '../contexts/AuthContext'
import { DocumentRevision } from '../models/DocumentRevision'
import { DragEvent } from 'react'
import { IProductJson, Product } from '../models/Product'
import { Consent } from '../models/Consent'
import { Contract } from '../models/Contract'
import { AccountLegalStatus } from '../models/AccountLegalStatus'
import { getTargetProductProfileType } from '../pages/ProductsPage/ProductsPage.types'
import { Stream } from 'stream'
import { v4 as uuidv4 } from 'uuid'

export enum ValType {
  notNull = 1,
  notEmpty = 2,
  lenghtEqualThan = 3,
  shorterOrEqualThan = 4,
  longherOrEqualThan = 5,
  contiansUppsercase = 6,
  containsNumber = 7,
  withoutBlank = 8,
  lowerOrEqualThan = 9,
  higherOrEqualThan = 10,
}

export type ValRule = {
  type: ValType
  value1?: number
  value2?: number
}

export enum ValFieldType {
  email = 1,
  fiscalCode = 2,
  vatNumber = 3,
  password = 4,
  text = 5,
  date = 6,
  sdiCode = 7,
}

export const dateShortOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
}

export const dateTimeShortOptions = {
  ...dateShortOptions,
  hour: '2-digit',
  minute: '2-digit',
}

export class Utils {
  // ------------------------------------------------
  // Date and time helpers
  // ------------------------------------------------

  /**
   * Return the input value without the time part.
   *
   * @param {date}
   * @param {toISOString}
   */
  public static toDate = (date: string | Date, toISOString: boolean = false) => {
    let _date = new Date(date).toISOString().slice(0, 10)
    return toISOString ? _date : new Date(_date)
  }

  /**
   * Return date formatted.
   * Have a look to Utils module for ready to use options
   *
   * @param {Date | string | null | undefine} date
   * @param locale
   * @param options
   */
  public static toLocaleDateString = (
    date: Date | string | null | undefined,
    locale: i18n,
    options: any = dateShortOptions
  ): string | null | undefined => {
    if (!date) {
      return date
    }
    return locale.t(msgIds.MSG_DATE, {
      val: new Date(date),
      formatParams: { val: options },
    })
  }

  /**
   * Return a dateTime value of today and without the time part.
   *
   * @param {toISOString}
   */
  public static today = (toISOString: boolean = false) => {
    const _date = new Date()
    return Utils.toDate(_date, toISOString)
  }

  /**
   * Return a timeless date shifted by days from input dateTime
   *
   * @param {date}
   * @param {shift}
   * @param {toISOString}
   */
  public static daysFromDate = (date: string | Date, shift: number, toISOString: boolean = false) => {
    let _date = new Date(date)
    if (shift) {
      _date = addDays(_date, shift)
    }
    return Utils.toDate(_date, toISOString)
  }

  /**
   * Return a timeless date shifted by weeks from input dateTime
   *
   * @param {date}
   * @param {shift}
   * @param {toISOString}
   */
  public static weeksFromDate = (date: string | Date, shift: number, toISOString: boolean = false) => {
    let _date = new Date(date)
    if (shift) {
      _date = addWeeks(_date, shift)
    }
    return Utils.toDate(_date, toISOString)
  }

  /**
   * Return a timeless date shifted by months from input dateTime
   *
   * @param {date}
   * @param {shift}
   * @param {toISOString}
   */
  public static monthsFromDate = (date: string | Date, shift: number, toISOString: boolean = false) => {
    let _date = new Date(date)
    if (shift) {
      _date = addMonths(_date, shift)
    }
    return Utils.toDate(_date, toISOString)
  }

  /**
   * Return a timeless date shifted by years from input dateTime
   *
   * @param {date}
   * @param {shift}
   * @param {toISOString}
   */
  public static yearsFromDate = (date: string | Date, shift: number, toISOString: boolean = false) => {
    let _date = new Date(date)
    if (shift) {
      _date = addYears(_date, shift)
    }
    return Utils.toDate(_date, toISOString)
  }

  /**
   * Return a dateTime shifted by days from input dateTime
   *
   * @param {date}
   * @param {shift}
   * @param {toISOString}
   */
  public static daysFromDateTime = (date: string | Date, shift: number, toISOString: boolean = false) => {
    let _date = new Date(date)
    if (shift) {
      _date = addDays(_date, shift)
    }
    return toISOString ? _date.toISOString() : _date
  }

  /**
   * Return a dateTime shifted by weeks from input dateTime
   *
   * @param {date}
   * @param {shift}
   * @param {toISOString}
   */
  public static weeksFromDateTime = (date: string | Date, shift: number, toISOString: boolean = false) => {
    let _date = new Date(date)
    if (shift) {
      _date = addWeeks(_date, shift)
    }
    return toISOString ? _date.toISOString() : _date
  }

  /**
   * Return a dateTime shifted by months from input dateTime
   *
   * @param {date}
   * @param {shift}
   * @param {toISOString}
   */
  public static monthsFromDateTime = (date: string | Date, shift: number, toISOString: boolean = false) => {
    let _date = new Date(date)
    if (shift) {
      _date = addMonths(_date, shift)
    }
    return toISOString ? _date.toISOString() : _date
  }

  /**
   * Return a dateTime shifted by years from input dateTime
   *
   * @param {date}
   * @param {shift}
   * @param {toISOString}
   */
  public static yearsFromDateTime = (date: string | Date, shift: number, toISOString: boolean = false) => {
    let _date = new Date(date)
    if (shift) {
      _date = addYears(_date, shift)
    }
    return toISOString ? _date.toISOString() : _date
  }

  /**
   * Return a timeless dateTime shifted by days from now
   *
   * @param {shift}
   * @param {toISOString}
   */
  public static daysFromToday = (shift: number, toISOString: boolean = false) => {
    let _date = new Date()
    return Utils.daysFromDate(_date, shift, toISOString)
  }

  /**
   * Return a timeless dateTime shifted by weeks from now
   *
   * @param {shift}
   * @param {toISOString}
   */
  public static weeksFromToday = (shift: number, toISOString: boolean = false) => {
    let _date = new Date()
    return Utils.weeksFromDate(_date, shift, toISOString)
  }

  /**
   * Return a timeless dateTime shifted by months from now
   *
   * @param {shift}
   * @param {toISOString}
   */
  public static monthsFromToday = (shift: number, toISOString: boolean = false) => {
    let _date = new Date()
    return Utils.monthsFromDate(_date, shift, toISOString)
  }

  /**
   * Return a timeless dateTime shifted by years from now
   *
   * @param {shift}
   * @param {toISOString}
   */
  public static yearsFromToday = (shift: number, toISOString: boolean = false) => {
    let _date = new Date()
    return Utils.yearsFromDate(_date, shift, toISOString)
  }

  public static validateDates = (start: string | undefined, end: string | undefined, matchType: string) => {
    let isvalid = true
    if (!start || !end) {
      isvalid = false
    }
    if (start && end) {
      const startDate = new Date(start).getTime()
      const endDate = new Date(end).getTime()
      switch (matchType) {
        case 'minor':
          isvalid = startDate < endDate
          break
        case 'minorOrEqual':
          isvalid = startDate <= endDate
          break
        case 'major':
          isvalid = startDate > endDate
          break
        case 'majorOrEqual':
          isvalid = startDate >= endDate
          break
        default:
          isvalid = startDate < endDate
      }
    }
    return isvalid ? undefined : msgIds.MSG_VAL_ERR_WRONG_DATE_RANGE
  }

  public static wait = (milliseconds: number) => {
    return new Promise((res) => setTimeout(res, milliseconds))
  }

  public static daysDifference(startAt: Date, endAt?: Date): number {
    const start = dayjs(startAt)
    const end = endAt ? dayjs(endAt) : dayjs()
    const diffInDays = end.diff(start, 'day')
    return diffInDays
  }

  // ------------------------------------------------
  // Currency helpers
  // ------------------------------------------------

  public static toCurrencyFormat = (amount: number, symbol: boolean, digits: number) => {
    if (symbol) {
      return amount.toLocaleString('it', { style: 'currency', currency: 'EUR', minimumFractionDigits: digits })
    } else {
      return amount.toLocaleString('it', { minimumFractionDigits: digits })
    }
  }

  // ------------------------------------------------
  // File system helpers
  // ------------------------------------------------

  public static removeFileExtension(filename: string) {
    const lastDotIndex = filename.lastIndexOf('.')
    return lastDotIndex !== -1 ? filename.slice(0, lastDotIndex) : filename
  }

  public static displayByteSize(size: number | null | undefined): string {
    if (size === undefined || size === null) {
      return '-'
    }
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
    if (size === 0) return '0 B'
    const i = parseInt(Math.floor(Math.log(size) / Math.log(1024)).toString(), 10)
    const formattedSize = (size / Math.pow(1024, i)).toFixed(1)
    return `${formattedSize} ${sizes[i]}`
  }

  // public static stream2buffer(stream: Stream) {
  //   return new Promise((resolve, reject) => {
  //     const _buf: Uint8Array[] = []
  //     stream.on('data', (chunk) => _buf.push(chunk))
  //     stream.on('end', () => resolve(Buffer.concat(_buf)))
  //     stream.on('error', (err) => reject(err))
  //   })
  // }

  public static getTextMD5Checksum(text: string) {
    const wordArray = CryptoJS.enc.Utf16LE.parse(text)
    const hash = CryptoJS.MD5(wordArray).toString()
    return hash
  }

  public static getBinaryMD5Checksum(buffer: ArrayBuffer) {
    const uint8Array = new Uint8Array(buffer)
    var numberArray: number[] = []
    for (var i = 0; i < uint8Array.byteLength; i++) {
      numberArray[i >>> 2] |= uint8Array[i] << (24 - (i % 4) * 8)
    }
    const wordArray = CryptoJS.lib.WordArray.create(numberArray, uint8Array.length)
    const hash = CryptoJS.MD5(wordArray).toString(CryptoJS.enc.Hex)
    return hash
  }

  public static getStreamMD5Checksum(stream: Stream): Promise<string> {
    // code copyed from:
    // https://stackoverflow.com/questions/18658612/obtaining-the-hash-of-a-file-using-the-stream-capabilities-of-crypto-module-ie
    return new Promise((resolve, reject) => {
      const hash = crypto.createHash('md5')
      stream.on('error', (err) => reject(err))
      stream.on('data', (chunk) => hash.update(chunk))
      stream.on('end', () => resolve(hash.digest('hex')))
    })
  }

  public static getTextSHA256Checksum(text: string) {
    const wordArray = CryptoJS.enc.Utf16LE.parse(text)
    const hash = CryptoJS.SHA256(wordArray).toString()
    return hash
  }

  public static getBinarySHA256Checksum(buffer: ArrayBuffer) {
    const uint8Array = new Uint8Array(buffer)
    const numberArray: number[] = []
    for (let i = 0; i < uint8Array.byteLength; i++) {
      numberArray[i >>> 2] |= uint8Array[i] << (24 - (i % 4) * 8)
    }

    const wordArray = CryptoJS.lib.WordArray.create(numberArray, uint8Array.length)
    const hash = CryptoJS.SHA256(wordArray).toString(CryptoJS.enc.Hex)
    return hash
  }

  public static getStreamSHA256Checksum(stream: Stream): Promise<string> {
    return new Promise((resolve, reject) => {
      const hash = crypto.createHash('sha256')
      stream.on('error', (err) => reject(err))
      stream.on('data', (chunk) => hash.update(chunk))
      stream.on('end', () => resolve(hash.digest('hex')))
    })
  }

  // ------------------------------------------------
  // Text helpers
  // ------------------------------------------------

  public static validateJsonText(text: string) {
    if (typeof text === 'string') {
      try {
        const value = JSON.parse(text)
        return value
      } catch (err) {
        return null
      }
    }
    return null
  }

  // ------------------------------------------------
  // Parsing helpers
  // ------------------------------------------------

  public static getBoolean(value: boolean | string) {
    if (typeof value === 'string') {
      return value.toLowerCase() === 'true'
    } else {
      return value
    }
  }

  public static getIntArray(value: number[]) {
    if (typeof value === 'string') {
      const intValues = []
      const strValues = String(value).split(',')
      for (const strValue of strValues) {
        if (strValue !== '') {
          const intValue = parseInt(strValue, 10)
          intValues.push(intValue)
        }
      }
      return intValues
    } else {
      const array = Array.isArray(value) ? value : [value]
      return array
    }
  }

  public static getIntValue(value: number | string) {
    if (typeof value === 'string') {
      const intValue = parseInt(value, 10)
      return intValue
    } else {
      return value
    }
  }

  // ------------------------------------------------
  // Array helpers
  // ------------------------------------------------

  public static getArrayUnion<T>(arr1: T[], arr2: T[]) {
    if (arr1 && arr2) {
      const union = Array.from(new Set([...arr1, ...arr2]))
      return union
    } else {
      return []
    }
  }

  public static getArrayIntersection<T>(arr1: T[], arr2: T[]) {
    if (arr1 && arr2) {
      const intersection = arr1.filter((p) => arr2.includes(p))
      return intersection
    } else {
      return []
    }
  }

  public static getArrayDifference<T>(arr1: T[], arr2: T[]) {
    if (arr1 && arr2) {
      const difference = arr1.filter((p) => !arr2.includes(p))
      return difference
    } else if (arr1 && !arr2) {
      return arr1
    } else {
      return []
    }
  }

  public static arrayBufferToBinary(arrayBuffer: ArrayBuffer) {
    const data = new Uint8Array(arrayBuffer)
    let binary = ''
    for (let i = 0; i < data.length; i++) {
      binary += String.fromCharCode(data[i])
    }
    return binary
  }

  public static findCommonAndMissingElements<T>(collections: T[][]): { common: T[]; missing: T[] } {
    // Elements present in all collections
    const commonElements: T[] = []
    // Items not present in at least one collection
    const missingElements: T[] = []

    if (collections.length === 0) {
      return { common: commonElements, missing: missingElements }
    }

    // Element occurrence map
    const occurrenceMap = new Map<T, number>()

    // Counting occurrences of each element across collections
    collections.forEach((collection) => {
      const uniqueElements = Array.from(new Set(collection))
      uniqueElements.forEach((element) => {
        occurrenceMap.set(element, (occurrenceMap.get(element) || 0) + 1)
      })
    })

    // Find common elements across all collections
    occurrenceMap.forEach((value, key) => {
      if (value === collections.length) {
        commonElements.push(key)
      } else {
        missingElements.push(key)
      }
    })

    return { common: commonElements, missing: missingElements }
  }

  public static incrementProgressive(strings: string[], prefix: string): string {
    const regex = new RegExp(`^${prefix}(?: \\((\\d+)\\))?$`)

    const filteredStrings = strings.filter((str) => str.startsWith(prefix))

    const groupedStrings: number[] = []

    // Group the strings based on the initial part
    filteredStrings.forEach((str) => {
      const match = str.match(regex)
      if (match) {
        const currentProgressive = match[1] ? parseInt(match[1], 10) : 0
        groupedStrings.push(currentProgressive)
      }
    })

    // Increment the maximum progressive number for the group
    const maxProgressive = Math.max(...groupedStrings)
    const newProgressive = maxProgressive + 1
    const newString = `${prefix}${newProgressive > 0 ? ` (${newProgressive})` : ''}`

    return newString
  }

  public static getUniqueStr(strs: string[], str: string) {
    let maxNum = 0

    // Find higher num on format 'str<num>'
    strs.forEach((item) => {
      const regex = new RegExp(`^${str}(?:\\s\\((\\d+)\\))?$`)
      const match = item.match(regex)
      if (match) {
        const numero = parseInt(match[1], 10)
        if (!isNaN(numero) && numero > maxNum) {
          maxNum = numero
        }
      }
    })

    const newNum = maxNum + 1
    return str + ' (' + newNum.toString() + ')'
  }

  // ------------------------------------------------
  // Enum helpers
  // ------------------------------------------------

  public static getEnumText<T extends object, U>(enumObj: T, value: U) {
    const indexOfS = Object.values(enumObj).indexOf(value as unknown as T)
    return Object.keys(enumObj)[indexOfS]
  }

  public static enumToArray<T extends object>(
    enumObj: T,
    t?: TFunction<'translation'>,
    translationMap: { [key: string]: string } = {}
  ) {
    const keys = Object.keys(enumObj)
    return Object.keys(keys)
      .filter((_, i) => i < keys.length / 2)
      .map((s, i) => ({
        value: keys[i],
        label: (t ? t(translationMap[keys[i]]) : null) || keys[i + keys.length / 2],
      }))
  }

  public static getErrorMessageId(err: any): string {
    if (axios.isAxiosError(err)) {
      const axiosErr = err as AxiosError

      if (axiosErr.response?.data) {
        const apiError = axiosErr.response?.data as HostApiError
        switch (apiError.message) {
          case 'Forbidden':
            return msgIds.MSG_ERR_FORBIDDEN
        }
        return 'MSG_' + apiError.message + '_DSC'
      }
    }

    return err.code !== 'ERR_CANCELED' ? msgIds.MSG_AN_ERROR_HAS_OCCURRED : ''
  }

  // ------------------------------------------------
  // Validation helpers
  // ------------------------------------------------

  static validateEmail = (value: string | undefined | null, rules: ValRule[]) => {
    for (const rule of rules) {
      if (rule.type === ValType.notNull && (value === undefined || value === null)) {
        return msgIds.MSG_VAL_ERR_REQUIRED_FIELD
      } else if (rule.type === ValType.notEmpty && value === '') {
        return msgIds.MSG_VAL_ERR_REQUIRED_FIELD
      }
    }
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (re.test(String(value).toLowerCase())) {
      return undefined
    } else {
      return msgIds.MSG_VAL_ERR_WRONG_MAIL_FORMAT
    }
  }

  static validateWebsiteUrl = (value: string | undefined | null, rules: ValRule[]) => {
    for (const rule of rules) {
      if (rule.type === ValType.notNull && (value === undefined || value === null)) {
        return msgIds.MSG_VAL_ERR_REQUIRED_FIELD
      } else if (rule.type === ValType.notEmpty && value === '') {
        return msgIds.MSG_VAL_ERR_REQUIRED_FIELD
      }
    }

    const re = /^(ftp|http|https):\/\/[^ "]+$/

    if (re.test(String(value).toLowerCase())) {
      return undefined
    } else {
      return msgIds.MSG_VAL_ERR_WRONG_WEBSITE_FORMAT
    }
  }

  static validatePhoneNumber = (value: string | undefined | null, rules: ValRule[]) => {
    for (const rule of rules) {
      if (rule.type === ValType.notNull && (value === undefined || value === null)) {
        return msgIds.MSG_VAL_ERR_REQUIRED_FIELD
      } else if (rule.type === ValType.notEmpty && value === '') {
        return msgIds.MSG_VAL_ERR_REQUIRED_FIELD
      }
    }

    const re = /^[0-9\s\.\-\+]+$/

    if (re.test(String(value))) {
      return undefined
    } else {
      return msgIds.MSG_VAL_ERR_WRONG_PHONE_FORMAT
    }
  }

  static validateFiscalCode = (value: string | undefined | null, rules: ValRule[]) => {
    for (const rule of rules) {
      if (rule.type === ValType.notNull && (value === undefined || value === null)) {
        return msgIds.MSG_VAL_ERR_REQUIRED_FIELD
      } else if (rule.type === ValType.notEmpty && value === '') {
        return msgIds.MSG_VAL_ERR_REQUIRED_FIELD
      }
    }
    const re =
      /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i
    if (re.test(String(value).toLowerCase())) {
      return undefined
    } else {
      return msgIds.MSG_VAL_ERR_WRONG_FISCAL_CODE_FORMAT
    }
  }

  static validateVatNumber = (value: string | undefined | null, rules: ValRule[]) => {
    for (const rule of rules) {
      if (rule.type === ValType.notNull && (value === undefined || value === null)) {
        return msgIds.MSG_VAL_ERR_REQUIRED_FIELD
      } else if (rule.type === ValType.notEmpty && value === '') {
        return msgIds.MSG_VAL_ERR_REQUIRED_FIELD
      }
    }
    if (value) {
      const oddMap = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9]
      var total = 0
      for (let i = 0; i < 11; i += 2) {
        total += +value[i]
      }
      for (let i = 1; i < 11; i += 2) {
        total += oddMap[+value[i]]
      }
      if (total % 10 === 0) {
        return undefined
      } else {
        return msgIds.MSG_VAL_ERR_WRONG_VAT_NUMBER_FORMAT
      }
    }
  }

  static validateSdiCode = (value: string | undefined | null, rules: ValRule[]) => {
    for (const rule of rules) {
      if (rule.type === ValType.notNull && (value === undefined || value === null)) {
        return msgIds.MSG_VAL_ERR_REQUIRED_FIELD
      } else if (rule.type === ValType.notEmpty && value === '') {
        return msgIds.MSG_VAL_ERR_REQUIRED_FIELD
      }
    }
    if (value && value.length === 7) {
      return undefined
    } else {
      return msgIds.MSG_VAL_ERR_WRONG_SDI_CODE_FORMAT
    }
  }

  static validatePassword = (value: string | undefined | null, rules: ValRule[]) => {
    for (const rule of rules) {
      if (rule.type === ValType.notNull && (value === undefined || value === null)) {
        return msgIds.MSG_VAL_ERR_REQUIRED_FIELD
      } else if (rule.type === ValType.notEmpty && value === '') {
        return msgIds.MSG_VAL_ERR_REQUIRED_FIELD
      } else if (value && rule.type === ValType.longherOrEqualThan && value.length < rule.value1!) {
        return msgIds.MSG_VAL_ERR_TOO_SHORT + `${rule.value1}`
      } else if (value && rule.type === ValType.contiansUppsercase && !value.match(/[A-Z]/)) {
        return msgIds.MSG_VAL_ERR_MUST_CONTAIN_UPPERCASE
      } else if (value && rule.type === ValType.containsNumber && !value.match(/[0-9]/)) {
        return msgIds.MSG_VAL_ERR_MUST_CONTAIN_NUMBER
      } else if (value && rule.type === ValType.withoutBlank && value.match(/\s/)) {
        return msgIds.MSG_VAL_ERR_CANNOT_CONTAIN_SPACES
      }
    }
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z])(?=.*[!%@-]).{8,64}$/i
    if (re.test(String(value).toLowerCase())) {
      return undefined
    } else {
      return msgIds.MSG_VAL_ERR_WRONG_PASSWORD_FORMAT
    }
  }

  static validateText = (value: string | undefined | null, rules: ValRule[]): string | undefined => {
    for (const rule of rules) {
      if (rule.type === ValType.notNull && (value === undefined || value === null)) {
        return msgIds.MSG_VAL_ERR_REQUIRED_FIELD
      } else if (rule.type === ValType.notEmpty && value === '') {
        return msgIds.MSG_VAL_ERR_REQUIRED_FIELD
      } else if (value && rule.type === ValType.lenghtEqualThan && value.length !== rule.value1) {
        return msgIds.MSG_VAL_ERR_LENGTH_MUST_BE + `${rule.value1}`
      } else if (value && rule.type === ValType.shorterOrEqualThan && value.length > rule.value1!) {
        return msgIds.MSG_VAL_ERR_MAX_LENGTH_MUST_BE + `${rule.value1}`
      } else if (value && rule.type === ValType.longherOrEqualThan && value.length < rule.value1!) {
        return msgIds.MSG_VAL_ERR_MIN_LENGTH_MUST_BE + `${rule.value1}`
      }
    }
    return undefined
  }

  static validateNumber = (value: number | undefined | null, rules: ValRule[]): string | undefined => {
    for (const rule of rules) {
      if (rule.type === ValType.notNull && (value === undefined || value === null)) {
        return msgIds.MSG_VAL_ERR_REQUIRED_FIELD
      } else if (value && rule.type === ValType.shorterOrEqualThan && value > rule.value1!) {
        return msgIds.MSG_VAL_ERR_MIN_MUST_BE + `${rule.value1}`
      } else if (value && rule.type === ValType.longherOrEqualThan && value < rule.value1!) {
        return msgIds.MSG_VAL_ERR_MAX_MUST_BE + `${rule.value1}`
      }
    }
    return undefined
  }

  static validateDate = (value: string | undefined | null, rules: ValRule[]) => {
    for (const rule of rules) {
      if (rule.type === ValType.notNull && (value === undefined || value === null)) {
        return msgIds.MSG_VAL_ERR_REQUIRED_FIELD
      } else if (rule.type === ValType.notEmpty && value === '') {
        return msgIds.MSG_VAL_ERR_REQUIRED_FIELD
      }
    }
    if (value === 'Invalid Date') {
      return msgIds.MSG_VAL_ERR_INVALID_DATE
    }
    return undefined
  }

  public static computeValidationError = (
    value: string | undefined | null,
    fieldType: ValFieldType,
    translator: TFunction<'translation', undefined, 'translation'>,
    rules: ValRule[]
  ) => {
    let error: string | undefined = undefined
    switch (fieldType) {
      case ValFieldType.email:
        error = this.validateEmail(value, rules)
        break
      case ValFieldType.fiscalCode:
        error = this.validateFiscalCode(value, rules)
        break
      case ValFieldType.vatNumber:
        error = this.validateVatNumber(value, rules)
        break
      case ValFieldType.sdiCode:
        error = this.validateSdiCode(value, rules)
        break
      case ValFieldType.password:
        error = this.validatePassword(value, rules)
        break
      case ValFieldType.text:
        error = this.validateText(value, rules)
        break
      case ValFieldType.date:
        error = this.validateDate(value, rules)
        break
    }
    const localizedError = error ? translator(error) : error
    return { hasError: !error, localizedError: localizedError }
  }

  public static isValidField = (
    value: string | undefined | null,
    fieldType: ValFieldType,
    setError: (err: string | undefined) => void,
    translator: TFunction<'translation', undefined, 'translation'>,
    rules: ValRule[]
  ) => {
    const err = this.computeValidationError(value, fieldType, translator, rules)
    setError(err.localizedError)
    return err.hasError
  }

  public static isValidField2 = (
    value: string | undefined | null,
    fieldType: ValFieldType,
    errors: any,
    errorName: string,
    translator: TFunction<'translation', undefined, 'translation'>,
    rules: ValRule[]
  ) => {
    const err = this.computeValidationError(value, fieldType, translator, rules)
    errors[errorName] = !!err.localizedError ? err.localizedError : undefined
    return err.hasError
  }

  // ------------------------------------------------
  //
  // ------------------------------------------------

  public static findIndexByAttribute(list: any[], attribute: string, value: any): number {
    return list.findIndex((obj) => obj[attribute] === value)
  }

  public static sleep(ms: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(resolve, ms)
    })
  }

  public static generateGUID() {
    return uuidv4()
  }

  // ------------------------------------------------
  // Data fetch helpers
  // ------------------------------------------------

  public static CheckAborted(abortSignal: AbortSignal, funcName: string): boolean {
    if (abortSignal.aborted) {
      log.debug(`[${funcName} aborted]`)
      return true
    }
    return false
  }

  public static enqueueSnackbarError(t: any, bypass: boolean = false) {
    return (err: any) => {
      const show = !bypass && err?.code !== 'ERR_CANCELED'
      if (!show) {
        const msgId: string = Utils.getErrorMessageId(err)
        return this.enqueueSnackbarErrorMgs(msgId, t, bypass)
      }
    }
  }

  public static enqueueSnackbarError2(err: any, t: any, bypass: boolean = false) {
    const msgId: string = Utils.getErrorMessageId(err)
    return this.enqueueSnackbarErrorMgs(msgId, t, bypass)
  }

  public static enqueueSnackbarErrorMgs(msgId: string, t: any, bypass: boolean = false) {
    if (msgId) {
      let errorMessage: string = (msgIds as any)[msgId]
      if (!errorMessage) {
        console.warn(`[enqueueSnackbarError]: il codice di errore ${msgId} non è mappato nei msgIds`)
        errorMessage = t(msgIds.MSG_AN_ERROR_HAS_OCCURRED)
      }
      return !bypass && enqueueSnackbar(t(errorMessage), { variant: 'error' })
    }
  }

  public static downloadBlob(blob: Blob, filename: string, anchorId?: string) {
    let el
    if (anchorId) {
      el = document.getElementById(anchorId)
    } else {
      el = document.createElement('a')
      el.style.display = 'none'
      document.body.appendChild(el)
    }
    if (!el) {
      log.error({ missingFakeDownloadAnchor: anchorId })
      return
    }
    const objectUrl = URL.createObjectURL(blob)
    const a = el as HTMLAnchorElement
    a.href = objectUrl
    a.download = filename
    a.target = '_blank' // for mobile that doesn't support download
    a.click()
    // needed?
    // URL.revokeObjectURL(objectUrl)
  }

  // ------------------------------------------------
  // Business logic helpers
  // ------------------------------------------------

  public static authorizeStructureToViewElement(
    element: Dox | Document,
    structure: Account,
    defaultViewDurationDays: number = 14
  ) {
    // TODO
    console.log({ authorizeStructureToViewElement: structure })
    if (!structure.profile?.profileId) {
      throw new Error('undefined structToAdd.profile?.profileId')
    }
    const startDate = new Date()
    const endDate = Utils.daysFromDate(startDate, defaultViewDurationDays, false) as Date
    const isDox = element instanceof Dox
    const actionType = isDox ? ActionType.viewDox : ActionType.viewDocuments
    const targetId = isDox ? (element as Dox).id : (element as Document).documentId
    const abortController = new AbortController()
    return dalPermission.createPermission(abortController.signal, {
      profileId: structure.profile?.profileId,
      action: actionType,
      targetId: targetId,
      startAt: startDate,
      endAt: endDate,
    })
  }

  public static async applyNewDoxSelections(
    loggedProfileType: ProfileType,
    rwArchiveDoxes: ArchiveDoxes,
    docSelection: Document[],
    addedDoxIds: number[],
    removedDoxIds: number[],
    applyChangesNow: boolean,
    stopRetentionIfNeeded: boolean
  ) {
    // if in the doxes to be assigned there is the archive root, in the case of the operator,
    // it returns an error on the backend side if I try to assign the root and it doesn't
    // has a data retention. In this specific case I avoid commanding the root association as
    // the backend will take care of it directly but without returning an error
    let assignArchiveRootIfRequested = true
    if (isOperator(loggedProfileType)) {
      if (
        rwArchiveDoxes.root &&
        !rwArchiveDoxes.root.treatmentId &&
        addedDoxIds.length > 1 &&
        addedDoxIds.includes(rwArchiveDoxes.root.id)
      ) {
        assignArchiveRootIfRequested = false
      }
    }

    const addDoxToDocumentAssignedDoxes = (doxIds: number[]) => {
      docSelection.forEach((document) => {
        if (isConsumer(loggedProfileType)) {
          document.isInDiary = true
        }
        if (!document.doxIds) {
          document.doxIds = [...doxIds]
        } else {
          const _set = new Set(document.doxIds)
          doxIds.forEach((doxId) => !!doxId && _set.add(doxId))
          document.doxIds = Array.from(_set)
        }
      })
    }

    const removeDoxFromDocumentAssignedDoxes = (doxId: number) => {
      docSelection.forEach((document) => {
        document.doxIds = [...document.doxIds.filter((p) => p !== doxId)]
      })
    }

    const docSelectionIds = docSelection.map((p) => p.documentId)
    const abortController = new AbortController()

    const addPromises = addedDoxIds.map((doxId) => {
      const dox = rwArchiveDoxes.getDox(doxId)
      if (dox) {
        if (applyChangesNow && (!dox.isRoot || (dox.isRoot && assignArchiveRootIfRequested))) {
          return dalDox
            .addDocumentsToDox(abortController.signal, doxId, docSelectionIds)
            .then(() => {
              const _doxIds = rwArchiveDoxes.root?.id ? [rwArchiveDoxes.root?.id, doxId] : [doxId]
              addDoxToDocumentAssignedDoxes(_doxIds)
            })
            .catch((err) => {
              throw err
            })
        } else {
          return new Promise<void>((resolve, reject) => {
            try {
              const _doxIds = rwArchiveDoxes.root?.id ? [rwArchiveDoxes.root?.id, doxId] : [doxId]
              addDoxToDocumentAssignedDoxes(_doxIds)
              resolve()
            } catch (err) {
              reject(err)
            }
          })
        }
      }
    })

    const removePromises = removedDoxIds.map((doxId) => {
      if (applyChangesNow) {
        return dalDox
          .removeDocumentsFromDox(abortController.signal, doxId, docSelectionIds, stopRetentionIfNeeded)
          .then(() => {
            removeDoxFromDocumentAssignedDoxes(doxId)
          })
          .catch((err) => {
            throw err
          })
      } else {
        return new Promise<void>((resolve, reject) => {
          try {
            removeDoxFromDocumentAssignedDoxes(doxId)
            resolve()
          } catch (err) {
            reject(err)
          }
        })
      }
    })

    const promises: Promise<void>[] = [...addPromises, ...removePromises].filter((p) => !!p) as Promise<void>[]
    await Promise.all(promises)
  }

  public static async loadAvailableAndReadonlyDoxes(
    abortSignal: AbortSignal,
    archiveType: ArchiveTypes,
    profileId: number | undefined,
    grantorProfileId: number | undefined,
    t: any
  ) {
    const rwArchiveDoxes = new ArchiveDoxes()
    const roArchiveDoxes = new ArchiveDoxes()
    if (archiveType === ArchiveTypes.structureArchiveForCustomer && profileId) {
      // fetch doxes owned by the structure for which the logged profile works and referred to a specific customer
      await roArchiveDoxes.getDoxesOfCustomerSharedWithStructure(abortSignal, profileId, t, msgIds)
      await rwArchiveDoxes.getDoxesForCustomerOwnedByStructure(abortSignal, profileId, t, msgIds)
    } else if (archiveType === ArchiveTypes.structureArchiveSharedByCustomer && profileId) {
      // fetch doxes shared by a customer with the structure for which the logged operator works
      await roArchiveDoxes.getDoxesOfCustomerSharedWithStructure(abortSignal, profileId, t, msgIds)
      await rwArchiveDoxes.getDoxesForCustomerOwnedByStructure(abortSignal, profileId, t, msgIds)
    } else if (archiveType === ArchiveTypes.customerArchiveOwned) {
      // fetch doxes of logged customer archive
      await rwArchiveDoxes.getDoxesOwned(abortSignal, t, msgIds)
    } else if (archiveType === ArchiveTypes.customerArchiveReceived) {
      // fetch doxes received by logged customer profile
      await rwArchiveDoxes.getDoxesOwned(abortSignal, t, msgIds)
      await roArchiveDoxes.getDoxesReceived(abortSignal, t, msgIds)
    } else if (archiveType === ArchiveTypes.guestArchive && profileId && grantorProfileId) {
      // fetch doxes shared by structure to guest profile
      await rwArchiveDoxes.getDoxesForGuestSharedByStructure(abortSignal, profileId, grantorProfileId, t, msgIds)
    }
    return { rwArchiveDoxes, roArchiveDoxes }
  }

  public static getRetentionDurationDsc(treatment: Treatment, t: any) {
    if (treatment.retentionDurationBase === 0) return ''

    let durationBaseDsc = ''
    switch (treatment.retentionDurationBase) {
      case RetentionDuration.day: {
        durationBaseDsc = treatment.retentionDurationCount === 1 ? t(msgIds.MSG_DAY) : t(msgIds.MSG_DAYS)
        break
      }
      case RetentionDuration.week: {
        durationBaseDsc = treatment.retentionDurationCount === 1 ? t(msgIds.MSG_WEEK) : t(msgIds.MSG_WEEKS)
        break
      }
      case RetentionDuration.month: {
        durationBaseDsc = treatment.retentionDurationCount === 1 ? t(msgIds.MSG_MONTH) : t(msgIds.MSG_MONTHS)
        break
      }
      case RetentionDuration.year: {
        durationBaseDsc = treatment.retentionDurationCount === 1 ? t(msgIds.MSG_YEAR) : t(msgIds.MSG_YEARS)
        break
      }
    }

    return `${treatment.retentionDurationCount} ${durationBaseDsc}`
  }

  public static getProfileTypeDsc(t: any, profileType?: ProfileType) {
    if (profileType) {
      switch (profileType) {
        case ProfileType.customer:
          return t(msgIds.MSG_PRIVATE_USER)
        case ProfileType.operatorAdmin:
        case ProfileType.operatorInt:
        case ProfileType.operatorExt:
          return t(msgIds.MSG_OPERATOR)
      }
    } else {
      return ''
    }
  }

  public static collectTreatedFields(t: any, dataTypes: TreatedDataTypes, mandatory: boolean) {
    const fields = dataTypes.fields
      .filter((p) => p.mandatory === mandatory)
      .map((p) =>
        !!t(TreatedFieldsTranslationMap.fields[p.fieldName]).toString()
          ? t(TreatedFieldsTranslationMap.fields[p.fieldName]).toString()
          : p.fieldName
      )
      .join(', ')

    const customFields = dataTypes.customFields
      .filter((p) => p.mandatory === mandatory)
      .map((p) => p.fieldName)
      .join(', ')

    return fields && customFields ? [fields, customFields].join(', ') : fields || customFields || ''
  }

  public static formatRetentionDuration = (
    t: any,
    retentionDurationBase: RetentionDuration_all,
    retentionDurationCount: number
  ) => {
    if (retentionDurationCount > 0) {
      const translatedBase = t(
        retentionDurationCount === 1
          ? RetentionDurationTranslationMap[retentionDurationBase]
          : RetentionDurationTranslationMapPlural[retentionDurationBase]
      ).toString()
      return `${retentionDurationCount} ${translatedBase}`
    } else {
      return ''
    }
  }

  public static computeDefaultDoxName(doxes: DoxTemplate[], t: any) {
    const defaultNameBase = t(msgIds.MSG_DOXES_TEMPLATES_DOX_NAME_DEFAULT)
    let defaultName = defaultNameBase
    while (true) {
      const doxWithSameName = doxes.find((d) => d.name.trim() === defaultName)
      if (!doxWithSameName) {
        return defaultName
      }
      const matches = /\((\d+)\) *$/.exec(doxWithSameName.name)
      console.log({ matches, name: doxWithSameName.name })
      if (matches && matches[1]) {
        const count = parseInt(matches[1])
        defaultName = `${defaultNameBase} (${count + 1})`
      } else {
        defaultName = `${defaultNameBase} (2)`
      }
    }
  }

  public static computeDefaultDistinctName(
    defaultNameBase: string,
    names: string[],
    pattern: RegExp = /\((\d+)\) *$/
  ): string {
    let defaultName = defaultNameBase
    while (true) {
      const sameNameIndex = names.indexOf(defaultName)
      if (sameNameIndex === -1) {
        return defaultName
      }
      const sameName = names[sameNameIndex]
      const matches = pattern.exec(sameName)
      // console.log({ matches, name: sameName })
      if (matches && matches[1]) {
        const count = parseInt(matches[1])
        defaultName = `${defaultNameBase} (${count + 1})`
      } else {
        defaultName = `${defaultNameBase} (1)`
      }
    }
  }

  public static createDocument(
    sourceType: ArchiveItemSourceType,
    authContext: IContextState,
    t: any,
    name?: string,
    content?: string
  ) {
    const newDocument: Document = new Document()
    newDocument.sourceType = sourceType
    if (
      isOperator(authContext.loggedProfileType) &&
      authContext.linkedStructureProfileId &&
      authContext.assistedAccountProfileId
    ) {
      newDocument.ownerProfileId = authContext.linkedStructureProfileId
      newDocument.targetProfileId = authContext.assistedAccountProfileId
    } else if (isConsumer(authContext.loggedProfileType) && authContext.loggedProfileId) {
      newDocument.ownerProfileId = authContext.loggedProfileId
      newDocument.targetProfileId = authContext.loggedProfileId
    }
    if (authContext.loggedProfileId) {
      newDocument.authorProfileId = authContext.loggedProfileId
    }
    newDocument.doxIds = []

    const newRevision: DocumentRevision = new DocumentRevision()
    newRevision.name = name || t(msgIds.MSG_DOCUMENT_EDITOR_NEW_INTERNAL_DOCUMENT_NAME)
    newRevision.editedAt = new Date().toISOString()

    if (sourceType === ArchiveItemSourceType.internalSource) {
      newRevision.creatorIdentity = authContext.loggedAccount?.getIdentityInverse() ?? ''
      newRevision.mimetype = 'text/markdown:custom1'
      newRevision.content = content || ''
      newRevision.checksum = Utils.getTextSHA256Checksum(newRevision.content)
    }
    newDocument.revisions = [newRevision]
    return newDocument
  }

  public static mapFileList(fileList: FileList): File[] {
    const files: File[] = []
    for (let i = 0; i < fileList.length; i++) {
      const item = fileList.item(i)
      if (item) {
        files.push(item)
      }
    }
    return files
  }

  public static filesFromDragEvent(event: DragEvent<HTMLElement>): File[] {
    if (Array.isArray(event.dataTransfer.items)) {
      return event.dataTransfer.items.reduce((list, item) => {
        if (item.kind !== 'file') {
          return list
        }
        const file = item.getAsFile()
        list.push(file)
        return list
      }, [])
    } else {
      return Utils.mapFileList(event.dataTransfer.files)
    }
  }

  public static validatePasswordConfirmation(password: string, passwordConfirmation: string): any | null {
    const errors = {
      password: Utils.validatePassword(password, [{ type: ValType.notNull }, { type: ValType.notEmpty }]),
      passwordConfirmation: password !== passwordConfirmation ? msgIds.MSG_VAL_ERR_PASSOWRD_NOT_CONFIRMED : undefined,
    }
    if (Object.values(errors).find((e) => !!e)) {
      return errors
    } else {
      return null
    }
  }

  // ------------------------------------------------
  // Billing helpers
  // ------------------------------------------------

  public static getAppTypeFrom(appTypeName: string) {
    switch (appTypeName) {
      case 'pro':
        return AppType.business
      case 'user':
        return AppType.consumer
      case 'admin':
        return AppType.admin
      case 'tools':
        return AppType.tools
    }
  }

  public static async getAllCachedProducts(abortSignal: AbortSignal, forceReload: boolean) {
    let products: Product[] = []
    let areCachedProductsExpired = false
    if (forceReload) {
      areCachedProductsExpired = true
    } else {
      // read products from browser cache
      const cachedProductsStr = localStorage.getItem('products')
      if (!cachedProductsStr) {
        areCachedProductsExpired = true
      } else {
        let cachedProducts = JSON.parse(cachedProductsStr)
        const schema = cachedProducts.schema as string
        if (!schema) {
          areCachedProductsExpired = true
        } else {
          if (schema === '1.0') {
            const fetchDateTime = new Date(cachedProducts.fetchDateTime)
            if (new Date() > addDays(fetchDateTime, 1)) {
              areCachedProductsExpired = true
            } else {
              const productsJson = cachedProducts.products as IProductJson[]
              products = Product.deserializeArray(productsJson)
            }
          }
        }
      }
    }

    if (areCachedProductsExpired) {
      products = await dalProduct.getProducts(abortSignal)
      const prices = await dalPrices.getPrices(abortSignal)
      const taxRates = await dalTaxRates.getTaxRates(abortSignal)

      products.forEach((product) => {
        product.taxRate = taxRates.find((p) => p.metadata.isBusiness === product.metadata.isBusiness)
        product.prices = prices
          .filter((p) => p.productId === product.id)
          .sort((price1, price2) => {
            if (price1.recurring?.interval === 'month' && price2.recurring?.interval === 'year') {
              return -1
            } else if (price1.recurring?.interval === 'year' && price2.recurring?.interval === 'month') {
              return 1
            } else {
              return 0
            }
          })
      })

      // write products in browser cache
      localStorage.setItem(
        'products',
        JSON.stringify({
          schema: '1.0',
          fetchDateTime: new Date().toISOString(),
          products: Product.serializeArray(products),
        })
      )
    }

    return products
  }

  public static async getCachedProducts(abortSignal: AbortSignal, profileType: ProfileType, forceReload: boolean) {
    let products: Product[] = []

    try {
      products = await this.getAllCachedProducts(abortSignal, forceReload)
    } catch {
      products = await this.getAllCachedProducts(abortSignal, true)
    }

    return isOperator(profileType)
      ? products
          .filter((p) => p.metadata.profileType === profileType)
          .sort((a, b) => a.metadata.position - b.metadata.position)
      : products
          .filter((p) => p.metadata.profileType === profileType)
          .sort((a, b) => a.metadata.position - b.metadata.position)
  }

  public static async getProduct(abortSignal: AbortSignal, productId: string, forceReload: boolean) {
    let products: Product[] = []

    try {
      products = await this.getAllCachedProducts(abortSignal, forceReload)
    } catch {
      products = await this.getAllCachedProducts(abortSignal, true)
    }

    return products.find((p) => p.id === productId)
  }

  public static async getFreeProduct(abortSignal: AbortSignal, profileType: ProfileType, forceReload: boolean) {
    let products: Product[] = []

    try {
      products = await this.getAllCachedProducts(abortSignal, forceReload)
    } catch {
      products = await this.getAllCachedProducts(abortSignal, true)
    }

    const targetProductProfileType = getTargetProductProfileType(profileType)
    return products.find(
      (p) => p.metadata.profileType === targetProductProfileType && p.prices.find((o) => o.amount === 0)
    )
  }

  // ------------------------------------------------
  // Contract helpers
  // ------------------------------------------------

  public static async checkLegalStatus(abortSignal: AbortSignal, targetProfileType: ProfileType) {
    // ...to simulate use cases
    // const als = new AccountLegalStatus()
    // als.pdxPrivacyPolicyAcceptanceStatus = ContractAcceptanceStatus.toAccept
    // als.pdxTermsOfUseAcceptanceStatus = ContractAcceptanceStatus.accepted
    // als.pdxPrivacyDataProcessorAcceptanceStatus = ContractAcceptanceStatus.implicityAcceptedButNotConfirmed
    // als.pdxAcceptanceStatusForLoggedProfile = ContractAcceptanceStatus.toAccept
    // const cts: Contract[] = []
    // return { accountLegalStatus: als, contracts: cts }

    var consents = await dalConsent.getActiveConsentsForPersonalDoxContracts(abortSignal)
    var accountLegalStatus = await this.checkPdxContractsAcceptanceStatus(abortSignal, targetProfileType, consents)
    return {
      consents: consents,
      accountLegalStatus: accountLegalStatus,
    }
  }

  public static async checkPdxContractsAcceptanceStatus(
    abortSignal: AbortSignal,
    targetProfileType: ProfileType,
    consents: Consent[]
  ) {
    const legalStatus = new AccountLegalStatus()
    const contracts = await dalContract.getPublishedContracts(abortSignal, {
      targetProfileType: targetProfileType,
      ownerProfileId: 0,
      getSections: true,
      ignoreSectionRead: true,
    })
    const acceptanceStatusForContracts: ContractAcceptanceStatus[] = []

    let pdxPrivacyPolicyAcceptanceStatus = this.getContractAcceptanceStatus(
      ContractType.privacyPolicy,
      contracts,
      consents
    )
    legalStatus.setContractAcceptanceStatus(pdxPrivacyPolicyAcceptanceStatus, ContractType.privacyPolicy)
    acceptanceStatusForContracts.push(pdxPrivacyPolicyAcceptanceStatus)

    let pdxTermsOfUseAcceptanceStatus = this.getContractAcceptanceStatus(ContractType.termsOfUse, contracts, consents)
    legalStatus.setContractAcceptanceStatus(pdxTermsOfUseAcceptanceStatus, ContractType.termsOfUse)
    acceptanceStatusForContracts.push(pdxTermsOfUseAcceptanceStatus)

    if (targetProfileType === ProfileType.operatorAdmin) {
      let pdxPrivacyDataProcessorAcceptanceStatus = this.getContractAcceptanceStatus(
        ContractType.dataProcessor,
        contracts,
        consents
      )
      legalStatus.setContractAcceptanceStatus(pdxPrivacyDataProcessorAcceptanceStatus, ContractType.dataProcessor)
      acceptanceStatusForContracts.push(pdxPrivacyDataProcessorAcceptanceStatus)
    }

    const acceptanceStatusForLoggedProfile =
      this.evaluateContractsAcceptanceStatusForLoggedProfile(acceptanceStatusForContracts)
    legalStatus.setAcceptanceStatusForLoggedProfile(acceptanceStatusForLoggedProfile)
    return legalStatus
  }

  private static getContractAcceptanceStatus(contractType: ContractType, contracts: Contract[], consents: Consent[]) {
    var lastPublishedVersion = contracts
      .find((p) => p.type === contractType)
      ?.versions.find((p) => p.state === ContractVersionState.published)
    if (!lastPublishedVersion) return ContractAcceptanceStatus.none

    var mustAcceptSectionsIds = lastPublishedVersion.sections
      .filter((p) => p.consentMode === ConsentMode.mandatory)
      .map((p) => p.id)

    const lastPublishedVersionId = lastPublishedVersion.id
    var consentsForVersion = consents.filter((p) => p.versionId === lastPublishedVersionId && !p.revokedAt)
    var consentsForVersion_SectionsIds = consentsForVersion.map((p) => p.sectionId)

    // Questo controllo serve per intercettare i casi in cui manchino consensi per almeno una delle sezioni ad accettazione obbligatoria.
    // Questa situazione non dovrebbe mai verificarsi ma se per un qualsiasi errore dovesse accadere, in questo modo l'utente viene forzato
    // a fornire nuovi consensi.
    if (mustAcceptSectionsIds.filter((p) => !consentsForVersion_SectionsIds.some((o) => o === p)).length > 0) {
      return ContractAcceptanceStatus.toAccept
    }

    if (consentsForVersion.length > 0) {
      if (consentsForVersion.every((p) => !p.isAutoGenerated)) {
        return ContractAcceptanceStatus.accepted
      } else if (consentsForVersion.some((p) => p.isAutoGenerated && !!p.sectionReadAt)) {
        return ContractAcceptanceStatus.implicityAcceptedAndConfirmed
      } else if (consentsForVersion.some((p) => p.isAutoGenerated && !p.sectionReadAt)) {
        if (lastPublishedVersion.areNewConsentsRequired) {
          return ContractAcceptanceStatus.implicityAcceptedOptionalConsentsRequiredButNotConfirmed
        } else {
          return ContractAcceptanceStatus.implicityAcceptedButNotConfirmed
        }
      }
    }

    return ContractAcceptanceStatus.toAccept
  }

  /// <summary>
  /// Restituisce una sintesti dello stato di tutti i contratti considerando le seguenti priorità:
  /// 1: almeno uno dei contratti deve essere accetatto
  /// 2: almeno uno dei contratti è stato accettato implicitamente ed è in attesa di una conferma
  /// 3: almeno uno dei contratti è stato accettato implicitamente e poi confermato
  /// 4: tutti i contratti sono stati accettati in modo esplicito
  /// </summary>
  public static evaluateContractsAcceptanceStatusForLoggedProfile(
    contractsAcceptanceStatus: ContractAcceptanceStatus[]
  ) {
    if (contractsAcceptanceStatus.some((p) => p === ContractAcceptanceStatus.toAccept)) {
      return ContractAcceptanceStatus.toAccept
    } else if (
      contractsAcceptanceStatus.some(
        (p) => p === ContractAcceptanceStatus.implicityAcceptedOptionalConsentsRequiredButNotConfirmed
      )
    ) {
      return ContractAcceptanceStatus.implicityAcceptedOptionalConsentsRequiredButNotConfirmed
    } else if (contractsAcceptanceStatus.some((p) => p === ContractAcceptanceStatus.implicityAcceptedButNotConfirmed)) {
      return ContractAcceptanceStatus.implicityAcceptedButNotConfirmed
    } else if (contractsAcceptanceStatus.some((p) => p === ContractAcceptanceStatus.implicityAcceptedAndConfirmed)) {
      return ContractAcceptanceStatus.implicityAcceptedAndConfirmed
    } else {
      return ContractAcceptanceStatus.accepted
    }
  }

  public static async confirmImplicitContractAcceptance(
    abortSignal: AbortSignal,
    targetProfileType: ProfileType,
    legalStatus: AccountLegalStatus
  ) {
    const args: dalContract.GetPublishedContractsArgs = {
      targetProfileType: targetProfileType,
      ownerProfileId: 0,
      getSections: true,
      ignoreSectionRead: false,
    }

    if (
      legalStatus.pdxPrivacyPolicyAcceptanceStatus === ContractAcceptanceStatus.implicityAcceptedButNotConfirmed ||
      legalStatus.pdxPrivacyPolicyAcceptanceStatus ===
        ContractAcceptanceStatus.implicityAcceptedOptionalConsentsRequiredButNotConfirmed
    ) {
      await dalContract.getPublishedContracts(abortSignal, {
        ...args,
        contractType: ContractType.privacyPolicy,
      })
    }

    if (
      legalStatus.pdxTermsOfUseAcceptanceStatus === ContractAcceptanceStatus.implicityAcceptedButNotConfirmed ||
      legalStatus.pdxTermsOfUseAcceptanceStatus ===
        ContractAcceptanceStatus.implicityAcceptedOptionalConsentsRequiredButNotConfirmed
    ) {
      await dalContract.getPublishedContracts(abortSignal, {
        ...args,
        contractType: ContractType.termsOfUse,
      })
    }

    if (targetProfileType === ProfileType.operatorAdmin) {
      if (
        legalStatus.pdxPrivacyDataProcessorAcceptanceStatus ===
          ContractAcceptanceStatus.implicityAcceptedButNotConfirmed ||
        legalStatus.pdxPrivacyDataProcessorAcceptanceStatus ===
          ContractAcceptanceStatus.implicityAcceptedOptionalConsentsRequiredButNotConfirmed
      ) {
        await dalContract.getPublishedContracts(abortSignal, {
          ...args,
          contractType: ContractType.dataProcessor,
        })
      }
    }
  }
}
