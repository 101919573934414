import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { IInternalDocTemplateSelectorDialogProps } from './InternalDocTemplateSelectorDialog.types'
import { StyledDialog } from '../styledDialog/StyledDialog'
import { ViewHeader } from '../../components/viewHeader/ViewHeader'
import { InternalDocumentTemplate } from '../../models/InternalDocumentTemplate'
import { InternalDocTemplatesList } from '../../components/internalDocTemplatesList/InternalDocTemplatesList'

export default function InternalDocTemplateSelectorDialog(props: IInternalDocTemplateSelectorDialogProps) {
  const { t } = useTranslation()

  function onClickDoxTemplate(template: InternalDocumentTemplate) {
    props.onResult({ userChoice: 'yes', template: template })
  }

  return (
    <StyledDialog open={props.isOpen} onClose={() => props.onClose({ userChoice: 'abort' })}>
      <ViewHeader
        exitButtonVisible={true}
        onClickExit={() => props.onClose({ userChoice: 'abort' })}
        title={t(msgIds.MSG_DOCUMENTS_TEMPLATES_DIALOG_TITLE)}
      />
      <InternalDocTemplatesList sx={{ minHeight: 300 }} onClickTemplate={onClickDoxTemplate} />
    </StyledDialog>
  )
}
