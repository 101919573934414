import { RetentionRules, RetentionRules_all } from '../shared/Constants'

export interface IDoxTemplateJson {
  name: string
  treatment_id?: number
  retention_rules: number
  automatically_set_end_retention_date: boolean
  children: IDoxTemplateJson[]
}

export class DoxTemplate {
  name: string = ''
  treatmentId?: number
  retentionRules: RetentionRules_all = RetentionRules.none
  automaticallySetEndRetentionDate: boolean = true
  children: DoxTemplate[] = []

  constructor() {}

  public static serialize(obj: DoxTemplate): IDoxTemplateJson {
    const res: IDoxTemplateJson = {
      name: obj.name,
      treatment_id: obj.treatmentId,
      retention_rules: obj.retentionRules,
      automatically_set_end_retention_date: obj.automaticallySetEndRetentionDate,
      children: DoxTemplate.serializeArray(obj.children || []),
    }
    return res
  }

  public static deserialize(json: IDoxTemplateJson): DoxTemplate {
    const res = new DoxTemplate()
    res.name = json.name
    res.treatmentId = json.treatment_id
    res.retentionRules = json.retention_rules
    res.automaticallySetEndRetentionDate = json.automatically_set_end_retention_date
    res.children = DoxTemplate.deserializeArray(json.children || [])
    return res
  }

  public static serializeArray(objs: DoxTemplate[]): IDoxTemplateJson[] {
    const jsons = objs.map((p) => {
      return DoxTemplate.serialize(p)!
    })
    return jsons
  }

  public static deserializeArray(json: IDoxTemplateJson[]): DoxTemplate[] {
    const res = json.map((p) => {
      return DoxTemplate.deserialize(p)!
    })
    return res
  }
}
