import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function VisibilityOffIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 12,6 c 3.79,0 7.17,2.13 8.82,5.5 -0.59,1.22 -1.420001,2.27 -2.410001,3.12 l 1.41,1.41 c 1.390001,-1.23 2.49,-2.77 3.18,-4.53 C 21.27,7.11 16.999999,4 12,4 10.73,4 9.51,4.2 8.3600001,4.57 L 10.01,6.2200001 C 10.66,6.09 11.32,6 12,6 Z M 10.93,7.14 13,9.21 c 0.57,0.25 1.03,0.7100001 1.28,1.28 l 2.07,2.07 c 0.08,-0.34 0.139999,-0.7 0.139999,-1.07 C 16.5,9.01 14.48,7 12,7 11.63,7 11.28,7.05 10.93,7.14 Z M 2.01,3.87 4.69,6.55 C 3.06,7.83 1.77,9.5300001 1,11.5 2.73,15.889999 7,19 12,19 c 1.52,0 2.98,-0.290001 4.32,-0.82 L 19.74,21.6 21.15,20.19 3.42,2.4499999 Z m 7.5,7.5 2.61,2.61 C 12.08,13.99 12.04,14 12,14 10.62,14 9.5000001,12.88 9.5000001,11.5 c 0,-0.05 0.01,-0.08 0.01,-0.129999 z M 6.1100001,7.9700001 7.86,9.72 C 7.63,10.27 7.5,10.87 7.5,11.5 c 0,2.48 2.02,4.5 4.5,4.5 0.63,0 1.23,-0.130005 1.77,-0.36 l 0.98,0.98 C 13.87,16.86 12.95,16.999999 12,16.999999 8.2100001,16.999999 4.83,14.87 3.18,11.5 3.8800001,10.07 4.9,8.89 6.1100001,7.9700001 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
