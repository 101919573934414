import { Center } from '../center/Center'
import { useLayout } from '../../hooks/Layout'
import { StackProps } from '@mui/material'
import { IEditorFormContainerProps } from './EditorFormContainer.types'

export function EditorFormContainer(props: IEditorFormContainerProps & StackProps): JSX.Element {
  const { children, ...rest } = props
  const { isMobile, isTablet } = useLayout()

  function getPaddingX() {
    if (props.isInDialog) {
      return 2
    } else if (isMobile) {
      return 2
    } else if (isTablet) {
      return 8
    } else {
      return 16
    }
  }

  return (
    <Center spacing={props.spacing ?? 4} mt={4} pb={2} px={getPaddingX()} alignItems="stretch" {...rest}>
      {children}
    </Center>
  )
}
