import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function ExpandMoreIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 7.41,8.5900032 12,13.170003 16.59,8.5900032 18,10.000003 l -6,6 -6,-6 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
