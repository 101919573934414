import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function HelpIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 11.999268,1.99951 a 9.9999999,9.9999999 0 1 0 9.999756,9.999756 10.00366,10.00366 0 0 0 -9.999756,-9.999756 z m 0,1.9995117 a 7.9999999,7.9999999 0 0 1 8.000244,8.0002443 7.9977949,7.9977949 0 0 1 -8.000244,8.000244 8.0002443,8.0002443 0 0 1 0,-16.0004883 z m 0.305419,1.8457032 A 3.68841,3.68841 0 0 0 9.7932129,6.697264 2.9281449,2.9281449 0 0 0 8.7890625,8.9582503 l -0.0022,0.2526855 H 10.76001 V 8.9604475 a 1.202545,1.202545 0 0 1 0.406494,-0.9624022 1.66,1.66 0 0 1 1.138183,-0.3669435 1.39789,1.39789 0 0 1 1.087647,0.3911133 1.59346,1.59346 0 0 1 0.369141,1.1337891 2.1333649,2.1333649 0 0 1 -0.595459,1.4436038 l -1.045899,1.074463 a 4.0761999,4.0761999 0 0 0 -0.861328,1.298584 4.9344049,4.9344049 0 0 0 -0.228516,1.571045 l -0.0044,0.254882 h 1.973144 v -0.250488 a 2.4423799,2.4423799 0 0 1 0.505372,-1.669922 l 0.870117,-0.861328 A 4.2476149,4.2476149 0 0 0 15.732421,9.1076643 3.1706149,3.1706149 0 0 0 14.818359,6.7192366 3.4920649,3.4920649 0 0 0 12.304687,5.8447249 Z M 11.950928,15.971922 a 1.061685,1.061685 0 0 0 -0.703125,0.32959 1.120925,1.120925 0 0 0 -0.28125,0.77124 1.091095,1.091095 0 0 0 0.283447,0.766845 1.07711,1.07711 0 0 0 0.832764,0.316407 1.09047,1.09047 0 0 0 0.832763,-0.314209 1.07885,1.07885 0 0 0 0.290039,-0.769043 1.112105,1.112105 0 0 0 -0.285644,-0.773437 1.077,1.077 0 0 0 -0.837158,-0.327393 1.061685,1.061685 0 0 0 -0.131836,0 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
