import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { ThemeProvider } from '@mui/material'

import { useAuthContext } from '../../contexts/AuthContext'
import { getProfileBasedTheme } from '../../themes/ThemeUtils'
import { ProfileType } from '../../shared/Constants'

interface IDynamicThemeContextProps {
  setProfileType: Dispatch<SetStateAction<ProfileType | undefined>>
}

interface IDynamicThemeProviderProps {
  children: React.ReactNode
}

const DynamicThemeContext = createContext<IDynamicThemeContextProps | undefined>(undefined)
export default function DynamicThemeProvider(props: IDynamicThemeProviderProps) {
  const authContext = useAuthContext()
  const [profileType, setProfileType] = useState(authContext.loggedProfileType)
  const [theme, setTheme] = useState(getProfileBasedTheme(profileType))

  useEffect(() => {
    if (!authContext.loggedProfileType) return
    setProfileType(undefined)
  }, [authContext.loggedProfileType])

  useEffect(() => {
    const _theme = profileType ? getProfileBasedTheme(profileType) : getProfileBasedTheme(authContext.loggedProfileType)
    setTheme(_theme)
  }, [profileType, authContext.loggedProfileType])

  return (
    <DynamicThemeContext.Provider value={{ setProfileType }}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </DynamicThemeContext.Provider>
  )
}

export const useDynamicThemeContext = (): IDynamicThemeContextProps => {
  const context = useContext(DynamicThemeContext)
  if (!context) {
    throw new Error('useTourStateContext must be used within a TourStateContextProvider')
  }
  return context
}
