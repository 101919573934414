import {
  CustomerVisibilityRules,
  CustomerVisibilityRules_all,
  PDX_API_URL,
  RetentionDuration_all,
  RetentionEndBehavior_all,
  TreatmentLegalBase,
  TreatmentLegalBase_all,
} from '../shared/Constants'
import log from '../shared/Logger'
import { axiosInstance } from '../contexts/AuthContext'
import { UriComposer } from '../shared/types/UriComposer'
import { Treatment, ITreatmentJson } from '../models/Treatment'
import { TreatedDataTypes } from '../models/TreatedDataTypes'

// ********************
// GET

export function getTreatments(
  abortSignal: AbortSignal,
  ownerProfileId: number,
  active: boolean,
  onlyVisibleForDataProcessing: boolean
): Promise<Treatment[]> {
  const funcName = 'getTreatments'
  const url = UriComposer.create(`${PDX_API_URL}/v1/treatments`)
    .addParamNumber('owner_profile_id', ownerProfileId)
    .addParamBool('active', active)
    .addParamBool('visible_for_data_processing', onlyVisibleForDataProcessing)
    .getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Treatment.deserializeArray(resp.data as ITreatmentJson[])
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function getTreatment(abortSignal: AbortSignal, treatmentId: number): Promise<Treatment> {
  const funcName = 'getTreatment'
  const url = UriComposer.create(`${PDX_API_URL}/v1/treatments/${treatmentId}`).getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Treatment.deserialize(resp.data as ITreatmentJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// POST

export function createNewTreatment(
  abortSignal: AbortSignal,
  code: string,
  legalBase: TreatmentLegalBase,
  customerVisibilityRules: CustomerVisibilityRules
): Promise<Treatment> {
  const funcName = 'createNewTreatment'
  let url = `${PDX_API_URL}/v1/treatments`

  return axiosInstance
    .post(
      url,
      {
        code: code,
        legal_base: legalBase,
        customer_visibility_rules: customerVisibilityRules,
      },
      {
        headers: {
          'Accept-Version': '1.0.x',
        },
        signal: abortSignal,
      }
    )
    .then((resp) => {
      const reply = Treatment.deserialize(resp.data as ITreatmentJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// PATCH

export type UpdateTreatmentArgs = {
  legalBase?: TreatmentLegalBase_all
  retentionDurationBase?: RetentionDuration_all
  retentionDurationCount?: number
  code?: string
  shortDescription?: string
  description?: string
  retentionNoticeDaysBeforeExpiration?: number
  retentionEndBehavior?: RetentionEndBehavior_all
  customerVisibilityRules?: CustomerVisibilityRules_all
  dataTypes?: TreatedDataTypes
}

export function updateTreatment(abortSignal: AbortSignal, treatmentId: number, args: UpdateTreatmentArgs) {
  const funcName = 'updateTreatment'
  let url = `${PDX_API_URL}/v1/treatments/${treatmentId}`

  const parms = {
    legal_base: args.legalBase,
    retention_duration_base: args.retentionDurationBase,
    retention_duration_count: args.retentionDurationCount,
    code: args.code,
    short_description: args.shortDescription,
    description: args.description,
    retention_notice_days_before_expiration: args.retentionNoticeDaysBeforeExpiration,
    retention_end_behavior: args.retentionEndBehavior,
    customer_visibility_rules: args.customerVisibilityRules,
    data_types: args.dataTypes ? TreatedDataTypes.serialize(args.dataTypes) : undefined,
  }
  return axiosInstance
    .patch(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Treatment.deserialize(resp.data as ITreatmentJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

export function changeTreatmentEnabledState(
  abortSignal: AbortSignal,
  treatmentId: number,
  isEnabled: boolean
): Promise<Treatment> {
  const funcName = 'changeTreatmentEnabledState'
  let url = `${PDX_API_URL}/v1/treatments/${treatmentId}`

  return axiosInstance
    .patch(
      url,
      {
        is_enabled: isEnabled,
      },
      {
        headers: {
          'Accept-Version': '1.0.x',
        },
        signal: abortSignal,
      }
    )
    .then((resp) => {
      const reply = Treatment.deserialize(resp.data as ITreatmentJson)
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// DELETE

export function deleteTreatment(abortSignal: AbortSignal, treatmentId: number): Promise<boolean> {
  const funcName = 'deleteTreatment'
  let url = `${PDX_API_URL}/v1/treatments/${treatmentId}`

  return axiosInstance
    .delete(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = resp.data != 0
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}
