import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function CloseIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 19,6.410005 -1.41,-1.41 -5.590001,5.58999 -5.59,-5.58999 -1.41,1.41 5.59,5.58999 -5.59,5.59 1.41,1.41 5.59,-5.59 5.590001,5.59 1.41,-1.41 -5.590001,-5.59 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
