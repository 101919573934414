import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function CheckIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 8.7999999,15.910037 -4.2,-4.210939 -1.4,1.403646 5.6,5.614587 L 20.8,6.6860977 19.399999,5.2824509 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
