import { Autocomplete, TextField } from '@mui/material'
import { ISimpleAutocompleteTextFieldProps } from './SimpleAutocompleteTextField.types'

export function SimpleAutocompleteTextField<Value extends { label: string }>(
  props: ISimpleAutocompleteTextFieldProps<Value>
): JSX.Element {
  const { error, helperText, disabled, options, label, value, filterFromInputLength, noOptionsText, onChange } = props
  return (
    <Autocomplete
      filterOptions={(x, state) => {
        const inputValue = state.inputValue.toLowerCase()
        if (inputValue.length < (filterFromInputLength ?? 0)) {
          return []
        }
        return x.filter(({ label }) => label.toLowerCase().includes(inputValue))
      }}
      noOptionsText={noOptionsText}
      disablePortal
      disabled={disabled}
      options={options}
      value={value}
      onChange={(_event, country) => onChange && onChange(country)}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
            }}
            label={label}
            variant="outlined"
            error={error}
            helperText={helperText}
          />
        )
      }}
    />
  )
}
