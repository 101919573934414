export interface IContractVersionEditorPageInit {
  contractId: number
  versionId: number
}

export class ContractVersionsComparation {
  removedFields: string[] = []
  addedFields: string[] = []
  dataProcessingRemovedFields: string[] = []
  consentsRemovedFields: string[] = []
  dataProcessingAddedFields: string[] = []
  consentsAddedFields: string[] = []

  constructor() {}
}
