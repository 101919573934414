import { useState } from 'react'
import { AppBar, Box, Breakpoint, IconButton, Stack, Toolbar, Tooltip } from '@mui/material'
import msgIds from '../../locales/msgIds'
import { t } from 'i18next'
import { useUiContext } from '../../contexts/UiContext'
import { useAuthContext } from '../../contexts/AuthContext'
import { DashboardIco, HelpIco, MenuIco } from '../icons'
import { PDXLogo } from '../../components/images'
import { useNavigate } from 'react-router-dom'
import { NotificationButton } from '../notificationButton/NotificationButton'
import { NotificationsStepper } from '../notificationsStepper/NotificationsStepper'
import { StyledDialog } from '../../dialogs/styledDialog/StyledDialog'
import { TutorialsMenu } from '../tutorialsMenu/TutorialsMenu'
import { useNotificationsContext } from '../../contexts/NotificationsContext'
import TopbarSearchSelector from './TopbarSearchSelector'
import { useLayout } from '../../hooks/Layout'
import TopbarMenu from './TopbarMenu'

export default function Topbar() {
  const { isPhone } = useLayout()
  const uiContext = useUiContext()
  const authContext = useAuthContext()
  const navigate = useNavigate()
  const [showNotifications, setShowNotifications] = useState(false)
  const notificationsContext = useNotificationsContext()
  const [notificationDialogMaxWidth, setNotificationDialogMaxWidth] = useState<Breakpoint>('sm')
  const [tutorialsMenuAnchorEl, setTutorialsMenuAnchorEl] = useState<HTMLElement | null>(null)

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} elevation={0}>
        <Toolbar
          /*variant='dense'*/ sx={{
            position: 'relative',
            minHeight: '64px',
          }}
        >
          <Stack direction={'row'} width={'100%'}>
            {authContext.loggedProfileId &&
              !authContext.loggedAccount?.isGuest &&
              !authContext.isOnlyPageContentVisible && (
                <IconButton
                  className="leftbar-toggle"
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => uiContext.toggleLeftbar()}
                >
                  <MenuIco />
                </IconButton>
              )}

            <PDXLogo
              sx={{
                flexGrow: 1,
                alignSelf: 'center',
                justifyContent: 'flex-start',
                width: { xs: '20%', sm: '20%' },
                maxWidth: '203px',
                height: 'auto',
                display: 'block',
              }}
            />

            {authContext.loggedProfileId &&
              !authContext.loggedAccount?.isGuest &&
              !authContext.isOnlyPageContentVisible && (
                <>
                  <TopbarSearchSelector tourStepName="search-selector" />

                  <NotificationButton
                    className="notifications"
                    onClick={() => {
                      notificationsContext.resetNotifications()
                      setShowNotifications(true)
                    }}
                  />
                  <StyledDialog
                    open={showNotifications}
                    onClose={() => setShowNotifications(false)}
                    minHeight={100}
                    maxWidth={notificationDialogMaxWidth}
                  >
                    <NotificationsStepper
                      onGoTo={(path, state) => {
                        navigate(path, { state: state })
                        setShowNotifications(false)
                      }}
                      onClickExit={() => setShowNotifications(false)}
                      setMaxWidth={setNotificationDialogMaxWidth}
                    />
                  </StyledDialog>

                  {!isPhone && (
                    <>
                      <Tooltip title={t(msgIds.MSG_NOTICE_BOARD)}>
                        <IconButton
                          className="notice-board"
                          size="large"
                          aria-label="account of current user"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          color="inherit"
                          onClick={() => {
                            navigate('/notice_board', {})
                          }}
                        >
                          <DashboardIco color="inherit" />
                        </IconButton>
                      </Tooltip>

                      <Box>
                        <Tooltip title={t(msgIds.MSG_NOTICE_GUIDE)}>
                          <IconButton
                            className="tutorials"
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={(event) => {
                              setTutorialsMenuAnchorEl(event.currentTarget)
                            }}
                          >
                            <HelpIco color="inherit" />
                          </IconButton>
                        </Tooltip>
                        <TutorialsMenu
                          anchorEl={tutorialsMenuAnchorEl}
                          open={Boolean(tutorialsMenuAnchorEl)}
                          onClose={() => setTutorialsMenuAnchorEl(null)}
                        />
                      </Box>
                    </>
                  )}

                  <TopbarMenu tourStepName="profile-info" />
                </>
              )}
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
