import { ActionType } from '../shared/Constants'
import { Account, IAccountJson } from './Account'
import { AuthorizationAction, IAuthorizationActionJson } from './AuthorizationAction'
import { Permission } from './Permission'

export interface IAuthorizationJson {
  profile_id: number
  profile_info: IAccountJson | undefined
  actions: { [key: string]: IAuthorizationActionJson }
}

export class Authorization {
  static action_view: string = 'view'
  static action_download: string = 'download'
  static action_update: string = 'update'
  static action_delete: string = 'delete'

  profileId: number = 0
  dstIdentity: Account | undefined
  actions: { [key: string]: AuthorizationAction } = {}

  _isVisualizationAllowed: boolean = false
  get isVisualizationAllowed(): boolean {
    return this.actions[Authorization.action_view].isAllowed
  }

  _isVisualizationExpired: boolean = false
  get isVisualizationExpired(): boolean {
    return this.actions[Authorization.action_view].isExpired
  }

  _isVisualizationSuspended: boolean = false
  get isVisualizationSuspended(): boolean {
    return this.actions[Authorization.action_view].isSuspended
  }

  _isVisualizationRevoked: boolean = false
  get isVisualizationRevoked(): boolean {
    return (
      !this.actions[Authorization.action_view].isAllowed &&
      !this.actions[Authorization.action_view].isSuspended &&
      !this.actions[Authorization.action_view].isExpired
    )
  }

  _isVisualizationInherited: boolean = false
  get isVisualizationInherited(): boolean {
    // if there is a 'view_documents' permission it means that this object was created asking for permission for a document
    // and that the document was authorized directly. In this case it does not matter if there are also other permissions
    // inherited from the doxes to which the document is associated.
    if (
      this.actions[Authorization.action_view].permissions.find((p) => p.action === ActionType.viewDocuments) ||
      this.actions[Authorization.action_view].permissions.find((p) => p.action === ActionType.viewDox && !p.isInherited)
    ) {
      return false
    } else {
      return this.actions[Authorization.action_view].permissions.findIndex((p) => p.isInherited) !== -1
    }
  }

  _isDownloadPermitted: boolean = false
  get isDownloadPermitted(): boolean {
    return Authorization.action_download in this.actions && this.actions[Authorization.action_download].isAllowed
  }

  _isUpdatePermitted: boolean = false
  get isUpdatePermitted(): boolean {
    return Authorization.action_update in this.actions && this.actions[Authorization.action_update].isAllowed
  }

  _isDeletePermitted: boolean = false
  get isDeletePermitted(): boolean {
    return Authorization.action_delete in this.actions && this.actions[Authorization.action_delete].isAllowed
  }

  _permissionsIdsForVisualization: Permission[] = []
  get permissionsIdsForVisualization(): Permission[] {
    return this.actions[Authorization.action_view].permissions.filter((p) => p.action !== 'data_processing')
  }

  _dataProcessingPermission: Permission | undefined
  get dataProcessingPermission(): Permission | undefined {
    return this.actions[Authorization.action_view].permissions.filter((p) => p.action === 'data_processing')[0]
  }

  constructor() {}

  public static serialize(obj: Authorization): IAuthorizationJson {
    return {
      profile_id: obj.profileId,
      profile_info: obj.dstIdentity ? Account.serialize(obj.dstIdentity) : undefined,
      actions: AuthorizationAction.serializeDictionary(obj.actions ?? {}),
    }
  }

  public static deserialize(json: IAuthorizationJson): Authorization {
    const res = new Authorization()
    res.profileId = json.profile_id
    res.dstIdentity = json.profile_info ? Account.deserialize(json.profile_info) : undefined
    res.actions = AuthorizationAction.deserializeDictionary(json.actions ?? {})
    return res
  }

  public static serializeArray(objs: Authorization[]): IAuthorizationJson[] {
    const jsons = objs.map((p) => {
      return Authorization.serialize(p)!
    })
    return jsons
  }

  public static deserializeArray(json: IAuthorizationJson[]): Authorization[] {
    const res = json.map((p) => {
      return Authorization.deserialize(p)!
    })
    return res
  }

  public getPermissionIdForVisualizationOfTarget(targetId: number, actionType: string): number {
    const permission = this.actions[Authorization.action_view].permissions.find(
      (p) => p.targetId === targetId && p.action === actionType
    )
    return permission ? permission.id : 0
  }

  public getDoxIdsFromWhichIsInherited(currentTargetId: number) {
    const doxIds = this.actions[Authorization.action_view].permissions
      .filter((p) => p.isValid && p.action === ActionType.viewDox && p.targetId !== currentTargetId)
      .map((p) => p.targetId)
    return doxIds
  }
}
