import { ContractAcceptanceStatus, ContractType } from '../shared/Constants'

export class AccountLegalStatus {
  pdxPrivacyPolicyAcceptanceStatus: ContractAcceptanceStatus = ContractAcceptanceStatus.none
  pdxTermsOfUseAcceptanceStatus: ContractAcceptanceStatus = ContractAcceptanceStatus.none
  pdxPrivacyDataProcessorAcceptanceStatus: ContractAcceptanceStatus = ContractAcceptanceStatus.none
  pdxAcceptanceStatusForLoggedProfile: ContractAcceptanceStatus = ContractAcceptanceStatus.none
  pdxContractTypesThatNeedsAction: ContractType[] = []

  constructor() {}

  public setContractAcceptanceStatus(status: ContractAcceptanceStatus, contractType: ContractType) {
    switch (contractType) {
      case ContractType.privacyPolicy: {
        this.pdxPrivacyPolicyAcceptanceStatus = status
        break
      }
      case ContractType.termsOfUse: {
        this.pdxTermsOfUseAcceptanceStatus = status
        break
      }
      case ContractType.dataProcessor: {
        this.pdxPrivacyDataProcessorAcceptanceStatus = status
        break
      }
    }

    if (
      status === ContractAcceptanceStatus.toAccept ||
      status === ContractAcceptanceStatus.implicityAcceptedButNotConfirmed ||
      status === ContractAcceptanceStatus.implicityAcceptedOptionalConsentsRequiredButNotConfirmed
    ) {
      this.pdxContractTypesThatNeedsAction.push(contractType)
    } else {
      var index = this.pdxContractTypesThatNeedsAction.indexOf(contractType)
      if (index > -1) {
        this.pdxContractTypesThatNeedsAction.splice(index, 1)
      }
    }
  }

  public getContracts(status: ContractAcceptanceStatus) {
    const contractTypes: ContractType[] = []
    if (this.pdxPrivacyPolicyAcceptanceStatus === status) {
      contractTypes.push(ContractType.privacyPolicy)
    }
    if (this.pdxTermsOfUseAcceptanceStatus === status) {
      contractTypes.push(ContractType.termsOfUse)
    }
    if (this.pdxPrivacyDataProcessorAcceptanceStatus === status) {
      contractTypes.push(ContractType.dataProcessor)
    }
    return contractTypes
  }

  public setAcceptanceStatusForLoggedProfile(status: ContractAcceptanceStatus) {
    this.pdxAcceptanceStatusForLoggedProfile = status
  }
}
