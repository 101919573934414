import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DoxDetails } from '../../components/doxDetails/DoxDetails'
import DialogTemplate from '../../components/template/dialogTemplate/DialogTemplate'
import msgIds from '../../locales/msgIds'
import { IDoxDetailsDialogProps } from './DoxDetailsDialog.types'
import * as dalDox from '../../dal/DalDox'
import * as dalTreatment from '../../dal/DalTreatment'
import { Utils } from '../../shared/Utils'
import { Treatment } from '../../models/Treatment'
import { Button, CircularProgress, Stack } from '@mui/material'
import { Dox } from '../../models/Dox'
import { useArchiveContext } from '../../contexts/ArchiveContext'
import { useChange } from '../../hooks/Change'
import { useAuthContext } from '../../contexts/AuthContext'

export function DoxDetailsDialog(props: IDoxDetailsDialogProps) {
  const { t } = useTranslation()
  const authContext = useAuthContext()
  const archiveContext = useArchiveContext()
  const [isLoading, setIsLoading] = useState(false)
  const [dox, setDox] = useState<Dox>()
  const [treatment, setTreatment] = useState<Treatment>()

  useChange(() => {
    setDox(undefined)
  }, [props.doxId])

  useEffect(() => {
    const loadDoxDetails = async (abortSignal: AbortSignal) => {
      try {
        setIsLoading(true)
        // TODO: consider expanding the getDoxDetails call on the backend side with the
        //       'treatment' attribute to allow treatment fetch with a single call
        const dox = await dalDox.getDoxDetails(abortSignal, props.doxId)
        const availableDoxes =
          dox.ownerProfileId === authContext.loggedProfileId ||
          dox.ownerProfileId === authContext.linkedStructureProfileId
            ? archiveContext.rwArchiveDoxes
            : archiveContext.roArchiveDoxes
        availableDoxes.initRoutes(dox) // the routes are valorised only if the dox belongs to the owned archive
        setDox(dox)

        const treatment = dox.treatmentId ? await dalTreatment.getTreatment(abortSignal, dox.treatmentId) : undefined
        setTreatment(treatment)
      } catch (err) {
        Utils.enqueueSnackbarError2(err, t)
      } finally {
        setIsLoading(false)
      }
    }

    const abortController = new AbortController()
    loadDoxDetails(abortController.signal)

    return () => {
      abortController.abort()
    }
  }, [props.doxId, t])

  return (
    <DialogTemplate
      isOpen={!!props.isOpen}
      title={t(msgIds.MSG_DOX_DETAILS_DIALOG_TITLE)}
      isFullscreenForMobile={true}
      maxWidth="sm"
      mt={3}
      canAbort={true}
      onClose={props.onClose}
      showActions={true}
      actions={[
        <Button
          autoFocus
          variant={'contained'}
          sx={{ textTransform: 'none' }}
          onClick={() => {
            props.onClose({ userChoice: 'yes' })
          }}
        >
          {t(msgIds.MSG_OK)}
        </Button>,
      ]}
    >
      {!dox && (
        <Stack justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      )}
      {dox && <DoxDetails dox={dox} treatment={treatment} />}
    </DialogTemplate>
  )
}
