import { useTranslation } from 'react-i18next'
import { IEventHistoryTableProps } from './EventHistoryTable.types'
import { Breakpoint, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import msgIds from '../../locales/msgIds'
import { Utils, dateTimeShortOptions } from '../../shared/Utils'
import { HistoryContextToDsc, HistoryTypeToDsc } from './EventHistoryUtils'
import { MultilineTableCell } from '../multilineTableCell/MultilineTableCell'
import { History } from '../../models/History'
import { StyledDialog } from '../../dialogs/styledDialog/StyledDialog'
import { EventHistoryStepper } from '../eventHistoryStepper/EventHistoryStepper'
import { useState } from 'react'
import { StyledTableRow } from '../../shared/StyledControls'

const dateTimeOptions = { ...dateTimeShortOptions, second: 'numeric' }

export function EventHistoryTable(props: IEventHistoryTableProps): JSX.Element {
  const { history } = props
  const { t, i18n } = useTranslation()
  const [eventHistoryDialogMaxWidth, setEventHistoryDialogMaxWidth] = useState<Breakpoint>('sm')

  // dialogs
  const [selectedHistory, setSelectedHistory] = useState<History>()
  const [historyDetailsDialogOpen, setHistoryDetailsDialogOpen] = useState(false)

  function handleClickRow(history: History) {
    setSelectedHistory(history)
    setHistoryDetailsDialogOpen(true)
  }

  return (
    <Table stickyHeader size="small">
      <StyledDialog
        open={historyDetailsDialogOpen}
        onClose={() => setHistoryDetailsDialogOpen(false)}
        minHeight={100}
        maxWidth={eventHistoryDialogMaxWidth}
      >
        <EventHistoryStepper
          history={selectedHistory}
          onClickExit={() => setHistoryDetailsDialogOpen(false)}
          setMaxWidth={setEventHistoryDialogMaxWidth}
        />
      </StyledDialog>

      <TableHead>
        <TableRow>
          <TableCell>{t(msgIds.MSG_EVENT_HISTORY_TABLE_DATE_HEADER)}</TableCell>
          <TableCell>{t(msgIds.MSG_EVENT_HISTORY_TABLE_AUTHOR_HEADER)}</TableCell>
          <TableCell>{t(msgIds.MSG_EVENT_HISTORY_TABLE_ACTION_HEADER)}</TableCell>
          <TableCell>{t(msgIds.MSG_EVENT_HISTORY_TABLE_DESCRIPTION_HEADER)}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {history.map((h) => (
          <StyledTableRow key={h.id} hover={true} onClick={() => handleClickRow(h)}>
            <TableCell>{Utils.toLocaleDateString(h.eventCreatedAt, i18n, dateTimeOptions)}</TableCell>
            <TableCell>{!!h.author ? h.author?.getIdentityInverse() : 'PersonalDOX'}</TableCell>
            <TableCell>{HistoryTypeToDsc(h, t)}</TableCell>
            <MultilineTableCell text={HistoryContextToDsc(h, t, i18n)} />
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  )
}
