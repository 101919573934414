import { Paper, Stack, Typography } from '@mui/material'
import { IProfileInformationsProps } from './ProfileInformations.types'
import * as dalAccount from '../../dal/DalAccount'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useAuthContext } from '../../contexts/AuthContext'
import { Utils } from '../../shared/Utils'
import { Account } from '../../models/Account'
import { FieldAcl, useAccountEditorContext } from '../../contexts/AccountEditorContext'
import { AccountEditorResidenceForm } from '../accountEditorResidence/AccountEditorResidenceForm'
import { AccountEditorContactsForm } from '../accountEditorContacts/AccountEditorContactsForm'

export function ProfileInformations(props: IProfileInformationsProps): JSX.Element {
  const { t } = useTranslation()
  const authContext = useAuthContext()
  const accountEditorContext = useAccountEditorContext()
  const [isLoading, setIsLoading] = useState(false)
  const [account, setAccount] = useState<Account>()

  useEffect(() => {
    async function LoadAccountDetails(abortSignal: AbortSignal) {
      try {
        setIsLoading(true)
        const data = await dalAccount.getAccountFromProfileId(abortSignal, props.profileId, true, false)
        setAccount(data)
      } catch (err) {
        Utils.enqueueSnackbarError2(err, t)
      } finally {
        setIsLoading(false)
      }
    }

    const abortController = new AbortController()
    if (props.profileId && props.profileId !== authContext.loggedProfileId) {
      LoadAccountDetails(abortController.signal)
    } else {
      setAccount(authContext.loggedAccount)
    }

    return () => {
      abortController.abort()
    }
  }, [props.profileId])

  useEffect(() => {
    accountEditorContext.setFieldsAcl(authContext.loggedAccount, account)
  }, [account])

  function checkFormVisibility(fieldsAcl: FieldAcl[]) {
    return fieldsAcl.some((p) => p === 'editable')
  }

  const isResidenceFormVisible = checkFormVisibility([
    accountEditorContext.fieldsAcl.residenceCountry,
    accountEditorContext.fieldsAcl.residenceProvince,
    accountEditorContext.fieldsAcl.residenceCity,
    accountEditorContext.fieldsAcl.residenceStreet,
    accountEditorContext.fieldsAcl.residenceStreetNumber,
    accountEditorContext.fieldsAcl.residenceZip,
  ])

  const isContactsFormVisible = checkFormVisibility([
    accountEditorContext.fieldsAcl.phone,
    accountEditorContext.fieldsAcl.mobilePhone,
    accountEditorContext.fieldsAcl.email,
  ])

  if (!account) {
    return <Typography>{t(msgIds.MSG_PROFILE_EDITOR_NOT_AVAILABLE_ACCOUNT)}</Typography>
  }

  return (
    <Stack padding={4} spacing={1} {...props}>
      {isResidenceFormVisible && (
        <Paper elevation={0}>
          <AccountEditorResidenceForm
            isInDialog={props.isInDialog}
            account={account}
            detailsOwnerProfileId={props.ownerProfileId || account.profile?.profileId || 0}
            mandatoryFieldsNames={props.mandatoryFieldsNames}
            optionalFieldsNames={props.optionalFieldsNames}
          />
        </Paper>
      )}
      {isContactsFormVisible && (
        <Paper elevation={0}>
          <AccountEditorContactsForm
            isInDialog={props.isInDialog}
            account={account}
            detailsOwnerProfileId={props.ownerProfileId || account.profile?.profileId || 0}
            mandatoryFieldsNames={props.mandatoryFieldsNames}
            optionalFieldsNames={props.optionalFieldsNames}
          />
        </Paper>
      )}
    </Stack>
  )
}
