import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function DossierIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 6.3414486,3.025198 c -0.8259802,0 -1.4965307,0.6705374 -1.4965307,1.4965332 V 6.616435 h 0.8983565 c 0.3271402,0 0.6320135,0.086457 0.8961655,0.2388345 V 4.8197246 H 19.205478 V 19.180275 H 6.6394399 V 17.14473 c -0.264152,0.152916 -0.5690253,0.241033 -0.8961655,0.241033 H 4.8449179 v 2.094704 c 0,0.82649 0.6705505,1.494335 1.4965307,1.494335 H 19.50347 C 20.329452,20.974802 21,20.306957 21,19.480467 V 4.5217312 C 21,3.6957354 20.329452,3.025198 19.50347,3.025198 Z M 9.3323189,6.616435 V 8.410962 H 16.512599 V 6.616435 Z M 3.9684724,7.516982 c -0.4952845,0 -0.8961656,0.402579 -0.8961656,0.898362 0,0.49529 0.4008811,0.896164 0.8961656,0.896164 h 1.7967133 c 0.4957913,0 0.8961653,-0.400874 0.8961653,-0.896164 0,-0.495783 -0.400374,-0.898362 -0.8961653,-0.898362 z m 2.6709675,2.447473 c -0.264152,0.152931 -0.5690253,0.241018 -0.8961655,0.241018 H 4.8449179 v 3.591237 h 0.8983565 c 0.3271402,0 0.6320135,0.08601 0.8961655,0.238835 V 13.79671 10.205473 Z m 2.692879,0.241018 V 12 H 16.512599 V 10.205473 Z M 3.8983567,14.725752 C 3.4025652,14.725752 3,15.128302 3,15.6241 c 0,0.495783 0.4025652,0.897869 0.8983567,0.898362 h 1.7945221 c 0.4963001,0 0.8961655,-0.402579 0.8961655,-0.898362 0,-0.495798 -0.4003739,-0.898348 -0.8961655,-0.898348 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
