import { IAuthorizationsDialogProps } from './AuthorizationsDialog.types'
import { AuthorizationsStepper } from '../../components/authorizationsStepper/AuthorizationsStepper'
import { StyledDialog } from '../styledDialog/StyledDialog'

export default function AuthorizationsDialog(props: IAuthorizationsDialogProps) {
  return (
    <StyledDialog maxWidth={'md'} open={props.isOpen} onClose={() => props.onClose({ userChoice: 'abort' })}>
      <AuthorizationsStepper
        element={props.element}
        onClickExit={() => props.onClose({ userChoice: 'abort' })}
        flexGrow={1}
      />
    </StyledDialog>
  )
}
