import { Button, Chip, Stack, Typography } from '@mui/material'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'

export interface FiltersBarFilter {
  keyLabel: string
  valueLabel: string
}

export interface IFiltersBarProps<T extends FiltersBarFilter> {
  filters?: T[]
  onClickFilters: () => void
  onClickDeleteFilter: (filter: T) => void
}

export function FiltersBar<T extends FiltersBarFilter>(props: IFiltersBarProps<T>): JSX.Element {
  const { filters, onClickFilters, onClickDeleteFilter } = props
  const { t } = useTranslation()

  return (
    <Stack direction="row" alignItems="center">
      <Button onClick={onClickFilters}>{t(msgIds.MSG_DOCS_FILTERS_BAR_BUTTON)}</Button>
      <Stack direction="row" gap={1} flexWrap="wrap">
        {(!filters || filters?.length === 0) && (
          <Typography variant="caption">{t(msgIds.MSG_DOCS_FILTERS_BAR_PLACEHOLDER)}</Typography>
        )}
        {filters?.map((filter, i) => (
          <Chip
            key={i}
            color="primary"
            label={`${filter.keyLabel}: ${filter.valueLabel}`}
            onDelete={() => onClickDeleteFilter(filter)}
          />
        ))}
      </Stack>
    </Stack>
  )
}
