import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function DiaryStructureIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 21,5.0000032 C 19.864896,4.6592743 18.685104,4.4907325 17.5,4.5000033 c -1.95,0 -4.05,0.4 -5.5,1.5 -1.45,-1.1 -3.5500001,-1.5 -5.5000001,-1.5 -1.95,0 -4.0499999,0.4 -5.49999991,1.5 V 20.650003 c 0.018229,0.26823 0.23177071,0.481772 0.50000001,0.5 0.1,0 0.15,-0.05 0.2500001,-0.05 1.4932372,-0.682401 3.1087993,-1.056531 4.7499998,-1.1 1.95,0 4.0500001,0.399999 5.5000001,1.5 1.686895,-0.932895 3.573051,-1.447302 5.5,-1.5 1.644929,-0.03908 3.274811,0.32121 4.749999,1.05 0.07679,0.04038 0.163592,0.05774 0.250001,0.05 0.26823,-0.01823 0.481771,-0.231771 0.499999,-0.499999 V 6.0000033 C 22.392885,5.5589152 21.717139,5.2210423 21,5.0000032 Z m 0,13.5000008 c -1.134335,-0.344211 -2.314659,-0.512829 -3.5,-0.500001 -1.549924,0.0443 -3.077191,0.383688 -4.5,1 V 7.5000033 c 1.422809,-0.616313 2.950076,-0.955706 4.5,-1.0000001 1.185341,-0.01283 2.365665,0.1557896 3.5,0.5 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
