import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function DocReceivedIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 10.999512,2.9992659 v 9.6701661 l -2.5905766,-2.57959 -1.408447,1.410645 4.9987786,4.998779 5.000977,-4.998779 -1.410644,-1.410645 -2.590577,2.57959 V 2.9992659 Z M 2.9992676,11.999266 v 7.000488 a 2.005875,2.005875 0 0 0 2.0017088,1.999512 H 18.999756 a 2.005875,2.005875 0 0 0 1.999512,-1.999512 v -7.000488 h -1.999512 v 7.000488 H 5.0009764 v -7.000488 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
