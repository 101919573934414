import { PDX_API_URL } from '../shared/Constants'
import log from '../shared/Logger'
import { axiosInstance } from '../contexts/AuthContext'
import { UriComposer } from '../shared/types/UriComposer'
import { Consent, IConsentJson } from '../models/Consent'

// ********************
// GET

export function getActiveConsentsForPersonalDoxContracts(abortSignal: AbortSignal): Promise<Consent[]> {
  const funcName = 'getActiveConsentsForPersonalDoxContracts'
  const url = UriComposer.create(`${PDX_API_URL}/v1/consents?public=true&all=false&owner_profile_id=0`).getCompleteUri()

  return axiosInstance
    .get(url, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = Consent.deserializeArray(resp.data as IConsentJson[])
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// POST

// ********************
// PATCH

export type ConsentChange = {
  sectionId: number
  sharedFields?: string[]
}

export function updateConsents(
  abortSignal: AbortSignal,
  sectionsToRevoke: ConsentChange[],
  sectionsToGrant: ConsentChange[],
  grantorProfileId?: number
) {
  const funcName = 'updateConsents'
  let url = `${PDX_API_URL}/v1/consents`

  const parms = {
    sections_to_revoke: sectionsToRevoke.map((p) => {
      return {
        id: p.sectionId,
      }
    }),
    sections_to_grant: sectionsToGrant.map((p) => {
      return {
        id: p.sectionId,
        shared_fields: p.sharedFields,
      }
    }),
    profile_id: grantorProfileId,
  }

  return axiosInstance
    .patch(url, parms, {
      headers: {
        'Accept-Version': '1.0.x',
      },
      signal: abortSignal,
    })
    .then((resp) => {
      const reply = resp.data
      return reply
    })
    .catch((err) => {
      log.error(`[${funcName} error] %o`, err)
      return Promise.reject(err)
    })
}

// ********************
// DELETE
