import { FormControlLabel, Radio, RadioGroup, Stack, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { Utils } from '../../shared/Utils'
import { Gender, genderFromValue, isStructure } from '../../shared/Constants'
import { SimpleAutocompleteTextField } from '../simpleAutocompleteTextField/SimpleAutocompleteTextField'
import { CountryOption, countryOptionByValue, countryOptionByKey, countryOptions } from '../../shared/types/Country'
import { CommuneOption, communeOptionByValue } from '../../shared/types/Commune'
import { IAccountEditorIdentityFieldsProps } from './AccountEditorIdentityFields.types'
import { useCommuneOptions } from '../../hooks/CommuneOptions'

export function AccountEditorIdentityFields(props: IAccountEditorIdentityFieldsProps): JSX.Element {
  const { profileType, disabled, isEditMode, args, onChangeArgs, errors, fieldsAcl } = props
  const { t } = useTranslation()
  const communeOptions = useCommuneOptions()
  const birthplaceValue = communeOptionByValue(communeOptions, args?.birthplace)
  const birthCountry: CountryOption | undefined = countryOptionByValue(args.birthplace) || countryOptionByKey('IT')
  const extraIT = birthCountry && birthCountry.key !== 'IT'

  function onChangeBirthdate(value: dayjs.Dayjs | null) {
    if (value?.isValid()) {
      const v = value.set('hours', 12)
      const date = v.toDate()
      const dateString = Utils.toDate(date, true) as string
      onChangeArgs({ ...args, birthdate: dateString }, 'birthdate')
    } else {
      onChangeArgs({ ...args, birthdate: undefined }, 'birthdate')
    }
  }

  function onChangeBirthCountry(country: CountryOption | null) {
    if (country && country.key !== 'IT') {
      onChangeArgs({ ...args, birthplace: country.value }, 'birthplace')
    } else {
      onChangeArgs({ ...args, birthplace: undefined }, 'birthplace')
    }
  }
  function onChangeBirthCity(commune: CommuneOption | null) {
    if (commune) {
      onChangeArgs({ ...args, birthplace: commune.value }, 'birthplace')
    } else {
      onChangeArgs({ ...args, birthplace: undefined }, 'birthplace')
    }
  }

  return (
    <Stack gap={4} pt={1}>
      {fieldsAcl.isVisible('registrationEmail') && (
        <TextField
          error={!!errors.email}
          helperText={t(errors.email)}
          disabled={!fieldsAcl.isEditable('registrationEmail') || disabled || !isEditMode}
          label={t(msgIds.MSG_EMAIL)}
          variant="outlined"
          value={args?.email || ''}
          onChange={(event) => onChangeArgs({ ...args, email: event.target.value }, 'email')}
        />
      )}

      {fieldsAcl.isVisible('lastname') && (
        <TextField
          error={!!errors.lastname}
          helperText={t(errors.lastname)}
          disabled={!fieldsAcl.isEditable('lastname') || disabled || !isEditMode}
          label={t(msgIds.MSG_LASTNAME)}
          variant="outlined"
          value={args?.lastname || ''}
          onChange={(event) => onChangeArgs({ ...args, lastname: event.target.value }, 'lastname')}
        />
      )}

      {fieldsAcl.isVisible('name') && (
        <TextField
          error={!!errors.name}
          helperText={t(errors.name)}
          disabled={!fieldsAcl.isEditable('name') || disabled || !isEditMode}
          label={isStructure(profileType) ? t(msgIds.MSG_COMPANY_NAME) : t(msgIds.MSG_NAME)}
          variant="outlined"
          value={args?.name || ''}
          onChange={(event) => onChangeArgs({ ...args, name: event.target.value }, 'name')}
        />
      )}

      {fieldsAcl.isVisible('gender') && (
        <RadioGroup
          row
          aria-disabled={!fieldsAcl.isEditable('gender') || disabled || !isEditMode}
          value={args?.gender || null}
          onChange={(event) => onChangeArgs({ ...args, gender: genderFromValue(event.target.value) }, 'gender')}
        >
          <FormControlLabel
            disabled={!fieldsAcl.isEditable('gender') || disabled || !isEditMode}
            value={Gender.male.toString()}
            control={<Radio />}
            label="M"
          />
          <FormControlLabel
            disabled={!fieldsAcl.isEditable('gender') || disabled || !isEditMode}
            value={Gender.female.toString()}
            control={<Radio />}
            label="F"
          />
        </RadioGroup>
      )}

      {fieldsAcl.isVisible('birthdate') && (
        <Stack spacing={0} m={0} p={0}>
          <DatePicker
            disabled={!fieldsAcl.isEditable('birthdate') || disabled || !isEditMode}
            label={t(msgIds.MSG_TREATED_FIELDS_BIRTH_DATE)}
            value={args?.birthdate ? dayjs(new Date(args?.birthdate)) : null}
            onChange={onChangeBirthdate}
            minDate={dayjs(new Date(Date.now() - 1000 * 60 * 60 * 24 * 365 * 300))}
            maxDate={dayjs(new Date())}
            slotProps={{
              textField: {
                error: !!errors.birthdate,
                helperText: t(errors.birthdate),
              },
            }}
          />
        </Stack>
      )}

      {fieldsAcl.isVisible('birthplace') && (
        <>
          <SimpleAutocompleteTextField
            error={!!errors.birthplace}
            helperText={t(errors.birthplace)}
            label={t(msgIds.MSG_PROFILE_EDITOR_IDENTITY_BIRTH_COUNTRY)}
            options={countryOptions}
            disabled={!fieldsAcl.isEditable('birthplace') || disabled || !isEditMode}
            value={birthCountry}
            onChange={onChangeBirthCountry}
          />
          {birthCountry?.key === 'IT' && (
            <SimpleAutocompleteTextField
              error={!!errors.birthplace}
              helperText={t(errors.birthplace)}
              options={communeOptions}
              filterFromInputLength={3}
              noOptionsText={t(msgIds.MSG_COMMUNE_AUTOCOMPLETE_TIP)}
              disabled={!fieldsAcl.isEditable('birthplace') || extraIT || !birthCountry || disabled || !isEditMode}
              value={birthplaceValue || null}
              onChange={onChangeBirthCity}
              label={t(msgIds.MSG_PROFILE_EDITOR_IDENTITY_BIRTH_CITY)}
            />
          )}
        </>
      )}

      {fieldsAcl.isVisible('fiscalCode') && (
        <TextField
          error={!!errors.fiscalCode}
          helperText={t(errors.fiscalCode)}
          disabled={!fieldsAcl.isEditable('fiscalCode') || disabled || !isEditMode}
          label={t(msgIds.MSG_TREATED_FIELDS_FISCAL_CODE)}
          variant="outlined"
          value={args?.fiscalCode || ''}
          onChange={(event) => onChangeArgs({ ...args, fiscalCode: event.target.value.toUpperCase() }, 'fiscalCode')}
          inputProps={{
            style: { textTransform: 'uppercase' },
          }}
        />
      )}

      {fieldsAcl.isVisible('vatNumber') && (
        <TextField
          error={!!errors.vatNumber}
          helperText={t(errors.vatNumber)}
          disabled={!fieldsAcl.isEditable('vatNumber') || disabled || !isEditMode}
          label={t(msgIds.MSG_PROFILE_EDITOR_IDENTITY_VAT)}
          variant="outlined"
          value={args?.vatNumber || ''}
          onChange={(event) => onChangeArgs({ ...args, vatNumber: event.target.value }, 'vatNumber')}
        />
      )}
    </Stack>
  )
}
