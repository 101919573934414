import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function InvitationIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 5.0017089,3 C 3.8958109,2.99906 2.9990566,3.89588 2.9999998,5.00171 V 19.00049 C 3.0002674,20.10554 3.8966667,21.00095 5.0017089,21 H 19.000488 C 20.10467,20.99973 20.999733,20.10468 21,19.00049 V 5.00171 C 21.000942,3.89673 20.105526,3.00027 19.000488,3 17.620766,3.003 7.4645377,3.001 6.1508788,3 Z M 4.8522948,4.85231 H 7.5395506 L 4.8522948,7.53956 Z m 4.2736816,0 h 5.7084956 l 4.317627,4.32421 c 5.4e-5,1.406 1.7e-5,3.8016 0,5.64258 l -4.328613,4.33301 H 9.1369627 L 4.8522948,14.86304 V 9.13257 Z m 7.2949216,0 h 2.731201 c 4.01e-4,0.007 -1.38e-4,2.27268 0,2.72899 z M 12,6.7749 a 2.1548747,2.2103225 0 0 0 -1.043702,4.14405 2.109756,2.1640428 0 0 0 2.087403,0 A 2.1548747,2.2103225 0 0 0 12,6.7749 Z m 0,5.57228 c -1.585986,0 -4.7416994,0.89485 -4.7416994,2.65429 v 1.32495 H 16.7417 v -1.32495 c 0,-1.75944 -3.155714,-2.65429 -4.7417,-2.65429 z m 7.152099,4.06713 c -7e-6,1.05361 0,1.30546 0,2.7378 h -2.73999 z m -14.2998042,0.0417 2.6960449,2.69606 H 4.8522948 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
