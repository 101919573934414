export type CommuneOption = { label: string; value: string }

export function mapCommuneToOption(c: any): CommuneOption {
  return { label: `${c.fullName} (${c.pv})`, value: c.code }
}

export function communeOptionByValue(
  options: CommuneOption[],
  value: string | undefined | null
): CommuneOption | undefined {
  if (!value) {
    return undefined
  }
  return options.find((c) => c.value === value)
}
