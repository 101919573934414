import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { Alert } from '@mui/material'
import { IAccountEditorBillingProps } from './AccountEditorBilling.types'
import { useEffect, useState } from 'react'
import { EditorFormContainer } from '../editorFormContainer/EditorFormContainer'
import { UpdateUserArgs } from '../../dal/DalAccount'
import { Utils } from '../../shared/Utils'
import * as dalAccount from '../../dal/DalAccount'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import { useSnackbar } from 'notistack'
import { useLayout } from '../../hooks/Layout'
import { AccountEditorBillingFields } from './AccountEditorBillingFields'
import { validateBillingUserArgs } from '../../shared/UpdateUserArgs'

export function AccountEditorBilling(props: IAccountEditorBillingProps): JSX.Element {
  const { account, disabled, isEditMode, setIsLoading } = props
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { isMobile } = useLayout()
  const [errors, setErrors] = useState<any>({})
  const [args, setArgs] = useState<UpdateUserArgs>({})
  const [areUnsavedChanges, setAreUnsavedChanges] = useState(false)

  function initFields() {
    setArgs({
      sdiCode: account?.userDetails?.sdiCode,
      pec: account?.userDetails?.pec,
      country: account?.userDetails?.country,
      city: account?.userDetails?.city,
      province: account?.userDetails?.province,
      street: account?.userDetails?.street,
      streetNumber: account?.userDetails?.streetNumber,
      zip: account?.userDetails?.zip,
    })
  }

  useEffect(() => {
    initFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  useEffect(() => {
    const check =
      args?.sdiCode !== account?.userDetails?.sdiCode ||
      args?.pec !== account?.userDetails?.pec ||
      args?.country !== account?.userDetails?.country ||
      args?.city !== account?.userDetails?.city ||
      args?.province !== account?.userDetails?.province ||
      args?.street !== account?.userDetails?.street ||
      args?.streetNumber !== account?.userDetails?.streetNumber ||
      args?.zip !== account?.userDetails?.zip
    setAreUnsavedChanges(check)
  }, [args])

  function validateData(val: UpdateUserArgs) {
    return validateBillingUserArgs(val)
  }

  async function onSave() {
    const userId = account?.user?.userId
    if (!userId || !args) return

    const errors = validateData(args)
    setErrors(errors || {})
    if (errors) {
      enqueueSnackbar(t(msgIds.MSG_VAL_ERR_THERE_ARE_FORM_ERRORS), { variant: 'error' })
      return
    }

    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const updatedUser = await dalAccount.updateUser(abortController.signal, userId, args)
      account.userDetails = updatedUser.userDetails
      setAreUnsavedChanges(false)
      props.onSave && props.onSave()
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  function onCancel() {
    initFields()
    setErrors({})
    props.onCancel && props.onCancel()
  }

  return (
    <EditorFormContainer>
      <AccountEditorBillingFields
        args={args}
        onChangeArgs={setArgs}
        errors={errors}
        isEditMode={isEditMode}
        disabled={disabled}
      />
      {isEditMode && (
        <ViewActions justifyContent={'center'} sx={{ paddingTop: 6 }}>
          <ViewActionsButton autoFocus defaultAction onClick={onSave} disabled={!areUnsavedChanges}>
            {t(msgIds.MSG_SAVE)}
          </ViewActionsButton>
          <ViewActionsButton onClick={onCancel}>{t(msgIds.MSG_CANCEL)}</ViewActionsButton>
        </ViewActions>
      )}
    </EditorFormContainer>
  )
}
