import { BoxProps, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import msgIds from "../../locales/msgIds"
import { forwardRef } from "react"

export interface ILoadMoreProps extends BoxProps {
  noMoreToLoad?: boolean
}

function LoadMoreRef(props: ILoadMoreProps, ref: any): JSX.Element {
  const { noMoreToLoad, style, ...rest } = props
  const { t } = useTranslation()
  return (
    <Stack ref={ref} style={{ alignItems: 'center', justifyContent: 'center', padding: 20, ...style }} {...rest}>
      {noMoreToLoad && <Typography>{t(msgIds.MSG_LOAD_MORE_NO_MORE_TO_LOAD)}</Typography>}
      {!noMoreToLoad && <Typography>{t(msgIds.MSG_LOAD_MORE_LOADING_MORE)}</Typography>}
    </Stack>
  )
}

export const LoadMore = forwardRef(LoadMoreRef)
