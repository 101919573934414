import { Button } from '@mui/material'
import { IViewActionsButtonProps } from './ViewActionsButton.types'

export function ViewActionsButton(props: IViewActionsButtonProps): JSX.Element {
  const { defaultAction, children } = props

  // ugly
  const buttonProps = { ...props }
  delete buttonProps.defaultAction
  delete buttonProps.children

  return (
    <Button
      autoFocus={defaultAction}
      variant={defaultAction ? 'contained' : 'outlined'}
      sx={{ textTransform: 'none' }}
      {...buttonProps}
    >
      {children}
    </Button>
  )
}
