import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function ArrowLeftIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 12,20 13.41,18.59 7.83,13 H 20 V 11 H 7.83 L 13.42,5.4200003 12,4.0000004 4,12 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
