import { Box } from '@mui/material'
import MarkdownViewerNew from '../markdownEditorNew/MarkdownViewerNew'
import { isPersonalDoxMimetype } from '../../shared/Constants'
import { FileContent } from './FileContent'
import { useEffect, useState } from 'react'
import log from '../../shared/Logger'
import { IDocumentRevisionProps } from './DocumentRevisionProps'
import { useTranslation } from 'react-i18next'
import { Utils } from '../../shared/Utils'

export default function DocumentContent(props: IDocumentRevisionProps): JSX.Element {
  const { revision, isLoading } = props
  const { t } = useTranslation()

  const [fileData, setFileData] = useState<string | null | undefined>(null)
  const [error, setError] = useState<string | undefined>(undefined)

  useEffect(() => {
    const processRevisionContent = async () => {
      if (!revision) {
        setError('missing revision')
        setFileData(null)
        return
      }
      if (!revision.mimetype) {
        setError('missing mimetype')
        setFileData(null)
        return
      }

      const typeOfContent = typeof revision.content

      if (isPersonalDoxMimetype(revision.mimetype) && typeOfContent !== 'string' && typeOfContent !== 'undefined') {
        setError('content type mismatch mimetype')
        setFileData(undefined)
        return
      }

      if (isPersonalDoxMimetype(revision.mimetype)) {
        setFileData(revision.content as string)
        setError(undefined)
      } else {
        try {
          const arrayBuffer = revision.content as ArrayBuffer
          const binaryStr = Utils.arrayBufferToBinary(arrayBuffer)
          if (!binaryStr) {
            setError('missing content data')
            setFileData(null)
            return
          }
          const dataStr = `data:${revision.mimetype};base64,` + btoa(binaryStr)
          setFileData(dataStr)
          setError(undefined)
        } catch (err) {
          setError('Error processing content')
        }
      }
    }

    processRevisionContent()
  }, [revision])

  if (error && isLoading === false) {
    log.error(error)
    return <Box padding={2}>{/* <Typography>{t(msgIds.MSG_DOCUMENT_EDITOR_DOWNLOAD_ERROR)}</Typography> */}</Box>
  }

  if (revision && fileData) {
    const { filename } = revision
    if (isPersonalDoxMimetype(revision.mimetype)) {
      return (
        <Box padding={2}>
          <MarkdownViewerNew initialValue={fileData} />
        </Box>
      )
    } else {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
          {!!revision.mimetype && <FileContent mimetype={revision.mimetype} filename={filename} data={fileData} />}
        </Box>
      )
    }
  } else {
    return <Box></Box>
  }
}
