import { useEffect, useState } from 'react'
import { ITreatmentCustomFieldProps } from './TreatmentCustomField.types'
import { Checkbox, FormControlLabel, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import msgIds from '../../locales/msgIds'
import { TreatedField } from '../../models/TreatedField'
import { Utils, ValFieldType, ValType } from '../../shared/Utils'
import { ViewContent } from '../viewContent/ViewContent'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'

export default function TreatmentCustomField(props: ITreatmentCustomFieldProps) {
  const { t } = useTranslation()
  const [updatedValue, setUpdatedValue] = useState(props.customField || new TreatedField())
  const [nameValErr, setNameValErr] = useState<undefined | string>(undefined)
  const [isValidated, setIsValidated] = useState(true)

  useEffect(() => {
    const uniqueName = !!updatedValue.fieldName
      ? updatedValue.fieldName
      : Utils.getUniqueStr(props.customFields?.map((p) => p.fieldName) || [], t(msgIds.MSG_NEW_TREATMENT_FIELD))
    updatedValue.fieldName = uniqueName
    updateInfoState()
  }, [])

  const handleChange = <K extends keyof TreatedField>(field: K, val: TreatedField[K]) => {
    const copy = _.cloneDeep(updatedValue)
    copy[field] = val
    setUpdatedValue(copy)
  }

  function updateInfoState() {
    let isValidated: boolean = true
    isValidated &&= Utils.isValidField(updatedValue.fieldName, ValFieldType.text, setNameValErr, t, [
      { type: ValType.notNull },
      { type: ValType.notEmpty },
    ])
    let isNameUnique = !props.customFields?.find(
      (p) => p !== props.customField && p.fieldName === updatedValue.fieldName
    )
    if (!isNameUnique) {
      setNameValErr(t(msgIds.MSG_ERR_TREATMENT_CUSTOM_FIELD_NOT_UNIQUE_NAME_DSC).toString())
      isValidated &&= isNameUnique
    }
    setIsValidated(isValidated)
  }

  useEffect(() => {
    updateInfoState()
  }, [updatedValue.fieldName])

  return (
    <ViewContent id="treatment-custom-field" spacing={2} alignItems="stretch">
      <TextField
        onChange={(e) => handleChange('fieldName', e.target.value)}
        value={updatedValue?.fieldName || ''}
        label={t(msgIds.MSG_TREATMENT_FIELD_NAME)}
        variant="outlined"
        fullWidth
        error={!!nameValErr}
        helperText={nameValErr}
      />
      <TextField
        sx={{ mt: 2 }}
        onChange={(e) => handleChange('description', e.target.value)}
        value={updatedValue?.description || ''}
        label={t(msgIds.MSG_TREATMENT_FIELD_DESCRIPTION)}
        variant="outlined"
        multiline
        rows={4}
        fullWidth
      />
      <FormControlLabel
        sx={{ mt: 2 }}
        control={
          <Checkbox
            onChange={(e) => handleChange('mandatory', e.target.checked)}
            checked={updatedValue?.mandatory || false}
          />
        }
        label={t(msgIds.MSG_TREATMENT_FIELD_MANDATORY)}
      />
      <ViewActions>
        <ViewActionsButton
          autoFocus
          defaultAction
          onClick={() => props.onUpdated(updatedValue)}
          disabled={!isValidated}
        >
          {t(msgIds.MSG_YES)}
        </ViewActionsButton>
        <ViewActionsButton onClick={() => props.onCancel()}>{t(msgIds.MSG_NO)}</ViewActionsButton>
      </ViewActions>
    </ViewContent>
  )
}
