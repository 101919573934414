import { Divider, IconButton, Stack, StackProps, Typography, useTheme } from '@mui/material'
import msgIds from '../../locales/msgIds'
import { IEventHistoryDetailsProps } from './EventHistoryDetails.types'
import { useTranslation } from 'react-i18next'
import * as dalDocument from '../../dal/DalDocument'
import * as dalDox from '../../dal/DalDox'
import * as dalTreatment from '../../dal/DalTreatment'
import * as dalContract from '../../dal/DalContract'
import * as dalContractVersion from '../../dal/DalContractVersion'
import { Utils, dateTimeShortOptions } from '../../shared/Utils'
import AccountIdentity from '../identities/AccountIdentity'
import { ActionType, ConsentRevokeCause, ContractType, HistoryEventType, ProfileType } from '../../shared/Constants'
import { AccountInfo } from '../../models/Account'
import { HistoryTypeToDsc, HistoryContextToDsc } from '../eventHistoryTable/EventHistoryUtils'
import { icoFromType } from './EventHistoryUtils'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'
import { ISimpleDialogData } from '../../dialogs/simpleDialog/SimpleDialog.types'
import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router'
import { AxiosError } from 'axios'
import { useAuthContext } from '../../contexts/AuthContext'

export function EventHistoryDetails(props: IEventHistoryDetailsProps & StackProps): JSX.Element {
  const {
    history,
    onClickAvatar,
    onShowDoxDetails,
    onShowDocumentDetails,
    onShowContractVersion,
    onCloseDialog,
    ...rest
  } = props
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const authContext = useAuthContext()
  const navigate = useNavigate()
  const leftIcon = icoFromType(history)
  const [isLoading, setIsLoading] = useState(false)

  // dialogs
  const [simpleDialogData, setSimpleDialogData] = useState<ISimpleDialogData>()
  const [simpleDialogOpen, setSimpleDialogOpen] = useState(false)

  const getAccountInfoToShow = (profileType?: ProfileType): AccountInfo[] => {
    switch (profileType) {
      case ProfileType.structure:
        return ['main']
      case ProfileType.operatorAdmin:
        return ['main', 'linked']
      case ProfileType.operatorExt:
      case ProfileType.operatorInt:
        return ['main', 'principal']
      case ProfileType.customer:
        return ['main']
      default:
        return []
    }
  }

  async function showResource() {
    switch (history.subjectType) {
      case 'permissions': {
        switch (history.subjectPermissionType) {
          case ActionType.dataProcessing: {
            break
          }
          case ActionType.alwaysDownload: {
            break
          }
          case ActionType.viewDox: {
            const doxId = history.contextPermission?.dox?.id
            if (doxId) {
              await showDox(doxId)
            }
            break
          }
          case ActionType.viewDocuments: {
            const documentId = history.contextPermission?.document?.id
            if (documentId) {
              await showDocument(documentId)
            }
            break
          }
          case ActionType.viewProfiles: {
            break
          }
          default: {
            console.log(`SUBTYPE ${history.subjectPermissionType}: UNKNOWN`)
          }
        }
        break
      }
      case 'dox': {
        const doxId = history.contextDox ? history.contextDox?.id : history.contextDoxUpdate?.new?.id
        if (doxId) {
          await showDox(doxId)
        }
        break
      }
      case 'documents': {
        let documentId: number | undefined
        let revisionId: number | undefined
        if (history.type === HistoryEventType.EVT_DOCUMENT_CREATED) {
          documentId = history.contextDocument?.documentId
          revisionId = history.contextDocument?.id
        } else if (history.type === HistoryEventType.EVT_DOCUMENT_RENAMED) {
          documentId = history.contextDocument?.newRevision?.documentId
          revisionId = history.contextDocument?.newRevision?.id
        } else if (history.type === HistoryEventType.EVT_DOCUMENT_REVISION_ARCHIVED) {
          documentId = history.contextDocumentRevision?.documentId
          revisionId = history.contextDocumentRevision?.id
        } else if (history.type === HistoryEventType.EVT_DOCUMENT_REVISION_DOWNLOADED) {
          documentId = history.contextDocument
            ? history.contextDocument?.documentId
            : history.contextDocumentRevision?.documentId
          revisionId = history.contextDocument
            ? history.contextDocument?.lastArchivedRevisionId
            : history.contextDocumentRevision?.id
        } else if (history.type === HistoryEventType.EVT_DOCUMENT_REVISION_VIEWED) {
          documentId = history.contextDocumentRevision?.documentId
          revisionId = history.contextDocumentRevision?.id
        } else {
          documentId = history.contextDocumentRevision?.documentId
          revisionId = history.contextDocument?.lastArchivedRevisionId
        }
        if (documentId) {
          await showDocument(documentId, revisionId)
        }
        break
      }
      case 'contracts': {
        const contractId = history.contextContractVersion?.contractId
        const contractOwnerProfileId = history.contextContractVersion?.contract?.ownerProfileId ?? 0
        const contractType = history.contextContractVersion?.contract?.type
        const targetProfileType = history.contextContractVersion?.contract?.targetProfileType
        const versionId = history.contextContractVersion?.id
        const grantorProfileId = authContext.loggedProfileId ?? 0
        const atDate = history.contextContractVersion?.publishedAt
        if (contractId && contractType && targetProfileType && versionId) {
          await showContractVersionWithConsents(
            contractId,
            contractOwnerProfileId,
            contractType,
            targetProfileType,
            versionId,
            grantorProfileId,
            atDate
          )
        }
        break
      }
      case 'consents': {
        const contractId = history.contextConsent?.contract?.id
        const contractOwnerProfileId = history.contextConsent?.contract?.ownerProfileId ?? 0
        const contractType = history.contextConsent?.contract?.type
        const targetProfileType = history.contextConsent?.contract?.targetProfileType
        const versionId = history.contextConsent?.version?.id
        const grantorProfileId = history.contextConsent?.profileId
        const atDate = history.contextConsent?.revokedAt ?? history.contextConsent?.createdAt
        if (contractId && contractType && targetProfileType && versionId && grantorProfileId) {
          await showContractVersionWithConsents(
            contractId,
            contractOwnerProfileId,
            contractType,
            targetProfileType,
            versionId,
            grantorProfileId,
            atDate
          )
        }
        break
      }
      case 'profiles': {
        break
      }
      case 'treatments': {
        if (history.contextTreatment) {
          navigate('/treatments/editor', { state: { treatmentId: history.contextTreatment.id } })
        }
        break
      }
      default: {
        console.log(`SUBTYPE ${history.subjectType}: UNKNOWN`)
      }
    }
  }

  async function showDocument(documentId: number, revisionId?: number) {
    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const document = await dalDocument.getDocument(abortController.signal, documentId)
      if (document) {
        onShowDocumentDetails(document, revisionId)
      }
    } catch (err) {
      if ((err as AxiosError)?.response?.status === 403) {
        setSimpleDialogOpen(true)
        setSimpleDialogData({
          title: t(msgIds.MSG_DOCUMENT_SHOW_TITLE),
          content: t(msgIds.MSG_DOCUMENT_NOT_AVAILABLE),
          actionsStyle: 'Ok',
          onClose: async (result) => {
            setSimpleDialogOpen(false)
          },
        })
      } else {
        Utils.enqueueSnackbarError2(err, t)
      }
    } finally {
      setIsLoading(false)
    }
  }

  async function showDox(doxId: number) {
    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const dox = await dalDox.getDoxDetails(abortController.signal, doxId)
      if (dox) {
        const treatment = dox.treatmentId
          ? await dalTreatment.getTreatment(abortController.signal, dox.treatmentId)
          : undefined
        onShowDoxDetails(dox, treatment)
      }
    } catch (err) {
      if ((err as AxiosError)?.response?.status === 403) {
        setSimpleDialogOpen(true)
        setSimpleDialogData({
          title: t(msgIds.MSG_HISTORY_SHOW_DOX_TITLE),
          content: t(msgIds.MSG_HISTORY_SHOW_DOX_NOT_AVAILABLE),
          actionsStyle: 'Ok',
          onClose: async (result) => {
            setSimpleDialogOpen(false)
          },
        })
      } else {
        Utils.enqueueSnackbarError2(err, t)
      }
    } finally {
      setIsLoading(false)
    }
  }

  async function showContractVersion(
    contractId: number,
    contractOwnerProfileId: number,
    contractType: ContractType,
    targetProfileType: ProfileType,
    versionId: number
  ) {
    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const contractVersion = await dalContractVersion.getContractVersion(abortController.signal, contractId, versionId)
      onShowContractVersion(contractOwnerProfileId, contractType, targetProfileType, contractVersion)
    } catch (err) {
      if ((err as AxiosError)?.response?.status === 403) {
        setSimpleDialogOpen(true)
        setSimpleDialogData({
          title: t(msgIds.MSG_HISTORY_SHOW_CONTRACT_TITLE),
          content: t(msgIds.MSG_HISTORY_SHOW_CONTRACT_NOT_AVAILABLE),
          actionsStyle: 'Ok',
          onClose: async (result) => {
            setSimpleDialogOpen(false)
          },
        })
      } else {
        Utils.enqueueSnackbarError2(err, t)
      }
    } finally {
      setIsLoading(false)
    }
  }

  async function showContractVersionWithConsents(
    contractId: number,
    contractOwnerProfileId: number,
    contractType: ContractType,
    targetProfileType: ProfileType,
    versionId: number,
    grantorProfileId: number,
    atDate?: Date
  ) {
    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const consentsHistory = await dalContract.getContractConsents(abortController.signal, {
        grantorProfileId: grantorProfileId,
        contractId: contractId,
        versionId: versionId,
        getSectionsText: true,
        atDate: atDate,
      })
      const consentsSnapshot = consentsHistory[0]
      if (consentsSnapshot) {
        // To consistently represent the state of consent, delete revokedAt if caused
        // by contract publication and after the publication of the contract
        consentsSnapshot.sections.forEach((section) => {
          if (
            atDate &&
            section.consent?.revokedAt &&
            section.consent?.revokeCause &&
            section.consent.revokeCause === ConsentRevokeCause.automatically &&
            section.consent?.revokedAt > atDate
          ) {
            section.consent.revokedAt = undefined
          }
        })
        onShowContractVersion(contractOwnerProfileId, contractType, targetProfileType, consentsSnapshot)
      }
    } catch (err) {
      if ((err as AxiosError)?.response?.status === 403) {
        setSimpleDialogOpen(true)
        setSimpleDialogData({
          title: t(msgIds.MSG_HISTORY_SHOW_CONTRACT_TITLE),
          content: t(msgIds.MSG_HISTORY_SHOW_CONTRACT_NOT_AVAILABLE),
          actionsStyle: 'Ok',
          onClose: async (result) => {
            setSimpleDialogOpen(false)
          },
        })
      } else {
        Utils.enqueueSnackbarError2(err, t)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const description = HistoryContextToDsc(history, t, i18n)

  return (
    <Stack {...rest} padding={3} spacing={2}>
      {simpleDialogData && <SimpleDialog {...simpleDialogData} isOpen={simpleDialogOpen}></SimpleDialog>}
      <Stack>
        <Typography textAlign="start" color={theme.palette.common.gray5}>
          {t(msgIds.MSG_EVENT_HISTORY_TABLE_DATE_HEADER)}
        </Typography>
        <Typography textAlign="start">
          {Utils.toLocaleDateString(history.eventCreatedAt, i18n, dateTimeShortOptions)}
        </Typography>
      </Stack>

      <Divider />

      {history.author && (
        <Stack>
          <Typography textAlign="start" color={theme.palette.common.gray5}>
            {t(msgIds.MSG_EVENT_HISTORY_TABLE_AUTHOR_HEADER)}
          </Typography>
          <AccountIdentity
            account={history.author}
            infoToShow={getAccountInfoToShow(history.author.profile?.type)}
            showProfileInfo={true}
            avatarClicked={onClickAvatar}
          />
        </Stack>
      )}

      <Divider />

      <Stack>
        <Typography textAlign="start" color={theme.palette.common.gray5}>
          {t(msgIds.MSG_EVENT_HISTORY_TABLE_ACTION_HEADER)}
        </Typography>
        <Stack direction={'row'}>
          <Typography flexGrow={1} textAlign="start">
            {HistoryTypeToDsc(history, t)}
          </Typography>
          {leftIcon && (
            <IconButton sx={{ marginTop: '-8px' }} onClick={() => showResource()}>
              {leftIcon({})}
            </IconButton>
          )}
        </Stack>
      </Stack>

      <Divider />

      <Stack>
        <Typography textAlign="start" color={theme.palette.common.gray5}>
          {t(msgIds.MSG_EVENT_HISTORY_TABLE_DESCRIPTION_HEADER)}
        </Typography>
        <Typography flexGrow={1} textAlign="start">
          {description &&
            description.split('\n').map((line, index) => (
              <Fragment key={index}>
                {line}
                <br />
              </Fragment>
            ))}
        </Typography>
      </Stack>
    </Stack>
  )
}
