import { Box, Button, Stack, Tooltip, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { Link as RouterLink, useOutletContext } from 'react-router-dom'
import { InfoIco } from '../icons'
import * as dalAuth from '../../dal/DalAuth'
import * as dalPermission from '../../dal/DalPermission'
import { Utils } from '../../shared/Utils'
import { PDXLogo } from '../images'
import { StyledLoginForm } from '../../shared/StyledControls'
import { ILoginViewProps } from '../loginView/LoginView.types'
import { isOperator } from '../../shared/Constants'
import { PasswordInput } from '../passwordInput/PasswordInput'

export function LoginGuest2FaView(): JSX.Element {
  const state: ILoginViewProps = useOutletContext()
  const { profileType, onUserLogged, setIsLoading } = state
  const { t } = useTranslation()
  const [pin, setPin] = useState<string>('')

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    await verifyPin()
  }

  async function verifyPin() {
    if (!pin) {
      return
    }
    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const { account, permissionId } = await dalAuth.checkGuestPin(abortController.signal, pin)
      const permission = await dalPermission.getPermission(abortController.signal, permissionId)
      await onUserLogged(abortController.signal, account, profileType, permission)
    } catch (error) {
      Utils.enqueueSnackbarError2(error, t)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <StyledLoginForm>
      <Stack spacing={1}>
        <PDXLogo
          sx={{
            alignSelf: 'center',
            width: { xs: '70%', sm: '60%' },
            maxWidth: '420px',
            height: 'auto',
          }}
        />
        <Typography textAlign={'center'}>
          {isOperator(profileType) ? t(msgIds.MSG_LOGIN_FOR_GUEST_OPERATORS) : t(msgIds.MSG_LOGIN_FOR_GUEST_CUSTOMERS)}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={2} component="form" onSubmit={handleSubmit}>
        <PasswordInput
          sx={{ flexGrow: 1 }}
          label={t(msgIds.MSG_LOGIN_GUEST_PIN_LABEL)}
          value={pin}
          onChange={(event) => setPin(event.target.value)}
        />
        <Tooltip title={t(msgIds.MSG_LOGIN_GUEST_2FA_INFO)}>
          <Box>
            <InfoIco />
          </Box>
        </Tooltip>
      </Stack>
      <Button variant="contained" onClick={verifyPin}>
        {t(msgIds.MSG_LOGIN_BUTTON_TITLE)}
      </Button>
    </StyledLoginForm>
  )
}
