import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function AccountIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 12 2 C 6.480011 2 2 6.480011 2 12 C 2 17.519988 6.480011 22 12 22 C 17.519988 22 22 17.519988 22 12 C 22 6.480011 17.519988 2 12 2 z M 12 4 C 16.419992 4 20 7.5800088 20 12 C 20 13.949996 19.300623 15.729143 18.140625 17.119141 C 16.450629 15.799143 14.319996 15 12 15 C 9.6800046 15 7.5493716 15.799143 5.859375 17.119141 C 4.6993774 15.729143 4 13.949996 4 12 C 4 7.5800088 7.5800088 4 12 4 z M 12 6 C 10.070004 6 8.5 7.5700038 8.5 9.5 C 8.5 11.429996 10.070004 13 12 13 C 13.929996 13 15.5 11.429996 15.5 9.5 C 15.5 7.5700038 13.929996 6 12 6 z "
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
