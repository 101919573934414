import { ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from '@mui/material'
import {
  AuthorizationIco,
  ChronologyIco,
  DeleteIco,
  DeliverIco,
  DetailsIco,
  DocSharedIco,
  DownloadIco,
  DoxAddIco,
  DoxOrganizedAddIco,
  EditIco,
  RetentionStopIco,
  SwapVertIco,
} from '../../components/icons'
import { Dox } from '../../models/Dox'
import { AccountType, ActionType, isConsumer, isOperator } from '../../shared/Constants'
import { ArchiveTypes } from '../../models/ArchiveTypes'
import { useAuthContext } from '../../contexts/AuthContext'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'
import { IDoxCommandsMenuProps } from './DoxCommandsMenu.types'
import { useArchiveContext } from '../../contexts/ArchiveContext'

export default function DoxCommandsMenu(props: IDoxCommandsMenuProps): JSX.Element {
  const { t } = useTranslation()
  const archiveContext = useArchiveContext()
  const authContext = useAuthContext()
  const profileType = authContext.loggedProfileType
  const open = Boolean(props.anchorEl)

  function onClick(command: IDoxCommand) {
    props.onDoxCommand(props.dox, command.id)
    props.onClose()
  }

  const commands: IDoxCommand[] = (() => {
    if (isConsumer(profileType)) {
      if (archiveContext.archiveType === ArchiveTypes.customerArchiveOwned) {
        const commands = []
        if (!props.dox.isRoot) commands.push(showAuthorizations)
        commands.push(createNewDox)
        if (!props.dox.isRoot) {
          commands.push(modifyDox)
          commands.push(moveDox)
          commands.push(deleteDox)
        }
        commands.push(downloadDox)
        commands.push(showDoxHistory)
        commands.push(showDoxDetails)
        return commands
      } else if (archiveContext.archiveType === ArchiveTypes.guestArchive) {
        const commands = []
        if (authContext.loggedAccount?.canDo(ActionType.updateDox)) {
          commands.push(createNewDox)
          if (!props.dox.isRoot) {
            commands.push(modifyDox)
            commands.push(moveDox)
            commands.push(deleteDox)
          }
        }
        if (authContext.loggedAccount?.canDo(ActionType.downloadDox)) {
          commands.push(downloadDox)
        }
        return commands
      } else {
        const commands = []
        commands.push(moveDox)
        commands.push(deleteDox)
        commands.push(downloadDox)
        commands.push(showDoxHistory)
        commands.push(showDoxDetails)
        return commands
      }
    } else if (isOperator(profileType)) {
      if (archiveContext.archiveType === ArchiveTypes.structureArchiveSharedByCustomer) {
        const commands = []
        commands.push(showAuthorizations)
        commands.push(downloadDox)
        commands.push(showDoxHistory)
        commands.push(showDoxDetails)
        return commands
      } else {
        const commands = []
        commands.push(createNewDox)
        commands.push(createNewOrganizedDox)
        commands.push(modifyDox)
        if (!props.dox.isRoot) {
          commands.push(moveDox)
          commands.push(deleteDox)
        }
        commands.push(downloadDox)
        commands.push(showDoxHistory)
        commands.push(showDoxDetails)
        if (props.dox.treatmentId) {
          commands.push(terminateRetention)
        }
        if (!props.dox.isRoot) {
          commands.push(deliverDox)
        }
        if (authContext.assistedAccount?.user?.accountType === AccountType.placeholderUser) {
          commands.push(shareDox)
        }
        return commands
      }
    }

    return []
  })()

  return (
    <Menu
      id="dox-commands-menu"
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      open={open}
      sx={{
        maxHeight: 600,
      }}
      MenuListProps={{
        'aria-labelledby': props.anchorId,
      }}
    >
      <MenuList>
        {commands.map((command) => (
          <MenuItem key={command.id} onClick={() => onClick(command)}>
            <ListItemIcon>{command.icon}</ListItemIcon>
            <ListItemText>{t(command.label)}</ListItemText>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

interface IDoxCommand {
  id: string
  label: string
  action: (dox: Dox) => void
  icon: JSX.Element
}

const showAuthorizations: IDoxCommand = {
  id: 'showAuthorizations',
  label: msgIds.MSG_DOX_COMMAND_SHOW_AUTHORIZATIONS,
  action: console.log,
  icon: <AuthorizationIco />,
}

const createNewDox: IDoxCommand = {
  id: 'createNewDox',
  label: msgIds.MSG_DOX_COMMAND_CREATE_NEW_DOX,
  action: console.log,
  icon: <DoxAddIco />,
}

const createNewOrganizedDox: IDoxCommand = {
  id: 'createNewOrganizedDox',
  label: msgIds.MSG_DOX_COMMAND_CREATE_NEW_ORGANIZED_DOX,
  action: console.log,
  icon: <DoxOrganizedAddIco />,
}

const modifyDox: IDoxCommand = {
  id: 'modifyDox',
  label: msgIds.MSG_DOX_COMMAND_MODIFY_DOX,
  action: console.log,
  icon: <EditIco />,
}

const moveDox: IDoxCommand = {
  id: 'moveDox',
  label: msgIds.MSG_DOX_COMMAND_MOVE_DOX,
  action: console.log,
  icon: <SwapVertIco />,
}

const deleteDox: IDoxCommand = {
  id: 'deleteDox',
  label: msgIds.MSG_DOX_COMMAND_DELETE_DOX,
  action: console.log,
  icon: <DeleteIco />,
}

const showDoxHistory: IDoxCommand = {
  id: 'showDoxHistory',
  label: msgIds.MSG_DOX_COMMAND_SHOW_DOX_CHRONOLOGY,
  action: console.log,
  icon: <ChronologyIco />,
}

const showDoxDetails: IDoxCommand = {
  id: 'showDoxDetails',
  label: msgIds.MSG_DOX_COMMAND_SHOW_DOX_DETAILS,
  action: console.log,
  icon: <DetailsIco />,
}

const downloadDox: IDoxCommand = {
  id: 'downloadDox',
  label: msgIds.MSG_DOX_COMMAND_DOWNLOAD_DOX,
  action: console.log,
  icon: <DownloadIco />,
}

const terminateRetention: IDoxCommand = {
  id: 'terminateRetention',
  label: msgIds.MSG_DOX_COMMAND_TERMINATE_RETENTION,
  action: console.log,
  icon: <RetentionStopIco />,
}

const deliverDox: IDoxCommand = {
  id: 'deliverDox',
  label: msgIds.MSG_DOX_COMMAND_DELIVER_DOX,
  action: console.log,
  icon: <DeliverIco />,
}

const shareDox: IDoxCommand = {
  id: 'shareDox',
  label: msgIds.MSG_DOX_COMMAND_SHARE_DOX,
  action: console.log,
  icon: <DocSharedIco />,
}
