import { NotificationsType, NotificationsType_all } from '../shared/Constants'
import { INotificationContextJson, NotificationContext } from './NotificationContext'

export interface INotificationJson {
  id: number
  activity_id: number
  user_id: number
  profile_id: number
  type: number
  title_id: string
  body_id: string
  pinned: boolean
  archived_at: string
  context?: INotificationContextJson
  created_at: string
  updated_at: string
}

export class Notification {
  id: number = 0
  activityId: number = 0
  userId: number = 0
  profileId: number = 0
  type: NotificationsType_all = NotificationsType.userPrivateMessage
  titleId: string = ''
  bodyId: string = ''
  pinned: boolean = false
  archivedAt?: Date = new Date(0)
  context?: NotificationContext
  createdAt: Date = new Date(0)
  updatedAt: Date = new Date(0)

  constructor() {}

  public static serialize(obj: Notification): INotificationJson {
    return {
      id: obj.id,
      activity_id: obj.activityId,
      user_id: obj.userId,
      profile_id: obj.profileId,
      type: obj.type,
      title_id: obj.titleId,
      body_id: obj.bodyId,
      pinned: obj.pinned,
      archived_at: obj.archivedAt ? obj.archivedAt.toISOString() : '',
      context: obj.context ? NotificationContext.serialize(obj.context, obj.type) : undefined,
      created_at: obj.createdAt.toISOString(),
      updated_at: obj.updatedAt.toISOString(),
    }
  }

  public static deserialize(json: INotificationJson): Notification {
    const res = new Notification()
    res.id = json.id
    res.activityId = json.activity_id
    res.userId = json.user_id
    res.profileId = json.profile_id
    res.type = json.type
    res.titleId = json.title_id
    res.bodyId = json.body_id
    res.pinned = json.pinned
    res.archivedAt = json.archived_at ? new Date(json.archived_at) : undefined
    res.context = json.context ? NotificationContext.deserialize(json.context, json.type) : undefined
    res.createdAt = new Date(json.created_at)
    res.updatedAt = new Date(json.updated_at)
    return res
  }

  public static serializeArray(objs: Notification[]): INotificationJson[] {
    const jsons = objs.map((p) => {
      return Notification.serialize(p)!
    })
    return jsons
  }

  public static deserializeArray(json: INotificationJson[]): Notification[] {
    const res = json.map((p) => {
      return Notification.deserialize(p)!
    })
    return res
  }
}
