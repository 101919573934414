import { Stack, TableRow, styled } from '@mui/material'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  border: 'unset !important',
  '& .MuiToggleButtonGroup-grouped': {
    border: '1px solid !important',
    borderRadius: theme.spacing(3),
    borderColor: 'unset',
    color: theme.palette.primary.contrastText,
  },
}))

export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&.Mui-selected': {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.darker,
  },
  '&.Mui-disabled': {
    color: 'Black',
    backgroundColor: '#f0ff00',
  },
}))

export const StyledToggleButtonGroupDoL = styled(ToggleButtonGroup)(({ theme }) => ({
  border: 'unset !important',
  '& .MuiToggleButtonGroup-grouped': {
    border: '1px solid !important',
    borderRadius: theme.spacing(3),
    borderColor: 'unset',
    color: theme.palette.primary.darkGray,
  },
}))

export const StyledToggleButtonDoL = styled(ToggleButton)(({ theme }) => ({
  '&.Mui-selected': {
    color: theme.palette.common.gray2,
    backgroundColor: theme.palette.common.gray7,
  },
  '&:hover': {
    color: theme.palette.primary.gray,
    backgroundColor: theme.palette.common.gray6,
  },
  '&.Mui-disabled': {
    color: 'Black',
    backgroundColor: '#f0ff00',
  },
}))

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.lighter,
  },
  //'&:last-child td, &:last-child th': { border: 0 },
}))

export const StyledLoginForm = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(4),
  width: '100%',
  maxWidth: '100%',
  padding: theme.spacing(4),
  backgroundColor: theme.palette.common.gray11,
  borderRadius: 16,
  [theme.breakpoints.up('sm')]: {
    maxWidth: ((theme.breakpoints.values.sm - theme.breakpoints.values.xs) / 3) * 2,
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: 'calc(100% - 70px)',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))
