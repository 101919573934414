import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function SearchIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 15.5,14.000004 h -0.8 l -0.3,-0.3 A 6.2591601,6.2591601 0 0 0 16.000001,9.5000034 6.5000001,6.5000001 0 1 0 9.5000001,16.000004 6.2591601,6.2591601 0 0 0 13.7,14.400003 l 0.3,0.3 v 0.800001 l 5.000001,5 1.5,-1.5 z m -5.9999999,0 A 4.5000001,4.5000001 0 1 1 14,9.5000034 4.48129,4.48129 0 0 1 9.5000001,14.000004 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
