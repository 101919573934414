import { Button, Stack, Typography } from '@mui/material'

export default function ErrorFallbackPage() {
  const navigateToHome = () => {
    window.location.href = '/login-or-register'
  }

  return (
    <Stack spacing={8} sx={{ alignItems: 'center', paddingTop: 8, paddingX: 2 }}>
      <Typography textAlign="center" variant={'h3'}>
        {'Oops, qualcosa è andato storto!'}
      </Typography>
      <Stack spacing={4} marginTop={4}>
        <Button
          variant="contained"
          onClick={navigateToHome}
          sx={{
            height: 70,
            width: '100%',
            fontSize: '16px',
            textTransform: 'none',
            backgroundColor: '#2d66ba',
            color: '#ffffff',
          }}
        >
          {'Vai alla home'}
        </Button>
        <Button
          variant="contained"
          sx={{
            height: 70,
            width: '100%',
            fontSize: '16px',
            textTransform: 'none',
            backgroundColor: '#2d66ba',
            color: '#ffffff',
          }}
          onClick={() => window.location.reload()}
        >
          {'Riprova'}
        </Button>
      </Stack>
    </Stack>
  )
}
