import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function ArrowUpIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 3.9999999,12 1.41,1.41 L 11,7.8299999 V 19.999999 h 2 V 7.8299998 L 18.579999,13.42 19.999999,12 12,3.9999999 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
