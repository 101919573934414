import { useTranslation } from 'react-i18next'
import CommandBar from '../../components/commandBar/CommandBar'
import { PageContainer, PageContent } from '../../components/pageContainer/PageContainer'
import msgIds from '../../locales/msgIds'
import { LoginOrRegister } from '../../components/loginOrRegister/LoginOrRegister'

export function LoginOrRegisterPage(): JSX.Element {
  const { t } = useTranslation()
  return (
    <PageContainer>
      <CommandBar style={{ minHeight: 'auto' }} title={t(msgIds.MSG_PRIVATE_AREA_SELECTION_TITLE)} commands={[]} />
      <PageContent>
        <LoginOrRegister />
      </PageContent>
    </PageContainer>
  )
}
