import { ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from '@mui/material'
import {
  AddToDesktopIco,
  ArchiveIco,
  AuthorizationIco,
  ChronologyIco,
  DeleteIco,
  DiaryStructureRemoveIco,
  DownloadIco,
  DoxIco,
  EditIco,
  InterdictIco,
  UserAnonymousIco,
  UserNotAnonymousIco,
} from '../../components/icons'
import { useTranslation } from 'react-i18next'
import { IContextState, useAuthContext } from '../../contexts/AuthContext'
import { Document } from '../../models/Document'
import { isBusiness } from '../../shared/Constants'
import msgIds from '../../locales/msgIds'
import { IDocCommandsMenuProps } from './DocCommandsMenu.types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import * as dalPermission from '../../dal/DalPermission'
import { Authorization } from '../../models/Authorization'
import { ArchiveTypes } from '../../models/ArchiveTypes'
import { Utils } from '../../shared/Utils'
import { useArchiveContext } from '../../contexts/ArchiveContext'

export default function DocCommandsMenu(props: IDocCommandsMenuProps): JSX.Element {
  const { t } = useTranslation()
  const archiveContext = useArchiveContext()
  const authContext = useAuthContext()
  const open = Boolean(props.anchorEl)
  const [authorizations, setAuthorizations] = useState<Authorization[]>([])

  const downloadAllowed = useMemo(() => {
    if (!authorizations) {
      return false
    }
    return authorizations.reduce((allowed, a) => allowed || (a ? a.isDownloadPermitted : false), false)
  }, [authorizations])

  const modifyAllowed = useMemo(() => {
    if (!authorizations) {
      return false
    }
    return authorizations.reduce((allowed, a) => allowed || (a ? a.isUpdatePermitted : false), false)
  }, [authorizations])

  const deleteAllowed = useMemo(() => {
    if (!authorizations) {
      return false
    }
    return authorizations.reduce((allowed, a) => allowed || (a ? a.isDeletePermitted : false), false)
  }, [authorizations])

  function onClick(command: IDocCommand) {
    props.onDocumentCommand(props.doc, command.id)
    props.onClose()
  }

  useEffect(() => {
    const loadAuthorizations = async (abortSignal: AbortSignal) => {
      try {
        setAuthorizations([])
        const authorizations = await dalPermission.getDocumentAuthorizations(
          abortSignal,
          props.doc.documentId,
          authContext.loggedProfileId ? [authContext.loggedProfileId] : []
        )
        setAuthorizations(authorizations)
      } catch (err) {
        Utils.enqueueSnackbarError2(err, t)
      }
    }

    const abortController = new AbortController()
    loadAuthorizations(abortController.signal)

    return () => {
      abortController.abort()
    }
  }, [props.doc.documentId, authContext.loggedProfileId])

  const isCommandVisible = useCallback(
    (command: IDocCommand) => {
      const visible = command.enabled(props.doc, authContext, archiveContext.archiveType)
      return visible
    },
    [props.doc, authContext, archiveContext.archiveType]
  )

  const isCommandDisabled = useCallback(
    (command: IDocCommand) => {
      if (command.id === 'download') {
        return !downloadAllowed
      } else if (command.id === 'modify') {
        return !modifyAllowed
      } else if (command.id === 'delete') {
        return !deleteAllowed
      }
      return false
    },
    [downloadAllowed, modifyAllowed, deleteAllowed]
  )

  return (
    <Menu
      id="doc-commands-menu"
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      open={open}
      sx={{
        maxHeight: 500,
      }}
      MenuListProps={{
        'aria-labelledby': props.anchorId,
      }}
    >
      <MenuList>
        {commands.filter(isCommandVisible).map((command) => (
          <MenuItem disabled={isCommandDisabled(command)} key={command.id} onClick={() => onClick(command)}>
            <ListItemIcon>{command.icon(props.doc, authContext)}</ListItemIcon>
            <ListItemText>
              {typeof command.label === 'string' ? t(command.label) : t(command.label(props.doc, authContext))}
            </ListItemText>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

interface IDocCommand {
  id: string
  label: string | ((doc: Document, authContext: IContextState) => string)
  icon: (doc: Document, authContext: IContextState) => JSX.Element
  enabled: (doc: Document, authContext: IContextState, archiveType: ArchiveTypes) => boolean
}

const commands: IDocCommand[] = [
  {
    id: 'associateToDox',
    label: msgIds.MSG_DOC_COMMAND_ASSOCIATE_TO_DOX,
    icon: (_doc: Document, _authContext: IContextState) => {
      return <DoxIco />
    },
    enabled: () => true,
  },
  {
    id: 'showAuthorizations',
    label: msgIds.MSG_DOC_COMMAND_SHOW_AUTHORIZATIONS,
    icon: (_doc: Document, _authContext: IContextState) => {
      return <AuthorizationIco />
    },
    enabled: (doc: Document, authContext: IContextState, archiveType: ArchiveTypes) => {
      const business = isBusiness(authContext.loggedProfileType) && !authContext.loggedAccount?.isGuest
      return Boolean(
        doc.lastArchivedRevisionId &&
          ((!business && archiveType === ArchiveTypes.customerArchiveOwned) ||
            (business && doc.ownerProfileId !== authContext.linkedStructureProfileId))
      )
    },
  },
  {
    id: 'removeFromStructureArchive',
    label: msgIds.MSG_DOC_COMMAND_REMOVE_FROM_STRUCTURE_ARCHIVE,
    icon: (_doc: Document, _authContext: IContextState) => {
      return <DiaryStructureRemoveIco />
    },
    enabled: (doc: Document, authContext: IContextState, archiveType: ArchiveTypes) => {
      return Boolean(
        isBusiness(authContext.loggedProfileType) &&
          doc.ownerProfileId !== authContext.linkedStructureProfileId &&
          archiveType === ArchiveTypes.structureArchiveForCustomer &&
          !authContext.loggedAccount?.isGuest
      )
    },
  },
  {
    id: 'markAsObsolete',
    label: msgIds.MSG_DOC_COMMAND_MARK_AS_OBSOLETE,
    icon: (_doc: Document, _authContext: IContextState) => {
      return <InterdictIco />
    },
    enabled: (doc: Document, authContext: IContextState) => {
      const canMarkAsObsolete = !doc.expiredAt
      return Boolean(
        doc.authorProfileId === authContext.loggedProfileId && doc.lastArchivedRevisionId && canMarkAsObsolete
      )
    },
  },
  {
    id: 'showHistory',
    label: msgIds.MSG_DOC_COMMAND_SHOW_CHRONOLOGY,
    icon: (_doc: Document, _authContext: IContextState) => {
      return <ChronologyIco />
    },
    enabled: (_doc: Document, authContext: IContextState) => {
      return !authContext.loggedAccount?.isGuest ?? true
    },
  },
  {
    id: 'archive',
    label: msgIds.MSG_DOC_COMMAND_ARCHIVE,
    icon: (_doc: Document, _authContext: IContextState) => {
      return <ArchiveIco />
    },
    enabled: (doc: Document, authContext: IContextState) => {
      return doc.authorProfileId === authContext.loggedProfileId && !!doc.draftRevisionId
    },
  },
  {
    id: 'addToDesktop',
    label: msgIds.MSG_DOC_COMMAND_ADD_DOCUMENT_TO_DESKTOP,
    icon: (_doc: Document, _authContext: IContextState) => {
      return <AddToDesktopIco />
    },
    enabled: (_doc: Document, authContext: IContextState) => {
      return !authContext.loggedAccount?.isGuest ?? true
    },
  },
  {
    id: 'toggleAnonymousState',
    label: (doc: Document, _authContext: IContextState) => {
      if (doc.anonymousAt) {
        return msgIds.MSG_DOC_COMMAND_UNSET_ANONYMOUS_STATE
      } else {
        return msgIds.MSG_DOC_COMMAND_SET_ANONYMOUS_STATE
      }
    },
    icon: (doc: Document, _authContext: IContextState) => {
      if (doc.anonymousAt) {
        return <UserNotAnonymousIco />
      } else {
        return <UserAnonymousIco />
      }
    },
    enabled: (_doc: Document, authContext: IContextState) => {
      return isBusiness(authContext.loggedProfileType) && !authContext.loggedAccount?.isGuest
    },
  },
  {
    id: 'modify',
    label: msgIds.MSG_DOC_COMMAND_MODIFY,
    icon: (_doc: Document, _authContext: IContextState) => {
      return <EditIco />
    },
    enabled: (doc: Document, authContext: IContextState) => {
      // handled in the component after backend call
      return !!doc.documentId && doc.authorProfileId === authContext.loggedProfileId
    },
  },
  {
    id: 'download',
    label: msgIds.MSG_DOC_COMMAND_DOWNLOAD,
    icon: (_doc: Document, _authContext: IContextState) => {
      return <DownloadIco />
    },
    enabled: (_doc: Document, _authContext: IContextState) => {
      // handled in the component after backend call
      return true
    },
  },
  {
    id: 'delete',
    label: msgIds.MSG_DOC_COMMAND_DELETE,
    icon: (_doc: Document, _authContext: IContextState) => {
      return <DeleteIco />
    },
    enabled: (doc: Document, authContext: IContextState) => {
      // handled in the component after backend call
      return !!doc.documentId && doc.authorProfileId === authContext.loggedProfileId
    },
  },
]
