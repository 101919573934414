import { Box, Divider, List, ListItem, Typography, useTheme } from '@mui/material'
import { IKeyValueTableProps } from './KeyValueTable.types'
import { useLayout } from '../../hooks/Layout'

export function KeyValueTable(props: IKeyValueTableProps): JSX.Element {
  const theme = useTheme()
  const { isMobile } = useLayout()

  return (
    <List>
      {props.data.map((data, index) => (
        <Box key={data.label}>
          <ListItem sx={{ display: 'flex', justifyContent: 'stretch', gap: 2, alignItems: 'center', flexWrap: 'wrap' }}>
            <Typography
              sx={{
                flex: isMobile ? 1 : 0.6,
                flexGrow: 1,
                flexShrink: 0,
                color: theme.palette.common.gray5,
              }}
            >
              {data.label}
            </Typography>
            <Typography
              sx={{
                flex: isMobile ? 1 : 1.4,
                flexShrink: 0,
                color: theme.palette.common.gray0,
              }}
            >
              {data.value}
            </Typography>
          </ListItem>
          {(!props.hideLastDivider || index !== props.data.length - 1) && <Divider sx={{ marginX: 2, marginY: 1 }} />}
        </Box>
      ))}
    </List>
  )
}
