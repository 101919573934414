import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function AutorenewIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 11.999268,0.99975422 8.0002443,5.000975 11.999268,8.9999985 V 6.0007309 A 6.0047551,6.0047551 0 0 1 18,11.999266 5.870365,5.870365 0 0 1 17.299073,14.80078 l 1.461181,1.458985 A 7.9859351,7.9859351 0 0 0 11.999268,3.9990218 Z M 5.2404786,7.7409652 A 7.9859601,7.9859601 0 0 0 11.999268,19.999511 v 3.001464 l 4.00122,-4.00122 -4.00122,-3.999024 v 2.999268 A 6.0047551,6.0047551 0 0 1 6.0007326,11.999266 5.8702801,5.8702801 0 0 1 6.699463,9.1999497 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
