import React from 'react'
import { IResponsiveDataGridProps } from './ResponsiveDataGrid.types'
import { DataGrid, GridCellParams, GridColDef, GridRowParams, GridValidRowModel, itIT, enUS } from '@mui/x-data-grid'
import { Box, Divider, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

type ResponsiveDataGridProps = <T extends GridValidRowModel>(
  p: IResponsiveDataGridProps<T>
) => React.ReactElement<IResponsiveDataGridProps<T>>
const ResponsiveDataGrid: ResponsiveDataGridProps = <T extends GridValidRowModel>({ ...props }) => {
  const theme = useTheme()
  const { i18n, t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const dataGridStyle = {
    '& .MuiDataGrid-cell:focus': {
      outline: 'inherit',
    },
  }

  function getLocale() {
    switch (i18n.language) {
      case 'it':
        return itIT.components.MuiDataGrid.defaultProps.localeText
      case 'en':
        return enUS.components.MuiDataGrid.defaultProps.localeText
      default:
        return itIT.components.MuiDataGrid.defaultProps.localeText
    }
  }

  const handleRowClick = (params: Partial<GridRowParams>) => {
    if (props.onRowClick) {
      props.onRowClick(params)
    }
  }
  const handleCellClick = (params: Partial<GridCellParams>) => {
    if (props.onCellClick) {
      props.onCellClick(params)
    }
  }
  const renderCell = (col: GridColDef<any>, row: T) => {
    const value = row[col.field]

    // !! Per mantenere la compatibilità con la versione mobile della griglia non utilizzare proprietà non definite nelle funzioni renderCell delle DataGrid
    // @ts-ignore
    // prettier-ignore
    const displayValue = col.renderCell ? col.renderCell({
      cellMode: 'view',
      field: col.field,
      formattedValue: undefined,
      hasFocus: false,
      id: row['id'],
      tabIndex: -1,
      value: value,
      row
    }) : value
    return (
      <Box
        onClick={() => handleCellClick({ row, field: col.field })}
        alignItems="center"
        display="flex"
        flexDirection="row"
        gap={1}
        flexGrow={1}
      >
        <Typography variant="body1">
          <strong>{col.headerName}:</strong>
        </Typography>
        {col.renderCell ? displayValue : <Typography variant="body1">{displayValue}</Typography>}
      </Box>
    )
  }

  if (isMobile) {
    return (
      <Box px={1} pt={1}>
        <Box display="flex" flexDirection="column" gap={1}>
          {props.rows.map((row: T, i: number) => (
            <Box key={i} display="flex" flexDirection="column" onClick={() => handleRowClick({ row })}>
              {props.columns.map((col: GridColDef<T>, j: number) => (
                <React.Fragment key={`${i}-${j}`}>{renderCell(col, row)}</React.Fragment>
              ))}
              <Divider sx={{ mt: 1 }} />
            </Box>
          ))}
        </Box>
      </Box>
    )
  }

  // @ts-ignore
  return <DataGrid {...props} sx={dataGridStyle} localeText={getLocale()} />
}

export default ResponsiveDataGrid
