import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function DiaryStructureRemoveIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 3.574951,2.1577151 2.1379394,3.5991212 20.42578,21.843018 21.862792,20.401611 Z m 2.9245605,1.3425291 c -0.043518,0 -0.088176,0.00619 -0.1318365,0.0066 l 6.631349,6.6159588 V 6.4995117 c 1.422808,-0.6163132 2.950072,-0.9554619 4.5,-0.9997558 1.18534,-0.012825 2.365908,0.1567659 3.500244,0.5009766 V 17.50122 c -0.27267,-0.08274 -0.546561,-0.155161 -0.823976,-0.21753 l 2.403809,2.397218 -0.355958,0.358155 c 0.0085,0.0042 0.0178,0.0068 0.02637,0.0111 0.07679,0.04038 0.164079,0.05827 0.250488,0.05053 0.268231,-0.01823 0.48275,-0.232748 0.500976,-0.500978 V 5.0009766 C 22.393861,4.5598887 21.716406,4.2222597 20.999268,4.0012208 19.864162,3.6604917 18.684126,3.4909733 17.499024,3.5002442 c -1.950003,0 -4.049756,0.4007325 -5.499756,1.5007324 C 10.549266,3.9009767 8.4495115,3.5002442 6.4995115,3.5002442 Z M 1.672119,4.5703125 C 1.4363267,4.7001783 1.2102102,4.8413216 0.99975583,5.0009766 V 19.650146 c 0.0182291,0.268231 0.23274717,0.482746 0.50097657,0.500976 0.1000005,0 0.1482915,-0.05053 0.248291,-0.05053 1.4932372,-0.682403 3.1092874,-1.057361 4.7504881,-1.100831 1.95,0 4.0497545,0.400734 5.4997565,1.500732 1.318759,-0.729306 2.76077,-1.193544 4.247313,-1.390869 L 14.587646,17.455077 C 14.047938,17.602187 13.5148,17.776583 12.999024,18 v -2.131348 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
