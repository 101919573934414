import DataEditorHeader from '../dataEditorHeader/DataEditorHeader'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { EditIco } from '../icons'
import msgIds from '../../locales/msgIds'
import { PasswordEditor } from './PasswordEditor'
import { IPasswordEditorFormProps } from './PasswordEditorForm.types'
import { DataEditorContainer } from '../dataEditorContainer/DataEditorContainer'

export function PasswordEditorForm(props: IPasswordEditorFormProps): JSX.Element {
  const { t } = useTranslation()
  const [isEditMode, setIsEditMode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  return (
    <DataEditorContainer isLoading={isLoading}>
      <DataEditorHeader
        title={t(msgIds.MSG_PASSWORD_EDITOR_TITLE)}
        isEditMode={isEditMode}
        viewModeCommands={[
          {
            commandText: '',
            icon: <EditIco />,
            onClick: () => setIsEditMode(true),
            tooltipText: t(msgIds.MSG_MODIFY) ?? undefined,
            disabled: false,
          },
        ]}
        editModeCommands={[]}
      />
      <PasswordEditor
        isEditMode={isEditMode}
        onCancel={() => setIsEditMode(false)}
        onSave={() => setIsEditMode(false)}
        setIsLoading={setIsLoading}
      />
    </DataEditorContainer>
  )
}
