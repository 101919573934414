import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function AnagraphicIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 5.0009767,2.9992659 A 1.999445,1.999445 0 0 0 2.9992676,5.000975 v 13.99878 a 1.999445,1.999445 0 0 0 2.0017091,1.999512 H 18.999756 a 2.00588,2.00588 0 0 0 1.999512,-1.999512 V 5.000975 A 2.00588,2.00588 0 0 0 18.999756,2.9992659 Z m 0,2.0017091 H 18.999756 v 13.99878 H 5.0009767 Z M 11.638916,6.0227035 A 3.0000001,3.0000001 0 0 0 9.0000002,8.9999985 3.0088201,3.0088201 0 0 0 11.999268,11.999266 3.0000001,3.0000001 0 1 0 11.638916,6.0227035 Z m 0.360352,1.9775392 a 1,1 0 1 1 -0.999756,0.9997558 1.00294,1.00294 0 0 1 0.999756,-0.9997558 z m 0,4.9987793 c -2.0299981,0 -5.9985354,1.081545 -5.9985354,3.581543 v 1.419434 H 18 v -1.419434 c 0,-2.499998 -3.970734,-3.581543 -6.000732,-3.581543 z m 0,2.001709 a 7.0417401,7.0417401 0 0 1 3.520019,0.999756 H 8.4792482 a 7.0417401,7.0417401 0 0 1 3.5200198,-0.999756 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
