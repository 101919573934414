import { Dox } from '../../models/Dox'

export enum DoxSelectionMode {
  single,
  multiple,
  hierarchical,
}

export interface IDoxesNavProps {
  selectionMode: DoxSelectionMode
  selection: Dox[]
  selectedDoxId?: number
  onChangeSelection: (selection: Dox[]) => void
  onDoxCommand: (dox: Dox, action: string) => void
}
