import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Center } from '../../center/Center'
import { ThemeProvider } from '@mui/material'
import { getProfileBasedTheme } from '../../../themes/ThemeUtils'
import {
  AccountType,
  AppType,
  ContractAcceptanceStatus,
  isConsumer,
  isOperator,
  ProfileType,
} from '../../../shared/Constants'
import queryString from 'query-string'
import * as dalAccount from '../../../dal/DalAccount'
import msgIds from '../../../locales/msgIds'
import { ILoginTemplateProps } from './LoginTemplate.types'
import { Account } from '../../../models/Account'
import { Utils } from '../../../shared/Utils'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ContractsUpdateWarningDialog } from '../../../dialogs/contractsUpdateWarningDialog/ContractsUpdateWarningDialog'
import { AccountLegalStatus } from '../../../models/AccountLegalStatus'
import { StyledDialog } from '../../../dialogs/styledDialog/StyledDialog'
import { ViewHeader } from '../../viewHeader/ViewHeader'
import { ViewContent } from '../../viewContent/ViewContent'
import { ProfileSelector } from '../../profileSelector/ProfileSelector'
import { useAuthContext } from '../../../contexts/AuthContext'
import SimpleDialog from '../../../dialogs/simpleDialog/SimpleDialog'
import { ISimpleDialogData } from '../../../dialogs/simpleDialog/SimpleDialog.types'
import { Permission } from '../../../models/Permission'
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay'

export function LoginTemplate(): JSX.Element {
  const { search } = useLocation()
  const params: ILoginTemplateProps = queryString.parse(search, { parseNumbers: true, parseBooleans: true })
  const profileType: ProfileType = params.profileType ?? ProfileType.operatorAdmin

  const { t } = useTranslation()
  const navigate = useNavigate()
  const authContext = useAuthContext()
  const appType = isOperator(profileType) ? AppType.business : AppType.consumer
  const theme = getProfileBasedTheme(appType === AppType.business ? ProfileType.operatorAdmin : ProfileType.customer)

  const [isLoading, setIsLoading] = useState(false)
  const [availableAccounts, setAvailableAccounts] = useState<Account[]>([])
  const [accountLegalStatus, setAccountLegalStatus] = useState<AccountLegalStatus>()

  // dialogs
  const [simpleDialogData, setSimpleDialogData] = useState<ISimpleDialogData>()
  const [simpleDialogOpen, setSimpleDialogOpen] = useState(false)
  const [profileSelectorDialogOpen, setProfileSelectorDialogOpen] = useState(false)
  const [contractsUpdateWarningDialogOpen, setContractsUpdateWarningDialogOpen] = useState(false)
  function closeContractsUpdateWarningDialog() {
    setContractsUpdateWarningDialogOpen(false)
  }

  async function onUserLogged(
    abortSignal: AbortSignal,
    account: Account,
    desiredProfileType: ProfileType,
    permission?: Permission
  ) {
    // If the user arrives from the product page, or does not have a default profile,
    // must select which of his profiles wants to use (for login o to apply the changes is making to)
    const haveDefaultProfile = isOperator(desiredProfileType)
      ? !!account.user?.defaultBusinessProfile
      : !!account.user?.defaultConsumerProfile
    const pathname = authContext.getDestinationAfterLogin()?.pathname
    const isFromProducts = pathname?.includes('billing/products')
    if (isFromProducts || !account.profile || !haveDefaultProfile) {
      await checkAvailableAccounts(abortSignal, desiredProfileType)
    } else {
      await useAccount(account, permission)
    }
  }

  async function checkAvailableAccounts(abortSignal: AbortSignal, desiredProfileType: ProfileType) {
    const allAccounts = await dalAccount.getLoggedAvailableAccounts(abortSignal)
    const accounts = isOperator(desiredProfileType)
      ? allAccounts.filter((p) => isOperator(p.profile?.type) && !p.profile?.expiredAt)
      : allAccounts.filter((p) => isConsumer(p.profile?.type) && !p.profile?.expiredAt)
    setAvailableAccounts(accounts)
    if (accounts.length > 1) {
      setProfileSelectorDialogOpen(true)
    } else if (accounts.length === 1) {
      useAccount(accounts[0])
    } else if (accounts.length === 0) {
      setSimpleDialogOpen(true)
      setSimpleDialogData({
        title: t(msgIds.MSG_LOGIN_FAILED_DIALOG_TITLE),
        content: isOperator(desiredProfileType)
          ? t(msgIds.MSG_LOGIN_FAILED_DIALOG_NO_AVAILABLE_BUSINESS_PROFILES)
          : t(msgIds.MSG_LOGIN_FAILED_DIALOG_NO_AVAILABLE_CONSUMER_PROFILES),
        actionsStyle: 'Ok',
        onClose: async (result) => {
          setSimpleDialogOpen(false)
        },
      })
    }
  }

  async function useAccount(account: Account, permission?: Permission) {
    if (!account.profile) return

    authContext.setLoggedAccount(account)
    if (account.user?.accountType === AccountType.placeholderUser) {
      navigate('/archive', {
        state: {
          isPerspective: true,
          onlyReceived: false,
          grantorProfileId: permission?.authorId,
          selectedDoxId: permission?.targetId,
        },
      })
      return
    }

    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const { accountLegalStatus, consents } = await Utils.checkLegalStatus(
        abortController.signal,
        account.profile.type
      )
      setAccountLegalStatus(accountLegalStatus)

      switch (accountLegalStatus.pdxAcceptanceStatusForLoggedProfile) {
        case ContractAcceptanceStatus.toAccept:
        case ContractAcceptanceStatus.implicityAcceptedButNotConfirmed:
        case ContractAcceptanceStatus.implicityAcceptedOptionalConsentsRequiredButNotConfirmed:
          setContractsUpdateWarningDialogOpen(true)
          break
        case ContractAcceptanceStatus.accepted:
        case ContractAcceptanceStatus.implicityAcceptedAndConfirmed:
          authContext.navigateToLastLocationAsLogged()
          break
      }
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Center height="100%" minHeight="100vh" p={0}>
        <Outlet context={{ profileType, onUserLogged, setIsLoading }} />
        <LoadingOverlay isLoading={isLoading}></LoadingOverlay>
      </Center>

      {simpleDialogData && <SimpleDialog {...simpleDialogData} isOpen={simpleDialogOpen}></SimpleDialog>}

      <StyledDialog
        minHeight="auto"
        open={profileSelectorDialogOpen}
        onClose={() => setProfileSelectorDialogOpen(false)}
      >
        <ViewHeader
          title={t(msgIds.MSG_PROFILE_SELECTOR_DIALOG_TITLE)}
          exitButtonVisible={true}
          onClickExit={() => setProfileSelectorDialogOpen(false)}
        />
        <ViewContent>
          <ProfileSelector
            accounts={availableAccounts}
            onChangeAccount={async (account) => {
              setProfileSelectorDialogOpen(false)
              await useAccount(account)
            }}
          />
        </ViewContent>
      </StyledDialog>

      <ContractsUpdateWarningDialog
        isOpen={contractsUpdateWarningDialogOpen}
        onClose={closeContractsUpdateWarningDialog}
        profileType={profileType ?? ProfileType.none}
        accountLegalStatus={accountLegalStatus ?? new AccountLegalStatus()}
      />
    </ThemeProvider>
  )
}
