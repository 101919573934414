import { styled } from '@mui/material'
import { Box } from '@mui/material'

import Topbar from '../../topbar/Topbar'
import Leftbar from '../../leftbar/Leftbar'
import { useUiContext, topbarHeight, leftbarWidth } from '../../../contexts/UiContext'
import { IMainTemplateProps } from './MainTemplate.types'
import { Navigate, Outlet } from 'react-router'
import { useAuthContext } from '../../../contexts/AuthContext'

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(1.6, 3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}))

export default function MainTemplate(props: IMainTemplateProps) {
  const uiContext = useUiContext()
  const authContext = useAuthContext()
  const isLeftbarUsed = authContext.loggedAccount && !authContext.loggedAccount.isGuest

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Topbar />
      <Box
        sx={{
          width: '100%',
          height: `calc(100% - ${topbarHeight}px)`,
          display: 'flex',
          backgroundColor: '#eeeeee',
        }}
      >
        {isLeftbarUsed && <Leftbar />}
        <Main
          sx={{
            overflowY: 'auto',
            maxWidth: '100vw',
            p: 0,
            marginLeft: isLeftbarUsed ? (uiContext.isLeftbarOpened ? '0px' : `-${leftbarWidth}px`) : '0px',
          }}
          open={uiContext.isLeftbarOpened}
        >
          {props.protectedRoutes ? (
            <>{authContext.loggedAccount ? <Outlet /> : <Navigate to="/login?profileType=1" replace />}</>
          ) : (
            <Outlet />
          )}
        </Main>
      </Box>
    </Box>
  )
}
