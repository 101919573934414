import { Button, ButtonProps, IconButton, Typography, styled, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import TreatmentListDialog from '../../dialogs/treatmentListDialog/TreatmentListDialog'
import { CloseIco } from '../icons'
import { Treatment } from '../../models/Treatment'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { Utils } from '../../shared/Utils'
import { useAuthContext } from '../../contexts/AuthContext'
import * as dalTreatment from '../../dal/DalTreatment'
import { ITreatmentSelectProps } from './TreatmentSelect.types'

export function TreatmentSelect(props: ITreatmentSelectProps): JSX.Element {
  const { disabled, treatmentId, onChangeTreatment } = props
  const authContext = useAuthContext()
  const { t } = useTranslation()
  const [treatmentListDialogOpen, setTreatmentListDialogOpen] = useState(false)
  const theme = useTheme()
  const [treatments, setTreatments] = useState<Treatment[]>(props.treatments || [])
  const treatment = treatments.find(({ id }) => id === treatmentId)

  useEffect(() => {
    if (props.treatments) {
      setTreatments(props.treatments)
      return
    }
    const abortController = new AbortController()
    dalTreatment
      .getTreatments(abortController.signal, authContext.linkedStructureProfileId || 0, true, false)
      .then(setTreatments)
      .catch(Utils.enqueueSnackbarError(t))

    return () => {
      abortController.abort()
    }
  }, [props.treatments, authContext.linkedStructureProfileId])

  return (
    <>
      <SelectExternalButton
        disabled={disabled}
        onClick={() => setTreatmentListDialogOpen(true)}
        variant="outlined"
        color="secondary"
        sx={{
          justifyContent: 'space-between',
          m: 0,
          py: 1.9,
          px: 1.5,
          borderColor: theme.palette.common.gray6,
        }}
      >
        <Typography
          sx={{
            display: treatment ? 'block' : 'none',
            position: 'absolute',
            left: '8px',
            top: 'calc(-1em - 0.5px)',
            py: '1px',
            px: '6px',
            textAlign: 'left',
            textTransform: 'none',
            backgroundColor: 'white',
          }}
          variant="caption"
        >
          {t(msgIds.MSG_DOX_EDITOR_RETENTION_PLACEHOLDER)}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: theme.palette.text.primary, textTransform: 'none', textAlign: 'left' }}
        >
          {!treatment && (
            <Typography style={{ color: theme.palette.text.secondary }}>
              {t(msgIds.MSG_DOX_EDITOR_RETENTION_PLACEHOLDER)}
            </Typography>
          )}
          {treatment && (
            <>
              <span style={{ color: theme.palette.text.secondary }}>{t(msgIds.MSG_FINALITY)}:</span> {treatment.code}{' '}
              {treatment.shortDescription} <br />
              <span style={{ color: theme.palette.text.secondary }}>{t(msgIds.MSG_RETENTION)}:</span>{' '}
              {Utils.getRetentionDurationDsc(treatment, t)}
            </>
          )}
        </Typography>
        {treatment && (
          <IconButton
            onClick={(event) => {
              event.stopPropagation()
              onChangeTreatment(undefined)
            }}
          >
            <CloseIco />
          </IconButton>
        )}
      </SelectExternalButton>
      <TreatmentListDialog
        treatments={treatments}
        onResult={(result) => {
          setTreatmentListDialogOpen(false)
          if (result.userChoice !== 'yes') {
            return
          }
          const selectedTreatment = result.treatment
          if (selectedTreatment) {
            onChangeTreatment(selectedTreatment)
          }
        }}
        isOpen={treatmentListDialogOpen}
        onClose={() => setTreatmentListDialogOpen(false)}
      />
    </>
  )
}

const SelectExternalButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.common.white,
  },
}))
