export interface INotificationContextValuesJson {
  days_countdown: number
  name: number
}

export class NotificationContextValues {
  daysCountdown: number = 0
  name: number = 0

  constructor() {}

  public static serialize(obj: NotificationContextValues): INotificationContextValuesJson {
    return {
      days_countdown: obj.daysCountdown,
      name: obj.name,
    }
  }

  public static deserialize(json: INotificationContextValuesJson): NotificationContextValues {
    const res = new NotificationContextValues()
    res.daysCountdown = json.days_countdown
    res.name = json.name
    return res
  }
}
