import {
  Checkbox,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material'
import { Dox } from '../../models/Dox'
import { DoxSelectorMode, IDoxSelectorProps } from './DoxSelector.types'
import { MouseEvent, useEffect, useMemo, useState } from 'react'
import { ChevronRightIco, EditIco, AddIco, ExpandMoreIco, RetentionIco, RetentionDisabledIco } from '../icons'
import { useAuthContext } from '../../contexts/AuthContext'
import { isOperator } from '../../shared/Constants'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { ViewContent } from '../viewContent/ViewContent'
import { useArchiveContext } from '../../contexts/ArchiveContext'

export type CheckBoxState = 'true' | 'false' | 'indeterminate'

export function DoxSelector(props: IDoxSelectorProps): JSX.Element {
  const { t } = useTranslation()
  const authContext = useAuthContext()
  const archiveContext = useArchiveContext()
  const [selectionMap, setSelectionMap] = useState<{ [doxId: number]: CheckBoxState }>({})
  const [disabledDoxIds, setDisabledDoxIds] = useState<number[]>(props.disabledDoxId ? [props.disabledDoxId] : [])

  useEffect(() => {
    const _disabledDoxIds = new Set(props.disabledDoxId ? [props.disabledDoxId] : [])
    const assignableDoxes = archiveContext.getArchiveDoxesFromAcl(props.assignableDoxesAcl)
    assignableDoxes.distinct.forEach((dox) => {
      switch (props.selectionMode) {
        case DoxSelectorMode.selectMultiple: {
          break
        }
        case DoxSelectorMode.selectMultipleWithRetention: {
          disableIfWithoutRetention(dox, _disabledDoxIds)
          break
        }
        case DoxSelectorMode.selectSingleExceptSelfAndChilds: {
          disableIfSelfOrChild(_disabledDoxIds)
          break
        }
        case DoxSelectorMode.selectSingleWithRetentionExceptSelfAndChilds: {
          disableIfWithoutRetention(dox, _disabledDoxIds)
          disableIfSelfOrChild(_disabledDoxIds)
          break
        }
      }
    })
    setDisabledDoxIds(Array.from(_disabledDoxIds))
  }, [props.selectedDoxIds, props.undeterminedDoxIds])

  function disableIfWithoutRetention(dox: Dox, disabledDoxIds: Set<number>) {
    if (isOperator(authContext.loggedProfileType)) {
      if (!dox.isRetentionEnabled) {
        disabledDoxIds.add(dox.id)
      }
    }
  }

  function disableIfSelfOrChild(disabledDoxIds: Set<number>) {
    if (props.disabledDoxId) {
      const assignableDoxes = archiveContext.getArchiveDoxesFromAcl(props.assignableDoxesAcl)
      const disableDoxId = assignableDoxes.getDox(props.disabledDoxId)
      if (disableDoxId) {
        const parentDox = assignableDoxes.getDox(disableDoxId.parentId)
        if (parentDox) {
          disabledDoxIds.add(disableDoxId.parentId)
        }
        disableDoxId.iterateThroughtChilds(false, (childDox) => {
          disabledDoxIds.add(childDox.id)
        })
      }
    }
  }

  useEffect(() => {
    const assignableDoxes = archiveContext.getArchiveDoxesFromAcl(props.assignableDoxesAcl)
    let map = (assignableDoxes.roots || []).reduce((m, { id: doxId }) => {
      m[doxId] = 'false'
      return m
    }, {} as any)
    map = (props.selectedDoxIds || []).reduce((m, doxId) => {
      m[doxId] = 'true'
      return m
    }, map)
    map = (props.undeterminedDoxIds || []).reduce((m, doxId) => {
      m[doxId] = 'indeterminate'
      return m
    }, map)
    setSelectionMap(map)
  }, [props.selectedDoxIds, props.undeterminedDoxIds])

  const indeterminates = useMemo(() => {
    return Object.keys(selectionMap)
      .filter((k) => (selectionMap as any)[k] === 'indeterminate')
      .map((k) => parseInt(k, 10))
  }, [selectionMap])

  const selectedDoxIds = useMemo(() => {
    return Object.keys(selectionMap)
      .filter((k) => (selectionMap as any)[k] === 'true')
      .map((k) => parseInt(k, 10))
  }, [selectionMap])

  function onChangeSelection(dox: Dox, selected: boolean) {
    const index = selectedDoxIds.indexOf(dox.id)
    const isSelected = index !== -1

    const wasIndeterminate = (props.undeterminedDoxIds || []).indexOf(dox.id) !== -1

    const updatedSelectionMap = { ...selectionMap }

    if (selected) {
      if (
        props.selectionMode === DoxSelectorMode.selectSingleExceptSelfAndChilds ||
        props.selectionMode === DoxSelectorMode.selectSingleWithRetentionExceptSelfAndChilds
      ) {
        Object.keys(updatedSelectionMap).forEach((key) => {
          updatedSelectionMap[Number(key)] = 'false'
        })
        setSelectionMap(updatedSelectionMap)
      }
    }

    if (wasIndeterminate) {
      const doxSelectionState = selectionMap[dox.id]
      const isIndeterminate = doxSelectionState === undefined || doxSelectionState === 'indeterminate'
      if (isIndeterminate) {
        setSelectionMap({ ...updatedSelectionMap, [dox.id]: 'true' })
      } else {
        if (selected) {
          setSelectionMap({ ...updatedSelectionMap, [dox.id]: 'indeterminate' })
        } else if (!selected) {
          setSelectionMap({ ...updatedSelectionMap, [dox.id]: 'false' })
        }
      }
    } else {
      if (isSelected === selected) {
        return
      }
      if (selected) {
        setSelectionMap({ ...updatedSelectionMap, [dox.id]: 'true' })
      } else {
        setSelectionMap({ ...updatedSelectionMap, [dox.id]: 'false' })
      }
    }
  }

  function onConfirm() {
    const undeterminedDoxIds = Object.keys(selectionMap)
      .filter((doxId) => selectionMap[parseInt(doxId)] === 'indeterminate')
      .map(Number)
    props.onConfirm(selectedDoxIds, undeterminedDoxIds)
  }

  return (
    <>
      <ViewContent id="dox-selector-view-content" overflow="auto" flexGrow={1}>
        <DoxesNavList
          doxes={archiveContext.getArchiveDoxesFromAcl(props.assignableDoxesAcl).roots}
          level={0}
          selectedDoxIds={selectedDoxIds}
          undeterminedDoxIds={indeterminates}
          disabledDoxIds={disabledDoxIds}
          expandedDoxIds={props.visibleDoxIds || []}
          onExpandDox={props.onExpandDox}
          onChangeSelection={onChangeSelection}
          onEditDox={props.onEditDox}
          onAddDox={props.onAddDox}
        />
      </ViewContent>
      <ViewActions>
        <ViewActionsButton defaultAction onClick={onConfirm}>
          {t(msgIds.MSG_OK)}
        </ViewActionsButton>
        <ViewActionsButton onClick={props.onCancel}>{t(msgIds.MSG_CANCEL)}</ViewActionsButton>
      </ViewActions>
    </>
  )
}

interface IDoxesNavList {
  doxes: Dox[]
  level: number
  selectedDoxIds: number[]
  undeterminedDoxIds: number[]
  disabledDoxIds: number[]
  expandedDoxIds: number[]
  onExpandDox: (dox: Dox, expanded: boolean) => void
  onChangeSelection: (dox: Dox, selected: boolean) => void
  onEditDox: (dox: Dox) => void
  onAddDox: (dox: Dox) => void
}

function DoxesNavList(props: IDoxesNavList): JSX.Element {
  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }} dense={true} disablePadding>
      {props.doxes.map((dox) => {
        const expanded = props.level === 0 || props.expandedDoxIds.indexOf(dox.id) !== -1
        return (
          <DoxListItem
            key={`dox-list-item-${dox.id}`}
            dox={dox}
            level={props.level}
            childrenExpanded={expanded}
            selectedDoxIds={props.selectedDoxIds}
            undeterminedDoxIds={props.undeterminedDoxIds}
            disabledDoxIds={props.disabledDoxIds}
            expandedDoxIds={props.expandedDoxIds}
            onExpandDox={props.onExpandDox}
            onChangeSelection={props.onChangeSelection}
            onEditDox={props.onEditDox}
            onAddDox={props.onAddDox}
          />
        )
      })}
    </List>
  )
}

interface IDoxListItemProps {
  dox: Dox
  level: number
  childrenExpanded?: boolean
  selectedDoxIds: number[]
  undeterminedDoxIds: number[]
  disabledDoxIds: number[]
  expandedDoxIds: number[]
  onExpandDox: (dox: Dox, expanded: boolean) => void
  onChangeSelection: (dox: Dox, selected: boolean) => void
  onEditDox: (dox: Dox) => void
  onAddDox: (dox: Dox) => void
}

const iconSizePx = 34

function DoxListItem(props: IDoxListItemProps): JSX.Element {
  const { dox } = props
  const hasChildren = dox.children.length !== 0
  const children = dox.children

  function toggleChildren(event: MouseEvent<HTMLButtonElement, any>) {
    event.stopPropagation()
    event.preventDefault()
    props.onExpandDox(dox, !props.childrenExpanded)
  }

  const levelPadding = props.level * 2 * 8
  const paddingLeft = hasChildren ? levelPadding : iconSizePx + levelPadding
  const selected = props.selectedDoxIds.indexOf(dox.id) !== -1
  const disabled = props.disabledDoxIds.includes(dox.id)

  return (
    <>
      <ListItem
        title={dox.name}
        disablePadding
        disableGutters={true}
        sx={{ width: '100%', paddingLeft: `${paddingLeft}px` }}
        secondaryAction={
          <>
            <IconButton size="small" onClick={() => props.onEditDox(dox)}>
              <EditIco />
            </IconButton>
            <IconButton size="small" onClick={() => props.onAddDox(dox)}>
              <AddIco />
            </IconButton>
          </>
        }
        dense={true}
      >
        {hasChildren && (
          <IconButton size="small" onClick={toggleChildren}>
            {props.childrenExpanded ? <ExpandMoreIco /> : <ChevronRightIco />}
          </IconButton>
        )}
        <ListItemButton
          role={undefined}
          disabled={disabled}
          onClick={() => {
            props.onChangeSelection(dox, !selected)
          }}
          disableGutters={false}
          dense={true}
          sx={{ paddingLeft: '8px' }}
        >
          <DoxItem
            dox={dox}
            selected={selected}
            indeterminate={props.undeterminedDoxIds.indexOf(dox.id) !== -1}
            disabled={disabled}
          />
        </ListItemButton>
      </ListItem>
      <Collapse in={props.childrenExpanded} timeout="auto" unmountOnExit={false}>
        <DoxesNavList
          doxes={children}
          level={props.level + 1}
          selectedDoxIds={props.selectedDoxIds}
          undeterminedDoxIds={props.undeterminedDoxIds}
          disabledDoxIds={props.disabledDoxIds}
          expandedDoxIds={props.expandedDoxIds}
          onExpandDox={props.onExpandDox}
          onChangeSelection={props.onChangeSelection}
          onEditDox={props.onEditDox}
          onAddDox={props.onAddDox}
        />
      </Collapse>
    </>
  )
}

interface IDoxItemProps {
  dox: Dox
  selected: boolean
  indeterminate?: boolean
  disabled: boolean
}

function DoxItem(props: IDoxItemProps): JSX.Element {
  const { dox } = props
  const authContext = useAuthContext()
  const isUserOperator = isOperator(authContext.loggedProfileType)
  const labelId = `dox-checkbox-label-${dox.id}`

  return (
    <>
      <ListItemIcon>
        <Stack direction="row" alignItems="center" paddingRight={isUserOperator ? 1 : 0}>
          <Checkbox
            disabled={props.disabled}
            checked={props.selected}
            indeterminate={props.indeterminate}
            edge={isUserOperator ? 'start' : false}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': labelId }}
          />
          {isUserOperator &&
            (!!dox.treatmentId ? (
              <RetentionIco opacity={!!dox.retentionInheritedFrom ? 0.5 : 1} />
            ) : (
              <RetentionDisabledIco opacity={0.5} />
            ))}
        </Stack>
      </ListItemIcon>
      <ListItemText
        id={labelId}
        primary={dox.name}
        primaryTypographyProps={{ noWrap: true, textOverflow: 'ellipsis' }}
      />
    </>
  )
}
