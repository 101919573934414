import { useTranslation } from 'react-i18next'
import { Chip, Divider, IconButton, Stack, Typography } from '@mui/material'
import { useAuthContext } from '../../contexts/AuthContext'
import { DoxIco, RetentionDisabledIco, RetentionIco } from '../icons'
import { ProfileType, isBusiness, isConsumer } from '../../shared/Constants'
import { IDocumentDoxDetailsProps } from './DocumentDoxDetails.types'
import { Dox } from '../../models/Dox'
import msgIds from '../../locales/msgIds'
import { themeCustomerLight } from '../../themes/ThemeCustomerLight'
import { themeOperatorLight } from '../../themes/ThemeOperatorLight'
import { Account } from '../../models/Account'
import { useMemo, useState } from 'react'
import { DoxDetailsDialog } from '../../dialogs/doxDetailsDialog/DoxDetailsDialog'
import { IDoxDetailsDialogData } from '../../dialogs/doxDetailsDialog/DoxDetailsDialog.types'
import { IDoxSelectorDialogData } from '../../dialogs/doxSelectorDialog/DoxSelectorDialog.types'
import DoxSelectorDialog from '../../dialogs/doxSelectorDialog/DoxSelectorDialog'
import { useArchiveContext } from '../../contexts/ArchiveContext'
import { DoxSelectorMode } from '../doxSelector/DoxSelector.types'
import { Utils } from '../../shared/Utils'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'
import { ISimpleDialogData } from '../../dialogs/simpleDialog/SimpleDialog.types'
import { useSnackbar } from 'notistack'
import { ArchiveTypes } from '../../models/ArchiveTypes'
import { Document } from '../../models/Document'

export default function DocumentDoxDetails(props: IDocumentDoxDetailsProps): JSX.Element {
  const { document, applyChangesNow } = props
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const archiveContext = useArchiveContext()
  const authContext = useAuthContext()
  const consumer = isConsumer(authContext.loggedProfileType)
  const business = isBusiness(authContext.loggedProfileType)
  const guest = authContext.loggedAccount?.isGuest ?? false
  const [doxIds, setDoxIds] = useState<number[]>(document.doxIds ?? [])
  // dialogs
  const [simpleDialogData, setSimpleDialogData] = useState<ISimpleDialogData>()
  const [simpleDialogOpen, setSimpleDialogOpen] = useState(false)
  const [doxDetailsDialogData, setDoxDetailsDialogData] = useState<IDoxDetailsDialogData>()
  const [doxDetailsDialogOpen, setDoxDetailsDialogOpen] = useState(false)
  const [doxSelectorDialogData, setDoxSelectorDialogData] = useState<IDoxSelectorDialogData>()
  const [doxSelectorDialogOpen, setDoxSelectorDialogOpen] = useState(false)

  const assignedDocumentRWDoxes: Dox[] = useMemo(() => {
    return archiveContext.getLatestRwArchiveDoxes().distinct.filter((dox) => doxIds.includes(dox.id))
  }, [doxIds])

  const assignedDocumentRODoxes: Dox[] = useMemo(() => {
    return archiveContext.getLatestRoArchiveDoxes().distinct.filter((dox) => doxIds.includes(dox.id))
  }, [doxIds])

  function onClickDoxChip(dox: Dox) {
    setDoxDetailsDialogOpen(true)
    setDoxDetailsDialogData({
      doxId: dox.id,
      onClose: (result) => {
        setDoxDetailsDialogOpen(false)
      },
    })
  }

  async function onClickDoxButton() {
    const docSelection = [document]

    const selectionMode =
      isBusiness(authContext.loggedProfileType) && !!docSelection.find((p) => !p.anonymousAt)
        ? DoxSelectorMode.selectMultipleWithRetention
        : DoxSelectorMode.selectMultiple

    setDoxSelectorDialogOpen(true)
    setDoxSelectorDialogData({
      selectionMode: selectionMode,
      assignableDoxesAcl: 'rw',
      disabledDoxId: undefined,
      selectedDoxIds: document.doxIds,
      undeterminedDoxIds: [],
      onResult: async (result1) => {
        setDoxSelectorDialogOpen(false)
        if (result1.userChoice === 'yes') {
          const remainingDoxIds = Utils.getArrayDifference(
            Utils.getArrayUnion(document.doxIds, result1.addedDoxIds),
            result1.removedDoxIds
          )
          if (
            isBusiness(authContext.loggedProfileType) &&
            remainingDoxIds.length === 0 &&
            !!docSelection.find((p) => !!p.anonymousAt)
          ) {
            setSimpleDialogOpen(true)
            setSimpleDialogData({
              title: t(msgIds.MSG_DOX_SELECTOR_DIALOG_TITLE),
              content:
                docSelection.length > 1
                  ? t(msgIds.MSG_DOX_SELECTOR_DIALOG_REMOVING_DOCUMENTS_CONFIRM)
                  : t(msgIds.MSG_DOX_SELECTOR_DIALOG_REMOVING_DOCUMENT_CONFIRM),
              actionsStyle: 'Ok',
              onClose: async (result2) => {
                try {
                  await doAssociateToDox(docSelection, result1.addedDoxIds, result1.removedDoxIds)
                  setSimpleDialogOpen(false)
                } catch (err) {
                  Utils.enqueueSnackbarError2(err, t)
                }
              },
            })
          } else {
            try {
              await doAssociateToDox(docSelection, result1.addedDoxIds, result1.removedDoxIds, false)
            } catch (err) {
              Utils.enqueueSnackbarError2(err, t)
            }
          }
        }
      },
    })
  }

  async function doAssociateToDox(
    docSelection: Document[],
    addedDoxIds: number[],
    removedDoxIds: number[],
    applyChangesNow: boolean = true
  ) {
    await Utils.applyNewDoxSelections(
      authContext.loggedProfileType!,
      archiveContext.getLatestRwArchiveDoxes(),
      docSelection,
      addedDoxIds,
      removedDoxIds,
      applyChangesNow,
      true
    )
    const _assignedDoxIds = docSelection[0].doxIds
    props.onEditedDocumentAssignedDoxChanged(_assignedDoxIds)
    setDoxIds(_assignedDoxIds)
    if (applyChangesNow) {
      enqueueSnackbar(t(msgIds.MSG_DOX_SELECTOR_DIALOG_CHANGES_MADE_SUCCESSFULLY), { variant: 'success' })
    }
  }

  return (
    <Stack padding={2} spacing={2}>
      {simpleDialogData && <SimpleDialog {...simpleDialogData} isOpen={simpleDialogOpen}></SimpleDialog>}
      {doxDetailsDialogData && <DoxDetailsDialog {...doxDetailsDialogData} isOpen={doxDetailsDialogOpen} />}
      {doxSelectorDialogData && (
        <DoxSelectorDialog
          {...doxSelectorDialogData}
          isOpen={doxSelectorDialogOpen}
          onClose={() => setDoxSelectorDialogOpen(false)}
        />
      )}

      {consumer && !guest && (
        <DoxesOfOwner
          doxes={assignedDocumentRWDoxes.filter((dox) => dox.ownerProfileId === authContext.loggedProfileId)}
          isEditable={props.isEditable}
          onClickDoxButton={() => {
            onClickDoxButton()
          }}
          account={authContext.loggedAccount}
          onClickDoxChip={onClickDoxChip}
        />
      )}
      {guest && (
        <DoxesOfOwner
          doxes={assignedDocumentRWDoxes.filter((dox) => dox.ownerProfileId === authContext.linkedStructureProfileId)}
          isEditable={props.isEditable}
          onClickDoxButton={() => {
            onClickDoxButton()
          }}
          account={authContext.loggedAccount}
          onClickDoxChip={onClickDoxChip}
        />
      )}
      {business && (
        <DoxesOfOwner
          doxes={assignedDocumentRWDoxes.filter((dox) => dox.ownerProfileId === authContext.linkedStructureProfileId)}
          isEditable={props.isEditable}
          onClickDoxButton={() => {
            onClickDoxButton()
          }}
          account={authContext.linkedStructureAccount}
          onClickDoxChip={onClickDoxChip}
        />
      )}
      {business && archiveContext.archiveType === ArchiveTypes.structureArchiveSharedByCustomer && (
        <>
          <Divider />
          <DoxesOfOwner
            doxes={
              assignedDocumentRODoxes?.filter((dox) => dox.ownerProfileId === authContext.assistedAccountProfileId) ??
              []
            }
            isEditable={props.isEditable}
            account={authContext.assistedAccount}
            onClickDoxChip={onClickDoxChip}
          />
        </>
      )}
    </Stack>
  )
}

interface IDoxesOfOwnerProps {
  doxes: Dox[]
  color?: any
  isEditable: boolean
  onClickDoxButton?: () => void
  account?: Account
  onClickDoxChip: (dox: Dox) => void
}

function DoxesOfOwner(props: IDoxesOfOwnerProps): JSX.Element {
  const { account } = props
  const { t } = useTranslation()
  const authContext = useAuthContext()

  function GetArchiveIco(dox: Dox) {
    if (isConsumer(authContext.loggedProfileType)) {
      return undefined
    }
    if (isConsumer(account?.profile?.type)) {
      return undefined
    }
    if (dox.treatmentId) {
      if (!dox.retentionInheritedFrom) {
        return <RetentionIco />
      } else {
        return <RetentionIco opacity={0.5} />
      }
    } else {
      return <RetentionDisabledIco opacity={0.5} />
    }
  }

  return (
    <Stack spacing={1}>
      <Typography>{t(msgIds.MSG_DOCUMENT_EDITOR_DOX_OF) + account?.getIdentityInverse()}</Typography>
      <Stack direction="row" spacing={1} alignItems="center" sx={{ flexWrap: 'wrap', gap: '8px' }}>
        {props.isEditable && props.onClickDoxButton && (
          <IconButton color="inherit" onClick={props.onClickDoxButton}>
            <DoxIco />
          </IconButton>
        )}
        {props.doxes.length !== 0 ? (
          props.doxes.map((dox) => (
            <Chip
              key={dox.id}
              label={dox.name}
              onClick={() => props.onClickDoxChip(dox)}
              sx={{
                background:
                  props.account?.profile?.type === ProfileType.customer
                    ? themeCustomerLight.palette.primary.lighter
                    : themeOperatorLight.palette.primary.lighter,
              }}
              icon={GetArchiveIco(dox)}
            />
          ))
        ) : (
          <Typography sx={{ paddingLeft: '10px' }}>{t(msgIds.MSG_DOCUMENT_EDITOR_NO_DOX)}</Typography>
        )}
      </Stack>
    </Stack>
  )
}
