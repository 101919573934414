import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function PinIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 8.2023924,1.8874495 A 1.17236,1.17236 0 0 0 7.0334475,3.0563947 v 0.015381 a 1.17132,1.17132 0 0 0 1.1491699,1.1667482 v 6.5983881 a 0.3125,0.3125 0 0 1 -0.092285,0.221924 l -2.403809,2.790528 A 0.31242,0.31242 0 0 0 5.90625,14.383299 h 12.199219 a 0.31242,0.31242 0 0 0 0.217529,-0.53833 l -2.414795,-2.786133 a 0.31239999,0.31239999 0 0 1 -0.09668,-0.224121 V 4.2385237 A 1.171615,1.171615 0 0 0 16.96729,3.0717757 V 3.0563947 A 1.17236,1.17236 0 0 0 15.79834,1.8874495 Z M 9.9997559,4.2187483 H 12.003662 V 11.045653 H 9.9997559 Z m 0.2812501,10.9182137 1.443603,6.754393 a 0.284025,0.284025 0 0 0 0.551514,0 l 1.441406,-6.754393 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
