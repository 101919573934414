import {
  Avatar,
  Box,
  Radio,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material'
import msgIds from '../../locales/msgIds'
import * as dalProfile from '../../dal/DalProfile'
import { AttachPlaceholderArgs } from '../../dal/DalProfile'
import { useTranslation } from 'react-i18next'
import {
  checkIfPlaceholderPropertyIsReusableArgs,
  IAccountAttachField,
  IAccountAttachProps,
} from './AccountAttach.types'
import { ProfileDetails } from '../../models/ProfileDetails'
import { Profile } from '../../models/Profile'
import { AccountType, Gender, TreatedFieldName } from '../../shared/Constants'
import { Account } from '../../models/Account'
import { useEffect, useState } from 'react'
import { useLayout } from '../../hooks/Layout'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import { Utils } from '../../shared/Utils'
import { useNavigate } from 'react-router'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'
import { ISimpleDialogData } from '../../dialogs/simpleDialog/SimpleDialog.types'
import AvatarImage from '../avatarImage/AvatarImage'

export function AccountAttach(props: IAccountAttachProps): JSX.Element {
  const { customerAccount, placeholderAccount, onClickExit } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useTheme()
  const { isMobile } = useLayout()
  const [isLoading, setIsLoading] = useState(false)
  const [fields, setFields] = useState<IAccountAttachField[]>([])
  const [selectedValues, setSelectedValues] = useState<Record<string, string>>({})

  // dialogs
  const [simpleDialogData, setSimpleDialogData] = useState<ISimpleDialogData>()
  const [simpleDialogOpen, setSimpleDialogOpen] = useState(false)

  useEffect(() => {
    const initialValues: Record<string, string> = {}

    fields.forEach((field) => {
      const isPlaceholderFilled = !!field.placeholderValue
      const isAttachingFilled = !!field.attachingValue

      initialValues[field.propertyName] = isAttachingFilled ? 'attaching' : isPlaceholderFilled ? 'placeholder' : ''
    })

    setSelectedValues(initialValues)
  }, [fields])

  useEffect(() => {
    setFields((fds) => [])
    const canAttach = validateAccountsForAttach(customerAccount, placeholderAccount)
    if (canAttach) {
      CheckPlaceholderFieldsToRetain(customerAccount, placeholderAccount)
    } else {
      notifyCannotAttach()
    }
  }, [customerAccount, placeholderAccount])

  function notifyCannotAttach() {
    setSimpleDialogOpen(true)
    setSimpleDialogData({
      title: t(msgIds.MSG_ACCOUNT_ATTACH_DIALOG_IMPOSSIBLE_TITLE),
      content: t(msgIds.MSG_ACCOUNT_ATTACH_DIALOG_IMPOSSIBLE_BODY),
      actionsStyle: 'Ok',
      onClose: async (result2) => {
        setSimpleDialogOpen(false)
        onClickExit()
      },
    })
  }

  function CheckPlaceholderFieldsToRetain(customerAccount: Account, placeholderAccount: Account) {
    const args: checkIfPlaceholderPropertyIsReusableArgs = {
      customerAccount: customerAccount,
      placeholderAccount: placeholderAccount,
      fieldGroup: t(msgIds.MSG_TREATED_FIELDS_GROUP_RECOGNITION),
      fieldName: t(msgIds.MSG_FIELDNAME_AVATAR),
      treatedFieldName: TreatedFieldName.avatar,
      propertyName: 'avatarImage',
    }

    // identification data
    checkIfPlaceholderPropertyIsReusable(args)

    // residence data
    args.fieldGroup = t(msgIds.MSG_TREATED_FIELDS_GROUP_RESIDENCE)
    checkIfPlaceholderPropertyIsReusable({
      ...args,
      fieldName: t(msgIds.MSG_FIELDNAME_COUNTRY),
      treatedFieldName: TreatedFieldName.residenceCountry,
      propertyName: 'country',
    })
    checkIfPlaceholderPropertyIsReusable({
      ...args,
      fieldName: t(msgIds.MSG_FIELDNAME_PROVINCE),
      treatedFieldName: TreatedFieldName.residenceProvince,
      propertyName: 'province',
    })
    checkIfPlaceholderPropertyIsReusable({
      ...args,
      fieldName: t(msgIds.MSG_FIELDNAME_CITY),
      treatedFieldName: TreatedFieldName.residenceCity,
      propertyName: 'city',
    })
    checkIfPlaceholderPropertyIsReusable({
      ...args,
      fieldName: t(msgIds.MSG_FIELDNAME_STREET),
      treatedFieldName: TreatedFieldName.residenceStreet,
      propertyName: 'street',
    })
    checkIfPlaceholderPropertyIsReusable({
      ...args,
      fieldName: t(msgIds.MSG_FIELDNAME_STREET_NUMBER),
      treatedFieldName: TreatedFieldName.residenceStreetNumber,
      propertyName: 'streetNumber',
    })
    checkIfPlaceholderPropertyIsReusable({
      ...args,
      fieldName: t(msgIds.MSG_FIELDNAME_ZIP),
      treatedFieldName: TreatedFieldName.residenceZip,
      propertyName: 'zip',
    })

    // contacts data
    args.fieldGroup = t(msgIds.MSG_TREATED_FIELDS_GROUP_CONTACT)
    checkIfPlaceholderPropertyIsReusable({
      ...args,
      fieldName: t(msgIds.MSG_FIELDNAME_PHONE),
      treatedFieldName: TreatedFieldName.phone,
      propertyName: 'phone',
    })
    checkIfPlaceholderPropertyIsReusable({
      ...args,
      fieldName: t(msgIds.MSG_FIELDNAME_MOBILE_PHONE1),
      treatedFieldName: TreatedFieldName.mobilePhone,
      propertyName: 'mobilePhone1',
    })
    checkIfPlaceholderPropertyIsReusable({
      ...args,
      fieldName: t(msgIds.MSG_FIELDNAME_MOBILE_PHONE2),
      treatedFieldName: TreatedFieldName.mobilePhone,
      propertyName: 'mobilePhone2',
    })
    checkIfPlaceholderPropertyIsReusable({
      ...args,
      fieldName: t(msgIds.MSG_FIELDNAME_EMAIL1),
      treatedFieldName: TreatedFieldName.email,
      propertyName: 'email1',
    })
    checkIfPlaceholderPropertyIsReusable({
      ...args,
      fieldName: t(msgIds.MSG_FIELDNAME_EMAIL2),
      treatedFieldName: TreatedFieldName.email,
      propertyName: 'email2',
    })
    checkIfPlaceholderPropertyIsReusable({
      ...args,
      fieldName: t(msgIds.MSG_FIELDNAME_EMAIL3),
      treatedFieldName: TreatedFieldName.email,
      propertyName: 'email3',
    })
    checkIfPlaceholderPropertyIsReusable({
      ...args,
      fieldName: t(msgIds.MSG_FIELDNAME_EMAIL4),
      treatedFieldName: TreatedFieldName.email,
      propertyName: 'email4',
    })
  }

  function validateAccountsForAttach(customerAccount: Account, placeholderAccount: Account): boolean {
    const customerDetails = customerAccount.userDetails
    const placeholderDetails = placeholderAccount.userDetails

    if (!customerDetails) return false
    if (!placeholderDetails) return false

    if (customerDetails.lastname !== placeholderDetails.lastname || customerDetails.name !== placeholderDetails.name) {
      return false
    }
    if (placeholderDetails.birthdate && placeholderDetails.birthdate !== customerDetails.birthdate) {
      return false
    }
    if (placeholderDetails.birthplace && placeholderDetails.birthplace !== customerDetails.birthplace) {
      return false
    }
    if (placeholderDetails.gender !== Gender.none && placeholderDetails.gender !== customerDetails.gender) {
      return false
    }
    if (placeholderDetails.fiscalCode && placeholderDetails.fiscalCode !== customerDetails.fiscalCode) {
      return false
    }
    return true
  }

  function checkIfPlaceholderPropertyIsReusable(args: checkIfPlaceholderPropertyIsReusableArgs): void {
    if (args.customerAccount.treatedFields?.some((p) => p.fieldName === args.treatedFieldName)) {
      let placeholderAccountFieldValue: string
      let customerAccountFieldValue: string

      if (args.treatedFieldName === TreatedFieldName.avatar) {
        if (!args.placeholderAccount.profile) return
        if (!args.customerAccount.profile) return
        const pn = args.propertyName as keyof Profile
        placeholderAccountFieldValue = args.placeholderAccount.profile[pn] as string
        customerAccountFieldValue = args.customerAccount.profile[pn] as string
      } else {
        if (!args.placeholderAccount.profileDetailsOwnedByOthers) return
        if (!args.customerAccount.profileDetailsOwnedBySelf) return
        const pn = args.propertyName as keyof ProfileDetails
        placeholderAccountFieldValue = args.placeholderAccount.profileDetailsOwnedByOthers[pn] as string
        customerAccountFieldValue = args.customerAccount.profileDetailsOwnedBySelf[pn] as string
      }

      if (placeholderAccountFieldValue || customerAccountFieldValue) {
        const field = {
          propertyName: args.propertyName,
          fieldGroup: args.fieldGroup,
          fieldName: args.fieldName,
          placeholderValue: placeholderAccountFieldValue,
          attachingValue: customerAccountFieldValue,
        }
        setFields((fds) => [...fds, field])
      }
    }
  }

  const handleRadioChange = (propertyName: string, value: string) => {
    setSelectedValues((prev) => ({
      ...prev,
      [propertyName]: value,
    }))
  }

  async function onAttach() {
    if (!customerAccount.profile) return
    if (!customerAccount.profileDetailsOwnedBySelf) return
    if (!placeholderAccount.profile) return
    if (!placeholderAccount.profileDetailsOwnedByOthers) return

    const args: AttachPlaceholderArgs = {}
    fields.forEach((field) => {
      const propertyName = field.propertyName as keyof AttachPlaceholderArgs
      if (propertyName === 'avatarImage') {
        if (!customerAccount.profile) return
        if (!placeholderAccount.profile) return
        const selectedValue = selectedValues[propertyName]
        args[propertyName] =
          selectedValue === 'placeholder'
            ? placeholderAccount.profile[propertyName]
            : customerAccount.profile[propertyName]
      } else {
        if (!placeholderAccount.profileDetailsOwnedByOthers) return
        if (!customerAccount.profileDetailsOwnedBySelf) return
        const selectedValue = selectedValues[propertyName]
        args[propertyName] =
          selectedValue === 'placeholder'
            ? placeholderAccount.profileDetailsOwnedByOthers[propertyName]
            : customerAccount.profileDetailsOwnedBySelf[propertyName]
      }
    })

    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const account = await dalProfile.attachPlaceholder(
        abortController.signal,
        customerAccount.profile?.profileId,
        placeholderAccount.profile?.profileId,
        args
      )
      navigate
      goToAccountPage(account)
      onClickExit()
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  function goToAccountPage(account: Account) {
    if (account.profile) {
      navigate(`/account_card`, {
        state: {
          profileId: account.profile?.profileId,
        },
      })
    }
  }

  return (
    <>
      {simpleDialogData && <SimpleDialog {...simpleDialogData} isOpen={simpleDialogOpen}></SimpleDialog>}

      {isMobile ? (
        <Box>
          {fields.map((field) => {
            const isPlaceholderFilled = !!field.placeholderValue
            const isAttachingFilled = !!field.attachingValue
            const selectedValue =
              selectedValues[field.propertyName] || (isAttachingFilled ? 'attaching' : 'placeholder')

            return (
              <Box
                key={field.propertyName}
                sx={{ marginBottom: 2, padding: 1, border: '1px solid #ccc', borderRadius: '8px' }}
              >
                <Typography sx={{ marginBottom: 1 }}>{field.fieldName}</Typography>

                {isPlaceholderFilled && (
                  <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                    <Radio
                      checked={selectedValue === 'placeholder'}
                      onChange={() => handleRadioChange(field.propertyName, 'placeholder')}
                      value="placeholder"
                      name={`${field.propertyName}-radio-group`}
                    />
                    {field.propertyName === 'avatarImage' ? (
                      <>
                        <AvatarImage
                          identity={placeholderAccount.getIdentityInverse()}
                          initials={placeholderAccount.getInitials()}
                          image={placeholderAccount.profile?.avatarImage}
                          accountType={placeholderAccount.user?.accountType || AccountType.none}
                          sx={{ alignSelf: 'start' }}
                        />
                        <Typography component="span" sx={{ fontStyle: 'italic', fontSize: '0.875rem', marginLeft: 2 }}>
                          {`(${t(msgIds.MSG_PRIVATE)})`}
                        </Typography>
                      </>
                    ) : (
                      <Stack>
                        <Typography component="span" sx={{ fontStyle: 'italic', fontSize: '0.875rem', marginRight: 2 }}>
                          {`(${t(msgIds.MSG_PRIVATE)})`}
                        </Typography>
                        <Typography component="span">{field.placeholderValue}</Typography>
                      </Stack>
                    )}
                  </Box>
                )}

                {isAttachingFilled && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Radio
                      checked={selectedValue === 'attaching'}
                      onChange={() => handleRadioChange(field.propertyName, 'attaching')}
                      value="attaching"
                      name={`${field.propertyName}-radio-group`}
                    />
                    {field.propertyName === 'avatarImage' ? (
                      <>
                        <AvatarImage
                          identity={customerAccount.getIdentityInverse()}
                          initials={customerAccount.getInitials()}
                          image={customerAccount.profile?.avatarImage}
                          accountType={customerAccount.user?.accountType || AccountType.none}
                          sx={{ alignSelf: 'start' }}
                        />
                        <Typography component="span" sx={{ fontStyle: 'italic', fontSize: '0.875rem', marginLeft: 2 }}>
                          {`(${t(msgIds.MSG_SHARED)})`}
                        </Typography>
                      </>
                    ) : (
                      <Stack>
                        <Typography component="span" sx={{ fontStyle: 'italic', fontSize: '0.875rem', marginRight: 2 }}>
                          {`(${t(msgIds.MSG_SHARED)})`}
                        </Typography>
                        <Typography component="span">{field.attachingValue}</Typography>
                      </Stack>
                    )}
                  </Box>
                )}
              </Box>
            )
          })}
        </Box>
      ) : (
        <Box>
          <Box sx={{ marginBottom: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t(msgIds.MSG_FIELD)}</TableCell>
                  <TableCell>{t(msgIds.MSG_PRIVATE)}</TableCell>
                  <TableCell>{t(msgIds.MSG_SHARED)}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((field) => {
                  const isPlaceholderFilled = !!field.placeholderValue
                  const isAttachingFilled = !!field.attachingValue
                  const selectedValue =
                    selectedValues[field.propertyName] || (isAttachingFilled ? 'attaching' : 'placeholder')

                  return (
                    <TableRow key={field.propertyName}>
                      <TableCell sx={{ borderBottom: 'none', padding: 1 }}>
                        <Typography>{field.fieldName}</Typography>
                      </TableCell>

                      <TableCell sx={{ borderBottom: 'none', padding: 1 }}>
                        {isPlaceholderFilled && (
                          <Stack direction="row" alignItems="center">
                            <Radio
                              checked={selectedValue === 'placeholder'}
                              onChange={() => handleRadioChange(field.propertyName, 'placeholder')}
                              value="placeholder"
                              name={`${field.propertyName}-radio-group`}
                            />
                            {field.propertyName === 'avatarImage' ? (
                              <AvatarImage
                                identity={placeholderAccount.getIdentityInverse()}
                                initials={placeholderAccount.getInitials()}
                                image={placeholderAccount.profile?.avatarImage}
                                accountType={placeholderAccount.user?.accountType || AccountType.none}
                                sx={{ alignSelf: 'start' }}
                              />
                            ) : (
                              <Typography component="span">{field.placeholderValue}</Typography>
                            )}
                          </Stack>
                        )}
                      </TableCell>

                      <TableCell sx={{ borderBottom: 'none', padding: 1 }}>
                        {isAttachingFilled && (
                          <Stack direction="row" alignItems="center">
                            <Radio
                              checked={selectedValue === 'attaching'}
                              onChange={() => handleRadioChange(field.propertyName, 'attaching')}
                              value="attaching"
                              name={`${field.propertyName}-radio-group`}
                            />
                            {field.propertyName === 'avatarImage' ? (
                              <AvatarImage
                                identity={customerAccount.getIdentityInverse()}
                                initials={customerAccount.getInitials()}
                                image={customerAccount.profile?.avatarImage}
                                accountType={customerAccount.user?.accountType || AccountType.none}
                                sx={{ alignSelf: 'start' }}
                              />
                            ) : (
                              <Typography component="span">{field.attachingValue}</Typography>
                            )}
                          </Stack>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Box>
        </Box>
      )}

      <ViewActions>
        <ViewActionsButton
          autoFocus
          defaultAction
          onClick={() => {
            onAttach()
          }}
        >
          {t(msgIds.MSG_CONFIRM)}
        </ViewActionsButton>
        <ViewActionsButton onClick={() => onClickExit()}>{t(msgIds.MSG_CANCEL)}</ViewActionsButton>
      </ViewActions>
    </>
  )
}
