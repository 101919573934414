import {
  INotificationContextDocumentRevisionJson,
  NotificationContextDocumentRevision,
} from './NotificationContextDocumentRevision'

export interface INotificationContextDocumentJson {
  id: number
  author_profile_id: number
  draft_revision_id?: number
  last_archived_revision_id: number
  delivered_at?: string
  target_profile_id: number
  owner_profile_id: number
  last_archived_revision?: INotificationContextDocumentRevisionJson
}

export class NotificationContextDocument {
  id: number = 0
  authorProfileId: number = 0
  draftRevision?: number
  lastArchivedRevisionId: number = 0
  deliveredAt?: Date
  targetProfileId: number = 0
  ownerProfileId: number = 0
  lastArchivedRevision?: NotificationContextDocumentRevision

  constructor() {}

  public static serialize(obj: NotificationContextDocument): INotificationContextDocumentJson {
    return {
      id: obj.id,
      author_profile_id: obj.authorProfileId,
      draft_revision_id: obj.draftRevision,
      last_archived_revision_id: obj.lastArchivedRevisionId,
      delivered_at: obj.deliveredAt?.toISOString(),
      target_profile_id: obj.targetProfileId,
      owner_profile_id: obj.ownerProfileId,
      last_archived_revision: obj.lastArchivedRevision
        ? NotificationContextDocumentRevision.serialize(obj.lastArchivedRevision)
        : undefined,
    }
  }

  public static deserialize(json: INotificationContextDocumentJson): NotificationContextDocument {
    const res = new NotificationContextDocument()
    res.id = json.id
    res.authorProfileId = json.author_profile_id
    res.draftRevision = json.draft_revision_id
    res.lastArchivedRevisionId = json.last_archived_revision_id
    res.deliveredAt = json.delivered_at ? new Date(json.delivered_at) : undefined
    res.targetProfileId = json.target_profile_id
    res.ownerProfileId = json.owner_profile_id
    res.lastArchivedRevision = json.last_archived_revision
      ? NotificationContextDocumentRevision.deserialize(json.last_archived_revision)
      : undefined
    return res
  }
}
