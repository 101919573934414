import { Checkbox, FormControlLabel, Radio, RadioGroup, Stack, Typography, useTheme } from '@mui/material'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'
import { ConsentMode, ContractType } from '../../shared/Constants'
import { IContractSectionAgreementProps } from './ContractSectionAgreement.types'

export function ContractSectionAgreement(props: IContractSectionAgreementProps): JSX.Element {
  const { agreement, onChangeAgreement } = props
  const { t } = useTranslation()
  const theme = useTheme()

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeAgreement(!agreement)
  }

  function getMandatoryConsentExplanation() {
    return props.contractType === ContractType.privacyPolicy
      ? t(msgIds.MSG_CONSENT_ACCEPTANCE_MODE_MANDATORY_EXPLANATION_PRIVACY_POLICY)
      : t(msgIds.MSG_CONSENT_ACCEPTANCE_MODE_MANDATOTY_EXPLANATION_OTHER)
  }

  return (
    <Stack>
      {props.consentMode === ConsentMode.mandatory && !props.areConsentsDisabled && (
        <Typography variant="body2" color={theme.palette.error.main}>
          {getMandatoryConsentExplanation()}
        </Typography>
      )}

      {props.contractType === ContractType.privacyPolicy && props.isDataProcessingSection ? (
        <FormControlLabel
          control={
            <Checkbox onChange={handleInputChange} checked={agreement} disabled={props.areConsentsDisabled || false} />
          }
          label={t(msgIds.MSG_CONSENT_I_HAVE_READ)}
        />
      ) : (
        <RadioGroup row name="agreement" value={agreement} onChange={handleInputChange}>
          <FormControlLabel
            control={<Radio disabled={props.areConsentsDisabled || false} />}
            value={true}
            label={
              props.contractType === ContractType.privacyPolicy
                ? t(msgIds.MSG_CONSENT_AGREE)
                : t(msgIds.MSG_CONSENT_ACCEPT)
            }
          />

          <FormControlLabel
            control={<Radio disabled={props.areConsentsDisabled || false} />}
            value={false}
            label={
              props.contractType === ContractType.privacyPolicy
                ? t(msgIds.MSG_CONSENT_NOT_AGREE)
                : t(msgIds.MSG_CONSENT_NOT_ACCEPT)
            }
          />
        </RadioGroup>
      )}
    </Stack>
  )
}
