import { useEffect, useState } from 'react'
import { Stack } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import * as dalAccount from '../../dal/DalAccount'
import { IAccountInfoOperatorProps } from './AccountInfoOperator.types'
import AccountInfoIdentity from '../accountInfoIdentity/AccountInfoIdentity'
import AccountInfoContacts from '../accountInfoContacts/AccountInfoContacts'
import { Utils } from '../../shared/Utils'

export default function AccountInfoOperator(props: IAccountInfoOperatorProps) {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const [detailedAccount, setDetailedAccount] = useState(props.account)

  // ********************
  // component mounting
  useEffect(() => {
    async function LoadAccountDetails() {
      try {
        setIsLoading(true)
        const abortController = new AbortController()
        const data = await dalAccount.getAccountFromProfileId(
          abortController.signal,
          props.account.profile?.profileId!,
          true,
          false
        )
        setDetailedAccount(data)
      } catch (err) {
        enqueueSnackbar(t(Utils.getErrorMessageId(err)), { variant: 'error' })
      } finally {
        setIsLoading(false)
      }
    }

    if (!isLoading) {
      LoadAccountDetails()
    }
  }, [props.account])

  return (
    <>
      <Stack direction={'column'} alignItems={'stretch'} padding={1} spacing={1}>
        <AccountInfoIdentity account={detailedAccount} isLoading={isLoading} />
        <AccountInfoContacts account={detailedAccount} isLoading={isLoading} showProfileDetailsSelector={false} />
      </Stack>
    </>
  )
}
