import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { IAccountEditorContactsFormProps } from './AccountEditorContactsForm.types'
import { AccountEditorContacts } from './AccountEditorContacts'
import DataEditorHeader from '../dataEditorHeader/DataEditorHeader'
import { EditIco } from '../icons'
import { useState } from 'react'
import { DataEditorContainer } from '../dataEditorContainer/DataEditorContainer'

export function AccountEditorContactsForm(props: IAccountEditorContactsFormProps): JSX.Element {
  const { t } = useTranslation()
  const [isEditMode, setIsEditMode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  return (
    <DataEditorContainer isLoading={isLoading}>
      <DataEditorHeader
        title={t(msgIds.MSG_PROFILE_EDITOR_CONTACTS_TITLE)}
        isEditMode={isEditMode}
        viewModeCommands={[
          {
            commandText: '',
            icon: <EditIco />,
            onClick: () => setIsEditMode(true),
            tooltipText: t(msgIds.MSG_MODIFY) ?? undefined,
            disabled: false,
          },
        ]}
        editModeCommands={[]}
      />
      <AccountEditorContacts
        isInDialog={props.isInDialog}
        account={props.account}
        detailsOwnerProfileId={props.detailsOwnerProfileId}
        mandatoryFieldsNames={props.mandatoryFieldsNames}
        optionalFieldsNames={props.optionalFieldsNames}
        isEditMode={isEditMode}
        disabled={false}
        onSave={() => setIsEditMode(false)}
        onCancel={() => setIsEditMode(false)}
        setIsLoading={setIsLoading}
      />
    </DataEditorContainer>
  )
}
