import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function PrivacyIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 11.999268,0.99975422 2.9992676,5.000975 v 5.998535 c 0,5.549996 3.8400055,10.741466 9.0000004,12.001465 5.159994,-1.259999 9,-6.451469 9,-12.001465 V 5.000975 Z m 0,2.19067388 7.000488,3.1091309 v 4.699951 c 0,4.519996 -2.980492,8.689445 -7.000488,9.929444 C 7.9792718,19.688955 5.0009767,15.519506 5.0009767,10.99951 V 6.299559 Z M 16.589355,7.5805648 9.9997561,14.170165 7.4091798,11.590575 6.0007326,12.999022 9.9997561,17.000243 18,8.9999985 Z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
