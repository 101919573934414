import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function RetentionStopIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="M 16.218445,1.5705 A 6.2889273,6.2889273 0 0 0 9.929871,7.8590742 6.2889273,6.2889273 0 0 0 16.218445,14.149845 6.2889273,6.2889273 0 0 0 22.50702,7.8590742 6.2889273,6.2889273 0 0 0 16.218445,1.5705 Z M 3.5490115,2.4494064 C 2.5490116,2.4494064 1.5495,3.3489179 1.5495,4.4489179 v 3.010254 c 0,0.72 0.4297558,1.3396973 0.9997557,1.6896972 V 20.449407 c 0,1.100001 1.1017089,1.99951 2.001709,1.999512 H 18.549744 c 0.9,0 1.999512,-0.899511 1.999512,-1.999512 v -6.651124 a 7.4246212,7.4246212 0 0 1 -1.999512,1.081055 v 5.570069 H 4.5509647 V 9.4498945 h 4.326416 A 7.4246212,7.4246212 0 0 1 8.688416,7.8744552 7.4246212,7.4246212 0 0 1 8.7257694,7.4503828 H 3.5490115 V 4.4489179 H 9.549744 a 7.4246212,7.4246212 0 0 1 1.529297,-1.9995115 z m 9.7382815,1.7424315 2.931152,2.928955 2.928956,-2.928955 0.73828,0.7382812 -2.928955,2.9289551 2.928955,2.9311528 -0.73828,0.738281 L 16.218445,8.5995527 13.287293,11.528508 12.549012,10.790227 15.480163,7.8590742 12.549012,4.9301191 Z M 8.5499881,12.449163 v 2.001708 h 4.1660159 a 7.4246212,7.4246212 0 0 1 -2.430176,-2.001708 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
