import { Box, IconButton, Stack, StackProps, Tooltip, Typography, useTheme } from '@mui/material'
import { DeleteIco, EditIco } from '../icons'
import { INoticeBoardProps } from './NoticeBoard.types'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'
import { DragEvent, useState } from 'react'
import { useLayout } from '../../hooks/Layout'

export function NoticeBoard(props: INoticeBoardProps & StackProps): JSX.Element {
  const { notes, onDeleteNote, onEditNote, onMoveNote, ...rest } = props
  const { isMobile } = useLayout()
  const theme = useTheme()
  const { t } = useTranslation()
  const [hoverIndex, setHoverIndex] = useState<number>(-1)
  const [draggable, setDraggable] = useState<boolean>(false)

  function onDragStart(event: DragEvent<HTMLDivElement>, index: number) {
    event.dataTransfer.setData('text/plain', '' + index)
  }
  function onDragEnd() {
    setHoverIndex(-1)
  }
  function onDragEnter(index: number) {
    console.log('onDragEnter')
    setHoverIndex(index)
  }
  function onDrop(event: DragEvent<HTMLDivElement>, index: number, side: 'left' | 'right') {
    const draggedIndex = parseInt(event.dataTransfer.getData('text/plain'), 10)
    setHoverIndex(-1)
    if (side === 'right' && Math.abs(index - draggedIndex) < 2) {
      return
    }
    onMoveNote(draggedIndex, index)
  }

  const gap = 34
  const separatorWidth = 3

  return (
    <Stack
      direction="row"
      justifyContent={isMobile ? 'center' : 'flex-start'}
      alignItems={isMobile ? 'center' : 'flex-start'}
      flexWrap="wrap"
      gap={gap + 'px'}
      {...rest}
    >
      {notes.map((note, index) => (
        <Box key={index} sx={{ position: 'relative', maxWidth: '100%' }}>
          <Stack
            flexShrink={1}
            width={300}
            maxWidth="100%"
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            p={1.5}
            sx={{ boxSizing: 'border-box', backgroundColor: theme.palette.common.memoText }}
            draggable={draggable}
            onDragStart={(event) => onDragStart(event, index)}
            onDragEnd={onDragEnd}
            onDragEnter={() => onDragEnter(index)}
          >
            <Box height={200} overflow="auto">
              <Typography>{note.contextNote?.text}</Typography>
            </Box>
            <Stack
              sx={{ cursor: 'grab' }}
              onMouseEnter={() => setDraggable(true)}
              onMouseLeave={() => setDraggable(false)}
            >
              <Tooltip title={t(msgIds.MSG_NOTICE_BOARD_DELETE_ELEMENT)} placement="left">
                <IconButton size="small" onClick={() => onDeleteNote(index)}>
                  <DeleteIco />
                </IconButton>
              </Tooltip>
              <Tooltip title={t(msgIds.MSG_NOTICE_BOARD_EDIT_ELEMENT)} placement="left">
                <IconButton size="small" onClick={() => onEditNote(index)}>
                  <EditIco />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
          {(hoverIndex === index || hoverIndex === index + 1) && (
            <>
              <DroppableBox
                side="left"
                index={index}
                hoverIndex={hoverIndex}
                gap={gap}
                separatorWidth={separatorWidth}
                onDragEnter={onDragEnter}
                onDrop={onDrop}
              />
              <DroppableBox
                side="right"
                index={index + 1}
                hoverIndex={hoverIndex}
                gap={gap}
                separatorWidth={separatorWidth}
                onDragEnter={onDragEnter}
                onDrop={onDrop}
              />
            </>
          )}
        </Box>
      ))}
    </Stack>
  )
}

type Side = 'left' | 'right'

interface IDroppableBox {
  side: Side
  index: number
  hoverIndex: number
  gap: number
  separatorWidth: number
  onDragEnter: (index: number) => void
  onDrop: (event: DragEvent<HTMLDivElement>, index: number, side: Side) => void
}

function DroppableBox(props: IDroppableBox): JSX.Element {
  const { side, index, hoverIndex, gap, separatorWidth } = props
  const halfGap = gap / 2
  const theme = useTheme()

  function onDragEnter(event: DragEvent<HTMLDivElement>) {
    event.preventDefault()
    props.onDragEnter(index)
  }
  function onDrop(event: DragEvent<HTMLDivElement>) {
    event.preventDefault()
    props.onDrop(event, index, side)
  }

  const offset = `-${halfGap + separatorWidth / 2}px`

  return (
    <Box
      onDragEnter={onDragEnter}
      onDragOver={(event) => event.preventDefault()}
      onDrop={onDrop}
      sx={{
        zIndex: 10,
        position: 'absolute',
        width: `calc(50% + ${halfGap - separatorWidth}px)`,
        height: `calc(100% + ${gap}px)`,
        top: `-${halfGap}px`,
        [side]: offset,
        borderWidth: 0,
        borderStyle: 'solid',
        borderRightWidth: side === 'right' ? separatorWidth + 'px' : 0,
        borderLeftWidth: side === 'left' ? separatorWidth + 'px' : 0,
        borderColor: hoverIndex === index ? theme.palette.primary.main : 'transparent',
      }}
    />
  )
}
