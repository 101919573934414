import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function DocSharedIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 19.19092,17.473966 c 0.966213,0 1.738875,0.772665 1.738875,1.787145 0,0.96621 -0.772662,1.73889 -1.738875,1.73889 -0.965724,0 -1.787158,-0.77268 -1.787158,-1.73889 0,-0.14475 0.04824,-0.29007 0.04824,-0.43485 l -4.298711,-2.511555 c -0.289556,0.338325 -0.724407,0.53136 -1.207514,0.53136 -1.013979,0 -1.835413,-0.820935 -1.835413,-1.8354 0,-1.01448 0.821434,-1.78716 1.835413,-1.78716 0.483107,0 0.917958,0.14475 1.207514,0.483135 l 4.250441,-2.51157 c 0,-0.14475 -0.04824,-0.241815 -0.04824,-0.386581 0,-1.0139846 0.821434,-1.8354146 1.835412,-1.8354146 1.014468,0 1.787143,0.82143 1.787143,1.8354146 0,0.966211 -0.772675,1.787146 -1.787143,1.787146 -0.48312,0 -0.917454,-0.193035 -1.255782,-0.483105 l -4.250443,2.463285 c 0.04824,0.14535 0.04824,0.29007 0.04824,0.43485 0,0.14475 0,0.28956 -0.04824,0.43485 l 4.298696,2.511555 c 0.33833,-0.29007 0.724409,-0.483105 1.207529,-0.483105 z m 0,-7.293361 c -0.337825,0 -0.627898,0.290055 -0.627898,0.627885 0,0.290056 0.290073,0.579631 0.627898,0.579631 0.338328,0 0.579615,-0.289575 0.579615,-0.579631 0,-0.33783 -0.241287,-0.627885 -0.579615,-0.627885 z m -7.245114,5.457961 c 0.338329,0 0.57963,-0.28956 0.57963,-0.6279 0,-0.338325 -0.241301,-0.57963 -0.57963,-0.57963 -0.337824,0 -0.627884,0.241305 -0.627884,0.57963 0,0.33834 0.29006,0.6279 0.627884,0.6279 z m 7.245114,4.202175 c 0.338328,0 0.579615,-0.2418 0.579615,-0.57963 0,-0.338325 -0.241287,-0.6279 -0.579615,-0.6279 -0.337825,0 -0.627898,0.289575 -0.627898,0.6279 0,0.33783 0.290073,0.57963 0.627898,0.57963 z M 8.433482,3 h 8.61925 V 6.9685053 H 15.048161 V 4.9639351 H 9.3153831 V 9.2535004 H 5.0263203 v 9.7419306 h 8.0177747 v 2.00457 H 4.8256698 c -1.0022986,0 -1.8039196,-0.80214 -1.8039196,-1.804425 V 8.3715903 L 8.433482,3"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
