import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function AddDocExternalIco(props: SvgIconProps) {
  return (
    <SvgIcon enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="none" {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path
          d="m 18.224121,1.5183088 v 2.8344726 h -2.832276 v 1.4677734 h 2.832276 v 2.8344726 h 1.46997 V 5.8205548 h 2.834473 V 4.3527814 H 19.694091 V 1.5183088 Z M 6.5610349,5.706297 1.5183105,10.749021 V 20.83447 c 0.00315,0.928061 0.7572378,1.680383 1.6853026,1.680909 H 9.522949 V 20.663085 H 3.3749998 V 11.592772 H 7.4047849 V 7.5366193 H 12.74414 V 9.421873 h 1.85669 V 5.706297 Z m 7.0114741,9.166992 -3.8056636,3.807861 h 2.5334476 v 3.818848 h 2.540038 V 18.68115 h 2.537842 z"
          style={{
            fill: props.color,
          }}
        />
      </g>
    </SvgIcon>
  )
}
