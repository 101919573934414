import * as loglevel from 'loglevel'

import { APP_ENV } from './Constants'

if (APP_ENV === 'production') {
  loglevel.setLevel('error')
} else {
  loglevel.setLevel('debug')
}

// export const logResponseError = (response) => {
//     if (response) {
//         if (response.data) {
//             loglevel.error(JSON.stringify(response.data));
//         } else {
//             loglevel.error(JSON.stringify(response));
//         }
//     } else {
//         loglevel.error('Nessuna risposta...')
//     }
// }

export default loglevel
