import { ITreatedFieldJson, TreatedField } from './TreatedField'

export interface ITreatedDataTypesJson {
  schema_ver: number
  fields: ITreatedFieldJson[]
  custom_fields: ITreatedFieldJson[]
}

export class TreatedDataTypes {
  schemaVer: number = 0
  fields: TreatedField[] = []
  customFields: TreatedField[] = []

  constructor() {}

  public static serialize(obj: TreatedDataTypes): ITreatedDataTypesJson {
    return {
      schema_ver: obj.schemaVer,
      fields: TreatedField.serializeArray(obj.fields),
      custom_fields: TreatedField.serializeArray(obj.customFields),
    }
  }

  public static deserialize(json: ITreatedDataTypesJson): TreatedDataTypes {
    const res = new TreatedDataTypes()
    res.schemaVer = json.schema_ver
    res.fields = json.fields ? TreatedField.deserializeArray(json.fields) : []
    res.customFields = json.custom_fields ? TreatedField.deserializeArray(json.custom_fields) : []
    return res
  }
}
