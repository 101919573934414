import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { IContractInfoEditorFormProps } from './ContractInfoEditorForm.types'
import { useState } from 'react'
import DataEditorHeader from '../dataEditorHeader/DataEditorHeader'
import { EditIco } from '../icons'
import ContractInfoEditor from './ContractInfoEditor'
import { DataEditorContainer } from '../dataEditorContainer/DataEditorContainer'

export function ContractInfoEditorForm(props: IContractInfoEditorFormProps): JSX.Element {
  const { t } = useTranslation()
  const [isEditMode, setIsEditMode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  return (
    <DataEditorContainer isLoading={isLoading}>
      <DataEditorHeader
        title={t(msgIds.MSG_CONTRACT_INFO_TITLE)}
        isEditMode={isEditMode}
        viewModeCommands={
          props.isAuthorizedToEdit
            ? [
                {
                  commandText: '',
                  icon: <EditIco />,
                  onClick: () => setIsEditMode(true),
                  tooltipText: t(msgIds.MSG_MODIFY) ?? undefined,
                  disabled: false,
                },
              ]
            : []
        }
        editModeCommands={[]}
      />
      <ContractInfoEditor
        contractVersion={props.contractVersion}
        comparation={props.comparation}
        isAuthorizedToEdit={props.isAuthorizedToEdit}
        isEditMode={isEditMode}
        disabled={false}
        onSave={() => setIsEditMode(false)}
        onCancel={() => setIsEditMode(false)}
        setIsLoading={setIsLoading}
      />
    </DataEditorContainer>
  )
}
