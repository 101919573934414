import { Backdrop, CircularProgress } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { LoadingOverlayProps } from './LoadingOverlaytypes'

export default function LoadingOverlay({ isLoading }: LoadingOverlayProps) {
  const theme = useTheme()

  /*
  NOTE: 
  To use LoadingOverlay inside a component and have its size adapt to the size of the component itself, 
  apply the style: position: 'relative' to the component.
  Without this style, LoadingOverlay takes up the entire page.
  */

  return (
    <Backdrop
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1, // Make sure the overlay is above other elements
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark overlay
      }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
