import { InvitationPurposeType, InvitationPurposeType_all } from '../shared/Constants'

export interface IHistoryContextInvitationJson {
  id: number
  owner_profile_id: number
  author_profile_id: number
  target_profile_id: number
  purpose: number
  revoked_at?: string
  accepted_at?: string
  created_profile_id: number
}

export class HistoryContextInvitation {
  id: number = 0
  ownerProfileId: number = 0
  authorProfileId: number = 0
  targetProfileId: number = 0
  purpose: InvitationPurposeType_all = InvitationPurposeType.collaborate
  revokedAt?: Date
  acceptedAt?: Date
  createdProfileId: number = 0

  constructor() {}

  public static serialize(obj: HistoryContextInvitation): IHistoryContextInvitationJson {
    return {
      id: obj.id,
      owner_profile_id: obj.ownerProfileId,
      author_profile_id: obj.authorProfileId,
      target_profile_id: obj.targetProfileId,
      purpose: obj.purpose,
      revoked_at: obj.revokedAt?.toISOString(),
      accepted_at: obj.acceptedAt?.toISOString(),
      created_profile_id: obj.createdProfileId,
    }
  }

  public static deserialize(json: IHistoryContextInvitationJson): HistoryContextInvitation {
    const res = new HistoryContextInvitation()
    res.id = json.id
    res.ownerProfileId = json.owner_profile_id
    res.authorProfileId = json.author_profile_id
    res.targetProfileId = json.target_profile_id
    res.purpose = json.purpose
    res.revokedAt = json.revoked_at ? new Date(json.revoked_at) : undefined
    res.acceptedAt = json.accepted_at ? new Date(json.accepted_at) : undefined
    res.createdProfileId = json.created_profile_id
    return res
  }
}
