export class PaginatedResponse<T> {
  rows: T[]
  page: number
  offset: number
  limit: number
  total: number

  _hasMore = false
  get hasMore() {
    return this.offset + this.rows.length < this.total
  }

  constructor() {
    this.rows = []
    this.page = 0
    this.offset = 0
    this.limit = 0
    this.total = 0
  }
}
