export interface INotificationContextContractSectionActionJson {
  action: string
  target: string
}

export class NotificationContextContractSectionAction {
  action: string = ''
  target: string = ''

  constructor() {}

  public static serialize(
    obj: NotificationContextContractSectionAction
  ): INotificationContextContractSectionActionJson {
    return {
      action: obj.action,
      target: obj.target,
    }
  }

  public static deserialize(
    json: INotificationContextContractSectionActionJson
  ): NotificationContextContractSectionAction {
    const res = new NotificationContextContractSectionAction()
    res.action = json.action
    res.target = json.target
    return res
  }

  public static serializeArray(
    objs: NotificationContextContractSectionAction[]
  ): INotificationContextContractSectionActionJson[] {
    const jsons = objs.map((p) => {
      return NotificationContextContractSectionAction.serialize(p)!
    })
    return jsons
  }

  public static deserializeArray(
    json: INotificationContextContractSectionActionJson[]
  ): NotificationContextContractSectionAction[] {
    const res = json.map((p) => {
      return NotificationContextContractSectionAction.deserialize(p)!
    })
    return res
  }
}
