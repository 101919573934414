import log from '../../shared/Logger'
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { DownloadIco } from '../icons'
import { useTranslation } from 'react-i18next'
import { Utils, dateTimeShortOptions } from '../../shared/Utils'
import { DocumentRevision } from '../../models/DocumentRevision'
import { useState } from 'react'
import * as dalDocument from '../../dal/DalDocument'
import { IDocumentRevisionsTableProps } from './DocumentRevisionsTable.types'
import msgIds from '../../locales/msgIds'
import { CircularProgressWithLabel } from '../circularProgress/CircularProgressWithLabel'
import { StyledTableRow } from '../../shared/StyledControls'
import { createBlob, IDownload, useDocumentCacheContext } from '../../contexts/DocumentCacheContext'
import { isPersonalDoxMimetype } from '../../shared/Constants'

export default function DocumentRevisionsTable(props: IDocumentRevisionsTableProps): JSX.Element {
  const { t, i18n } = useTranslation()
  const [downloads, setDownloads] = useState<{ [rId: number]: IDownload }>({})
  const { document } = props
  const { revisions } = document
  const documentCacheContext = useDocumentCacheContext()

  function onChangeDownload(download: IDownload) {
    setDownloads((downloads) => {
      return { ...downloads, [download.revisionId]: download }
    })
  }

  function removeDownload(download: IDownload) {
    setDownloads((downloads) => {
      const newValue = { ...downloads }
      delete newValue[download.revisionId]
      return newValue
    })
  }

  function fakeDownloadAId(revisionId: number) {
    return `fake-download-a-${revisionId}`
  }

  function downloadBlob(blob: Blob, revision: DocumentRevision) {
    const objectUrl = URL.createObjectURL(blob)
    const fakeAnchorId = fakeDownloadAId(revision.revisionId)
    const el = myGetElementById(fakeAnchorId)
    if (!el) {
      log.error({ missingFakeDownloadAnchor: fakeAnchorId })
      return
    }
    const a = el as HTMLAnchorElement
    a.href = objectUrl
    a.download = revision.filename || revision.name
    a.target = '_blank' // for mobile that doesn't support download
    a.click()
    // needed?
    // URL.revokeObjectURL(objectUrl)
  }

  async function onClickDownload(revision: DocumentRevision) {
    const downloadId = `doc/rev/${revision.revisionId}`
    const { revisionId, documentId, mimetype } = revision
    const name = revision.filename || revision.name || downloadId
    const cachedDownload = documentCacheContext?.downloadMap[downloadId]
    if (cachedDownload) {
      if (isPersonalDoxMimetype(mimetype) && cachedDownload && cachedDownload.data) {
        // force memetype because internal documents download produce a pdf
        const blob = createBlob(cachedDownload.data, 'application/pdf')
        downloadBlob(blob, revision)
        return
      } else if (!isPersonalDoxMimetype(mimetype)) {
        const cachedDocument = documentCacheContext?.documentMap[revision.documentId]
        const cachedRevision = cachedDocument?.getRevision(revision.revisionId)
        const cachedContent = cachedRevision?.content
        if (!!cachedContent) {
          log.debug({ onClikcDownload: 'cacheBlob' })
          const blob = createBlob([cachedContent], cachedRevision.mimetype)
          downloadBlob(blob, revision)
          return
        }
      }
    }

    let download = downloads[revision.revisionId]
    if (download) {
      return
    }

    try {
      download = {
        id: downloadId,
        name,
        mimetype,
        revisionId,
        documentId,
        progress: 0,
      }
      onChangeDownload(download)
      const abortController = new AbortController()
      const arrayBuffer = await dalDocument.getDocumentRevisionContent(
        abortController.signal,
        document.documentId,
        revisionId,
        false,
        (progress) => {
          download.progress = progress
          console.log(progress)
          onChangeDownload(download)
        }
      )

      download.data = [arrayBuffer]
      documentCacheContext.patchDownload(downloadId, download)
      if (documentCacheContext?.setRevisionContent) {
        if (isPersonalDoxMimetype(revision.mimetype)) {
          documentCacheContext.setRevisionContent(document.documentId, revisionId, revision.content)
        } else {
          documentCacheContext.setRevisionContent(document.documentId, revisionId, arrayBuffer)
        }
      }
      const blob = createBlob([arrayBuffer], mimetype)
      downloadBlob(blob, revision)
    } catch (err) {
      download.error = err
      onChangeDownload(download)
    } finally {
      removeDownload(download)
    }
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t(msgIds.MSG_DOCUMENT_EDITOR_REVISION_ARCHIVED_AT_LABEL)}</TableCell>
          <TableCell>{t(msgIds.MSG_DOCUMENT_EDITOR_REVISION_NAME_LABEL)}</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {revisions.map((revision) => (
          <StyledTableRow
            key={revision.revisionId}
            onClick={() => props.onChangeRevisionId(revision.revisionId, false)}
            onDoubleClick={() => props.onChangeRevisionId(revision.revisionId, true)}
            selected={revision.revisionId === props.revision?.revisionId}
            hover={true}
          >
            <TableCell component="th" scope="row">
              {revision.archivedAt
                ? Utils.toLocaleDateString(revision.archivedAt, i18n, dateTimeShortOptions)
                : t(msgIds.MSG_DRAFT)}
            </TableCell>
            <TableCell>{revision.name}</TableCell>
            <TableCell>
              <a style={{ display: 'none' }} id={fakeDownloadAId(revision.revisionId)} href="#download"></a>
              {downloads[revision.revisionId] ? (
                <CircularProgressWithLabel
                  label={downloads[revision.revisionId].progress}
                  value={downloads[revision.revisionId].progress}
                  size={24}
                />
              ) : (
                <IconButton
                  color="inherit"
                  onClick={(event) => {
                    event.stopPropagation()
                    onClickDownload(revision)
                  }}
                >
                  <DownloadIco />
                </IconButton>
              )}
            </TableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  )
}

function myGetElementById(id: string) {
  return document.getElementById(id)
}
