import { Divider, Stack, Switch, Typography } from '@mui/material'
import { ISwitchRowProps } from './SwitchRow.types'

export function SwitchRow(props: ISwitchRowProps): JSX.Element {
  const { divider, label, ...rest } = props
  return (
    <Stack spacing={1}>
      <Stack spacing={1} flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography>{label}</Typography>
        <Switch {...rest} />
      </Stack>
      {divider && <Divider />}
    </Stack>
  )
}
