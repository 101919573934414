import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import * as dalAuth from '../../dal/DalAuth'
import { Utils } from '../../shared/Utils'
import { Button, useTheme } from '@mui/material'
import msgIds from '../../locales/msgIds'
import { useNavigate, useParams } from 'react-router-dom'
import { PDXLogo } from '../images'
import { StyledLoginForm } from '../../shared/StyledControls'
import { useSnackbar } from 'notistack'
import { PasswordEditorFields } from '../passwordEditor/PasswordEditorFields'
import { useAuthContext } from '../../contexts/AuthContext'
import { StyledDialog } from '../../dialogs/styledDialog/StyledDialog'
import { ViewHeader } from '../viewHeader/ViewHeader'
import { ViewContent } from '../viewContent/ViewContent'
import { ProfileType } from '../../shared/Constants'
import { getProfileBasedTheme } from '../../themes/ThemeUtils'

export function PasswordReset(): JSX.Element {
  const { token } = useParams()

  const authContext = useAuthContext()
  if (token) {
    authContext.setJwt(token)
  }

  const theme = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [password, setPassword] = useState<string>('')
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('')
  const [errors, setErrors] = useState<any>({})
  const [isLoading, setIsLoading] = useState(false)
  const [isPasswordSaved, setIsPasswordSaved] = useState(false)

  function validate() {
    return Utils.validatePasswordConfirmation(password, passwordConfirmation)
  }

  async function onResetPassword() {
    if (!token) return

    const errors = validate()
    setErrors(errors || {})
    if (errors) {
      return
    }
    setIsLoading(true)
    const abortController = new AbortController()
    try {
      await dalAuth.resetPassword(abortController.signal, password, token)
      setIsPasswordSaved(true)
      enqueueSnackbar(t(msgIds.MSG_OPERATION_EXECUTED_SUCCESSFULLY), { variant: 'success' })
    } catch (error) {
      Utils.enqueueSnackbarError2(error, t)
    } finally {
      setIsLoading(false)
    }
  }

  function loginAsOperator() {
    navigate(`/login?profileType=${ProfileType.operatorAdmin}`)
  }

  function loginAsCustomer() {
    navigate(`/login?profileType=${ProfileType.customer}`)
  }

  return (
    <StyledLoginForm>
      <PDXLogo
        sx={{
          alignSelf: 'center',
          width: { xs: '70%', sm: '60%' },
          maxWidth: '420px',
          height: 'auto',
        }}
      />

      <PasswordEditorFields
        errors={errors}
        password={password}
        passwordConfirmation={passwordConfirmation}
        onChangePassword={setPassword}
        onChangePasswordConfirmation={setPasswordConfirmation}
      />

      <Button variant="contained" onClick={onResetPassword}>
        {t(msgIds.MSG_LOGIN_PASSWORD_RESET)}
      </Button>

      <StyledDialog maxWidth="xs" minHeight="auto" open={isPasswordSaved} onClose={() => setIsPasswordSaved(false)}>
        <ViewHeader
          title={t(msgIds.MSG_LOGIN_FAILED_DIALOG_TITLE)}
          exitButtonVisible={true}
          onClickExit={() => setIsPasswordSaved(false)}
        />
        <ViewContent spacing={4} marginY={4}>
          <Button
            variant="contained"
            onClick={loginAsOperator}
            sx={{
              textTransform: 'none',
              backgroundColor: getProfileBasedTheme(ProfileType.operatorAdmin).palette.primary.main,
            }}
          >
            {t(msgIds.MSG_LOGIN_AS_BUSINESS_USER)}
          </Button>
          <Button
            variant="contained"
            onClick={loginAsCustomer}
            sx={{
              textTransform: 'none',
              backgroundColor: getProfileBasedTheme(ProfileType.customer).palette.primary.main,
            }}
          >
            {t(msgIds.MSG_LOGIN_AS_CONSUMER_USER)}
          </Button>
        </ViewContent>
      </StyledDialog>
    </StyledLoginForm>
  )
}
