export interface IPermissionActionOtpJson {
  generated: boolean
  pin: string
}

export class PermissionActionOtp {
  generated: boolean = false
  pin: string = ''

  constructor() {}

  public static serialize(obj: PermissionActionOtp): IPermissionActionOtpJson {
    return {
      generated: obj.generated,
      pin: obj.pin,
    }
  }

  public static deserialize(json: IPermissionActionOtpJson): PermissionActionOtp {
    const res = new PermissionActionOtp()
    res.generated = json.generated
    res.pin = json.pin
    return res
  }
}
