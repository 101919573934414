import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useAuthContext } from '../../contexts/AuthContext'
import msgIds from '../../locales/msgIds'
import { Stack } from '@mui/material'
import * as dalPermission from '../../dal/DalPermission'
import * as dalContractVersion from '../../dal/DalContractVersion'
import { IContractVersionViewerPageInit, IMissingInfoDialogData } from './ContractVersionViewerPage.types'
import { ContractVersion } from '../../models/ContractVersion'
import { ContractType, ContractTypeTranslationMap, isConsumer, isOperator } from '../../shared/Constants'
import ContractVersionViewer from '../../components/contractVersionViewer/ContractVersionViewer'
import { PageContainer, PageContent } from '../../components/pageContainer/PageContainer'
import CommandBar from '../../components/commandBar/CommandBar'
import { ICommand } from '../../components/commandBar/CommandBar.types'
import { DownloadIco, PauseIco, PlayIco, PrivacyIco } from '../../components/icons'
import DialogTemplate from '../../components/template/dialogTemplate/DialogTemplate'
import { ViewContent } from '../../components/viewContent/ViewContent'
import { ContractVersionsTable } from '../../components/contractVersionsTable/ContractVersionsTable'
import { Utils } from '../../shared/Utils'
import { Permission } from '../../models/Permission'
import { AccountEditorContextProvider } from '../../contexts/AccountEditorContext'
import { ProfileInformations } from '../../components/profileInformations/ProfileInformations'
import { IDialogResultBase } from '../../components/template/dialogTemplate/DialogTemplate.types'
import { useSnackbar } from 'notistack'
import { createBlob } from '../../contexts/DocumentCacheContext'
import { SectionsEditability } from '../../components/registration/RegistrationHooks'

export default function ContractVersionViewerPage() {
  const location = useLocation()
  const state = location.state as IContractVersionViewerPageInit

  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const authContext = useAuthContext()

  const grantorProfileId = state.grantorProfileId || 0
  const contractOwnerProfileId = state.contractOwnerProfileId || 0
  const targetProfileType = state.targetProfileType
  const contractType = state.contractType

  const [contractId, setContractId] = useState<number>(0)
  const [consentsSnapshot, setConsentsSnapshot] = useState<ContractVersion>()
  const [dataProcessingPermission, setDataProcessingPermission] = useState<Permission>()

  const contractViewerRef = useRef<{ onGrantorInfoUpdated: () => void }>(null)

  useEffect(() => {
    if (!isConsumer(authContext.loggedProfileType) && contractType !== ContractType.privacyPolicy) {
      return
    }
    const abortController = new AbortController()
    const getDataProcessingPermission = async () => {
      try {
        const permission = await dalPermission.getDataProcessingPermission(
          abortController.signal,
          contractOwnerProfileId
        )
        setDataProcessingPermission(permission)
      } catch (error) {
        Utils.enqueueSnackbarError2(error, t)
      }
    }
    getDataProcessingPermission()
    return () => abortController.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext.loggedProfileType, contractOwnerProfileId, contractType])

  function getPageTitle() {
    if (isConsumer(targetProfileType)) {
      switch (contractType) {
        case ContractType.privacyPolicy:
          return t(msgIds.MSG_CONTRACT_PRIVACY_POLICY_FOR_CONSUMER_TITLE)
        case ContractType.termsOfUse:
          return t(msgIds.MSG_CONTRACT_TERMS_OF_USE_FOR_CONSUMER_TITLE)
        default:
          return ''
      }
    } else if (isOperator(targetProfileType)) {
      switch (contractType) {
        case ContractType.privacyPolicy:
          return t(msgIds.MSG_CONTRACT_PRIVACY_POLICY_FOR_OPERATOR_TITLE)
        case ContractType.termsOfUse:
          return t(msgIds.MSG_CONTRACT_TERMS_OF_USE_FOR_OPERATOR_TITLE)
        case ContractType.dataProcessor:
          return t(msgIds.MSG_CONTRACT_DATA_PROCESSOR_FOR_OPERATOR_TITLE)
        default:
          return ''
      }
    } else {
      return ''
    }
  }

  async function onDownload() {
    if (!consentsSnapshot?.id) return

    const abortController = new AbortController()
    try {
      const arrayBuffer = await dalContractVersion.downloadContractVersion(
        abortController.signal,
        contractId,
        consentsSnapshot?.id,
        grantorProfileId
      )
      const blob = createBlob([arrayBuffer], 'application/pdf')
      downloadBlob(blob, contractType)
    } catch (error) {
      Utils.enqueueSnackbarError2(error, t)
    }
  }

  function downloadBlob(blob: Blob, contractType: ContractType) {
    const objectUrl = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = objectUrl
    a.download = t(ContractTypeTranslationMap[contractType])
    document.body.appendChild(a)
    a.click()

    // Clean up
    setTimeout(() => {
      document.body.removeChild(a)
      URL.revokeObjectURL(objectUrl)
    }, 100)
  }

  async function toggleDataProcessingPermission() {
    if (!dataProcessingPermission) {
      return
    }
    const abortController = new AbortController()
    try {
      const permission = await dalPermission.updatePermissionSuspensionState(
        abortController.signal,
        dataProcessingPermission.id,
        !dataProcessingPermission.isSuspended
      )
      setDataProcessingPermission(permission)
      enqueueSnackbar(t(msgIds.MSG_OPERATION_EXECUTED_SUCCESSFULLY), {
        variant: 'success',
      })
    } catch (error) {
      Utils.enqueueSnackbarError2(error, t)
    }
  }

  const [showObsoleteDialogOpen, setShowObsoleteDialogOpen] = useState(false)

  const commands: ICommand[] = []
  if (consentsSnapshot) {
    commands.push({
      commandText: t(msgIds.MSG_CONTRACT_VERSION_VIEWER_PAGE_DOWNLOAD_COMMAND),
      icon: <DownloadIco />,
      onClick: onDownload,
    })
  }

  commands.push({
    commandText: t(msgIds.MSG_CONTRACT_VERSION_VIEWER_PAGE_SHOW_OBSOLETE_COMMAND),
    icon: <PrivacyIco />,
    onClick: () => setShowObsoleteDialogOpen(true),
  })

  if (
    isConsumer(authContext.loggedProfileType) &&
    contractType === ContractType.privacyPolicy &&
    contractOwnerProfileId !== 0 &&
    dataProcessingPermission
  ) {
    commands.push({
      icon: dataProcessingPermission?.isSuspended ? <PlayIco /> : <PauseIco />,
      commandText: dataProcessingPermission?.isSuspended
        ? t(msgIds.MSG_CONTRACT_VERSION_VIEWER_PAGE_ACTIVATE_DATA_PROCESSING)
        : t(msgIds.MSG_CONTRACT_VERSION_VIEWER_PAGE_SUSPEND_DATA_PROCESSING),
      onClick: toggleDataProcessingPermission,
    })
  }

  function onSelectVersion(version: ContractVersion) {
    setConsentsSnapshot(version)
    setShowObsoleteDialogOpen(false)
  }

  const [missingInfoDialogData, setMissingInfoDialogData] = useState<IMissingInfoDialogData>()
  const [missingInfoDialogOpen, setMissingInfoDialogOpen] = useState(false)
  function onAddMissingInfo(mandatoryFieldsNames: string[], optionalFieldsNames: string[]) {
    setMissingInfoDialogOpen(true)
    setMissingInfoDialogData({
      profileId: grantorProfileId,
      ownerProfileId:
        (isOperator(authContext.loggedProfileType)
          ? authContext.linkedStructureProfileId
          : authContext.loggedProfileId) || 0,
      mandatoryFieldsNames: mandatoryFieldsNames,
      optionalFieldsNames: optionalFieldsNames,
    })
  }

  function onMissingInfoDialogClose(result: IDialogResultBase) {
    setMissingInfoDialogOpen(false)
    contractViewerRef.current?.onGrantorInfoUpdated()
  }

  function computeSectionsEditability(): SectionsEditability {
    return contractOwnerProfileId === 0 ? 'allExceptMandatory' : 'allExceptNotRevocable'
  }

  return (
    <PageContainer>
      <CommandBar style={{ minHeight: 'auto' }} title={getPageTitle()} commands={commands} />
      <PageContent sx={{ padding: 0 }}>
        <Stack width="100%" maxWidth="lg" alignItems="stretch">
          <ContractVersionViewer
            ref={contractViewerRef}
            isEditable={state.isEditable}
            sectionsEditability={computeSectionsEditability()}
            contractType={contractType}
            targetProfileType={targetProfileType}
            consentsSnapshot={consentsSnapshot}
            grantorProfileId={grantorProfileId}
            contractOwnerProfileId={contractOwnerProfileId}
            onAddMissingInfo={onAddMissingInfo}
            onContractLoaded={(contract) => contract && setContractId(contract.id)}
            onConsentsSnapshotShowed={setConsentsSnapshot}
          />
        </Stack>
      </PageContent>

      <DialogTemplate
        title={t(msgIds.MSG_CONTRACT_VERSION_LIST_DIALOG_TITLE)}
        isOpen={showObsoleteDialogOpen}
        onClose={() => setShowObsoleteDialogOpen(false)}
        canAbort={true}
        showActions={false}
        actions={[]}
        isFullscreenForMobile={true}
        maxWidth="sm"
      >
        <ViewContent>
          <ContractVersionsTable
            grantorProfileId={grantorProfileId}
            contractId={contractId}
            onClickVersion={onSelectVersion}
          />
        </ViewContent>
      </DialogTemplate>

      <DialogTemplate
        title={t(msgIds.MSG_CONTRACT_VERSION_LIST_DIALOG_TITLE)}
        isOpen={missingInfoDialogOpen}
        onClose={onMissingInfoDialogClose}
        canAbort={true}
        showActions={false}
        actions={[]}
        isFullscreenForMobile={true}
        maxWidth="md"
      >
        <ViewContent disablePadding>
          {missingInfoDialogData && (
            <AccountEditorContextProvider>
              <ProfileInformations
                profileId={missingInfoDialogData?.profileId}
                ownerProfileId={missingInfoDialogData?.ownerProfileId}
                mandatoryFieldsNames={missingInfoDialogData?.mandatoryFieldsNames}
                optionalFieldsNames={missingInfoDialogData?.optionalFieldsNames}
              />
            </AccountEditorContextProvider>
          )}
        </ViewContent>
      </DialogTemplate>
    </PageContainer>
  )
}
